// todo: Refactor

export enum LoginType {
  Twitch,
  Instagram,
  Youtube,
  Email,
  Auto
}


export type AutoLoginRequest = { loginType: LoginType.Auto, redirectUrl?: string};
export type TwitchLoginRequest = { loginType: LoginType.Twitch};
export type InstagramLoginRequest = { loginType: LoginType.Instagram, accessToken: string};
export type YoutubeLoginRequest = { loginType: LoginType.Youtube};
export type EmailLoginRequest = { loginType: LoginType.Email, username: string, password: string, redirectUrl?: string};

export type LoginResponse = { redirectUrl?: string} & any;
export type LoginRequest = TwitchLoginRequest | InstagramLoginRequest | YoutubeLoginRequest | EmailLoginRequest | AutoLoginRequest;

export type LoginFailed = any;
