import { Component, memo } from 'react';
import Globals from '../global-functions.js';
import FacebookLogin from '../dependencies/facebook-login.js';
import BlinkingDot from './blinking-dot.jsx';

class AccountSocials extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectedPlatformConnect: '',
      socials: [],
      tiktokUsername: ''
    }
  }

  componentDidMount() {

  }

  youtubeLogin(){ $.ajax({ method: 'GET', url: '/api/YouTube/authUrl', data: {} }).done((data) => { location.replace(data) }) }
  youtubeRevoke(){ $.ajax({ method: 'POST', url: '/api/YouTube/revoke', data: {} }).done((data) => { this.props.refreshUser() }) }
  twitchLogin(){ $.ajax({ method: 'GET', url: '/api/Twitch/login', data: {} }).done((data) => { location.replace(data) }) }
  twitchRevoke(){ $.ajax({ method: 'POST', url: '/api/Twitch/revoke', data: {} }).done((data) => { this.props.refreshUser() }) }
  tiktokSave(){ $.ajax({ method: 'POST', url: '/api/Tiktok/save', data: { username: this.state.tiktokUsername } }).done((data) => { this.props.refreshUser() }) }
  tiktokRevoke(){ $.ajax({ method: 'POST', url: '/api/Tiktok/remove', data: { } }).done((data) => { this.props.refreshUser() }) }

  onClickPlatform(platform){
    var platforms = this.state.platforms
    platforms[platform] = !platforms[platform]
    this.setState({ platforms: platforms })
  }

  storeInstagramToken(response) { this._storeInstagramToken(response.accessToken).done((response) => { this.props.refreshUser() }) }
  _storeInstagramToken(access_token){ return $.ajax({ method: 'POST', url: '/api/Instagram/storeToken', data: { access_token: access_token } }) }
  revokeInstagram() { this._revokeInstagram().done((response) => { this.props.refreshUser() }) }
  _revokeInstagram(){ return $.ajax({ method: 'POST', url: '/api/Instagram/revoke', data: { test: '1' } }) }
  _notifyLinkAccount() { return $.ajax({ method: 'POST', url: '/api/Users/notifyLinkAccount' }) }

  selectPlatformConnect(platform){ this.setState({ selectedPlatformConnect: this.state.selectedPlatformConnect != platform ? platform : false }) }

  onChange(field, e){ this.setState({ [field]: e.target.value }) }

  render() {

    var socialActive = {}
    this.props.socials.forEach((s) => {
      if (s.label == 'InstagramUser'){ socialActive.instagram = true }
      else if (s.label == 'YoutubeUser'){ socialActive.youtube = true }
      else if (s.label == 'TwitchUser'){ socialActive.twitch = true }
      else if (s.label == 'TiktokUser'){ socialActive.tiktok = true }
    })
    var selectedPlatformConnect = this.state.selectedPlatformConnect
    var user = this.props.user

    return (
      <div className="connect-socials-container" id="socials">
        <div className="row row0" style={{marginBottom: '32px'}}>
          <h3 style={{ margin: '20px 0 10px 0', padding: 0, fontSize: '20px', textTransform: 'initial' }}>
            <span>My Socials</span>
            {
              !user.connectedSocials &&
              <BlinkingDot />
            }
          </h3>
          <p className="sub-text">Weave needs at least 1 social connected to verify your account so we know we are sending real products and payments to the right creator, as well as know when you post about the brand. We do not change or edit anything about your account.</p>
        </div>

        {
          !this.state.selectedPlatformConnect &&
          <div className="row row8 connect-platforms" style={{maxWidth: '716px', textAlign: 'center'}}>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className={`module connect-platform instagram ${selectedPlatformConnect == 'instagram' ? 'selected' : ''} ${socialActive.instagram ? 'active' : ''}`} onClick={this.selectPlatformConnect.bind(this, 'instagram')}>
                <div className={`connect-platform-success ${socialActive.instagram ? 'active' : ''}`}><img src={Globals.icon('ok', 'white')} /></div>
                <img className="connect-platform-img" src={Globals.icon('instagram')} />
                <h5>Instagram</h5>
                <p className="sub-text">{socialActive.instagram ? 'Connected' : 'Not Connected'}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className={`module connect-platform youtube ${selectedPlatformConnect == 'youtube' ? 'selected' : ''} ${socialActive.youtube ? 'active' : ''}`} onClick={this.selectPlatformConnect.bind(this, 'youtube')}>
                <div className={`connect-platform-success ${socialActive.youtube ? 'active' : ''}`}><img src={Globals.icon('ok', 'white')} /></div>
                <img className="connect-platform-img" src={Globals.socialIcon('youtube')} />
                <h5>YouTube</h5>
                <p className="sub-text">{socialActive.youtube ? 'Connected' : 'Not Connected'}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className={`module connect-platform twitch ${selectedPlatformConnect == 'twitch' ? 'selected' : ''} ${socialActive.twitch ? 'active' : ''}`} onClick={this.selectPlatformConnect.bind(this, 'twitch')}>
                <div className={`connect-platform-success ${socialActive.twitch ? 'active' : ''}`}><img src={Globals.icon('ok', 'white')} /></div>
                <img className="connect-platform-img" src={Globals.icon('twitch')} />
                <h5>Twitch</h5>
                <p className="sub-text">{socialActive.twitch ? 'Connected' : 'Not Connected'}</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className={`module connect-platform tiktok ${selectedPlatformConnect == 'tiktok' ? 'selected' : ''} ${socialActive.tiktok ? 'active' : ''}`} onClick={this.selectPlatformConnect.bind(this, 'tiktok')}>
                <div className={`connect-platform-success ${socialActive.tiktok ? 'active' : ''}`}><img src={Globals.icon('ok', 'white')} /></div>
                <img className="connect-platform-img" src={Globals.icon('tiktok')} />
                <h5>TikTok</h5>
                <p className="sub-text">{socialActive.tiktok ? 'Connected' : 'Not Connected'}</p>
              </div>
            </div>
          </div>
        }

        <div>
          { selectedPlatformConnect == 'instagram' &&
            <div className="module" style={{maxWidth: '700px'}}>
              <div className="close-social">
                <img src={Globals.icon('close')} onClick={this.selectPlatformConnect.bind(this, selectedPlatformConnect)} />
              </div>
              {/*<div className="row row0">
                <p className="sub-text">If you're Instagram is not a Business or Creator account, add the following code into your Instagram bio temporarily. This code will allow us to prove you own the Instagram page you’ve submitted and, once verified, can be removed permanently from your bio. Verification only takes a few seconds.</p>
                <p><b style={{fontSize: '18px'}}>h7G98Sn</b></p>
                <input className="dark-input" placeholder="Instagram handle here..." />
                <button className="btn-action btn-primary">Verify Instagram</button>
              </div>*/}

              { !socialActive.instagram &&
                <>
                  <div className="row row0" style={{marginBottom: '25px'}}>
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Connect your Instagram</h3>
                    <p className="sub-text">
                      Connect your Instagram account to verify your ownership and share your statistics and demographics for Weave to correctly match you with the right opportunities.
                    </p>
                    <p className="sub-text">
                      Ensure you've <b>1)</b> upgraded your IG account to a Creator or Business account and <b>2)</b> you have a Facebook Page you've connected to your IG account.
                    </p>
                    <p className="sub-text">
                      Weave will never post, edit, or delete any of your Instagram content.
                    </p>
                  </div>
                  <div className="row row0">
                    { !Globals.isMobile() &&
                      <FacebookLogin
                        appId="520203271980535"
                        autoLoad={false}
                        scope="public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,email"
                        callback={this.storeInstagramToken.bind(this)}
                        render={renderProps => (
                          <a onClick={renderProps.onClick}>
                            <div className="integration-btn" role="button" style={{backgroundColor: '#dc4465'}}>
                              <div className="integration-btn-text-container">
                                <div className="integration-btn-text">
                                  <div className="integration-btn-text-left">
                                    <div className="integration-btn-img-container">
                                      <div className="integration-btn-img" style={{backgroundImage: 'url(/images/instagram-icon.svg)'}}></div>
                                    </div>
                                    <span className="integration-btn-title">Instagram</span>
                                    <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                                  </div>
                                  <div className="integration-btn-text-right">
                                    <button>
                                      Login
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        )}
                       />
                    }
                    {
                      Globals.isMobile() &&
                      <FacebookLogin
                        appId="520203271980535"
                        autoLoad={false}
                        scope="public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights"
                        callback={this.storeInstagramToken.bind(this)}
                        redirectUri={`https://${location.host}/redirect/instagram`}
                        responseType="token"
                        render={renderProps => (
                          <a onClick={renderProps.onClick}>
                            <div className="integration-btn" role="button" style={{backgroundColor: '#dc4465'}}>
                              <div className="integration-btn-text-container">
                                <div className="integration-btn-text">
                                  <div className="integration-btn-text-left">
                                    <div className="integration-btn-img-container">
                                      <div className="integration-btn-img" style={{backgroundImage: 'url(/images/instagram-icon.svg)'}}></div>
                                    </div>
                                    <span className="integration-btn-title">Instagram</span>
                                    <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                                  </div>
                                  <div className="integration-btn-text-right">
                                    <button>
                                      Login
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        )}
                       />
                    }
                  </div>


                </>
              }

              { socialActive.instagram &&
                <>
                  <div className="row row0">
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Your Instagram is successfully connected</h3>
                    <p className="sub-text st0">
                      You are eligible to participate in any Instagram bounties that are listed in your marketplace. If you would like to connect a different profile or disconnect this current on, please click the button below.
                    </p>
                    <br /><br /><br />
                    <a onClick={this.revokeInstagram.bind(this)}>
                      <div className="integration-btn" role="button" style={{background: 'red'}}>
                        <div className="integration-btn-text-container" style={{padding: '12px 24px 12px 42px'}}>
                          <div className="integration-btn-text">
                            <div className="integration-btn-text-left">
                              <div className="integration-btn-img-container">
                                {/*<div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('youtube', 'white')})`}}></div>*/}
                              </div>
                              <span className="integration-btn-title">Revoke Facebook/Instagram Access</span>
                              <div className="integration-btn-sub">Disconnect Weave from access to your account</div>
                            </div>
                            <div className="integration-btn-text-right">
                              <button>
                                Revoke
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              }
            </div>
          }

          { selectedPlatformConnect == 'youtube' &&
            <div className="module" style={{maxWidth: '700px'}}>
              <div className="close-social">
                <img src={Globals.icon('close')} onClick={this.selectPlatformConnect.bind(this, selectedPlatformConnect)} />
              </div>
              { !socialActive.youtube &&
                <>
                  <div className="row row0" style={{marginBottom: '25px'}}>
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Connect your YouTube</h3>
                    <p className="sub-text">
                      Connect your YouTube account to verify your ownership and share your account metrics to qualify you for bounties. We will never post, edit, or delete any of your YouTube content without your explicit permission.
                    </p>
                  </div>
                  <div className="row row0">
                    <a onClick={this.youtubeLogin.bind(this)}>
                      {/*<img src="/images/google-signin.png" />*/}
                      <div className="integration-btn" role="button" style={{backgroundColor: '#FF0000'}}>
                        <div className="integration-btn-text-container">
                          <div className="integration-btn-text">
                            <div className="integration-btn-text-left">
                              <div className="integration-btn-img-container">
                                <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('youtube', 'white')})`}}></div>
                              </div>
                              <span className="integration-btn-title">Connect with YouTube</span>
                              <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                            </div>
                            <div className="integration-btn-text-right">
                              <button>
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              }

              {
                socialActive.youtube &&
                <>
                  <div className="row row0">
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Your YouTube is successfully connected</h3>
                    <p className="sub-text st0">
                      You are eligible to participate in any YouTube bounties that are listed in your marketplace. If you would like to connect a different profile or disconnect this current one, click the button below.
                    </p>

                    <div className="row row0" style={{marginTop: '15px'}}>
                      <a onClick={this.youtubeRevoke.bind(this)}>
                        <div className="integration-btn" role="button" style={{backgroundColor: '#FF0000'}}>
                          <div className="integration-btn-text-container" style={{padding: '12px 24px 12px 42px'}}>
                            <div className="integration-btn-text">
                              <div className="integration-btn-text-left">
                                <div className="integration-btn-img-container">
                                  <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('youtube', 'white')})`}}></div>
                                </div>
                                <span className="integration-btn-title">Revoke YouTube Access</span>
                                <div className="integration-btn-sub">Disconnect YouTube from Weave</div>
                              </div>
                              <div className="integration-btn-text-right">
                                <button>
                                  Revoke
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </>
              }
            </div>
          }

          {
            selectedPlatformConnect == 'twitch' &&
            <div className="module" style={{maxWidth: '700px'}}>

              <div className="close-social">
                <img src={Globals.icon('close')} onClick={this.selectPlatformConnect.bind(this, selectedPlatformConnect)} />
              </div>

              {
                !socialActive.twitch &&
                <>
                  <div className="row row0" style={{marginBottom: '25px'}}>
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Connect your Twitch</h3>
                    <p className="sub-text">Connecting your Twitch to verify your ownership. We will never post, edit, or delete any of your Twitch content without your explicit permission.</p>
                  </div>
                  <div className="row row0">
                    <a onClick={this.twitchLogin.bind(this)}>
                      <div className="integration-btn" role="button" style={{backgroundColor: '#6441a4'}}>
                        <div className="integration-btn-text-container">
                          <div className="integration-btn-text">
                            <div className="integration-btn-text-left">
                              <div className="integration-btn-img-container">
                                <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('twitch', 'white')})`}}></div>
                              </div>
                              <span className="integration-btn-title">Connect with Twitch</span>
                              <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                            </div>
                            <div className="integration-btn-text-right">
                              <button>
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              }

              {
                socialActive.twitch &&
                <>
                  <div className="row row0">
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Your Twitch is successfully connected</h3>
                    <p className="sub-text st0">
                      You are eligible to participate in any Twitch bounties that are listed in your marketplace. If you would like to connect a different profile or disconnect this current one, click the button below.
                    </p>

                    <div className="row row0" style={{marginTop: '15px'}}>
                      <a onClick={this.twitchRevoke.bind(this)}>
                        <div className="integration-btn" role="button" style={{backgroundColor: '#6441a4'}}>
                          <div className="integration-btn-text-container" style={{padding: '12px 24px 12px 42px'}}>
                            <div className="integration-btn-text">
                              <div className="integration-btn-text-left">
                                <div className="integration-btn-img-container">
                                  <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('twitch', 'white')})`}}></div>
                                </div>
                                <span className="integration-btn-title">Revoke Twitch Access</span>
                                <div className="integration-btn-sub">Disconnect Twitch from Weave</div>
                              </div>
                              <div className="integration-btn-text-right">
                                <button>
                                  Revoke
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </>
              }
            </div>
          }

          {
            selectedPlatformConnect == 'tiktok' &&
            <div className="module" style={{maxWidth: '700px'}}>

              <div className="close-social">
                <img src={Globals.icon('close')} onClick={this.selectPlatformConnect.bind(this, selectedPlatformConnect)} />
              </div>

              {
                !socialActive.tiktok &&
                <>
                  <div className="row row0" style={{marginBottom: '25px'}}>
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Connect your TikTok</h3>
                    <p className="sub-text">Add your TikTok username below, you will not be redirected to sign in with TikTok.</p>
                  </div>
                  <div className="row row0">
                    <input className="dark-input" style={{width: '100%', marginBottom: '15px'}} placeholder="Add TikTok username..." value={this.state.tiktokUsername} onChange={this.onChange.bind(this, 'tiktokUsername')} />
                  </div>
                  <div className="row row0">
                    <a onClick={this.tiktokSave.bind(this)}>
                      <div className="integration-btn" role="button" style={{backgroundColor: '#010101'}}>
                        <div className="integration-btn-text-container">
                          <div className="integration-btn-text">
                            <div className="integration-btn-text-left">
                              <div className="integration-btn-img-container">
                                <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('tiktok', 'white')})`}}></div>
                              </div>
                              <span className="integration-btn-title">Add TikTok Username</span>
                              <div className="integration-btn-sub" style={{display: 'none'}}>Earn from posting videos</div>
                            </div>
                            <div className="integration-btn-text-right">
                              <button>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </>
              }

              {
                socialActive.tiktok &&
                <>
                  <div className="row row0">
                    <h3 style={{padding: 0, margin: '0 0 15px 0', textTransform: 'initial'}}>Your TikTok is successfully connected</h3>
                    <p className="sub-text st0">
                      You are eligible to participate in any TikTok bounties that are listed in your marketplace. If you would like to connect a different profile or disconnect this current one, click the button below.
                    </p>

                    <div className="row row0" style={{marginTop: '15px'}}>
                      <a onClick={this.tiktokRevoke.bind(this)}>
                        <div className="integration-btn" role="button" style={{backgroundColor: '#010101'}}>
                          <div className="integration-btn-text-container" style={{padding: '12px 24px 12px 42px'}}>
                            <div className="integration-btn-text">
                              <div className="integration-btn-text-left">
                                <div className="integration-btn-img-container">
                                  <div className="integration-btn-img" style={{backgroundImage: `url(${Globals.icon('tiktok', 'white')})`}}></div>
                                </div>
                                <span className="integration-btn-title">Revoke TikTok Access</span>
                                <div className="integration-btn-sub">Disconnect TikTok from Weave</div>
                              </div>
                              <div className="integration-btn-text-right">
                                <button>
                                  Revoke
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

export default memo(AccountSocials)
