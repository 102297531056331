import React from 'react';
import {Route} from 'react-router-dom';
import Globals from '../global-functions.js';
import Header from './header.jsx';
import Main from './main.jsx';
import Landing from './landing';
import Onboarding from './onboarding.jsx';
import Cookies from 'js-cookie';
import VerifyPasswordReset from "./homepage/verify-password-reset";
import store from 'store2';
var documentScrollStarted = false
const PLATFORM_PAGES = ['/dashboard','/bounties','/account','/refer','/offer','/bounty','/links','/analytics','/vip','/invoices','/outreach','/roster','/redirect/gmail','/company','/search'],
LANDING_PAGES = ['/influencers','/brands','/foodies','/restaurants','/new-landing','/login','/signup','/forgot','/privacy','/terms-of-service','/godlogin','/invoice/','collab/','/invite/'],
ONBOARDING_PAGES = ['/verify-email','/signedin','/redirect/youtube','/redirect/twitch','/redirect/instagram','/redirect/uber','/redirect/lyft','/offer','/onboard-integrations','/verify-paypal-token'],
ADMIN_PAGES = ['/applications','/data-admin']

export default class App extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isMobile: Globals.isMobile()
    }
  }

  checkPath(pages){ var match, path = location.pathname; pages.forEach((p) => { if (path.indexOf(p) > -1){ match = true } }); return match }

  UNSAFE_componentWillMount(){
    var path = location.path
    let userid = Cookies.get('access_token'), user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : null, onboarding = Cookies.get('onboarding'), onboardingStep = Cookies.get('onboarding-status')
    if (!userid && this.checkPath(PLATFORM_PAGES)){ location.replace('/') }
    else if (userid && path == '/'){ location.replace('/dashboard') }
  }

  componentDidMount(){
    // window.Intercom("update");

    if (!Globals.isMobile() && Globals.isProd){ window.Intercom('boot', { app_id: 'rm4ja52n' }); }
    if (!documentScrollStarted){ documentScrollStarted = true; window.addEventListener('scroll', this.updateScreenHeight.bind(this)); window.addEventListener('resize', this.updateScreenHeight.bind(this)); }
    function hasTouch() { return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0; }

    if (hasTouch()) { // remove all the :hover stylesheets
      try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
          var styleSheet = document.styleSheets[si];
          if (!styleSheet.rules) continue;
          for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
            if (!styleSheet.rules[ri].selectorText) continue;
            if (styleSheet.rules[ri].selectorText.match(':hover')) {
              styleSheet.deleteRule(ri);
            }
          }
        }
      } catch (ex) {}
    }

    const ref = new URLSearchParams(location.search).get('ref');
    if (ref) { store('ref', ref); }
  }

  updateScreenHeight(){
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  render() {
    // todo: continue deprecating all Cookies.get stuff
    let html = null, path = location.pathname, userid = Cookies.get('access_token'), user = Cookies.get('access_token'), onboarding = Cookies.get('onboarding')

    if (path === '/verify-password-reset'){ html = ( <div><Route path='/verify-password-reset' component={VerifyPasswordReset}/></div> ) }
    else if (onboarding === 'true' && this.checkPath(ONBOARDING_PAGES)){ html = ( <div><Onboarding /></div> ) }
    else if (userid && (this.checkPath(PLATFORM_PAGES))){
      html = (
        <div>
          <Header mainThis={this.props.this} toggleTheme={this.props.toggleTheme} themeMode={this.props.themeMode} />
          <Main toggleTheme={this.props.toggleTheme} />
        </div>
      )
    }
    else if (userid && location.host.indexOf('localhost') > -1 && (this.checkPath(ADMIN_PAGES))){
      html = (
        <div>
          <Header mainThis={this.props.this} toggleTheme={this.props.toggleTheme} themeMode={this.props.themeMode} />
          <Main toggleTheme={this.props.toggleTheme} />
        </div>
      )
    }
    else { html = ( <div><Landing /></div> ) }
    return (
      <div className={this.state.isMobile ? 'isMobile' : ''}>
        {html}
      </div>
    )
  }
}
