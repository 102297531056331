import React from 'react';
import Guideline from './guideline.jsx';

export default class Guidelines extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    return (
      <div>
        <div className="guidelines-container">
          {
            this.props.guidelines &&
            this.props.guidelines.map((g) => {
              return (
                <Guideline
                  userid={this.props.userid}
                  bountyObj={this.props.bountyObj}
                  guideline={g}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
