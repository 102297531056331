import { GetContentObjectivesRequest } from '../redux/models/content.models';
import { MakeFetchGet, MakeFetchPost, MakeFetchPut } from '../utils/utils';

declare const $: any;

interface CreateContentApprovalRequest {
  platformType?: string;
  postType?: string;
  contentLink: string;
  caption?: string;
  videoTitle?: string;
  videoCaption?: string;
  videoThumbnail?: string;
  influencerId?: string;
  bountyId?: string;
  parentInfluencerContentId?: number;
  brandCampaignId?: string;
  contentId?: string
}

// todo: use fetch
export const fetchContentApprovals = () =>
  MakeFetchGet('/api/ContentApproval/getAll');

export const fetchContentApprovalsAnonymous = (contentId: string) =>
  MakeFetchGet('/api/ContentApproval/getAllAnonymous', { contentId });

export const deleteContentApprovals = (id: string) => $.ajax({
    method: 'DELETE',
    url: '/api/ContentApproval/deleteAnonymous',
    data: {
      id,
    },
  });

export const updateContentApproval = (data: any) =>
  MakeFetchPut('/api/ContentApproval/update', data);

export const fetchObjectives = (params: GetContentObjectivesRequest) =>
  MakeFetchGet('/api/ContentApproval/getObjectives', params);


export const createContentApproval = (data: CreateContentApprovalRequest) => $.ajax({
  method: 'POST',
  url: '/api/ContentApproval/create',
  data,
});


export const createContentApprovalAnonymous = (data: CreateContentApprovalRequest) =>
  MakeFetchPost('/api/ContentApproval/createAnonymous', data);

export const _getUploadParams = () => ({
  url: '/api/ContentApproval/uploadFileAnonymous',
});

export const contentReviewStatuses = Object.freeze({
  pending: 'PENDING_APPROVAL',
  rejected: 'REJECTED',
  approved: 'APPROVED',
});

export const ReviewContentTypes = Object.freeze({
  ig_reel: 'ig_reel',
  ig_post: 'ig_post',
  tiktok_post: 'tiktok_post',
  ig_story: 'ig_story',
  yt_video: 'yt_video',
  yt_dedicated: 'yt_dedicated',
  yt_mention: 'yt_mention',
});

// const platforms = [
//   {typeId: '1', name: 'instagram', type: 'photo'},
//   {typeId: '2', name: 'instagram', type: 'instagramStory'},
//   {typeId: '3', name: 'instagram', type: 'reel'},
//   {typeId: '4', name: 'youtube', type: 'youtubeVideo'},
//   {typeId: '5', name: 'youtube', type: 'youtubeVideo'},
//   {typeId: '6', name: 'tiktok', type: 'tiktokVideo'},
//   {typeId: '7', name: 'twitch', type:'mention'},
//   {typeId: '8', name: 'youtube', type: 'reel'},
// ];

const platforms = [
  {typeId: '1', name: 'instagram', type: 'ig_post', platform: 'instagram'},
  {typeId: '2', name: 'instagram', type: 'ig_story', platform: 'instagram'},
  {typeId: '3', name: 'instagram', type: 'ig_reel', platform: 'instagram'},
  {typeId: '4', name: 'youtube', type: 'yt_dedicated', platform: 'youtube'},
  {typeId: '5', name: 'youtube', type: 'yt_mention', platform: 'youtube'},
  {typeId: '6', name: 'tiktok', type: 'tiktok_post', platform: 'tiktok'},
  {typeId: '7', name: 'twitch', type:'twitch_mention', platform: 'twitch'},
  {typeId: '8', name: 'youtube', type: 'yt_reel', platform: 'youtube'},
 ];

export const getPlatformByTypeId = (typeId: string) => platforms.find(p => p.typeId == typeId);
export const getPlatformByNameAndType = (platformName: string, type: string) =>
  platforms.find(p => p.name === platformName && p.type === type);
