import { Component, memo } from 'react';
import { Link } from 'react-router-dom';
import Cashout from './cashout.jsx';
import NavigationMenuItem from './navigation-menu-item.jsx';
import Globals from '../global-functions.js';
import CountUp from 'react-countup';
import Cookies from 'js-cookie';
import { isProd, isDev } from '../hooks';

class Header extends Component {
  constructor(props){
    super(props)
    this.state = {
      // user: Globals.getUser(),
      user: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {},
      access_token: Cookies.get('access_token'),
      totalBalance: Cookies.get('totalBalance') ? parseFloat(Cookies.get('totalBalance')) : 0.00,
      totalBalanceStart: Cookies.get('totalBalance') ? parseFloat(Cookies.get('totalBalance')) : 0.00,
      availableBalance: Cookies.get('availableBalance') ? parseFloat(Cookies.get('availableBalance')) : 0.00,
      pendingBalance: Cookies.get('pendingBalance') ? parseFloat(Cookies.get('pendingBalance')) : 0.00,
      balanceLoaded: false,
      bountyCredits: 0,
      bountyCreditLimit: 1,
      cashout: false,
      page: null,
      dashboard: null,
      walmart: null,
      links: null,
      analytics: null,
      merchants: null,
      messages: null,
      vip: null,
      search: null,
      outreach: null,
      tools: null,
      bounties: null,
      refer: null,
      invoices: null,
      rosters: null,
      twitchUser: Cookies.get('twitchdata') ? JSON.parse(Cookies.get('twitchdata')) : {}
    }
  }

  componentDidUpdate(){
    this.updateMenu()
  }

  componentDidMount(){
    this.updateMenu()
    this.getBalance()
    this.getBountyCredits()

    this.getUser().done((user) => {
      if (user){ this.setState({ user: user }) }
      else { location.replace('/') }
    })

    var open_side_nav = false
    $('.nav-sidenav').hover(() => {
      open_side_nav = true
      setTimeout(() => {
        if (open_side_nav){
          $('.page-with-side-navigation').addClass('hover')
          $('.white-logo-container').css('transform', 'translateX(63px)')
        }
      }, 850)
    }, () => {
      open_side_nav = false
      $('.page-with-side-navigation').removeClass('hover')
      $('.white-logo-container').css('transform', 'translateX(0px)')
    })

    $('.nav-sidenav li').click(() => {
      open_side_nav = false
    })

  }

  updateMenu(){
    let href = location.href, page, dashboard, refer, walmart, links, analytics, merchants, messages, tools, account, bounties, vip, search, outreach, invoices, rosters
    let breadcrumb
    if (href.indexOf('dashboard') > -1){ page = 'dashboard'; dashboard = 'active'; breadcrumb = '<span>Dashboard</span>'; }
    else if (href.indexOf('refer') > -1){ page = 'refer'; refer = 'active'; breadcrumb = '<span>Refer</span>' }
    else if (href.indexOf('walmart') > -1){ page = 'walmart'; walmart = 'active'; breadcrumb = '<span>Walmart</span>'; }
    else if (href.indexOf('links') > -1){ page = 'links'; links = 'active'; breadcrumb = '<span>Create Links</span>'; }
    else if (href.indexOf('analytics') > -1){ page = 'analytics'; analytics = 'active'; breadcrumb = '<span>Analytics</span>'; }
    else if (href.indexOf('merchants') > -1){ page = 'merchants'; merchants = 'active'; breadcrumb = '<span>Merchants</span>'; }
    else if (href.indexOf('vip') > -1){ page = 'vip'; vip = 'active'; breadcrumb = '<span>Management</span>'; }
    else if (href.indexOf('search') > -1){ page = 'search'; search = 'active'; breadcrumb = '<span>Search</span>'; }
    else if (href.indexOf('outreach') > -1){ page = 'outreach'; outreach = 'active'; breadcrumb = '<span>Outreach</span>'; }
    else if (href.indexOf('invoices') > -1){ page = 'invoices'; invoices = 'active'; breadcrumb = '<span>Invoices</span>'; }
    else if (href.indexOf('rosters') > -1){ page = 'rosters'; rosters = 'active'; breadcrumb = '<span>Rosters</span>'; }
    else if (href.indexOf('concierge') > -1){ page = 'messages'; messages = 'active'; breadcrumb = '<span>Messages</span>'; }
    else if (href.indexOf('account') > -1){ page = 'account'; account = 'active'; breadcrumb = '<span>Account</span>'; }
    else if (href.indexOf('tools') > -1){ page = 'tools'; tools = 'active'; breadcrumb = '<span>Tools</span>'; }
    else if (href.indexOf('bounties') > -1){ page = 'bounties'; bounties = 'active'; breadcrumb = '<span>Bounties</span>'; }
    if (breadcrumb) { $('.breadcrumbs h3').empty().append(breadcrumb) }

    if (this.state.page !== page) {
      this.setState({ page, dashboard, refer, walmart, links, analytics, merchants, messages, tools, account, bounties, vip, search, outreach, invoices, rosters });
      isProd() && this.notifyPage(page);
    }
  }

  getUser(){ return $.ajax({ method: 'POST', url: '/api/Users/get', data: { extraFields: ['paypalEmail'] } }) }
  notifyPage(page){ return $.ajax({ method: 'POST', url: '/api/Users/notifyPage', data: { user: this.state.user, currentpage: page } }) }
  _getBalance(){ return $.ajax({ method: 'GET', url: '/api/Users/getBalance', data: { userid: this.state.userid } }) }
  _getBountyCredits(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getBountyCredits', data: { userid: this.state.userid } }) }
  showCashout(){ this.setState({ cashout: true }) }
  hideCashout(){ this.setState({ cashout: false }) }
  logout(){ Cookies.remove('userid'); Cookies.remove('userdata'); location.href = '/'; }

  getBalance() {
    this._getBalance().done((response) => {
      Cookies.set('totalBalance', response.total)
      Cookies.set('availableBalance', response.available)
      Cookies.set('pendingBalance', response.pending)

      this.setState({
        totalBalance: response.total,
        availableBalance: response.available,
        pendingBalance: response.pending,
        balanceLoaded: parseFloat(this.state.totalBalance) - parseFloat(response.total) != 0 ? true : false
      })
    })
  }



  getBountyCredits(){
    this._getBountyCredits().done((response) => {
      this.setState({
        bountyCredits: response.credits,
        bountyCreditLimit: response.creditLimit,
        concurrents: response.concurrents,
        twitchUser: response.twitchUser
      })
    })
  }

  render() {

    let includeNavBar = ''
    var profileImg = this.state.twitchUser.imageUrl
    var countUpDiv = ( <span>${Globals.numberWithCommas(parseFloat(this.state.totalBalance).toFixed(2))}</span> )
    var page = this.state.page

    if (this.state.balanceLoaded){
      countUpDiv = (
        <CountUp
          start={parseFloat(this.state.totalBalanceStart).toFixed(2)}
          end={parseFloat(this.state.totalBalance).toFixed(2)}
          duration={1.5}
          delay={0.1}
          separator=","
          decimals={2}
          decimal="."
          prefix="$"
          onEnd={() => { if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); } }}
          onStart={() => { if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); } }}
        >
          {({ countUpRef, start }) => { setTimeout(start, 3000); return ( <span ref={countUpRef}></span> ); }}
        </CountUp>
      )
    }

    if (this.state.access_token || true){
      includeNavBar = (
        <div>
          {/*<nav className={`navbar navbarDefault`} style={{display: 'none'}}>
            <div className="container">
              <ul className="nav navbar-nav navbar-left breadcrumbs">
                <li>
                  <h3></h3>
                </li>
              </ul>
              <ul className="nav navbar-nav navbar-right account-details">
                <li className="li-balance">
                  <div className="row account-balance">
                    <span>${this.state.user.balance}</span>
                  </div>
                  <div className="row withdraw-funds" onClick={this.showCashout.bind(this)}>
                    <span>Withdraw Funds</span>
                  </div>
                </li>
                <li className="top-nav-dropdown dropdown li-account">
                  <a id="basic-nav-dropdown" role="button" className="dropdown-toggle" aria-haspopup="true" aria-expanded="false">
                    <div className="prof-pic">
                      <img src="" />
                    </div>
                    <div className="current-user-info">
                      <span className="nav-ad-account username">{this.state.user.username}</span>
                    </div>
                  </a>
                  <ul role="menu" className="dropdown-menu" aria-labelledby="basic-nav-dropdown">
                    <li>
                      <Link className="footer-link" to="/account">Edit Account</Link>
                    </li>
                    <li>
                      <a href="#" id="logout" className="footer-link logout" onClick={this.logout}>Log out</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>*/}

          <div className={`side-navigation-wrapper`}>
            <div className="side-navigation-content">
              <div style={{ minHeight: '100%' }}>
                <ul id="nav-menu-options" className="nav nav-stacked nav-sidenav">
                  <div className="nav nav-stacked nav-sidenav">
                    <li className="nav-menu-balance nav-menu-earnings">
                      <p>Balance {this.state.totalBalance >= Globals.minimumCashout ? (<span>→ cash out</span>) : ''}</p>
                      <h3 onClick={this.showCashout.bind(this)} style={{cursor: 'pointer'}}>{countUpDiv}</h3>
                      {/*<div className="row withdraw-funds" onClick={this.showCashout.bind(this)}>
                        <span>Cash Out</span>
                      </div>*/}
                    </li>
                    {/*<li className="nav-menu-balance nav-menu-credits">
                      <p>Bounty Credits</p>
                      <h3><span>{this.state.bountyCredits}</span><span>/</span><span>{this.state.bountyCreditLimit}</span></h3>
                    </li>*/}
                    <li className="nav-menu-balance nav-menu-credits">
                      <p>Email Credits</p>
                      <h3><span>100</span><span>/</span><span>100</span></h3>
                    </li>
                    {/*<li className="nav-menu-balance nav-menu-credits">
                      <a className="nav-menu-cashout">Cash Out</a>
                    </li>*/}
                    {/*<li id="dashboard" className={this.state.dashboard}><div className={`select-pillar ${this.state.page}`}><div className="select-pillar-pill"></div></div><Link to='/dashboard'><div className="left-menu-circle"></div><img style={{width: '20px', marginTop: '3px', marginLeft: '6px'}} src={Globals.icon('home', this.state.dashboard ? 'white' : false)} /><div><p>Browse</p></div></Link></li>*/}
                    {/*<li id="bounties" className={this.state.bounties}><Link to='/bounties'><div className="left-menu-circle"></div><img style={{ width: '20px', marginLeft: '6px', marginRight: '33px', marginTop: '3px' }} src={Globals.icon('duplicate', this.state.bounties ? 'white' : false)} alt="Bounties Icon" /><div><p>My Bounties</p></div></Link></li>*/}
                    <NavigationMenuItem id="dashboard" name="Home" iconPack="home" page={page} firstItem={true} updateMenu={this.updateMenu.bind(this)} _this={this} />
                    <NavigationMenuItem id="bounties" name="My Bounties" iconPack="map" page={page} updateMenu={this.updateMenu.bind(this)} _this={this} />
                    <NavigationMenuItem id="search" name="Search" iconPack="search-heart" page={page} updateMenu={this.updateMenu.bind(this)} _this={this} />
                    <NavigationMenuItem id="outreach" name="Outreach" iconPack="comment" page={page} updateMenu={this.updateMenu.bind(this)} _this={this} />
                    {/*<NavigationMenuItem id="invoices" name="Invoices" iconPack="receipt" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                    {/*<NavigationMenuItem id="rosters" name="Roster" iconPack="diamond" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                    {/*<NavigationMenuItem id="refer" name="Refer" iconPack="hand-holding-heart" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                    {/*<li id="dashboard" className={this.state.dashboard} onClick={this.updateMenu.bind(this)}><div className={`select-pillar ${this.state.page}`}><div className="select-pillar-pill"></div></div><Link to='/dashboard'><div className="left-menu-circle"></div><div><img style={{width: '20px', marginTop: '3px', marginLeft: '6px'}} src={Globals.iconPack('home')} /></div><div><p>Home</p></div></Link></li>
                    <li id="bounties" className={this.state.bounties} onClick={this.updateMenu.bind(this)}><Link to='/bounties'><div className="left-menu-circle"></div><div><img style={{ width: '18px', marginLeft: '7px', marginRight: '23px', marginTop: '3px' }} src={Globals.iconPack('map')} alt="Bounties Icon" /></div><div><p>My Bounties</p></div></Link></li>
                    <li id="refer" className={this.state.refer} onClick={this.updateMenu.bind(this)}><Link to='/refer'><div className="left-menu-circle"></div><div><img style={{ width: '18px', marginLeft: '7px', marginRight: '23px', marginTop: '3px' }} src={Globals.iconPack('heart')} alt="Refer Icon" /></div><div><p>Refer</p></div></Link></li>*/}
                    {
                      Globals.isDev &&
                      <>
                        {/*<NavigationMenuItem id="vip" name="Management" iconPack="crown" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                        {/*<NavigationMenuItem id="messages" name="Messages" iconPack="comment" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                        {/*<NavigationMenuItem id="links" name="Links" iconPack="link" page={page} _this={this} updateMenu={this.updateMenu.bind(this)} />*/}
                        {/*<li id="messages" className={this.state.messages} onClick={this.updateMenu.bind(this)}><Link to='/concierge'><div className="left-menu-circle"></div><div><img style={{ width: '18px', marginLeft: '7px', marginRight: '23px', marginTop: '3px' }} src={Globals.iconPack('comment')} alt="Messages Icon" /></div><div><p>Messages</p></div></Link></li>
                        <li id="links" className={this.state.links} onClick={this.updateMenu.bind(this)}><Link to='/links'><div className="left-menu-circle"></div><div><img style={{ width: '18px', marginLeft: '7px', marginRight: '23px', marginTop: '3px' }} src={Globals.iconPack('link')} alt="Links Icon" /></div><div><p>Links</p></div></Link></li>*/}
                      </>
                    }
                    {/*<li id="messages" className={this.state.messages}><Link to='/concierge'><div className="left-menu-circle"></div><img style={{ width: '20px', marginLeft: '6px', marginRight: '33px', marginTop: '3px' }} src={Globals.icon('inbox', this.state.messages ? 'white' : false)} alt="Message Icon" /><div><p>Inbox</p></div></Link></li>*/}
                    {/*<li id="links" className={this.state.links}><Link to='/links'><div className="left-menu-circle"></div><img style={{ width: '20px', marginLeft: '6px', marginRight: '33px' }} src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${this.props.themeMode == 'dark' || this.state.links ? 'white' : 'black'}/link-icon.svg`} /><div><p>Create Links</p></div></Link></li>*/}
                    {/*<li id="walmart" className={this.state.walmart}><Link to='/walmart'><div className="left-menu-circle"></div><img style={{ width: '18px', marginLeft: '7px', marginRight: '33px', marginTop: '5px' }} src={`/images/walmart/walmart-logo-${this.props.themeMode == 'dark' || this.state.walmart ? 'white' : 'black'}.svg`} alt="Walmart Icon" /><div><p>Walmart</p></div></Link></li>*/}
                    {/*<li id="analytics" className={this.state.analytics}><Link to="/analytics"><div className="left-menu-circle"></div><img style={{ width: '20px', marginLeft: '6px', marginRight: '33px', marginTop: '3px' }} src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${this.props.themeMode == 'dark' || this.state.analytics ? 'white' : 'black'}/pulse-icon.svg`} alt="Analytics Icon" /><div><p>Analytics</p></div></Link></li>*/}

                    {/*<li id="refer" className={this.state.refer}><Link to='/refer-tab'><div className="left-menu-circle"></div><img style={{ width: '20px', marginLeft: '6px', marginRight: '33px', marginTop: '3px' }} src={Globals.icon('star-filled', this.state.refer ? 'white' : false)} alt="Refer Icon" /><div><p>Refer</p></div></Link></li>*/}
                    {/*<li id="support" className={this.state.support}><a href='http://support.weavesocial.com' target="_blank"><img src="/images/rocket-launch-2.svg" />Support</a></li>*/}
                  </div>
                </ul>
                <div className="profile-tab-container">
                  <div>
                    <div className="toggle-light-dark-container">
                      <a onClick={this.props.toggleTheme.bind(this.props.mainThis)}>
                        <div className={`toggle-light-dark ${this.props.themeMode == 'dark' ? 'theme-dark' : 'theme-light'}`}>
                          <div className="toggle-light-dark-outer">
                            <div className="toggle-light-dark-inner">
                              <img src={this.props.themeMode == 'dark' ? Globals.iconGeneral('moon') : Globals.iconGeneral('sunny')} />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="profile-tab" onClick={this.updateMenu.bind(this)}>
                      <Link to="/account">
                        <img style={{width: '23px', marginLeft: '6px', marginRight: '33px', marginTop: '-4px'}} src={Globals.iconPack('settings')} />
                        <span style={{fontFamily: 'Barlow', fontWeight: 500}}>Account</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          { this.state.cashout && <Cashout active={this.state.cashout} hideCashout={this.hideCashout} user={this.state.user} this={this} /> }
        </div>
      )
    }

    return (
      <div className="desktop-menu">
        <div className="background-gradient"></div>
        {/*<Link to="/dashboard">
          <div className="white-logo-container">
            <img className="white-logo" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/logo_black.png" />
            <span>beta</span>
          </div>
        </Link>*/}

        <div style={{position: 'fixed', top: '18px', left: '19px'}}>
          {/*<div className="left-menu-circle"></div>*/}
          <Link to="/dashboard">
            {/*<img className="white-logo" style={{ width: '28px', top: '18px', left: '22px' }} src={Globals.themeMode() == 'dark' ? '/images/new-logo-icon-white.svg' : '/images/new-logo-icon-black.svg'} />*/}
            {/*<img className="white-logo" style={{ width: '28px', top: '18px', left: '22px' }} src={Globals.themeMode() == 'dark' ? '/images/logo_black.png' : '/images/logo_black.png'} />*/}
            <img className="white-logo" src={Globals.icon('logo-text')} />
            {/*<img className="white-logo" style={{ width: '28px', top: '18px', left: '22px' }} src={Globals.themeMode() == 'dark' ? '/images/logo2.png' : '/images/logo2.png'} />*/}
            {/*<p className="beta-logo-txt">beta</p>*/}
          </Link>
        </div>

        {includeNavBar}
      </div>
    )
  }
}

export default memo(Header)
