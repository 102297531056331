import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class InvoicePaymentBtn extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  render() {
    var { title, icon, iconPack, iconGeneral, type, iconType } = this.props
    var iconUrl
    if (icon) { iconUrl = Globals.icon(icon) }
    else if (iconPack) { iconUrl = Globals.iconPack(iconPack) }
    else if (iconGeneral) { iconUrl = Globals.iconGeneral(iconGeneral) }

    return (
      <div className="invoice-payment-option">
        <div className="invoice-payment-btn" onClick={this.props.selectPaymentOption.bind(this.props.this, type)}>
          <div className="invoice-payment-btn-inner">
            { iconType == 'icon' &&
              <>
                <div className="invoice-payment-icon-container"><div className="invoice-payment-icon" style={{backgroundImage: `url(${iconUrl})`}}></div></div>
                <div className="invoice-payment-text-container"><div className="invoice-payment-text">{title}</div></div>
              </>
            }
            { iconType == 'logo' && <div className="invoice-payment-logo" style={{backgroundImage: `url(${iconUrl})`}}></div> }
          </div>
        </div>
      </div>
    )
  }
}
