import React from 'react';
import Loader from '../loader.jsx';
import Globals from '../../global-functions.js';
import history from '../history.js';
import InvitationSocials from './invitation-socials.jsx';
import Cookies from 'js-cookie';
import store from 'store2';

export default class Invitation extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      stage: '0',
      loaded: false,
      loaded2: false,
      invitation: {},
      // loginView: 'socials',
      loginView: 'signup',
      email: '',
      password: '',
      loginBtn: 'Log In',
      loginStage: 'email',
      signupLoading: false
    }
  }

  componentWillUnmount(){
    this.setState({ mounted: false })
    // this.unlisten();
  }

  componentDidMount(){
    const { id } = this.props.match.params
    this.getInvitation(id)

    this.setState({
      contentId: id
    })

    window.onpopstate = e => {
      this.setState({
        stage: '0'
      })
    }

    // this.unlisten = history.listen((location, action) => {
    //   if ( !(location.pathname.indexOf('/dashboard')) && this.state.slideOutActive == 'true' ){
    //     this.setState({
    //       stage: '0'
    //     })
    //   }
    //   // else if (location.pathname.indexOf('/dashboard/bounty/') > -1){
    //   //   var id = location.pathname.split('/dashboard/bounty/')[1]
    //   //   this.viewOffer(id)
    //   // }
    // });
  }

  getInvitation(id){
    const elapsed = +new Date, timeout = 2150
    if (Globals.isProd){ this._markInvitationViewed(id).done(); }
    this._getInvitation(id).done((response) => {
      if (response && response.success){
        if (response.hasExpired) { Cookies.get('access_token') ? location.replace('/dashboard') : location.replace('/login') }

        const check = timeout - (+new Date() - elapsed)
        const delay = check > 0 ? check : 0
        this.setState({ invitation: response, redeemed: response.redeemed })
        setTimeout(() => {
          this.setState({ loaded: true })
          setTimeout(() => {
            this.setState({ loaded2: true })
            if (response.redeemed){
              setTimeout(() => { location.replace('/login') }, 1000)
            }
          }, 250)
        }, delay)
      }
      else { location.replace('/') }
    })
  }

  _getInvitation(id){ return $.ajax({ method: 'GET', url: '/api/Invitation/get', data: { id: id } }) }
  _markInvitationViewed(id) { return $.ajax({ method: 'POST', url: '/api/Invitation/markInvitationViewed', data: { id } }) }

  // youtubeLogin(){
  //   this.onClick2()
  // }
  //
  // twitchLogin(){
  //   this.onClick2()
  // }

  // youtubeLogin(){
  //   // Globals.logEvent('click', 'youtubeLogin')
  //   $.ajax({
  //     method: 'POST',
  //     url: '/api/YouTube/googleUrl',
  //     data: {}
  //   })
  //   .done((data) => { location.href = data })
  // }
  //
  // twitchLogin(){
  //   // Globals.logEvent('click', 'youtubeLogin')
  //   $.ajax({
  //     method: 'GET',
  //     url: '/api/Twitch/login',
  //     data: {}
  //   })
  //   .done((data) => { location.href = data })
  // }

  onClick1(){
    if ( !(location.pathname.indexOf('/signup') > -1) ){
      history.push(`/invite/${this.state.contentId}/rewards`);
    }

    this.setState({
      stage: '1'
    })
  }

  onClick2(){
    if ( !(location.pathname.indexOf('/signup') > -1) ){
      history.push(`/invite/${this.state.contentId}/signup`);
    }

    this.setState({
      stage: '2'
    })
  }

  onClick3(){
    if ( !(location.pathname.indexOf('/password') > -1) ){
      history.push(`/invite/${this.state.contentId}/password`);
    }

    this.setState({
      stage: '3'
    })
  }

  // onClick4(){
  //   this.setState({
  //     stage: '2'
  //   })
  //
  //   setTimeout(() => {
  //     this.setState({
  //       stage: '3'
  //     })
  //
  //     setTimeout(() => {
  //       this.setState({
  //         stage: '4'
  //       })
  //
  //       setTimeout(() => {
  //         this.setState({
  //           stage: '5'
  //         })
  //       }, 300)
  //     }, 300)
  //   }, 300)
  // }

  onChange(type, e){
    this.setState({
      [type]: e.target.value,
      emailError: false
    })
  }

  showLogin(type){
    this.setState({
      loginView: type
    })
  }

  signup(e){
    // Globals.logEvent('click', 'login')
    e.preventDefault()
    // $('#login').text('LOGGING IN...')
    var email = this.state.email;
    var password = this.state.password1;

    if (this.state.signupLoading) {
      return
    }

    this.setState({ errorMessage: false, signupLoading: true })
    if (this.state.password1 != this.state.password2) {
      this.setState({ errorMessage: 'Passwords do not match.' })
    }
    else {
      $.ajax({
        method: 'POST',
        url: '/api/Apply/inviteSignup',
        data: { email: email, password: password, contentOrOfferId: this.state.contentId }
      })
        .done((res) => {
          if (res.success){
            var user = res.user

            console.log('response', res, res.user)

            Cookies.set('userid', user.userid)
            Cookies.set('access_token', user.access_token)
            store('access_token', user.access_token)
            store('userid', user.userid)

            location.replace('/dashboard')
            // // Globals.logEvent('click', 'approved')
            // // $('#submit').text('APPLICATION RECEIVED').attr('disabled', true);
            // Cookies.set('onboarding-status', '/onboard-email-sent');
            // Cookies.set('onboarding', true);
            // this.setState({ errorMessage: false })
            // location.href = '/onboard-email-sent'
            // // Globals.logEvent('event', 'loginSuccess')
            // // location.href = '/dashboard'
          }
          else {
            // Globals.logEvent('event', 'loginFailure')
            // alert(res.message)
            // $('#login').text('LOG IN')
            this.setState({ errorMessage: res.message })
          }
        })
        .catch(({ responseJSON: error }) => {
          this.setState({ errorMessage: error.message, signupLoading: false })
        })
    }
  }

  login(e){

    // Globals.logEvent('click', 'login')
    e.preventDefault()
    // $('#login').text('LOGGING IN...')
    var username = this.state.email
    var password = this.state.password

    $.ajax({
      method: 'POST',
      url: '/api/Users/emailLogin',
      data: { username: username, password: password }
    })
      .done((res) => {
        if (res.success){
          var user = res.user
          // Globals.logEvent('event', 'loginSuccess')
          // Cookies.set('userid', user.userid)
          // Cookies.set('access_token', user.access_token)
          // store('access_token', user.access_token)
          // store('userid', user.userid)
          this.setState({ errorMessage: false })
          location.href = '/dashboard'
        }
        else {
          // Globals.logEvent('event', 'loginFailure')
          this.setState({ errorMessage: res.message })
          $('#login').text('LOG IN')
        }
      })
  }

  _verifyEmail(email) {
    return $.ajax({
      method: 'GET',
      url: '/api/Users/verifyEmail',
      data: { email }
    })
  }

  emailContinue(){
    this._verifyEmail(this.state.email)
      .catch(({ responseJSON }) => {
        if (responseJSON && !responseJSON.success) {
          this.setState({
            emailError: true // todo: This assumes that every error we get is related to email.
          })
        } else {
          this.setState({
            errorMessage: 'An unknown error occurred.'
          })
        }
      })
      .done(response => {
        if (response && !response.user) {
          this.setState({
            loginStage: 'password'
          })
        }
      })
  }

  render() {
    const prof = 'https://scontent-lax3-2.cdninstagram.com/v/t51.2885-15/e35/p1080x1080/84458325_143959567088383_2354655717008907136_n.jpg?_nc_ht=scontent-lax3-2.cdninstagram.com&_nc_cat=107&_nc_ohc=GNND_IIL9wMAX_J16Bj&oh=8390a1fdbb1374f4f3279c57ed384f62&oe=5F2FDC2F';
    const banner = 'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/e35/p1080x1080/76801173_2887759657924671_7691038996382313491_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=103&_nc_ohc=0VTFPK8LLqsAX-GMTLe&oh=30260c35c17341d26c5296ff624f3f0e&oe=5F3106D5';
    const stage = this.state.stage;
    const invitation = this.state.invitation;
    const color = invitation ? invitation.oppositeBrandingColor : false;
    const brandingTextColor = invitation ? invitation.brandingTextColor : false;
    const social = invitation ? invitation.social : false;
    const rewards = invitation && typeof invitation.inviteRewards == 'string' ? JSON.parse(invitation.inviteRewards) : invitation.inviteRewards;
    const bgImg = invitation.bannerImageUrl ? invitation.bannerImageUrl : invitation.imageUrl

    return (
      <div className={`invitation ${this.state.loaded ? 'loaded' : ''} ${this.state.loaded2 ? 'loaded2' : ''} loading-stage-${stage} ${brandingTextColor == '#fff' ? 'white-text' : 'black-text'}`} style={{background: `${invitation.brandingColor}`, backgroundImage : `url(${bgImg})`}}>
        {/*<img className="logo-hero" src={Globals.icon('logo-text', 'black')} alt="Weave Logo" />*/}
        {/*<div className="invitation-bg-slide" style={{borderColor: `${invitation.brandingColor}`}}></div>*/}
        {/*<div className="invitation-bg-wave" style={{backgroundImage: `url('/images/login-wave.svg')`}}></div>*/}
        <div className="invitation-bg-darken"></div>
        <div className="invitation-module">
          {
            !this.state.loaded &&
            <Loader active={true} color={brandingTextColor} />
          }
          <div className={`invitation-inner ${this.state.loaded ? 'active' : ''}`}>
            {/*{
              this.state.loaded &&*/}
            <div>
              {
                (stage == '0' ||
                  stage == '1' ||
                  stage == '2') &&
                <div>
                  <div className="game-header">
                    <div className="game-header-banner" style={{backgroundImage: `url(${invitation.bannerImageUrl})`}}>
                      {/*<div className="game-header-banner-darken"></div>*/}
                    </div>
                  </div>
                </div>
              }

              <div className="invitation-meta">
                <div>
                  <div className="row row0">
                    <div className="invitation-brand-img" style={{backgroundImage: `url(${invitation.imageUrl})`}}></div>
                  </div>

                  {
                    this.state.redeemed &&
                    <div className="invitation-meta-inner">
                      <div className="row row0">
                        <h3>{invitation.bountyTitle}</h3>
                        <div className="row row0">
                          <p className="brand-tagline">This invitation has already been redeemed - redirecting to login...</p>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    !this.state.redeemed &&
                    <div className="invitation-meta-inner">
                      <div className="row row0">
                        <h3>{invitation.bountyTitle}</h3>
                        {
                          stage == '0' &&
                          <div className="row row0">
                            <p className="brand-tagline">You have been invited to a partnership with {invitation.bountyTitle}</p>
                          </div>
                        }
                        {
                          stage == '1' &&
                          <div className="row row0">
                            <p className="brand-tagline">Earn multiple rewards through this partnership.</p>
                          </div>
                        }
                        {
                          stage == '2' &&
                          <div className="row row0">
                            {
                              this.state.loginView == 'socials' &&
                              <p className="brand-tagline">Sign in with a social account to continue.</p>
                            }
                            {
                              this.state.loginView == 'signup' &&
                              <p className="brand-tagline">Sign up with your email to continue.</p>
                            }
                            {
                              this.state.loginView == 'login' &&
                              <p className="brand-tagline">Sign in with your Weave account to continue.</p>
                            }
                          </div>
                        }
                        {/*<p className="brand-tagline">{invitation.offerTagline}</p>*/}
                      </div>

                      <hr />

                      {
                        (stage == '0') &&
                        (social || invitation.displayName) &&
                        <div className="invitation-overview">
                          <div className="row row0">
                            {/*<h4>You have been invited by {invitation.bountyTitle}</h4>*/}
                            <div className="row row0 invitation-user">
                              {/*<object data={''} type="image">
                                  <img src={social.imageUrl} style={{borderColor: `${invitation.brandingColor}`}} />
                                </object>*/}
                              <h5 style={{margin: 0}}>{invitation.displayName || (social && (social.displayName || social.username)) || 'Partnership Signup'}</h5>
                            </div>
                            {/*<ul className="invitation-rewards">
                                <li>
                                  <div className="row row0">
                                    <p className="invitation-reward"><span>- </span>Free Product Gifts</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="row row0">
                                    <p className="invitation-reward"><span>- </span>10% of Sales</p>
                                  </div>
                                </li>
                                <li>
                                  <div className="row row0">
                                    <p className="invitation-reward"><span>- </span>15% Fan Discount</p>
                                  </div>
                                </li>
                              </ul>*/}
                          </div>
                        </div>
                      }

                      {
                        (stage == '0') &&
                        <>
                          <hr />
                          <div className="row row0 invitation-btn-container invitation-btn-container-0">
                            <p>{invitation.bountyTitle} uses Weave to make partnerships easy.</p>
                            <button className="invitation-btn" onClick={this.onClick1.bind(this)} style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Continue</button>
                          </div>
                        </>
                      }


                      <div className="invitation-rewards-container">
                        {
                          (stage == '1') &&
                          <div className="row row0">
                            {/*<h4>You'll receive:</h4>*/}
                            <ul className="invitation-rewards">
                              {
                                rewards &&
                                typeof rewards == 'object' &&
                                rewards.map((r) => {
                                  return (
                                    <li>
                                      <div className="row row0">
                                        <p className="invitation-reward"><span>• </span>{r.text}</p>
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        }
                      </div>


                      {
                        (stage == '1') &&
                        <>
                          <hr />
                          <div className="row row0 invitation-btn-container invitation-btn-container-1">
                            <p>Let's get you started!</p>
                            <button className="invitation-btn" onClick={this.onClick2.bind(this)} style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Get Started</button>
                          </div>
                        </>
                      }

                      <div className="invitation-socials">
                        {/*{
                            stage == '1' &&
                            <div className="row row0">
                              <p>Sign in with a social account to continue.</p>
                            </div>
                          }*/}

                        {
                          stage == '2' &&
                          <div>
                            {
                              this.state.loginView == 'socials' &&
                              <InvitationSocials this={this} />
                            }
                            {
                              this.state.loginView == 'login' &&
                              <div style={{position: 'relative'}}>
                                <div className="row row0">
                                  <div className="col-md-12" style={{margin: '0 0 10px 0', padding: '0'}}>
                                    <input className={`dark-input dark-input-large ${this.state.emailError ? ' error' : ''}`}
                                           style={{width: '400px', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '0'}}
                                           value={this.state.email} onChange={this.onChange.bind(this, 'email')}
                                           placeholder="Email..."
                                           type="email" />
                                  </div>
                                  <div className="col-md-12" style={{margin: '10px 0 0 0', padding: '0'}}>
                                    <input className="dark-input dark-input-large" style={{width: '400px', maxWidth: '100%', marginLeft: 'auto', marginRight: 'auto'}} value={this.state.password} onChange={this.onChange.bind(this, 'password')} placeholder="Password..." type="password" />
                                  </div>
                                  <div className="col-md-12" style={{margin: '20px 0', padding: '0'}}>
                                    <button className="invitation-btn" onClick={this.login.bind(this)} style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>{this.state.loginBtn}</button>
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              this.state.loginView == 'signup' &&
                              <div style={{position: 'relative'}}>
                                <div className="row row0">
                                  <div className="row row0">
                                    <div className="col-md-12" style={{margin: '0 0 10px', padding: '0'}}>
                                      <input className={`dark-input dark-input-large ${this.state.emailError ? ' error' : ''}`} style={{width: '400px', maxWidth: '100%', marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}} value={this.state.email} onChange={this.onChange.bind(this, 'email')} placeholder="Email..." />
                                    </div>
                                  </div>

                                  {
                                    this.state.loginStage == 'email' &&
                                    <div className="row row0">
                                      {/*<div className="col-md-12" style={{margin: '10px 0', padding: '0'}}>
                                          <input className="dark-input dark-input-large" style={{}} placeholder="Password..." />
                                        </div>*/}
                                      <div className="col-md-12" style={{margin: '10px 0', padding: '0'}}>
                                        <div className="row row0 invitation-btn-container">
                                          <button className="invitation-btn" onClick={this.emailContinue.bind(this)} style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Continue</button>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  <div className={`row row0 signup-password ${this.state.loginStage == 'password' ? 'loaded' : ''}`}>
                                    <div className="col-md-12" style={{margin: '0 0 10px', padding: '0'}}>
                                      <input className="dark-input dark-input-large" style={{width: '400px', maxWidth: '100%', marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}} value={this.state.password1} onChange={this.onChange.bind(this, 'password1')} placeholder="Password..." type="password" />
                                    </div>
                                    <div className="col-md-12" style={{margin: '0 0 10px', padding: '0'}}>
                                      <input className="dark-input dark-input-large" style={{width: '400px', maxWidth: '100%', marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}} value={this.state.password2} onChange={this.onChange.bind(this, 'password2')} placeholder="Verify Password..." type="password" />
                                    </div>
                                    <div className={`col-md-12  ${ this.state.signupLoading? 'disabled': '' }`}  style={{margin: '20px 0 0 0', padding: '0'}}>
                                      <button className="invitation-btn" onClick={this.signup.bind(this)} style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>
                                        { this.state.signupLoading? 'Signing Up....': 'Sign Up' }
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {
                              this.state.errorMessage &&
                              <div className="row row0" style={{marginTop: '15px'}}>
                                <div className="login-separator" style={{letterSpacing: 'initial', cursor: 'pointer', color: '#CF6679'}}>{this.state.errorMessage}</div>
                              </div>
                            }

                            {this.state.emailError &&
                            <div className={'email-error'}>
                              <p>The email <b>{this.state.email}</b> is already used by another account.</p>
                              <p> Please use another email address to sign up.</p>
                            </div>
                            }

                            {
                              (this.state.loginView == 'socials' || this.state.loginView == 'signup') &&
                              <div className="row row0" style={{marginTop: '15px'}}>
                                <span className="login-separator" style={{letterSpacing: 'initial'}}>By signing up, you agree to our <a href="/terms-of-service" target="_blank"><b>terms of service</b></a>.</span>
                              </div>
                            }
                            {/*{
                                this.state.loginView == 'login' &&
                                <div className="row row0" style={{marginTop: '15px'}}>
                                  <span className="login-separator" style={{letterSpacing: 'initial', cursor: 'pointer'}} onClick={this.showLogin.bind(this, 'signup')}>I don't have an account, sign up here.</span>
                                </div>
                              }
                              {
                                this.state.loginView == 'signup' &&
                                <div className="row row0" style={{marginTop: '15px'}}>
                                  <span className="login-separator" style={{letterSpacing: 'initial', cursor: 'pointer'}} onClick={this.showLogin.bind(this, 'login')}>I have an account, log in here.</span>
                                </div>
                              }*/}

                            <div className="row row0">
                              {/*<h4 style={{margin: '15px 0'}}>OR</h4>*/}
                              <hr />
                              {
                                this.state.loginView == 'socials' &&
                                <p>
                                  <a className="invitation-email-signup" onClick={this.showLogin.bind(this, 'signup')}>or continue with email</a>
                                </p>
                              }

                              {
                                this.state.loginView == 'login' &&
                                <div className="row row0" style={{marginTop: '15px'}}>
                                  <span className="login-separator" style={{letterSpacing: 'initial', cursor: 'pointer'}} onClick={this.showLogin.bind(this, 'signup')}>I don't have an account, sign up here.</span>
                                </div>
                              }
                              {
                                this.state.loginView == 'signup' &&
                                <div className="row row0" style={{marginTop: '15px'}}>
                                  <span className="login-separator" style={{letterSpacing: 'initial', cursor: 'pointer'}} onClick={this.showLogin.bind(this, 'login')}>I have an account, log in here.</span>
                                </div>
                              }

                              {/*{
                                  (this.state.loginView == 'signup' ||
                                  this.state.loginView == 'login') &&
                                  <p>
                                    <a className="invitation-email-signup" onClick={this.showLogin.bind(this, 'socials')}>or connect a social account</a>
                                  </p>
                                }*/}

                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>

                <p style={{marginTop: '25px', fontSize: '14px'}}>
                  <a href="https://weavesocial.com" target="_blank">Powered by <b>Weave</b></a>
                </p>
              </div>
            </div>
            {/*}*/}
          </div>
        </div>

        {/*<img className="signup-bg-wave" src="/images/homepage/signup-bg-wave.svg" />*/}

        {/*<footer className="footer">
          <div className="container">
            <div className="logo-container footer-left">
              <img className="logo" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
            </div>
            <div className="footer-right">
              <a href="https://medium.com/weavesocial" target="_blank">Blog</a>
              <a href="mailto:talent@weavesocial.com?Subject=Hello" target="_top">Contact Us</a>
              <a href="/terms-of-service" target="_blank">Terms of Use</a>
              <a href="/privacy" target="_blank">Privacy Policy</a>
              <a>Weave, Inc © 2022</a>
            </div>
          </div>
        </footer>*/}
      </div>
    )
  }
}
