import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
var client = require('braintree-web/client');
var hostedFields = require('braintree-web/hosted-fields');
var dataCollector = require('braintree-web/data-collector');
const BRAINTREE_AUTH = 'Basic aDN3emo5czhoNzh0M2hoZDphZjMxZTYxMTU0OWI3NmEyZDg5YTFmNjE5MTIyNjk2OA=='
const BRAINTREE_TOKEN = 'sandbox_w39z5jkn_7j9b3ghzq253gtgy'

export default class InvoiceBraintreeCard extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      loaded: false
    }
  }

  componentDidMount(){
    this.initializeBraintree()
  }

  submitPayment(invoiceId){ this._submitPayment(invoiceId).done((response) => { this.setState({ ...response, invoiceLoaded: true }) }) }
  _submitPayment(invoiceId){ return $.ajax({ method: 'POST', url: '/api/Braintree/submitPayment', data: { id: invoiceId } }) }

  initializeBraintree(){
    var form = document.querySelector('#my-sample-form');
    var submit = document.querySelector('input[type="submit"]');

    client.create({ authorization: BRAINTREE_TOKEN }, (err, clientInstance) => {
      dataCollector.create({ client: clientInstance }, (err, dataCollectorInstance) => {
        if (err) { return; }
        var deviceData = dataCollectorInstance.deviceData;
        hostedFields.create({
          client: clientInstance,
          styles: {
            'input': { 'font-size': '14px', height: '42px' },
            'input.invalid': { 'color': 'red' },
            'input.valid': { 'color': 'green' }
          },
          fields: {
            number: { container: '#card-number', placeholder: '4111 1111 1111 1111' },
            cvv: { container: '#cvv', placeholder: '123' },
            expirationDate: { container: '#expiration-date', placeholder: '10/2022' }
          }
        }, (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) { console.error(hostedFieldsErr); return; }
          this.setState({ loaded: true })
          submit.removeAttribute('disabled');
          form.addEventListener('submit', (event) => {
            event.preventDefault();
            hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
              if (tokenizeErr) { console.error(tokenizeErr); return; }
              console.log('Got a nonce: ' + payload.nonce);
            });
          }, false);
        });
      });
    });
  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    var { invoice } = this.props

    return (
      <div className="invoice-braintree">
        <div className="row">
          <form action="/api/Braintree/payment" id="my-sample-form" method="post" style={{display: `${this.state.loaded ? 'block' : 'none'}`}}>
            <label htmlFor="card-number">Card Number</label>
            <div id="card-number" className="dark-input" style={{maxHeight: '42px'}}></div>

            <label htmlFor="cvv">CVV</label>
            <div id="cvv" className="dark-input" style={{maxHeight: '42px'}}></div>

            <label htmlFor="expiration-date">Expiration Date</label>
            <div id="expiration-date" className="dark-input" style={{maxHeight: '42px'}}></div>

            <input className="btn-action btn-primary" type="submit" value="Pay" disabled />
          </form>
        </div>
      </div>
    )
  }
}
