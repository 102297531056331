import { memo } from 'react'

const MessageTemplateStats = ((props) => {
  var { dripStageStats, tab, chooseTab, _this } = props,
      { sent, opened, clicked, responded, failed } = dripStageStats

  return (
    <>
      <div className="module message-template-stats">
        <div className="row row0 message-template-stats-inner">
          <div className={`col-md-2 col-xs-4 ${tab == 'message' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'message')}>
            <label className="sub-text">Message</label>
            <h3>@</h3>
          </div>
          <div className={`col-md-2 col-xs-4 ${tab == 'sent' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'sent')}>
            <label className="sub-text">Sent</label>
            <h3>{sent || 0}</h3>
          </div>
          <div className={`col-md-2 col-xs-4 ${tab == 'opened' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'opened')}>
            <label className="sub-text">Opened</label>
            <h3 style={{color: '#358f4b'}}>{Number(sent) > 0 ? Math.round((opened / sent) * 100) : 0}%</h3>
          </div>
          <div className={`col-md-2 col-xs-4 ${tab == 'clicked' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'clicked')}>
            <label className="sub-text">Clicked</label>
            <h3 style={{color: '#c44db5'}}>{Number(sent) > 0 ? Math.round((clicked / sent) * 100) : 0}%</h3>
          </div>
          <div className={`col-md-2 col-xs-4 ${tab == 'replied' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'replied')}>
            <label className="sub-text">Replied</label>
            <h3 style={{color: '#a86b00'}}>{Number(sent) > 0 ? Math.round((responded / sent) * 100) : 0}%</h3>
          </div>
          <div className={`col-md-2 col-xs-4 ${tab == 'failed' ? 'active' : ''}`} onClick={chooseTab.bind(_this, 'failed')}>
            <label className="sub-text">Failed</label>
            <h3>{Number(sent) > 0 ? Math.round((failed / sent) * 100) : 0}%</h3>
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(MessageTemplateStats);
