import React from 'react';
import Globals from '../global-functions.js';

export default class ConversationPart extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  render(){

    var message = this.props.message
    // var detected = ''
    // if (message.author_type == 'user'){
    //   if (detect.type == 'declined') { detected = <b style={{color: '#f58a82'}}>• detected negative response</b> }
    //   if (detect.type == 'paid') { detected = <b style={{color: '#ffa500'}}>• detected paid response</b> }
    //   if (detect.type == 'accepted') { detected = <b style={{color: '#2ac30e'}}>• detected positive response</b> }
    // }

    return (
      <div key={Math.floor(Math.random() * 9999999)}>
        <div className="row">
          <div className={`chat-bubble-container ${message.author_type}`}>
            {
              message.author_type == 'user' &&
              <img className="chat-profile" src={this.props.influencer.imageUrl} />
            }
            <div className="chat-bubble">
              <div className="" dangerouslySetInnerHTML={{__html: message.message}}></div>
            </div>
            <div className="chat-timestamp">
              <span>{Globals.calculateTimeAbbr(message.created_at)}</span>
            </div>
          </div>
        </div>
      </div>
    )

  }

}
