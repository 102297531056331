import React from 'react';
import Globals from '../../global-functions.js';

export default class OnboardEmailSent extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      email: false
    }
  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section" style={{width: '500px'}}>
            <h3 style={{marginBottom: '25px'}}>Verify your email.</h3>
            <div className="set-email-container">
              <p style={{fontSize: '18px'}}>Please click on the verification link we just sent to your email to continue.</p>
              <p style={{fontSize: '14px'}}>{"Didn't get the email? Make sure to check all your mail and spam folders."}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
