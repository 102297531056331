import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoginType } from '../../redux/models/auth.models';
import { authActionCreators } from '../../redux/actions/auth.actions';
import { useAuthSelector } from '../../redux/selectors/auth.selectors';
import { Dispatch } from 'redux';
import Globals from '../../global-functions.js';

const login = (dispatch: Dispatch<any>, formEvent: FormEvent<HTMLFormElement>) => {
  const formData = new FormData(formEvent.target as HTMLFormElement);
  formEvent.preventDefault();

  dispatch(authActionCreators.createLoginAction({
    loginType: LoginType.Email,
    username: formData.get('email') as string,
    password: formData.get('password') as string
  }));
}


export const LoginComponent: React.FC<any> = (
  props,
): JSX.Element => {
  const dispatch = useDispatch();
  const auth = useAuthSelector();

  // todo: we should add redux-form or something similar here, but for now this will do:
  const onPasswordChange = () => auth.hasError && dispatch(authActionCreators.resetLoginAction())

  return (
    <div className="login-page">
      {/*<div className="login-bg-wave" style={{backgroundImage: `url('/images/login-wave.svg')`}}></div>*/}
      <div className="login-box">
        <div className="login-box-inner">
          <header>
            <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo"/>
          </header>
          <div className="login-padding">
            <form className={auth.hasError? 'login-error': ''} onSubmit={(ev) => login(dispatch, ev)}>
              <div className="input-text-wrapper email">
                <input required name="email" className="input-text" placeholder="Email or Username"/>
              </div>
              <div className="input-text-wrapper password">
                <input required onInput={onPasswordChange} name="password" className="input-text" placeholder="Password" type="password"/>
              </div>
              <button id="login" type="submit" className="btn-submit" style={{marginTop: '15px'}}>Log in</button>
            </form>
            <p className="switch-boxes">Still no account? Please <Link to="/signup"><b>sign up!</b></Link></p>
            <p className="switch-boxes">Forgot password? <Link to="/forgot"><b>Reset here.</b></Link></p>
          </div>
        </div>
      </div>
      {/*<img className="signup-bg-wave" src="/images/homepage/signup-bg-wave.svg" />*/}
    </div>
  )
}
