import React, { useState } from 'react';
import './invite-deliverables.scss';
import { ObjectiveElement } from './objective-element/objective-element';
import { InvitePanel } from '../invite-panel/invite-panel';
import { Objective } from '../../../redux/models/content.models';
import Globals from '../../../global-functions';

export interface InviteDeliverablesProps {
  objectives: Objective[];
  mode?: 'preview' | 'full';
  content?: object;
  campaign?: object;
  reviewType?: string;
}

export interface InviteDeliverablesPreviewProps {
  objectives: Objective[];
  content?: object;
  campaign?: object;
  reviewType?: string;
}



// Rewards:

// {/*<a onClick={toggleShowMore}> {showMore ? 'Show Less': 'Show More'}</a>*/}
//
// {((invitation?.inviteRewards) || []).map((reward: InviteReward) =>
//   <div key={reward.id} className="reward-row">
//     <div className={`green check`}>
//       <img src={Globals.icon('ok', 'white')}/>
//     </div>
//     <p key={reward.id}>{reward.text}</p>
//   </div>
// )}
// const GuidelinePanel = ({ title, showIf = true, children, hr = true}: any) =>
//   showIf?
//     <div className="guideline-panel">
//       <h3> { title } </h3>
//       {children}
//       { hr && <hr /> }
//     </div>:
//     <></>
//
//
//
let todo = false

export const InviteDeliverablesPreview: React.FC<InviteDeliverablesPreviewProps> = ({objectives, content, campaign, reviewType}) => {

  const totalObjectives = objectives.map(o => parseInt(o.value) || 0).reduce((a, b) => a + b, 0);

  return (
    <div className="invite-deliverables-preview">
      {/*<div className="sub-text">
        {totalObjectives == 1 ? 'One' : totalObjectives}{' '} content deliverable
        {totalObjectives > 1 && 's'}
      </div>*/}
      {
        (!objectives || objectives.length == 0) &&
        <p className="sub-text" style={{fontSize: '14px'}}>No content deliverables specified yet</p>
      }
      {objectives && objectives.map((objective, i) => (
        <ObjectiveElement key={i} objective={objective} reviewType={reviewType} mode={'preview'} ></ObjectiveElement>
      ))}
    </div>
  );
}

export const InviteDeliverables: React.FC<InviteDeliverablesProps> = ({objectives = [], mode = 'full', content, campaign, reviewType }) => {
  {/*if (!objectives) { return <></> }*/}

  return (
    <div className="invite-deliverables">
      {
        mode === 'full' ?
          <div className="invite-deliverables-full">
            {/*<div className> </div>*/}

            {objectives.map((objective, i) =>
              <div>
                {/*<div className="invite-deliverables-preview"></div>*/}
                <InvitePanel
                  key={i}
                  title={i === 0? 'Agreed Content': ''}
                  topHr={i === 0}
                  linkTo={'deliverables/' + (objective.typeId || Number(objective.value)) }
                >
                  <ObjectiveElement
                    objective={objective}
                    reviewType={reviewType}
                    contentReview={objective.contentReview}
                    postedLive={!!objective.postedLive} />
                </InvitePanel>
              </div>
            )}

            {objectives.length == 0 &&
              <div style={{marginBottom: '50px', textAlign: 'center'}}>
                <h3>No deliverables set</h3>
                <p className="sub-text">Check back here when your content deliverables are added</p>
              </div>
            }

            {/*todo WORK IN PROGRESS*/}
            {/*<InvitePanel*/}
            {/*  key={'custom'}*/}
            {/*  topHr={false}*/}
            {/*  linkTo={'deliverables/custom'}*/}
            {/*>*/}
            {/*  <div className="custom-content-btn" onClick={() => {}}>*/}
            {/*    <div className="add-img-container">*/}
            {/*      <img src={Globals.icon('plus')} />*/}
            {/*    </div>*/}
            {/*    <b>Add custom content for review</b>*/}
            {/*  </div>*/}
            {/*</InvitePanel>*/}

            <div style={{padding: '0 25px'}}>
              <h3 className="title">Posting Guidelines</h3>
              <p className="sub-text">
                { (!reviewType || reviewType == 'none') &&
                  <span>Once you post your content to your socials, please upload the link to your social post here. Please make sure to follow all requirements in the <b>Guidelines Brief</b>.</span>
                }
                { (reviewType == 'all' || reviewType == 'paid') &&
                  <span>The brand requires you to upload your content for approval before posting it live to your socials. Once you upload the content, you will be notified once the brand has approved for posting or asks for edits. Please make sure to follow all requirements in the <b>Guidelines Brief</b>.</span>
                }
              </p>
            </div>
          </div>
          : <InviteDeliverablesPreview objectives={objectives} reviewType={reviewType}></InviteDeliverablesPreview>
      }
    </div>
  );
}
