import React from 'react';

export default class SlideOutIncentive extends React.Component {

  render() {
    return (
      <div className="slide-out-incentives-box">
        <h2>{this.props.value}</h2>
        <p className="sub-text">{this.props.title}</p>
      </div>
    )
  }
}
