import React from 'react';
import Globals from '../global-functions.js';
import AccountReferrals from './account-referrals.jsx';
import store from 'store2';
import Cookies from 'js-cookie';
import { IsDev } from './isDev';

export default class Refer extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      user: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {},
      userid: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')).userid : '',
      platforms: {
        steam: false,
        xbox: false,
        playstation: false,
        nintendo: false,
        ios: false,
        android: false
      },
      userdata: false,
      firstName: '',
      lastName: '',
      email: '',
      paypalEmail: '',
      gender: '',
      birthday: '',
      updateSettingsBtn: 'Save',
      updateShippingBtn: 'Save',
      socials: []
    }
  }

  componentDidMount(){
    if (!this.state.user.internal && Globals.isProd){ window.Intercom("update"); }

    if (location.hash && location.hash.length > 3) {
      setTimeout(() => { location.href = location.hash }, 50)
    }
  }

  render() {

    var user = this.state.userdata, platforms = this.state.platforms

    return (
      <div>
        <div className="account-settings" style={{maxWidth: '700px', margin: '0', padding: '15px'}}>

          <AccountReferrals
            this={this}
            user={user}
          />
          <hr />

        </div>
      </div>
    )
  }
}
