import React from 'react';
import TaxInfoHeader from './taxinfo-header.jsx'
import TaxInfoInput from './taxinfo-input.jsx'

export default class TaxInfoAddress extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var  { inputDetails } = this.props
    var { address, address2, city, state, zip } = inputDetails

    return (
      <div>
        <TaxInfoHeader
          title={`What's your business address?`}
          description={`This can be the office your work at or you home address`}
        />
        <div>
          <div className="row">
            <TaxInfoInput label="Address" type="address" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
            <TaxInfoInput label="Address Line 2 (Optional)" type="address2" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
          </div>
          <div className="row">
            <TaxInfoInput label="City" type="city" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
            <TaxInfoInput label="State" type="state" size="col-xs-3" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
            <TaxInfoInput label="Zip Code" type="zip" size="col-xs-3" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
          </div>
        </div>
      </div>
    )
  }
}
