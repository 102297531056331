import React from 'react';

export default class YouTubeTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for YouTube</h4>
        <p>Using Weave on YouTube gives you a way to link to all your featured products and stores in your YouTube description. Here’s some helpful tips for posting on YouTube:</p>
        <ol>
          <li>include Weave links to all featured products and stores</li>
          <li>let your viewers know by doing a video call out that the product or store links will be included in your description</li>
          <li>pin a comment at the top of your comments section with Weave links to products or letting your viewers know that they can find the links in your description</li>
        </ol>
      </div>
    )
  }
}
