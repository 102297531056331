import { createReducer } from 'typesafe-actions';
import { GetBountyProductsFailed, GetBountyProductsResponse } from '../models/bounty.models';
import { Nullable } from '../../utils/utils';
import { bountyActionCreators, BountyActions } from '../actions/bounty.actions';

type BountyProductsState = {
  data: GetBountyProductsResponse;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetBountyProductsFailed>;
};

const initialState: BountyProductsState = Object.freeze({
  data: [],
  hasError: false,
  loading: false,
  error: null
});

export const bountyProductsReducer = createReducer<BountyProductsState, BountyActions>(
  initialState,
).handleAction(bountyActionCreators.getBountyProductsAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    bountyActionCreators.getBountyProductsSuccessAction,
    (state, action) => ({
      ...initialState,
      data: action.payload || [],
    }),
  )
  .handleAction(bountyActionCreators.getBountyProductsFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

