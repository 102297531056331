import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../global-functions.js';
import Loader from './loader.jsx';
import SlideOut from './slide-out.jsx';
import CampaignStream from './campaign-stream.jsx';
import Cookies from 'js-cookie';

export default class Bounties extends React.Component {
  constructor(props) {
    super(props);
    var user = Globals.getUser() ? Globals.getUser() : {};

    this.state = {
      mounted: false,
      user: user,
      userid: Cookies.get('userid'),
      campaigns_pending: [],
      campaigns_active: [],
      campaigns_past: [],
      brand_campaigns: [],
      slideOutActive: false,
      expandedCampaign: false,
      bountyDetailsExpanded: false,
      bountyCategorySelected: 'all',
      page: 0,
      loading: true,
    };
  }

  componentDidMount() {
    // this.getBrandCampaigns()
    this.getActiveBounties();
  }

  getBrandCampaigns() {
    this._getBrandCampaigns().done((response) => {
      this.setState({
        campaigns_active: response.campaigns_active,
        campaigns_past: response.campaigns_past,
        campaigns_pending: response.campaigns_pending
      });
    });
  }

  _getBrandCampaigns() {
    return $.ajax({
      method: 'GET',
      url: '/api/Campaign/getBrandCampaigns',
      data: {
        userid: this.state.userid,
        bountyCategorySelected: this.state.bountyCategorySelected,
      },
    });
  }

  getActiveBounties() {
    this._getActiveBounties().done((response) => {
      this.setState({
        campaigns_pending: response.campaigns_pending,
        campaigns_active: response.campaigns_active,
        campaigns_past: response.campaigns_past,
        loading: false,
      });
    });
  }

  _getActiveBounties() {
    return $.ajax({
      method: 'GET',
      url: '/api/Campaign/getActiveBounties',
      data: { userid: this.state.userid },
    });
  }

  // _getCompletedBounties() {
  //   return $.ajax({
  //     method: 'GET',
  //     url: '/api/Twitch/getCompletedBounties',
  //     data: { userid: this.state.userid },
  //   });
  // }

  viewOffer(campaign) {
    $('html').addClass('freeze');
    this.setState({
      expandedCampaign: campaign,
      slideOutActive: 'true',
    });
  }

  requestCampaign(params) {
    this.toggleBrandPopup(params, true);
    // this._requestCampaign(params).done((response) => {
    //   this.state.brand_campaigns[params.num].pending = true
    //   this.setState({
    //     brand_campaigns: this.state.brand_campaigns
    //   })
    // })
  }

  _requestCampaign(params) {
    return $.ajax({
      method: 'POST',
      url: '/api/Campaign/requestCampaign',
      data: {
        userid: this.state.userid,
        user: this.state.user,
        name: params.name,
        contentId: params.contentId,
      },
    });
  }

  _deleteCampaign(contentId) {
    return $.ajax({
      method: 'POST',
      url: '/api/Campaign/deleteCampaign',
      data: { userid: this.state.userid, contentId: contentId },
    });
  }

  deleteCampaign(_this, contentId) {
    this._deleteCampaign(contentId).done((response) => {
      this.closeSlideOut(this);
      // this.getBountyCredits()
      this.getActiveBounties();
      // this.getBrandCampaigns()
    });
  }

  _deleteRequestCampaign(params) {
    return $.ajax({
      method: 'POST',
      url: '/api/Campaign/deleteRequestCampaign',
      data: { userid: this.state.userid, name: params.name },
    });
  }

  closeSlideOut(_this) {
    $('html').removeClass('freeze');
    _this.setState({
      slideOutActive: false,
    });
    setTimeout(() => {
      _this.setState({
        expandedCampaign: false,
        slideOutType: false,
      });
    }, 500);
  }

  chooseCategory(type) {
    this.setState({
      bountyCategorySelected: type,
    });
    setTimeout(() => {
      this.getBrandCampaigns();
    }, 25);
  }

  deleteRequestCampaign(params) {
    this._deleteRequestCampaign(params).done((response) => {
      // this.state.brand_campaigns[params.num].pending = false;
      this.setState({
        campaigns_active: response.campaigns_active,
        campaigns_past: response.campaigns_past,
        campaigns_pending: response.campaigns_pending
      });
    });
  }

  addVod(campaign, link) {
    if (Globals.isURL(link)) {
      this._addVod(campaign.content.id, link).done((response) => {
        if (!campaign.vodLinks) {
          campaign.vodLinks = [];
        }
        campaign.vodLinks.push(link);
      });
    } else {
      alert('Not a valid link!');
    }
  }

  _addVod(id, link) {
    return $.ajax({
      method: 'POST',
      url: '/api/Campaign/addVod',
      data: { userid: this.state.userid, id: id, link: link },
    });
  }

  render() {
    var importantTiles = [],
      pastTiles = [];

    this.state.campaigns_pending.forEach((campaign) => {
      importantTiles.push(
        <CampaignStream
          this={this}
          colSize={'col-md-4 col-sm-6 col-xs-12'}
          twitchLive={campaign}
          campaign={campaign}
          twitchUser={this.state.twitchUser}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          viewOffer={this.viewOffer}
          getActiveBounties={this.getActiveBounties.bind(this)}
        />
      );
    });

    this.state.campaigns_active.forEach((campaign) => {
      importantTiles.push(
        <CampaignStream
          this={this}
          colSize={'col-md-4 col-sm-6 col-xs-12'}
          twitchLive={campaign}
          campaign={campaign}
          twitchUser={this.state.twitchUser}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          viewOffer={this.viewOffer}
          getActiveBounties={this.getActiveBounties.bind(this)}
        />
      );
    });

    this.state.campaigns_past.forEach((campaign) => {
      pastTiles.push(
        <CampaignStream
          this={this}
          colSize={'col-md-4 col-sm-6 col-xs-12'}
          twitchLive={campaign}
          campaign={campaign}
          twitchUser={this.state.twitchUser}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          viewOffer={this.viewOffer}
          getActiveBounties={this.getActiveBounties.bind(this)}
        />
      );
    });

    var importantActions = [
      {
        title: 'Redeem your first bounty',
        subtitle: 'Browse available bounties and redeem one to get started!',
        img: 'star-filled',
        class: 'for-you-socials',
      },
      {
        title: 'Add shipping address',
        subtitle: 'Add an address so our brands can ship you free products.',
        img: 'star-filled',
        class: 'for-you-address',
      },
      {
        title: 'Connect payment card for free meals',
        subtitle:
          'Connect at least 1 credit/debit card to be able to get reimbursed for meals you redeem.',
        img: 'star-filled',
        class: 'for-you-paymentcard',
      },
    ];

    var action = importantActions[0];

    return (
      <div>
        <h3
          style={{
            margin: '20px 0 32px 0',
            padding: '0 15px',
            fontSize: '32px',
            textTransform: 'initial',
          }}
        >
          My Bounties
        </h3>

        <div className="row row0 for-you-container">
          {this.state.loading && <Loader active="true" />}
          {!this.state.loading && (
            <>
              {importantTiles}

              {importantTiles.length == 0 && (
                <div>
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <Link to="/dashboard">
                      <div className={`for-you-tile ${action.class}`}>
                        <div className="for-you-header">
                          <img src={Globals.icon(action.img, 'white')} />
                        </div>
                        <div className="for-you-meta">
                          <h4>{action.title}</h4>
                          <p className="sub-text">{action.subtitle}</p>
                        </div>
                        <div className="for-you-footer">
                          <button>Let's go</button>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <h3
          style={{
            margin: '20px 0 32px 0',
            padding: '0px 15px',
            fontSize: '32px',
            textTransform: 'initial',
          }}
        >
          Completed Bounties
        </h3>
        <div className="row row0 for-you-container">{pastTiles}</div>

        <SlideOut
          active={this.state.slideOutActive}
          user={this.state.user}
          campaign={this.state.expandedCampaign}
          closeSlideOut={this.closeSlideOut}
          this={this}
          requestCampaign={this.requestCampaign}
          deleteRequestCampaign={this.deleteRequestCampaign}
          bountyActivated={this.bountyActivated}
          bountyCredits={this.state.bountyCredits}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          addVod={this.addVod.bind(this)}
        />
      </div>
    );
  }
}
