import { Component, memo } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Canvas from './canvas.jsx';
import ContentTile from './content-tile.jsx';
import Globals from '../global-functions.js';
import Loader from './loader.jsx';
// import CampaignPast from './campaign-past.jsx'
import BrandTile from './brand-tile.jsx';
import SlideOut from './slide-out.jsx';
import CampaignStream from './campaign-stream.jsx';
import history from './history.js';
import Cookies from 'js-cookie';
import { isDev } from '../hooks';
import { IsDev } from './isDev';

class Dashboard extends Component {

  constructor(props){
    super(props)
    var user = Globals.getUser() ? Globals.getUser() : {}
    this.state = {
      mounted: false,
      page: 0,
      chartData: false,
      content: false,
      user: user,
      userid: Cookies.get('userid'),
      totalBalance: Cookies.get('totalBalance') ? parseFloat(Cookies.get('totalBalance')) : 0.00,
      totalBalanceStart: Cookies.get('totalBalance') ? parseFloat(Cookies.get('totalBalance')) : 0.00,
      availableBalance: Cookies.get('availableBalance') ? parseFloat(Cookies.get('availableBalance')) : 0.00,
      pendingBalance: Cookies.get('pendingBalance') ? parseFloat(Cookies.get('pendingBalance')) : 0.00,
      balanceLoaded: false,
      clicks: 0,
      cashout: false,
      autoVideos: [],
      recommendedBrands: false,
      tracking_status: {},
      campaigns_pending: [],
      campaigns_active: [],
      campaigns_past: [],
      brand_campaigns: [],
      brandPopup: null,
      brandPopupOpen: false,
      slideOutActive: false,
      expandedCampaign: false,
      twitchLive: {},
      updateStreamInterval: false,
      liveStreamElapsed: '00:00:00',
      recentStreams: [],
      bountyDetailsExpanded: false,
      bountyCredits: 0,
      bountyCreditLimit: 1,
      concurrents: 0,
      achievements: false,
      twitchUser: false,
      bountyCategorySelected: Cookies.get('bountyCategorySelected') ? Cookies.get('bountyCategorySelected') : 'all',
      cardType: 'preview',
      stripeCard: false,
      loading: true,
      loadingBrands: true,
      loadingActive: true,
      bountySearchValue: '',
      bountySearchActive: false
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }


  componentWillUnmount(){
    this.setState({ mounted: false })
    this.unlisten();
  }

  componentDidMount() {
    Globals.pageView(window.location.pathname + window.location.search)
    if (window.hj) { hj('identify', this.state.userid, this.state.user); }
    if (!this.state.user.internal && Globals.isProd){ window.Intercom("update"); }
    this.setState({ mounted: true })

    if (location.pathname.indexOf('/dashboard/bounty/') > -1){
      var id = location.pathname.split('/dashboard/bounty/')[1]
      this.viewOffer(id)
    }

    this.unlisten = history.listen((location, action) => {
      if (location.pathname == '/dashboard' && this.state.slideOutActive == 'true'){
        this.closeSlideOut()
      }
      else if (location.pathname.indexOf('/dashboard/bounty/') > -1){
        var id = location.pathname.split('/dashboard/bounty/')[1]
        this.viewOffer(id)
      }
    });

    // this.getContent().then((content) => {
    //   if (this.state.mounted) { this.setState({ content: content }) }
    // })

    // this.getAnalytics()
    // this.getBalance()
    this.getBountyCredits()
    this.getBrandCampaigns()
    this.getActiveBounties()
    this.refreshUser()
    // this.getAchievements()

    // this.getCard('preview')

    // setInterval(this.getBalance.bind(this), 30000)
    // setInterval(this.getBountyCredits.bind(this), 30000)
    // setInterval(this.getActiveBounties.bind(this), 60000)



    // if (this.state.user && this.state.user.channelId){
    //   this.getAutoVideos().done((response) => {
    //     console.log('got auto videos')
    //     console.log(response)
    //     this.setState({
    //       autoVideos: response
    //     })
    //   })


    // this.checkLive().done((response) => {
    //   console.log('twitch')
    //   console.log(response)
    //   if (response && response.streamLive){
    //     this.setState({
    //       twitchLive: response,
    //       gameMeta: response
    //     })
    //     setTimeout(() => { this.setLiveStreamElapseInterval() }, 25)
    //   }
    // })

    // this._getRecentStreams().done((response) => {
    //   console.log('latest streams')
    //   console.log(response)
    //   this.setState({
    //     recentStreams: response
    //   })
    // })



    // this.getTrackingStatus().done((response) => {
    //   this.setState({
    //     tracking_status: response
    //   })
    // })

    // this.getCommission().done((balance) => {
    //   console.log('user', balance)
    //   this.setState({ balance: balance })
    //   Cookies.set('balance', balance)
    // })
  }

  refreshUser(){
    this.getUser().done((response) => {
      if (response){
        var obj = {
          userdata: response,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          paypalEmail: response.paypalEmail,
          gender: response.gender,
          birthday: response.birthday,
          socials: response.socials,
          addresses: response.addresses
        }
        this.setState(obj)
      }
      else {  }
    })
  }

  getUser(){ return $.ajax({ method: 'GET', url: '/api/me/details', data: { } }) }

  getAnalytics() { this._getAnalytics().done((response) => { if (this.state.mounted) { this.setState({ chartData: response, clicks: response.currentBalance.clicks }) } }) }

  getBalance() {
    this._getBalance().done((response) => {
      Cookies.set('totalBalance', response.total)
      Cookies.set('availableBalance', response.available)
      Cookies.set('pendingBalance', response.pending)

      this.setState({
        totalBalance: response.total,
        availableBalance: response.available,
        pendingBalance: response.pending,
        balanceLoaded: parseFloat(this.state.totalBalance) - parseFloat(response.total) != 0 ? true : false
      })
    })
  }

  getAchievements(){ this._getAchievements().done((response) => { this.setState({ achievements: response.achievements }) }) }
  _getAchievements(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getAchievements', data: { userid: this.state.userid, username: 'bingbangham', platform: 'mixer' } }) }
  getBrandCampaigns(){ this._getBrandCampaigns().done((response) => { this.setState({ brand_campaigns: response.campaigns, noConnectedSocials: response.noConnectedSocials, user: response.user, loadingBrands: false }); this.checkLoaded() }) }
  getActiveBounties(){ this._getActiveBounties().done((response) => { this.setState({ campaigns_pending: response.campaigns_pending, campaigns_active: response.campaigns_active, campaigns_past: response.campaigns_past, loadingActive: false }); this.checkLoaded() }) }
  _getActiveBounties(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getActiveBounties', data: { userid: this.state.userid } }) }

  checkLoaded(){ setTimeout(() => { if (!this.state.loadingBrands && !this.state.loadingActive) { this.setState({ loading: false }) } }, 10) }

  setLiveStreamElapseInterval(){
    if (!this.state.updateStreamInterval){
      this.setState({
        updateStreamInterval: true,
        liveStreamElapsed: Globals.getElapsed(this.state.campaigns_active[0].user.streamStarted)
      })
      setInterval(this.updateLiveStreamElapse.bind(this), 1000)
    }
  }

  updateLiveStreamElapse(){ this.setState({ liveStreamElapsed: Globals.getElapsed(this.state.campaigns_active[0].user.streamStarted) }) }

  requestCampaign(params){
    console.log('requestCampaign')
    this.toggleBrandPopup(params, true)
    $('html, body').animate({scrollTop: $('article').offset().top - 50}, 815)
    // this._requestCampaign(params).done((response) => {
    //   this.state.brand_campaigns[params.num].pending = true
    //   this.setState({
    //     brand_campaigns: this.state.brand_campaigns
    //   })
    // })
  }

  toggleBrandPopup(params, open){
    if (open){ $('.page-with-side-navigation').css('transform', 'none').css('left', '75px') }
    else { $('.page-with-side-navigation').css('transform', 'translateX(75px)').css('left', '0') }
    this.setState({ brandPopup: params, brandPopupOpen: open })
  }

  deleteRequestCampaign(params){
    this._deleteRequestCampaign(params).done((response) => {
      this.state.brand_campaigns[params.num].pending = false
      this.setState({
        brand_campaigns: this.state.brand_campaigns
      })
    })
  }

  ytAuthenticate(){ this.getYTAuthentication().done((url) => { location.href = url }) }

  getCard(type){ this._getCard(type).done((response) => { this.setState({ stripeCard: response.card }) }) }
  _getCard(type){ return $.ajax({ method: 'GET', url: '/api/Card/get', data: { userid: this.state.userid, type: type } }) }

  getChallenges(){ return $.ajax({ method: 'GET', url: '/api/Challenge/get', data: { } }) }
  getYTAuthentication(){ return $.ajax({ method: 'GET', url: '/api/YouTube/authenticateUrl', data: { } }) }
  checkLive(){ return $.ajax({ method: 'GET', url: '/api/Twitch/checkLive', data: { userid: this.state.userid } }) }

  getBountyCredits(){ this._getBountyCredits().done((response) => { this.setState({ bountyCredits: response.credits, bountyCreditLimit: response.creditLimit, concurrents: response.concurrents, twitchUser: response.twitchUser }) }) }
  _getBountyCredits(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getBountyCredits', data: { userid: this.state.userid } }) }
  _getBrandCampaigns(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getBrandCampaigns', data: { userid: this.state.userid, bountyCategorySelected: this.state.bountyCategorySelected, searchValue: this.state.bountySearchValue } }) }
  _requestCampaign(params){ return $.ajax({ method: 'POST', url: '/api/Campaign/requestCampaign', data: { userid: this.state.userid, user: this.state.user, name: params.name, contentId: params.contentId  } }) }
  _deleteRequestCampaign(params){ return $.ajax({ method: 'POST', url: '/api/Campaign/deleteRequestCampaign', data: { userid: this.state.userid, name: params.name } }) }
  getAutoVideos(){ return $.ajax({ method: 'GET', url: '/api/Recommendation/getVideos', data: { id: this.state.user.channelId } }) }
  _getBalance(){ return $.ajax({ method: 'GET', url: '/api/Users/getBalance', data: { userid: this.state.userid } }) }
  getCommission(){ return $.ajax({ method: 'POST', url: '/api/PromoClick/getBalance', data: { userid: this.state.userid, hash: this.state.user.username } }) }
  _getAnalytics(){ let userdata = Globals.getUser(); return $.ajax({ method: 'POST', url: '/Analytics/getDashboardAnalytics', data: { user: userdata, hash: userdata.username, user_hash: userdata.hash, userid: userdata.userid, startDate: moment().subtract('30', 'days').format(), endDate: moment().add('1', 'days').format() } }) }

  getContent(){}

  copyReferral(){ Globals.logEvent('click', 'copyReferral'); $('#referral-url').select(); document.execCommand('copy'); }

  showCashout(){ this.setState({ cashout: true }) }
  hideCashout(){ this.setState({ cashout: false }) }

  _getRecentStreams(){ return $.ajax({ method: 'GET', url: '/api/Stream/getRecentStreams', data: { userid: this.state.userid } }) }
  _deleteCampaign(contentId){ return $.ajax({ method: 'POST', url: '/api/Campaign/deleteCampaign', data: { userid: this.state.userid, contentId: contentId } }) }
  deleteCampaign(_this, contentId){ this._deleteCampaign(contentId).done((response) => { this.closeSlideOut(this); this.getBountyCredits(); this.getActiveBounties(); this.getBrandCampaigns(); }) }

  addVod(campaign, link){
    if (Globals.isURL(link)){
      this._addVod(campaign.content.id, link).done((response) => {
        if (!campaign.vodLinks) { campaign.vodLinks = [] }
        campaign.vodLinks.push(link)
      })
    }
    else { alert('Not a valid link!') }
  }

  _addVod(id, link){ return $.ajax({ method: 'POST', url: '/api/Campaign/addVod', data: { userid: this.state.userid, id: id, link: link } }) }

  // _notifyViewBounty(title) {
  //   return $.ajax({
  //     method: 'POST',
  //     url: '/api/Users/notifyViewBounty',
  //     data: { title: title }
  //   })
  // }

  viewOffer(campaign){
    if (!this.state.expandedCampaign){
      if (typeof campaign == 'string'){
        var campaigns = this.state.brand_campaigns
        campaigns = campaigns.concat(this.state.campaigns_pending)
        campaigns = campaigns.concat(this.state.campaigns_active)
        var expandedCampaign = false
        campaigns.forEach((c) => { if (c.id == campaign){ expandedCampaign = c } })
        if (expandedCampaign){
          $('html').addClass('freeze');
          this.setState({ expandedCampaign: expandedCampaign, slideOutActive: 'true' });
          var bountyTitle = expandedCampaign && expandedCampaign.bountyTitle ? expandedCampaign.bountyTitle : (expandedCampaign.offer ? expandedCampaign.offer.bountyTitle : 'Bounty')
          // this._notifyViewBounty(bountyTitle).done();
        }
      }
      else {
        $('html').addClass('freeze')
        history.push(`/dashboard/bounty/${campaign.id}`);
        this.setState({ expandedCampaign: campaign, slideOutActive: 'true' })
      }
    }
  }

  closeSlideOut(_this){
    if (_this){
      $('html').removeClass('freeze')
      history.push(`/dashboard`);
      _this.setState({ slideOutActive: false })
      setTimeout(() => { _this.setState({ expandedCampaign: false, slideOutType: false }) }, 500)
    }
    else {
      $('html').removeClass('freeze')
      this.setState({ slideOutActive: false })
      setTimeout(() => { this.setState({ expandedCampaign: false, slideOutType: false }) }, 500)
    }
  }

  expandBountyDetails(_this, id){ _this.setState({ bountyDetailsExpanded: id }) }
  bountyActivated(_this, params){ _this.closeSlideOut(_this); _this.getBountyCredits(); _this.getBrandCampaigns(); _this.getActiveBounties(); }

  chooseCategory(type){
    this.setState({ bountyCategorySelected: type })
    Cookies.set('bountyCategorySelected', type)
    setTimeout(() => { this.getBrandCampaigns() }, 25)
  }

  render() {
    var user = this.state.user
    var canvas1 = false, canvas2 = false
    if (this.state.chartData){
      canvas1 = <Canvas element="myChart1" total={this.state.chartData.earningct} labels={this.state.chartData.earnings.labels} data={this.state.chartData.earnings.data} type="earnings" showRange={true} size="col-md-12"></Canvas>
      canvas2 = <Canvas element="myChart2" total={this.state.chartData.clickct} labels={this.state.chartData.clicks.labels} data={this.state.chartData.clicks.data} type="clicks" showRange={true} size="col-md-12"></Canvas>
    }
    else {
      canvas1 = <Loader active="true" />
    }

    var contentStatus = this.state.content ? true : false, content = []
    if (contentStatus && this.state.content.length > 0){
      content = this.state.content.map(function(content){
        return ( <ContentTile key={content.id} size="col-md-4" content={content}></ContentTile> )
      })
    }
    else if (contentStatus && this.state.content.length == 0){
      content = content = <h5 style={{ textAlign: 'center', position: 'absolute', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0, height: '30px' }}>You have not created any links.<div style={{paddingTop: '15px'}}><Link to="/links"><button className="action-btn">Create Your 1st Link!</button></Link></div></h5>
    }
    else {
      content = ''
    }

    var countUpDiv = (
      <p className="earnings-amt" id="totalEarnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}>${Globals.numberWithCommas(parseFloat(this.state.totalBalance).toFixed(2))}</p>
    )

    if (this.state.balanceLoaded){
      countUpDiv = (
        <CountUp
          start={parseFloat(this.state.totalBalanceStart).toFixed(2)}
          end={parseFloat(this.state.totalBalance).toFixed(2)}
          duration={1.5} delay={0.1} separator="," decimals={2} decimal="." prefix="$"
          onEnd={() => { if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); } }}
          onStart={() => { if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); } }}
        >
          {({ countUpRef, start }) => { setTimeout(start, 3000); return ( <p ref={countUpRef} className="earnings-amt" id="totalEarnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}></p> ); }}
        </CountUp>
      )
    }

    var game = this.state.gameMeta
    var username = ''

    var time = moment().format('H')
    var greeting = 'Good Day'
    if (time >= 0 && time <= 4){ greeting = 'Good Evening' }
    else if (time >= 5 && time <= 11){ greeting = 'Good Morning' }
    else if (time >= 12 && time <= 16){ greeting = 'Good Afternoon' }
    else if (time >= 17 && time <= 19){ greeting = 'Good Evening' }
    else if (time >= 20 && time <= 24){ greeting = 'Good Evening' }
    var capName = this.state.user.username ? `, ${this.state.user.username}` : ''
    greeting = greeting + capName

    var importantTiles = [], importantActions = []
    if (!this.state.socials || this.state.socials.length == 0){
      importantActions.push({
        type: 'connectedSocials',
        title: 'Add a social platform',
        subtitle: 'Add at least 1 social platform to qualify for bounties.',
        img: 'cloud',
        class: 'for-you-socials',
        hash: 'socials'
      })
    }
    if (!this.state.addresses || this.state.addresses.length == 0){
      importantActions.push({
        type: 'connectedAddress',
        title: 'Add shipping address',
        subtitle: 'Add an address so our brands can ship you free products.',
        img: 'delivery',
        class: 'for-you-address',
        hash: 'addresses'
      })
    }
    // if (!user.invitedFriends && isDev()){
    //   importantActions.push({
    //     type: 'connectedInvite',
    //     title: 'Invite friends',
    //     subtitle: 'Refer your creator friends & earn when they sign up.',
    //     img: 'heart',
    //     class: 'for-you-invite',
    //     hash: 'invite',
    //     url: '/refer'
    //   })
    // }
    // if (!user.connectedCards){
    //   importantActions.push({
    //     type: 'connectedCards',
    //     title: 'Connect payment card for free meals',
    //     subtitle: 'Connect at least 1 credit/debit card to be able to get reimbursed for meals you redeem.',
    //     img: 'food',
    //     class: 'for-you-paymentcard',
    //     hash: 'cards'
    //   })
    // }


    this.state.campaigns_pending.forEach((campaign) => {
      importantTiles.push(
        <CampaignStream
          this={this}
          colSize={'col-xl- 3 col-lg-4 col-md-6 col-sm-6 col-xs-12'}
          twitchLive={campaign}
          campaign={campaign}
          user={user}
          twitchUser={this.state.twitchUser}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          viewOffer={this.viewOffer}
          getActiveBounties={this.getActiveBounties.bind(this)}
        />
      )
    })

    this.state.campaigns_active.forEach((campaign) => {
      importantTiles.push(
        <CampaignStream
          this={this}
          colSize={'col-xl- 3 col-lg-4 col-md-6 col-sm-6 col-xs-12'}
          twitchLive={campaign}
          campaign={campaign}
          user={user}
          twitchUser={this.state.twitchUser}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          viewOffer={this.viewOffer}
          getActiveBounties={this.getActiveBounties.bind(this)}
        />
      )
    })

    for (var i = importantTiles.length ; i < 3 ; i++){
      if (importantActions.length > 0){
        var action = importantActions.shift()
        importantTiles.push(
          <div className="col-xl- 3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <Link to={`/account#${action.hash}`}>
              <div className={`for-you-tile ${action.class}`}>
                <div className="for-you-header">
                  <img src={Globals.icon(action.img, 'white')} />
                </div>
                <div className="for-you-meta">
                  <h4>{action.title}</h4>
                  <p className="sub-text">{action.subtitle}</p>
                </div>
                <div className="for-you-footer">
                  <button>Let's go</button>
                </div>
              </div>
            </Link>
          </div>
        )
      }
    }

    return (
      <div>
        <div className="col-md-12" style={{ paddingTop: '15px', paddingBottom: '15px', minHeight: '100vh' }}>

          {/*<div className="module" style={{ display: 'none' }}>
            <div className="row" style={{ position: 'relative', minHeight: '200px' }}>
              <>
                <div className="col-md-6">
                <h4>Auto-Monetize</h4>
                  <p>We noticed you have <b>{Globals.convertNumberFormat(this.state.user.totalMonetizable)}</b> links you are not making money from in your past YouTube videos totalling <b>{Globals.convertNumberFormat(this.state.user.totalViewsMonetizable)} views</b>.</p>
                  <br />
                  <p>We'll take care of those links for you.</p>
                  <button className="monetize-links-btn">MONETIZE LINKS</button>
                </div>
                <div className="col-md-6">
                  {

                    this.state.autoVideos.map((vid, i) => {
                      return (
                        <div className={`auto-video col-xs-4 ${ Math.floor(i / 3) % 2 == 1 ? 'right' : '' }`}>
                          <img src={"//img.youtube.com/vi/"+vid.videoId+"/mqdefault.jpg"} alt="Video Thumbnail" style={{ width: '100%' }} />
                        </div>
                      )
                    })
                  }
                </div>
              </>
            </div>
          </div>*/}

          { importantTiles.length > 0 && !this.state.loading &&
            <div className="row row0" style={{marginBottom: '20px'}}>
              <h3 style={{ margin: '0 0 20px 0', padding: 0, fontSize: '32px', textTransform: 'initial' }}>In Progress</h3>
              <div className="row for-you-container">
                {importantTiles}
              </div>
            </div>
          }

          <div className="row row0">
            <h3 style={{ margin: '0 0 20px 0', padding: 0, fontSize: '32px', textTransform: 'initial' }}>Browse</h3>
            <div className="row row0 bounty-categories">
              <div className={`bounty-category all ${this.state.bountyCategorySelected == 'all' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'all')}>
                <div className={`bounty-category-highlight ${this.state.bountyCategorySelected}`}></div>
                <a>All</a>
              </div>
              <div className={`bounty-category product ${this.state.bountyCategorySelected == 'product' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'product')}>
                <a>Products</a>
              </div>
              <div className={`bounty-category app ${this.state.bountyCategorySelected == 'app' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'app')}>
                <a>Apps</a>
              </div>
              <div className={`bounty-category game ${this.state.bountyCategorySelected == 'game' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'game')}>
                <a>Gaming</a>
              </div>
              <div className={`bounty-category restaurant ${this.state.bountyCategorySelected == 'restaurant' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'restaurant')}>
                <a>Dining</a>
              </div>
              <div className={`bounty-category nft ${this.state.bountyCategorySelected == 'nft' ? 'active' : ''}`} onClick={this.chooseCategory.bind(this, 'nft')}>
                <a>NFT</a>
              </div>
              <div className={`bounty-category search`} style={{marginTop: '-2px'}} onClick={this.chooseCategory.bind(this, 'search')}>
                <a><img src={Globals.icon('loupe')} style={{width: '16px', margin: 0}} /></a>
              </div>
            </div>
          </div>


          <div className="row row8" style={{position: 'relative', minHeight: '250px'}}>
            { this.state.loading && <Loader active="true" /> }

            { !this.state.loading &&
              <>
                { this.state.brand_campaigns.length == 0 && !this.state.noConnectedSocials && (
                    <>
                      { this.state.bountyCategorySelected != 'game' && this.state.bountyCategorySelected != 'nft' && this.state.bountyCategorySelected != 'restaurant' &&
                        <div>
                          <div className="results-empty-message">
                            <h3>No bounties currently available.</h3>
                            <p className="sub-text">Check back later for new bounties to participate in.</p>
                          </div>
                        </div>
                      }
                      { this.state.bountyCategorySelected == 'game' &&
                        <div>
                          <div className="results-empty-message">
                            <h3>Games coming soon.</h3>
                            <p className="sub-text">Check back for future game releases & exclusive opportunities.</p>
                          </div>
                        </div>
                      }
                      { this.state.bountyCategorySelected == 'restaurant' &&
                        <div>
                          <div className="results-empty-message">
                            <h3>Restaurants coming soon.</h3>
                            <p className="sub-text">Check back for future free restaurant.</p>
                          </div>
                        </div>
                      }
                      { this.state.bountyCategorySelected == 'nft' &&
                        <div>
                          <div className="results-empty-message">
                            <h3>NFTs coming soon.</h3>
                            <p className="sub-text">Check back for future NFT opportunities & digital goods.</p>
                          </div>
                        </div>
                      }
                    </>
                  )
                }

                { this.state.brand_campaigns.length == 0 && this.state.noConnectedSocials && (
                    <div>
                      <div className="results-empty-message">
                        <h3>You must connect at least one social platform.</h3>
                        <p className="sub-text"><Link to="/account">Click here to connect a social platform in your settings.</Link></p>
                      </div>
                    </div>
                  )
                }

                { this.state.brand_campaigns.length > 0 && this.state.brand_campaigns.map((campaign, i) => {
                    return ( <BrandTile key={`brand-campaign-${i}`} size={'col-lg-3 col-md-4 col-sm-6 col-xs-12'} num={i} campaign={campaign} viewOffer={this.viewOffer.bind(this)} _this={this} /> )
                  })
                }
              </>
            }

          </div>

        </div>
        <div className="col-md-4" style={{display: 'none'}}>
          <h3>{greeting}</h3>
          <div className="module">
            <div className="cashout-section">
              {countUpDiv}
              <p className="earnings-cashout" style={{fontFamily: 'Barlow', fontWeight: '900'}}>total</p>
            </div>
            <div className="cashout-adj" style={{ display: 'block' }}>
              <button className="cashout-btn" onClick={this.showCashout.bind(this)} style={ this.state.availableBalance < Globals.minimumCashout ? { backgroundColor: '#4e4e4e' } : {} }>
                <div style={{fontFamily: 'Barlow', fontWeight: '900'}}>CASH OUT</div>
                <div className="available-cash" style={{fontFamily: 'Barlow', fontWeight: '900'}}>
                  <div className="available-balance">${Globals.numberWithCommas(this.state.availableBalance)}</div>
                  <div className="available-text" style={ this.state.availableBalance >= Globals.minimumCashout ? { color: '#fff' } : {} }>available</div>
                </div>
              </button>
            </div>
              {/* <li style={{display: 'none'}}>
                <div>
                  <div className="earnings-bar-container">
                    <div className="earnings-bar">
                      <div className={"earnings-bar-fill " + (this.state.user.balance >= 25 ? 'active' : '')} style={{width: ((this.state.balance / 25) * 100).toFixed(2) + '%' }}></div>
                    </div>
                  </div>
                  <div className="earnings-details">
                    <p className="earnings-amt">{'$'+this.state.balance}</p>
                    <p className="earnings-cashout">{this.state.balance >= 25 ? 'earned' : 'earned'}</p>
                  </div>
                  <div className="earnings-details">
                    <p className="earnings-amt">{Globals.convertNumberFormat(this.state.clicks)}</p>
                    <p className="earnings-cashout">total clicks</p>
                  </div>
                  <div className="earnings-details">
                    <div className="earnings-amt" style={{fontSize: '16px'}}>
                      <Countdown
                        options={{
                          endDate: '09/21/2018 3:00 PM',
                          // endDate: '09/24/2018 1:00 AM',
                          prefix: ''
                        }}
                      />
                    </div>
                    <p className="earnings-cashout">until campaign starts</p>
                  </div>
                </div>
              </li> */}

              { /*
              <Notification message="You can now add your own Amazon Affiliate tag if you have one here!" link="/account" />
              <Notification message="HUGE site update! New Link Builder, new UI, much faster experience. Let us know what you think!" link="" />
              <Notification message="Check out our blog here: " link="https://medium.com/weavesocial" />
              */ }
          </div>

          {/*<div className="row" style={{position: 'relative', minHeight: '200px'}}>*/}
            {/*{canvas1}*/}
            {/*{canvas2}*/}
          {/*</div>*/}

          <div className="module" style={{padding: 0, marginBottom: '10px'}}>
            <div className="row" style={{textAlign: 'center', margin: 0, padding: '25px'}}>
              <div className="col-xs-6">
                <h2 style={{marginTop: '0', fontSize: '42px', fontFamily: 'Barlow', fontWeight: 900}}>
                  <img src="/images/coin.svg" style={{width: '33px', marginTop: '-7px'}} /> {this.state.bountyCredits}<span>/</span><span>{this.state.bountyCreditLimit}</span>
                </h2>
                <p className="sub-text" style={{fontFamily: 'Barlow', fontWeight: 500, textTransform: 'uppercase', fontSize: '14px'}}>BOUNTY CREDITS</p>
              </div>
              <div className="col-xs-6">
                <h2 style={{marginTop: '0', fontSize: '42px', fontFamily: 'Barlow', fontWeight: 900}}>
                  {this.state.concurrents}
                </h2>
                <p className="sub-text" style={{fontFamily: 'Barlow', fontWeight: 500, textTransform: 'uppercase', fontSize: '14px'}}>AVG VIEWERS</p>
              </div>
            </div>
            {/*{
              this.state.twitchUser &&
              <div className="row bounty-credit-bottom" style={{margin: 0, padding: '25px'}}>
                <div className="col-xs-1">

                </div>
                <div className="col-xs-11" style={{padding: 0}}>
                  <div className="col-xs-2" style={{padding: 0}}>
                    <div style={{position: 'relative', width: '49px'}}>
                      <img
                        src={this.state.twitchUser.imageUrl}
                        style={{width: '100%', borderRadius: '50px'}}
                      />
                      <div className={`avatar-online ${this.state.twitchUser.streamLive ? 'online' : ''}`}>
                        <div className="avatar-online-container">
                          <div className="avatar-online-border"></div>
                          <div className="avatar-online-dot"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-10">
                    <p style={{fontFamily: 'Barlow', fontWeight: 700, fontSize: '16px', marginTop: '0px', marginBottom: '4px'}}>{this.state.twitchUser.username}</p>
                    {
                      this.state.twitchUser.streamLive &&
                      <p className="sub-text" style={{marginBottom: '0'}}>
                        Currently streaming <b>{this.state.twitchUser.game_name}</b>
                      </p>
                    }
                    {
                      !this.state.twitchUser.streamLive &&
                      <p className="sub-text" style={{marginBottom: '0'}}>
                        Last streaming <b>{this.state.twitchUser.game_name}</b> {Globals.calculateTime(moment(this.state.twitchUser.streamEnded).utcOffset(0).format('x'))} ago
                      </p>
                    }
                  </div>
                </div>
              </div>
            }*/}
            {/*<div className="row" style={{marginTop: '20px', display: 'none'}}>
              {
                false &&
                <>
                  <div className="col-md-3">
                    <img
                      src="https://static-cdn.jtvnw.net/ttv-boxart/./Call%20of%20Duty:%20Modern%20Warfare-188x250.jpg"
                      style={{width: '100%', borderRadius: '3px'}}
                    />
                  </div>
                  <div className="col-md-9">
                    <h4 style={{margin: 0}}>Call of Duty: Modern Warfare</h4>
                    <p><span>• 02:18:31 </span><span> 30 </span><span> 178,392 </span></p>
                  </div>
                </>
              }
              {
                true &&
                <>
                  <div className="col-md-1" style={{textAlign: 'center'}}>

                  </div>
                  <div className="col-md-2" style={{padding: '0 2px'}}>
                    <img
                      src="https://static-cdn.jtvnw.net/ttv-boxart/./Call%20of%20Duty:%20Modern%20Warfare-188x250.jpg"
                      style={{width: '49px', borderRadius: '3px'}}
                    />
                  </div>
                  <div className="col-md-9">
                    <h4 style={{margin: 0}}>Call of Duty: Modern Warfare</h4>
                    <p className="sub-text"><span>Last streamed 18 hours ago.</span></p>
                  </div>
                </>
              }
            </div>*/}
          </div>

          {/*<div className="module">
            <h3 style={{margin: '0 0 4px 0'}}>
              Bounty Credits
            </h3>
            <h2 style={{marginTop: '0', fontSize: '42px', fontFamily: 'Barlow', fontWeight: 900}}>
              <img src="/images/coin.svg" style={{width: '33px', marginTop: '-7px'}} /> {this.state.bountyCredits}
            </h2>
            <p className="sub-text">You have <b style={{color: '#fff'}}>{this.state.bountyCredits}/{this.state.bountyCreditLimit} credits</b> available.
              You earn a credit back once you complete a bounty.
              Your credit limit will increase with successfully completed bounties.
            </p>
          </div>*/}

          {/*<div className="module">
            <h3 style={{margin: '0 0 4px 0'}}>
              Achievements
              <span><a>View All</a></span>
            </h3>
          </div>*/}


          {/*<div>
            {
              this.state.achievements &&
              this.state.achievements.map((a) => {
                return (
                  <Achievement this={this} achievement={a} />
                )
              })
            }
          </div>*/}

            {/*<div className="module">
              <div className="row row0">
                <p className="achievement-text sub-text" style={{color: '#fff', fontWeight: '800', fontSize: '16px'}}>VIRTUAL CARD</p>
                <p className="achievement-text sub-text">Use this for online food delivery orders. It's convenient if you add it to Apple or Google Wallets</p>
              </div>
              <div className="row row0">
                {
                  this.state.stripeCard &&
                  <StripeCard
                    this={this}
                    card={this.state.stripeCard}
                    getCard={this.getCard}
                    scale={0.55}
                  />
                }
              </div>
            </div>*/}

          {/*<div className="module">
            <h4 style={{margin: '0 0 4px 0'}}>
              Authenticate YouTube Audience
            </h4>
            <p>
              In order to participate in campaigns, you must allow Weave to view your audience demographics.
              Brands require this to confirm your audience is a good fit for their brand.
            </p>
            <div className="login-box" style={{marginTop: '35px'}}>
              <a className="social-button" onClick={this.ytAuthenticate.bind(this)}>
                <span className="social-button-icon"></span>
                <span className="social-button-text">CONNECT YOUTUBE AUDIENCE</span>
              </a>
            </div>
          </div>*/}


          {/*<div className="module invite-link-module">
            <h3 style={{ margin: 0 }}>Invite Friends & Earn</h3>
            <div className="" style={{position: 'relative'}}>
              <input id="referral-url" className="dark-input" defaultValue={`https://weave.gg/r/${this.state.user.hash}`} style={{width: '100%', margin: 0}} readOnly  />
              <button className="copy-invite-link" onClick={this.copyReferral.bind(this)}>Copy</button>
            </div>
            <p className="sub-text" style={{marginTop: '15px'}}>Use this link anywhere and earn when users sign up through it!</p>
          </div>

          <div className="module invite-link-module">
            <h3 style={{ margin: 0 }}>Invite Brands & Earn</h3>
            <div className="" style={{position: 'relative'}}>
              <input id="referral-url" className="dark-input" defaultValue={`https://weave.gg/r/${this.state.user.hash}`} style={{width: '100%', margin: 0}} readOnly  />
              <button className="copy-invite-link" onClick={this.copyReferral.bind(this)}>Copy</button>
            </div>
            <p className="sub-text" style={{marginTop: '15px'}}>Use this link anywhere and earn when users sign up through it!</p>
          </div>*/}

          {/*<div className="module">
            <h3 style={{ margin: 0 }}>Join Our Discord</h3>
            <div className="row" style={{margin: 0}}>
              <a href="https://discord.gg/tge86bX" target="_blank">
                <button className="discord-button">
                  <img src="/images/discord-logo-full-white.svg" style={{position: 'initial', height: '42px', width: 'inital', margin: 0, left: 0}} />
                </button>
              </a>
            </div>
            <p className="sub-text" style={{marginTop: '15px'}}>Join our community of gamers on Discord for help, tips, and more!</p>
          </div>*/}



          {/* <h3>Helpful Resources</h3>
          <div className="module">
            <p><a href="https://medium.com/weavesocial/test-c682536580e3" target="_blank" style={{textDecoration: 'underline'}}>How Weave Works and What Makes Us Different</a></p>
            <p><a href="https://medium.com/weavesocial/what-is-affiliate-marketing-6062eae37066" target="_blank" style={{textDecoration: 'underline'}}>What is Affiliate Marketing</a></p>
            <p><a href="https://medium.com/weavesocial/should-you-use-affiliate-links-d1591a3fd83e" target="_blank" style={{textDecoration: 'underline'}}>Should You Use Affiliate Links</a></p>
            <p><a href="https://medium.com/weavesocial/weave-instant-cash-out-1e96cb793d" target="_blank" style={{textDecoration: 'underline'}}>Cash Out Process</a></p>
            <p><a href="https://medium.com/weavesocial/referring-others-to-weave-3a62538e0d63" target="_blank" style={{textDecoration: 'underline'}}>Referring Others to Weave</a></p>
          </div>
          */}
        </div>

        {/*<Cashout active={this.state.cashout} hideCashout={this.hideCashout} user={this.state.user} balance={this.state.availableBalance} this={this} />*/}

        {/*<SlideOut active={this.state.slideOutActive} influencer={this.state.expandedInfluencer} closeSlideOut={this.closeSlideOut} this={this} type={this.state.slideOutType} />*/}
        <SlideOut
          active={this.state.slideOutActive}
          user={this.state.user}
          campaign={this.state.expandedCampaign}
          closeSlideOut={this.closeSlideOut}
          this={this}
          requestCampaign={this.requestCampaign}
          deleteRequestCampaign={this.deleteRequestCampaign}
          bountyActivated={this.bountyActivated}
          bountyCredits={this.state.bountyCredits}
          liveStreamElapsed={this.state.liveStreamElapsed}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          deleteCampaign={this.deleteCampaign}
          addVod={this.addVod.bind(this)}
        />
      </div>
    )
  }
}

export default memo(Dashboard)
