import React from 'react';
import Globals from '../global-functions.js';
import ShippingTrackingGameBar from './shipping-tracking/shipping-tracking-game-bar.jsx';
import SlideOutProgressTitle from './slide-out/slide-out-progress-title.jsx';

var countdown

export default class BountyGame extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
  }

  componentDidMount(){
    this.handleEvents()
  }

  componentWillUnmount(){
    clearInterval(countdown)
    countdown = false
  }

  handleEvents(){
    countdown = setInterval(this.handleCountdown.bind(this), 500)
  }

  handleCountdown(){
    var time = 1603843200000,
    days = Globals.getTimeTo(time, true).days >= 0 ? Globals.getTimeTo(time, true).days : '00',
    hours = Globals.getTimeTo(time, true).hours >= 0 ? Globals.getTimeTo(time, true).hours : '00',
    minutes = Globals.getTimeTo(time, true).minutes >= 0 ? Globals.getTimeTo(time, true).minutes : '00',
    seconds = Globals.getTimeTo(time, true).seconds >= 0 ? Globals.getTimeTo(time, true).seconds : '00'

    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    })
  }

  render() {

    var username = this.props.username,
    campaign = this.props.campaign,
    game = campaign.game ? campaign.game : campaign.offer,
    content = campaign ? campaign.content : {},
    campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    user = this.props.user ? this.props.user : {},
    offer = campaign.offer

    var name = offer.bountyTitle, status = content.status

    return (
      <div>
        <div className="brand-game">
          <div className="brand-image">
            <div className="brand-image-spread" style={{backgroundImage: `url(${offer.imageUrl ? offer.imageUrl : offer.imageUrl})`}}>
              <div className="brand-image-profile" style={{backgroundImage: `url(${offer.imageUrl})`}}></div>
            </div>
          </div>

          <div className="brand-meta">
            <h3>{name}</h3>
            <div style={{padding: '0 25px'}}>
              {
                status == 'brand_invited' &&
                <div className="row row0">
                  <div className="bounty-tile-icon">
                    <img src={Globals.icon('star-filled', 'white')} style={{width: '16px', marginTop: '-2px'}} />
                  </div>
                </div>
              }
              {
                status != 'brand_invited' &&
                status != 'brand_completed' &&
                <ShippingTrackingGameBar status={status} size="small" />
              }
            </div>

            <div className="row row0" style={{paddingTop: '8px'}}>
            { status == 'brand_awaiting' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_applied' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_responded' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_invited' && <SlideOutProgressTitle title="You are invited" description="Review and accept the invitation here." /> }
            { status == 'brand_active' && <SlideOutProgressTitle title="Post Content" description="Follow the guidelines below to promote the game" /> }
            {/*{ status == 'brand_live' && <SlideOutProgressTitle title="Promote your link" description="Continue to promote your link until bounty expires" /> }
            { status == 'brand_completed' && <SlideOutProgressTitle title="Bounty Completed" description="The bounty has now been finished" /> }*/}
              {
                (status == 'brand_live' || status == 'brand_completed') &&
                <>
                  <div className="row row0">
                    {/*<div className="col-xs-4">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>${content.totalSales ? Globals.convertNumberFormat(content.totalSales) : '0.00'}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Sales</h4>
                    </div>*/}
                    <div className="col-xs-6">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>${content.totalCommission ? Globals.numberWithCommas(content.totalCommission, true) : '0.00'}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Earnings</h4>
                    </div>
                    <div className="col-xs-6">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>{content.link && content.link.count ? Globals.numberWithCommas(content.link.count) : 0}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Clicks</h4>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
