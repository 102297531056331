import React from 'react';
import Globals from '../../global-functions.js';
import SlideOutHeaderProduct from './slide-out-header-product.jsx';
import SlideOutHeaderApp from './slide-out-header-app.jsx';
import SlideOutHeaderGame from './slide-out-header-game.jsx';
import SlideOutHeaderRestaurant from './slide-out-header-restaurant.jsx';

export default class SlideOutHeader extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      brandColors: {
        Vibrant: {},
        DarkVibrant: {},
        DarkMuted: {},
        photos: []
      },
      gameMeta: {},
    }
  }

  componentDidMount(){

  }


  render() {

    var campaign = this.props.campaign
    var user = campaign.user
    var game = campaign.game ? campaign.game : campaign.offer
    var offer = campaign.offer ? campaign.offer : {}
    var content = campaign.content ? campaign.content : {}
    if (campaign) {
      campaign.brandType = campaign.brandType ? campaign.brandType : offer.brandType
    }
    var restaurant = campaign.restaurant

    var platformVars = {
      product: {
        color: '#96bf48',
        text: {
          active: 'Requesting...',
          inactive: 'Request Products'
        }
      },
      game: {
        color: '#6441a4',
        text: {
          active: 'Activating...',
          inactive: 'Activate Bounty'
        }
      },
      restaurant: {
        color: '#d32323',
        text: {
          active: 'Redeeming...',
          inactive: 'Redeem Offer'
        }
      }
    }

    return (
      <div className="slide-out-header">
        {
          this.props.tabActive == 'offer_accept' &&
          <div className="slideout-close" onClick={this.props.chooseTab.bind(this.props.thisClose, 'offer')}>
            <img src={Globals.icon('arrow-left-1', 'white')} />
          </div>
        }
        {
          this.props.tabActive != 'offer_accept' &&
          <div className="slideout-close" onClick={this.props.handleClose.bind(this.props.thisClose, 'close')}>
            <img src={Globals.icon('close', 'white')} />
          </div>
        }

        {
          campaign.brandType == 'game' &&
          <SlideOutHeaderGame
            this={this}
            campaign={campaign}
            user={user}
            game={game}
            offer={offer}
            content={content}
            platformVars={platformVars}
            brandColors={this.state.brandColors}
            tabActive={this.props.tabActive}
            scrollingDown={this.props.scrollingDown}
          />
        }

        {
          campaign.brandType == 'restaurant' &&
          <SlideOutHeaderRestaurant
            this={this}
            campaign={campaign}
            user={user}
            offer={offer}
            content={content}
            platformVars={platformVars}
            restaurant={restaurant}
            brandColors={this.state.brandColors}
            tabActive={this.props.tabActive}
            scrollingDown={this.props.scrollingDown}
          />
        }

        {
          campaign.brandType == 'product' &&
          <SlideOutHeaderProduct
            this={this}
            campaign={campaign}
            user={user}
            offer={offer}
            content={content}
            platformVars={platformVars}
            brandColors={this.state.brandColors}
            tabActive={this.props.tabActive}
            scrollingDown={this.props.scrollingDown}
          />
        }

        {
          campaign.brandType == 'app' &&
          <SlideOutHeaderApp
            this={this}
            campaign={campaign}
            user={user}
            offer={offer}
            content={content}
            platformVars={platformVars}
            brandColors={this.state.brandColors}
            tabActive={this.props.tabActive}
            scrollingDown={this.props.scrollingDown}
          />
        }
      </div>
    )
  }
}
