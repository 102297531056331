import React from 'react';
import Globals from '../../global-functions.js';
import { ContentForReview } from './content-for-review/content-for-review';

export default class SlideOutBounty extends React.Component {

  constructor(props){
    super(props)
    this.state = {}
  }

  componentDidMount(){}

  copyLink(type, e){
    var currentFocus = document.activeElement
    var target = document.getElementById(type)
    target.focus()
    target.setSelectionRange(0, target.value.length)
    var succeed
    try { succeed = document.execCommand("copy") }
    catch(e) { succeed = false }
    if (currentFocus && typeof currentFocus.focus === "function") { currentFocus.focus(); }
  }

  onChange(type, e){ this.setState({ [`${type}`]: e.target.value }) }

  onAddPost(e){

  }

  addPost(url){
    var type, id, url = url ? url : this.state.value
    if (url.indexOf('instagram.com') > -1){
      if (url.indexOf('instagram.com/p/') > -1) {
        id = url.split('instagram.com/p/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
        type = 'ig_post'
      }
      else if (url.indexOf('instagram.com/tv/') > -1) {
        id = url.split('instagram.com/tv/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
        type = 'ig_post'
      }
      else if (url.indexOf('instagram.com/stories/') > -1) {
        id = url.split('instagram.com/stories/')[1].split('/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
        type = 'ig_story'
      }
    }
    else if (url.indexOf('youtube.com/watch?v=') > -1 || url.indexOf('youtu.be/') > -1){
      type = 'youtube'
      if (url.indexOf('youtube.com/watch?v=') > -1) {
        id = url.split('youtube.com/watch?v=')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
      else if (url.indexOf('youtu.be/') > -1) {
        id = url.split('youtu.be/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
    }
    else if (url.indexOf('twitch.tv') > -1) {
      type = 'twitch'
      if (url.indexOf('twitch.tv/videos/') > -1) {
        id = url.split('twitch.tv/videos/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
    }
    else if (url.indexOf('tiktok.com') > -1){
      type = 'tiktok'
      if (url.indexOf('m.tiktok.com/v/') > -1){
        id = url.split('m.tiktok.com/v/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
      else if (url.indexOf('vm.tiktok.com/') > -1){
        id = url.split('vm.tiktok.com/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
      else if ((url.indexOf('tiktok.com/@') > -1 && url.indexOf('/video/') > -1)){
        id = url.split('/video/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0]
      }
    }
    console.log(type, id, url)
    if (type && id){
      this._addPost({ type: type, id: id, url: url }).done((response) => {
        this.props.updateSlideOut({ ...this.props.campaign, id: this.props.campaign.content.id, viewType: 'active' })
        this.setState({ addPost: '' })
      })
    }
  }

  _addPost(post){
    var { campaign } = this.props
    var params = { post: post, contentId: campaign && campaign.content ? campaign.content.id : false }
    return $.ajax({ method: 'POST', url: '/api/Campaign/addPost', data: params })
  }

  onPaste(type, e){
    // console.log(type, e, 'pasted')
    // console.log(e.target.value)
    // https://vm.tiktok.com/ZMJbhL9Js/
    setTimeout(() => { this.addPost(this.state.addPost) }, 50)

    // this.setState({
    //   [`${type}`]: e.target.value
    // })
  }

  onEnter(type, e){
    // console.log(type, e, 'pasted')
    // console.log(e.target.value)
    // https://vm.tiktok.com/ZMJbhL9Js/
    setTimeout(() => { this.addPost(this.state.addPost) }, 50)

    // this.setState({
    //   [`${type}`]: e.target.value
    // })
  }

  render() {
    var props = this.props, status = this.props.status, campaign = props.campaign ? props.campaign : {},
        content = campaign.content ? campaign.content : {}, offer = campaign.offer ? campaign.offer : {}, shipping = campaign.shipping ? campaign.shipping : {}
        if (typeof content.objectives == 'string') { try { content.objectives = JSON.parse(content.objectives) } catch (err) {  } }

    return (
      <div>
        {
          false &&
          !!offer.id &&
          <>
            <ContentForReview offerId={offer.id} brandCampaignId={content.id}/>
            <hr />
          </>
        }
        <div className="shipping-tracking shipping-tracking-product" style={{ display: 'none' }}>
          <div className="row row0" style={{ marginTop: '10px' }}>
            <h3>Bounty Agreement</h3>
            {offer.brandType == 'product' && (
              <p>
                Content posted within:{' '}
                <b>
                  {content.timeframe}{' '}{content.timeframe == '1' ? 'week' : 'weeks'}
                </b>
              </p>
            )}

            <div className="slide-out-objectives">
              {content.objectives &&
                typeof content.objectives == 'object' &&
                content.objectives.map((o) => {
                  if (parseInt(o.value) > 0) {
                    return (
                      <div className="slide-out-objective" style={{ marginTop: '15px', display: 'none' }}>
                        <div className="slide-out-objective-title">
                          <div className={`slide-out-objective-status ${o.posts && o.posts.length == o.value ? 'complete' : 'pending'}`}>
                            <div className="slide-out-objective-status-img"></div>
                          </div>
                          <span>
                            <span style={{ marginRight: '8px' }}>{o.name}</span>
                            <b>{o.posts ? o.posts.length : 0}</b>
                            <span className="sub-text"> /{o.value} </span>
                          </span>
                        </div>
                        <div style={{ paddingLeft: '8px' }}>
                          {o.posts &&
                            o.posts.map((post) => {
                              return (
                                <div className="row row0 social-info" style={{ marginTop: '8px' }}>
                                  <a href={post.url} target="_blank">
                                    <div className="social-info-img-container">
                                      <img src={Globals.icon(post.platform)} style={{ top: 0 }} />
                                    </div>
                                    <div className="social-info-name">
                                      <span>{post.postType}</span>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                        </div>

                        {/*<div className="row row0 social-info" style={{marginTop: '8px'}}>
                          <div className="social-info-img-container" style={{background: 'none'}}>
                            <img src={Globals.icon('plus')} />
                          </div>
                          <div className="social-info-name" style={{width: '75%'}}>
                            <input placeholder="Paste link to post..." style={{padding: '0', border: 'none', background: 'none', width: '100%'}} value={this.state.addPost} onChange={this.onChange.bind(this, 'addPost')} onPaste={this.onPaste.bind(this, 'addPost')} />
                          </div>
                        </div>*/}
                      </div>
                    );
                  }
                })}

              {content.additionalPosts && content.additionalPosts.length > 0 && (
                <div>
                  <div className="slide-out-objective" style={{ marginTop: '15px', display: 'none' }}>
                    <div className="slide-out-objective-title" style={{ marginBottom: '8px' }}>
                      <div className={`slide-out-objective-status complete`}>
                        <div className="slide-out-objective-status-img"></div>
                      </div>
                      <span>
                        <span style={{ marginRight: '8px' }}>
                          Additional Posts
                        </span>
                        <b>{content.additionalPosts.length}</b>
                        <span className="sub-text">
                          {' '}
                          /{content.additionalPosts.length}{' '}
                        </span>
                      </span>
                    </div>
                    <div style={{ paddingLeft: '8px' }}>
                      {content.additionalPosts.map((post) => {
                        return (
                          <div className="row row0 social-info" style={{ marginTop: '8px' }}>
                            <a href={post.url} target="_blank">
                              <div className="social-info-img-container">
                                <img src={Globals.icon(post.platform)} style={{ top: 0 }} />
                              </div>
                              <div className="social-info-name">
                                <span>{post.postType}</span>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className="row row0 social-info" style={{ display: 'none' }}>
                <div className="social-info-img-container" style={{ background: 'none' }}>
                  <img src={Globals.icon('plus')} />
                </div>
                <div className="social-info-name" style={{ width: '75%' }}>
                  <input
                    placeholder="Paste link to post..."
                    style={{ padding: '0', border: 'none', background: 'none', width: '100%' }}
                    value={this.state.addPost}
                    onChange={this.onChange.bind(this, 'addPost')}
                    onPaste={this.onPaste.bind(this, 'addPost')}
                    onEnter={this.onEnter.bind(this, 'addPost')}
                  />
                </div>
              </div>
            </div>
            {/*<p>
              {
                content.objectives &&
                typeof content.objectives == 'object' &&
                content.objectives.map((o) => {
                  if (parseInt(o.value) > 0){
                    return (
                      <div style={{marginRight: '12px'}}><i><b>{o.value}x</b> {o.name}</i></div>
                    )
                  }
                })
              }
            </p>*/}
          </div>
          <hr />

          {content.products && content.products.length > 0 && (
            <>
              <div className="row row0 slide-out-select-gifts">
                <h3>Products</h3>
                <p className="sub-text">Your gift status</p>
                {content.products.map((product) => {
                  return (
                    <>
                      <div className={`row row0 equal select-gift-option`} style={{ padding: '8px 0' }}>
                        <div className="col-xs-1">
                          <div className="select-gift-indicator"></div>
                        </div>
                        <div className="col-xs-2">
                          <img className="select-gift-img" src={product.imageUrl} />
                        </div>
                        <div className="col-xs-6">
                          <p className="two-line-ellipsis" style={{ fontWeight: '500', fontFamily: 'Barlow', height: 'initial' }}>
                            {product.title}
                          </p>
                          <p style={{ fontSize: '16px', fontWeight: '500', fontFamily: 'Barlow' }}>
                            <span className="sub-text" style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                              ${Globals.formatCurrency(product.price)}
                            </span>
                            <span style={{ color: 'green' }}>$0.00</span>
                          </p>
                        </div>
                        <div className="col-xs-3"></div>
                      </div>
                    </>
                  );
                })}
              </div>
              <hr />
            </>
          )}

          {content.links &&
            content.links.length > 0 &&
            content.links.map((link) => {
              return (
                <>
                  <div className="row row0">
                    <h3>Promo Link</h3>
                    <p className="sub-text">Direct your audience to this link on your socials!</p>
                    <input
                      id="bounty-link"
                      className="dark-input"
                      style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                      defaultValue={`https://weav.me/~${link.hash ? link.hash : ``}`}
                      onClick={this.copyLink.bind(this, 'bounty-link')}
                      readOnly
                    />
                  </div>
                  <hr />
                </>
              );
            })}

          {content.discountCodes &&
            content.discountCodes.length > 0 &&
            content.discountCodes.map((discount) => {
              if (discount.discountReason == 'customer') {
                return (
                  <>
                    <div className="row row0">
                      <h3>Discount Code</h3>
                      <p className="sub-text">Include your discount code in your description, caption, or story for fans to purchase.</p>
                      <input
                        id="bounty-code"
                        className="dark-input"
                        style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                        defaultValue={ discount.code ? discount.code.toUpperCase() : offer.bountyFallbackDiscount ? offer.bountyFallbackDiscount.toUpperCase() : '' }
                        onClick={this.copyLink.bind(this, 'bounty-code')}
                        readOnly
                      />
                    </div>
                    <hr />
                  </>
                );
              }
            })}

          {offer.bountyHashtags && offer.bountyHashtags.length > 0 && (
            <>
              <div className="row row0">
                <h3>Hashtag</h3>
                <p className="sub-text">Include the hashtag in each caption or story.</p>
                {offer.bountyHashtags.map((h, i) => {
                  return (
                    <input
                      id={`bounty-hashtag-${i}`}
                      className="dark-input"
                      style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', }}
                      defaultValue={`#${h}`}
                      onClick={this.copyLink.bind(this, `bounty-hashtag-${i}`)}
                      readOnly
                    />
                  );
                })}
              </div>
              <hr />
            </>
          )}

          {offer.bountyMentions && offer.bountyMentions.length > 0 && (
            <>
              <div className="row row0">
                <h3>Brand Mention</h3>
                <p className="sub-text">Mention the brand in each caption or story.</p>
                {offer.bountyMentions.map((m, i) => {
                  return (
                    <input
                      id={`bounty-mention-${i}`}
                      className="dark-input"
                      style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                      defaultValue={`@${m}`}
                      onClick={this.copyLink.bind(this, `bounty-mention-${i}`)}
                      readOnly
                    />
                  );
                })}
              </div>
              <hr />
            </>
          )}

          {offer.brandType == 'game' && (
            <>
              <div className="row row0">
                <h3>Brand Assets</h3>
                <p className="sub-text">Use only this image asset for your post.</p>
                <div
                  className="downloadable-img"
                  style={{ backgroundImage: `url(${offer.imageUrl})` }}
                ></div>
                <a href={offer.imageUrl} target="_blank" download>
                  <div className="dark-input" style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', marginTop: '15px' }}>
                    <span>
                      <img src={Globals.icon('download')} style={{ width: '16px', marginRight: '6px', marginTop: '-5px' }} />
                    </span>{' '}
                    <span>Download</span>
                  </div>
                </a>
              </div>
              <hr />
            </>
          )}

          {offer.externalAssets && offer.externalAssets.length > 0 && (
            <>
              <div className="row row0">
                <h3>Brand Assets</h3>
                <p className="sub-text">
                  You may use these brand assets to augment your content.
                </p>
                <a href={offer.externalAssets} target="_blank">
                  <div
                    className="dark-input"
                    style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', marginTop: '15px' }}
                  >
                    <span>
                      <img
                        src={Globals.icon('link')}
                        style={{ width: '16px', marginRight: '6px', marginTop: '-3px' }}
                      />
                    </span>{' '}
                    <span>View Brand Assets</span>
                  </div>
                </a>
              </div>
              <hr />
            </>
          )}
        </div>
      </div>
    );
  }
}
