import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import InvoiceBraintreeCard from './invoice-braintree-card.jsx'

export default class InvoiceCard extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    var { invoice } = this.props

    return (
      <div className="invoice-payment invoice-ach">
        <div className="row row0">
          <h4>ACH Bank Payment Instructions</h4>
          <p className="sub-text">
            Weave provides an easy way to pay directly from your bank avoiding any transaction fees. Weave is partnered through <a href="https://plaid.com" target="_blank"><b><u>Plaid</u></b></a> & <a href="https://stripe.com" target="_blank"><b><u>Stripe</u></b></a> to ensure your bank details remain private & secure. Weave does not access/store your bank details or account balances to protect your safety.
          </p>
        </div>
        <div className="invoice-plaid-container">
          <div className="row row0">
            <InvoiceBraintreeCard invoice={invoice} />
          </div>
        </div>

      </div>
    )
  }
}
