import React from 'react'
import Loader from '../loader'
import Globals from '../../global-functions.js'

export default class InsightsChart extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      active: false
    }
  }

  render(){
    var { filter, data, title, type, changeFilter, size, _this } = this.props
    size = size || 'col-md-3'; var precursor = ''
    if (type == 'hashtag'){ precursor = '#'; }
    else if (type == 'mention'){ precursor = '@'; }

    return (
      <>
      <div className={`trending-list-container ${size}`}>
        <ul className="trending-list">
          <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}><span>{title}</span></li>
          { !data && <Loader active={true} /> }
          { data && data.map((t) => {
            var name = t.name || t.account.username, num = Globals.convertNumberFormat(t.count || t.account.followerCount)
            return (
              <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                <a href={`https://instagram.com/${name}`} target="_blank">{precursor}{name}</a>
                <a href={`/insights/company/${name}`} target="_blank">
                  <span style={{ float: 'right' }}>{num}</span>
                </a>
              </li>
            )
          }) }
        </ul>
      </div>
      </>
    )
  }
}
