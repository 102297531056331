import React from 'react';
import YouTubeTips from './builder-tips/youtube.jsx';
import InstagramTips from './builder-tips/instagram.jsx';
import FacebookTips from './builder-tips/facebook.jsx';
import SnapchatTips from './builder-tips/snapchat.jsx';
import TwitterTips from './builder-tips/twitter.jsx';
import PinterestTips from './builder-tips/pinterest.jsx';
import BlogTips from './builder-tips/blog.jsx';
import OtherTips from './builder-tips/other.jsx';
import GeneralTips from './builder-tips/general.jsx';
import YouTubeDescription from './youtube-description.jsx';
import Header from './header.jsx';

export default class Platform extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
    console.log(this.props.video)
  }

  componentWillUnmount(){

  }

  render() {

    let chosenTips = null, modules = null
    switch (this.props.platform) {
      case 'youtube':
        chosenTips = <YouTubeTips />
        if (this.props.video){
          modules = (
            <div>
              <YouTubeDescription video={this.props.video} updateDescription={this.props.updateDescription} this={this.props.this} />
            </div>
          )
        }
        break;
      case 'instagram':
        chosenTips = <InstagramTips />
        break;
      case 'facebook':
        chosenTips = <FacebookTips />
        break;
      case 'snapchat':
        chosenTips = <SnapchatTips />
        break;
      case 'twitter':
        chosenTips = <TwitterTips />
        break;
      case 'pinterest':
        chosenTips = <PinterestTips />
        break;
      case 'blog':
        chosenTips = <BlogTips />
        break;
      case 'other':
        chosenTips = <OtherTips />
        break;
      default:
        chosenTips = <GeneralTips />

    }

    return (
      <div>
        <Header
          platform={this.props.platform}
          user={this.props.user}
          links={this.props.links}
          contentid={this.props.contentid}
          chooseSocial={this.props.chooseSocial}
          chooseVideo={this.props.chooseVideo}
          video={this.props.video}
          editLabel={this.props.editLabel}
          label={this.props.label}
          this={this.props.this}
          showMoreVideos={this.props.showMoreVideos}
        />
        {modules}
        <div className="module tips sub-text">
          {chosenTips}
        </div>
      </div>
    )
  }
}
