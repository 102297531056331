import { createAction } from 'typesafe-actions';
import { ActionType } from 'typesafe-actions';
import { LoginRequest } from '../models/auth.models';
import { LoginResponse } from '../models/auth.models';
import { LoginFailed } from '../models/auth.models';



export const authActionCreators = {
  resetLoginAction: createAction('login/reset')<void>(),
  createLoginAction: createAction('login/CREATE')<LoginRequest>(),
  createLoginSuccessAction: createAction('login/CREATE/success')<LoginResponse>(),
  createLoginFailAction: createAction('login/CREATE/fail')<LoginFailed>()
};

export type ResetLoginAction = ActionType<
  typeof authActionCreators.resetLoginAction
  >;

export type CreateLoginAction = ActionType<
  typeof authActionCreators.createLoginAction
  >;
export type CreateLoginSuccessAction = ActionType<
  typeof authActionCreators.createLoginSuccessAction
  >;
export type CreateLoginFailAction = ActionType<
  typeof authActionCreators.createLoginFailAction
  >;


export type AuthActions = ActionType<typeof authActionCreators>;
