import React from 'react';

export default class TwitterTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Pinterest</h4>
        <p>Using Weave on Pinterest allows you to link your images and boards to the product or store that you feature. Here’s some helpful tips for posting on Pinterest:</p>
        <ol>
          <li>include Weave links to all your product or store focused boards</li>
          <li>after you pin products, click the edit button on the pin, and then you can replace the original link in the website box with your Weave link</li>
        </ol>
      </div>
    )
  }
}
