import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class InvoiceWire extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault();
  }

  // getAllDeals(){
  //   var { startDate, endDate, incrementType } = this.state
  //   var params = { startDate, endDate, incrementType }
  //   this._getAllDeals(params).done((response) => { this.setState(response) })
  // }
  // _getAllDeals(params){ return $.ajax({ method: 'GET', url: '/api/Account/getAllDeals', data: params }) }

  render() {
    var { invoice } = this.props

    return (
      <div className="invoice-payment invoice-wire">
        <div className="row row0">
          <h4>Wire Instructions</h4>
          <p className="sub-text">
            Please submit a wire of the full invoice amount to the below bank details. Contact Weave support if you require any assistance.
          </p>
          <p className="sub-text">
            Include <b>Invoice #{invoice.id}</b> in your payment description.
          </p>
        </div>

        <hr />
        <div className="row row0">
          <h4>Domestic USA Wire</h4>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="row row0">
              <label className="sub-text">Bank Name</label>
              <input className="dark-input" defaultValue={'Column NA - Brex'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Bank Address</label>
              <input className="dark-input" defaultValue={'1110 Gorgas Ave, Suite A4-700, San Francisco, CA, 94129'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Recipient/Beneficiary Name</label>
              <input className="dark-input" defaultValue={'Weave, Inc'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Recipient/Beneficiary Address</label>
              <input className="dark-input" defaultValue={'7403 Alverstone Ave, Los Angeles, CA 90045'} readOnly />
            </div>
          </div>
          <div className="col-xs-6">
            <div className="row row0">
              <label className="sub-text">Account #</label>
              <input className="dark-input" defaultValue={'347400857065224'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Routing #</label>
              <input className="dark-input" defaultValue={'121145349'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Memo/Special Instructions</label>
              <input className="dark-input" defaultValue={`Invoice #${invoice.id}`} readOnly />
            </div>
          </div>
        </div>

        <hr />
        <div className="row row0">
          <h4>International Wire</h4>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <div className="row row0">
              <label className="sub-text">Bank Name</label>
              <input className="dark-input" defaultValue={'JPMorgan Chase Bank N.A.'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Bank Address</label>
              <input className="dark-input" defaultValue={'270 Park Avenue, New York, NY 10017'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Beneficiary Name</label>
              <input className="dark-input" defaultValue={'Brex Treasury LLC'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Beneficiary Address</label>
              <input className="dark-input" defaultValue={'405 Howard St. Floor 2, San Francisco, CA 94105'} readOnly />
            </div>
          </div>
          <div className="col-xs-6">
            <div className="row row0">
              <label className="sub-text">Beneficiary Account #</label>
              <input className="dark-input" defaultValue={'670912226'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">SWIFT / BIC Code</label>
              <input className="dark-input" defaultValue={'CHASUS33'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">SWIFT ABA/routing/transit #</label>
              <input className="dark-input" defaultValue={'021000021'} readOnly />
            </div>
            <div className="row row0">
              <label className="sub-text">Memo/Special Instructions</label>
              <input className="dark-input" defaultValue={`FFC Weave, Inc, Account number 347400857065224 payment for invoice #${invoice.id}`} readOnly />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
