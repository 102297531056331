import React from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainCell from './crm-table-main-cell'

export default class WatchlistTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Globals.getUser(),
    };
  }

  componentDidMount() {}

  render() {
    var { usernames } = this.props, user = this.state.user;
    var sortFilter = this.props.sortFilter

    return (
      <div>
        <div className="crm-table">
          <div className="crm-table-header">
            <div className={`row row0 crm-row ${this.props.selectedActive ? "selected" : ""}`}>
              {/*<div className="crm-td crm-td-xs crm-td-img">
                <input type="checkbox" style={{ display: "block" }} onClick={this.props.selectInfluencer.bind(this.props.this, "all")} checked={this.props.selectedActive} />
              </div>*/}
              <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              {/*<CRMTableHeaaderCell label="Status" title="Status" size="crm-td-sm" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Created" title="Created" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Last Updated" title="Last Updated" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />*/}
            </div>
          </div>

          <div className="crm-table-main">
            {usernames.map((username) => {

              return (
                <div key={username} className={`row row0 new-module crm-row crm-row-influencer`}>
                  <div className="">
                    {/*<CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={''} initial={''} hoverFunc={this.props.selectInfluencer} this={this.props.this} />*/}
                    <CRMTableMainCell title={username} size="crm-td-md" classes="crm-username bold-text" this={this.props.this} />
                    {/*<CRMTableMainCell title={campaign.active ? 'Active' : 'Inactive'} size="crm-td-sm" type="tile" tileColor={campaign.active ? 'color-complete' : 'color-error'}  this={this.props.this} />
                    <CRMTableMainCell title={moment(campaign.created_at).format('MMM M, YYYY')} size="crm-td-md" this={this.props.this} />
                    <CRMTableMainCell title={moment(campaign.updated_at ? campaign.updated_at : campaign.created_at).format('MMM M, YYYY')} size="crm-td-md" this={this.props.this} />*/}
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.loading && <Loader active={true} />}
        </div>
      </div>
    );
  }
}
