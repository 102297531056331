import ShippingTrackingBar from '../../shipping-tracking/shipping-tracking-bar';
import ShippingTrackingProductBar from '../../shipping-tracking/shipping-tracking-product-bar';
import ShippingTrackingAppBar from '../../shipping-tracking/shipping-tracking-app-bar';
import ShippingTrackingGameBar from '../../shipping-tracking/shipping-tracking-game-bar';
import ShippingTrackingRestaurantBar from '../../shipping-tracking/shipping-tracking-restaurant-bar';
import SlideOutProgressTitle from '../../slide-out/slide-out-progress-title';
import './invite-steps.scss';

export interface InviteStepsProps {
  status: string;
  content: object;
  offer: object;
}

let STAGES = {
  product: {
    steps: 4,
    'brand_awaiting': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_scheduled': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_emailed': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_applied': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_responded': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_shortlist': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    'brand_invited': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 1, icon: 'cart' },
    // 'brand_awaiting': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_scheduled': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_emailed': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_applied': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_responded': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_shortlist': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    // 'brand_invited': { title: `Accept Collaboration`, description: `Review all guidelines & accept the invitation to start`, step: 1, icon: 'cart' },
    'brand_active': { title: `Order Product Gift`, description: `Review the guidelines & order your product below`, step: 2, icon: 'cart' },
    'brand_shipped': { title: `Product On The Way`, description: `This campaign link will update once your product is delivered`, step: 2, icon: 'map-marker-home' },
    'brand_received': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 3, icon: 'file-check' },
    'brand_review': { title: `Content Approved to Post`, description: `Your content is approved to post on your socials`, step: 3, icon: 'hastag' },
    'brand_live': { title: `Content Live`, description: `Pending final approval from the brand for completion`, step: 4, icon: 'hastag' },
    'brand_completed': { title: `Bounty Completed`, description: `You have completed the bounty!`, step: 4, icon: 'check' },
    'brand_archived': { title: `Bounty Paused`, description: `The brand has paused your collaboration - please email or chat us below`, step: false, icon: 'exclamation' },
  },
  app: {
    steps: 3,
    'brand_awaiting': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_scheduled': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_emailed': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_applied': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_responded': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_invited': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_active': { title: `Submit Content for Review`, description: `Follow guidelines to create content & upload for review here`, step: 1, icon: 'file-check' },
    'brand_shipped': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: 'file-check' },
    'brand_received': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: 'file-check' },
    'brand_review': { title: `Content Awaiting Review`, description: `Your content will be reviewed by the brand before posting`, step: 2, icon: 'hastag' },
    'brand_approved': { title: `Content Approved to Post`, description: `Your content is approved to post on your socials`, step: 2, icon: 'hastag' },
    'brand_live': { title: `Content Live`, description: `Pending final approval from the brand for completion`, step: 3, icon: 'hastag' },
    'brand_completed': { title: `Bounty Completed`, description: `You have completed the bounty!`, step: 3, icon: 'check' },
    'brand_archived': { title: `Bounty Paused`, description: `The brand has paused your collaboration - please email or chat us below`, step: false, icon: 'exclamation' },
  },
  game: {
    steps: 3,
    'brand_awaiting': { title: `Redeem Game`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_scheduled': { title: `Redeem Game`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_emailed': { title: `Redeem Game`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_applied': { title: `Redeem Game`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_responded': { title: `Redeem Game`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_invited': { title: `Redeem Game`, description: `Review and accept the invitation here.`, step: 1, icon: '' },
    'brand_active': { title: `Redeem Game`, description: `Follow the guidelines below to promote the bounty`, step: 1, icon: '' },
    'brand_shipped': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_received': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_review': { title: `Submit Content for Review`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_live': { title: `Content Live`, description: `Pending final approval from the brand for completion`, step: 1, icon: '' },
    'brand_completed': { title: `Bounty Completed`, description: `You have completed the bounty!`, step: 1, icon: '' },
    'brand_archived': { title: `Bounty Paused`, description: `The brand has paused your collaboration - please email or chat us below`, step: false, icon: 'exclamation' },
  },
  restaurant: {
    steps: 4,
    'brand_awaiting': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_scheduled': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_emailed': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_applied': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_responded': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_invited': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_active': { title: `Dine In Restaurant`, description: `Follow the guidelines below to dine at the restaurant`, step: 1, icon: '' },
    'brand_shipped': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_received': { title: `Awaiting Acceptance`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_review': { title: `Submit Content for Review`, description: `You will be notified once you're accepted`, step: 1, icon: '' },
    'brand_live': { title: `Content Live`, description: `Pending final approval from the brand for completion`, step: 1, icon: '' },
    'brand_completed': { title: `Bounty Completed`, description: `You have completed the bounty!`, step: 1, icon: '' },
    'brand_archived': { title: `Bounty Paused`, description: `The brand has paused your collaboration - please email or chat us below`, step: false, icon: 'exclamation' },
  }
}

export const InviteSteps: React.FC<InviteStepsProps> = ({status, content, offer, brandType}) =>  <div>
    <div className={`shipping-tracking shipping-tracking-game animated invite-steps`}>
      {
        brandType && status && STAGES[brandType] && STAGES[brandType][status] && offer?.id != 'cd30619e-2097-415d-9c0c-b758b2fded96' &&
        <>
          { (content?.accepted || true) && STAGES[brandType][status].step &&
            <div className="row row0" style={{margin: '0 0 25px 0'}}>
              <ShippingTrackingBar status={status} animated={true} size="large" stepsCt={STAGES[brandType]?.steps} stepNum={STAGES[brandType][status]?.step} icon={STAGES[brandType][status]?.icon} />
            </div>
          }

          {/*{ !content?.accepted &&
            <div className="row row0" style={{margin: '25px 0'}}>
              <button className="btn-action btn-primary" style={{float: 'initial', margin: 0}}>Accept Collaboration</button>
            </div>
          }*/}

          <div className="row row0 shipping-meta" style={{marginBottom: '50px'}}>
            <SlideOutProgressTitle title={STAGES[brandType][status]?.title || ''} description={STAGES[brandType][status]?.description || ''} />
          </div>

          {/*{ !content?.accepted &&
            <div className="row row0" style={{margin: '15px 0 35px'}}>
              <button className="invitation-accept-btn">Accept Collaboration</button>
            </div>
          }*/}
        </>
      }
    </div>
</div>
