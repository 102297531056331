import './index.scss';
import React from 'react';
import './global-deps';
import { render } from 'react-dom';

import Routes from './js/routes.jsx';
import { Provider } from 'react-redux';
import { configureStore } from './configure-store'
// import weaveApp from '../reducers';
// let store = createStore(weaveApp)
// <Provider store={store}>

import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import { PersistGate } from 'redux-persist/integration/react';
import { Integrations } from "@sentry/tracing";
import { isDev, isProd } from './js/hooks';



if (isProd()) {
  hotjar.initialize(1042774, 6);
  Sentry.init({
    dsn: "https://2530373fac604699a71df7bc973bc391@o67929.ingest.sentry.io/5253175",
    release: 'influencer-frontend@' + process.env.REACT_APP_GIT_SHA,
    integrations: [new Integrations.BrowserTracing()],
  });
}

console.log('Running in', isDev()? 'development': 'production', 'mode.' );
const { store, persistor } = configureStore();

render (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById('weave-react-container') ||document.body
);

