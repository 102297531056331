import React from 'react';
import Globals from '../global-functions.js';

export default class GmailRedirect extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    // let social = this.gp('social')
    // if (social == 'youtube'){
    //   Cookies.remove('onboarding')
    //   Cookies.remove('onboarding-status')
    //   location.href = '/dashboard'
    // }

    setTimeout(() => {
      // var code = location.search.split('code=')[1].split('&')[0]
      var code = Globals.gp('code')
      this.authenticateGmail(code).done((response) => { location.replace('/outreach') })
    }, 50)
  }

  authenticateGmail(code){ return $.ajax({ method: 'POST', url: '/api/YouTube/authenticateGmail', data: { code } }) }

  render() {
    return (
      <div>
        <div style={{position: 'relative', width: '100vw', height: '100vh'}}>
          <div className="results-empty-message">
            <img src={Globals.iconGeneral('google_dark_normal')} style={{ height: '40px', marginRight: '24px'}} />
            <img src={Globals.icon('logo', 'black')} style={{ height: '40px'}} />
            <h3>Connecting Gmail...</h3>
            <p className="sub-text">You will be redirected to Weave in a few seconds...</p>
          </div>
        </div>
      </div>
    )
  }
}



// tokens {
//   access_token: 'ya29.a0ARrdaM-62St_Nuk-d9xksz1mPLyeQCvaaXz_r0Qew2gXrIYGge4bJQfxHvdG8H3CZHuE96NwZlohxr8U_oEAeX6Lp6O4wH_DtWmnrM5RV1XQINdmsvVqc5i-8janXAZl4-daX3NS5qd7JEklzRJSw7ENc0jj',
//   refresh_token: '1//0ffok2ToBiiz5CgYIARAAGA8SNwF-L9IrD-jdnGK7Lh8s72C0BI1TvGYBc8j7CpHhgDUcmLw-d89oNjw9ic2_mp2Q9v1gREdA-FE',
//   scope: 'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid https://mail.google.com/ https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.labels',
//   token_type: 'Bearer',
//   id_token: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjJiMDllNzQ0ZDU4Yzk5NTVkNGYyNDBiNmE5MmY3YjM3ZmVhZDJmZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzNDAxNzg0NDMwMDItamFrYjZhcjQ4ZzJiaWdzYm43dmlwN3VhYWI3ZDRvMmouYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIzNDAxNzg0NDMwMDItamFrYjZhcjQ4ZzJiaWdzYm43dmlwN3VhYWI3ZDRvMmouYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDA1NjQyODY3MDE2OTA4OTU5OTMiLCJlbWFpbCI6IndpbGxtZWFzZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IlJXU2p6OERuRWFlWU1OWkpJcDYxN2ciLCJuYW1lIjoiV2lsbCBNZWFzZSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQVRYQUp6WG9xeFV3Xy1UaGRLZjZTZTFWeDIyRmdxLWZpQy10Tl8yVldOWXZ3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IldpbGwiLCJmYW1pbHlfbmFtZSI6Ik1lYXNlIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2NTYxODQxMzAsImV4cCI6MTY1NjE4NzczMH0.qPyKFPe9T4LNNqY8vnnLYYCi_RIx3KSge39lLO9usQFZovPE4l6UDWY_6HJ_LgW49iwR_bz9CFyaK6pqBJHQsDh4Ri7a2lItX7I868oOiIkdL8p5j2y-L0ddqhVD2E-LewmV5n5HG4I1kXLpC0KrpiuMA1IxnbgpH0bO42AGiDU5DfnKWpn5s0LesbLXCFcI4Y91VejGix3sFkhio8lT2wQZYExKDvX-xLwlmmdj5bUj3jxtAUqPdJcbSF_GIVjngq3ds6bn1tCxweoqp3PvJe8BxZA1JxI_x927mGReGsvGayg2epXkttMJ29rwoJOcMGSkhA6NKiRCQvDv3UbvlA',
//   expiry_date: 1656187729660
// }
// payload {
//   iss: 'https://accounts.google.com',
//   azp: '340178443002-jakb6ar48g2bigsbn7vip7uaab7d4o2j.apps.googleusercontent.com',
//   aud: '340178443002-jakb6ar48g2bigsbn7vip7uaab7d4o2j.apps.googleusercontent.com',
//   sub: '100564286701690895993',
//   email: 'willmease@gmail.com',
//   email_verified: true,
//   at_hash: 'RWSjz8DnEaeYMNZJIp617g',
//   name: 'Will Mease',
//   picture: 'https://lh3.googleusercontent.com/a/AATXAJzXoqxUw_-ThdKf6Se1Vx22Fgq-fiC-tN_2VWNYvw=s96-c',
//   given_name: 'Will',
//   family_name: 'Mease',
//   locale: 'en',
//   iat: 1656184130,
//   exp: 1656187730
// }
