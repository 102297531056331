import React from 'react';
import Cookies from 'js-cookie';

export default class Analyze extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      user: JSON.parse(Cookies.get('userdata').replace('j:', ''))
    }
  }

  componentDidMount(){
    if (!this.state.user.internal){ window.Intercom("update"); }
  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <h3>Analyzing...</h3>
      </div>
    )
  }
}
