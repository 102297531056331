import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import {isDevelopment} from "../../../../../influencer-backend/api/utils/env";

export default class BrandLogin extends React.Component {

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
  }

  onSubmit(e){
    e.preventDefault()
    $('#login').text('LOGGING IN...')
    var username = $('#email').val()
    var password = $('#password').val()
    const dev = isDevelopment();

    return $.ajax({
      method: 'GET',
      // url: 'https://brands.weavesocial.com/api/login/verify',
      url: dev? 'http://localhost:1337/api/login/verify': 'https://brands.weavesocial.com/api/login/verify',
      data: { email: username, password },
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true
    }).done((response) => {
      if (response.success){
        // var user = response.user
        // Globals.logEvent('event', 'loginSuccess')
        // Cookies.set('userid', response.user.userid)
        // Cookies.set('userdata', response.user)
        // store('access_token', user.access_token)
        // store('userid', user.userid)
        setTimeout(() =>
          dev?
            location.replace(`http://localhost:3001/home`) :
            location.replace(`https://brands.weavesocial.com/home`),
          1000);

      }
      else {
        // Globals.logEvent('event', 'loginFailure')
        alert(response.message || 'Invalid password')
        $('#login').text('LOG IN')
      }
    })
  }

  render() {
    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div className="login-box-inner">
              <header>
                <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </header>
              <div className="login-padding">
                {/*<div className="col-md-12" style={{margin: '10px 0'}}>
                  <a className="social-button" onClick={this.youtubeLogin.bind(this)}>
                    <span className="social-button-icon"></span>
                    <span className="social-button-text">LOGIN WITH YOUTUBE</span>
                  </a>
                </div>*/}
                <h3 style={{textAlign: 'center', margin: '0 0 25px 0'}}>Brand Login</h3>
                <form onSubmit={this.onSubmit.bind(this)}>
                  <div className="input-text-wrapper email">
                    <input id="email" className="input-text" placeholder="Email or Username" />
                  </div>
                  <div className="input-text-wrapper password">
                    <input id="password" className="input-text" placeholder="Password" type="password" />
                  </div>
                  <button id="login" type="submit" className="btn-submit">LOG IN</button>
                </form>
                <p className="switch-boxes">Still no account? Please <Link to="/brand-signup"><b>sign up!</b></Link></p>
                <p className="switch-boxes">Forgot password? <Link to="/brand-forgot"><b>Reset here.</b></Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
