import { createReducer } from 'typesafe-actions';
import { GetSelectedProductFailed, Product } from '../models/bounty.models';
import { Nullable } from '../../utils/utils';
import { bountyActionCreators, BountyActions } from '../actions/bounty.actions';

type SelectedProductState = {
  data: Nullable<Product>;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetSelectedProductFailed>;
};

const initialState: SelectedProductState = Object.freeze({
  data: null,
  hasError: false,
  loading: false,
  error: null
});

export const selectedProductReducer = createReducer<SelectedProductState, BountyActions>(
  initialState,
).handleAction(bountyActionCreators.getSelectedProductAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    bountyActionCreators.getSelectedProductSuccessAction,
    (state, action) => ({
      ...initialState,
      data: action.payload?.product,
    }),
  )
  .handleAction(bountyActionCreators.getSelectedProductFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

