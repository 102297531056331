import { memo } from 'react';
import Globals from '../../global-functions.js';
import Icon from '../icon'

const DynamicValueCopy = ((props) => {
  var { icon, title, label, type, copyValue, _this } = props

  return (
    <li>
      <div className="dark-input" onClick={copyValue.bind(_this, `${label}-${type}`)}>
        { icon && <Icon icon={icon} style={{margin: '-2px 6px 0 0'}} /> }
        <span>{title}</span>
        <span style={{float: 'right'}}><Icon iconPack="copy-alt" style={{margin: '-2px 6px 0 0'}} defaultColor={true} /></span>
      </div>
      <input id={`dynamic-value-${label}-${type}`} value={`{{ ${label}.${type} }}`} />
    </li>
  );
})

export default memo(DynamicValueCopy);
