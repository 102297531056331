// todo: to be removed. from:

// <!--SCRIPTS-->
// <script src="/js/dependencies/jquery.min.js?v=1611448257212"></script>
// <!--SCRIPTS END-->
const w = window as any;

w.jQuery = require('./js/dependencies/jquery.min.js');
w.$ = w.jQuery;
w.moment = require('./js/dependencies/moment.min.js');
w.flatpickr = require('./js/dependencies/flatpickr.min.js');

// require('./js/dependencies/jquery-ui.js');
// require('./js/dependencies/bootstrap.min.js');
// require('./js/dependencies/bootstrap-5.2.0.min.js');
require('./js/dependencies/bootstrap-3.4.1.min.js');
// require('./js/bundle.js');
