import React from 'react';

export default class SlideOutMainBtn extends React.Component {

  render() {
    return (
      <div className="menu-checkout menu-checkout-accept">
        <button className="menu-checkout-btn" onClick={this.props.onClickFunc.bind(this.props.this)}>
          <div className="menu-checkout-btn-container" style={{padding: '12px 0'}}>
            <div className="row">
              <div className="menu-checkout-btn-text">
                <span>{this.props.title}</span>
                {this.props.description && <p style={{color: '#fff', textTransform: 'initial'}}>{this.props.description}</p>}
              </div>
            </div>
          </div>
        </button>
      </div>
    )
  }
}
