import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';

export default class NewBrandsRestaurants extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    return (
      <div className="landing">
        <div className="section hero">
          <div className="background-strip" style={{backgroundImage: `url(${Globals.landing('group2.svg')})`}}></div>
          <div className="container">
            <Link to="/">
              <img className="logo-hero" src={Globals.icon('logo-text', 'black')} alt="Weave Logo" />
            </Link>
            <div className="login-container">
              <div className="login-container-inner">
                <Link to="/influencers"><span className="signup" style={{marginRight: '45px'}}>influencers</span></Link>
                <Link to="/brands"><span className="signup" style={{marginRight: '45px'}}>brands</span></Link>
                <Link to="/login"><span>login</span></Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="title">
                <h1>Grow your customers through local foodies.</h1>
                <p style={{color: '#000'}}>The easiest way to partner with local foodies to market your restaurant on Instagram.</p>
                <div className="actions">
                  <div className="row row0">
                    <div className="btn-container">
                      <Link to="/signup"><span className="get-started agency btn-apply" style={{marginRight: '15px'}}>Get Started</span></Link>
                    </div>
                    <div className="btn-container">
                      <Link to="/foodies"><span className="get-started learn-more agency btn-apply">I'm a foodie</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="hero-icons">
                <img className="shadow" src={Globals.landing('map3.png')} />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container" style={{paddingTop: '50px', textAlign: 'left'}}>
            <div className="" style={{maxWidth: '650px', margin: '0 auto'}}>
              <div className="row" style={{}}>
                <div className="ur-goddamn-right" style={{maxWidth: '680px'}}>
                  <div className="" style={{display: 'block', margin: '0 auto', textAlign: 'center', width: 'calc(100%)', maxWidth: '550px'}}>
                    <h2 style={{maxWidth: '100%', marginTop: '18px'}}>Weave is bringing restaurants digital.</h2>
                  </div>
                </div>
              </div>
              <p style={{fontSize: '22px', color: '#6b7c93', maxWidth: '100%', margin: '25px 0 0', textAlign: 'center'}}>We understand the difficulty of marketing your restaurant online. We make it ridiculously simple.</p>
            </div>
          </div>
        </div>

        <div className="section sliver how-it-works" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <h2 style={{textAlign: 'center', fontSize: '38px', marginBottom: '50px'}}>How It Works</h2>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-3 how-3points">
                    <img src={Globals.landing('foodie-3points.svg')} />
                  </div>
                  <div className="col-sm-9 col-xs-12">
                    <div className="row row0 how-it-works-text">
                      <img className="how-point-small" src={Globals.landing('foodie-point1.svg')} />
                      <h2 style={{marginBottom: '8px'}}>Serve</h2>
                      <p className="sub-text">Foodies purchase a meal from your restaurant</p>
                    </div>
                    <div className="row row0 how-it-works-text">
                      <img className="how-point-small" src={Globals.landing('foodie-point2.svg')} />
                      <h2 style={{marginBottom: '8px'}}>Share</h2>
                      <p className="sub-text">Foodies share a photo of their meal to Instagram</p>
                    </div>
                    <div className="row row0 how-it-works-text">
                      <img className="how-point-small" src={Globals.landing('foodie-point3.svg')} />
                      <h2 style={{marginBottom: '8px'}}>Reimburse</h2>
                      <p className="sub-text">Weave reimburses the foodie the cost of the meal</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <img src={Globals.landing('bounty-meal.png')} style={{width: '300px', margin: '0 auto'}} />
              </div>
            </div>
          </div>
        </div>

        <div className="section sliver" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 feature-text">
                <h2>Approve foodies in a simple click.</h2>
                <p>Foodies will request to eat at your restaurant & you only approve the foodies that fit your audience.</p>
              </div>
              <div className="col-sm-6 col-sm-pull-6 icon-display adjust-left">
                <img src={Globals.landing('people.png')} />
              </div>
            </div>
          </div>

          <div className="container earnings-container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Connect with thousands of local foodies.</h2>
                <p>Weave’s platform exposes your restaurant to thousands of influential foodies in your area effortlessly.</p>
              </div>
              <div className="col-sm-6 icon-display">
                <img src={Globals.landing('map2.png')} />
              </div>
            </div>
          </div>
        </div>


        <div className="section" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 feature-text">
                <h2>Convert customers & track ROI through smart coupons.</h2>
                <p>Influencers direct followers to unique coupons to track how many followers are converted to purchases.</p>
              </div>
              <div className="col-sm-6 col-sm-pull-6 icon-display adjust-left">
                <img src={Globals.landing('coupon-left.png')} />
              </div>
            </div>
          </div>
        </div>

        {/*<div className="section" style={{position: 'relative'}}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Repurpose content across your digital media.</h2>
                <p>Use the content foodies create about your restaurant across all your other digital channels.</p>
              </div>
              <div className="col-sm-6 icon-display">
                <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/searchandautoconverticonhomecard.svg" />
              </div>
            </div>
          </div>
        </div>*/}

        <div className="section" style={{overflow: 'hidden', paddingBottom: 0}}>
          <div className="shard-1"></div>
          <div className="shard-2"></div>

          <div className="container split-features" style={{display: 'none', padding: 0}}>
            <div className="col-sm-6 split-left">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/codehomelogo-blue.svg" alt="Weave Logo" />
              </div>
              <h3>ALWAYS DEVELOPING</h3>
              <p>Weave is an ever-growing platform that constantly develops new features. Our goal is to build technology for the modern day creator that helps them truly improve performance and earn more from their content. We know that you work hard to create posts that add value to people’s lives, and we want Weave to add as much value as possible to yours.</p>
            </div>
            <div className="col-sm-6 split-right">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/highfivehomeicon-blue.svg" alt="Weave Logo" />
              </div>
              <h3>CREATOR FOCUSED</h3>
              <p>We initially started Weave to help creators monetize their YouTube videos. We saw that other companies were offensively exclusive, charged outrageous fees on earnings and never built tools to actually help the performance of their users. By offering the simplest, yet most advanced platform on the internet and connecting you to thousands of retailers, we know that we can change the way you earn from your content.</p>
            </div>
          </div>

          <div className="container final-get-started">
            <h2 style={{marginBottom: '25px', color: 'white'}}>{'Ready to join?'}</h2>
            <div className="btn-container" style={{float: 'initial'}}>
              <Link to="/signup"><button className="get-started" style={{float: 'initial', margin: '15px 0 0 0'}}>Get Started</button></Link>
            </div>
          </div>

          <footer className="footer">
            <div className="container">
              <div className="logo-container footer-left">
                <img className="logo" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </div>
              <div className="footer-right">
                <a href="https://medium.com/weavesocial" target="_blank">Blog</a>
                <a href="mailto:talent@weavesocial.com?Subject=Hello" target="_top">Contact Us</a>
                <a href="/terms-of-service" target="_blank">Terms of Use</a>
                <a href="/privacy" target="_blank">Privacy Policy</a>
                <a>Weave, Inc © 2022</a>
              </div>
            </div>
          </footer>
        </div>

      </div>
    )
  }
}
