import React from 'react';
import Globals from '../../../global-functions';
import { Objective } from '../../../services/campaign-service';
import './edit-deliverable.scss';
import { InvitePanel } from '../invite-panel/invite-panel';
import { DropzoneInput } from '../../shared/dropzone-input';
import { ContentForReview } from '../../slide-out/content-for-review/content-for-review';

export interface EditDeliverableProps {
  campaign?: object;
  objectives?: Array<object>;
  typeId: string,
  offerId: string,
  contentId: string,
  reviewType: string
}

export const EditDeliverable: React.FC<EditDeliverableProps> = (props) => {
  return <InvitePanel title={''} bottomHr={false}>
    <ContentForReview campaign={props.campaign} objectives={props.objectives} offerId={props.offerId} brandCampaignId={props.contentId} lockToTypeId={props.typeId} reviewType={props.reviewType} />
  </InvitePanel>
}
