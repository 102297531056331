import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class CreateInvoiceDeliverables extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { deliverables } = this.props

    return (
      <div className="create-invoice-deliverables">
        <div className="row row0" style={{position: 'relative'}}>
          { deliverables.map((d) => {
            return (
              <div className="row create-invoice-deliverable">
                <div className="col-xs-10">
                  <div className="create-invoice-deliverable-icon">
                    <img src={Globals.icon(d.type)} />
                  </div>
                  <div className="create-invoice-deliverable-description">
                    <i>{d.description}</i>
                  </div>
                </div>
                <div className="col-xs-1">
                  <b>{d.quantity}</b>
                </div>
                <div className="col-xs-1">
                  <div className="create-invoice-deliverable-remove">
                    <a onClick={this.props.removeDeliverable.bind(this.props.this, d)}>
                      <IconPack icon="trash" style={{width: '16px'}} />
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
          {(!deliverables || deliverables.length == 0) &&
            <div className="row row0" style={{marginTop: '8px'}}>
              <p>No deliverables added.</p>
            </div>
          }
        </div>
      </div>
    )
  }
}
