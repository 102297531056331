import React from 'react';
import Guidelines from '../guidelines.jsx';
import Globals from '../../global-functions.js';

var updateElapseInterval = null

export default class SlideOutProductIGPhoto extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      url: ''
    }
  }

  componentDidMount(){
    var bounty = this.props.bounty,
    bountyObj = bounty.bountyObj
    var obj = { bountyId: bountyObj.id }
    if (bountyObj.post && bountyObj.post.url){
      obj.url = bountyObj.post.url
    }
    this.setState(obj)
  }

  uploadPost(){}

  checkPostFormat(){
    if (this.state.url.indexOf('instagram.com/p/') > -1){
      return true
    }
    else return false
  }

  onChangeInput(type, e){
    this.setState({
      [type]: e.target.value
    })
  }


  render() {
    var campaign = this.props.campaign,
    game = campaign.game ? campaign.game : campaign.offer,
    content = campaign ? campaign.content : {},
    campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    user = campaign.user,
    twitchUser = this.props.twitchUser ? this.props.twitchUser : {},
    offer = campaign.offer,
    bounty = this.props.bounty,
    bountyObj = bounty.bountyObj,
    bountyOffer = bounty.bountyOffer,
    video = bountyObj.video ? bountyObj.video : false

    var objectives = bountyObj.objectives ? bountyObj.objectives.map((obj) => {
      if (obj.type == 'link'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add <b>{obj.value}</b> above the fold in video description</span></p>
        )
      }
      else if (obj.type == 'hashtag'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add <b>#{obj.value}</b> to video description</span></p>
        )
      }
      else if (obj.type == 'discount'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add discount <b>{obj.value}</b> to video description</span></p>
        )
      }
    }) : []

    return (
      <div>
        <div className="module bounty-objective bounty-objective-slideout">
          <div className="row bounty-objective-row">
            <div className="module bounty-objective-container">
              <div className="bounty-platform-color bounty-platform-color-twitch"></div>
              <div className="col-xs-2 bounty-platform-icon">
                <img src="/images/achievements/instagram-logo.svg" />
              </div>
              <div className="col-xs-7 bounty-objective-text">
                <p className="bounty-objective-p">
                  <b style={{fontSize: '16px'}}>1 IG Feed Post</b>
                  <br />
                  {/*<span className="sub-text">${bountyObj.proposedOffer} • {bountyObj.proposedViews}+ views</span>*/}
                </p>
                {
                  bountyObj.missingRequirements && false &&
                  <p className="bounty-requirement-missing">{`*Missing ${bountyObj.missingRequirements} requirement${bountyObj.missingRequirements > 1 ? 's' : ''}`}</p>
                }
              </div>
              <div className="col-xs-3">
                <p className="bounty-objective-status"><b>{bountyObj.viewProgress ? bountyObj.viewProgress : 0}</b><span> / {bountyObj.proposedViews ? bountyObj.proposedViews : 1}</span></p>
              </div>
            </div>
            <div className="bounty-progress">
              <div className="bounty-progress-outer">
                <div className="bounty-progress-inner" style={{width: `${bountyObj.completionPerc}%`}}></div>
              </div>
            </div>
          </div>
          {
            (content.status == 'brand_posting' ||
            content.status == 'brand_received' ||
            content.status == 'brand_live') &&
            <>
              <div className="row bounty-objective-expanded" style={{margin: 0}}>
                <div className="module bounty-objective-container">
                  {
                    video &&
                    video.videoId &&
                    <div className="row bounty-objective-video">
                      <div className="module bounty-objective-video-img">
                        <img src={`https://img.youtube.com/vi/${video.videoId}/mqdefault.jpg`} />
                      </div>
                      <div className="module bounty-objective-video-text">
                        <p>{video.title}</p>
                      </div>
                    </div>
                  }
                  <Guidelines
                    guidelines={bountyObj.objectives}
                  />
                </div>
                <div className="module bounty-objective-container guidelines-container" style={{margin: 0}}>
                  <div className="row guideline">
                    <div className="col-xs-3">
                      {
                        bountyObj.post &&
                        bountyObj.post.shortcode &&
                        <div className="bounty-objective-add-post" style={{backgroundImage: `url(https://www.instagram.com/p/${bountyObj.post.shortcode}/media/?size=m)`}}></div>
                      }
                      {
                        (!bountyObj.post ||
                        !bountyObj.post.shortcode) &&
                        <div className="bounty-objective-add-post">
                          <svg className="SVGInline-svg SVGInline--cleaned-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="b5f73d9c-be37-45b9-924c-84a9c2d374ac" dataname="Design"><path d="M23.11,12a.5.5,0,0,1-.5.5H12.5V22.62a.5.5,0,1,1-1,0V12.51H1.39a.5.5,0,1,1,0-1H11.5V1.4a.5.5,0,0,1,1,0V11.51H22.61A.5.5,0,0,1,23.11,12Z"></path></g></svg>
                        </div>
                      }
                    </div>
                    <div className="col-xs-9">
                      <div className="guideline-text" style={{marginBottom: '15px'}}>
                        <p className="guideline-main">Paste link to IG post</p>
                        <p className="guideline-sub sub-text">If not detected automatically, paste link here.</p>
                      </div>
                      <div className="trackable-link" style={{float: 'right'}}>
                        <div className="trackable-link-wrapper">
                          <input className="dark-input" id={`guideline-input-${this.state.copyId}`} value={this.state.url} onChange={this.onChangeInput.bind(this, 'url')} style={{width: '100%'}} />
                          {
                            this.checkPostFormat() &&
                            <div className="trackable-link-copy">
                              <div className="trackable-link-copy-container">
                                <img src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${Globals.themeMode == 'dark' ? 'white' : 'black'}/share-1-icon.svg`} onClick={this.uploadPost.bind(this)} style={{cursor: 'pointer'}} />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

}
