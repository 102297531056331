import React from 'react';
import { Link } from 'react-router-dom';
import ShippingTrackingRestaurantBar from './shipping-tracking-restaurant-bar.jsx';
import SlideOutProgressTitle from '../slide-out/slide-out-progress-title.jsx';

export default class ShippingTrackingRestaurant extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      cards: {}
    }
  }

  componentDidMount(){
    this.getCards()
  }

  getCards(){
    this._getCards().done((response) => {
      this.setState({
        cards: response.cards
      })
    })
  }

  _getCards(){
    return $.ajax({
      method: 'GET',
      url: '/api/Card/getCards',
      data: {

      }
    })
  }

  getCard(viewType, proceed){
    if (proceed){
      this._getCard(viewType).done((response) => {
        var c = this.state.cards
        console.log()
        var cards = { physical: c.physical, virtual: response.card }
        this.setState({
          cards: cards
        })
      })
    }
  }

  _getCard(viewType){
    return $.ajax({
      method: 'GET',
      url: '/api/Card/get',
      data: {
        userid: this.state.userid,
        viewType: viewType
      }
    })
  }


  render() {
    var props = this.props, status = this.props.status

    return (
      <div>
        <div className="shipping-tracking shipping-tracking-restaurant">
          <div className="row row0 shipping-stages stages-3" style={{margin: '25px 0'}}>
            <ShippingTrackingRestaurantBar status={status} animated={true} size="large" />
          </div>

          {/*<h3 style={{marginTop: '25px'}}>Status</h3>*/}
          <div className="row row0 shipping-meta">

          {/*{ status == 'brand_awaiting' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
          { status == 'brand_applied' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
          { status == 'brand_responded' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
          { status == 'brand_active' && <SlideOutProgressTitle title="Post Content" description="Follow the guidelines below to promote the app" /> }
          { status == 'brand_live' && <SlideOutProgressTitle title="Promote your link" description="Continue to promote your link until bounty expires" /> }
          { status == 'brand_completed' && <SlideOutProgressTitle title="Bounty Completed" description="The bounty has now been finished" /> }*/}

            {
              status == 'brand_applied' &&
              <>
                <h4 className="shipping-meta-title">Awaiting Acceptance</h4>
                <p className="sub-text shipping-meta-desc" style={{margin: 0}}>The restaurant is reviewing your request, and you will be notified once you're accepted or passed. If you are accepted, you may use your Weave virtual credit card to order your meal on Doordash or Postmates.</p>
              </>
            }
            {
              status == 'brand_responded' &&
              <>
                <h4 className="shipping-meta-title">Awaiting Acceptance</h4>
                <p className="sub-text shipping-meta-desc" style={{margin: 0}}>The restaurant is reviewing your request, and you will be notified once you're accepted or passed. If you are accepted, you may use your Weave virtual credit card to order your meal on Doordash or Postmates.</p>
              </>
            }
            {
              status == 'brand_active' &&
              <>
                <h4 className="shipping-meta-title">Go eat!</h4>
                <p className="sub-text shipping-meta-desc" style={{margin: 0}}>You have been accepted! Order and create your content.</p>

                {/*<div className="row">
                  <div className="col-md-4" style={{width: '170px'}}>
                    {
                      this.state.cards.physical &&
                      <StripeCard
                        this={this}
                        card={this.state.cards.physical}
                        getCard={this.getCard}
                        scale={0.35}
                        top={'-81.5px'}
                        left={'-130px'}
                      />
                    }
                  </div>
                  <div className="col-md-8">
                    <p>Pay with your card</p>
                    <p className="sub-text shipping-meta-desc">Your Weave Card is synced with this bounty. Pay for your meal in the restaurant with your Weave Card. Don't worry about tipping either, Weave takes care of all of this for you.</p>
                  </div>
                </div>*/}
              </>
            }
            {
              status == 'brand_dining' &&
              <>
                <h4 className="shipping-meta-title" style={{textTransform: 'initial'}}>Order via Postmates</h4>
                <h4 className="shipping-meta-title" style={{textTransform: 'initial', margin: '0 0 15px 0'}}><b>$50</b> meal credit</h4>
                <p style={{margin: '0 0 25px 0'}}>Use your <b>virtual Weave payment card</b> to purchase your meal - your card will authorize a purchase up to $50. After your purchase, please tip $10 in the app with your Weave card as well. You can find your virtual card details in your <Link to="/account">account settings</Link></p>

                {/*{
                  true &&
                  <div className="row">
                    <div className="col-md-4" style={{width: '170px'}}>
                      {
                        this.state.cards.virtual &&
                        <StripeCard
                          this={this}
                          card={this.state.cards.virtual}s
                          getCard={this.getCard}
                          scale={0.35}
                          top={'-81.5px'}
                          left={'-130px'}
                        />
                      }
                    </div>
                    <div className="col-md-8">
                      <p>Pay with your card</p>
                      <p className="sub-text shipping-meta-desc">Your Weave Card is synced with this bounty. Pay for your meal in the restaurant with your Weave Card. Don't worry about tipping either, Weave takes care of all of this for you.</p>
                    </div>
                  </div>
                }*/}

                {/*{
                  false &&
                  <div className="row">
                    <div className="col-md-4" style={{width: '170px'}}>
                      {
                        this.state.cards.physical &&
                        <StripeCard
                          this={this}
                          card={this.state.cards.physical}
                          getCard={this.getCard}
                          scale={0.35}
                          top={'-81.5px'}
                          left={'-130px'}
                        />
                      }
                    </div>
                    <div className="col-md-8">
                      <p>Pay with your card</p>
                      <p className="sub-text shipping-meta-desc">Your Weave Card is synced with this bounty. Pay for your meal in the restaurant with your Weave Card. Don't worry about tipping either, Weave takes care of all of this for you.</p>
                    </div>
                  </div>
                }*/}
              </>
            }
            {
              status == 'brand_posting' &&
              <>
                <h4 className="shipping-meta-title">Delivered. Time to post!</h4>
                <p className="sub-text shipping-meta-desc" style={{margin: 0}}>Post Deadline: Fri, Mar 27th</p>
              </>
            }
            {
              status == 'brand_live' &&
              <>
                <h4 className="shipping-meta-title">Post is live</h4>
                <p className="sub-text shipping-meta-desc" style={{margin: 0}}>Est. Delivery: Fri, Mar 27th</p>
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}
