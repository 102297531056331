import { createReducer } from 'typesafe-actions';
import { GetBrandCampaignFailed, BrandCampaign } from '../models/brand-campaign.models';
import { Nullable } from '../../utils/utils';
import { brandCampaignActionCreators, BrandCampaignActions } from '../actions/brand-campaign.actions';

type BrandCampaignState = {
  data: Nullable<BrandCampaign>;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetBrandCampaignFailed>;
};

const initialState: BrandCampaignState = Object.freeze({
  data: null,
  hasError: false,
  loading: false,
  error: null
});

export const brandCampaignReducer = createReducer<BrandCampaignState, BrandCampaignActions>(
  initialState,
).handleAction(brandCampaignActionCreators.getBrandCampaignAction, (state, action) => ({
    ...initialState,
    loading: true,
  }))
  .handleAction(
    brandCampaignActionCreators.getBrandCampaignSuccessAction,
    (state, action) => ({
      ...initialState,
      data: action.payload?.campaign,
    }),
  )
  .handleAction(brandCampaignActionCreators.getBrandCampaignFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

