import React from 'react';

export default class BlinkingDot extends React.Component {

  render() {
    return (
      <div className="blinking-dot">
        <div className="blinking-dot-container" style={{top: '3px'}}>
          <div className="blinking-dot-dot"></div>
        </div>
      </div>
    )
  }
}
