import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';

export default class BrandForgot extends React.Component {

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault()
    $('#login').text('RESETTING...')
    var username = $('#email').val()

    $.ajax({
      method: 'POST',
      url: '/api/Users/forgot',
      data: { username: username }
    })
    .done(function(res){
      console.log(res)
      if (res.success){
        $('#login').text('RESET EMAIL SENT')
        // Cookies.set('userid', res.user.userid)
        // Cookies.set('userdata', res.user)
        // location.href = '/dashboard'
      }
      else {
        alert(res.message)
        $('#login').text('RESET PASSWORD')
      }
    })
  }

  render() {
    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div className="login-box-inner">
              <header>
                <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </header>
              <div className="login-padding">
                <form onSubmit={this.onSubmit.bind(this)}>
                  <div className="input-text-wrapper email">
                    <input id="email" className="input-text" placeholder="Email or Username" />
                  </div>
                  <button id="login" type="submit" className="btn-submit">RESET PASSWORD</button>
                </form>
                <p className="switch-boxes">Didn't forget your password? <Link to="/brand-login"><b>Login here.</b></Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
