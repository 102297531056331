import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'

const FilterHashtag = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <div className="row row0">
        {/*<div className="filter-option-container">
          <div className="row row0">
            <label>Brand Posts #</label>
            <div className="row row0">
              <SearchDropdown endpoint="getAutocomplete" searchIndex="hashtag_index" precursor={'#'} index={0} type={type} field="name" changeFilter={changeFilter} placeholder="Select brand hashtag..." _this={_this} />
            </div>
            <div className="row row0">
              { filter[0] && filter[0].length > 0 && filter[0].map((l) => {
                return ( <PillTag key={l.id || l.name} _this={_this} icon={""} name={'#'+l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> ) })
              }
            </div>
          </div>
        </div>*/}

        <div className="filter-option-container">
          <div className="row row0">
            <label>Brand & Influencer Hashtags</label>
            <div className="row row0">
              <SearchDropdown endpoint="getAutocomplete" searchIndex="hashtag_index" precursor={'#'} index={1} type={type} field="name" changeFilter={changeFilter} placeholder="Select influencer hashtag..." _this={_this} />
            </div>
            <div className="row row0">
              { filter[1] && filter[1].length > 0 && filter[1].map((l) => {
                return ( <PillTag key={l.id || l.name} _this={_this} icon={""} name={'#'+l.name} item={l} type={type} index={1} deleteTag={changeFilter} /> ) })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(FilterHashtag);
