import React from 'react';

export default class Privacy extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return (
      <div>
        <div className="container terms">
          <h3>Weave Privacy Policy</h3>
          <p>
            Last updated: August 11, 2020
          </p>
          <p>
            Weave ("us", "we", or "our") operates https://www.weavesocial.com (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.
          </p>
          <p>
            We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.
          </p>

          <h4>Information Collection And Use</h4>
          <p>
            While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").
          </p>

          <h4>Log Data</h4>
          <p>
            Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").
          </p>
          <p>
            This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.
          </p>
          <p>
            In addition, we may use third party services such as Google Analytics that collect, monitor and analyze other information.
          </p>

          <p>Social Platforms Terms of Service</p>
          <ul>
            <li>
              Google: <a href="https://www.google.com/intl/en/policies/terms/" target="_blank">https://www.google.com/intl/en/policies/terms/</a>
            </li>
            <li>
              YouTube: <a href="https://www.youtube.com/t/terms" target="_blank">https://www.youtube.com/t/terms</a>
            </li>
            <li>
              YouTube API Client: <a href="https://developers.google.com/youtube/terms/api-services-terms-of-service" target="_blank">https://developers.google.com/youtube/terms/api-services-terms-of-service</a>
            </li>
            <li>
              Facebook: <a href="https://www.facebook.com/legal/terms" target="_blank">https://www.facebook.com/legal/terms</a>
            </li>
            <li>
              Instagram: <a href="https://help.instagram.com/478745558852511" target="_blank">https://help.instagram.com/478745558852511</a>
            </li>
            <li>
              Twitter: <a href="https://twitter.com/en/tos" target="_blank">https://twitter.com/en/tos</a>
            </li>
            <li>
              Twitch: <a href="https://www.twitch.tv/p/legal/terms-of-service/" target="_blank">https://www.twitch.tv/p/legal/terms-of-service/</a>
            </li>
          </ul>
          <p>Social Platforms Privacy Policies</p>
          <ul>
            <li>
              Google & YouTube: <a href="https://www.google.com/policies/privacy" target="_blank">https://www.google.com/policies/privacy</a>
            </li>
            <li>
              Facebook: <a href="https://www.facebook.com/privacy/explanation" target="_blank">https://www.facebook.com/privacy/explanation</a>
            </li>
            <li>
              Instagram: <a href="https://help.instagram.com/155833707900388" target="_blank">https://help.instagram.com/155833707900388</a>
            </li>
            <li>
              Twitter: <a href="https://twitter.com/en/privacy" target="_blank">https://twitter.com/en/privacy</a>
            </li>
            <li>
              Twitch: <a href="https://www.twitch.tv/p/legal/privacy-policy/" target="_blank">https://www.twitch.tv/p/legal/privacy-policy/</a>
            </li>
          </ul>
          <p>
            You can revoke Weave’s access to your data from YouTube, Google, Facebook, Instagram, and Twitter at any time via each respective platform’s app and security settings via the links below. Note that revoking such authorizations may limit or eliminate certain functionality in Weave’s Products.
          </p>
          <ul>
            <li>
              Google & YouTube: <a href="https://security.google.com/settings/security/permissions." target="_blank">https://security.google.com/settings/security/permissions.</a>
            </li>
            <li>
              Facebook: <a href="https://www.facebook.com/settings?tab=applications" target="_blank">https://www.facebook.com/settings?tab=applications</a>
            </li>
            <li>
              Instagram: <a href="https://www.instagram.com/accounts/manage_access/" target="_blank">https://www.instagram.com/accounts/manage_access/</a>
            </li>
            <li>
              Twitter: <a href="https://twitter.com/settings/applications" target="_blank">https://twitter.com/settings/applications</a>
            </li>
            <li>
              Twitch: <a href="https://www.twitch.tv/settings/connections" target="_blank">https://www.twitch.tv/settings/connections</a>
            </li>
          </ul>
          <p>
            If you have any questions, comments, or concerns regarding these terms or Weave Sites and Products, please contact us at talent@weavesocial.com.
          </p>

          <h4>Communications</h4>
          <p>
            We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information.
          </p>

          <h4>Cookies</h4>
          <h5>What Are Cookies?</h5>
          <p>
            Cookies are small data files which are stored on the browser or device that you use to view a website, social post, application, or other content. There are many types of cookies, including session cookies (which expire when you close your browser or application), persistent cookies (which expire after a certain amount of time or on a specific date), and flash cookies (written using Adobe Flash and which may be permanently store). Most major browsers are set up so that they will initially accept cookies. For additional information, please see our Privacy Policy.
          </p>
          <h5>What Are Cookies Used For?</h5>
          <p>
            Cookies help store user preferences and track user activity, both within the Services and beyond. For example, cookies help Weave recognize you at login, provide you with a personalized premium experience, and track sales in order to pay commissions to influencers after you make a purchase, and to provide other analytics to help us asses and improve our services. For additional information, please see our Privacy Policy.
          </p>
          <h5>When Do We Set Cookies?</h5>
          <p>
            We met set cookies when you visit our Sites, when you download our apps, and as you interact with our services, which includes emails, newsletters, ads, instant messages, and other communication. We may also set cookies when you are browsing a third party partner (such as a retailer website, app, or an influencers’ blog). Third party cookies and other tracking technologies may also be used in our services. For additional information, please see our Privacy Policy.
          </p>
          <h5>How Do I Control Cookies?</h5>
          <p>
            Most browsers provide you with cookie controls. For more detailed information on your Choices in relation to our Services, please see our Privacy Policy. If you choose to block, reject, delete, or change the management settings for cookies, then the Services may not function properly or at all.
          </p>

          <h4>Security</h4>
          <p>
            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
          </p>

          <h4>Information Collected, Tracked or Analyzed by Third Parties.</h4>
          <p>
            We may permit advertisers or other third parties to collect information from the Services. For example, a third party may place cookies or similar tracking technologies (such as advertising IDs or deep linking technology) and may send its own cookies to your cookie file. We may also utilize third parties to analyze data collected from the Services. We may permit third parties to collect personally identifiable information over time and across different websites and across multiple mobile apps. Please see the following third party privacy policies for additional information: <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245</a>
          </p>

          <h4>Use of Personal Information</h4>
          <p>
            We may use the Personal Information we collect or receive about you for our legitimate business interests, including:
          </p>
          <ul>
            <li>
              To enforce compliance with our Terms of Use and applicable law. This may include developing tools and algorithms that help us prevent violations.
            </li>
            <li>
              To protect the rights and safety of our Customers and third parties, as well as our own.
            </li>
            <li>
              To meet legal requirements, including complying with court orders, valid discovery requests, valid subpoenas, and other appropriate legal mechanisms.
            </li>
            <li>
              To provide information to representatives and advisors, including attorneys and accountants, to help us comply with legal, accounting, or security requirements.
            </li>
            <li>
              To prosecute and defend a court, arbitration, or similar legal proceeding.
            </li>
            <li>
              To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements.
            </li>
            <li>
              To provide, support and improve the Services. For example, this may include sharing your information with third parties in order to provide and support our Services or to make certain features of the Services available to our Customers.
            </li>
            <li>
              For our data analytics projects. Our data analytics projects use data from Weave accounts, including your Personal Information, to provide and improve the Services. We use information, like your social media content performance, so we can make more informed predictions, decisions, and products for our Customers. For example, we use Social Media Data from Creator accounts to recommend Creators for certain Customer branded content campaigns based on audience demographics. If you prefer not to share this data, you can revoke Weave’s authorization to collect data about your connected social media account at any time via the privacy settings on the respective social media platform.
            </li>
            <li>
              Other purposes. To carry out other legitimate business purposes, as well as other lawful purposes.
            </li>
          </ul>

          <h4>Control Over Your Information</h4>
          <h5>Profile and Data Sharing Settings</h5>
          <p>
            You may update your profile information, such as your user name and may change some of your data sharing preferences within your user Account page, or should you need assistance you can email customer support at talent@weavesocial.com
          </p>
          <h5>How to control your communications preferences</h5>
          <p>
            You can stop receiving promotional email communications from us by clicking on the “unsubscribe link” provided in such communications. You may not opt out of service-related communications (e.g., account verification, transactional communications, changes/updates to features of the Service, technical and security notices).
          </p>
          <h5>Modifying or deleting your information</h5>
          <p>
            If desired, you may decline to share your personal information with Weave, at which time we will no longer be able to provide you with a majority of the functionality of our offerings. If you would like to access, review, correct, update, suppress/remove, or otherwise limit our use of your personal information and/or User Content, you may contact us at talent@weavesocial.com. Please note that in order to protect your privacy and security, we take reasonable steps to verify your identity before making any changes to your personal information.
          </p>

          <h4>How We Store And Protect Your Information</h4>
          <h5>Data Storage And Transfer</h5>
          <p>
            Your information collected through our website may be stored and processed in the United States or any other country in which Weave or its affiliates or service providers maintain facilities. If you are located in other regions with laws governing data collection and use that may differ from U.S. law, please note that we may transfer information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction, and you consent to the transfer of information to the U.S. or any other country in which Weave or its subsidiaries, affiliates, or service providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy. If you wish to enquire further about these transfers, please contact us using the details set out in the "Contact Us" section below.
          </p>

          <h5>Data Retention</h5>
          <p>
            We will retain your personal information only for as long as reasonably necessary to maintain the Service (through Facebook, we maintain your information up to 90 days -- through YouTube, we maintain your information for up to 30 days) to meet legal and accounting obligations, and for the purposes described in this Privacy Policy. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and the applicable legal requirements.
          </p>

          <h5>Keeping Your Information Safe</h5>
          <p>
            We care about the security of your information and employ physical, administrative, and technological safeguards designed to preserve the integrity and security of all information collected through our Service. However, no security system is impenetrable, and we cannot guarantee the security of our systems 100%. In the event that any information under our control is compromised as a result of a breach of security, we will take reasonable steps to investigate the situation and, where appropriate, notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.
          </p>

          <h4>Other Data Protection Rights</h4>
          <p>
            You may have the following data protection rights:
          </p>
          <p>
            – To access, correct, update, or request deletion of your Personal Information. Weave takes reasonable steps to ensure that the data we collect is reliable for its intended use, accurate, complete, and up to date. You may contact the Customer network you work with or applied to join or Weave directly at any time about accessing, correcting, updating, or deleting your Personal Information, or altering your data. Email Weave at talent@weavesocial.com. We will consider your request in accordance with applicable laws.
          </p>
          <p>
            You can revoke Weave’s access to your authorized data from Google, YouTube, Facebook, Instagram, Twitter, Twitch, or other social media platforms at any time via each platform’s app and security settings, which can be found in the links below. Note that revoking such authorizations may limit or eliminate certain functionality in Weave’s Services.
          </p>
          <p>
            If you wish to have your authorized Social Media Data removed from Weave products or websites, please contact us at talent@weavesocial.com.
          </p>
          <p>
            – In addition, if you are a resident of the European Economic Area (EEA), you can object to processing of your Personal Information, ask us to restrict processing of your Personal Information, or request portability of your Personal Information. Again, you may contact the Customer network you work with or applied to join or Weave directly at any time.
          </p>
          <p>
            – You have the right to complain to a data protection authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA are available <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm" target="_blank">here</a>.
          </p>
          <p>
            As described above, for much of the Personal Information we collect and process about Creators through the Services, we act as a data processor on behalf of our Customers. In such cases, if you are a Creator and want to exercise any data protection rights that may be available to you under applicable law or have questions or concerns about how your Personal Information is handled by Weave as a data processor on behalf of our individual Customer, you should contact the relevant Customer that is using the Weave Services, and refer to its separate privacy policies.
          </p>
          <p>
            If you no longer want to be contacted by one of our Customer through our Services, please contact the Customer directly to update or delete your data. If you contact us directly, we may remove or update your information within a reasonable time and after providing notice to the Customer of your request.
          </p>
          <p>
            We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond efficiently to your request.
          </p>

          <h4>Changes To This Privacy Policy</h4>
          <p>
            This Privacy Policy is effective as of July 31, 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
          </p>
          <p>
            We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
          </p>
          <p>
            If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
          </p>

          <h4>
            Contact Us
          </h4>
          <p>
            If you have any questions about this Privacy Policy, please contact us at talent@weavesocial.com.
          </p>
        </div>
      </div>
    )
  }
}
