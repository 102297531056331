import React from 'react';
import Cookies from 'js-cookie';

export default class TwitchRedirect extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      access_token: Cookies.get('access_token'),
      userid: Cookies.get('userid')
    }
  }

  componentDidMount(){
    // let social = this.gp('social')
    // if (social == 'youtube'){
    //   Cookies.remove('onboarding')
    //   Cookies.remove('onboarding-status')
    //   location.href = '/dashboard'
    // }

    var token = location.search.split('code=')[1].split('&')[0]
    // console.log(token)
    this.storeToken(token).done((response) => {
      // console.log(response)
      if (response && response.success){
        location.replace('/account')
        // var user = response.user
        // Cookies.set('userid', user.userid)
        // Cookies.set('access_token', user.access_token)
        // Cookies.set('twitchdata', user.twitchUser)
        // user.twitchUser = null
        // // console.log(user)
        // user = {
        //   access_token: user.access_token,
        //   active: user.active,
        //   aff: user.aff,
        //   balance: user.balance,
        //   balanceUpdated: user.balanceUpdated,
        //   channelId: user.channelId,
        //   channelid: user.channelid,
        //   defaultThumbnail: user.defaultThumbnail,
        //   email: user.email,
        //   full_name: user.full_name,
        //   id: user.id,
        //   id_token: user.id_token,
        //   intercomId: user.intercomId,
        //   paypalAccount: user.paypalAccount,
        //   thumbnails: user.thumbnails,
        //   totalCashedOut: user.totalCashedOut,
        //   totalEarned: user.totalEarned,
        //   userid: user.userid,
        //   lastActive: user.lastActive,
        //   profileImg: user.profileImg
        // }
        // Cookies.set('userdata', user)
        // Cookies.set('onboarding', true)
        // Cookies.set('onboarding-status', '/onboard-yt-approved')
        // Cookies.set('onboarding-finished', true)
        // location.replace('/onboard-yt-approved')
      }
      else { alert('verification unsuccessful') }
    })
  }

  storeToken(token){
    return $.ajax({
      method: 'POST',
      url: '/api/Twitch/storeToken',
      data: { token: token, access_token: this.state.access_token, userid: this.state.userid }
    })
  }

  render() {
    return (
      <div>

      </div>
    )
  }
}
