import React from 'react';
import './invite-panel.scss';
import { Link } from 'react-router-dom';

export interface InvitePanelProps {
  title?: string,
  showIf?: boolean,
  children?: any,
  topHr?: boolean,
  bottomHr?: boolean,
  linkTo?: string
}


interface LinkWrapperProps {
  linkTo?: string,
  children: any;
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({linkTo, children}) =>
  linkTo? <Link to={linkTo}> {children} </Link>:
    <> { children } </>;

export const InvitePanel = ({
  title,
  showIf = true,
  children,
  topHr = false,
  bottomHr = true,
  linkTo = ''
}: InvitePanelProps) =>
  showIf ? (
    <div className="invite-panel">
      {title && <h3 className='title'> {title} </h3>}

      <LinkWrapper linkTo={linkTo}>
        {topHr && <hr />}
        {children}
        {bottomHr && <hr />}
      </LinkWrapper>

    </div>
  ) : (
    <></>
  );
