import { Component, memo } from 'react';

class Loader extends Component {
  constructor(props){
    super(props)
    this.state = {
      color: '#000'
    }
  }

  componentDidMount(){
    var color = this.props.color ? this.props.color : false
    if (color) {
      $('head').append(`<style>.sk-chase-dot:before{background-color:${color}}</style>`);
    }
  }

  componentDidUpdate(){
    var color = this.props.color ? this.props.color : false
    if (color) {
      $('head').append(`<style>.sk-chase-dot:before{background-color:${color}}</style>`);
    }
  }

  render() {
    let loaderHtml = '', textHtml = ''
    var { active, title, subtext } = this.props
    if (this.props.active){
      loaderHtml = (
        <>
          <div className="loader-module-icon">
            <div className="sk-chase-container">
              <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
              </div>
            </div>
          </div>
        </>
      )
      if (title || subtext){
        textHtml = (
          <>
            <div className="loader-module-text">
              <div className="row row0">
                <h3>{title}</h3>
                <p className="sub-text">{subtext}</p>
              </div>
            </div>
          </>
        )
      }
    }
    return (
      <div className="loader-module">
        <div className="loader-module-inner">
          {loaderHtml}
          {textHtml}
        </div>
      </div>
    )
  }
}

export default memo(Loader);
