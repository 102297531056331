import React from 'react';
import Cookies from 'js-cookie';

export default class OnboardCampaignInvited extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    setTimeout(function(){
      $('.onboard-youtube-prof-card').addClass('loaded')
      setTimeout(function(){
        $('.onboard-youtube-prof-card').addClass('loaded-2')
    //     setTimeout(function(){
    //       if (Cookies.get('onboarding-finished') === 'true'){
    //         Cookies.remove('onboarding')
    //         Cookies.remove('onboarding-status')
    //         Cookies.remove('onboarding-finished')
    //         location.href = '/dashboard'
    //       }
    //       else {
    //         Cookies.set('onboarding', true)
    //         Cookies.set('onboarding-status', '/onboard-email')
    //         location.replace('/onboard-email')
    //       }
    //     }, 500)
      }, 2000)
    }, 100)

  }

  render() {

    let user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {}
    let profpic = user.thumbnails ? user.thumbnails.medium.url : ''

    return (
      <div>
        <div className="onboard-page onboard-campaign">
          <div className="onboard-container interact-section" style={{overflow: 'initial'}}>
            <div className="onboard-youtube-prof-card">
              <div className="onboard-banner">
                <h4><img style={{ width: '135px' }} src="/images/etc.png" /> GEOLOGIE</h4>
              </div>
              {/*<div className="onboard-banner" style={{backgroundImage: `url(${user.imageUrl})`, backgroundImage: `url(https\:\/\/yt3\.ggpht\.com\/yRQ0FERZRHURhNWgbeWE6ThBlKG_PQmIS6-OQlZKMFwhUwS…Grtr3lVQQrrRs0w\=w2560-fcrop64\=1\,00005a57ffffa5a8-nd-c0xffffffff-rj-k-no)`}}></div>*/}
              <div className="onboard-bottom">
                <h4><img style={{ width: '95px', borderrRadius: '50%' }} src="https://yt3.ggpht.com/a-/AN66SAxZNGUeVOzWGTPf6ygH0quJIFY666OmfVsA2g=s240-mo-c-c0xffffffff-rj-k-no" /> SquattinCassanova</h4>
                <div className="start-btn">Start</div>
                {/*<span className="onboard-channel">{user.channelTitle}</span>*/}
                {/*<span className="onboard-channel-approved"><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/icons/emailverified-icon.svg" />Approved! Redirecting...</span>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
