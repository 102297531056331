import React from 'react';

export default class GodLogin extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      userid: '',
      password: ''
    }
  }

  componentDidMount(){
    var split = location.search.replace('?', '').split('&')
    var obj = {}
    split.forEach((r) => { var s = r.split('='); obj[s[0]] = s[1] })
    if (obj.userid && obj.password) {
      this.login(obj.userid, obj.password)
    }
  }

  onChange(type, e){
    var val = e.target.value
    var state = { [`${type}`]: val }
    this.setState(state)
  }

  login(userid, password){
    this._login(userid, password).done((response) => {
      if (response) { location.replace('/dashboard') }
      else { alert('Login info incorrect') }
    })
  }

  _login(userid, password){
    return $.ajax({
      method: 'GET',
      url: '/api/Admin/godLogin',
      data: {
        userid: userid ? userid : this.state.userid,
        password: password ? password : this.state.password
      }
    })
  }

  render() {

    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div className="login-box-inner">
              <header>
                <h2 style={{color: 'white', fontFamily: 'Barlow', fontWeight: 900}}>God Login</h2>
              </header>
              <div className="login-padding">
                <div className="input-text-wrapper email">
                  <input className="input-text" placeholder="Email or User ID" type="email" onChange={this.onChange.bind(this, 'userid')} />
                </div>
                <div className="input-text-wrapper password">
                  <input className="input-text" placeholder="password" type="password" onChange={this.onChange.bind(this, 'password')} />
                </div>
                <button className="btn-submit" onClick={this.login.bind(this, null, null)}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
