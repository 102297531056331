import { all, call, put, takeLatest } from 'typed-redux-saga';
import { fetchPrimaryAddressAnonymous } from '../../services/address-service';
import { addressActionCreators, GetPrimaryAddressAnonymousAction } from '../actions/address.actions';

function* getPrimaryAddressAnonymousSaga(action: GetPrimaryAddressAnonymousAction) {
  try {
    const addressResponse = yield* call(fetchPrimaryAddressAnonymous, action.payload.contentId);
    return yield* put(addressActionCreators.getPrimaryAddressAnonymousSuccessAction(addressResponse));
  } catch (e) {
    yield put(addressActionCreators.getPrimaryAddressAnonymousFailAction(e));
  }
}

export function* addressSagas() {
  yield all([
    takeLatest('addressPrimaryAnonymous/GET', getPrimaryAddressAnonymousSaga),
  ]);
}
