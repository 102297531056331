import React from 'react';

export default class SlideOutMenuItem extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var item = this.props.item

    return (
      <li className={`menu-item menu-item-${this.props.type}`} onClick={this.props.chooseItem.bind(this.props.this, item)}>
        {
          this.props.type == 'full' &&
          <div className="row row0">
            <div className="col-xs-8 menu-text">
              <div>
                <p><b>{item.title}</b></p>
              </div>
              <div>
                <p className="menu-ingredients sub-text">{item.description}</p>
              </div>
              <div>
                <p className="menu-price">${item.price}</p>
              </div>
            </div>
            <div className="col-xs-4 boot0 menu-img" style={{backgroundImage: `url("${item.img}")`}}></div>
          </div>
        }

        {
          this.props.type == 'simple' &&
          <div className="row row0">
            <div className="col-xs-1 menu-text">
              {item.quantity}
            </div>
            <div className="col-xs-8 menu-text">
              <div>
                <p className="menu-text-ellipsis" style={{margin: '0'}}><b>{item.title}</b></p>
              </div>
            </div>
            <div className="col-xs-3 menu-text" style={{textAlign: 'right'}}>
              <div>
                <p className="menu-price" style={{margin: '0'}}>${item.price}</p>
              </div>
            </div>
          </div>
        }
      </li>
    )
  }
}
