import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';

export default class Signup extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      email: '',
      website: '',
      message: ''
    }
  }

  componentDidMount(){
    // Globals.pageView(window.location.pathname + window.location.search)
  }

  onSubmit(e){
    //GoogleAnalyticsService.logEvent('onboard', 'signup')
    // Globals.logEvent('click', 'signup')
    e.preventDefault()
    $('#submit').text('SUBMITTING...')

    // var referral = location.search
    // if (referral.indexOf('r=') > -1){
    //   referral = referral.split('r=')[1].split('&')[0]
    //   store('referral', referral)
    //   Cookies.set('referral', referral)
    // }
    // else{
    //   referral = null;
    // }

    var obj = {
      email: $('#email').val(),
      website: $('#website').val(),
      message: $('#message').val()
      // referral: (referral ? referral : false),
      // campaign: (cmp ? cmp : false)
    }
    console.log(obj);

    if (!Globals.validateEmail(obj.email)){
      alert('not a valid email')
      $('#submit').text('SIGN UP')
      return
    }

    $.ajax({ method: 'POST', url: '/api/BrandApply/apply', data: obj })
    .done((response) => {
      if (response.success){
        $('#submit').text('APPLICATION RECEIVED').attr('disabled', true);
        // location.href = '/onboard-email-sent'
      }
      else if (!response.success){
        $('#submit').text('EMAIL EXISTS')
      }
    })
  }

  render() {
    // var cmp = location.search
    // if (cmp.indexOf('campaign=') > -1){
    //   cmp = cmp.split('campaign=')[1].split('&')[0]
    //   Cookies.set('cmp', cmp)
    // }
    // else{
    //   cmp = null;
    // }
    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div className="login-box-inner">
              <header>
                <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </header>
              <div className="login-padding">
                <h3 style={{textAlign: 'center', margin: '0 0 25px 0'}}>Brand Signup</h3>

                <form onSubmit={this.onSubmit.bind(this)}>
                  <div className="input-text-wrapper email">
                    <input id="email" className="input-text" placeholder="Business Email" type="email" />
                  </div>
                  <div className="input-text-wrapper" style={{backgroundImage: `url(${Globals.icon('link', 'black')})`}}>
                    <input id="website" className="input-text" placeholder="Business Website" type="website" />
                  </div>
                  <div className="input-text-wrapper message">
                    <textarea id="message" className="input-text" placeholder="*Optional - provide more info to help us approve your account" />
                  </div>
                  <span className="login-separator" style={{letterSpacing: 'initial', fontSize: '13px'}}>By requesting demo, you agree to our <a href="/terms-of-service" target="_blank"><b>terms of service</b></a>.</span>
                  <button id="submit" type="submit" className="btn-submit">REQUEST DEMO</button>
                </form>
                <p className="switch-boxes">Have an account? <Link to="/brand-login"><b>Login here!</b></Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
