import React from 'react';
import Cookies from "js-cookie";
import store from "store2";

export default class VerifyPasswordReset extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      token: null
    }
  }

  componentDidMount(){
    // todo: pasted from logout
    Cookies.remove('access_token');
    Cookies.remove('userid');
    Cookies.remove('userdata');
    Cookies.remove('twitchdata');
    Cookies.remove('mixerdata');
    store('access_token', null);
    store('userid', null);

    this.setState({
      ...this.state,
      token: location.search.split('token=')[1].split('&')[0]
    });
  }

  onSubmit(e){
    e.preventDefault()
    const p1 = $('#password-1').val()
    const p2 = $('#password-2').val()

    if (p1.length > 6 && p1 === p2){
      $.ajax({
        method: 'POST',
        url: '/api/Users/resetPassword',
        data: {resetPasswordToken: this.state.token, newPassword: p1}
      }).done((data) => {
        if (data.success){
          $('#submit').text('Password set. Redirecting...')
          // Cookies.set('onboarding-status', '/onboard-username')
          location.href = '/login'
        }
        else {
          alert(data.message)
        }
      })
    }
    else if (p1 !== p2){
      alert('passwords do not match')
    }
    else {
      alert('password must be longer than 6 characters')
    }
  }

  render() {
    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section">
            <h3>Set Password</h3>
            <div className="set-email-container">
              <form onSubmit={this.onSubmit.bind(this)}>
                <div className="input-text-wrapper email">
                  <input id="password-1" className="input-text" type="password" placeholder="Enter Password" />
                </div>
                <div className="input-text-wrapper email">
                  <input id="password-2" className="input-text" type="password" placeholder="Verify Password" />
                </div>
                <button id="submit" type="submit" className="btn-submit">SAVE PASSWORD</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
