import React from 'react';
import includes from 'array-includes';
import ShippingTrackingProduct from './shipping-tracking-product.jsx';
import ShippingTrackingGame from './shipping-tracking-game.jsx';
import ShippingTrackingApp from './shipping-tracking-app.jsx';
import ShippingTrackingRestaurant from './shipping-tracking-restaurant.jsx';

// GAMES
import SlideOutGameTwitchStream from '../slide-out/slideout-game-twitch-stream.jsx';
import SlideOutGameMixerStream from '../slide-out/slideout-game-mixer-stream.jsx';
import SlideOutGameYTStream from '../slide-out/slideout-game-yt-stream.jsx';
import SlideOutGameYTVideo from '../slide-out/slideout-game-yt-video.jsx';
import SlideOutGameIGVideo from '../slide-out/slideout-game-ig-video.jsx';
import SlideOutGameIGPhoto from '../slide-out/slideout-game-ig-photo.jsx';

// RESTAURANTS
import SlideOutRestaurantYTStream from '../slide-out/slideout-restaurant-yt-stream.jsx';
import SlideOutRestaurantTwitchStream from '../slide-out/slideout-restaurant-twitch-stream.jsx';
import SlideOutRestaurantMixerStream from '../slide-out/slideout-restaurant-mixer-stream.jsx';
import SlideOutRestaurantYTVideo from '../slide-out/slideout-restaurant-yt-video.jsx';
import SlideOutRestaurantIGPhoto from '../slide-out/slideout-restaurant-ig-photo.jsx';

// PRODUCTS
import SlideOutProductTwitchStream from '../slide-out/slideout-product-twitch-stream.jsx';
import SlideOutProductMixerStream from '../slide-out/slideout-product-mixer-stream.jsx';
import SlideOutProductYTStream from '../slide-out/slideout-product-yt-stream.jsx';
import SlideOutProductYTVideo from '../slide-out/slideout-product-yt-video.jsx';
import SlideOutProductIGPhoto from '../slide-out/slideout-product-ig-photo.jsx';

export default class ShippingTracking extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var props = this.props

    var campaign = this.props.campaign
    var user = campaign.user
    // console.log(campaign)
    var game = campaign.game ? campaign.game : campaign.offer
    var offer = campaign.offer ? campaign.offer : {}
    var content = campaign.content ? campaign.content : {}
    if (campaign) {
      campaign.brandType = campaign.brandType ? campaign.brandType : offer.brandType
    }
    var restaurant = campaign.restaurant

    var bountyObjs = []
    if (campaign && campaign.bountyObjs){
      campaign.bountyObjs.forEach((bounty) => {
        if (includes(bounty.labels, 'BountyGameTwitchStream')) { bountyObjs.push(<SlideOutGameTwitchStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyGameMixerStream')) { bountyObjs.push(<SlideOutGameMixerStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyGameYTStream')) { bountyObjs.push(<SlideOutGameYTStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyGameYTVideo')) { bountyObjs.push(<SlideOutGameYTVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyGameIGVideo')) { bountyObjs.push(<SlideOutGameIGVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyGameIGPhoto')) { bountyObjs.push(<SlideOutGameIGPhoto campaign={campaign} bounty={bounty} />) }

        else if (includes(bounty.labels, 'BountyProductTwitchStream')) { bountyObjs.push(<SlideOutProductTwitchStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyProductMixerStream')) { bountyObjs.push(<SlideOutProductMixerStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyProductYTStream')) { bountyObjs.push(<SlideOutProductYTStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyProductYTVideo')) { bountyObjs.push(<SlideOutProductYTVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyProductIGVideo')) { bountyObjs.push(<SlideOutGameYTVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyProductIGPhoto')) { bountyObjs.push(<SlideOutProductIGPhoto campaign={campaign} bounty={bounty} />) }

        else if (includes(bounty.labels, 'BountyRestaurantTwitchStream')) { bountyObjs.push(<SlideOutRestaurantTwitchStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyRestaurantMixerStream')) { bountyObjs.push(<SlideOutRestaurantMixerStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyRestaurantYTStream')) { bountyObjs.push(<SlideOutRestaurantYTStream campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyRestaurantYTVideo')) { bountyObjs.push(<SlideOutRestaurantYTVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyRestaurantIGVideo')) { bountyObjs.push(<SlideOutRestaurantIGVideo campaign={campaign} bounty={bounty} />) }
        else if (includes(bounty.labels, 'BountyRestaurantIGPhoto')) { bountyObjs.push(<SlideOutRestaurantIGPhoto campaign={campaign} bounty={bounty} />) }
      })
    }

    return (
      <div>
        {
          props.brandType == 'product' &&
          <ShippingTrackingProduct _this={this.props.this} status={props.status} campaign={props.campaign} tabActive={this.props.tabActive} chooseTab={this.props.chooseTab} />
        }
        {
          props.brandType == 'game' &&
          <ShippingTrackingGame _this={this.props.this} status={props.status} campaign={props.campaign} tabActive={this.props.tabActive} chooseTab={this.props.chooseTab} />
        }
        {
          props.brandType == 'app' &&
          <ShippingTrackingApp _this={this.props.this} status={props.status} campaign={props.campaign} tabActive={this.props.tabActive} chooseTab={this.props.chooseTab} />
        }
        {
          props.brandType == 'restaurant' &&
          <ShippingTrackingRestaurant _this={this.props.this} status={props.status} campaign={props.campaign} tabActive={this.props.tabActive} chooseTab={this.props.chooseTab} />
        }

        {/*<div className="row row0" style={{marginTop: '25px'}}>
          <h3>Objectives</h3>
          {bountyObjs}
        </div>*/}
      </div>
    )
  }
}
