import React from 'react';
import Globals from '../../global-functions.js';

export default class ShippingTrackingDisplay extends React.Component {

  render() {
    var shipping = this.props.shipping

    return (
      <div className="row">
        <div className="col-xs-6">
            {
              (shipping.expectedDelivery || shipping.deliveryDate) &&
              <>
                <p style={{fontWeight: 'bold', fontSize: '20px'}}>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.checkpointUpdatedAt).format('dddd')}</p>
                <p>
                  <span style={{fontSize: '48px', marginRight: '12px', fontWeight: '800', lineHeight: '36px'}}>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('D')}</span>
                  <div style={{display: 'inline-block'}}>
                    <div><b>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('MMMM')}</b></div>
                    <div><b>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('YYYY')}</b></div>
                  </div>
                </p>
              </>
            }
            {
              !(shipping.expectedDelivery || shipping.deliveryDate) &&
              <>
                <p style={{fontWeight: 'bold', fontSize: '20px'}}>Pending...</p>
                <p>
                  Delivery date estimation will update once received from shipping courier.
                </p>
              </>
            }

        </div>
        <div className="col-xs-6">
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>
            {
              shipping.shippingStatus == 'delivered' &&
              <span>Delivered <img src={Globals.icon('ok')} style={{marginTop: '-4px', marginLeft: '4px', height: '22px'}} /></span>
            }
            {
              shipping.shippingStatus != 'delivered' &&
              <span>In-Transit <img src={Globals.icon('delivery')} style={{marginTop: '-4px', marginLeft: '4px', height: '22px'}} /></span>
            }
          </p>
          <p><b>{shipping.shippingMessage}</b></p>
          <p style={{margin: 0}}>{moment(shipping.checkpointUpdatedAt).format('ddd, MMM Do YYYY h:mm a')}</p>
          <p style={{margin: 0}}>{shipping.shippingLocation}</p>
        </div>
      </div>
    )
  }
}
