import { memo } from 'react';

const FilterOption = ((props) => {
  var { filter, changeFilter, type, title, label, filterType, index, _this } = props
  index = index || 0

  return (
    <>
      <div className="filter-option-container filter-option-toggle-selector" onClick={changeFilter.bind(_this, type[index], label, filterType)}>
        <div className="row row0 filter-option-inner">
          <div className={`filter-option-selector-container ${filter[index].value == label ? 'active' : ''}`}>
            <div className="row row0">
              <div className="filter-option-selector">
                <div className="filter-checkbox">
                  <div className="filter-checkbox-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-option-title">
            <label>{title}</label>
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(FilterOption);
