import React from 'react';

var labelUpdater = 0

export default class CouponPopup extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      // expanded: false,
      // domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me'
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  copyLink(link){
    $(`#aff-link-${link.hash}`).select()
    document.execCommand('copy')
    // setTimeout(() => {
    //   $(`#aff-link-${link.hash}`).removeClass('copied')
    // }, 1500)
  }

  render() {
    var coupons = ''
    if (this.props.coupons && this.props.coupons.length > 0){
      coupons = this.props.coupons.map((coupon) => {
        return (
          <li>
            <p style={{fontSize: '18px'}}><b>{coupon.code}</b></p>
            <p>{coupon.description}</p>
            <p className="coupon-dates">Valid: {coupon.startDate} to {coupon.endDate}</p>
            <p className="coupon-dates">*Restrictions: {coupon.restriction && coupon.restriction.length > 3 ? coupon.restriction : 'no restrictions listed'}</p>
          </li>
        )
      })
    }
    else {
      coupons = <p className="placeholder">No coupons currently available at this retailer.</p>
    }

    return (
      <div>
        <div className="coupon-popup-container">
          <div className="coupon-popup">
            <div className="coupon-popup-padding">
              <div className="coupon-popup-header">
                <p>Available Coupons</p>
                <span onClick={this.props.hideCoupon.bind(this.props.this)}>x</span>
              </div>
              <ul className="coupon-list">
                {coupons}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
