import React from 'react';
import Guidelines from '../guidelines.jsx';

var updateElapseInterval = null

export default class SlideOutGameTwitchStream extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    console.log('bounty obj')
    console.log(this.props.campaign)
  }


  render() {
    var campaign = this.props.campaign,
    game = campaign.game ? campaign.game : campaign.offer,
    content = campaign ? campaign.content : {},
    campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    user = campaign.user,
    twitchUser = this.props.twitchUser ? this.props.twitchUser : {},
    offer = campaign.offer,
    bounty = this.props.bounty,
    bountyObj = bounty.bountyObj,
    bountyOffer = bounty.bountyOffer,
    video = bountyObj.video ? bountyObj.video : false

    var objectives = bountyObj.objectives ? bountyObj.objectives.map((obj) => {
      if (obj.type == 'link'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add <b>{obj.value}</b> above the fold in video description</span></p>
        )
      }
      else if (obj.type == 'hashtag'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add <b>#{obj.value}</b> to video description</span></p>
        )
      }
      else if (obj.type == 'discount'){
        return (
          <p style={{color: `${obj.active ? '#90dc5a' : '#bdbbc0'}`, marginBottom: '5px'}}><span>{obj.active ? '• ' : 'x '}</span><span>Add discount <b>{obj.value}</b> to video description</span></p>
        )
      }
    }) : []

    return (
      <div>
        <div className="module bounty-objective bounty-objective-slideout">
          <div className="row bounty-objective-row">
            <div className="module bounty-objective-container">
              <div className="bounty-platform-color bounty-platform-color-twitch"></div>
              <div className="col-xs-2 bounty-platform-icon">
                <img src="/images/achievements/twitch-logo.svg" />
              </div>
              <div className="col-xs-7 bounty-objective-text">
                <p className="bounty-objective-p">
                  <b style={{fontSize: '16px'}}>{bountyOffer.offerHours}+ minute video feature</b>
                  <br />
                  <span className="sub-text">${bountyObj.proposedOffer} • {bountyObj.proposedViews}+ views</span>
                </p>
                {
                  bountyObj.missingRequirements &&
                  <p className="bounty-requirement-missing">{`*Missing ${bountyObj.missingRequirements} requirement${bountyObj.missingRequirements > 1 ? 's' : ''}`}</p>
                }
              </div>
              <div className="col-xs-3">
                <p className="bounty-objective-status"><b>{bountyObj.viewProgress ? bountyObj.viewProgress : 0}</b><span> / {bountyObj.proposedViews ? bountyObj.proposedViews : 100}</span></p>
              </div>
            </div>
            <div className="bounty-progress">
              <div className="bounty-progress-outer">
                <div className="bounty-progress-inner" style={{width: `${bountyObj.completionPerc}%`}}></div>
              </div>
            </div>
          </div>
          {
            (content.status == 'brand_posting' ||
            content.status == 'brand_received' ||
            content.status == 'brand_live') &&
            <>
              <div className="row bounty-objective-expanded" style={{margin: 0}}>
                <div className="module bounty-objective-container">
                  {
                    video &&
                    video.videoId &&
                    <div className="row bounty-objective-video">
                      <div className="module bounty-objective-video-img">
                        <img src={`https://img.youtube.com/vi/${video.videoId}/mqdefault.jpg`} />
                      </div>
                      <div className="module bounty-objective-video-text">
                        <p>{video.title}</p>
                      </div>
                    </div>
                  }
                  <Guidelines
                    guidelines={bountyObj.objectives}
                  />
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

}
