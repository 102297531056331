import { all, call, put, takeLatest } from 'typed-redux-saga';
import { CreateLoginAction, CreateLoginSuccessAction } from '../actions/auth.actions';
import { authActionCreators } from '../actions/auth.actions';
import { LoginType } from '../models/auth.models';
import { MakeFetchPost } from '../../utils/utils';
import history from '../../components/history';

function* loginSaga(action: CreateLoginAction) {
  try {
    switch (action.payload.loginType) {

      case LoginType.Auto:
        const autoLoginResponse = yield* call(MakeFetchPost, '/api/Users/autoLogin');
        if (autoLoginResponse?.success) {
          return yield* put(authActionCreators.createLoginSuccessAction({
            redirectUrl: null
          }));
        } else {
          throw Error('Failed to login user');
        }
        break;
      case LoginType.Email:
        const { username, password, redirectUrl } = action.payload;
        const emailResponse = yield* call(MakeFetchPost, '/api/Users/emailLogin', {username, password});
        if (emailResponse?.success) {
          return yield* put(authActionCreators.createLoginSuccessAction({
            ...(emailResponse || {}),
            redirectUrl
          }));
        }

        throw Error('Email login failed.');

    }
  } catch (e) {
    yield put(authActionCreators.createLoginFailAction(null));
  }
}



function* loginSuccesfulSaga(action: CreateLoginSuccessAction) {
  if (action?.payload?.redirectUrl === null) {
    return;
  }

  history.push(action?.payload?.redirectUrl || '/dashboard'); // todo: continue here - history push not working that indicates a deeper problem.
  window.location.reload();
}


export function* authSagas() {
  yield all([
    takeLatest('login/CREATE', loginSaga),
    takeLatest('login/CREATE/success', loginSuccesfulSaga)
  ]);
}
