import { createReducer } from 'typesafe-actions';
import { GetInvitationFailed, GetInvitationResponse } from '../models/invitation.models';
import { Nullable } from '../../utils/utils';
import { invitationActionCreators, InvitationActions } from '../actions/invitation.actions';

type InvitationState = {
  data: Nullable<GetInvitationResponse>;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetInvitationFailed>;
};

const initialState: InvitationState = Object.freeze({
  data: null,
  hasError: false,
  loading: false,
  error: null
});

export const invitationReducer = createReducer<InvitationState, InvitationActions>(
  initialState,
).handleAction(invitationActionCreators.getInvitationAction, (state, action) => ({
    ...initialState,
    loading: true,
  }))
  .handleAction(
    invitationActionCreators.getInvitationSuccessAction,
    (state, action) => ({
      ...initialState,
      data: action.payload,
    }),
  )
  .handleAction(invitationActionCreators.getInvitationFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

