import React from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from '../outreach/crm-table-header-cell'
import CRMTableMainCell from '../outreach/crm-table-main-cell'

export default class InvoicesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Globals.getUser(),
    };
  }

  componentDidMount() {}

  render() {
    var { invoices } = this.props, user = this.state.user;
    var sortFilter = this.props.sortFilter

    return (
      <div>
        <div className="crm-table">
          <div className="crm-table-header">
            <div className={`row row0 crm-row ${this.props.selectedActive ? "selected" : ""}`}>
              <div className="crm-td crm-td-xs crm-td-img">
                <input type="checkbox" style={{ display: "block" }} onClick={this.props.selectInfluencer.bind(this.props.this, "all")} checked={this.props.selectedActive} />
              </div>
              <CRMTableHeaaderCell label="Invoice" title="Invoice" size="crm-td-xs" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Company" title="Company" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Date" title="Date" size="crm-td-sm" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Amount" title="Amount" size="crm-td-sm text-right" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Status" title="Status" size="crm-td-sm" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Last Updated" title="Last Updated" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Due Date" title="Due Date" size="crm-td-sm" icon="mail" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Net Terms" title="Net Terms" size="crm-td-sm" icon="user" sortFilter={sortFilter} this={this.props.this} />
            </div>
          </div>

          <div className="crm-table-main">
            {invoices.map((invoice) => {
              // var stats = campaign.stats ? campaign.stats : { sent: 0 }
              // var sent = stats.sent,
              //     opened = Math.round(((+stats.opened) / (+stats.sent || 1)) * 100) + '%',
              //     responded = Math.round(((+stats.responded) / (+stats.sent || 1)) * 100) + '%',
              //     clicked = Math.round(((+stats.clicked) / (+stats.sent || 1)) * 100) + '%',
              //     agreed = Math.round(((+stats.clicked) / (+stats.sent || 1)) * 100) + '%',
              //     completed = Math.round(((+stats.failed) / (+stats.sent || 1)) * 100) + '%'

              return (
                <div key={invoice.id} className={`row row0 new-module crm-row crm-row-influencer ${invoice.selectedActive ? "selected" : ""}`}>
                  <div className="">
                    <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={`https://logo.clearbit.com/${invoice.domain}?size=150`} initial={''} hoverFunc={this.props.selectInfluencer} this={this.props.this} />

                    <CRMTableMainCell title={`#${invoice.id}`} size="crm-td-xs" this={this.props.this} />
                    <CRMTableMainCell title={invoice.company ? invoice.company : 'Untitled'} size="crm-td-md" pathUrl={`/invoices/create/${invoice.id}`} classes="crm-username" this={this.props.this} />
                    <CRMTableMainCell title={moment(invoice.createdAt).format('MMM M, YYYY')} size="crm-td-sm" this={this.props.this} />
                    <CRMTableMainCell title={`$${Globals.numberWithCommas(invoice.amount) || 0}`} size="crm-td-sm" classes="crm-username text-right" this={this.props.this} />
                    <CRMTableMainCell title={invoice.status || 'draft'} size="crm-td-sm" type="tile" tileColor={invoice.active ? 'color-complete' : 'color-error'}  this={this.props.this} />
                    <CRMTableMainCell title={invoice.name ? invoice.name : 'Untitled'} size="crm-td-md" pathUrl={`/invoices/create/${invoice.id}`} classes="crm-username" this={this.props.this} />
                    <CRMTableMainCell title={moment(invoice.updatedAt || invoice.createdAt).format('MMM M, YYYY')} size="crm-td-md" this={this.props.this} />
                    {/*<CRMTableMainCell title={sent} size="crm-td-sm" classes="bold-text" />
                    <CRMTableMainCell title={opened} size="crm-td-sm" classes="sub-text" />
                    <CRMTableMainCell title={responded} size="crm-td-sm" classes="sub-text" />
                    <CRMTableMainCell title={clicked} size="crm-td-sm" classes="sub-text" />
                    <CRMTableMainCell title={agreed} size="crm-td-sm" classes="sub-text" />
                    <CRMTableMainCell title={completed} size="crm-td-sm" classes="sub-text" />*/}
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.loading && <Loader active={true} />}
        </div>
      </div>
    );
  }
}
