import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import Canvas from '../canvas.jsx';
import IconPack from '../icon-pack.jsx';
import InvoiceAddDeliverable from './invoice-add-deliverable.jsx'
import CreateInvoiceTitle from './create-invoice-title.jsx'
import CreateInvoiceInput from './create-invoice-input.jsx'
import CreateInvoiceDeliverables from './create-invoice-deliverables.jsx'
import moment from 'moment'
import DatePicker from 'react-date-picker'

export default class CreateInvoice extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      dueDate: new Date(),
      startDate: moment().subtract('5', 'month').startOf('month').format('YYYY/M/D'),
      endDate: moment().format('YYYY/M/D'),
      amount: '',
      email: '',
      name: '',
      company: '',
      domain: '',
      dueDate: new Date(),
      netTerms: '',
      notes: '',
      deliverables: [],
      poNumber: '',
      calendarOpen: false,
      addDeliverableOpen: false
    }
  }

  componentDidMount(){
    const { id } = this.props.match.params
    this.setState({ id }, () => { this.getInvoice() })
  }

  async getInvoice(){ var res = await this._getInvoice();
    if (res && res.invoice){
      var { invoiceId, amount, email, name, company, domain, dueDate, netTerms, notes, deliverables, poNumber } = res.invoice
      this.setState({ invoiceId, amount: amount || '', email: email || '', name: name || '', company: company || '', domain: domain || '', dueDate: dueDate || new Date(), netTerms: netTerms || '', notes: notes || '', deliverables: deliverables || [], poNumber: poNumber || '' })
    }
  }
  async _getInvoice(){ return $.ajax({ method: 'GET', url: '/api/Invoice/getInternal', data: { id: this.state.id } }) }
  async sendInvoice(){ await this.updateInvoice(); var res = await this._sendInvoice(); this.setState(res) }
  async _sendInvoice(){ return $.ajax({ method: 'POST', url: '/api/Invoice/send', data: { id: this.state.id } }) }
  async removeInvoice(){ var res = await this._removeInvoice(); this.setState(response) }
  async _removeInvoice(){ return $.ajax({ method: 'POST', url: '/api/Invoice/remove', data: { id: this.state.id } }) }
  async voidInvoice(){ var res = await this._voidInvoice(); this.setState(response) }
  async _voidInvoice(){ return $.ajax({ method: 'POST', url: '/api/Invoice/void', data: { id: this.state.id } }) }
  onChange(type, e){ this.setState({ [type]: e.target.value }) }
  setDate(type, date){ this.setState({ [type]: date }) }
  toggleCalendar() { this.setState({ calendarOpen: !this.state.calendarOpen }) }

  async updateInvoice(cb){
    var { invoiceId, amount, email, name, company, domain, dueDate, netTerms, notes, deliverables, poNumber } = this.state
    var obj = { id: invoiceId, invoice: { amount, email, name, company, domain, dueDate, netTerms, notes, deliverables, poNumber } }
    this.setState({ updatingInvoice: true })
    var res = await this._updateInvoice(obj); this.setState({ updatingInvoice: false }); return true
  }
  async _updateInvoice(data){ return $.ajax({ method: 'POST', url: '/api/Invoice/update', data: data }) }

  addDeliverable(obj){
    var { deliverables } = this.state
    deliverables.push(obj)
    this.setState({ deliverables })
    this.toggleAddDeliverable()
  }

  removeDeliverable(obj){
    var { deliverables } = this.state, newDeliverables = []
    deliverables.forEach((d) => { if (d.id != obj.id){ newDeliverables.push(d) } })
    this.setState({ deliverables: newDeliverables })
  }

  toggleAddDeliverable() { this.setState({ addDeliverableOpen: !this.state.addDeliverableOpen }) }

  render() {
    var { id, invoiceId, amount, email, name, company, domain, deliverables, dueDate, netTerms, notes, deliverables, poNumber, calendarOpen, updatingInvoice, removingInvoice, addDeliverableOpen } = this.state

    return (
      <div>
        <div className="row row0" style={{marginBottom: '25px'}}>
          <Link to="/invoices">
            <IconPack icon="arrow-left" style={{width: '18px', marginTop: '-2px'}} />
            <span style={{marginLeft: '8px'}}><b>Go Back</b></span>
          </Link>
          <div className="row row0">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Create New Invoice</h3>
            <p className="sub-text">Fill out the details below to send an invoice to the brand.</p>
          </div>
          <div className="row row0">
            <a href={`/invoice/${invoiceId}`} target="_blank">
              <button className="btn-action btn-secondary" style={{margin: 0, float: 'left'}}>Preview Invoice</button>
            </a>
          </div>
        </div>
        <div className="row row0" style={{fontFamily: 'Barlow'}}>
          <div style={{position: 'relative', width: '600px'}}>
            <div className="module">
              <div className="row row0" style={{marginBottom: '25px'}}>
                <h3 style={{padding: 0, margin: '0 0 8px 0'}}>Payment Details</h3>
                <p className="sub-text" style={{margin: 0}}>
                  Add the amount you are requesting from the brand. You won't incur any fees.
                </p>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <div className="row row0">
                    <label>Amount</label>
                    <div className="dark-input-container">
                      <div className="dark-input-preicon">$</div>
                      <input className="dark-input" value={amount} placeholder="0.00" onChange={this.onChange.bind(this, 'amount')} />
                    </div>
                  </div>
                </div>
                <div className="col-xs-6" style={{fontWeight: 'bold', textAlign: 'right'}}>
                  <div className="row row0" style={{marginBottom: '8px'}}>
                    <div className="col-xs-6">
                      <span>Total requested</span>
                    </div>
                    <div className="col-xs-6" style={{textAlign: 'right'}}>
                      <span>${Globals.numberWithCommas(amount)}</span>
                    </div>
                  </div>
                  <div className="row row0" style={{marginBottom: '8px'}}>
                    <div className="col-xs-6">
                      <span style={{marginRight: '6px'}}>💸</span><span style={{color: '#267dbb'}}>No fees</span>
                    </div>
                    <div className="col-xs-6" style={{textAlign: 'right'}}>
                      <span>$0</span>
                    </div>
                  </div>
                  <div className="row row0">
                    <div className="col-xs-6">
                      <span>You will receive</span>
                    </div>
                    <div className="col-xs-6" style={{textAlign: 'right'}}>
                      <span>${Globals.numberWithCommas(amount)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="module">
              <CreateInvoiceTitle title="Recipient Info" subtext="Fill in details about the recipient of the client brand for your deal." />
              <div className="row">
                <div className="col-xs-6">
                  <CreateInvoiceInput this={this} title="Company Email" value={email} label="email" onChangeInput={this.onChange.bind(this)} />
                  <CreateInvoiceInput this={this} title="Company Name" value={company} label="company" onChangeInput={this.onChange.bind(this)} />
                </div>
                <div className="col-xs-6">
                  <CreateInvoiceInput this={this} title="Contact Name" value={name} label="name" onChangeInput={this.onChange.bind(this)} />
                  <CreateInvoiceInput this={this} title="Company Website" value={domain} label="domain" onChangeInput={this.onChange.bind(this)} />
                </div>
              </div>
              <CreateInvoiceInput this={this} title="Notes - Optional" value={notes} label="notes" type="textarea" onChangeInput={this.onChange.bind(this)} />
            </div>
            <div className="module">
              <CreateInvoiceTitle title="Invoice Terms" subtext="Normally the brand should provide you their Net payment terms - how long they have to pay your invoice from the time you complete your collaboration. If you don't know, a 30 day Net payment terms is usually typical." />
              <div className="row">
                <div className="col-xs-6">
                  <div className="row row0">
                    <label>Due Date</label>
                    <div className="dark-input" style={{position: 'relative', cursor: 'pointer', marginTop: '5px'}} onClick={this.toggleCalendar.bind(this)}>
                      <span>{moment(+new Date(dueDate)).format('MMM Do YYYY')}</span>
                    </div>
                    <div className="row row0">
                      <DatePicker onChange={this.setDate.bind(this, 'dueDate')} value={new Date(dueDate)} isOpen={calendarOpen} />
                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <CreateInvoiceInput this={this} title="Net Terms - days" value={netTerms} label="netTerms" onChangeInput={this.onChange.bind(this)} />
                </div>
              </div>
            </div>

            <div className="module">
              <div className="row" style={{marginBottom: '25px'}}>
                <div className="col-xs-9">
                  <CreateInvoiceTitle title="Deliverables" subtext="List the deliverables for your collaboration on the invoice." style={{margin: 0}} />
                </div>
                <div className="col-xs-3" style={{textAlign: 'right'}}>
                  { !addDeliverableOpen && <button className="btn-action btn-primary" style={{margin: 0}} onClick={this.toggleAddDeliverable.bind(this)}>+ Add</button> }
                </div>
              </div>
              { addDeliverableOpen && <InvoiceAddDeliverable this={this} addDeliverable={this.addDeliverable.bind(this)} toggleAddDeliverable={this.toggleAddDeliverable.bind(this)} /> }
              { !addDeliverableOpen && <CreateInvoiceDeliverables this={this} deliverables={deliverables} removeDeliverable={this.removeDeliverable.bind(this)} /> }
            </div>

            <div className="module">
              <CreateInvoiceTitle title="PO Number - Optional" subtext="Optionally provide a PO Number if it was requested." />
              <div className="row">
                <div className="col-xs-6">
                  <CreateInvoiceInput this={this} title="PO Number" value={poNumber} label="poNumber" onChangeInput={this.onChange.bind(this)} />
                </div>
              </div>
            </div>

            <div className="row row0">
              <div className="module">
                <CreateInvoiceTitle title="Total Requested" subtext="Once you send your invoice, you cannot edit it. Make sure you double-check your info before sending!" />
                <div className="row row0">
                  <h3 style={{fontSize: '32px', marginTop: 0}}>${Globals.numberWithCommas(amount)}</h3>
                </div>
                <div className="row row0">
                  <button className="btn-action btn-primary" onClick={this.sendInvoice.bind(this)} style={{float: 'left', marginRight: '8px'}}>Send Invoice</button>
                  <button className="btn-action btn-secondary" onClick={this.updateInvoice.bind(this)} style={{float: 'left', marginRight: '8px'}}>{!updatingInvoice ? 'Save Draft' : 'Saving...'}</button>
                  <button className="btn-action btn-error" onClick={this.removeInvoice.bind(this)} style={{float: 'left', marginRight: '8px'}}>{!removingInvoice ? 'Delete Draft' : 'Deleting...'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
