import { memo } from 'react'
import Globals from '../../global-functions.js'
import Loader from "../loader";
import FilterList from './filters/filter-list'
import FilterLead from './filters/filter-lead'
import FilterAd from './filters/filter-ad'
import FilterMetrics from './filters/filter-metrics'
import FilterCompany from './filters/filter-company'
import FilterCountry from './filters/filter-country'
import FilterEmployees from './filters/filter-employees'
import FilterHashtag from './filters/filter-hashtag'
import FilterIndustry from './filters/filter-industry'
import FilterInfluencer from './filters/filter-influencer'
import FilterLanguage from './filters/filter-language'
import FilterMention from './filters/filter-mention'
import FilterSocials from './filters/filter-socials'
import FilterKeyword from './filters/filter-keyword'
import FilterEmail from './filters/filter-email'
import FilterAffiliate from './filters/filter-affiliate'
import FilterRestaurant from './filters/filter-restaurant'
import Icon from '../icon'
import PillTag from './pill-tag'

function getActiveFilters(filters){
  var filtersApplied = 0
  filters.forEach((f) => { var l = typeof f == 'object' && f[0] ? f.length : 0; if (l > 0 || (f && f.active)){ filtersApplied += l || 1 } })
  return { filters, filtersApplied }
}

const SearchFilterContainer = ((props) => {

  //
  // removeFilters(){
  //   var { filter, type, changeFilter } = props
  //   changeFilter(type, filter, 'all')
  // }
  //
  //


  var { title, type, label, filterOpen, filter, icon, iconWidth, iconHeight, changeFilter, toggleOpen, _this, isDev } = props, body = '', active = filterOpen == type[0] ? true : false
  switch (label || type[0]){
    case 'filterList': body = ( <FilterList type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterLead': body = ( <FilterLead type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterAds': body = ( <FilterAd type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterMetrics': body = ( <FilterMetrics type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterCompany': body = ( <FilterCompany type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterCountry': body = ( <FilterCountry type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterEmployees': body = ( <FilterEmployees type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterHashtag': body = ( <FilterHashtag type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterKeyword': body = ( <FilterKeyword type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterMentionBrand': body = ( <FilterMention type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterIndustry': body = ( <FilterIndustry type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterInfluencer': body = ( <FilterInfluencer type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterLanguage': body = ( <FilterLanguage type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterEmail': body = ( <FilterEmail type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterAffiliate': body = ( <FilterAffiliate type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    case 'filterRestaurant': body = ( <FilterRestaurant type={type} filter={filter} changeFilter={changeFilter} _this={_this} /> ); break;
    {/*case 'mention': body = ( <FilterMention changeFilter={changeFilter} _this={_this} /> ); break;
    case 'socials': body = ( <FilterSocials changeFilter={changeFilter} _this={_this} /> ); break;
    case 'keyword': body = ( <FilterKeyword changeFilter={changeFilter} _this={_this} /> ); break;*/}
  }

  var filterLength = 0; filter.forEach((f) => { if (f && f.length > 0) filterLength += f.length })
  var { filtersApplied } = getActiveFilters(filter)

  return (
    <> { isDev && Globals.isProd ? ( <></> ) :
    <>
      <div className={`search-filter-container ${active ? 'active' : ''}`}>
        <div className={`search-filter-header`} onClick={toggleOpen.bind(_this, type[0])}>
          <div className="row">
            <div className="col-xs-7 search-filter-header-text" style={{paddingRight: 0}}>
              <div className="row row0">
                <div className="search-filter-header-icon-container">
                  <Icon iconPack={icon} className="search-filter-header-icon" defaultColor={!active} activeColor={active} width={iconWidth||'16px'} height={iconHeight||'16px'} />
                </div>
                <div className="search-filter-header-title">
                  <span style={{fontWeight: 500}}>{title}</span>
                </div>
              </div>
            </div>
            <div className="col-xs-5 search-filter-header-caret" style={{paddingLeft: 0}}>
              { filtersApplied > 0 && <PillTag _this={this} className="pill-tag-clear-all" icon={""} name={filtersApplied} type={type} deleteTag={changeFilter.bind(_this, type, filter, 'all')} /> }
              <div style={{paddingRight: '12px'}}>
                <Icon icon={active ? 'arrow-up-1' : 'arrow-down-1'} className="search-filter-caret-img" width="8px" defaultColor={!active} activeColor={active} />
              </div>
            </div>
          </div>
        </div>
        { active && <div className="search-filter-body">{body}</div> }
      </div>
    </>
    } </>
  )
})

export default memo(SearchFilterContainer);
