import React from 'react';

export default class SlideOutTitle extends React.Component {

  render() {

    var { title, description, descriptionHtml, noMargin, hr } = this.props

    return (
      <div className="row row0">
        <h3 style={{marginTop: `${!noMargin ? '15px' : '0'}`}}>{title}</h3>
        {description && <p className="sub-text">{description}</p> }
        {descriptionHtml && <p className="sub-text"><div dangerouslySetInnerHTML={{__html: descriptionHtml}}></div></p>  }
        {hr && <hr />}
      </div>
    )
  }
}
