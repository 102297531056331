import React from 'react'
import Globals from '../../global-functions.js'

export default class DropdownCRM extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
    this.toggleCRMOpen = this.toggleCRMOpen.bind(this)
  }

  componentDidUpdate(){ }
  componentDidMount(){ document.addEventListener('click', this.toggleCRMOpen, true) }
  componentWillUnmount(){ document.removeEventListener('click', this.toggleCRMOpen, true) }

  toggleCRMOpen(e) {
    var target = document.querySelector('.dropdown-crm-ref')
    if (target && target.contains(e.target)) {  }
    else if (e.target.id == 'switch-crm-span'){  }
    else if (this.props.togglePlatformDropdown) { this.props.togglePlatformDropdown(false) }
    else if (this.props.toggleCRMActionDropdown) { this.props.toggleCRMActionDropdown(false) }
  }

  render() {
    var options = this.props.options ? this.props.options : []
    var type = this.props.type

    return (
      <div className="dropdown-crm-ref" ref={ pd => this.pd = pd } key={Math.floor(Math.random() * 99999999999)} tabIndex="-1">
        <div className="main-dropdown-container crm-filter-dropdown">
          <div className={`main-dropdown ${this.props.crmActionDropdown ? 'active' : ''}`} style={{minHeight: 'initial', top: '5px'}}>
            <div className="main-dropdown-outer">
              <div className="main-dropdown-inner">
                <div className="dropdown-section">
                  <div className={`dropdown-insert active`}>
                    <ul className="dropdown-results">
                      {
                        options.map((f) => {
                          if (type == 'add-bounty'){
                            if (f.title != 'All Bounties' && (f.active == true || f.active == 'true')){
                              return (
                                <li className="hoverable" onClick={this.props.chooseCRMAction.bind(this.props.this, f, type)} style={{padding: '8px'}} key={Math.floor(Math.random() * 99999999999)}>
                                  <div className="inner-autocomplete a-name">
                                    <span><img src={f.imageUrl} style={{width: '16px', marginTop: '-3px', borderRadius: 0}} /></span>
                                    <span>{f.title}</span>
                                  </div>
                                </li>
                              )
                            }
                          }
                          else if (type == 'move-bounty'){
                            return (
                              <li className="hoverable" onClick={this.props.chooseCRMAction.bind(this.props.this, f, type)} style={{padding: '8px'}} key={Math.floor(Math.random() * 99999999999)}>
                                <div className="inner-autocomplete a-name">
                                  {/*<span><img src={f.imageUrl} style={{width: '16px', marginTop: '-3px', borderRadius: 0}} /></span>*/}
                                  <span>{f.title}</span>
                                </div>
                              </li>
                            )
                          }
                          else {
                            return (
                              <li className="hoverable" onClick={this.props.chooseCRMAction.bind(this.props.this, f, type)} style={{padding: '8px'}} key={Math.floor(Math.random() * 99999999999)}>
                                <div className="inner-autocomplete a-name">
                                  {/*<span><img src={f.imageUrl} style={{width: '16px', marginTop: '-3px', borderRadius: 0}} /></span>*/}
                                  <span>{f.title}</span>
                                </div>
                              </li>
                            )
                          }
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
