import React from 'react';
import OnboardStatus from './onboard-status.jsx';
import Globals from '../../global-functions.js';
import Cookies from 'js-cookie';

export default class OnboardPassword extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search);
    this.setState({
      token: location.search.split('token=')[1].split('&')[0]
    })
  }

  onSubmit(e){
    Globals.logEvent('click', 'submitPassword')
    e.preventDefault()
    var p1 = $('#password-1').val()
    var p2 = $('#password-2').val()
    var user = JSON.parse(Cookies.get('userdata').replace('j:', ''))

    if (p1.length > 6 && p1 === p2){
      $.ajax({
        method: 'POST',
        url: '/api/Users/resetPassword',
        data: {resetPasswordToken: this.state.token, newPassword: p1}
      }).done((data) => {
        if (data.success){
          Globals.logEvent('event', 'passwordSuccess')
          $('#submit').text('Password set. Redirecting...')
          if (user.username || true){
            Cookies.remove('onboarding-status')
            Cookies.remove('onboarding');
            location.href = '/dashboard'
          }
          else {
            Cookies.set('onboarding-status', '/onboard-username')
            location.href = '/onboard-username'
          }
        }
        else {
          Globals.logEvent('event', 'passwordFailure')
          alert(data.message)
        }
      })
    }
    else if (p1 != p2){
      alert('passwords do not match')
    }
    else {
      alert('password must be longer than 6 characters')
    }
  }

  render() {
    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section">
            <h3>Set Password</h3>
            <div className="set-email-container">
              <OnboardStatus status="50%" />
              <form onSubmit={this.onSubmit.bind(this)}>
                <div className="input-text-wrapper email">
                  <input id="password-1" className="input-text" type="password" placeholder="Enter Password" />
                </div>
                <div className="input-text-wrapper email">
                  <input id="password-2" className="input-text" type="password" placeholder="Verify Password" />
                </div>
                <button id="submit" type="submit" className="btn-submit">SAVE PASSWORD</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
