import React from 'react';

export interface BrandTileProductProps {
  imageUrl: string;
  bountyImg: string;
  tagLine: string;
  tags: string[];
  name: string;
  bountyCredit: number;
}

const BrandTileProduct: React.FC<BrandTileProductProps> = ({ imageUrl, bountyImg, tagLine, tags, name, bountyCredit}: BrandTileProductProps) => <div>
  <div className="brand-game">
    <div className="brand-image">
      <div className="brand-image-hover"/>
      <div className="brand-image-spread" style={{backgroundImage: `url(${imageUrl || bountyImg || ''})`}} />
      <div className="brand-image-tiles" style={{display: 'none'}}>
        {/*<div className="brand-image-tile">💰 $0.85</div>*/}
        <div className="brand-image-tile">🎁 $50</div>
        <div className="brand-image-tile">🏷 10%</div>
      </div>
    </div>

    <div className="brand-meta">
      <h3>
        <span>{name}</span>
        {/*<span style={{float: 'right', fontSize: '14px', fontWeight: 400}}>{!isNaN(bountyCredit) ? bountyCredit : 1} credit</span>*/}
      </h3>
      <p className="sub-text">{tagLine}</p>
      <div className="row" style={{marginTop: '10px'}}>
        <div className="col-xs-6" />
      </div>

      <div className="row row0 bounty-tile-categories">
        <div className="bounty-tile-categories-container">
          {
            tags?.map((tag) => <div className="bounty-tile-category">{tag}</div>)
          }
        </div>
      </div>
    </div>
  </div>
</div>

export default React.memo(BrandTileProduct)
