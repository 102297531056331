import React from 'react';
import Cookies from 'js-cookie';

export default class VerifyEmail extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    const token = location.search.split('token=')[1].split('&')[0]

    $.ajax({
      method: 'POST',
      url: '/api/Users/verifyEmailToken',
      data: {emailVerificationToken: token}
    }).done((data) => {
      if (data && data.success){
        var user = data.user
        Cookies.remove('onboarding')
        Cookies.remove('onboarding-status')
        Cookies.set('userid', data.user.userid)
        Cookies.set('userdata', data.user)

        if (data.user.password){
          Cookies.remove('onboarding')
          Cookies.remove('onboarding-status')
          location.href = '/dashboard'
        }
        else if ( (user && user.username && user.channelId) ) {
          Cookies.remove('onboarding')
          Cookies.remove('onboarding-status')
          location.href = '/dashboard'
        }
        // else if (user && user.username && !user.channelId){
        //   Cookies.set('onboarding', true)
        //   Cookies.set('onboarding-status', '/onboard-connect')
        //   location.href = '/onboard-connect'
        // }
        else if (user){
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-password')
          location.href = '/onboard-password?token=' + token;
        }
      }
      else {
        alert('Not verified correctly. Please contact talent@weavesocial.com or chat us in the page.')
      }
    })
  }

  render() {
    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section">
            <h3 id="verifying-status">Verifying email...</h3>
          </div>
        </div>
      </div>
    )
  }
}
