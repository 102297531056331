import { memo } from 'react';

const FilterInfluencer = ((props) => {

  return (
    <>
      <input className="dark-input" />
    </>
  )
})

export default memo(FilterInfluencer);
