import { ActionType, createAction } from 'typesafe-actions';
import { GetBrandCampaignFailed, GetBrandCampaignRequest, GetBrandCampaignResponse } from '../models/brand-campaign.models';

export const brandCampaignActionCreators = {
  getBrandCampaignAction: createAction('brandCampaign/GET')<GetBrandCampaignRequest>(),
  getBrandCampaignSuccessAction: createAction('brandCampaign/GET/success')<GetBrandCampaignResponse>(),
  getBrandCampaignFailAction: createAction('brandCampaign/GET/fail')<GetBrandCampaignFailed>()
};

export type GetBrandCampaignAction = ActionType<
  typeof brandCampaignActionCreators.getBrandCampaignAction
  >;
export type GetBrandCampaignSuccessAction = ActionType<
  typeof brandCampaignActionCreators.getBrandCampaignSuccessAction
  >;
export type GetBrandCampaignFailAction = ActionType<
  typeof brandCampaignActionCreators.getBrandCampaignFailAction
  >;

export type BrandCampaignActions = ActionType<typeof brandCampaignActionCreators>;
