import React from 'react';

export default class ShippingTrackingLine extends React.Component {

  render() {
    var progress = this.props.progress

    return (
      <div className={`shipping-section shipping-line ${progress}`}>
        <hr />
      </div>
    )
  }
}
