import React from 'react';
import TaxInfoHeader from './taxinfo-header.jsx'

export default class TaxInfoType extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { businessType } = this.props.inputDetails
    var options = ['Sole-Proprietorship/Individual','C-corp','S-corp','Partnership','Single-member LLC','LLC C-corp','LLC S-corp','LLC Partnership']

    return (
      <div>
        <TaxInfoHeader
          title={`What's your business type?`}
          description={`If you aren't sure, select Sole-Proprietorship/Individual.`}
        />
        <div className="row">
          <div className="col-xs-6">
            <label className="sub-text">Business Entity Type</label>
            <select className="dark-input" value={businessType} onChange={this.props.onChange.bind(this.props.this, 'businessType')}>
              { options.map((o) => { return ( businessType == o ? <option selected="selected">{o}</option> : <option>{o}</option> ) }) }
            </select>
          </div>
        </div>
      </div>
    )
  }
}
