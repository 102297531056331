import { memo } from 'react'
import { LineChart, Line, XAxis, YAxis, ReferenceLine, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import Globals from '../global-functions.js'

const Chart = ((props) => {
  const { data, dataKey, value, title, isMoney, emptyData, size, color, id, includeYAxis } = props;
  if (data && data.length > 0) data.forEach((d) => { if (d.name && !d.date) d.date = d.name })
  console.log(value, Math.round(value))

  return (
    <div>
      <div className={`${size} canvas-container`}>
        <div className="module" style={{ minHeight: '180px', padding: '5px' }}>
          {/*<div style={{ position: 'absolute', height: '40px', padding: '11px', background: '#15c2fe', width: '100%', top: 0, left: 0}}>
            <h2 style={{color: '#034a63', fontFamily: 'Barlow', fontWeight: 800, textTransform: 'uppercase', fontSize: '18px', margin: 0}}>{this.props.type}</h2>
          </div>*/}

          <span className="chart-label sub-text" style={{fontFamily: 'Barlow', fontWeight: '500'}}>{title}</span>
          <span className="chart-number chart-earnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}>
            {isMoney ? '$' : ''}{Globals.numberWithCommas(value)}
            {/*<span className={`chart-indicator ${this.props.percChange >= 0 ? 'up' : 'down'}`}> <span className="chart-indicator-caret"></span> {this.props.percChange}%</span>*/}
          </span>
          {/*
            <canvas id={this.props.element} width="400" height="200"></canvas>
          */}
          <br /><br /><br />
          { data && data.length > 0 && !emptyData ?
            <div style={{position: 'relative', width: '100%', height: '150px'}}>
              <ResponsiveContainer>
                <AreaChart height={130} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
                      <stop offset="95%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
                      <stop offset="95%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id={`colorXv_${id}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="25%" stopColor={color} stopOpacity={0.1}/>
                      <stop offset="90%" stopColor={color} stopOpacity={0}/>
                    </linearGradient>
                  </defs>

                  <Tooltip
                    separator={': '}
                    formatter={(val => Globals.convertNumberFormat(val))}
                    isAnimationActive={false}
                    animationDuration={0}
                    labelStyle={{ color: '#fff', fontWeight: '500' }}
                    labelStyle={{ textAlign: 'center', display: 'none', color: '#fff', fontWeight: '500' }}
                    wrapperStyle={{ borderWidth: 0, borderRadius: 3, backgroundColor: '#1d1e1f', color: '#fff', fontWeight: '500' }}
                    contentStyle={{ borderWidth: 0, borderRadius: 3, backgroundColor: '#1d1e1f', color: '#fff', fontWeight: '500' }}
                    cursor={{ stroke: '#fff', strokeWidth: 1 }}
                    viewBox={{ height: '24' }}
                  />
                  {includeYAxis && <YAxis axisLine={false} tickLine={false} />}
                  <XAxis dataKey="date" axisLine={true} tickLine={true} interval={`preserveStartEnd`} ticks={[data[0].date, data[data.length-1].date]} />
                  <Area type='monotone' dataKey={dataKey} stroke={color} strokeWidth={2} fillOpacity={1} fill={`url(#colorXv_${id})`} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            : <div style={{padding: '0 24px', height: '150px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><b className="sub-text" style={{opacity: 0.4}}>Empty or missing data</b></div>
          }
        </div>
      </div>
    </div>
  )
})

export default memo(Chart)
