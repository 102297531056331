import React from 'react';

export default class SocialTile extends React.Component {
  constructor(props){
    super(props)
  }

  render() {

    return (
      <div className="col-sm-4 col-md-3" style={{padding: '7px'}}>
        <div className="social-tile" onClick={this.props.chooseSocial.bind(this.props.this, this.props.platform)}>
          <img style={{display: 'block', margin: '0 auto', width: '65%', textAlign: 'center'}} src={this.props.img} />
          <p className={this.props.platform} style={{textAlign: 'center', marginTop: '10px'}}><b>{this.props.title}</b></p>
          <p style={{textAlign: 'center', lineHeight: '15px'}}>{this.props.tagline}</p>
        </div>
      </div>
    )
  }
}
