import React from 'react';

export default class LinkOptions extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      active: false
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  open(){
    this.setState({
      active: !this.state.active
    })
  }

  render() {
    return (
      <div>
        <div className={`link-menu-container ${this.state.active ? 'active' : ''}`}>
          <span className="link-menu-ellipsis" onClick={this.open.bind(this)}>...</span>
          <ul className="link-menu">
            <li onClick={this.props.searchAgain.bind(this.props.this, this.props.link)}>Search again</li>
            <li onClick={this.props.archiveLink.bind(this.props.this, this.props.link)}>Remove</li>
          </ul>
        </div>
      </div>
    )
  }
}
