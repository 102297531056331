import React from 'react';

export default class YouTubeDescription extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      descriptionInvalid: false,
      descriptionLength: 0
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
    this.checkDescription()
  }

  checkDescription(){
    let descriptionLength = $('#youtube-description').val().length
    let descriptionInvalid = descriptionLength > 5000 ? true : false
    this.setState({
      descriptionInvalid: descriptionInvalid,
      descriptionLength: descriptionLength
    })
  }

  render() {
    return (
      <div>
        <div className="youtube-des-container">
          <div className="youtube-des-header">
            <div className="row" style={{padding: '15px'}}>
              <div className="col-xs-8">
                <h4 style={{marginBottom: 0, marginTop: 0}}>Update YouTube Description</h4>
                <span style={{fontSize: '12px', color: '#cccccc'}}>description length: {this.state.descriptionLength}/5000</span>
              </div>
              <div className="col-xs-4">
                <button
                  id="update-yt-description"
                  className="copy-link-btn"
                  onClick={this.props.updateDescription.bind(this.props.this)}
                  disabled={this.state.descriptionInvalid ? 'disabled' : ''}
                >
                  {this.state.descriptionInvalid ? 'Description too long' : 'Update'}
                </button>
              </div>
            </div>
          </div>
          <textarea id="youtube-description" style={{width: '100%', minHeight: '250px', padding: '15px', border: 'none', outline: 0, resize: 'none'}} onChange={this.checkDescription.bind(this)} defaultValue={this.props.video ? this.props.video.snippet.description : ''}></textarea>
        </div>
      </div>
    )
  }
}
