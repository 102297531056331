import { Component, memo } from 'react';
import Globals from '../global-functions.js';
import Loader from './loader.jsx';
import '../../styles/scss/slideout.scss';
import '../../styles/scss/popup.scss';
import SlideOutHeader from './slide-out/slide-out-header.jsx';
import SlideOutOffer from './slide-out/slide-out-offer.jsx';
import SlideOutAccept from './slide-out/slide-out-accept.jsx';
import SlideOutBounty from './slide-out/slide-out-bounty.jsx';
import SlideOutMenu from './slide-out/slide-out-menu.jsx';
import SlideOutShop from './slide-out/slide-out-shop.jsx';
import ShippingTracking from './shipping-tracking/shipping-tracking.jsx';
import { isDev } from '../hooks';

var oldScroll = 0,
  oldHeight = 0,
  ALLOWED_SWIPE_TIME = 300;

class SlideOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Globals.getUser(),
      influencers: [],
      domains: [],
      videos: [],
      //user: JSON.parse(Cookies.get('user')),
      skip: 0,
      autocomplete: [],
      autocompleteNames: [],
      searchValue: '',
      dropdown: false,
      tags: [],
      searchArtists: [],
      chosenArtist: false,
      loading: false,
      loadingSeq: false,
      searchBtnTxt: 'Find Fans',
      links: [],
      optionPost: false,
      optionStory: false,
      optionDedicated: false,
      optionFeature: false,
      optionStream: false,
      optionMixer: false,
      tabActive: 'offer',
      message: '',
      photos: [],
      reviews: [],
      brandColors: { Vibrant: {}, DarkVibrant: {}, DarkMuted: {}, },
      gameMeta: {},
      activateBountyStatus: 'inactive',
      vodLinkVal: '',
      vodAutocomplete: [],
      scrollingDown: false,
      submitBountyBtnText: 'Submit Bounty Request',
      acceptInviteBountyBtnText: 'Click To Accept Invitation',
      bountyParticipants: [],
      campaign: {},
    };
  }

  // componentWillUpdate(nextState, nextProps){
  //
  // }

  UNSAFE_componentWillMount() { document.addEventListener('mousedown', this.handleClose.bind(this), false); }

  componentDidMount() {
    if (this.props.brand_active) { }
    if (!this.state.initiatedScroll) { this.handleScroll(); }
    if (this.props.viewType == 'standalone'){ var offer = this.props.campaign ? this.props.campaign : {}; this.updateSlideOut(offer); }
    this.enable();
  }

  componentWillUnmount() { document.removeEventListener( 'mousedown', this.handleClose.bind(this), false ); }

  recordTouchStartValues(e) { var touch = e.changedTouches[0]; this.startX = touch.pageX; this.startY = touch.pageY; this.startTime = new Date().getTime(); }

  detectSwipeDirection(e) {
    var touch = e.changedTouches[0];
    var distX = touch.pageX - this.startX;
    var distY = touch.pageY - this.startY;
    var absX = Math.abs(distX);
    var absY = Math.abs(distY);
    var elapsedTime = new Date().getTime() - this.startTime;

    // console.log(distX, distY)

    if (elapsedTime > ALLOWED_SWIPE_TIME) return;

    switch (true) {
      case absX >= this.threshold && absX > absY && distX < 0: this.doSwipeDirection('left'); break;
      case absX >= this.threshold && absX > absY && distX > 0: this.doSwipeDirection('right'); this.props.closeSlideOut(this.props.this); break;
      case absY >= this.threshold && absY > absX && distY < 0: this.doSwipeDirection('up'); break;
      case absY >= this.threshold && absY > absX && distY > 0: this.doSwipeDirection('down'); break;
    }
  }

  doSwipeDirection(val){ return val }

  enable() { document.getElementById('slide-out').addEventListener('touchstart', this.recordTouchStartValues.bind(this)); document.getElementById('slide-out').addEventListener('touchend', this.detectSwipeDirection.bind(this)); }
  disable() { document.getElementById('slide-out').removeEventListener('touchstart', this.recordTouchStartValues.bind(this)); document.getElementById('slide-out').removeEventListener('touchend', this.detectSwipeDirection.bind(this)); }

  handleScroll() {
    this.setState({ initiatedScroll: true, });

    oldHeight = $('.slide-out-offer-scrollable').height();
    $('.slide-out-offer-scrollable').scroll((e) => {
      var scrollTop = $(e.target).scrollTop(), newHeight = $('.slide-out-offer-scrollable').height();
      var direction = oldScroll > scrollTop ? 'up' : 'down';
      if (oldHeight != newHeight) { oldHeight = newHeight; oldScroll = scrollTop; }
      else {
        var scrollGap = Math.abs(oldScroll - scrollTop) > 50 ? true : false;
        if (direction == 'up' && this.state.scrollingDown && scrollGap) { oldScroll = scrollTop; this.setState({ scrollingDown: false, }); }
        else if ( direction == 'down' && !this.state.scrollingDown && scrollGap) { oldScroll = $(e.target).scrollTop(); this.setState({ scrollingDown: true, }); }
      }
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.campaign && this.props.campaign != props.campaign) {
      $('.slide-out-container').scrollTop(0);
      var offer = props.campaign ? props.campaign : {};
      this.updateSlideOut(offer);
      // this.getBountyParticipants(offer)
      if (offer.brandType == 'restaurant') {
        // this._getCampaignMeta({
        //   id: offer.restaurant.yelp_id,
        //   brandType: offer.brandType,
        // }).done((response) => {
        //   this.setState({
        //     reviews: response.reviews,
        //     photos: response.photos,
        //   });
        //   // setTimeout(() => {
        //   //   var doc = document.getElementsByClassName('chat-messages-container')
        //   //   if (doc) { doc[0].scrollTop = 10000000 }
        //   // }, 5)
        // });
      } else if (offer.brandType == 'game') {
        // this.getMetadata(props);
      } else {
        // this.updateSlideOut(props.influencer)
      }
      this.setState({ tabActive: 'offer', });
    }
  }

  updateSlideOut(params) {
    this.setState({ loading: true })
    this._getBrandCampaign(params).done((response) => { this.setState({ campaign: response.campaign, loading: false }); });
  }

  _getBrandCampaign(params) { return $.ajax({ method: 'GET', url: '/api/Campaign/getBrandCampaign', data: { userid: this.props.user.userid, id: params.id, type: params.viewType }, }); }
  getBountyParticipants(offer) { this._getBountyParticipants(offer).done((response) => { this.setState({ bountyParticipants: response, }); }); }
  _getBountyParticipants(offer) { return $.ajax({ method: 'GET', url: '/api/Campaign/getBountyParticipants', data: { offerId: offer.id }, }); }
  getMetadata(props) { this.getCampaignMeta(props); }

  getCampaignMeta(props) {
    var campaign = props.campaign;
    this._getCampaignMeta({ brandType: campaign.brandType, game_id: campaign.game ? campaign.game.id : false,}).done((response) => { setTimeout(() => { this.setState({ brandColors: response.palette, gameMeta: response.game, }); }, 50); });
  }

  chooseVod(vod) { this._chooseVod(vod).done((response) => {}); }
  _chooseVod(vod) { return $.ajax({ method: 'POST', url: '/api/Campaign/chooseVod', data: { vod: vod, content_id: this.props.campaign.content.id }, }); }
  getVodList(id) { this._getVodList(id).done((response) => { if (response) { this.setState({ vodAutocomplete: response, }); } }); }
  _getVodList(id) { return $.ajax({ method: 'GET', url: '/api/Campaign/getVodList', data: { user_id: id }, }); }
  _getCampaignMeta(params) { return $.ajax({ method: 'GET', url: '/api/Campaign/getCampaignMeta', data: params, }); }

  // _getInfluencerPanel(id){
  //   return $.ajax({
  //   	method: 'GET',
  //   	url: '/api/Search/getInfluencerPanel',
  //   	data: { id: id }
  //   })
  // }

  connectSocialSlideOut() { this.props.closeSlideOut(this.props.this); setTimeout(() => { location.replace('/account'); }, 25); }

  handleClose(e, close) {
    if (this.props.active) {
      var status = this.props.campaign.content ? this.props.campaign.content.status : false;
      if (close) {
        console.log('1')
        this.props.closeSlideOut(this.props.this);
        if (status == 'brand_offer') { this.setState({ tabActive: 'offer' }); }
      }
      else if (this.node && this.node.contains(e.target)) { console.log('2'); return; }
      else if (e.target.className && typeof e.target.className == 'string' && e.target.className.indexOf('brand-tile') > -1) { console.log('3'); return; }
      else {
        console.log('4')
        if (status == 'brand_offer') { this.setState({ tabActive: 'offer' }); }
        this.props.closeSlideOut(this.props.this);
      }
    }
    else { console.log('5') }
  }

  addLink(link) {
    var val = $('.add-link').val();
    if (Globals.isURL(val)) { this.setState({ links: this.state.links.concat(val), }); $('.add-link').val(''); }
    else { $('.add-link').css('border-color', 'red'); }
  }

  chooseOption(option) { this.setState({ [`option${option}`]: !this.state[`option${option}`], }); }
  chooseTab(option) { this.setState({ tabActive: option, }); }
  deleteCampaign() {}

  requestCampaign(obj) {
    console.log('requestCampaign', this.state.activateBountyStatus)
    if (this.state.activateBountyStatus != 'active'){
      console.log('requestCampaign')
      console.log(obj)
      var params = {
        campaign_name: this.props.campaign.name,
        message: obj.message ? obj.message : this.state.message,
        // campaign: this.props.campaign,
        brandType: this.props.campaign.brandType,
        offerId: obj.offer.id,
        contentId: obj.content.id,
        objectives: obj.objectives,
        timeframe: obj.timeframe,
        status: this.props.campaign.content ? this.props.campaign.content.status : false,
        product: obj.product,
        giftOption: obj.giftOption,
        sizeShirt: obj.sizeShirt,
        paymentFlatRateCreatorProposal: obj.paymentFlatRateCreatorProposal,
      };

      if (this.props.campaign.content && this.props.campaign.content.id) { params.contentId = this.props.campaign.content.id }
      this.setState({ activateBountyStatus: 'active', submitBountyBtnText: 'Submitting Bounty...', acceptInviteBountyBtnText: 'Accepting Invite...' });

      if (obj.objectives.length > 0) {
        this._requestCampaign(params).done((response) => {
          // this.state.brand_campaigns[params.num].pending = true
          if (this && this.props && this.props.bountyActivated) { this.props.bountyActivated(this.props.this, params); }
          this.setState({
            brand_campaigns: this.state.brand_campaigns,
            activateBountyStatus: 'inactive',
            submitBountyBtnText: 'Bounty Request Submitted!',
            acceptInviteBountyBtnText: 'Accepting Invite...'
          });
          setTimeout(() => {
            this.setState({
              // brand_campaigns: this.state.brand_campaigns,
              // activateBountyStatus: 'inactive',
              submitBountyBtnText: 'Submit Bounty Request',
              acceptInviteBountyBtnText: 'Click To Accept Invitation'
            });
          }, 1250)
        });
      } else { alert('Please select at least one submission type.'); }
    }
  }

  _requestCampaign(params) { return $.ajax({ method: 'POST', url: '/api/Campaign/requestCampaign', data: params, }); }
  updateText(type, e) { this.setState({ [type]: e.target.value, }); }
  _notifyContinueOnBounty(title) { return $.ajax({ method: 'POST', url: '/api/Users/notifyContinueOnBounty', data: { title: title } }) }

  startAcceptBounty() {
    $('.slide-out-container').scrollTop(0);
    this.setState({ tabActive: 'offer_accept', });
    var campaign = this.state.campaign ? this.state.campaign : {};
    var bountyTitle = campaign && campaign.bountyTitle ? campaign.bountyTitle : (campaign.offer ? campaign.offer.bountyTitle : 'Bounty')
    this._notifyContinueOnBounty(bountyTitle).done();
  }

  render() {
    // var campaign = this.props.campaign
    var campaign = this.state.campaign ? this.state.campaign : {};
    var user = campaign && campaign.user ? campaign.user : this.state.user;
    var game = campaign.game ? campaign.game : campaign.offer;
    var offer = campaign.offer ? campaign.offer : {};
    var content = campaign.content ? campaign.content : {};
    var brandType = false;
    if (campaign) {
      campaign.brandType = campaign.brandType ? campaign.brandType : offer.brandType;
      brandType = campaign.brandType;
    }
    var restaurant = campaign.restaurant;
    var stars = '';
    if (restaurant) {
      var rating = Math.floor(restaurant.rating), partial = restaurant.rating - rating > 0 ? '_half' : '';
      stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`;
    }
    if (this.state.reviews) {
      this.state.reviews.forEach((r) => {
        var rating = Math.floor(r.rating), partial = r.rating - rating > 0 ? '_half' : '';
        r.stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`;
      });
    }

    var pageHTML = '';

    var platformVars = {
      product: { color: '#96bf48', text: { active: 'Requesting...', inactive: 'Request Products', }, },
      game: { color: '#6441a4', text: { active: 'Activating...', inactive: 'Activate Bounty', }, },
      restaurant: { color: '#d32323', text: { active: 'Redeeming...', inactive: 'Redeem Offer', }, },
    };

    if ( content && content.status != 'brand_awaiting' && content.status != 'brand_offer' && content.status != 'brand_incomplete' && content.status != 'brand_invited' ) {
      pageHTML = (
        <>
          <ShippingTracking
            brandType={campaign.brandType}
            status={content.status}
            this={this}
            _this={this.props.this}
            active={this.props.active}
            user={this.props.user}
            campaign={campaign}
            closeSlideOut={this.props.closeSlideOut}
            this={this.props.this}
            bountyActivated={this.props.bountyActivated}
            bountyCredits={this.props.bountyCredits}
            liveStreamElapsed={this.props.liveStreamElapsed}
            expandBountyDetails={this.props.expandBountyDetails}
            bountyDetailsExpanded={this.props.bountyDetailsExpanded}
            deleteCampaign={this.props.deleteCampaign}
            addVod={this.props.addVod}
            updateText={this.updateText}
            getVodList={this.getVodList}
            chooseVod={this.chooseVod}
            activateBountyStatus={this.state.activateBountyStatus}
            chooseOption={this.chooseOption}
            scrollingDown={this.state.scrollingDown}
            startAcceptBounty={this.startAcceptBounty.bind(this)}
            tabActive={this.state.tabActive}
            chooseTab={this.chooseTab.bind(this)}
          />

          {(this.state.tabActive == 'offer' || true) && (
            <SlideOutBounty
              brandType={campaign.brandType}
              status={content.status}
              this={this}
              _this={this.props.this}
              active={this.props.active}
              user={this.props.user}
              campaign={campaign}
              updateSlideOut={this.updateSlideOut.bind(this)}
              closeSlideOut={this.props.closeSlideOut}
              this={this.props.this}
              bountyActivated={this.props.bountyActivated}
              bountyCredits={this.props.bountyCredits}
              liveStreamElapsed={this.props.liveStreamElapsed}
              expandBountyDetails={this.props.expandBountyDetails}
              bountyDetailsExpanded={this.props.bountyDetailsExpanded}
              deleteCampaign={this.props.deleteCampaign}
              addVod={this.props.addVod}
              updateText={this.updateText}
              getVodList={this.getVodList}
              chooseVod={this.chooseVod}
              activateBountyStatus={this.state.activateBountyStatus}
              chooseOption={this.chooseOption}
              scrollingDown={this.state.scrollingDown}
              startAcceptBounty={this.startAcceptBounty.bind(this)}
              tabActive={this.state.tabActive}
              chooseTab={this.chooseTab.bind(this)}
            />
          )}

          {(this.state.tabActive == 'guidelines' || true) && (
            <SlideOutOffer
              this={this}
              active={this.props.active}
              user={this.props.user}
              campaign={campaign}
              closeSlideOut={this.props.closeSlideOut}
              this={this.props.this}
              bountyActivated={this.props.bountyActivated}
              bountyCredits={this.props.bountyCredits}
              liveStreamElapsed={this.props.liveStreamElapsed}
              expandBountyDetails={this.props.expandBountyDetails}
              bountyDetailsExpanded={this.props.bountyDetailsExpanded}
              deleteCampaign={this.props.deleteCampaign}
              addVod={this.props.addVod}
              updateText={this.updateText}
              getVodList={this.getVodList}
              chooseVod={this.chooseVod}
              activateBountyStatus={this.state.activateBountyStatus}
              chooseOption={this.chooseOption}
              scrollingDown={this.state.scrollingDown}
              startAcceptBounty={this.startAcceptBounty.bind(this)}
              connectSocialSlideOut={this.connectSocialSlideOut.bind(this)}
              bountyParticipants={this.state.bountyParticipants}
            />
          )}
        </>
      );
    } else if (this.state.tabActive == 'offer') {
      pageHTML = (
        <SlideOutOffer
          this={this}
          active={this.props.active}
          user={this.props.user}
          campaign={campaign}
          closeSlideOut={this.props.closeSlideOut}
          this={this.props.this}
          bountyActivated={this.props.bountyActivated}
          bountyCredits={this.props.bountyCredits}
          liveStreamElapsed={this.props.liveStreamElapsed}
          expandBountyDetails={this.props.expandBountyDetails}
          bountyDetailsExpanded={this.props.bountyDetailsExpanded}
          deleteCampaign={this.props.deleteCampaign}
          addVod={this.props.addVod}
          updateText={this.updateText}
          getVodList={this.getVodList}
          chooseVod={this.chooseVod}
          activateBountyStatus={this.state.activateBountyStatus}
          chooseOption={this.chooseOption}
          scrollingDown={this.state.scrollingDown}
          startAcceptBounty={this.startAcceptBounty.bind(this)}
          connectSocialSlideOut={this.connectSocialSlideOut.bind(this)}
          bountyParticipants={this.state.bountyParticipants}
        />
      );
    } else if (this.state.tabActive == 'offer_accept') {
      pageHTML = (
        <SlideOutAccept
          this={this}
          active={this.props.active}
          offerId={campaign && campaign.offer ? campaign.id : false}
          user={this.props.user}
          campaign={campaign}
          closeSlideOut={this.props.closeSlideOut}
          handleClose={this.handleClose}
          thisClose={this}
          this={this.props.this}
          requestCampaign={this.requestCampaign.bind(this)}
          bountyActivated={this.props.bountyActivated}
          bountyCredits={this.props.bountyCredits}
          liveStreamElapsed={this.props.liveStreamElapsed}
          expandBountyDetails={this.props.expandBountyDetails}
          bountyDetailsExpanded={this.props.bountyDetailsExpanded}
          deleteCampaign={this.props.deleteCampaign}
          addVod={this.props.addVod}
          message={this.state.message}
          updateText={this.updateText.bind(this)}
          getVodList={this.getVodList}
          chooseVod={this.chooseVod}
          activateBountyStatus={this.state.activateBountyStatus}
          chooseOption={this.chooseOption}
          scrollingDown={this.state.scrollingDown}
          submitBountyBtnText={this.state.submitBountyBtnText}
          acceptInviteBountyBtnText={this.state.acceptInviteBountyBtnText}
          chooseTab={this.chooseTab.bind(this)}
        />
      );
    } else if (this.state.tabActive == 'details') {
      pageHTML = (
        <SlideOutOffer
          this={this}
          active={this.props.active}
          user={this.props.user}
          campaign={campaign}
          closeSlideOut={this.props.closeSlideOut}
          this={this.props.this}
          bountyActivated={this.props.bountyActivated}
          bountyCredits={this.props.bountyCredits}
          liveStreamElapsed={this.props.liveStreamElapsed}
          expandBountyDetails={this.props.expandBountyDetails}
          bountyDetailsExpanded={this.props.bountyDetailsExpanded}
          deleteCampaign={this.props.deleteCampaign}
          addVod={this.props.addVod}
          updateText={this.updateText}
          getVodList={this.getVodList}
          chooseVod={this.chooseVod}
          activateBountyStatus={this.state.activateBountyStatus}
          chooseOption={this.chooseOption}
          scrollingDown={this.state.scrollingDown}
          startAcceptBounty={this.startAcceptBounty.bind(this)}
        />
      );
    } else if (this.state.tabActive == 'menu') {
      pageHTML = (
        <SlideOutMenu
          active={this.props.active}
          user={this.props.user}
          campaign={campaign}
          closeSlideOut={this.props.closeSlideOut}
          this={this.props.this}
          bountyActivated={this.props.bountyActivated}
          bountyCredits={this.props.bountyCredits}
          liveStreamElapsed={this.props.liveStreamElapsed}
          expandBountyDetails={this.props.expandBountyDetails}
          bountyDetailsExpanded={this.props.bountyDetailsExpanded}
          deleteCampaign={this.props.deleteCampaign}
          addVod={this.props.addVod}
        />
      );
    } else if (this.state.tabActive == 'shop') {
      pageHTML = (
        <SlideOutShop
          active={this.props.active}
          user={this.props.user}
          campaign={campaign}
          closeSlideOut={this.props.closeSlideOut}
          this={this.props.this}
          requestCampaign={this.props.requestCampaign}
          bountyActivated={this.props.bountyActivated}
          bountyCredits={this.props.bountyCredits}
          liveStreamElapsed={this.props.liveStreamElapsed}
          expandBountyDetails={this.props.expandBountyDetails}
          bountyDetailsExpanded={this.props.bountyDetailsExpanded}
          deleteCampaign={this.props.deleteCampaign}
          addVod={this.props.addVod}
        />
      );
    }
    // else if (this.state.tabActive == 'progress'){
    //   pageHTML = (
    //     <SlideOutProgress
    //       active={this.props.active}
    //       user={this.props.user}
    //       campaign={this.props.campaign}
    //       closeSlideOut={this.props.closeSlideOut}
    //       this={this.props.this}
    //       requestCampaign={this.props.requestCampaign}
    //       deleteRequestCampaign={this.props.deleteRequestCampaign}
    //       bountyActivated={this.props.bountyActivated}
    //       bountyCredits={this.props.bountyCredits}
    //       liveStreamElapsed={this.props.liveStreamElapsed}
    //       expandBountyDetails={this.props.expandBountyDetails}
    //       bountyDetailsExpanded={this.props.bountyDetailsExpanded}
    //       deleteCampaign={this.props.deleteCampaign}
    //       addVod={this.props.addVod}
    //       getVodList={this.getVodList}
    //       chooseVod={this.chooseVod}
    //     />
    //   )
    // }

    return (
      <div id={`slide-out`} ref={(node) => (this.node = node)}>
        <div className={`slide-out outline ${this.props.active == 'true' ? 'active' : ''} ${this.props.viewType == 'standalone' ? 'slide-out-standalone' : ''}`}>
          <div className="slide-out-container">
            { this.state.loading && <Loader active={true} /> }
            {
              !this.state.loading &&
              <>
                <div className="popup-module-outer">
                  <div className="popup-module-inner">
                    <SlideOutHeader
                      active={this.props.active}
                      user={this.props.user}
                      campaign={campaign}
                      closeSlideOut={this.props.closeSlideOut}
                      chooseTab={this.chooseTab}
                      handleClose={this.handleClose}
                      thisClose={this}
                      this={this.props.this}
                      requestCampaign={this.props.requestCampaign}
                      bountyActivated={this.props.bountyActivated}
                      bountyCredits={this.props.bountyCredits}
                      liveStreamElapsed={this.props.liveStreamElapsed}
                      expandBountyDetails={this.props.expandBountyDetails}
                      bountyDetailsExpanded={this.props.bountyDetailsExpanded}
                      deleteCampaign={this.props.deleteCampaign}
                      addVod={this.props.addVod}
                      tabActive={this.state.tabActive}
                      scrollingDown={this.state.scrollingDown}
                    />

                    <div>
                      {pageHTML}

                      {isDev() &&
                        content &&
                        content.status != 'brand_awaiting' &&
                        content.status != 'brand_offer' &&
                        content.status != 'brand_incomplete' &&
                        content.status != 'brand_invited' && (
                          <div className="row row0" style={{ marginTop: '100px' }}>
                            <p style={{ color: 'rgb(189, 187, 192)', textAlign: 'center', fontSize: '12px', }}>
                              *Unable to complete the bounty?{' '}
                              <a onClick={this.props.deleteCampaign.bind( this.props.this, this.props.this, content.id )} style={{ cursor: 'pointer' }}>
                                <b>Click here to cancel</b>
                              </a>
                              . Repeated cancelled bounties may limit your chances
                              to participate in future bounties.
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>
    );
  }
}

export default memo(SlideOut)
