import React from 'react';
import Globals from '../global-functions.js';
import ShippingTrackingRestaurantBar from './shipping-tracking/shipping-tracking-restaurant-bar.jsx';
import SlideOutProgressTitle from './slide-out/slide-out-progress-title.jsx';

export default class BountyRestaurant extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {

    // var username = this.props.username,
    // campaign = this.props.campaign,
    // game = campaign.game ? campaign.game : campaign.offer,
    // content = campaign ? campaign.content : {},
    // campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    // user = campaign.user,
    // offer = campaign.offer,
    // restaurant = this.props.restaurant,
    // name = restaurant.name

    var restaurant = this.props.restaurant
    var campaign = this.props.campaign
    var content = campaign.content ? campaign.content : {}
    var offer = this.props.offer
    var stars = ''
    if (restaurant){
      var rating = Math.floor(restaurant.rating), partial = restaurant.rating - rating > 0 ? '_half' : ''
      stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`
    }
    else { restaurant = {} }

    var bountyCategoryTags = typeof restaurant.categories == 'string' ? restaurant.categories.split(',') : restaurant.categories
    var name = restaurant.name


    return (
      <div>
        <div className="brand-game">
          <div className="brand-image">
            <div className="brand-image-spread">
              {
                restaurant && restaurant.photos &&
                restaurant.photos.map((p, i) => {
                  var borderRadius = 0
                  if (i == 0) { borderRadius = '15px 0 0 0' }
                  else if (i == 2) { borderRadius = '0 15px 0 0' }

                  return (
                    <div className="brand-res-photo-container">
                      <div className="brand-res-photo" style={{backgroundImage: `url(${p})`, borderRadius: borderRadius}}></div>
                    </div>
                  )
                })
              }
              <div className="brand-image-profile" style={{backgroundImage: `url(${restaurant.image_url})`}}></div>
            </div>
          </div>

          <div className="brand-meta">
            <h3>{restaurant.name}</h3>
            <div style={{padding: '0 25px'}}>
              <ShippingTrackingRestaurantBar status={content.status} size="small" />
            </div>
            <div className="row row0" style={{paddingTop: '8px'}}>
              {
                content.status == 'brand_applied' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.proposedOffer} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>credit</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Awaiting approval from {name}.</p>
                  <p className="sub-text">We'll update you if you are accepted or passed.</p>
                </>
              }
              {
                content.status == 'brand_responded' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.proposedOffer} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>credit</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Awaiting approval from {name}.</p>
                  <p className="sub-text">We'll update you if you are accepted or passed.</p>
                </>
              }
              {
                content.status == 'brand_dining' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.proposedOffer} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>credit</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Dine in the restaurant and pay with a connected card.</p>
                  <p className="sub-text">{restaurant.address1} {restaurant.address2}, {restaurant.city}, {restaurant.state} {restaurant.zip}</p>
                </>
              }
              {
                content.status == 'brand_posting' &&
                content.paymentMethod == 'stripe' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.spentAmount} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>spent</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Redeemed meal at {restaurant.name} for ${content.merchantPlaidTransAmount}</p>
                  <p className="sub-text">Now post your content to complete the bounty.</p>
                </>
              }
              {
                content.status == 'brand_posting' &&
                content.paymentMethod == 'plaid' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.spentAmount} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>spent</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Pending reimbursement from {restaurant.name} for ${content.merchantPlaidTransAmount}</p>
                  <p className="sub-text">Once your post is live and approved, your reimbursement will be available for withdrawal.</p>
                </>
              }
              {
                content.status == 'brand_live' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.spentAmount} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>spent</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Pending reimbursement from {restaurant.name} for ${content.merchantPlaidTransAmount}</p>
                  <p className="sub-text">Your post is under review and you will be reimbursed after approval.</p>
                </>
              }
              {
                content.status == 'brand_completed' &&
                content.orderType == 'store' &&
                content.payType == 'personal' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.spentAmount} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>reimbursed</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Congrats! {restaurant.name} reimbursed ${content.merchantPlaidTransAmount} for your withdrawal.</p>
                  <p className="sub-text">We appreciate you dining with us, please consider leaving a review.</p>
                </>
              }
              {
                content.status == 'brand_completed' &&
                content.orderType == 'store' &&
                content.payType == 'stripe' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}><span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>BOUNTY COMPLETE</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Congrats! {restaurant.name} approved your post.</p>
                  <p className="sub-text">We appreciate you dining with us, please consider leaving a review.</p>
                </>
              }
              {
                content.status == 'brand_completed' &&
                content.orderType == 'online' &&
                <>
                  {/*<h4 style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '22px'}}>${content.spentAmount} <span className="sub-text" style={{fontSize: '16px', fontWeight: '500'}}>reimbursed</span></h4>*/}
                  <p style={{margin: '0', fontSize: '16px', fontWeight: '500'}}>Congrats! {restaurant.name} reimbursed ${content.merchantPlaidTransAmount} for your withdrawal.</p>
                  <p className="sub-text">We appreciate you dining with us, please consider leaving a review.</p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
