import { memo } from 'react';
import FilterOption from './filter-option'

const FilterAffiliate = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <FilterOption title="Has Affiliate Program" label="yes" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="No Affiliate Program" label="none" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
    </>
  )
})

export default memo(FilterAffiliate);


// var dropdownOptionsAffiliate = [
//   { name: 'Has Affiliate' },
//   { name: 'No Affiliate' },
//   { name: 'Refersion' },
//   { name: 'CJ' },
//   { name: 'Shareasale' },
//   { name: 'Rakuten' },
//   { name: 'Awin' },
//   { name: 'ImpactRadius' },
//   { name: 'Pepperjam' },
//   { name: 'Avantlink' },
//   { name: 'Skimlinkes' },
//   { name: 'Viglink' }
// ]
