import React from 'react';
import Cookies from 'js-cookie';

export default class YouTubeRedirect extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      access_token: Cookies.get('access_token')
    }
  }


  componentDidMount(){
    console.log('youtube redirect')
    // Globals.logEvent('event', 'youtubeRedirect')
    var id_token = gp('code')
    $.ajax({ method: 'POST', url: '/api/YouTube/login', data: {token: id_token, access_token: Cookies.get('access_token')} })
    .done((data) => {
      console.log(data)
      if (data.success) {
        // location.replace('/waiting-list')
        // Globals.logEvent('event', 'youtubeSignupSuccess')
        var user = data.user
        var redirect = Cookies.get('redirect_google')
        Cookies.remove('redirect_google')

        Cookies.set('access_token', user.access_token)
        Cookies.set('userid', user.userid)
        Cookies.set('userdata', user)

        if (!user.email && user.youtubeConnected) {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-yt-approved')
          location.replace('/onboard-yt-approved')
        }
        else if (redirect == 'finish_yt_connected') {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-yt-approved')
          Cookies.set('onboarding-finished', true)
          location.replace('/onboard-yt-approved')
        }
        else if (!user.email) {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-email')
          location.replace('/onboard-email')
        }
        else if ( redirect && redirect.indexOf('onboard') > -1 ){
          Cookies.remove('redirect_google')
          location.href = redirect
        }
        else if (redirect && redirect.indexOf('/links') > -1){
          Cookies.remove('redirect_google')
          location.replace(redirect)
        }
        else {
          Cookies.remove('onboarding')
          Cookies.remove('onboarding-status')

          Cookies.set('userid', user.userid)
          Cookies.set('userdata', JSON.stringify(user))
          console.log('should redirect')
          console.log(user, redirect)
          console.log('******')
          console.log( Cookies.get('userdata') )
          location.replace('/account')
        }
      }
      else {
        console.log('no data?')
        // Globals.logEvent('event', 'youtubeSignupFailure')
        // confirm(data.message);
        // location.replace('/')
      }

    });

    function gp(n) {
      let m = RegExp('[?&]' + n + '=([^&]*)').exec(window.location.search);
      return m && decodeURIComponent(m[1].replace(/\+/g, ' '));
    }

  }

  render() {
    return (
      <div>

      </div>
    )
  }
}
