import React from 'react';

export default class Template extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {

    var campaign = this.props.campaign
    var user = this.props.user
    var game = this.props.game
    var offer = this.props.offer
    var content = this.props.content
    var brandColors = this.props.brandColors
    var bountyCategoryTags = offer && offer.bountyCategoryTags ? offer.bountyCategoryTags : []

    var bannerImageUrl = offer.bannerImageUrl ? offer.bannerImageUrl : offer.imageUrl
    var imageUrl = offer.imageUrl ? offer.imageUrl : offer.imageUrl

    // var imageUrl = game && game.box_art_url ? game.box_art_url.replace('{width}', '564').replace('{height}', '750') : (offer.imageUrl ? offer.imageUrl : offer.imageUrl)
    // var imageUrl = game && game.box_art_url ? game.box_art_url.replace('{width}', '564').replace('{height}', '750') : (offer.imageUrl ? offer.imageUrl : offer.imageUrl)

    return (
      <>
        <div className={`game-header ${this.props.scrollingDown ? 'minimize' : ''}`} style={{background: `linear-gradient(0deg, #2d3135 0, ${brandColors.DarkMuted.rgb}), linear-gradient(90deg, ${brandColors.DarkVibrant.rgb} 0, ${brandColors.Vibrant.rgb}) !important`}}>
          <div className="game-header-banner" style={{backgroundImage: `url(${bannerImageUrl})`}}>
            <div className="game-header-banner-darken"></div>
          </div>

          {/*<div className="game-header-outer">
            <div className="game-header-inner">
              <div className="game-header-img">
                <div className="game-header-img-container">
                  <div className="game-header-img-outer">
                    <div className="game-header-img-inner">
                      <div className="game-header-img-padding"></div>
                      <div className="game-header-img-div" alt={game.name} style={{backgroundImage: `url(${imageUrl})`}}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="game-header-meta">
                <div className="game-header-name">
                  <h1 className="" style={{fontFamily: 'Barlow', fontWeight: '900', marginBottom: 0}}>{game.name}</h1>
                </div>

                {
                  game &&
                  game.totalViewers &&
                  <div>
                    <div className="game-header-meta-items">
                      <div className="game-header-meta-item">
                        <p className="">
                          <strong>{Globals.convertNumberFormat(game.totalViewers)}</strong>
                          Viewers
                        </p>
                      </div>
                      <div className="game-header-meta-item">
                        <p className="game-header-meta-dot">·</p>
                      </div>
                      <div className="game-header-meta-item">
                        <p className="">
                          <strong>{Globals.convertNumberFormat(game.channels)}</strong>
                          Channels
                        </p>
                      </div>
                      <div className="game-header-meta-item">
                        <p className="game-header-meta-dot">·</p>
                      </div>
                      <div className="game-header-meta-item">
                        <p className="">
                          <strong>#{Globals.convertNumberFormat(game.ranking)}</strong>
                        </p>
                      </div>
                      {
                        campaign.categories &&
                        campaign.categories.length > 0 &&
                        <div className="game-header-meta-item">
                          <p className="game-header-meta-dot">·</p>
                        </div>
                      }

                      <div className="game-header-meta-item">
                        {
                          campaign.categories &&
                          campaign.categories.split(',').map((c) => {
                            return (
                              <span className="brand-tag">{c}</span>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                }

                {
                  (!game ||
                  (game &&
                  !game.totalViewers)) &&
                  <div className="game-header-meta-item">
                    <p className="">
                      <strong>Game not launched yet.</strong>
                    </p>
                  </div>
                }

                <div className="row">
                  <div className="col-md-3">
                    <h3 style={{fontFamily: 'Barlow', fontWeight: 900, margin: '0', fontSize: '32px', padding: 0}}>${campaign.proposedOffer ? campaign.proposedOffer : (content.proposedOffer ? content.proposedOffer : '--')}</h3>
                  </div>
                </div>

              </div>
            </div>
          </div>*/}
        </div>
        <div className="slide-out-header">
          <div className="restaurant-main-img" style={{backgroundImage: `url(${imageUrl})`}}></div>
          <div className="row row0" style={{paddingTop: '55px'}}>
            <h3>{offer.bountyTitle}</h3>
            <p className="sub-text" style={{margin: 0}}>{offer.offerCTA}</p>
            <p className="sub-text"><a href={offer.offerWebsiteUrl} target="_blank">{offer.offerWebsiteUrl}</a></p>
            {
              bountyCategoryTags.map((c) => {
                return <div className="bounty-tile-category" style={{float: 'initial'}}>{c}</div>
              })
            }
          </div>
          {/*<div className="restaurant-ratings">
            <div className="restaurant-stars-container">
              <div className="restaurant-stars">
                <img src={stars} />
              </div>
            </div>
            <span className="sub-text ratings-text">{parseInt(restaurant.review_count)} reviews</span>
          </div>*/}
          {/*<div className="sub-text restaurant-sub">
            <span className="restaurant-price">{restaurant.price ? restaurant.price : '$'}</span>
            <span className="restaurant-dot">•</span>
            <span className="restaurant-categories">{restaurant.categories ? restaurant.categories : ''}</span>
            <span className="restaurant-dot">•</span>
            <span className="restaurant-city">{restaurant.city}</span>
          </div>*/}
        </div>
      </>
    )
  }
}
