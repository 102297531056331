import React from 'react';
import BlinkingDot from './blinking-dot.jsx';
import {LocationSearchInput} from "./LocationSearch";
// import { AccountAddressForm } from './account-address-form';

export default class AccountAddresses extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      updateSettingsBtn: 'Save',
      updateShippingBtn: 'Save',
      shippingFirstName: (props && props.firstName) || '',
      shippingLastName: (props && props.lastName) ||'',
      address1: (props && props.address1) || '',
      address2: (props && props.address2) || '',
      city: (props && props.city) || '',
      province: (props && props.province) || '',
      zip: (props && props.zip) || '',
      countryCode: (props && props.countryCode) || '',
      addresses: []
    }
  }

  componentDidMount(){
    this.getAddresses()
  }

  onChangeInput(field, e){
    console.log('updating field', field, e);
    if (typeof e == 'string'){ this.setState({ [field]: e }) }
    else { this.setState({ [field]: e.target.value }) }
  }

  getAddresses(){ this._getAddresses().done((addresses) => { this.setState({ addresses }) }) }
  _getAddresses(){ return $.ajax({ method: 'GET', url: '/api/Address/get', data: { } }) }

  toggleAddAddress(open, type){
    var obj = {
      shippingFirstName: '',
      shippingLastName: '',
      address1: '',
      address2: '',
      city: '',
      province: '',
      zip: '',
      countryCode: ''
    }
    if (!open){ obj.addAddress = false; obj.editAddress = false; }
    else if (type == 'addAddress'){ obj.addAddress = true; obj.editAddress = false; }
    else if (type == 'editAddress'){ obj.addAddress = false; obj.editAddress = true; }
    this.setState(obj)
  }

  editAddress(address){
    address.editAddress = true;
    address.addressId = address.id;
    address.shippingFirstName = address.firstName;
    address.shippingLastName = address.lastName;
    delete address.id;
    delete address.firstName;
    delete address.lastName;
    this.setState(address);
  }

  createAddress(){
    this._createAddress().done((response) => {
      this.getAddresses();
      this.props.refreshUser()
      setTimeout(() => { this.toggleAddAddress(false) }, 250)
    })
  }

  _createAddress(){
    return $.ajax({
      method: 'POST',
      url: '/api/Address/create',
      data: {
        firstName: this.state.shippingFirstName,
        lastName: this.state.shippingLastName,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        province: this.state.province,
        zip: this.state.zip,
        countryCode: this.state.countryCode,
      }
    })
  }

  onSubmit() {
    if (this.state.editAddress) { this.updateAddress(); }
    if (this.state.addAddress) { this.createAddress(); }
  }

  updateAddress(){
    this._updateAddress().done((response) => {
      setTimeout(() => { this.getAddresses(); this.toggleAddAddress(false); }, 250)
    })
  }

  _updateAddress(){
    return $.ajax({
      method: 'POST',
      url: '/api/Address/update',
      data: {
        id: this.state.addressId,
        firstName: this.state.shippingFirstName,
        lastName: this.state.shippingLastName,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        province: this.state.province,
        zip: this.state.zip,
        countryCode: this.state.countryCode,
      }
    })
  }

  setPrimaryAddress(address){
    this._setPrimaryAddress(address.id).done((response) => {
      this.getAddresses()
      setTimeout(() => { this.toggleAddAddress(false) }, 25)
    })
  }

  _setPrimaryAddress(id){ return $.ajax({ method: 'POST', url: '/api/Address/setPrimary', data: { id: id } }) }

  removeAddress(address){
    this._removeAddress(address.id).done((response) => {
      this.getAddresses()
      setTimeout(() => { this.toggleAddAddress(false) }, 25)
    })
  }

  _removeAddress(id){ return $.ajax({ method: 'POST', url: '/api/Address/remove', data: { id: id } }) }

  render() {

    var user = this.props.user;

    return (
      <div id="addresses">
        <div className="row">
          {user && (
            <div className="col-xs-6">
              <h3
                style={{
                  margin: '20px 0 32px 0',
                  padding: 0,
                  fontSize: '20px',
                  textTransform: 'initial',
                }}
              >
                <span>My Shipping Addresses</span>
                {!user.connectedAddress && <BlinkingDot />}
              </h3>
            </div>
          )}
          <div className="col-xs-6">
            <button
              className="btn-action btn-primary"
              onClick={this.toggleAddAddress.bind(this, true, 'addAddress')}
              style={{ float: 'right', marginTop: '13px' }}
            >
              + Add Address
            </button>
          </div>
        </div>

        <div style={{ position: 'relative', minHeight: '192px' }}>
          {this.state.addresses.length === 0 &&
            !(this.state.addAddress || this.state.editAddress) && (
              <div>
                <div className="results-empty-message">
                  <h3>No shipping addresses.</h3>
                  <p className="sub-text">
                    Brands cannot send you free products until you add a
                    shipping address.
                  </p>
                </div>
              </div>
            )}
          {(this.state.addAddress || this.state.editAddress) && (
            <div className="row row0">
              <div className="module">
                {this.state.addAddress && <h3>Add Address</h3>}
                {this.state.editAddress && <h3>Edit Address</h3>}
                {/*<AccountAddressForm*/}
                {/*  address={this.state.addresses[0]}*/}
                {/*  addAddress={this.state.addAddress}*/}
                {/*  editAddress={this.state.editAddress}*/}
                {/*  toggleAddAddress={(open) => this.toggleAddAddress(open)}*/}
                {/*  createAddress={(form) => this.createAddress(form)}*/}
                {/*  updateAddress={(form) => this.updateAddress(form)}/>*/}

                <LocationSearchInput
                  onLocationSelect={(address) => {
                    this.setState({
                      ...this.state,
                      ...{
                        shippingFirstName:
                          this.state.shippingFirstName ||
                          (this.props.user && this.props.user.firstName) ||
                          '',
                        shippingLastName:
                          this.state.shippingLastName ||
                          (this.props.user && this.props.user.lastName) ||
                          '',
                        address1: address.address1,
                        city: address.city,
                        province: address.province,
                        zip: address.zip,
                        countryCode: address.countryCode,
                      },
                    });
                  }}
                ></LocationSearchInput>

                <form
                  className="row"
                  onSubmit={(event) => {
                    this.onSubmit();
                    event.preventDefault();
                  }}
                >
                  <div className="col-md-6">
                    <h5 className="sub-text">First Name</h5>
                    <input
                      value={this.state.shippingFirstName}
                      name="first-name"
                      onChange={this.onChangeInput.bind(
                        this,
                        'shippingFirstName'
                      )}
                      className="dark-input"
                    />
                    <h5 className="sub-text">City</h5>
                    <input
                      value={this.state.city}
                      name="city"
                      onChange={this.onChangeInput.bind(this, 'city')}
                      className="dark-input"
                      required
                    />

                    <h5 className="sub-text">Street</h5>
                    <input
                      value={this.state.address1}
                      name="address-line1"
                      onChange={this.onChangeInput.bind(this, 'address1')}
                      className="dark-input"
                      required
                    />
                    <h5 className="sub-text">Apt/Suite</h5>
                    <input
                      value={this.state.address2}
                      name="address-line2"
                      onChange={this.onChangeInput.bind(this, 'address2')}
                      className="dark-input"
                    />
                  </div>
                  <div className="col-md-6">
                    <h5 className="sub-text">Last Name</h5>
                    <input
                      value={this.state.shippingLastName}
                      name="last-name"
                      onChange={this.onChangeInput.bind(
                        this,
                        'shippingLastName'
                      )}
                      className="dark-input"
                      required
                    />

                    <h5 className="sub-text">State</h5>
                    <input
                      value={this.state.province}
                      name="state"
                      onChange={this.onChangeInput.bind(this, 'province')}
                      className="dark-input"
                      required
                    />
                    <h5 className="sub-text">Zip</h5>
                    <input
                      value={this.state.zip}
                      name="postal-code"
                      onChange={this.onChangeInput.bind(this, 'zip')}
                      className="dark-input"
                      required
                    />
                    <h5 className="sub-text">Country Code</h5>
                    <input
                      value={this.state.countryCode}
                      name="country-code"
                      onChange={this.onChangeInput.bind(this, 'countryCode')}
                      className="dark-input"
                      required
                      minLength={2}
                      maxLength={2}
                    />
                  </div>
                  <div
                    className="row"
                    style={{ paddingLeft: '28px', paddingRight: '28px' }}
                  >
                    <div>
                      <button
                        className="btn-action btn-primary"
                        type="submit"
                        style={{ marginLeft: '12px' }}
                      >
                        Save
                      </button>
                      <button
                        className="btn-action"
                        onClick={this.toggleAddAddress.bind(this, false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          <div className="row">
            {this.state.addresses.map((a, index) => {
              return (
                <div key={index} className="col-md-6">
                  <div className="module">
                    <div className="row row0" style={{ height: '30px' }}>
                      <p>
                        <b>
                          <i>{a.primary ? 'Primary Address' : ''}</i>
                        </b>
                      </p>
                    </div>

                    <div className="row row0">
                      <p style={{ margin: 0 }}>
                        <b>
                          {a.firstName} {a.lastName}
                        </b>
                      </p>
                      <p style={{ margin: 0 }}>
                        {a.address1 + ' ' + a.address2}
                      </p>
                      <p style={{ margin: 0 }}>
                        {a.city +
                          ' ' +
                          a.province +
                          ' ' +
                          a.zip +
                          ', ' +
                          a.countryCode}
                      </p>
                    </div>
                    <div
                      className="row row0 settings-address-actions"
                      style={{ textAlign: 'right', marginTop: '25px' }}
                    >
                      {!a.primary && (
                        <div>
                          <a
                            style={{ borderRight: '1px solid #2f80ed' }}
                            onClick={this.editAddress.bind(this, a)}
                          >
                            Edit
                          </a>
                          <a
                            style={{ borderRight: '1px solid #2f80ed' }}
                            onClick={this.setPrimaryAddress.bind(this, a)}
                          >
                            Set Primary
                          </a>
                          <a onClick={this.removeAddress.bind(this, a)}>
                            Remove
                          </a>
                        </div>
                      )}
                      {a.primary && (
                        <div>
                          <a
                            style={{ borderRight: '1px solid #2f80ed' }}
                            onClick={this.editAddress.bind(this, a)}
                          >
                            Edit
                          </a>
                          <a onClick={this.removeAddress.bind(this, a)}>
                            Remove
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
