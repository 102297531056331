import { Component, memo } from 'react'
// import Header from './header'
// import Main from './main'
import Globals from '../../global-functions.js'
import store from "store2";
import Loader from '../loader'
import Calendar from '../calendar'
import SlideOutBrand from '../slide-out-brand'
import BrandsTable from './brands-table'
import InfluencerTable from './influencer-table'
import KeywordsTable from './keywords-table'
import DropdownBrandsFilter from './dropdown-brands-filter'
import DropdownCRM from './dropdown-crm'
import SearchFilters from './search-filters'
import AddContactPopup from './add-contact-popup'
import AddOutreachPopup from './add-outreach-popup'
import AddFiltersPopup from './add-filters-popup'
import EditBrandPopup from './edit-brand-popup'
import AddSavedFilters from './add-filters-popup'
import Icon from '../icon'
import HoverTip from '../hover-tip'
import CrmMenu from './crm-menu'
import CrmActionBtn from './crm-action-btn'
import ToggleMenuCollapse from '../toggle-menu-collapse'
import Insights from './insights'
// import AddPitchPopup from './crm/add-pitch-popup'
// import EditBrandPopup from './crm/edit-brand-popup'
import PillTag from './pill-tag';
// import 'react-tagsinput/react-tagsinput.css'
// import '../../styles/scss/trending.scss'
// import '../../styles/scss/search.scss'
// import '../../styles/scss/search-fans.scss'

var checkSearch = '', searchQueryStart, searchQueryTime
async function _getInsight(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
async function _setInsight(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

class SearchBrands extends Component {

  constructor(props){
    super(props)

    this.state = {
      loading: false,

      slideOutActive: false,
      expandedInfluencer: false,
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateStr: `${moment().subtract(7, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`,
      // categoryFilter: 'All ',
      slideOutType: false,
      targetFilter: 'Merchant',
      view: 'brands',
      industriesFilter: store('crm_industriesFilter') ? store('crm_industriesFilter') : 'All',
      industriesFilterNum: store('crm_industriesFilterNum') ? store('crm_industriesFilterNum') : 0,
      industries: [],
      countries: [],
      languages: [],
      outreachLists: [],
      minFollowers: 0,
      maxFollowers: 0,
      minAds: 0,
      maxAds: 0,
      minMentions: 0,
      maxMentions: 0,
      country: false,
      selectedEntity: store('crm_selectedEntity') ? store('crm_selectedEntity') : 'brands',
      activeFilters: {}
    }
  }

  componentDidMount(){
    // store('brandListSortFilter', false)
    // this.getBrands()
    // this.getWatchlists()
    // this.getIndustries()
    // this.getLanguages()
    // this.getKeywords()
    // this.getBrandActionOptions()
  }

  async getInsight(query, data){ var { id, username, domain, startDate, endDate, platformFilter } = this.state; return _getInsight(query, Object.assign({ id, username, domain, startDate, endDate, platformFilter }, data ? data : {}) ) }

  async startBrandSearch(filtersActive){
    console.log('resetting state search brands', filtersActive)
    this.setState({ lastSearchTime: +new Date(), filtersActive, loading: true })
  }

  async finishBrandSearch(){ this.setState({ loading: false }) }

  // async markAccountType(username, accountType, module){
  //   var res = await _setInsight('setAccountType', { username, accountType })
  //   var users = this.state[module]
  //   users.forEach((u) => { if (u.username == username) { u.accountType = accountType } else if (u.account && u.account.username == username) { u.account.accountType = accountType } })
  //   this.setState({ [`${module}`]: users })
  // }

  updateCalendar(_this, startDate, endDate, dateStr){ _this.setState({ startDate, endDate, dateStr }) }
  toggleCollapse(){ this.setState({ collapsed: !this.state.collapsed }) }

  chooseSort(sort) {
    // var direction = this.state.sortFilter.indexOf(sort) > -1 && this.state.sortFilter.indexOf("DESC") > -1 ? "ASC" : "DESC";
    // var sortFilter = sort + " " + direction;
    // this.setState({ sortFilter });
    // store('brandListSortFilter', sortFilter);
    // setTimeout(() => { this.getBrands(); }, 50);
  }

  selectedBrands(){ var { brands } = this.state; return brands ? brands.filter((b) => { if (b.selectedActive){ return b } }) : [] }

  togglePopup(type, val) {
    console.log(type, val)
    var s = { popupType: type, clickedBrand: null }
    if (type == 'contact-all' && val){
      s.brands = this.state.brands
      s.brands.forEach((b) => { if (b.meta.id == val.metaId){ b.selectedContact = val.contact } })
    }
    else if (type == 'outreach-all'){
      s.brands = this.state.brands
      s.brands.forEach((b) => { val.forEach((v) => { if (b.meta.id == v.meta.id) b.lists = v.lists }) })
    }
    else if (type == 'addSavedFilterPopup'){
      s.filtersActive = val
    }
    else if (type == 'viewBrand'){
      if (val){ s.clickedBrand = val; $('html').addClass('freeze') }
      else $('html').removeClass('freeze')
    }
    else if (type && val) {
      s.clickedBrand = val
    }
    console.log(s)
    this.setState(s);
  }

  toggleEntity(type){ store('crm_selectedEntity', type); this.setState({ selectedEntity: type }) }

  render() {
    var brandsSelectedNum = false, brandsSelected = this.selectedBrands(), _stages = []
    var { clickedBrand, selectedEntity, loading, collapsed, searchQueryTime, popupType, filtersActive, lastSearchTime } = this.state
    var { selectBrand, togglePopup, finishBrandSearch } = this
    var brandsSelectedNum = 0

    return (
      <div>
        <div className="mgmt-section" style={{marginBottom: '-150px'}}>
          <div className="mgmt-menu" style={{background: '#fff'}}>
            <SearchFilters
              _this={this}
              skinny={true}
              loading={loading}
              startBrandSearch={this.startBrandSearch.bind(this)}
              togglePopup={this.togglePopup.bind(this)}
            />
          </div>

          <div className={`mgmt-list ${collapsed ? 'collapsed' : ''}`}>
            <div className="crm-actions" style={{background: '#fafafa'}}>
              <div className="row row0" style={{marginBottom: '0'}}>
                <ToggleMenuCollapse collapsed={collapsed} toggleCollapse={this.toggleCollapse.bind(this)} _this={this} />
                <CrmMenu selectedEntity={selectedEntity} toggleEntity={this.toggleEntity.bind(this)} _this={this} />
              </div>
              <div className="row">
                <hr style={{marginTop: 0}} />
              </div>
              <div className="row row0">
                {/*<ToggleMenuCollapse collapsed={collapsed} toggleCollapse={this.toggleCollapse.bind(this)} _this={this} />*/}
                <div className="" style={{display: 'inline-block', float: 'left'}}>
                  <div className="crm-action-btn-container crm-action-btn-2" style={brandsSelectedNum > 0 ? {} : { opacity: '0.6', cursor: 'not-allowed' }}>
                    <div className="row row0">
                      <CrmActionBtn type="addOutreachPopup" icon="list-check" togglePopup={this.togglePopup.bind(this)} _this={this} />
                      <CrmActionBtn type="addContactPopup" icon="user-add" togglePopup={this.togglePopup.bind(this)} _this={this} />
                      {/*<CrmActionBtn type="editTemplatesPopup" icon="edit" togglePopup={this.togglePopup.bind(this)} _this={this} />*/}
                      {/*<CrmActionBtn type="addOutreachPopup" icon="menu-dots" togglePopup={this.togglePopup.bind(this)} _this={this} />*/}
                    </div>
                  </div>
                </div>
                { searchQueryTime && <p style={{display: 'inline-block', margin: '6px 0 0 20px'}}><Icon iconPack={'time-fast'} style={{margin: '-2px 3px 0 0'}} defaultColor={true} /> <span><b>{(searchQueryTime / 1000).toFixed(2)}</b> secs</span></p> }
                {/*<div className="" style={{display: 'inline-block', float: 'right'}}>
                  <div className="crm-action-btn-container" style={{width: '125px'}}>
                    <div className="row row0">
                      <div className="crm-action-btn" onClick={this.togglePopup.bind(this, 'editTemplatesPopup')} style={{position: 'relative', padding: '5px 5px 5px 31px', width: '110px', textAlign: 'left', borderRadius: '3px'}}>
                        <div className="row row0">
                          <Icon iconPack={'interlining'} style={{margin: 'auto 0', left: '9px'}} preventColor={true} />
                          <span style={{color: '#5d6a7e'}}>Relevance</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>

            <div className="content-library" style={{padding: '0'}}>
              <div className="row row0 trending-lists" style={{margin: 0}}>
                <div className="row row0">
                  <div className="trending-list-container" style={{padding: 0}}>
                    <ul className="trending-list" style={{minHeight: '500px', maxHeight: 'calc(100vh - 113px)', padding: 0, margin: 0, boxShadow: 'none', borderRadius: 0}}>
                      { selectedEntity == 'brands' && <BrandsTable _this={this} filtersActive={filtersActive} lastSearchTime={lastSearchTime} finishBrandSearch={finishBrandSearch.bind(this)} togglePopup={togglePopup.bind(this)} selectedEntity={selectedEntity} /> }
                      {/*{ selectedEntity == 'influencers' && <InfluencerTable _this={this} influencers={influencers} loading={loading} selectBrand={selectBrand.bind(this)} togglePopup={togglePopup.bind(this)} /> }
                      { selectedEntity == 'lists' && <InfluencerTable _this={this} influencers={influencers} loading={loading} selectBrand={selectBrand.bind(this)} togglePopup={togglePopup.bind(this)} /> }
                      { selectedEntity == 'saved' && <InfluencerTable _this={this} influencers={influencers} loading={loading} selectBrand={selectBrand.bind(this)} togglePopup={togglePopup.bind(this)} /> }
                      { selectedEntity == 'insights' && <Insights _this={this} />  }*/}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          { popupType == 'addContactPopup' && <AddContactPopup _this={this} active={true} brands={clickedBrand ? [clickedBrand] : brandsSelected} togglePopup={togglePopup.bind(this)} /> }
          { popupType == 'addOutreachPopup' && <AddOutreachPopup _this={this} active={true} brands={clickedBrand ? [clickedBrand] : brandsSelected} togglePopup={togglePopup.bind(this)} /> }
          { popupType == 'editBrandPopup' && <EditBrandPopup _this={this} active={true} brands={clickedBrand ? [clickedBrand] : brandsSelected} togglePopup={togglePopup.bind(this)} /> }
          { popupType == 'addSavedFilterPopup' && <AddFiltersPopup _this={this} active={true} filtersActive={filtersActive} togglePopup={togglePopup.bind(this)} /> }

          <SlideOutBrand
            _this={this}
            active={popupType == 'viewBrand'}
            brand={clickedBrand}
            closeSlideOut={togglePopup.bind(this)}
          />
        </div>

      </div>
    )
  }
}

export default memo(SearchBrands);
