import React from 'react';

export default class WaitingList extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    return (
      <div>
        <h3>Weave is currently in a private, closed beta and is invite only. We'll reach out to you in the coming weeks as we open up our platform and list more bounty sponsorships in our marketplace.</h3>
      </div>
    )
  }
}
