import React from 'react';
import Cookies from 'js-cookie';

export default class OnboardYTApproved extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    this.startAnimation()
    // this._getUser().done((response) => {
    //
    // })
  }

  startAnimation(){
    setTimeout(() => {
      $('.onboard-youtube-prof-card').addClass('loaded')
      setTimeout(() => {
        $('.onboard-youtube-prof-card').addClass('loaded1')
        setTimeout(() => {
          $('.loading-status-text-0').addClass('completed')
          $('.loading-status-text-1').addClass('active')
          setTimeout(() => {
            $('.loading-status-text-1').addClass('completed')
            $('.loading-status-text-2').addClass('active')
            setTimeout(() => {
              $('.loading-status-text-2').addClass('completed')
              $('.loading-status-text-3').addClass('active')
              setTimeout(() => {
                $('.loading-status-text-3').addClass('completed')
                setTimeout(() => {
                  $('.onboard-youtube-prof-card').addClass('loaded2')
                  setTimeout(() => {
                    if (Cookies.get('onboarding-finished') === 'true'){
                      Cookies.remove('onboarding')
                      Cookies.remove('onboarding-status')
                      Cookies.remove('onboarding-finished')
                      location.replace('/dashboard')
                    }
                    else {
                      Cookies.set('onboarding', true)
                      Cookies.set('onboarding-status', '/onboard-email')
                      location.replace('/onboard-email')
                    }
                  }, 500)
                }, 500)
              }, 1500)
            }, 1500)
          }, 1500)
        }, 500)
      }, 250)
    }, 100)
  }

  // _getUser(){
  //   return $.ajax({
  //     method: 'GET',
  //     url: '/api/User/getNeo',
  //     data: { }
  //   })
  // }

  render() {

    var user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {}
    var twitchUser = Cookies.get('twitchdata') ? JSON.parse(Cookies.get('twitchdata')) : {}
    var mixerUser = Cookies.get('mixerdata') ? JSON.parse(Cookies.get('mixerdata')) : {}
    // var profpic = user.thumbnails && user.thumbnails.medium ? user.thumbnails.medium.url : ''
    // var title = user.channelTitle
    // var imageUrl = user.imageUrl
    var profpic = ''
    var title = ''
    var bannerColorGradient = 'linear-gradient(119deg, rgb(103, 7, 7), rgb(216, 20, 28))'

    if (twitchUser){
      profpic = twitchUser.imageUrl
      title = twitchUser.username
      bannerColorGradient = `linear-gradient(119deg, ${twitchUser.LightMuted}, ${twitchUser.Vibrant})`
    }
    else if (mixerUser){
      profpic = mixerUser.imageUrl
      title = mixerUser.username
      bannerColorGradient = `linear-gradient(119deg, ${mixerUser.LightMuted}, ${mixerUser.Vibrant})`
    }

    else {

    }

    return (
      <div>
        <div className="onboard-page" style={{backgroundImage: `${bannerColorGradient}`}}>
          <div className="onboard-container interact-section" style={{overflow: 'initial'}}>
            <div className="onboard-youtube-prof-card">
              <div className="onboard-banner" style={{backgroundImage: `${bannerColorGradient}`}}></div>
              <div className="onboard-bottom">
                <div className="onboard-prof-pic" style={{backgroundImage: `url(${profpic})`}}></div>
                <span className="onboard-channel">{title}</span>
                {/*<span className="onboard-channel-approved">
                  <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/icons/emailverified-icon.svg" />
                  Verified! Syncing...
                </span>*/}
                <span className="onboard-channel-approved">
                  <div>
                    <p className="loading-status-text loading-status-text-0 active">Verified account! Syncing...</p>
                    <p className="loading-status-text loading-status-text-1">Setting up your account...</p>
                    <p className="loading-status-text loading-status-text-2">Curating brand bounties...</p>
                    <p className="loading-status-text loading-status-text-3">Finalizing your account...</p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
