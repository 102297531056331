import React from 'react';
import Cookies from 'js-cookie';
import Globals from '../../global-functions.js'

export default class OnboardYTSignedIn extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    // Globals.logEvent('event', 'youtubeRedirect')
    var id_token = Globals.gp('code')
    $.ajax({ method: 'POST', url: '/api/YouTube/login', data: {token: id_token, userid: Cookies.get('access_token')} })
    .done((data) => {

      if (data.success) {
        // location.replace('/waiting-list')
        // Globals.logEvent('event', 'youtubeSignupSuccess')
        var user = data.user
        var redirect = Cookies.get('redirect_google')
        Cookies.remove('redirect_google')

        Cookies.set('access_token', user.access_token)
        Cookies.set('userid', user.userid)
        Cookies.set('userdata', user)

        if (!user.email && user.youtubeConnected) {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-yt-approved')
          location.replace('/onboard-yt-approved')
        }
        else if (redirect == 'finish_yt_connected') {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-yt-approved')
          Cookies.set('onboarding-finished', true)
          location.replace('/onboard-yt-approved')
        }
        else if (!user.email) {
          Cookies.set('onboarding', true)
          Cookies.set('onboarding-status', '/onboard-email')
          location.replace('/onboard-email')
        }
        else if ( redirect && redirect.indexOf('onboard') > -1 ){
          Cookies.remove('redirect_google')
          location.href = redirect
        }
        else if (redirect && redirect.indexOf('/links') > -1){
          Cookies.remove('redirect_google')
          location.replace(redirect)
        }
        else {
          Cookies.remove('onboarding')
          Cookies.remove('onboarding-status')

          Cookies.set('userid', user.userid)
          Cookies.set('userdata', JSON.stringify(user))
          console.log('should redirect')
          console.log(user, redirect)
          console.log('******')
          console.log( Cookies.get('userdata') )
          location.replace('/dashboard')
        }
      }
      else {
        // Globals.logEvent('event', 'youtubeSignupFailure')
        confirm(data.message);
        location.replace('/')
      }

    });

  }

  render() {

    // let user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {}
    // let profpic = user.thumbnails ? user.thumbnails.medium.url : ''

    return (
      <div>
        {/*<div className="onboard-page">*/}
          {/*<div className="onboard-container interact-section" style={{overflow: 'initial'}}>*/}
            {/*<div className="onboard-youtube-prof-card">*/}
              {/*<div className="onboard-banner" style={{backgroundImage: `url(${user.imageUrl})`}}></div>*/}
              {/*<div className="onboard-bottom">*/}
                {/*<div className="onboard-prof-pic" style={{backgroundImage: `url(${profpic})`}}></div>*/}
                {/*<span className="onboard-channel">{user.channelTitle}</span>*/}
                {/*<span className="onboard-channel-approved"><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/icons/emailverified-icon.svg" />Approved! Redirecting...</span>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
      </div>
    )
  }
}
