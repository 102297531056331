import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'

const FilterListDropdown = ((props) => {
  var { title, index, filter, type, changeFilter, staticList, _this } = props

  return (
    <>
      <div className="filter-option-container">
        <div className="row row0">
          <label>{title}</label>
        </div>
        <div className="row row0">
          <SearchDropdown changeFilter={changeFilter} staticList={staticList} type={type} index={index} _this={_this} />
        </div>
        <div className="row row0">
          { filter[index] && filter[index].length > 0 && filter[index].map((l) => {
            return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={index} deleteTag={changeFilter} /> )
          }) }
        </div>
      </div>
    </>
  )
})

export default memo(FilterListDropdown);
