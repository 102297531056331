import React from 'react';

export default class SnapchatTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Snapchat</h4>
        <p>Using Weave for Snapchat gives your viewers an easy way to swipe up and see all the products or stores that you’re featuring. Here’s some helpful tips for posting on Snap:</p>
        <ol>
          <li>using Weave for Snap stories allows viewers to swipe up and go directly to the store as they’re watching your story</li>
          <li>you can easily build one link or multiple links for your Snap story and include one link per story post</li>
          <li>Links perform best when you include text in your story that says “swipe up to shop” or “swipe up to see more”</li>
        </ol>
      </div>
    )
  }
}
