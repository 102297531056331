import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import InvoicePayments from './invoice-payments.jsx'

export default class InvoiceOverview extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    function formatAmount(amount){ return (<span><span className="sub-text invoice-dollar">$</span>{Globals.numberWithCommas(amount)}</span>) }
    var { deliverables, amount, notes } = this.props

    return (
      <div className="invoice-padding invoice-padding-top">
        <div className="row row0">
          <div className="invoice-items">
            <div className="invoice-items-header sub-text">
              <div className="row">
                <div className="col-xs-9">
                  <label className="sub-text">Deliverable</label>
                </div>
                <div className="col-xs-3" style={{textAlign: 'right'}}>
                  <label className="sub-text">Quantity</label>
                </div>
              </div>

            </div>
            { deliverables && deliverables.length > 0 && deliverables.map((i) => {
              return (
                <div className="invoice-item">
                  <div className="row">
                    <div className="col-xs-9 invoice-item-desc">
                      <div className="invoice-item-desc-platform-container">
                      <div className="invoice-item-desc-platform">
                        <div className="invoice-item-desc-icon" style={{backgroundImage: `url(${Globals.icon(i.type)})`}}></div>
                      </div>
                      </div>
                      <div className="invoice-item-desc-text">
                        <span><i>{i.description}</i></span>
                      </div>
                    </div>
                    <div className="col-xs-3 invoice-item-amount">
                      {i.quantity}
                    </div>
                  </div>
                </div>
              )
            }) }
          </div>
          <hr />
          <div className="row row0">
            <div className="invoice-notes">
              <label className="sub-text">Notes</label>
              <p className="sub-text invoice-notes-text">{notes || 'No notes added.'}</p>
            </div>
            <div className="invoice-total-sum">
              <label className="sub-text">Total Amount</label>
              <p className="invoice-total-sum-num">{formatAmount(amount)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
