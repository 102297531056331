import React from 'react';
import Globals from '../../global-functions.js';

export default class ShippingTrackingStage extends React.Component {

  render() {
    var { progress, icon, iconPack } = this.props
    if (progress == 'finished' || progress == 'visited') { icon = 'ok' }

    return (
      <div className={`shipping-section shipping-stage ${progress}`}>
        <div className="shipping-stage-inner">
          <div className="shipping-stage-dot-accent"></div>
          <div className="shipping-stage-dot"></div>
          { (progress != 'pending' && progress != 'inactive') && <img src={icon ? Globals.icon(icon) : iconPack ? Globals.iconPack(icon) : Globals.iconPack('map-marker')} style={{filter: `${Globals.hexToFilter('#ffffff')}`}} /> }
        </div>
      </div>
    )
  }
}
