import React from 'react'
import Globals from '../global-functions.js'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Icon extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    let { type, icon, iconPack, imageUrl, className, color, noColor, preventColor, width, height, style, defaultColor, defaultActiveColor, activeColor, verifiedColor, warningColor, errorColor, colorType, opacity, country } = this.props
    style = style ? { ...style } : {}; var blackOrWhite = false; width = width || '14px'; className=className||''
    if (defaultColor||colorType=='default') color = '#909fb1'; else if (defaultActiveColor||colorType=='defaultActive') color = '#5d6a7e'; else if (activeColor||colorType=='active') color = '#1991EB'; else if (verifiedColor||colorType=='verified') color = '#07c897'; else if (warningColor||colorType=='warning') color = '#ff8e37'; else if (errorColor||colorType=='error') color = '#ec4e2e';
    if (!color && !preventColor) { color = Globals.color; blackOrWhite = Globals.themeMode == 'dark' ? 'white' : 'black' }
    if (opacity){ style.opacity = opacity }
    if (color && !noColor && !preventColor) { style.filter = Globals.hexToFilter(color) }
    if (width) { style.width = width.indexOf('px') > -1 || width.indexOf('%') > -1 || width.indexOf('vw') > -1 ? width : width + 'px' }
    if (height) { style.height = height.indexOf('px') > -1 || height.indexOf('%') > -1 || height.indexOf('vh') > -1 ? height : height + 'px' }

    if (type == 'iconPack' || iconPack){ imageUrl = Globals.iconPack(iconPack || icon) }
    else if (type == 'icon' || icon){ imageUrl = Globals.icon(icon, blackOrWhite) }
    else if (country){ imageUrl = `https://weave-img.s3-us-west-2.amazonaws.com/countries/${country.toLowerCase()}.svg` }
    // var html = ( <img className={className} src={imageUrl} style={style} loading="lazy" /> )
    var html = ( <LazyLoadImage key={imageUrl} className={className} src={imageUrl} style={style} /> )
    var iconCheck = icon || iconPack
    if (iconCheck == 'ampersand' || iconCheck == '@'){
      color = color == '#909fb1' ? '#98aabb' : color; style.color = color; delete style.width; delete style.filter; style.fontFamily = 'Roboto'; style.fontWeight = 500
      html = ( <a className={`icon-default icon-ampersand ${className}`} style={style}>@</a> )
    }

    // html = (<></>)
    return ( <>{html}</> )
  }
}
