import React from 'react';
import OnboardStatus from './onboard-status.jsx';
import Cookies from 'js-cookie';

export default class OnboardConnect extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    // let social = this.gp('social')
    // if (social == 'youtube'){
    //   Cookies.remove('onboarding')
    //   Cookies.remove('onboarding-status')
    //   location.href = '/dashboard'
    // }
  }

  gp(n){
    let m = RegExp('[?&]' + n + '=([^&]*)').exec(window.location.search)
    return m && decodeURIComponent(m[1].replace(/\+/g, ' '));
  }

  connectYouTube(e){
    e.preventDefault()

    $.ajax({
      method: 'POST',
      url: '/api/YouTube/googleUrl',
      data: {}
    }).done(function(data){ Cookies.set('redirect_google', 'finish_yt_connected'); location.href = data })
  }

  skip(){
    Cookies.remove('onboarding')
    Cookies.remove('onboarding-status')
    location.href = '/dashboard'
  }

  render() {
    return (
      <div>
        <div className="onboard-page login-box">
          <div className="onboard-container interact-section">
            <h3>Let's connect YouTube to get started.</h3>
            <div className="set-email-container">
              <OnboardStatus status="90%" />
              <p style={{maxWidth: '600px'}}>
                *please note that YouTube will ask for permissions for your account. Weave integrates directly with social platforms to allow you to easily create links and post directly to your content. This permission does not give us any control over your accounts or allow us to post on your behalf, but it is required in order for you to build links for these platforms.
              </p>
              <a className="social-button" onClick={this.connectYouTube.bind(this)} style={{maxWidth: '300px', marginTop: '50px'}}>
                <span className="social-button-icon"></span>
                <span className="social-button-text">CONNECT WITH YOUTUBE</span>
              </a>
              <a className="social-button" onClick={this.skip.bind(this)} style={{maxWidth: '300px', background: 'black', marginTop: '15px'}}>
                <span className="social-button-text" style={{top: 0}}>SKIP FOR NOW</span>
              </a>
              <a className="social-button" style={{maxWidth: '300px', background: 'purple', marginTop: '15px'}} href="https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=vomoslvnl8stwtg9wzzmlmb99876pb&redirect_uri=http://localhost/redirect/twitch&scope=analytics:read:games+bits:read+channel:read:subscriptions+user:read:broadcast+user:read:email&state=c3ab8aa609ea11e793ae92361f002671">
                <span className="social-button-text" style={{top: 0}}>CONNECT WITH TWITCH</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
