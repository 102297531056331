import React from 'react';

export default class SlideOutHeaderProduct extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var campaign = this.props.campaign
    var offer = this.props.offer
    var brandColors = {}
    var bountyCategoryTags = offer && offer.bountyCategoryTags ? offer.bountyCategoryTags : []

    return (
      <div>
        <>
          <div className={`game-header ${this.props.scrollingDown ? 'minimize' : ''}`} style={{background: `rgba(0,0,0,0.32)`}}>
            <div className="game-header-banner" style={{backgroundImage: `url(${offer.bannerImageUrl ? offer.bannerImageUrl : offer.imageUrl})`}}>
              <div className="game-header-banner-darken"></div>
            </div>
          </div>
          <div className="slide-out-header">
            <div className="restaurant-main-img" style={{backgroundImage: `url(${offer.imageUrl})`, top: '-68px'}}></div>
            <div className="row row0" style={{paddingTop: '75px'}}>
              <h3>{offer.bountyTitle}</h3>
              <p className="sub-text" style={{margin: 0}}>{offer.offerCTA}</p>
              <p className="sub-text"><a href={offer.offerWebsiteUrl} target="_blank">{offer.offerWebsiteUrl}</a></p>
              {
                bountyCategoryTags && bountyCategoryTags.length > 0 &&
                <div className="row row0" style={{margin: '15px 0'}}>
                  {
                    bountyCategoryTags.map((c) => {
                      return <div className="bounty-tile-category" style={{float: 'initial'}}>{c}</div>
                    })
                  }
                </div>
              }
            </div>
          </div>
        </>
      </div>
    )
  }
}
