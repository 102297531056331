import { createReducer } from 'typesafe-actions';
import { authActionCreators, AuthActions } from '../actions/auth.actions';

type LoginState = {
  authenticated: boolean;
  loading: boolean;
  hasError: boolean;
};

const initialState: LoginState = Object.freeze({
  authenticated: false,
  hasError: false,
  loading: false
});

export const authReducer = createReducer<LoginState, AuthActions>(
  initialState,
).handleAction(authActionCreators.resetLoginAction, () => ({...initialState}))
  .handleAction(authActionCreators.createLoginAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    authActionCreators.createLoginSuccessAction,
    (state, action) => ({
      ...initialState,
      authenticated: true
    }),
  )
  .handleAction(authActionCreators.createLoginFailAction, (state, action) => ({
    ...initialState,
    hasError: true
  }));

