import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
var CLIENT_ID
// 'AVFfpKvBnbK2ZbIMO0Pc0zJ-TLClITx51Jhr1hRfpnpqyK5jEzlq87gvYlBFWcUDVcJevVFUNqx72ASw'
if (Globals.isDev) { CLIENT_ID = 'AfImsAuHDUmlHnKglyHUw0UoL-71wnNdotMWBWr4saaaIJ3Ve8V6duQKcw1qtwbMS87PSTi1UH870uo_' }
if (Globals.isProd) { CLIENT_ID = 'AfImsAuHDUmlHnKglyHUw0UoL-71wnNdotMWBWr4saaaIJ3Ve8V6duQKcw1qtwbMS87PSTi1UH870uo_' }

export default class InvoicePayPal extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  openPayPalMe(){
    var { invoice } = this.props
    var url = `http://www.paypal.me/weavesocial/${invoice.amount}`
    window.open(url,'popup','width=600,height=600')
  }

  approvePayment(details){
    var { invoice } = this.props
    console.log(details)
  }

  render() {
    var { invoice } = this.props
    var { id, name, amount, invoiceOwner } = invoice
    var paypalDesc = `Invoice #${id} -- ${name} paying $${Globals.numberWithCommas(amount)} to ${invoiceOwner}`
    var paypalCheckoutFees = Math.ceil(((Number(amount) * .0349) + 0.30) * 100)/100, paypalCheckoutTotal = Number(amount) + paypalCheckoutFees

    return (
      <div className="invoice-payment invoice-paypal">
        <div className="row row0">

        </div>
        <div>
          <div className="row row0">
            <h4>PayPal.Me</h4>
            <p className="sub-text">
              Paying through PayPal.Me allows payments to be sent with no fees. Please select the <b>Sending to a friend</b> to avoid any fees.
            </p>
          </div>
          <div className="row" style={{marginTop: '25px'}}>
            <div className="col-xs-6">
              <div className="module" style={{padding: '15.5px 25px', borderColor: '#23e026', marginBottom: 0}}>
                <div className="invoice-paypal-me-ind"><div className="invoice-paypal-me-valid"><IconPack icon="check" color="#fff" /></div></div>
                <img src={Globals.iconGeneral('paypal-paying-friend-simple.png')} style={{width: '100%'}} />
              </div>
            </div>
            <div className="col-xs-6">
              <div className="module" style={{borderColor: '#ff4747', marginBottom: 0}}>
                <div className="invoice-paypal-me-ind"><div className="invoice-paypal-me-invalid"><IconPack icon="cross" color="#fff" /></div></div>
                <img src={Globals.iconGeneral('paypal-paying-service-simple.png')} style={{width: '100%'}} />
              </div>
            </div>
          </div>
          <div className="row row0">
            <div className="row" style={{marginTop: '25px'}}>
              <div className="col-xs-2">
                <label>Invoice</label>
                <h4 className="sub-text">${Globals.numberWithCommas(amount)}</h4>
              </div>
              <div className="col-xs-3">
                <label>PayPal Fees</label>
                <h4 className="sub-text">${0}</h4>
              </div>
              <div className="col-xs-2">
                <label>Total</label>
                <h3 style={{margin: '4px 0 0 0'}}><b>${Globals.numberWithCommas(amount)}</b></h3>
              </div>
            </div>
          </div>

          <div className="row row0" style={{marginTop: '25px'}}>
            <label>Copy/Paste Description Into Payment</label>
            <input className="dark-input" value={paypalDesc} style={{fontWeight: 'bold', marginBottom: 0}} readOnly />
          </div>

          <div className="row row0" style={{marginTop: '25px'}}>
            <a onClick={this.openPayPalMe.bind(this)}>
              <div className="invoice-paypal-btn">
                <div className="invoice-paypal-me"></div>
              </div>
            </a>
          </div>
        </div>

        <hr />
        <div className="row row0">
          <div className="row row0">
            <div className="row row0">
              <h4>PayPal Checkout</h4>
              <p className="sub-text">
                PayPal Checkout allows you to pay directly through your PayPal account here. It does, however, assess a transaction fee of <b>3.49% + $0.30</b> towards the invoice amount.
              </p>
            </div>

            <div className="row" style={{marginTop: '25px'}}>
              <div className="col-xs-2">
                <label>Invoice</label>
                <h4 className="sub-text">${Globals.numberWithCommas(amount)}</h4>
              </div>
              <div className="col-xs-3">
                <label>PayPal Fees</label>
                <h4 className="sub-text">${paypalCheckoutFees}</h4>
              </div>
              <div className="col-xs-2">
                <label>Total</label>
                <h3 style={{margin: '4px 0 0 0'}}><b>${Globals.numberWithCommas(paypalCheckoutTotal)}</b></h3>
              </div>
            </div>
          </div>

          <div className="row row0" style={{marginTop: '25px'}}>
            <PayPalScriptProvider options={{ 'client-id': CLIENT_ID, currency: 'USD' }}>
              <PayPalButtons style={{ layout: 'horizontal', tagline: 'false' }}
                createOrder={(data, actions) => { return actions.order.create({ purchase_units: [{ amount: { value: paypalCheckoutTotal }, invoice_id: id, description: paypalDesc } ] }); }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                      const name = details.payer.name.given_name;
                      this.approvePayment(details)
                    });
                }}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </div>
    )
  }
}
