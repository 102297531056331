import React from 'react'
import Globals from '../global-functions.js'

export default class HoverTipItem extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  render(){
    var html, { title, subtext } = this.props
    html = (
      <>
        <p className="search-tip-title">{title}</p>
        <p className="search-tip-example sub-text">
          {subtext}
        </p>
      </>
    )

    return (
      <>
        {html}
      </>
    )
  }

}
