import { memo } from 'react'
import Icon from '../icon'

const SearchPagination = ((props) => {
  var { skip, limit, brandsLength, incrementPage, _this } = props

  return (
    <div className="pagination-container">
      <div className="pagination-container-outer">
        <div className="pagination-container-inner">
          <div className="crm-inline" style={{ float: 'left', padding: '3px 0 0 15px' }}>
            <button className={`crm-btn crm-btn-toggle switch-platform-span sub-text`} style={{ padding: '3px 8px 5px' }} onClick={incrementPage.bind(_this, 'previous')}>
              <Icon icon="arrow-left-1" defaultColor={true} />
            </button>
            <div className={`crm-btn crm-btn-toggle switch-platform-span sub-text`} style={{ border: 'none' }}>
              {skip} - {skip + (brandsLength || limit)}
            </div>
            <button className={`crm-btn crm-btn-toggle switch-platform-span sub-text`} style={{ padding: '3px 8px 5px' }} onClick={incrementPage.bind(_this, 'next')}>
              <Icon icon="arrow-right-1" defaultColor={true} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default memo(SearchPagination)
