import React from 'react';

export default class FacebookTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Facebook</h4>
        <p>Using Weave on Facebook gives your followers a quick way to click and purchase from your posts. Here’s some helpful tips for posting on Facebook:</p>
        <ol>
          <li>include links to images, text, and video posts if you’re featuring stores and products that you want your followers to be able to easily find</li>
          <li>for videos and images, make sure to let your viewers know that they can find and purchase from your links that you’ve created for them</li>
        </ol>
      </div>
    )
  }
}
