import { spawn } from 'typed-redux-saga';
import { authSagas } from './auth.sagas';
import { invitationSagas } from './invitation.sagas';
import { brandCampaignSagas } from './brand-campaign.sagas';
import { bountySagas } from './bounty.sagas';
import { inviteUserSagas } from './invite-user.sagas';
import { contentSagas } from './content.sagas';
import { addressSagas } from './address.sagas';

export function* rootSaga() {
  yield spawn(addressSagas);
  yield spawn(authSagas);
  yield spawn(invitationSagas);
  yield spawn(brandCampaignSagas);
  yield spawn(bountySagas);
  yield spawn(inviteUserSagas);
  yield spawn(contentSagas);
}
