import React from 'react';
import { Line, LineChart, Tooltip } from 'recharts';
import Globals from '../global-functions.js';


export default class Canvas extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      mounted: false,
      chart: false,

    }
  }

  componentDidUpdate(){
    // console.log(this.props.element)
    // console.log(this.props.labels)
    // console.log(this.props.data)
    // console.log(this.props.type)

    // var data = []
    // this.props.labels.forEach((l, i) => {
    //   data.push({
    //     name: l,
    //     earnings: parseFloat(this.props.data[i])
    //   })
    // })
    //
    // console.log(data)

    // this.renderChart(this.props.element, this.props.labels, this.props.data, this.props.type)
  }

  componentDidMount() {
    console.log('mounting!!!')
    this.setState({ mounted: true })
    // this.renderChart(this.props.element, this.props.labels, this.props.data, this.props.type)

    // var data = []
    // this.props.labels.forEach((l, i) => {
    //   data.push({
    //     name: l,
    //     value: parseFloat(this.props.data[i])
    //   })
    // })
    // console.log(data)
  }

  componentWillUnmount(){
    this.setState({ mounted: false })
  }

  renderChart(element, labels, data, type){



    //
    // console.log(element, labels, data, type)
    // if (this.state.chart){
  	// 	this.state.chart.destroy()
  	// }
    //
  	// var ctx = document.getElementById(element).getContext("2d");
    //
  	// var gradient = ctx.createLinearGradient(0, 0, 0, 400);
  	//     gradient.addColorStop(0, type == 'earnings' ? 'rgba(76, 175, 80, 0.37)' : 'rgba(0, 95, 213, 0.37)');
  	//     gradient.addColorStop(1, type == 'earnings' ? 'rgba(76, 175, 80, 0)' : 'rgba(0, 95, 213, 0)');
    //
    //
    // // var
    //
    //
    // var chart = new Chart(ctx, {
    //  type: 'line',
    //  data: {
    //   labels: labels,
    //   datasets: [
    // 		{
    // 			//  label: labelName,
    // 		   data: data,
  	//   		 backgroundColor: gradient,
  	// 			//  backgroundColor: 'rgba(0,0,0,0)',
  	// 		   borderColor: type == 'earnings' ? 'rgba(76, 175, 80, 0.6)' : 'rgba(0, 95, 213, 0.6)',
  	// 		   borderWidth: 3,
  	//   		 pointBackgroundColor: type == 'earnings' ? 'rgba(76, 175, 80, 0)' : 'rgba(0, 95, 213, 0)',
  	//   		 pointBorderColor: type == 'earnings' ? 'rgba(76, 175, 80, 0)' : 'rgba(0, 95, 213, 0)',
  	//   		 pointHoverBorderColor: type == 'earnings' ? 'rgba(76, 175, 80, 0.43)' : 'rgba(0, 95, 213, 0.43)',
  	//   		 pointHoverBackgroundColor: type == 'earnings' ? 'rgba(76, 175, 80, 0.43)' : 'rgba(0, 95, 213, 0.43)'
    // 		}
    // 	]
    // },
    // options: {
    // 	responsive: true,
    // 	maintainAspectRatio: true,
  	// 	legend: {
  	// 		display: false
  	// 	},
    //    scales: {
    //   		xAxes: [{
    //   			ticks: {
    // 					display: false
    //   				// maxTicksLimit: 0
    //   			},
    // 				gridLines: {
    // 					display: false,
    // 					drawBorder: false
    // 				},
    //   		}],
    //      yAxes: [{
    // 	     	ticks: {
    // 					display: false
    // 					// beginAtZero: true
    // 	     	},
    // 				gridLines: {
    // 					display: false,
    // 					drawBorder: false
    // 				},
    //      }]
    //   	},
    //   	tooltips: {
    //   		enabled: true
    //   	},
    //     animation: false
    //     // animation: {
    //     //   animateScale: false,
    //     //   animateRotate: false
    //     // }
    //   }
    // })
    //
    // // this.setState({
    // //   chart: chart
    // // })
  }

  render() {

    // var data = []
    var { data, total, type, size, showRange, element } = this.props
    console.log(data)

    // var colors = {
    //   green: '#4caf50',
    //   blue: '#005fd5'
    // }

    var colors = {
      green: '#7de280',
      blue: '#69a5e2'
    }

    // for (var i = 0; i <= 30; i++){
    //   data.push({
    //     name: this.props.labels[i],
    //     [this.props.type]: this.props.data[i] ? parseFloat(this.props.data[i]) : 0
    //   })
    // }

    // this.props.labels.forEach((l, i) => {
    //   data.push({
    //     name: l,
    //     value: parseFloat(this.props.data[i])
    //   })
    // })

    return (
      <div>
        <div className={`${size} canvas-container`}>
          <div className="module" style={{ minHeight: '180px' }}>
            {/*<div style={{ position: 'absolute', height: '40px', padding: '11px', background: '#15c2fe', width: '100%', top: 0, left: 0}}>
              <h2 style={{color: '#034a63', fontFamily: 'Barlow', fontWeight: 800, textTransform: 'uppercase', fontSize: '18px', margin: 0}}>{type}</h2>
            </div>*/}

            <span className="chart-label" style={{fontFamily: 'Barlow', fontWeight: '900'}}>{type}</span>
            <span className="chart-number chart-earnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}>{type == 'earnings' ? '$'+Globals.numberWithCommas(parseFloat(total).toFixed(2)) : Globals.numberWithCommas(total)}</span>
            <span className={`chart-range ${showRange ? '' : 'hidden'}`}>Last 30 days</span>
            {/*
              <canvas id={element} width="400" height="200"></canvas>
            */}
            <br />
            { data && data.length > 0 &&
              <LineChart width={300} height={90} data={data}>
                <Tooltip separator={type == 'earnings' ?  ': $' : ': '} isAnimationActive={false} animationDuration={0} formatter={(val => Globals.convertNumberFormat(val))} labelStyle={{ textAlign: 'center', display: 'none' }} wrapperStyle={{ borderWidth: 0, borderRadius: 8, backgroundColor: '#ffffff' }} cursor={{ stroke: '#29292933300', strokeWidth: 1 }} />
                <Line type='monotone' dataKey={type} stroke={type == 'earnings' ? colors['green'] : colors['blue']} strokeWidth={2} />
              </LineChart>

            }
          </div>
        </div>
      </div>
    )
  }
}
