import React from 'react'
import history from './history.js';
import EmptyMessage from './empty-message'
import RosterTable from './roster-table'

async function _getInsight(query, data){ return $.ajax({ method: 'GET', url: `/api/Roster/${query}`, data }) }
async function _setInsight(query, data){ return $.ajax({ method: 'POST', url: `/api/Roster/${query}`, data }) }

export default class Rosters extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      active: false,
      loading: true,
      rosters: []
    }
  }

  componentDidMount(){
    // store('brandListSortFilter', false)
    this.getRosters()
  }

  async getRosters(){ var res = await this.getInsight('getAll', {}); this.setState({ ...res, loading: false }); }
  async getInsight(query, data){ return _getInsight(query) }
  async createRoster(){ var res = await this._createRoster(); history.push(`/rosters/${res}`); }
  async _createRoster(){ return $.ajax({ method: 'POST', url: '/api/Roster/create', data: {  } }) }

  chooseSort(){}
  selectInfluencer(){}

  render(){
    var { rosters, loading } = this.state

    return (
      <>
        <div style={{padding: '15px 0'}}>
          <div className="row">
            <div className="col-xs-6">
              <h3 style={{margin: 0, padding: 0}}>Rosters</h3>
            </div>
            <div className="col-xs-6">
              <button className="btn-action btn-primary btn-right" onClick={this.createRoster.bind(this)}>+ Create Roster</button>
            </div>
          </div>
        </div>
        <div>
          { rosters.length > 0 && <RosterTable this={this} rosters={rosters} chooseSort={this.chooseSort} selectInfluencer={this.selectInfluencer} style={{height: '500px'}} /> }
          { rosters.length == 0 && !loading && <EmptyMessage title={'No rosters created.'} subtext={'Create a roster to manage your influencers'} align="top" />}
        </div>
      </>
    )
  }
}
