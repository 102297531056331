export const MakeFetchPost = (url: string, body?: any) => fetch(url,{ method: 'post',
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
}).then(response => response.json());

export const MakeFetchPut = (url: string, body?: any) => fetch(url,{ method: 'put',
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(body)
}).then(response => response.json())


export const MakeFetchGet = (url: string, params?: any) => fetch(
  params ? `${url}?${new URLSearchParams(params)}` : url,
  {
    method: 'get',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
}).then(response => response.json())


export const kFormatter = (num: number) =>
  Math.abs(num) > 999 ? (Math.sign(num)* Math.abs(num)/1000).toFixed(1) +
    'k' : Math.sign(num)*Math.abs(num);

import { compose } from 'redux';

export const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;


export type Nullable<T> = T | null;
export const makeBackgroundImage = (url?: string) => url? ({ backgroundImage: `url(${url})` }) : {};

export function isiOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
