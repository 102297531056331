import React from 'react';

export default class InstagramTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Instagram</h4>
        <p>Using Weave for Instagram is a great way for you to give your followers easy access to the products that you feature in posts or stories. Here’s some helpful tips for posting on Insta:</p>
        <ol>
          <li>using Weave in Insta stories is great for viewers because they can actually swipe up and go directly to the store as they’re watching</li>
          <li>for Insta posts, you can include Weave links, but your viewers will have to enter that link manually since Instagram doesn’t support clickable links in captions.</li>
          <li>use Weave links in your Insta bio if you’d like to feature a store or product!</li>
        </ol>
      </div>
    )
  }
}
