import { memo } from "react";
import moment from 'moment';
import {Link} from 'react-router-dom'
import Globals from '../../global-functions.js';
import Icon from '../icon'
import PillTag from './pill-tag'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CRMTableMainCell = ((props) => {

  // useEffect(() => {
  //
  // })

    var classes = props.classes ? props.classes : ''
    var { type, title, subtext, tileColor, badgeText, badgeIcon, badgeColor, meta, social, socials, imageUrl, initial, icon, size, style, influencer, instagram, dataObject, influencerLabel, pathUrl, linkUrl, domain, brand, clickFunc, hoverFunc, togglePopup, emailStatus, precursor, _this } = props
    style = style ? style : {}; social=social||{}; influencer = influencer||social; precursor=precursor||''
    meta=meta||{}; socials=socials||[]

    var colorPalette = {
      'pitched': 'yellow',
      'responded': '#48caf882',
      'closed': 'green',
      'archived': 'red'
    }

    var html = ''
    if (type == 'main-name'){
      style.paddingTop = '8px'
      var website, linkStyle = { display: 'inline-block', float: 'left', height: '14px', marginRight: '8px' }
      if (meta.domain) website = `https://${meta.domain}`
      else if (social.website) website = social.website
      html = (
        <>
          <div className="crm-username-title">
            {/*<a style={{color: '#1991EB', fontWeight: '500'}} onClick={props.triggerSlideOut.bind(props.this, influencer, influencerLabel)}>{title}</a>*/}
            <a className="crm-username-title-text" title={title} onClick={togglePopup.bind(_this, 'viewBrand', brand)}>{title}</a>
          </div>
          <div>
            { website &&
              <a key={Math.floor(Math.random() * 99999999)} title={meta.domain} href={`${website}`} target="_blank">
                <Icon icon="link" style={linkStyle} defaultColor={true} />
              </a>
            }
            { instagram && instagram.username &&
              <a key={Math.floor(Math.random() * 99999999)} title={`@${instagram.username}`} href={`https://www.instagram.com/${instagram.username}`} target="_blank">
                <Icon icon="instagram" style={linkStyle} defaultColor={true} />
              </a>
            }
            { meta.linkedin_url &&
              <a key={Math.floor(Math.random() * 99999999)} title={`${meta.linkedin_url}`} href={`${meta.linkedin_url}`} target="_blank">
                <Icon icon="linkedin" style={linkStyle} defaultColor={true} />
              </a>
            }
            { meta.facebook_url &&
              <a key={Math.floor(Math.random() * 99999999)} title={`${meta.facebook_url}`} href={`${meta.facebook_url}`} target="_blank">
                <Icon icon="facebook" style={linkStyle} defaultColor={true} />
              </a>
            }
            { meta.twitter_url &&
              <a key={Math.floor(Math.random() * 99999999)} title={`${meta.twitter_url}`} href={`${meta.twitter_url}`} target="_blank">
                <Icon icon="twitter" style={linkStyle} defaultColor={true} />
              </a>
            }
            {socials.map((s) => {
              if (Globals.platformIcons(s.label) && (s.username || s.id)) {
                return (
                  <a key={s.label} title={Globals.getPlatformUrl(s)} href={Globals.getPlatformUrl(s)} target="_blank">
                    <Icon icon="label" style={linkStyle} defaultColor={true} />
                  </a>
                );
              }
            })}
            {meta.countryId &&
              <a title={meta.country}>
                <Icon country={meta.countryId.toLowerCase()} style={linkStyle} />
              </a>
            }
          </div>
        </>
      )
    }
    else if (type == 'main-contact'){
      style.paddingTop = '8px'
      var linkStyle = { display: 'inline-block', float: 'left', height: '14px', marginRight: '8px' }
      badgeColor = badgeColor || 'active'
      html = (
        <>
          <div className="crm-username-title">
            {/*<a style={{color: '#1991EB', fontWeight: '500'}} onClick={props.triggerSlideOut.bind(props.this, influencer, influencerLabel)}>{title}</a>*/}
            <a className="crm-username-title-text" title={meta.name}>{meta.name}</a>
          </div>
          <div>
            { meta.linkedin_url &&
              <a key={Math.floor(Math.random() * 99999999)} title={`${meta.linkedin_url}`} href={`${meta.linkedin_url}`} target="_blank">
                <Icon icon="linkedin" style={linkStyle} defaultColor={true} />
              </a>
            }
            {meta.country && Globals.convertCountry(meta.country) &&
              <a title={meta.country}>
                <Icon country={(Globals.convertCountry(meta.country)||'').toLowerCase()} style={linkStyle} />
              </a>
            }
            { meta.selected && subtext &&
              <div>
                <p className="sub-text" title={subtext} style={{fontSize: '11px', margin: '0 0 0 0'}}>
                  { icon && <Icon iconPack={icon} verifiedColor="true" width="12px" style={{margin: '-1px 3px 0 0'}} /> }
                  <span style={{marginRight: '12px'}}>{subtext}</span>
                  { badgeIcon && <span><Icon iconPack={badgeIcon} colorType={badgeColor} width={dataObject.selected ? '12px' : '10px'} style={{margin: `${dataObject.selected ? '-1px 2px' : '-2px 1px'} 0 0`}} /></span> }
                  { badgeText && <span style={{fontStyle: 'italic'}}>{badgeText}</span> }
                </p>
              </div>
            }
          </div>
        </>
      )
    }
    else if (type == 'title-subtext'){
      badgeColor = badgeColor || 'active'

      html = (
      <>
        <div className="crm-dbl-text-container" style={{maxHeight: '50px'}}>
          <div className="crm-dbl-text" style={{lineHeight: '18px'}}>
            <div className="">
              <a className="" title={title} style={{color: '#242D3E'}}>{title}</a>
            </div>
          </div>
          { subtext &&
            <div>
              <p className="sub-text" title={subtext} style={{fontSize: '11px', margin: '0 0 0 0'}}>
                { icon && <Icon iconPack={icon} verifiedColor="true" width="12px" style={{margin: '-1px 3px 0 0'}} /> }
                <span style={{marginRight: '12px'}}>{subtext}</span>
                { badgeIcon && <span><Icon iconPack={badgeIcon} colorType={badgeColor} width={dataObject.selected ? '12px' : '10px'} style={{margin: `${dataObject.selected ? '-1px 2px' : '-2px 1px'} 0 0`}} /></span> }
                { badgeText && <span style={{fontStyle: 'italic'}}>{badgeText}</span> }
              </p>
            </div>
          }
        </div>
      </> )
    }
    else if (type == 'profileImg'){
      html = (
        <>
          <div className="crm-checkbox">
            <input type="checkbox" onChange={hoverFunc.bind(_this, influencer)} checked={influencer.selectedActive} />
          </div>
          <div className="message-tab-avatar-container message-tab-avatar-container-crm">
            <span className="message-tab-avatar">
              {imageUrl && (<LazyLoadImage key={imageUrl} src={imageUrl} className="message-tab-avatar-media" />)}
              {/*{imageUrl && (<span className="message-tab-avatar-media" style={{ backgroundImage: `url(${imageUrl})` }}></span>)}*/}
              {!imageUrl && (<span className="message-tab-avatar-media">{initial}</span>)}
            </span>
          </div>
        </>
      )
    }
    else if (type == 'socials'){
      html = (
        <>
          {socials ? socials.map((s) => {
            if (Globals.platformIcons(s.label) && (s.username || s.id)) {
              return (
                <a key={s.label} title={Globals.getPlatformUrl(s)} href={Globals.getPlatformUrl(s)} target="_blank">
                  <Icon icon={s.label} style={{display: 'inline-block', float: 'left', height: '14px', marginRight: '6px'}} defaultColor={true} />
                </a>
              );
            }
          }) : ""}
        </>
      )
    }
    else if (type == "actions"){
      var listCt = brand.lists && brand.lists.length > 0 ? brand.lists.length : null, contactStatus = 'error', opacity = .7
      if (meta.employeeCt > 0){ contactStatus = 'verified' }
      contactStatus = 'verified'
      // if (meta.employeeCt > 0 && )
      html = (
        <div className={`crm-action-btn-container crm-action-btn-3 ${listCt ? 'crm-action-btn-container-extended' : ''}`} style={{marginTop: '-6px'}}>
          <div className="row row0">
            <div className={`crm-action-btn ${listCt ? 'crm-action-btn-extended' : ''}`} title="Add/Remove from Outreach lists" onClick={togglePopup.bind(_this, 'addOutreachPopup', brand)}><Icon iconPack="list-check" preventColor={!listCt} defaultActiveColor={listCt} /> {listCt && <div className="crm-action-btn-stat">{listCt}</div>}</div>
            <div className="crm-action-btn" title="Choose primary contact to email" onClick={togglePopup.bind(_this, 'addContactPopup', brand)}><Icon iconPack={'user-add'} colorType={contactStatus} opacity={opacity} /></div>
            <div className="crm-action-btn" title="Edit brand..." onClick={togglePopup.bind(_this, 'editBrandPopup', brand)}><Icon iconPack={'pencil'} preventColor={true} /></div>
            {/*<div className="crm-action-btn" title="More actions..."><Icon iconPack={'menu-dots'} preventColor={true} /></div>*/}
          </div>
        </div>
      )
    }
    else if (type == "email_actions"){
      var btnStatus = 'default', { selected, recommendedContact } = dataObject, extraBtnStyle = {}, useIcon = 'envelope-download'
      if (emailStatus == 'verified') btnStatus = 'active'; else if (emailStatus == 'extrapolated') btnStatus = 'warning'; else if (emailStatus == 'unavailable'){ btnStatus = 'error'; extraBtnStyle = { cursor: 'not-allowed' }; useIcon = 'envelope-ban' }
      if (selected){ useIcon = 'check'; btnStatus = 'verified'; extraBtnStyle = { borderColor: '#07c897' } } else if (recommendedContact){ useIcon = 'check'; btnStatus = 'active'; /* extraBtnStyle = { borderColor: '#1991EB' } */ }

      html = (
        <div className="crm-action-btn-container" style={{marginTop: '-6px', width: '125px'}}>
          <div className="row row0">
            <div className="crm-action-btn" title="Mark as primary contact" onClick={hoverFunc.bind(_this, dataObject)} style={extraBtnStyle}>
              { true && <Icon iconPack={useIcon} colorType={btnStatus} /> }
            </div>
          </div>
        </div>
      )
    }
    else if (type == 'tile'){
      if (typeof title == 'string') { title = [title] }
      html = (
        <div className="crm-dbl-text-container">
          <div className="crm-dbl-text">
            { title.map((t) => {
              var colorStyle = {}; if (colorPalette[t]){ colorStyle.backgroundColor = colorPalette[t] }
              return ( <PillTag key={'name-'+Math.floor(Math.random() * 999999999)} name={t} style={{  ...colorStyle }} /> ) })
            }
          </div>
        </div>
      )
    }
    else if (type == 'dbl-text'){
      html = (
        <div className="crm-dbl-text-container">
          <div className="crm-dbl-text">
            <span title={title}>{title}</span>
          </div>
        </div>
      )
    }
    else if (type == 'text-img'){
      html = (
        <div className="crm-td">
          <div className="">
            <span title={title}>{title}</span>
          </div>
        </div>
      )
    }
    else if (linkUrl) { html = ( <a href={linkUrl} title={title} target="_blank"><span>{title}</span></a> ) }
    else if (pathUrl) { html = ( <Link to={pathUrl}><span>{title}</span></Link> ) }
    else if (domain) {
      var url = domain
      if ( !(domain.indexOf('http://') > -1 || domain.indexOf('https://') > -1) ) { url = 'http://'+domain }
      html = ( <a href={url} target="_blank"><span>{domain}</span></a> )
    }
    else { var titleText = `${title && title.length > 0 ? precursor : ''}${title}`; html = ( <span title={titleText}>{titleText}</span> ) }


    return (
      <>
        { clickFunc && <div className={`crm-td ${size} ${classes}`} style={style} onClick={clickFunc.bind(_this, influencer, influencerLabel)}>{html}</div> }
        { !clickFunc && <div className={`crm-td ${size} ${classes}`} style={style}>{html}</div> }
      </>
    );
})

export default memo(CRMTableMainCell);
