import React from 'react'
import Globals from '../../global-functions.js'
import Loader from '../loader'

export default class SettingsEmail extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      user: Globals.getUser(),
      collapsed: false,
      emailDomain: false,
      domainVal: ''
    }
  }

  componentDidMount(){ this.getEmail() }
  onChange(type, e){ this.setState({ [`${type}`]: e.target.value }) }
  getEmail(){ this._getEmail().done((response) => { this.setState({ emailDomain: response && typeof response == 'object' ? response : false, loading: false }) }) }
  _getEmail(){ return $.ajax({ method: 'GET', url: '/api/EmailDomain/get', data: { brandId: this.state.brandId } }) }

  createEmail(){
    var domain = this.state.domainVal
    domain = domain.replace('http://', '').replace('https://', '').replace('www.', '')
    this.setState({ loading: true, errorMessage: null })
    if (domain.indexOf('.') > -1){
      this._createEmail().done((response) => {
        if (response.status) { this.setState({ errorMessage: response.status, loading: false }) }
        else { this.setState({ emailDomain: response && typeof response == 'object' ? response : false, loading: false }) }
      })
    }
  }

  _createEmail(){ return $.ajax({ method: 'POST', url: '/api/EmailDomain/create', data: { brandId: this.state.brandId, domain: this.state.domainVal } }) }
  refreshEmail(){ this.setState({ loading: true }); this._refreshEmail().done((response) => { this.setState({ emailDomain: response && typeof response == 'object' ? response : false, loading: false }) }) }
  _refreshEmail(){ return $.ajax({ method: 'POST', url: '/api/EmailDomain/refresh', data: { brandId: this.state.brandId, domain: this.state.emailDomain.name } }) }
  removeEmail(){ this.setState({ loading: true }); this._removeEmail().done((response) => { this.setState({ emailDomain: response && typeof response == 'object' ? response : false, loading: false }) }) }
  _removeEmail(){ return $.ajax({ method: 'POST', url: '/api/EmailDomain/remove', data: { brandId: this.state.brandId, domain: this.state.emailDomain.name } }) }

  render() {

    var user = this.state.user ? this.state.user : {}, emailDomain = this.state.emailDomain, mainDomain = ''
    if (emailDomain){
      var subDomain = emailDomain.name
      var s = (subDomain || '').split('.')
      mainDomain = s.length > 2 ? `.${s[s.length - 2]}.${s[s.length - 1]}` : ''
    }

    return (
      <div>
        <div className="mgmt-section">
          <div className={`mgmt-list`}>
            <div className="crm-actions">
              <div className="row row0">
                <div style={{display: 'inline-block', float: 'left'}}>
                  <h4 className="crm-title">Email Settings</h4>
                </div>
              </div>
            </div>

            <div className="account-settings" style={{padding: '25px', overflow: 'auto'}}>
              { this.state.loading && <Loader active={this.state.loading} /> }
              { !this.state.loading &&
                <div className="row row0">
                  { !emailDomain &&
                    <>
                      <div className="row row0">
                        <div className="module" style={{maxWidth: '850px'}}>
                          <h4 style={{marginBottom: '25px'}}>Domain Name</h4>
                          <p className="sub-text">
                            We recommend using a subdomain with Weave, like "talent.mydomain.com". Using a subdomain you will still be able to send emails from your root domain e.g. "you@mydomain.com".
                          </p>
                          <input className="dark-input" style={{width: '100%'}} onChange={this.onChange.bind(this, 'domainVal')} />
                          {this.state.errorMessage && <p className="error-msg">{this.state.errorMessage}</p>}
                          <button className="btn-action btn-primary" style={{marginTop: '25px'}} onClick={this.createEmail.bind(this)}>Add Domain</button>
                        </div>
                      </div>
                    </>
                  }

                  { emailDomain && emailDomain.state == 'active' &&
                    <>
                      <div className="row row0">
                        <div className="module" style={{maxWidth: '850px'}}>
                          <h4 style={{marginBottom: '25px'}}>Domain Name <b style={{color: 'green'}}>• Connected</b></h4>
                          <p className="sub-text">
                            Your domain is successfully verified and available for use.
                          </p>
                          <h5>{emailDomain.name}</h5>
                          <div className="row row0" style={{marginTop: '25px'}}>
                            <button className="btn-action btn-secondary" onClick={this.refreshEmail.bind(this)}>Verify DNS Settings</button>
                            <button className="btn-action btn-margin btn-error" onClick={this.removeEmail.bind(this)}>Remove Email</button>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  { emailDomain && emailDomain.state == 'unverified' &&
                    <>
                      <div className="row row0">
                        <div className="module" style={{maxWidth: '850px'}}>
                          <h4 style={{marginBottom: '25px'}}>Domain Name</h4>
                          <p className="sub-text">
                            Please follow the directions below to finish setting up your email domain for Weave.
                          </p>
                          <h5>{emailDomain.name}</h5>
                          <div className="row row0" style={{marginTop: '25px'}}>
                            <button className="btn-action btn-secondary" onClick={this.refreshEmail.bind(this)}>Verify DNS Settings</button>
                            <button className="btn-action btn-margin btn-error" onClick={this.removeEmail.bind(this)}>Remove Email</button>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  { emailDomain &&
                    <>
                      <div className="module" style={{maxWidth: '850px'}}>
                        <h4 style={{marginBottom: '25px'}}>1) Go to your DNS provider</h4>
                        <p className="sub-text">
                          Go to the DNS provider that you use to manage <b>{emailDomain.name ? emailDomain.name : 'talent.mydomain.com'}</b> and add the following DNS records.
                        </p>
                        <div>
                          <h5>Common providers include:</h5>
                          <div><a style={{textDecoration: 'underline'}} href="https://support.godaddy.com/help/article/7925/adding-or-editing-txt-records?locale=en" target="_blank">GoDaddy</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="https://www.namecheap.com/support/knowledgebase/article.aspx/579/2237/which-record-type-option-should-i-choose-for-the-information-im-about-to-enter" target="_blank">NameCheap</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="http://www.networksolutions.com/support/how-to-manage-advanced-dns-records/" target="_blank">Network Solutions</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="https://support.rackspace.com/how-to/set-up-dns-records-for-cloud-office-email-and-skype-for-business/" target="_blank">Rackspace Email & Apps</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="http://www.rackspace.com/knowledge_center/article/rackspace-cloud-dns" target="_blank">Rackspace Cloud DNS</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="http://docs.aws.amazon.com/Route53/latest/DeveloperGuide/R53Console.html" target="_blank">Amazon Route 53</a></div>
                          <div><a style={{textDecoration: 'underline'}} href="http://code.krister.ee/mailgun-digitalocean/" target="_blank">Digital Ocean</a></div>
                        </div>
                      </div>

                      <div className="module" style={{maxWidth: '850px'}}>
                        <h4 style={{marginBottom: '25px'}}>2) Add DNS records for sending</h4>
                        <div className="row row-header" style={{fontWeight: 'bold', padding: '15px 0'}}>
                          <div className="col-xs-2"><span>Type</span></div>
                          <div className="col-xs-3"><span>Host</span></div>
                          <div className="col-xs-5"><span>Value</span></div>
                          <div className="col-xs-2"><span>Verified</span></div>
                        </div>

                        { emailDomain.sendingDnsRecords && emailDomain.sendingDnsRecords.map((r, i) => {
                            var valid = r.valid == 'valid' ? true : false
                            return (
                              <div className="row row-with-border" style={{padding: '15px 0', border: '1px solid'}}>
                                <div className="col-xs-2"><span style={{wordWrap: 'break-word'}}>{r.record_type}</span></div>
                                <div className="col-xs-3"><span style={{wordWrap: 'break-word'}}>{r.name.replace(mainDomain, '')}</span></div>
                                <div className="col-xs-5"><textarea id={`sending-${i}`} className="dark-input" defaultValue={r.value} style={{width: '100%', resize: 'none', cursor: 'pointer', height: `${r.name.indexOf('domainkey') > -1 ? '100px' : '42px'}`, marginBottom: '-4px'}} onClick={Globals.copyLink.bind(this, `sending-${i}`)} readOnly /></div>
                                <div className="col-xs-2"><span style={{color: `${valid ? 'green' : 'red'}`}}>{valid ? 'Verified' : 'Unverified'}</span></div>
                              </div>
                            )
                          })
                        }

                        <div className="row row0" style={{marginTop: '25px'}}>
                          <p className="sub-text">
                            MX records are recommended for all domains, even if you are only sending messages. You will <b><u>NOT</u> be able to receive emails</b> if you do not add your MX records.
                          </p>
                        </div>

                        <div className="row row-header" style={{fontWeight: 'bold', padding: '15px 0'}}>
                          <div className="col-xs-2"><span>Type</span></div>
                          <div className="col-xs-2"><span>Host</span></div>
                          <div className="col-xs-1"><span>Priority</span></div>
                          <div className="col-xs-5"><span>Value</span></div>
                          <div className="col-xs-2"><span>Verified</span></div>
                        </div>

                        { emailDomain.receivingDnsRecords && emailDomain.receivingDnsRecords.map((r, i) => {
                            var valid = r.valid == 'valid' ? true : false
                            return (
                              <div className="row row-with-border" row-with- style={{padding: '15px 0', border: '1px solid'}}>
                                <div className="col-xs-2"><span style={{wordWrap: 'break-word'}}>{r.record_type}</span></div>
                                <div className="col-xs-2"><span style={{wordWrap: 'break-word'}}>{emailDomain.name.replace(mainDomain, '')}</span></div>
                                <div className="col-xs-1"><textarea id={`receiving-priority-${i}`} className="dark-input" defaultValue={`10`} style={{width: '100%', resize: 'none', cursor: 'pointer', height: '42px', marginBottom: '-4px'}} onClick={Globals.copyLink.bind(this, `receiving-priority-${i}`)} readOnly /></div>
                                <div className="col-xs-5"><textarea id={`receiving-${i}`} className="dark-input" defaultValue={`${r.value}`} style={{width: '100%', resize: 'none', cursor: 'pointer', height: '42px', marginBottom: '-4px'}} onClick={Globals.copyLink.bind(this, `receiving-${i}`)} readOnly /></div>
                                <div className="col-xs-2"><span style={{color: `${valid ? 'green' : 'red'}`}}>{valid ? 'Verified' : 'Unverified'}</span></div>
                              </div>
                            )
                          })
                        }
                      </div>

                      { emailDomain && emailDomain.state == 'unverified' &&
                        <>
                          <div className="module" style={{maxWidth: '850px'}}>
                            <h4 style={{marginBottom: '25px'}}>4) Wait for your domain to verify</h4>
                            <p className="sub-text">
                              Once you make the above DNS changes it can take <b>24-48 hrs</b> for those changes to propagate. We will email you to let you know once your domain is verified.
                            </p>
                            <div className="row row0" style={{marginTop: '25px'}}>
                              <button className="btn-action btn-secondary" onClick={this.refreshEmail.bind(this)}>Verify DNS Settings</button>
                              <button className="btn-action btn-margin btn-error" onClick={this.removeEmail.bind(this)}>Remove Email</button>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
