import React from 'react';
import TaxInfoHeader from './taxinfo-header.jsx'

export default class TaxInfoExplanation extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return (
      <div>
        <TaxInfoHeader
          title={`Add your basic tax info`}
          description={`As part of 2022 U.S. tax requirements, we need your tax information so we can fulfill the new IRS 1099-K reporting requirement.`}
        />
        <div className="taxinfo-popup-body">
          <p className="sub-text">
            Here's what we need to collect from you for tax reporting:
          </p>
          <ul>
            <li>Social Security Number (SSN) or Employer Identification Number (EIN)</li>
            <li>Legal Name, address, and date of birth</li>
          </ul>
        </div>
      </div>
    )
  }
}
