import React from 'react'
import EmptyMessage from './empty-message'
import RosterTable from './roster-table'

export default class Roster extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      active: false,
      loading: false,
      roster: {},
      rosterTitle: ''
    }
  }

  componentDidMount(){
    const { rosterId } = this.props.match.params
    // this.getRoster(rosterId);
    this.setState({ rosterId });
  }

  chooseSort(){}
  selectInfluencer(){}

  onChange(type, e){
    var val = e.target.value, { rosterTitle } = this.state
    this.setState({ [`${type}`]: val })
    if (type == 'updateRoster'){
      setTimeout(() => { if (rosterTitle == val){ this.updateRoster() } }, 500)
    }
  }

  async getRoster(){ var { rosterTitle, rosterId } = this.state; var res = await this._getRoster({ rosterTitle, rosterId }); }
  async _getRoster(data){ return $.ajax({ method: 'GET', url: '/api/Roster/get', data }) }
  async updateRoster(){ var { rosterTitle, rosterId } = this.state; var res = await this._updateRoster({ rosterTitle, rosterId }); }
  async _updateRoster(data){ return $.ajax({ method: 'POST', url: '/api/Roster/update', data }) }

  render(){
    var { roster, rosterTitle, loading } = this.state

    return (
      <>
        <div style={{padding: '15px 0'}}>
          <div className="row">
            <div className="col-xs-6">
              <div className="row row0">
                <div style={{display: 'inline-block', float: 'left'}}>
                  <h3 style={{paddingBottom: '8px', marginTop: 0}}><input className="drip-bounty-title" value={rosterTitle} onChange={this.onChange.bind(this, 'updateRoster')} style={{paddingLeft: 0, paddingBottom: '6px'}} /></h3>
                  <p className="sub-text" style={{marginBottom: '25px'}}>Edit your roster title here</p>
                </div>
              </div>
            </div>
            <div className="col-xs-6">
              <button className="btn-action btn-primary btn-right">+ Add Creator</button>
            </div>
          </div>
        </div>
        <div>
          {/*{ roster.length > 0 && <RosterTable this={this} roster={roster} chooseSort={this.chooseSort} selectInfluencer={this.selectInfluencer} style={{height: '500px'}} /> }*/}
          {/*{ roster.length == 0 && !loading && <EmptyMessage title={'No rosters created.'} subtext={'Create a roster to manage your influencers'} align="top" />}*/}
        </div>
      </>
    )
  }
}
