import { MakeFetchGet, MakeFetchPost } from '../utils/utils';
import { HasAccountRequest } from '../redux/models/invite-user.models';

interface SetUpGuestUser {
  contentId: string
}

export const setUpGuestUser = (params: SetUpGuestUser) =>
  MakeFetchPost('/api/Users/setUpGuestUser', params) as Promise<{user: any}>;

export const fetchHasAccount = (params: HasAccountRequest) =>
  MakeFetchGet('/api/Users/hasAccount', params);

export const guestSignup = (params: { contentId: string, email: string, password: string }) =>
  MakeFetchPost('/api/Apply/signupGuestUser', params);

export const verifyInstagram = (params: { profile: string, userId: string }) =>
  MakeFetchPost('/api/Users/verifyInstagram', params);

export const verifyProfile = (params: { profile: string, userId: string }) =>
  MakeFetchPost('/api/Users/validateProfile', params);
