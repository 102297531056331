import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'

const FilterKeyword = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <div className="filter-option-container">
        <div className="row row0">
          <label>Brand Keywords</label>
          <div className="row row0">
            <SearchDropdown endpoint="getAutocomplete" searchIndex="apollo_keyword" index={0} type={type} field="name" changeFilter={changeFilter} placeholder="Select brand keyword..." _this={_this} />
          </div>
          <div className="row row0">
            { filter[0] && filter[0].length > 0 && filter[0].map((l) => {
              return ( <PillTag key={l.id || l.name} _this={_this} icon={""} name={l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> ) })
            }
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(FilterKeyword);
