import { memo } from 'react';
import Globals from '../../global-functions.js'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import Plain from 'slate-plain-serializer'
import Html from 'slate-html-serializer'

function parseMessage(value){
  var template = value.replace(/<p><\/p>/g, '<div><br></div>').replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>').replace(/\n/g, '<div><br></div>')
  var NAME = 'Will', LAST_RESTAURANT = 'Sweetfin Poke', INVITE_LINK = 'http://weave.vip/invite/@toblerone'
  var split = template.split('{{')
  split.shift()
  var variables = []
  split.forEach((s) => { var word = s.split('}}')[0].replace(/ /g, '').toLowerCase(); variables.push(word); })
  template = template.replace('{{ NAME }}', NAME).replace('{{ LAST_RESTAURANT }}', LAST_RESTAURANT).replace('{{ INVITE_LINK }}', INVITE_LINK)
  return template
}

function parseLogic(params){
  var template = html.serialize(this.state.value)
  var NAME = 'Will', LAST_RESTAURANT = 'Sweetfin Poke'

  var split = template.split('{% IF ')
  split.shift()
  var variables = []
  split.forEach((s) => {
    var word = s.split('%}')[0].replace(/ /g, '').toLowerCase();
    variables.push(word);
  })
}

// function fillDynamicValues(_template, params, options){
//   options = options || {}
//   var template = _template.replace(/{{ /g, '{{').replace(/ }}/g, '}}')
//   var contact = { firstName: 'Rachel' }, apollo = { name: 'Romwe' }, brand = { nam: 'Romwe' }, lead = {}, socials = { 'instagram': { username: 'measeter_miyagi', followerCount: 3000 } }
//   var replace = []
//   var split = template.split('{{').map((s) => { return s.split('}}')[0].trim() }).forEach((s) => {
//     var value = ''
//     var label = s.split('.')[0], key = s.split('.')[1]
//     if (label == 'contact'){ value = contact['firstName'] }
//     else if (label == 'brand'){ value = apollo[key] || brand[key]; value = cleanBrandName(value) }
//     else if (PLATFORMS[label] && socials[label]){
//       var social = { label, ...socials[label] }
//       if (key == 'url'){ value = `<a href="${GService.getPlatformUrl(social)}"><b>${PLATFORMS[label].name}</b></a>` }
//       else if (key == 'followers'){ value = `<b>${GService.convertNumberFormat(social.followerCount)}</b>` }
//     }
//     var k = { key: `{{${label}.${key}}}`, `<b style="color: blue;">${value}</b>` }
//     replace.push(k)
//   })
//   replace.forEach((r) => { template = template.replace(new RegExp(r.key,'g'), r.value) })
//
// }

const CreateMessagePreview = ((props) => {
  var { value, subject, _this } = props

  return (
    <>
      <h3>Email Preview</h3>
      <div className="row row0">
        <b>{subject}</b>
      </div>
      <hr />
      <div className="row row0">
        <div dangerouslySetInnerHTML={{__html: parseMessage(value)}}></div>
      </div>
    </>
  )
})

export default memo(CreateMessagePreview);
