import React from 'react';

export default class GeneralTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />General Useful Tips</h4>
        <p>When you select where your links are going it helps accomplish a few things:</p>
        <ol>
          <li>you can understand which of your social networks or blogs is performing best</li>
          <li>you give our brands the opportunity to discover you for sponsorship opportunities</li>
          <li>you can review how an entire post performed and we can develop insights to help you earn more over time</li>
          <li>you can go back and find links from your previous posts</li>
        </ol>
      </div>
    )
  }
}
