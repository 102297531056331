import { memo } from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainCell from './crm-table-main-cell'

const OutboundTable = ((props) => {
  var { dripCampaigns, selectedActive, selectInfluencer, sortFilter, loading, style, _this } = props
  style = style || {}; style = { ...style, marginBottom: '15px', height: '100vh' }

  return (
    <div>
      <div className="crm-table" style={style}>
        <div className="crm-table-header">
          <div className={`row row0 crm-row ${selectedActive ? "selected" : ""}`}>
            <div className="crm-td crm-td-xs crm-td-img">
              <input type="checkbox" style={{ display: "block" }} onClick={selectInfluencer.bind(_this, "all")} checked={selectedActive} />
            </div>
            <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-md" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Status" title="Status" size="crm-td-sm" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Created" title="Created" size="crm-td-md" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Last Updated" title="Last Updated" size="crm-td-md" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Queued" title="Queued" size="crm-td-sm" iconPack="list-check" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Sent" title="Sent" size="crm-td-sm" icon="mail" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Opened" title="Opened" size="crm-td-sm" icon="user" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Responded" title="Responded" size="crm-td-sm" icon="bubble" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Clicked" title="Clicked" size="crm-td-sm" icon="link" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Email Invalid" title="Invalid" size="crm-td-sm" icon="close" sortFilter={sortFilter} this={_this} />
            <CRMTableHeaaderCell label="Completed" title="Completed" size="crm-td-sm" icon="signal" sortFilter={sortFilter} this={_this} />
          </div>
        </div>

        <div className="crm-table-main">
          {dripCampaigns.map((campaign) => {
            var stats = campaign.stats ? campaign.stats : { sent: 0 }
            var total = stats.total || 0,
                queued = stats.queued || 0,
                sent = stats.pitched || 0,
                opened = Math.round(((+stats.opened || 0) / (+sent || 1)) * 100) + '%',
                responded = Math.round(((+stats.responded || 0) / (+sent || 1)) * 100) + '%',
                clicked = Math.round(((+stats.clicked || 0) / (+sent || 1)) * 100) + '%',
                invalid = Math.round(((+stats.emailInvalid || 0) / (+total || 1)) * 100) + '%',
                completed = Math.round(((+stats.failed || 0) / (+sent || 1)) * 100) + '%'

            return (
              <div key={campaign.id} className={`row row0 new-module crm-row crm-row-influencer ${campaign.selectedActive ? "selected" : ""}`}>
                <div className="">
                  <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={''} initial={''} hoverFunc={selectInfluencer} this={_this} />
                  <CRMTableMainCell title={campaign.title ? campaign.title : 'Untitled'} size="crm-td-md" pathUrl={`/outreach/drip/${campaign.id}`} classes="crm-username bold-text" this={_this} />
                  <CRMTableMainCell title={campaign.active ? 'Active' : 'Inactive'} size="crm-td-sm" type="tile" tileColor={campaign.active ? 'color-complete' : 'color-error'}  this={_this} />
                  <CRMTableMainCell title={moment(campaign.created_at).format('MMM M, YYYY')} size="crm-td-md" this={_this} />
                  <CRMTableMainCell title={moment(campaign.updated_at ? campaign.updated_at : campaign.created_at).format('MMM M, YYYY')} size="crm-td-md" this={_this} />
                  <CRMTableMainCell title={queued} size="crm-td-sm" classes="bold-text" />
                  <CRMTableMainCell title={sent} size="crm-td-sm" classes="bold-text" />
                  <CRMTableMainCell title={opened} size="crm-td-sm" classes="sub-text" />
                  <CRMTableMainCell title={responded} size="crm-td-sm" classes="sub-text" />
                  <CRMTableMainCell title={clicked} size="crm-td-sm" classes="sub-text" />
                  <CRMTableMainCell title={invalid} size="crm-td-sm" classes="sub-text" />
                  <CRMTableMainCell title={completed} size="crm-td-sm" classes="sub-text" />
                </div>
              </div>
            );
          })}
        </div>
        {loading && <Loader active={true} />}
      </div>
    </div>
  );
})

export default memo(OutboundTable);
