import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import FacebookLogin from '../../dependencies/facebook-login.js';
import Cookies from 'js-cookie';
import includes from 'array-includes';
import store from "store2";

export default class Signup extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      email: '',
      message: '',
      instagram: '',
      youtube: '',
      tiktok: '',
      twitch: '',
      emailExists: false,
      socialExists: false,
      btnText : 'APPLY FOR EARLY ACCESS',
      applicationExists: false
    }
  }

  componentDidMount(){
    // Globals.pageView(window.location.pathname + window.location.search)
  }

  storeInstagramToken(response) { this._storeInstagramToken(response.accessToken).done((response) => { this.props.refreshUser() }) }
  _storeInstagramToken(access_token){ return $.ajax({ method: 'POST', url: '/api/Instagram/storeToken', data: { access_token: access_token } }) }
  youtubeLogin(){ $.ajax({ method: 'POST', url: '/api/YouTube/googleUrl', data: {} }).done((data) => { location.href = data }) }
  twitchLogin(){ $.ajax({ method: 'GET', url: '/api/Twitch/login', data: {} }).done((data) => { location.href = data }) }

  onSubmit(e){
    //GoogleAnalyticsService.logEvent('onboard', 'signup')
    e.preventDefault()
    this.setState({ btnText: 'SUBMITTING...' })
    var cmp = location.search
    if (cmp.indexOf('campaign=') > -1){
      cmp = cmp.split('campaign=')[1].split('&')[0]
      Cookies.set('cmp', cmp)
    }
    else { cmp = Cookies.get('cmp'); }
    var { email, message, instagram, youtube, tiktok, twitch } = this.state
    var obj = { email, message, instagram, youtube, tiktok, twitch, referral: store('ref'), campaign: (cmp ? cmp : false) }

    $.ajax({ method: 'POST', url: '/api/Apply/apply', data: obj })
      .done((data) => {
        if (data.autoApproved){
          this.setState({ btnText: 'APPLICATION RECEIVED', applicationExists: true })
          Cookies.set('onboarding-status', '/onboard-email-sent');
          Cookies.set('onboarding', true);
          location.href = '/onboard-email-sent'
        }
        else if (data.success){
          this.setState({ btnText: 'APPLICATION RECEIVED', applicationExists: true })
        }
        else {
          alert(data.message)
          this.setState({ btnText: 'SIGN UP' })
        }
      })
  }

  onChange(type, e) {
    var val = e.target.value
    var { email, instagram, youtube, tiktok, twitch } = this.state, emailExists, socialExists
    if (email && email.length > 2 && Globals.validateEmail(email)) { emailExists = true }
    else if (type == 'email' && val.length > 2 && Globals.validateEmail(val)) { emailExists = true }
    if (instagram && instagram.length > 2) { socialExists = true }
    if (youtube && youtube.length > 2) { socialExists = true }
    if (tiktok && tiktok.length > 2) { socialExists = true }
    if (twitch && twitch.length > 2) { socialExists = true }
    if (includes(type, ['instagram', 'youtube', 'tiktok', 'twitch']) && val.length > 2) { socialExists = true }
    this.setState({ [type]: val, socialExists, emailExists })
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {
    var { emailExists, socialExists, applicationExists, instagram, youtube, tiktok, twitch, btnText } = this.state, instagramExists, youtubeExists, tiktokExists, twitchExists
    if (instagram && instagram.length > 2) { instagramExists = true }
    if (youtube && youtube.length > 2) { youtubeExists = true }
    if (tiktok && tiktok.length > 2) { tiktokExists = true }
    if (twitch && twitch.length > 2) { twitchExists = true }
    var cmp = location.search
    if (cmp.indexOf('campaign=') > -1){
      cmp = cmp.split('campaign=')[1].split('&')[0]
      Cookies.set('cmp', cmp)
    }
    else { cmp = null; }
    return (
      <div>
        <div className="login-page">
          <div className="login-box">
            <div className="login-box-inner">
              <header>
                <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </header>
              <div className="login-padding">
                {/*<div style={{ display: 'none' }}>

                  <div className="col-md-12" style={{margin: '10px 0', padding: '0'}}>
                    <div>
                      <FacebookLogin
                        appId="520203271980535"
                        autoLoad={false}
                        scope="public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,instagram_manage_comments"
                        callback={this.storeInstagramToken.bind(this)}
                        render={renderProps => (
                          <a onClick={renderProps.onClick}>
                            <div className="integration-btn" role="button" style={{backgroundColor: '#dc4465'}}>
                              <div className="integration-btn-text-container">
                                <div className="integration-btn-text">
                                  <div className="integration-btn-text-left">
                                    <div className="integration-btn-img-container">
                                      <div className="integration-btn-img" style={{backgroundImage: 'url(/images/instagram-icon.svg)'}}></div>
                                    </div>
                                    <span className="integration-btn-title">Instagram</span>
                                    <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                                  </div>
                                  <div className="integration-btn-text-right">
                                    <button>
                                      Login
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-12" style={{margin: '10px 0', padding: '0'}}>
                    <div>
                      <a onClick={this.youtubeLogin.bind(this)}>
                        <div className="integration-btn" role="button" style={{backgroundColor: '#FF0000'}}>
                          <div className="integration-btn-text-container">
                            <div className="integration-btn-text">
                              <div className="integration-btn-text-left">
                                <div className="integration-btn-img-container">
                                  <div className="integration-btn-img" style={{backgroundImage: 'url(/images/youtube-icon.svg)'}}></div>
                                </div>
                                <span className="integration-btn-title">YouTube</span>
                                <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                              </div>
                              <div className="integration-btn-text-right">
                                <button>
                                  Login
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="col-md-12" style={{margin: '10px 0', padding: '0'}}>
                    <div>
                      <a onClick={this.twitchLogin.bind(this)}>
                        <div className="integration-btn" role="button" style={{backgroundColor: '#6441a4'}}>
                          <div className="integration-btn-text-container">
                            <div className="integration-btn-text">
                              <div className="integration-btn-text-left">
                                <div className="integration-btn-img-container">
                                  <div className="integration-btn-img" style={{backgroundImage: 'url(/images/twitch-logo-white.svg)'}}></div>
                                </div>
                                <span className="integration-btn-title">Twitch</span>
                                <div className="integration-btn-sub" style={{display: 'none'}}>Stream games and make money</div>
                              </div>
                              <div className="integration-btn-text-right">
                                <button>Login</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>

                </div>*/}

                {/*<span className="login-separator">or</span>*/}

                <form onSubmit={this.onSubmit.bind(this)}>
                  {/*<p>Sign up to be approved into the Weave network</p>*/}
                  <div className="input-text-wrapper email" style={{backgroundImage: `url(${Globals.iconPack('envelope')})`}}>
                    <input id="email" className={`input-text ${emailExists ? 'success' : ''}`} placeholder="Email" type="email" onChange={this.onChange.bind(this, 'email')} />
                  </div>
                  <div className="input-text-wrapper message">
                    <textarea id="message" className="input-text" placeholder="*Optional - provide more info to help us approve your account" onChange={this.onChange.bind(this, 'message')} />
                  </div>
                  <hr />
                  <p>Add <b>at least 1 social</b> for Weave to approve your account</p>
                  <div className="input-text-wrapper url" style={{backgroundImage: `url(${Globals.icon('instagram', 'black')})`}}>
                    <input id="instagram" className={`input-text ${instagramExists ? 'success' : ''}`} placeholder="Instagram username or profile url..." onChange={this.onChange.bind(this, 'instagram')} />
                  </div>
                  <div className="input-text-wrapper url" style={{backgroundImage: `url(${Globals.icon('youtube', 'black')})`}}>
                    <input id="youtube" className={`input-text ${youtubeExists ? 'success' : ''}`} placeholder="YouTube profile url..." onChange={this.onChange.bind(this, 'youtube')} />
                  </div>
                  <div className="input-text-wrapper url" style={{backgroundImage: `url(${Globals.icon('tiktok', 'black')})`}}>
                    <input id="tiktok" className={`input-text ${tiktokExists ? 'success' : ''}`} placeholder="TikTok username or profile url..." onChange={this.onChange.bind(this, 'tiktok')} />
                  </div>
                  <div className="input-text-wrapper url" style={{backgroundImage: `url(${Globals.icon('twitch', 'black')})`}}>
                    <input id="twitch" className={`input-text ${twitchExists ? 'success' : ''}`} placeholder="Twitch username or profile url..." onChange={this.onChange.bind(this, 'twitch')} />
                  </div>

                  <span className="login-separator" style={{letterSpacing: 'initial', fontSize: '13px'}}>By signing up, you agree to our <a href="/terms-of-service" target="_blank"><b>terms of service</b></a>.</span>
                  <button id="submit" type="submit" className={`btn-submit ${(emailExists && socialExists) && !applicationExists ? '' : 'disabled'}`}>{btnText}</button>
                </form>
                <p className="switch-boxes">Have an account? <Link to="/login"><b>Login here!</b></Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
