import React from 'react';
import Globals from '../../global-functions.js';

export default class CreateInvoiceInput extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  render() {
    var { title, value, label, type } = this.props

    return (
      <div className="create-invoice-input">
        <div className="row row0">
          <label>{title}</label>
          { !type && <input className="dark-input" value={value} onChange={this.props.onChangeInput.bind(this.props.this, label)} /> }
          { type == 'textarea' && <textarea className="dark-input" style={{padding: '15px'}} value={value} onChange={this.props.onChangeInput.bind(this.props.this, label)} /> }
        </div>
      </div>
    )
  }
}
