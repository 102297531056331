import { memo } from 'react'
import Icon from './icon'

const ToggleMenuCollapse = ((props) => {
  var { toggleCollapse, collapsed, _this } = props
  return (
    <>
      <div style={{display: 'inline-block', float: 'left'}}>
        <div className={`crm-hamburger ${collapsed ? 'collapsed' : ''}`} onClick={toggleCollapse.bind(_this)}>
          <div className="crm-hamburger-inner">
            <Icon className="crm-hamburger-caret crm-hamburger-caret-left" iconPack="angle-left" defaultColor={true} width="10px" />
            <Icon className="crm-hamburger-icon" iconPack="menu-burger" defaultColor={true} width="13px" />
            <Icon className="crm-hamburger-caret crm-hamburger-caret-right" iconPack="angle-right" defaultColor={true} width="10px" />
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(ToggleMenuCollapse)
