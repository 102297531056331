import { memo } from 'react';
import SearchDropdown from '../search-dropdown'

const FilterOptionMinMax = ((props) => {
  var { title, filter, changeFilter, type, index, _this } = props

  return (
    <>
      <div className="filter-option-container min-max">
        <div className="filter-option-inner">
          <div className="row row0">
            <div className="filter-option-title">
              <label>{title}</label>
            </div>
          </div>

          <div className="row row0">
            <div className={`filter-option-selector-container ${filter[index].active ? 'active' : ''}`}>
              <div className="filter-option-selector">
                <div className="filter-checkbox" onClick={changeFilter.bind(_this, type[index], filter[index], 'selector')}>
                  <div className="filter-checkbox-inner"></div>
                </div>
              </div>
            </div>
            <div className="filter-option-selector-input ">
              <SearchDropdown disableDropdown={true} index={index} filter={filter} type={type} simple="true" field="name" changeFilter={changeFilter} placeholder="0" inputStyle={{ marginBottom: '-2px', borderRadius: '3px 3px 0 0' }} _this={_this} />
            </div>
            <div className="filter-option-selector-input-to">
              <span>-</span>
            </div>
            <div className="filter-option-selector-input ">
              <SearchDropdown disableDropdown={true} index={index} filter={filter} type={type} simple="true" field="name" changeFilter={changeFilter} placeholder="0" inputStyle={{ marginBottom: '-2px', borderRadius: '3px 3px 0 0' }} _this={_this} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(FilterOptionMinMax);
