import { all, call, put, takeLatest } from 'typed-redux-saga';
import {
  contentActionCreators, CreateContentApprovalAnonymousAction,
  GetContentApprovalsAnonymousAction,
  GetContentObjectivesAction, UpdateContentForReviewAction
} from '../actions/content.actions';
import {
  createContentApprovalAnonymous,
  fetchContentApprovalsAnonymous,
  fetchObjectives, updateContentApproval
} from '../../services/content-review-service';

function* getContentObjectivesSaga(action: GetContentObjectivesAction) {
  try {
    const response = yield* call(fetchObjectives, action.payload);
    if (Array.isArray(response)) {
      return yield* put(contentActionCreators.getContentObjectivesSuccessAction(response));
    } else {
      throw Error(response.message || 'Failed to get objectives');
    }

  } catch (e) {
    yield put(contentActionCreators.getContentObjectivesFailAction(e));
  }
}

function* getContentApprovalsAnonymousSaga(action: GetContentApprovalsAnonymousAction) {
  try {
    const response = yield* call(fetchContentApprovalsAnonymous, action.payload.contentId);
    return yield* put(contentActionCreators.getContentApprovalsAnonymousSuccessAction(response));
  } catch (e) {
    yield put(contentActionCreators.getContentApprovalsAnonymousFailAction(e));
  }
}

function* createContentApprovalAnonymousSaga(action: CreateContentApprovalAnonymousAction) {
  try {
    const response = yield* call(createContentApprovalAnonymous, action.payload);
    return yield* put(contentActionCreators.createContentApprovalAnonymousSuccessAction(response));
  } catch (e) {
    yield put(contentActionCreators.createContentApprovalAnonymousFailAction(e));
  }
}

function* updateContentApprovalSaga(action: UpdateContentForReviewAction) {
  try {
    const response = yield* call(updateContentApproval, action.payload);
    return yield* put(contentActionCreators.updateContentForReviewSuccessAction(response));
  } catch (e) {
    yield put(contentActionCreators.updateContentForReviewFailAction(e));
  }
}

export function* contentSagas() {
  yield all([
    takeLatest('contentObjectives/GET', getContentObjectivesSaga),
    takeLatest('contentApprovalsAnonymous/GET', getContentApprovalsAnonymousSaga),
    takeLatest('contentApprovalAnonymous/CREATE', createContentApprovalAnonymousSaga),
    takeLatest('contentForReview/UPDATE', updateContentApprovalSaga),
  ]);
}
