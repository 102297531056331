import React from 'react';

export default class Applications extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      applications: []
    }
  }

  componentDidMount(){
    this.getApplications()
  }

  getApplications(){
    this._getApplications().done((data) => {
      this.setState({
        applications: data
      })
    })
  }

  _getApplications(){
    return $.ajax({
      method: 'GET',
      url: '/api/Apply/get',
      data: {}
    })
  }

  approve(app){
    this._approve(app).done((data) => {
      console.log(data)
      if (data.success){
        var apps = this.state.applications
        var newApps = []
        apps.forEach((a) => {
          if (a.id != app.id) { newApps.push(a) }
        })
        this.setState({
          applications: newApps
        })
      }
      else {
        alert(data.message)
      }
    })
  }

  _approve(app){
    return $.ajax({
      method: 'POST',
      url: '/api/Apply/approve',
      data: app
    })
  }

  reject(app){
    this._reject(app).done((data) => {
      console.log(data)
      if (data.success){
        var apps = this.state.applications
        var newApps = []
        apps.forEach((a) => {
          if (a.id != app.id) { newApps.push(a) }
        })
        this.setState({
          applications: newApps
        })
      }
      else {
        alert(data.message)
      }
    })
  }

  _reject(app){
    return $.ajax({
      method: 'POST',
      url: '/api/Apply/reject',
      data: app
    })
  }


  render() {
    return (
      <div>
        {
          this.state.applications.map((app) => {
            return (
              <div id={app.id} class="col-md-3 application">
                <p>{app.name}</p>
                <p>{app.email}</p>
                <p>{app.channel_url}</p>
                <p>{app.referral}</p>
                <p>{app.createdAt}</p>
                <p>{app.message}</p>
                <button onClick={this.approve.bind(this, app)}>Approve</button>
                <button onClick={this.reject.bind(this, app)}>Reject</button>
              </div>
            )
          })
        }
      </div>
    )
  }
}
