import { memo } from 'react'
import CrmMenuEntity from './crm-menu-entity'

const CrmMenu = ((props) => {
  var { selectedEntity, toggleEntity, _this } = props
  return (
    <>
      <div className={`crm-toggle-entity-container ${selectedEntity}`}>
        <CrmMenuEntity title="Brands" icon="building" entity="brands" slider={true} selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />
        {/*<CrmMenuEntity isDev={true} title="Contacts" icon="portrait" entity="contacts" selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />*/}
        <CrmMenuEntity title="Influencers" icon="hastag" entity="influencers" selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />
        <CrmMenuEntity title="Lists" icon="list-check" entity="lists" selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />
        <CrmMenuEntity title="Saved Filters" icon="search-alt" entity="saved" selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />
        <CrmMenuEntity title="Insights" icon="chart-network" entity="insights" selectedEntity={selectedEntity} toggleEntity={toggleEntity} _this={_this} />
      </div>
    </>
  )
})

export default memo(CrmMenu);
