import React from 'react';
import Globals from '../global-functions.js';
import BlinkingDot from './blinking-dot.jsx';

export default class AccountReferrals extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      referralShortcode: '',
      referrals: 0
    }
  }

  componentDidMount() {
    this.fetchReferralInfo();
  }

  fetchReferralInfo() {
    $.ajax({
      method: 'GET',
      url: '/api/me/referrals',
      data: {}
    }).done((data) => {
      this.setState({
        referralShortcode: data.referralShortcode,
        referrals: data.referrals
      })
    })
  }

  onChange(field, e){
    this.setState({
      [field]: e.target.value
    })
  }

  render() {
    var user = this.props.user

    return (
      <div className="connect-socials-container" id="socials">
        <div className="row row0" style={{marginBottom: '32px'}}>
          <div>
            <div className="row row0" style={{maxWidth: '700px'}}>
              {/*<h3 style={{margin: '20px 0 15px 0', padding: 0, fontSize: '20px', textTransform: 'initial'}}>
                <span>Referrals</span>
              </h3>*/}
              <h3 style={{ margin: '20px 0 32px 0', padding: 0, fontSize: '32px', textTransform: 'initial' }}>Referrals</h3>
              <p className="sub-text" style={{marginBottom: '25px'}}>
                Refer other creators to Weave and earn a percentage of their brand collaborations!
                {(this.state.referrals) ?
                  <span> So far, a total of <b> {this.state.referrals} </b> {this.state.referrals > 1 ? 'influencers' : 'influencer'} have signed up using your link.</span> :
                  <span> So far, no creators have signed up using your link. </span>
                }
              </p>

              <div className="row row0">
                <div className="module">
                  <div className="row row0">
                    <h4>Creator Referral Link</h4>
                    <p className="sub-text">Refer creators to sign up for Weave with the link below - you'll earn 10% of Weave's earnings from each referral.</p>
                    {this.state.referralShortcode ?
                      <input className="dark-input"
                             style={{width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}
                             onMouseUp={(event) => {
                               event.target.select();
                               document.execCommand('copy');
                             }}
                             value={'https://weavesocial.com?ref=' + this.state.referralShortcode}
                             onChange={(ev) => this.handleBrandTypeChange(ev.target.value)}>
                      </input> :
                      <p className="sub-text"> Loading your referral code... </p>

                    }
                  </div>

                  {/*<div className="row row0">
                    <h4>Brand Referral Link</h4>
                    <p className="sub-text">Refer brands to sign up for Weave with the link below - you'll earn 5% of Weave's platform fees from each brand.</p>
                    {this.state.referralShortcode ?
                      <input className="dark-input"
                             style={{width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}
                             onMouseUp={(event) => {
                               event.target.select();
                               document.execCommand('copy');
                             }}
                             value={'https://www.weavesocial.com/signup?ref=' + this.state.referralShortcode}
                             onChange={(ev) => this.handleBrandTypeChange(ev.target.value)}>
                      </input> :
                      <p className="sub-text"> Loading your referral code... </p>

                    }
                  </div>
                  <hr />*/}

                  {/*<div className="row row0">
                    {
                      this.state.referrals &&
                      this.state.referrals.map((r) => {
                        return (
                          <div>

                          </div>
                        )
                      })
                    }
                  </div>*/}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="module">
                    <p className="sub-text module-label">Earnings</p>
                    <p className="module-val" style={{fontFamily: 'Barlow', fontSize: '32px'}}>$0.00</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="module">
                    <p className="sub-text module-label">Referrals</p>
                    <p className="module-val" style={{fontFamily: 'Barlow', fontSize: '32px'}}>0</p>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    )
  }
}
