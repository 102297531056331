import { Component, memo } from 'react'
import Globals from '../../global-functions.js'
import Loader from "../loader";
import Icon from '../icon'

async function _get(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
async function _set(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }
function cleanVal(val){ if (!val) return val; return val.replace(/[#@]/g, ''); }
var checkSearch = ''

class SearchDropdown extends Component {

  constructor(props){
    super(props)

    this.state = {
      active: false,
      loading: false,
      val: '',
      results: []
    }
  }

  componentDidMount(){
    var { preload, staticList, type, index, filter, simple } = this.props
    if (preload) this.get()
    else if (staticList){ this.setState({ results: staticList }) }
    else if (simple){ this.setState({ val: filter[index].value }) }
  }

  async get(val){ var { endpoint, searchIndex, dataKey } = this.props; val = cleanVal(val);; var res = await _get(endpoint, { searchIndex, val }); this.setState({ results: res[dataKey || 'results'] || [], loading: false}) }
  async searchAutocomplete(val){ val = cleanVal(val); checkSearch = val; setTimeout(() => { if (checkSearch == val && val != '' && val.length > 1){ this.get(val); } }, 325) }
  async delayChangeFilter(val){ val = cleanVal(val); checkSearch = val; var { type, index, changeFilter } = this.props; setTimeout(() => { if (checkSearch == val && val != '' && val.length > 1){ changeFilter(type[index], val, 'simple'); } }, 750) }
  onChange(type, e){
    var { preload, disableDropdown, simple } = this.props
    var val = e.target.value;
    if (preload){  }
    else if (val.length > 1 && !disableDropdown) { this.searchAutocomplete(val); }
    else if (simple){ this.delayChangeFilter(val) }
    this.setState({ [type]: val })
  }
  onFocus(e){ this.setState({ active: true, loading: true }); }
  onBlur(e){ setTimeout(() => { this.setState({ active: false }) }, 150) }
  onEnter(e){ if (e.key === 'Enter' && this.state.active) { this.setState({ active: false }); var { preload, staticList, simple, field } = this.props; if (!preload && !staticList && !simple){ this.chooseFilter({ [field]: this.state.val }) } } }
  chooseFilter(o) { var { type, index, changeFilter } = this.props; changeFilter(type[index], o); this.setState({ val: '' }) }

  render(){
    var { active, val, results } = this.state,
        { type, placeholder, index, filter, changeFilter, simple, searchIndex, field, preload, precursor, showImage, allowNew, disableDropdown, inputStyle, excludeList, _this, __this } = this.props
    if (preload) { results = results.filter((r) => { if (r.name && r.name.toLowerCase().indexOf(val.toLowerCase()) > -1){ return r } }) }

    return (
      <>
      <div className="outreach-list-search">
        <div className="row row0">
          <input className="dark-input search-filter-input" value={val != '0' ? val : ''} placeholder={placeholder || 'Search...'} style={inputStyle||{}} onChange={this.onChange.bind(this, 'val')} onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)} onKeyDown={this.onEnter.bind(this)} />
        </div>
        { (active && !disableDropdown) &&
          <div className="row row0" style={{position: 'relative'}}>
            <div className="outreach-list-dropdown">
              <div className="outreach-list-dropdown-inner">
                <ul className="outreach-list-ul">
                  { allowNew && val.length > 0 &&
                    <li className="outreach-list-item" onClick={this.chooseFilter.bind(this, { id: 'addNew', name: val })}>
                      <div className="row row0">
                        <span>{`Create new list "${val}"`}</span>
                      </div>
                    </li>
                  }
                  { results.map((o) => {
                    var { name, id, imageUrl } = o, exclude
                    if (type == 'filterCountry') { imageUrl = Globals.iconCountry(id) }
                    if (excludeList) excludeList.forEach((e) => { if (e == id) exclude = true })
                    if (!exclude){
                      return (
                        <li key={o.id} className="outreach-list-item" onClick={this.chooseFilter.bind(this,  o)}>
                          <div className="row">
                            { showImage &&
                              <>
                                <div className="col-xs-2">
                                  <img src={imageUrl} style={{width: '100%', borderRadius: '4px', }} />
                                </div>
                                <div className="col-xs-10">
                                  <span>{precursor||''}{o[field] || name}</span>
                                </div>
                              </>
                            }
                            { !showImage &&
                              <div className="col-xs-12">
                                <span>{precursor||''}{o[field] || name}{o.ct ? (<span className="sub-text"> ({o.ct})</span>) : ''}</span>
                              </div>
                            }
                          </div>
                        </li>
                      )
                    }
                  }) }
                </ul>
              </div>
            </div>
          </div>
        }
      </div>
      </>
    )
  }
}

export default memo(SearchDropdown);
