import React from 'react';

export default class BrandTileGame extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var campaign = this.props.campaign
    var content = campaign.content ? campaign.content : {}
    var offer = this.props.offer
    var game = campaign.game ? campaign.game : false
    var gameImg = campaign.imageUrl ? campaign.imageUrl : offer.bountyImg
    if (game && game.box_art_url){
      gameImg = game.box_art_url.replace('{width}', '564').replace('{height}', '750')
    }
    var name = campaign.name ? campaign.name : offer.bountyTitle
    var bountyCategoryTags = offer && offer.bountyCategoryTags ? offer.bountyCategoryTags : []
    var bountyCredit = !isNaN(this.props.bountyCredit) ? this.props.bountyCredit : 1

    return (
      <div>
        {
          offer && offer.bounty_active &&
          <div className={`ribbon small-ribbon ribbon-top-right ${campaign.brandType}-ribbon`}><span>ACTIVE</span></div>
        }
        {/*<div className="brand-category">
          {campaign.brandType}
        </div>*/}
        <div className="brand-game">
          <div className="brand-image">
            <div className="brand-image-spread" style={{backgroundImage: `url(${gameImg})`}}>
              <div className="brand-image-tiles" style={{display: 'none'}}>
                <div className="brand-image-tile">🔗 $0.15</div>
              </div>
            </div>
          </div>
          <div className="brand-meta">
            <h3 style={{margin: 0}}>
              <span>{name}</span>
              {/*<span style={{float: 'right', fontSize: '14px', fontWeight: 400}}>{bountyCredit ? bountyCredit : 1} credit</span>*/}
            </h3>
            <p className="sub-text">{offer.offerTagline}</p>
            <div className="row row0 bounty-tile-categories">
              <div className="bounty-tile-categories-container">
                {
                  bountyCategoryTags.map((c) => {
                    return <div className="bounty-tile-category">{c}</div>
                  })
                }
              </div>
            </div>
            {/*<div className="row" style={{marginTop: '0'}}>
              <div className="col-xs-6">
                <div style={{fontFamily: 'Barlow', fontWeight: '700', fontSize: '24px', marginBottom: '3px'}}>
                  ${content.proposedOffer ? content.proposedOffer : 0}
                </div>
              </div>
              <div className="col-xs-6" style={{paddingLeft: 0}}>
                <div className="bounty-countdown" style={{textAlign: 'right'}}>
                  <div className="countdown-day">
                    <p className="stream-stat" style={{fontSize: '18px', fontWeight: '600', marginTop: '3px', lineHeight: '22px', whiteSpace: 'nowrap'}}>
                      {moment(campaign.campaign_window).format('MMM Do')}
                    </p>
                    <p className="stream-stat stream-stat-sub sub-text">
                    {
                      moment(campaign.campaign_window).format('M/D') != moment(campaign.campaign_window_end).format('M/D') &&
                      <span>{moment(campaign.campaign_window).format('M/D')} - {moment(campaign.campaign_window_end).format('M/D')}</span>
                    }
                    {
                      moment(campaign.campaign_window).format('M/D') == moment(campaign.campaign_window_end).format('M/D') &&
                      <span>{moment(campaign.campaign_window).format('H:mm')} - {moment(campaign.campaign_window_end).format('H:mm')} PST</span>
                    }
                    </p>
                  </div>
                </div>
              </div>
            </div>*/}

            {/*<div className="game-live-nums" style={{display: 'none'}}>
              <div className="game-live-nums-container">
                <div className="game-live-num">
                  <img src="/images/twitch-logo-white.svg" />
                  <span>{Globals.convertNumberFormat(campaign.game ? campaign.game.viewers : Math.floor(Math.random() * 99999))}</span>
                </div>
                <div className="game-live-num">
                  <img src="/images/mixer-merge-white.svg" />
                  <span>{Globals.convertNumberFormat(campaign.mixerGame && campaign.mixerGame.viewers ? campaign.mixerGame.viewers : Math.floor(Math.random() * 99999))}</span>
                </div>
              </div>
            </div>*/}
            <p>{
              campaign.categories &&
              campaign.categories.split(',').map((c) => {
                return (
                  <span className="brand-tag">{c}</span>
                )
              })
            }</p>
          </div>
        </div>
      </div>
    )
  }
}
