import React from 'react';
import VideoTile from './video-tile.jsx';
import VideoTilePrimary from './video-tile-primary.jsx';
import Loader from '../loader.jsx';
import ShowMoreVideos from './show-more-videos.jsx';

export default class YouTubeChooser extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      videos: false,
      showMoreVideosActive: null
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
  }

  emptyFunction(){

  }

  componentWillUnmount(){

  }

  showMoreVideos(){
    this.setState({
      showMoreVideosActive: true
    })
  }

  hideMoreVideos(){
    this.setState({
      showMoreVideosActive: false
    })
  }

  render() {

    let showMoreVideos = ''
    if (this.state.showMoreVideosActive){
      showMoreVideos = (
        <ShowMoreVideos
          videos={this.state.videos}
          hideMoreVideos={this.hideMoreVideos}
          chooseVideo={this.props.chooseVideo}
          this={this.props.this}
          _this={this}
        />
      )
    }

    let module = ''
    if (this.props.video){
      module = (
        // <VideoTile key={this.props.video.id} video={this.props.video} this={this.props.this} chooseVideo={this.emptyFunction} />
        <VideoTilePrimary key={this.props.video.id} video={this.props.video} this={this.props.this} chooseVideo={this.emptyFunction} />
      )
    }
    else if (!this.state.videos){ module = (<Loader active="true" />) }
    else if (this.state.videos.length == 0) { module = <p className="placeholder">No YouTube videos were able to be loaded from your account. Chat us if you feel this is an error.</p> }
    else {
      module = (
        this.state.videos4.map((video) => {
          return (
            <VideoTile key={video.id} video={video} chooseVideo={this.props.chooseVideo} this={this.props.this} />
          )
        })
      )
    }

    return (
      <div>
        <div className="row" style={{position: 'relative', minHeight: '110px', margin: '25px 10px 0 0'}}>
          {module}
        </div>
        <p onClick={this.showMoreVideos.bind(this)} className={ (!this.state.videos || this.state.videos.length == 0 || this.props.video) ? 'hidden' : '' } style={{textAlign: 'center', margin: '15px 0 0 0', cursor: 'pointer', fontWeight: '600'}}>See More Videos</p>
        {showMoreVideos}
      </div>
    )
  }
}
