import { createAction } from 'typesafe-actions';
import { ActionType } from 'typesafe-actions';
import { GetAddressAnonymousRequest, GetAddressAnonymousResponse, GetAddressAnonymousFailed } from '../models/addresses.models';

export const addressActionCreators = {
  getPrimaryAddressAnonymousAction: createAction('addressPrimaryAnonymous/GET')<GetAddressAnonymousRequest>(),
  getPrimaryAddressAnonymousSuccessAction: createAction('addressPrimaryAnonymous/GET/success')<GetAddressAnonymousResponse>(),
  getPrimaryAddressAnonymousFailAction: createAction('addressPrimaryAnonymous/GET/fail')<GetAddressAnonymousFailed>()
};

export type GetPrimaryAddressAnonymousAction = ActionType<
  typeof addressActionCreators.getPrimaryAddressAnonymousAction
  >;
export type GetPrimaryAddressAnonymousSuccessAction = ActionType<
  typeof addressActionCreators.getPrimaryAddressAnonymousSuccessAction
  >;
export type GetPrimaryAddressAnonymousFailAction = ActionType<
  typeof addressActionCreators.getPrimaryAddressAnonymousFailAction
  >;


export type AddressActions = ActionType<typeof addressActionCreators>;
