import { all, call, put, takeLatest } from 'typed-redux-saga';
import {
  GetBountyProductsAction,
  bountyActionCreators,
  GetSelectedProductAction,
  SelectProductAction,
} from '../actions/bounty.actions';
import { fetchBountyProducts, fetchSelectedProduct, selectProduct } from '../../services/product-service';

function* getBountyProductsSaga(action: GetBountyProductsAction) {
  try {
    const bountyProductsResponse = yield* call(fetchBountyProducts, action.payload.offerId);
    return yield* put(bountyActionCreators.getBountyProductsSuccessAction(bountyProductsResponse));
  } catch (e) {
    yield put(bountyActionCreators.getBountyProductsFailAction(e));
  }
}

function* getSelectedProductSaga(action: GetSelectedProductAction) {
  try {
    const selectedProductsResponse = yield* call(fetchSelectedProduct, action.payload.contentId);
    return yield* put(bountyActionCreators.getSelectedProductSuccessAction(selectedProductsResponse));
  } catch (e) {
    yield put(bountyActionCreators.getBountyProductsFailAction(e));
  }
}

function* selectProductSaga(action: SelectProductAction) {
  try {
    const selectProductsResponse = yield* call(selectProduct, action.payload);
    return yield* all([
      put(bountyActionCreators.selectProductSuccessAction(selectProductsResponse)),
      put(bountyActionCreators.getSelectedProductAction({contentId: action.payload.contentId}))
    ]);
  } catch (e) {
    yield put(bountyActionCreators.selectProductFailAction(e));
  }
}

export function* bountySagas() {
  yield all([
    takeLatest('bountyProducts/GET', getBountyProductsSaga),
    takeLatest('selectedProduct/GET', getSelectedProductSaga),
    takeLatest('product/SELECT', selectProductSaga),
  ]);
}
