import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { VerifyPaypalEmailComponent } from './homepage/verify-paypal-email'
// import OnboardYTVerified from './onboard/onboard-yt-verified.jsx'
// import OnboardEmail from './onboard/onboard-email.jsx'
import OnboardYTApproved from './onboard/onboard-yt-approved.jsx';
import OnboardEmail from './onboard/onboard-email.jsx';
import OnboardEmailSent from './onboard/onboard-email-sent.jsx';
import VerifyEmail from './onboard/verify-email.jsx';
import OnboardPassword from './onboard/onboard-password.jsx';
import OnboardUsername from './onboard/onboard-username.jsx';
import OnboardConnect from './onboard/onboard-connect.jsx';
import OnboardIntegrations from './onboard/onboard-integrations.jsx';
import OnboardYTSignedIn from './onboard/youtube-signed-in.jsx';
import YouTubeRedirect from './youtube-redirect.jsx';
import TwitchRedirect from './twitch-redirect.jsx';
import InstagramRedirect from './instagram-redirect.jsx';
// import GmailRedirect from './gmail-redirect.jsx';

export default class Onboarding extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    // window.Intercom('boot', {
    //   app_id: 'rm4ja52n'
    // });
  }

  render() {
    return (
      <main>
        <Switch>
          {/*<Route path='/onboard-campaign-invited' component={OnboardCampaignInvited}/>*/}
          <Route path='/onboard-yt-approved' component={OnboardYTApproved}/>
          <Route path='/redirect/youtube' component={YouTubeRedirect}/>
          <Route path='/redirect/twitch' component={TwitchRedirect}/>
          <Route path='/redirect/instagram' component={InstagramRedirect}/>
          <Route path='/redirect/gmail' component={GmailRedirect}/>
          <Route path='/onboard-email' component={OnboardEmail}/>
          <Route path='/onboard-email-sent' component={OnboardEmailSent}/>
          <Route path='/verify-email' component={VerifyEmail}/>
          <Route path='/onboard-password' component={OnboardPassword}/>
          <Route path='/onboard-username' component={OnboardUsername}/>
          <Route path='/onboard-connect' component={OnboardConnect}/>
          <Route path='/onboard-integrations' component={OnboardIntegrations}/>
          <Route path='/signedin' component={OnboardYTSignedIn}/>
          <Route path='/verify-paypal-token' component={VerifyPaypalEmailComponent}/>
        </Switch>
      </main>
    )
  }
}
