import React from 'react';
import TaxInfoHeader from './taxinfo-header.jsx'
import TaxInfoInput from './taxinfo-input.jsx'

export default class TaxInfoDetails extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { inputDetails } = this.props
    var { firstName, lastName, businessName, ein, ssn, businessType } = inputDetails

    return (
      <div>
        <TaxInfoHeader
          title={`What are your business details?`}
          description={``}
        />
        <div className="taxinfo-popup-body">
          { businessType == 'Sole-Proprietorship/Individual' &&
            <>
              <div className="row">
                <TaxInfoInput label="Legal First Name" type="firstName" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
                <TaxInfoInput label="Legal Last Name" type="lastName" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
              </div>
              <div className="row">
                <TaxInfoInput label="Business Name (Optional)" type="businessName" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
                <TaxInfoInput label="Social Security Number (SSN)" type="ssn" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
              </div>
            </>
          }
          { businessType != 'Sole-Proprietorship/Individual' &&
            <>
              <div className="row">
                <TaxInfoInput label="Business Name" type="businessName" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
                <TaxInfoInput label="Employer Identification Number (EIN)" type="ein" inputDetails={inputDetails} onChange={this.props.onChange.bind(this.props.this)} this={this.props.this} />
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}
