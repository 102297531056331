import React from 'react';
import Globals from '../../global-functions.js';
import ShippingTrackingStage from './shipping-tracking-stage.jsx';
import ShippingTrackingLine from './shipping-tracking-line.jsx';

export default class ShippingTrackingBar extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var props = this.props, status = this.props.status

    var config = {}
    for (var i = 1; i <= this.props.stepsCt; i++) {
      if (i < this.props.stepNum){ config[`s${i}`] = 'visited'; config[`l${i-1}`] = 'pending'; }
      else if (i == this.props.stepNum){ config[`s${i}`] = 'pending active'; config[`l${i-1}`] = 'pending'; }
      else { config[`s${i}`] = 'inactive'; config[`l${i-1}`] = 'inactive'; }
    }

    let stageInt = []
    for (var i = 1; i <= this.props.stepsCt; i++) {
      if (i > 1) { stageInt.push(<ShippingTrackingLine progress={config[`l${i-1}`]} />) }
      stageInt.push(<ShippingTrackingStage icon={(config[`s${i}`] != 'finished' && config[`s${i}`] != 'visited') ? this.props.icon : 'check'} progress={config[`s${i}`]} />)
    }

    return (
      <div>
        <div className={`shipping-tracking shipping-tracking-product ${this.props.animated ? 'animated' : ''} ${this.props.size ? this.props.size : 'small'}`}>
          <div className={`row row0 shipping-stages stages-${this.props.stepsCt}`}>
            <div className="shipping-stages-inner">
              {stageInt}
              {/*<ShippingTrackingStage icon={`loupe`} progress={config.s1} />
              <ShippingTrackingLine progress={config.l1} />
              <ShippingTrackingStage icon={`camera`} progress={config.s2} />
              <ShippingTrackingLine progress={config.l2} />
              <ShippingTrackingStage icon={`ok`} progress={config.s3} />*/}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
