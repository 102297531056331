import { ActionType, createAction } from 'typesafe-actions';
import { HasAccountRequest, HasAccountResponse, HasAccountFailed } from '../models/invite-user.models';

export const inviteUserActionCreators = {
  hasAccountAction: createAction('inviteUserHasAccount/GET')<HasAccountRequest>(),
  hasAccountSuccessAction: createAction('inviteUserHasAccount/GET/success')<HasAccountResponse>(),
  hasAccountFailAction: createAction('inviteUserHasAccount/GET/fail')<HasAccountFailed>(),
};

export type HasAccountAction = ActionType<
  typeof inviteUserActionCreators.hasAccountAction
  >;
export type HasAccountSuccessAction = ActionType<
  typeof inviteUserActionCreators.hasAccountFailAction
  >;
export type HasAccountFailAction = ActionType<
  typeof inviteUserActionCreators.hasAccountFailAction
  >;

export type InviteUserActions = ActionType<typeof inviteUserActionCreators>;
