import React from 'react';
import moment from 'moment';
import Globals from '../../global-functions.js';
import Loader from '../loader';
import KeywordsTable from './keywords-table'


export default class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Globals.getUser(),
    };
  }

  // s.push(getTopHashtags(params))
  // s.push(getTopMentionHashtags(params))
  // s.push(getTopMentions(params))
  // s.push(getTopMentioned(params))
  // s.push(getTopAds(params))
  // s.push(getTopMentionAds(params))
  // s.push(getTopSimilarBrands(params))

  componentDidMount() {}

  render() {
    // var { keywords, sortFilter, loading, keywordsFilter } = this.props, user = this.state.user;
    // var selectedActive; if (keywordsFilter.length > 0) { selectedActive = true }

    return (
      <div>
        <div className="row row0">
          
        </div>
        {/*<KeywordsTable _this={this} keywords={keywords} loading={loading} sortFilter={sortFilter} keywordsFilter={keywordsFilter} chooseSort={this.chooseSort} selectBrand={this.selectBrand} selectKeyword={this.selectKeyword} />*/}
      </div>
    );
  }
}
