import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
var client = require('braintree-web/client');
var hostedFields = require('braintree-web/hosted-fields');
var usBankAccount = require('braintree-web/us-bank-account');
const BRAINTREE_AUTH = 'Basic aDN3emo5czhoNzh0M2hoZDphZjMxZTYxMTU0OWI3NmEyZDg5YTFmNjE5MTIyNjk2OA=='
const BRAINTREE_TOKEN = 'sandbox_w39z5jkn_7j9b3ghzq253gtgy'


export default class InvoiceBraintreeACH extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    this.initializeBraintree()
  }

  submitPayment(invoiceId){ this._submitPayment(invoiceId).done((response) => { this.setState({ ...response, invoiceLoaded: true }) }) }
  _submitPayment(invoiceId){ return $.ajax({ method: 'POST', url: '/api/Braintree/submitPayment', data: { id: invoiceId } }) }

  initializeBraintree(){
    var form = document.querySelector('#my-sample-form');
    var submit = document.querySelector('input[type="submit"]');

    client.create({ authorization: BRAINTREE_TOKEN }, (err, clientInstance) => {
      usBankAccount.create({
      client: clientInstance
    }, (usBankAccountErr, usBankAccountInstance) => {
        if (usBankAccountErr) {
          console.error('There was an error creating the USBankAccount instance.');
          throw usBankAccountErr;
        }

        braintree.dataCollector.create({ client: usBankAccountInstance }, (err, dataCollectorInstance) => {
          if (err) { return; }
          var deviceData = dataCollectorInstance.deviceData;
          hostedFields.create({
            client: usBankAccountInstance,
            styles: {
              'input': { 'font-size': '14px' },
              'input.invalid': { 'color': 'red' },
              'input.valid': { 'color': 'green' }
            },
            fields: {
              number: { container: '#account-number', placeholder: '4111 1111 1111 1111' },
              cvv: { container: '#routing-number', placeholder: '123' },
              // expirationDate: { container: '#expiration-date', placeholder: '10/2022' }
            }
          }, (hostedFieldsErr, hostedFieldsInstance) => {
            if (hostedFieldsErr) { console.error(hostedFieldsErr); return; }
            submit.removeAttribute('disabled');
            form.addEventListener('submit', (event) => {
              event.preventDefault();
              hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
                if (tokenizeErr) { console.error(tokenizeErr); return; }
                console.log('Got a nonce: ' + payload.nonce);
              });
            }, false);
          });
        });
      });

    });
  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    var { invoice } = this.props

    return (
      <div className="invoice-braintree">
        <div className="row row0">
        <form action="/api/Braintree/payment" id="my-sample-form" method="post">
          <label htmlFor="account-number">Account Number</label>
          <div id="account-number" className="dark-input"></div>

          <label htmlFor="routing-number">Routing Number</label>
          <div id="routing-number" className="dark-input"></div>

          {/*<label htmlFor="expiration-date">Expiration Date</label>
          <div id="expiration-date" className="dark-input"></div>
*/}
          <input type="submit" value="Pay" disabled />
        </form>
        </div>
      </div>
    )
  }
}
