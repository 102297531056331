import React, { useEffect, useState } from 'react';
import Loader from '../loader';
declare const $: any;

export const VerifyPaypalEmailComponent: React.FC<any> = (
  props,
): JSX.Element => {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!token && !!location.search) {
      const urlToken = location.search.split('id=')[1]?.split('&')[0];
      if (urlToken) {
        verifyToken(urlToken).catch(
          ({ responseJSON }: any) => {
            setError(responseJSON?.message);
            setLoading(false);
          }
        ).done((response: any) => {
          if (response?.success) {
            setToken(urlToken);
            setLoading(false);
            location.href = '/account'
          }
        });
      } else {
        setError('Missing token');
        setLoading(false);
      }
    }
  }, [])

  const verifyToken = (token: string) => {
    return $.ajax({
      method: 'GET',
      url: '/api/me/verifyPaypalEmail',
      data: {
        token,
      }
    })
  }

  return (
    <div>
      <div className="onboard-page">
        <div className="onboard-container interact-section">
          <h3 style={{marginTop: 0}}>Verifying PayPal Email</h3>
          {
            loading && <div style={{height: '200px'}}>
              <Loader active={loading}/>
            </div>
          }

          {
            error &&
            <div>
              <b><p className={'error-msg'} style={{marginLeft: 0, fontSize: '16px'}}>{error}</p></b>

              <a href={'/dashboard'} style={{textDecoration: 'underline'}}>Back to Dashboard</a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
