import { memo } from "react";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainCell from './crm-table-main-cell'
import EmptyMessage from '../empty-message'

const ContactsTable = ((props) => {
  var { brands, chooseSort, sortFilter, selectEmployee, chooseContact, loading, _this } = props,
  headerSelectedActive = props.selectedActive

  return (
    <div>
      <div className="crm-table" style={{height: '525px', width: '100%', borderRadius: 0, boxShadow: 'none', border: 'none'}}>
        <div className="crm-table-header" style={{borderTop: '1px solid #eeeeee'}}>
          <div className={`row row0 crm-row ${headerSelectedActive ? "selected" : ""}`}>
            <div className="crm-td crm-td-xs crm-td-img">
              <input type="checkbox" style={{ display: "block" }} onClick={selectEmployee.bind(_this, "all")} checked={headerSelectedActive} />
            </div>
            <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
            <CRMTableHeaaderCell label="Title" title="Title" size="crm-td-lg" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
            <CRMTableHeaaderCell label="Primary Contact" title="Make Primary" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
            <CRMTableHeaaderCell label="Location" title="Location" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
            <CRMTableHeaaderCell label="Company" title="Company" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
          </div>
        </div>

        <div className="crm-table-main" style={{height: '480px'}}>
          { !loading && brands.map((com) => {
            var { contacts, company } = com
            return (
              contacts.map((c) => {
                var { id, name, title, headline, email, email_status, city, state, imageUrl, selectedActive, selected, recommendedContact } = c,
                    subtext = false, icon = false
                if (selected){ subtext = 'Primary email contact' } else if (recommendedContact){ subtext = 'Recommended email contact' }
                if (selected){ icon = 'check' } else if (recommendedContact){ icon = 'bulb' }
                return (
                  <div key={'contactsTable-'+id} className={`row row0 crm-row crm-row-influencer ${selectedActive ? "selected" : ""}`}>
                    <div className="">
                      <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={imageUrl} initial={''} hoverFunc={selectEmployee.bind(_this, c)} _this={_this} />
                      <CRMTableMainCell meta={c} type={'main-contact'} subtext={subtext} icon={icon} size="crm-td-md" />
                      <CRMTableMainCell title={title} subtext={headline} size="crm-td-lg" type="title-subtext" />
                      <CRMTableMainCell type="email_actions" hoverFunc={chooseContact} dataObject={c} emailStatus={email_status} size="crm-td-sm" _this={_this} />
                      <CRMTableMainCell title={city || state ? `${city+',' || ''} ${state || ''}` : ``} size="crm-td-md" _this={_this} />
                      <CRMTableMainCell title={company.name} image={company.imageUrl || company.logoUrl} size="crm-td-md" />
                    </div>
                  </div>
                );
              }) )
            })
          }
          {brands.length == 0 && !loading && <EmptyMessage title={'No Contacts Found'} description={'Adjust your filters to widen your brand search'} />}
          {loading && <Loader active={true} />}
        </div>
      </div>
    </div>
  );
})

export default memo(ContactsTable);
