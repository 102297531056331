import React from 'react';
import Globals from '../../global-functions.js'
import TaxInfoAddress from './taxinfo-address.jsx'
import TaxInfoDetails from './taxinfo-details.jsx'
import TaxInfoExplanation from './taxinfo-explanation.jsx'
import TaxInfoSubmit from './taxinfo-submit.jsx'
import TaxInfoType from './taxinfo-type.jsx'
import TaxInfoFooter from './taxinfo-footer.jsx'

function checkLength(val, type){
  if (type == 'taxid') { val = val.replace(/[^0-9]+/g, ''); return val.length == 9 ? true : false }
  if (type == 'zip') { val = val.replace(/[^0-9]+/g, ''); return val.length >= 5 ? true : false }
  if (typeof val == 'string') { return val && val.length > 0 ? true : false }
  else if (typeof val == 'object') { var valid = true; val.forEach((v) => { if (!(v && v.length > 0)){ valid = false } }); return valid }
  else { return false }
}

export default class TaxInfo extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      step: 1,
      businessType: 'Sole-Proprietorship/Individual',
      businessName: '',
      ein: '',
      ssn: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: ''
    }
  }

  // UNSAFE_componentWillMount(){ document.addEventListener('mousedown', this.handleClosePopup.bind(this), false) }
  // componentWillUnmount(){ document.removeEventListener('mousedown', this.handleClosePopup.bind(this), false) }
  // handleClosePopup(e){
  //   if (this.node && this.node.contains(e.target) && !$(e.target).hasClass('taxinfo-close')){ return }
  //   // else if (this.node && this.node.contains(e.target)){ return }
  //   else { this.props.toggleTaxInfo() }
  // }

  toggleTaxInfo(e){
    if ($(e.target).hasClass('taxinfo-container')){ this.props.toggleTaxInfo() }
  }

  componentDidMount(){

  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }
  saveTaxInfo(){
    var { firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip } = this.state
    var obj = { firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip }
    this._saveTaxInfo(obj).done((response) => { this.props.toggleTaxInfo()()} )
  }
  _saveTaxInfo(obj){ return $.ajax({ method: 'POST', url: '/api/Invoice/saveTaxInfo', data: obj }) }
  moveStep(num){ this.setState({ step: this.state.step + num }) }
  checkValidForm(num){
    var { firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip } = this.state
    if (num == 3){
      if (businessType == 'Sole-Proprietorship/Individual'){ return checkLength([firstName,lastName]) && checkLength(ssn, 'taxid') }
      else { return checkLength([businessName]) && checkLength(ein, 'taxid') }
    }
    else if (num == 4){ return checkLength([address,city,state]) && checkLength(zip, 'zip') }
    else { return true }
  }



  render() {
    var page
    var { step, firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip } = this.state
    var inputDetails = { firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip }
    var valid = this.checkValidForm(step)

    switch(step){
      case 1: page = (<TaxInfoExplanation this={this} />); break;
      case 2: page = (<TaxInfoType this={this} inputDetails={inputDetails} onChange={this.onChange.bind(this)} />); break;
      case 3: page = (<TaxInfoDetails this={this} inputDetails={inputDetails} onChange={this.onChange.bind(this)} />); break;
      case 4: page = (<TaxInfoAddress this={this} inputDetails={inputDetails} onChange={this.onChange.bind(this)} />); break;
      case 5: page = (<TaxInfoSubmit this={this} inputDetails={inputDetails} saveTaxInfo={this.saveTaxInfo.bind(this)} />); break;
      default: break;
    }

    console.log('step', step)

    return (
      <div className="taxinfo" onClick={this.toggleTaxInfo.bind(this)}>
        <div className="taxinfo-container">
          <div className="taxinfo-popup">
            <div className="taxinfo-popup-inner">
              <div className="taxinfo-close" onClick={this.props.toggleTaxInfo.bind(this.props.this)}><img src={Globals.iconPack('cross')} /></div>
              <div className="taxinfo-popup-content">{page}</div>
              <TaxInfoFooter this={this} step={step} valid={valid} moveStep={this.moveStep.bind(this)} saveTaxInfo={this.saveTaxInfo.bind(this)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
