import { memo } from 'react'
import Icon from '../icon'

const CrmActionBtn = ((props) => {
  var { type, icon, togglePopup, _this } = props
  return (
    <>
      <div className="crm-action-btn" onClick={togglePopup.bind(_this, type)}>
        <Icon iconPack={icon} width="16px" preventColor={true} />
      </div>
    </>
  )
})

export default memo(CrmActionBtn);
