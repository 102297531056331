import React from 'react'
import Globals from '../../global-functions.js'
import moment from 'moment'

export default class InvoiceSettings extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      collapsed: false,
      messages: [],
      text: '',
      dripId: '99',
      dripCampaigns: []
    }
  }

  componentDidMount(){
    // this.getOutreachSettings()
    // this.getConnectedGmail()
  }

  updateFilter(type){ }

  gmailLogin(){ $.ajax({ method: 'GET', url: '/api/YouTube/gmailAuthUrl', data: {} }).done((data) => { location.replace(data) }) }
  listLabels(){ $.ajax({ method: 'GET', url: '/api/Gmail/listLabels', data: {} }).done((data) => { console.log(data) }) }
  sendEmail(){ $.ajax({ method: 'POST', url: '/api/Gmail/sendEmail', data: {} }).done((data) => { console.log(data) }) }
  getConnectedGmail(){ this._getConnectedGmail().done((response) => { this.setState({ dripCampaigns: response }) }) }
  _getConnectedGmail(){ return $.ajax({ method: 'GET', url: '/api/Gmail/getConnectedGmail', data: {  } }) }
  createDripCampaign(){ this._createDripCampaign().done((response) => { location.replace(`/outreach/drip/${response}`) }) }
  _createDripCampaign(){ return $.ajax({ method: 'POST', url: '/api/Drip/create', data: {  } }) }

  render() {
    var _stages = [], dripCampaigns = this.state.dripCampaigns
    var sortFilter
    var { taxinfo } = this.state

    return (
      <div>
        <div className="mgmt-section" style={{padding: '25px'}}>
          <div className="mgmt-menu" style={{marginBottom: '25px'}}>
            <h3 style={{marginTop: 0}}>Invoice Settings</h3>
            <p className="sub-text" style={{margin: 0, textDecoration: 'underline'}}><a href="http://support.weavesocial.com/en/articles/5435087-create-influencer-reach-out-emails" target="_blank">Read tips how to maximize your outreach here.</a></p>
          </div>
          <div className={`mgmt-list ${this.state.collapsed ? 'collapsed' : ''} mgmt-menu-dark`}>
            <div className="row row0" style={{width: '700px'}}>
              <div className="module">
                <h3 style={{marginTop: 0}}>Manage Tax Info</h3>
                <p className="sub-text">Weave allows your Gmail email address to be connected so we can automate sending emails on your behalf to brands. Weave only sends emails from your account when you schedule brands for outreach.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
