import React from 'react';

export default class TaxInfoHeader extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { title, description } = this.props

    return (
      <div className="taxinfo-popup-header">
        <div className="row row0">
          <h3>{title}</h3>
          <p className="sub-text">{description}</p>
        </div>
      </div>
    )
  }
}
