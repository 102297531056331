import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer'
import { routerReducer } from 'react-router-redux';
import { invitationReducer } from './invitation.reducer';
import { brandCampaignReducer } from './brand-campaign.reducer';
import { bountyProductsReducer } from './bounty-products.reducer';
import { selectedProductReducer } from './selected-product.reducer';
import { selectProductReducer } from './select-product.reducer';
import { inviteUserReducer } from './invite-user.reducer';
import { contentObjectivesReducer } from './content-objectives.reducer';
import { primaryAddressAnonymousReducer } from './primary-address-anonymous.reducer';
import { getContentApprovalsAnonymousReducer } from './get-content-approvals-anonymous.reducer';
import { createContentApprovalAnonymousReducer } from './create-content-approval-anonymous.reducer';
import { updateContentForReviewReducer } from './update-content-for-review.reducer';

export const rootReducer = combineReducers({
  router: routerReducer,
  login: authReducer,
  invitation: invitationReducer,
  brandCampaign: brandCampaignReducer,
  bountyProducts: bountyProductsReducer,
  selectedProduct: selectedProductReducer,
  selectProduct: selectProductReducer,
  inviteUser: inviteUserReducer,
  contentObjectives: contentObjectivesReducer,
  contentApprovalsAnonymous: getContentApprovalsAnonymousReducer,
  createContentApprovalAnonymous: createContentApprovalAnonymousReducer,
  updateContentForReview: updateContentForReviewReducer,
  primaryAddressAnonymous: primaryAddressAnonymousReducer
});

export type RootState = ReturnType<typeof rootReducer>;
