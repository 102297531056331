import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import moment from 'moment'
import history from '../history.js';
import DatePicker from 'react-date-picker'
import IconPack from '../icon-pack.jsx';
import CreateInvoiceTitle from '../invoices/create-invoice-title.jsx'
import CreateInvoiceInput from '../invoices/create-invoice-input.jsx'

export default class CreateOutreach extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: moment().subtract('5', 'month').startOf('month').format('YYYY/M/D'),
      endDate: moment().format('YYYY/M/D'),
      incrementType: 'month',
      invoices: [],
      taxInfoOpen: false,
      campaigns: []
    }
  }

  componentDidMount(){
    // this.getAllInvoices()
  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }

  // getAllInvoices(){ this._getAllInvoices().done((response) => { this.setState(response) }) }
  // _getAllInvoices(){ return $.ajax({ method: 'GET', url: '/api/Invoice/getAll', data: {} }) }
  // createInvoice(){
  //   if (!this.state.taxinfoCompleted){ this.toggleTaxInfo() }
  //   else {
  //     this._createInvoice().done((response) => {
  //       if (response) { history.push(`/invoices/create/${response.invoice.id}`) }
  //     })
  //   }
  // }
  // _createInvoice(){ return $.ajax({ method: 'POST', url: '/api/Invoice/create', data: {} }) }
  //
  // toggleTaxInfo() { this.setState({ taxInfoOpen: !this.state.taxInfoOpen }) }

  render() {
    var { campaigns } = this.state

    return (
      <div>
        <div className="row row0" style={{marginBottom: '25px'}}>
          <Link to="/outreach">
            <IconPack icon="arrow-left" style={{width: '18px', marginTop: '-2px'}} />
            <span style={{marginLeft: '8px'}}><b>Go Back</b></span>
          </Link>
          <div className="row row0">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Create New Outreach</h3>
            <p className="sub-text">Fill out the details below to wet up your brand pitching outreach.</p>
          </div>
          {/*<div className="row row0">
            <a href={`/invoice/${id || invoiceId}`} target="_blank">
              <button className="btn-action btn-secondary" style={{margin: 0, float: 'left'}}>Preview Invoice</button>
            </a>
          </div>*/}
        </div>
        <div className="row row0" style={{width: '600px'}}>
          <div className="module">
            <CreateInvoiceTitle title="Outreach Details" subtext="Fill in details about the recipient of the client brand for your deal." />
            <CreateInvoiceInput this={this} title="Outreach Name" value={''} label="email" onChangeInput={this.onChange.bind(this)} />
          </div>
        </div>
      </div>
    )
  }
}
