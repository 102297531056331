import { Component, memo } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import Globals from '../../global-functions.js'
import Filter from './filter'
import MessageTemplateStats from './message-template-stats'
import Icon from '../icon'
import DynamicEmailValues from './dynamic-email-values'
import CreateMessageSchedule from './create-message-schedule'
import CreateMessageInput from './create-message-input'
import CreateMessagePreview from './create-message-preview'
import { Value } from 'slate'
import Plain from 'slate-plain-serializer'
import Html from 'slate-html-serializer'

class CreateMessage extends Component {

  constructor(props){
    super(props)
    this.state = {
      user: Globals.getUser(),
      collapsed: false,
      offerDropdown: [],
      messages: [],
      text: '',
      convo_id: this.props.convo_id,
      value: html.deserialize('<p></p>'),
      adjValue: html.deserialize('<p></p>'),
      subject: '',
      serializedValue: '',
      addSavedBtn: 'Save',
      getSavedBtn: 'Auto',
      saveDripBtn: 'Save Message',
      sentMessages: [],
      dripEmails: [],
      email: {},
      dripStage: {},
      daysActive: [{name:'Sun',active:false},{name:'Mon',active:true},{name:'Tue',active:true},{name:'Wed',active:true},{name:'Thur',active:true},{name:'Fri',active:true},{name:'Sat',active:false}],
      timeStart: '6',
      timeEnd: '9',
      dripStageStats: {sent: 0, opened: 0, clicked: 0, responded: 0, failed: 0},
      tab: 'message',
      active: false,
      chosenPlatform: 'instagram'
    }
  }

  async componentDidMount(){
    const { dripId, messageId } = this.props.match.params
    await this.setState({
      // value: html.deserialize(existingValue || ''),
      // serializedValue: existingValue,
      dripId,
      messageId
    })
    // this.getOfferList()
    this.getDripEmail(dripId, messageId)
    this.getDripStageStats(dripId, messageId)
    this.getDripStage(messageId)

    // var existingValue = localStorage.getItem(`editortext-${this.props.id}`)


  }

  async getDripStageStats(dripId, messageId){ var res = await this._getDripStageStats(dripId, messageId); this.setState({ dripStageStats: res }) }
  async _getDripStageStats(dripId, messageId){ return $.ajax({ method: 'GET', url: '/api/DripStage/getStats', data: { dripId: dripId || this.state.dripId, dripStageId: messageId || this.state.messageId, dripIddripStageId: messageId || this.state.messageId } }) }
  updateFilter(type){

  }

  toggleCollapse(){ this.setState({ collapsed: !this.state.collapsed }) }

  async selectFilter(_this, name, value, option){
    if (name == 'override') {
      // _this.state.campaigns.forEach((c, i) => { if (c == value){ Cookies.set('campaignFilterNum', parseInt(i)) } })
      // Cookies.set('campaignFilter', value)
      await _this.setState({
        [name]: !isNaN(value) ? parseInt(value) : value,
        [`${name}Num`]: option,
        offerId: option.id,
        override: option.id,
        selectedOption: option
      })
      _this.getOffer(option.id); _this.getInfluencers();
    }
    else {
      _this.setState({ [name]: value, skip: 0 })
      if (name == 'statusFilter') { Globals.updateUrlParams('status', value) }
      else if (name == 'sortFilter') { Globals.updateUrlParams('sort', value) }
      else if (name == 'classificationFilter') { Globals.updateUrlParams('classification', value) }
      else if (name == 'platformFilter') { Globals.updateUrlParams('platform', value) }
      setTimeout(() => { _this.getInfluencers() }, 100)
    }
  }

  async getOfferList(){
    var res = await this._getOfferList()
    this.setState({ offerDropdown: res })
    if (res && res[0]){
      this.setState({ override: res[0].id, selectedOption: res[0] })
      // setTimeout(() => {
      //   this.getInfluencers()
      // }, 25)
    }
  }

  async _getOfferList(){ return $.ajax({ method: 'GET', url: '/api/Offer/getList', data: { } }) }

  componentWillReceiveProps(props){
    if (props.id && this.props.id != props.id) {
      var existingValue = localStorage.getItem(`editortext-${props.id}`)
      this.setState({ value: html.deserialize(existingValue || ''), serializedValue: existingValue, convo_id: props.convo_id })
    }
  }

  async onChange({ value }) {
    if (value.document != this.state.value.document) {
      var serializedValue = html.serialize(value)
      localStorage.setItem(`editortext-${this.props.id}`, serializedValue)
      this.setState({ value, serializedValue })
      await Globals.wait(25); $('.chat-messages-container').css('padding-bottom', `${125 + ($('.chat-input').height() - 144)}px`)
    }
    else { this.setState({ value }) }
  }

  onChangeInput(type, e){ this.setState({ [`${type}`]: e.target.value }) }

  renderMark(props, editor, next) {
    switch (props.mark.type) {
      case 'bold': return <strong>{props.children}</strong>
      // Add our new mark renderers...
      case 'code': return <code>{props.children}</code>
      case 'italic': return <em>{props.children}</em>
      case 'strikethrough': return <del>{props.children}</del>
      case 'underline': return <u>{props.children}</u>
      default: return next()
    }
  }

  focusEditor(){
    $('#intercom-chat-editor').focus()
  }

  async getDripEmail(dripId, messageId){
    var res = await this._getDripEmail(dripId, messageId)
      var email = res
      var obj = {
        dripEmails: res,
        email: email,
        message: email.message,
        subject: email.subject,
        value: html.deserialize(email.message || ''),
        // adjValue: highlightDynamic(email.message || ''),
        serializedValue: email.message
      }
      if (email.daysActive) {
        obj.daysActive = this.state.daysActive
        obj.daysActive.forEach((d, i) => { if (email.daysActive.indexOf(i) > -1) { d.active = true } })
        obj.timeStart = email.timeStart
        obj.timeEnd = email.timeEnd
      }
      this.setState(obj)
  }

  async _getDripEmail(dripId, messageId){ return $.ajax({ method: 'GET', url: '/api/DripEmailTemplate/get', data: { dripId: dripId || this.state.dripId, messageId: messageId || this.state.messageId, type: 'drip' } }) }

  async getDripStage(id){ var res = await this._getDripStage(id); this.setState({ dripStage: res, active: !res.active || res.active == 'false' ? false : true }) }
  async _getDripStage(id){ return $.ajax({ method: 'GET', url: '/api/DripStage/getDrip', data: { id: id } }) }

  async updateDripEmail(){
    this.setState({ saveDripBtn: 'Saving Message...' })
    await this._updateDripEmail(); this.setState({ saveDripBtn: 'Save Message' })
  }

  async _updateDripEmail(){
    var s = this.state
    return $.ajax({
      method: 'POST',
    	url: '/api/DripEmailTemplate/update',
      data: {
        id: s.messageId,
        subject: s.subject,
        message: html.serialize(s.value),
        daysActive: s.daysActive,
        timeStart: s.timeStart,
        timeEnd: s.timeEnd,
        type: 'drip',
        active: s.active
      }
    })
  }

  copyValue(id){
    $(`#dynamic-value-${id}`).select()
    document.execCommand('copy')
  }

  selectTime(type, e){ this.setState({ [type]: e.target.value }) }
  selectDay(day, e){
    // e.preventDefault()
    var days = this.state.daysActive
    days.forEach((d) => { if (day.name == d.name) { d.active = !d.active } })
    this.setState({ daysActive: days })
  }

  chooseTab(type){ this.setState({ tab: type }) }
  toggleStageActive(){ this.setState({ active: !this.state.active }) }

  // checkUpdates(){
  //   var original = this.state.originalState
  //   if (this.state.daysActive)
  //   daysActive:
  //   timeStart: '6',
  //   timeEnd: '9',
  // }

  choosePlatform(chosenPlatform){ this.setState({ chosenPlatform }) }

  render() {
    var _stages = [], { value, adjValue, subject, dripEmails, email, dripStage, user, dripStageStats, chosenPlatform, tab, daysActive } = this.state, drip = dripStage && dripStage.drip ? dripStage.drip : {}
    var { socialLabels, choosePlatform, copyValue, onChange, renderMark, focusEditor, onChangeInput, updateDripEmail, toggleStageActive, selectDay, selectTime, chooseTab } = this
    var timeStart = [], timeEnd = []
    for (var i = 0; i < 24; i++){
      var temp = i == 0 ? '12:00AM' : '12:00PM'
      if (i > 0 && i != 12) { temp = i < 13 ? temp = `${i}:00AM` : `${i-12}:00PM` }
      timeStart.push(<option selected={this.state.timeStart == i ? 'selected' : ''} value={i}>{temp}</option>)
      timeEnd.push(<option selected={this.state.timeEnd == i ? 'selected' : ''} value={i}>{temp}</option>)
    }

    var serializedValue = html.serialize(value)

    // console.log('value', this.state.value)
    // console.log('serialize', html.serialize(this.state.value))

    var socialLabels = ['Instagram','TikTok','YouTube','Twitch','Facebook','Twitter']

    return (
      <div>
        <div className="mgmt-section">
            <div className="message-template" style={{padding: '25px 25px 200px 25px'}}>
              <div className="">
                <div className="row">
                  <div className="col-xs-6">
                    <Link to={`/outreach/drip/${this.state.dripId}`}>
                      <label className="sub-text" style={{cursor: 'pointer'}}>Back to {drip.title || 'Drip'} Campaign</label>
                    </Link>
                    <h3>{dripStage.title}</h3>
                  </div>
                  <div className="col-xs-6">
                    <button className="btn-action btn-primary btn-right" onClick={updateDripEmail.bind(this)}>{this.state.saveDripBtn}</button>
                    <button className="btn-action btn-secondary btn-right" onClick={toggleStageActive.bind(this)}>
                      { this.state.active ?
                        <span><img src={Globals.iconPack('pause')} style={{height: '14px', margin: '-2px 4px 0 0', opacity: '0.75' }} /> Pause Stage</span> :
                        <span><img src={Globals.iconPack('play')} style={{height: '14px', margin: '-2px 4px 0 0', opacity: '0.75' }} /> Set Stage Active</span>
                      }
                    </button>
                  </div>
                </div>
                <MessageTemplateStats dripStageStats={dripStageStats} tab={tab} chooseTab={chooseTab.bind(this)} _this={this} />

                <div className="row" style={{marginBottom: '25px'}}>
                  <div className="col-md-9" style={{maxWidth: '1000px'}}>
                    <div className="col-md-3">
                      <div className="row">
                        <h3>Dynamic Fields</h3>
                        <p className="sub-text">
                          These fields populate info when emailed - <b>click field to copy</b>
                        </p>
                        <DynamicEmailValues socialLabels={socialLabels} chosenPlatform={chosenPlatform} choosePlatform={choosePlatform.bind(this)} copyValue={copyValue.bind(this)} _this={this} />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row row0">
                        <h3>Create your email</h3>
                        <p className="sub-text">
                          This email will be sent to influencers you select. You can add dynamic fields into the email to populate the influencer's name or other personal fields.
                        </p>
                      </div>

                      <div className="intercom-duplicate intercom-template" style={{height: 'initial'}}>
                        <input className="dark-input templates-subject chat-input" placeholder="Add Email Subject..." style={{width: '100%'}} value={this.state.subject} onChange={onChangeInput.bind(this, 'subject')} />
                        <CreateMessageInput value={value} onChange={onChange.bind(this)} focusEditor={focusEditor.bind(this)} renderMark={renderMark} _this={this} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3" style={{maxWidth: '500px'}}>
                    <CreateMessagePreview value={serializedValue} subject={subject} _this={this} />
                  </div>
                </div>

                <div className="row row0">
                  <CreateMessageSchedule daysActive={daysActive} timeStart={timeStart} timeEnd={timeEnd} selectDay={selectDay.bind(this)} selectTime={selectTime.bind(this)} _this={this} />
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

function highlightDynamic(value){
  var val = html.serialize(value)
  val = fillDynamicValues(val, {})
  val = html.deserialize(val)
  return val
}

const PLATFORMS = { 'instagram':{name:'Instagram',neo:'InstagramUser',field:'username'}, 'tiktok':{name:'TikTok',neo:'TiktokUser',field:'username'}, 'youtube':{name:'YouTube',neo:'Influencer',field:'id'}, 'twitch':{name:'Twitch',neo:'TwitchUser',field:'username'}, 'twitter':{name:'Twitter',neo:'TwitterUser',field:'username'}, 'facebook':{name:'Facebook',neo:'FacebookUser',field:'username'} }

function fillDynamicValues(_template, params, options){
  options = options || {}
  var template = _template.replace(/{{ /g, '{{').replace(/ }}/g, '}}')
  var replace = []
  var split = template.split('{{').map((s) => { return s.split('}}')[0].trim() }).forEach((s) => {
    var value = ''
    var label = s.split('.')[0], key = s.split('.')[1]
    var keyVal = `{{${label}.${key}}}`
    // style="color: blue;"
    var k = { key: keyVal, value: `<span>${keyVal}</span>` }
    replace.push(k)
  })
  replace.forEach((r) => { template = template.replace(new RegExp(r.key,'g'), r.value) })
  // console.log(template)
  return template
}

function MarkHotkey(options) {
  const { type, key } = options
  return {
    onKeyDown(event, editor, next) {
      if ( (!event.ctrlKey || event.key != key) && (event.key != 'Meta' || event.key != key) ) return next()
      event.preventDefault()
      editor.toggleMark(type)
    }
  }
}

const plugins = [ MarkHotkey({ key: 'b', type: 'bold' }), MarkHotkey({ key: '`', type: 'code' }), MarkHotkey({ key: 'i', type: 'italic' }), MarkHotkey({ key: '~', type: 'strikethrough' }), MarkHotkey({ key: 'u', type: 'underline' }) ]
function BoldMark(props) { return <strong>{props.children}</strong> }
function CodeNode(props) { return ( <pre {...props.attributes}><code>{props.children}</code></pre> ) }
const BLOCK_TAGS = { blockquote: 'quote', p: 'paragraph', pre: 'code' }
const MARK_TAGS = { em: 'italic', strong: 'bold', u: 'underline' }

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()]
      if (type) { return { object: 'block', type: type, data: { className: el.getAttribute('class') }, nodes: next(el.childNodes) } }
    },
    serialize(obj, children) {
      if (obj.object == 'block') {
        switch (obj.type) {
          case 'code': return ( <pre><code>{children}</code></pre> )
          case 'paragraph': return <p className={obj.data.get('className')}>{children}</p>
          case 'quote': return <blockquote>{children}</blockquote>
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) { return { object: 'mark', type: type, nodes: next(el.childNodes) } }
    },
    serialize(obj, children) {
      if (obj.object == 'mark') {
        switch (obj.type) {
          case 'bold': return <strong>{children}</strong>
          case 'italic': return <em>{children}</em>
          case 'underline': return <u>{children}</u>
        }
      }
    },
  },
]

const html = new Html({ rules })
export default memo(CreateMessage);
