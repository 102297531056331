import React from 'react';

export default class TaxInfoDisplay extends React.Component {

  render() {
    var { label, value } = this.props

    return (
      <div className="col-xs-6">
        <label className="sub-text">{label}</label>
        <p><b>{value}</b></p>
      </div>
    )
  }
}
