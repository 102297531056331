import React from 'react';

export default class SlideOutListItem extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var text = this.props.text, type = this.props.type
    return (
      <li style={{minHeight: '30px'}}>
        <div style={{display: 'inline-block', float: 'left'}}>
          {
            type == 'yes' &&
            <svg style={{ marginRight: '8px', marginTop: '3px', width: '15px' }} className="SVGInline-svg SVGInline--cleaned-svg" fill="#01d36d" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="f964ce12-05e4-410b-bfe0-4a4b8eaa49c4" dataname="Design"><path d="M20.43,7.07,10.71,17.79a1.26,1.26,0,0,1-.89.41h0a1.24,1.24,0,0,1-.89-.37l-5.1-5.1A1.25,1.25,0,0,1,5.57,11l4.17,4.17,8.84-9.75a1.25,1.25,0,1,1,1.85,1.68Z"></path></g></svg>
          }
          {
            type == 'no' &&
            <svg style={{ marginRight: '8px', marginTop: '3px', width: '15px', fill: '#ff6a6a' }} className="SVGInline-svg SVGInline--cleaned-svg" fill="#ffc112" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="b3d0e43f-d23f-4268-adce-b4ace259fbc5" dataname="Design"><path d="M18.88,17.13a1.24,1.24,0,0,1,0,1.76,1.23,1.23,0,0,1-1.76,0L12,13.78,6.88,18.89a1.23,1.23,0,0,1-1.76,0,1.24,1.24,0,0,1,0-1.76L10.23,12,5.12,6.89A1.24,1.24,0,0,1,6.88,5.13L12,10.24l5.12-5.11a1.24,1.24,0,0,1,1.76,1.76L13.77,12Z"></path></g></svg>
          }
        </div>
        <div style={{display: 'inline-block', position: 'relative', float: 'left', width: 'calc(100% - 25px)'}}>
          <div style={{ position: 'relative', width: '100%' }}>
            <p className="sub-text">{text}</p>
          </div>
        </div>
      </li>
    )
  }
}
