import React from 'react';

export default class OtherTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Any Post</h4>
        <p>Using Weave links in your content provides easy ways for your fans to find and purchase the stores and products you love most. Here’s some general tips for posting links:</p>
        <ol>
          <li>include links to all of the products or stores that you feature in your content</li>
          <li>let viewers know where they can find the links</li>
          <li>share your links across all your content and social platforms for maximum exposure</li>
        </ol>
      </div>
    )
  }
}
