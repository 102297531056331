import { Component, memo } from 'react'
import Globals from '../../global-functions.js'
import PillTag from './pill-tag';
// import OutreachListSearch from './outreach-list-search';

async function _get(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
async function _set(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

class AddFiltersPopup extends Component {

  constructor(props){
    super(props)
    this.state = {
      inputValue: '',
      addText: '+ ADD',
      personalizedMessage: '',
      draftEmail: '',
      outreachLists: []
    }
  }

  componentDidMount(){

  }

  handleClosePopup(e) {
    // if (this.props.active){
      // console.log($(e).hasClass('add-influencer-popup'), $(e).hasClass('add-influencer-popup-module'))
      // if ($(e.target).classList.contains('popup-out-close-icon')) { this.props.togglePopup() }
      // else if ($(e.target).classList.contains('add-influencer-popup-module')) { return }
      // else { this.props.togglePopup() }
    // }
  }

  selectEmployee(e){  }
  chooseSort(e){  }

  async createSavedFilter(name){
    var { filters, togglePopup } = this.props
    await _setInsight('createSavedFilter', { name, filters: JSON.stringify(filters) });
    togglePopup(false)
  }

  // async chooseList(type, list){
  //   var id, { brands, togglePopup } = this.props
  //   if (list && list.id == 'addNew'){ var res = await _set('createOutreachList', { name: list.name }); id = res.id }
  //   id = id || list.id; list.id = id
  //   await _set('addOutreachList', { list, brands: brands.map((b) => { var o = { id: b.meta.id }; if (b.selectedContact && b.selectedContact.id) o.contactId = b.selectedContact.id; return o }) })
  //   brands = brands.map((b) => { b.lists = [ ...(b.lists || []), list ]; return b })
  //   togglePopup('outreach', brands)
  // }

  // async removeList(list){
  //   var id, { brands, togglePopup } = this.props
  //   await _set('removeOutreachList', { list, brands: brands.map((b) => { var o = { id: b.meta.id }; return o }) })
  //   brands = brands.map((b) => { b.lists = b.lists.filter((l) => { if (l.id != list.id) return l }); return b })
  //   togglePopup('outreach', brands)
  // }

  render() {
    var { active, filtersActive, togglePopup, _this } = this.props
    var { filters, filtersApplied, filterLabels, activeFilters } = filtersActive
    var filtersList = Object.keys(activeFilters).map((f) => { return f })

    return (
      <div ref={ node => this.node = node }>
        <div className="add-influencer-popup">
          <div className="add-influencer-popup-container">
            <div className="add-influencer-popup-module" style={{width: '850px', height: '575px', maxWidth: '90%'}}>
              <div className="add-influencer-popup-outer">
                <div className="add-influencer-popup-inner">
                  <div className="add-influencer-popup-header">
                    <div className="row row0">
                      <h3>Create Saved Filter Group</h3>
                    </div>
                    <div className="popup-out-close-icon" onClick={togglePopup.bind(_this)} style={{position: 'absolute', top: '15px', right: '15px', width: '15px', height: '15px', cursor: 'pointer'}}>
                      <img src={Globals.icon('close')} style={{width: '100%', height: '100%'}} />
                    </div>
                  </div>
                  <div className="add-influencer-popup-body" style={{height: '525px'}}>
                    <div className="row row0">
                      <div className="col-xs-6">
                        <div style={{position: 'relative', width: '300px', padding: '0 15px'}}>
                          <input className="dark-input" placeholder="Enter name of saved filters..." />
                        </div>
                        <div>
                          <button className="btn-action btn-primary">Save Filters</button>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="row row0">
                          <h3>Active Filters</h3>
                        </div>
                        { filtersList.map((f) => {
                            return (
                              <>
                                <div className="crm-btn">
                                  <span>{f}</span>
                                </div>
                              </>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(AddFiltersPopup);
