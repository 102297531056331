import { all, call, put, takeLatest } from 'typed-redux-saga';
import { HasAccountAction, inviteUserActionCreators } from '../actions/invite-user.actions';
import { fetchHasAccount } from '../../services/user-service';

function* hasAccountSaga(action: HasAccountAction) {
  try {
    const inviteUserResponse = yield* call(fetchHasAccount, action.payload);
    return yield* put(inviteUserActionCreators.hasAccountSuccessAction(inviteUserResponse));
  } catch (e) {
    yield put(inviteUserActionCreators.hasAccountFailAction(e));
  }
}


export function* inviteUserSagas() {
  yield all([
    takeLatest('inviteUserHasAccount/GET', hasAccountSaga),
  ]);
}
