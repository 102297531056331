import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'
import FilterOption from './filter-option'

const FilterCountry = ((props) => {

  var { filter, changeFilter, type, _this } = props
  var disableFilters = filter[1].active

  return (
    <>
      <div className={`filter-option-container ${disableFilters ? 'disabled' : ''}`}>
        <div className="row row0">
          <label>Select Countries</label>
        </div>
        <div className="row row0">
          <SearchDropdown endpoint="getCountries" preload={true} index={0} type={type} field="name" dataKey="countries" changeFilter={changeFilter} placeholder="Select country..." _this={_this} />
        </div>
        <div className="row row0">
          { filter[0] && filter[0].length > 0 && filter[0].map((l) => {
            return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> )
          }) }
        </div>
      </div>

      <FilterOption title="Country Known" label="known" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="Country Unknown" label="unknown" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="USA & Country Unknown" label="exclude-world" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="USA, Canada, UK" label="primary" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
    </>
  )
})

export default memo(FilterCountry);
