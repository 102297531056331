import { Component, memo } from 'react';
import Globals from '../global-functions.js';
import CountUp from 'react-countup';

var animateCt = 35

class Cashout extends Component {

  constructor(props){
    super(props)
    this.state = {
      user: this.props.user,
      withdrawalHistory: false,
      withdrawals: [],
      withdrawSuccessful: false,
      paypalEmail: this.props.user ? this.props.user.paypalEmail : '',
      available: 0.00,
      pending: 0.00,
      total: 0.00,
      balanceLoaded: false,
      cashingOut: false,
      cashoutText: 'WITHDRAW USING PAYPAL'
    }
  }

  componentWillUpdate(){

  }

  componentDidMount(){
    if (!this.state.user.internal){ window.Intercom("update"); }
    if (Object.keys(this.props.user).length > 0){
      this.getBalance()
      this.getWithdrawalHistory()
    }
  }

  getBalance(){
    this._getBalance().done((data) => {
      if (data){
        this.setState({
          total: Number(data.total),
          available: Number(data.available),
          pending: Number(data.pending),
          balanceLoaded: true
        })
      }
    })
  }

  _getBalance(){ return $.ajax({ method: 'GET', url: '/api/Users/getBalance', data: { userid: this.props.user.userid } }) }
  getWithdrawalHistory(){ this._getWithdrawalHistory().done((data) => { if (data){ this.setState({ withdrawals: data }) } }) }

  _getWithdrawalHistory(){ return $.ajax({ method: 'POST', url: '/api/Cashout/withdrawalHistory', data: {  } }) }

  cashout(){
    if (!Globals.validateEmail(this.state.paypalEmail)){ alert('Not valid email') }
    else if (!this.state.withdrawSuccessful){
      this.setState({ cashingOut: true, cashoutText: 'WITHDRAWING...' })
      this._cashout().done((data) => {
        if (!data){ alert('Something went wrong. Chat us for help!') }
        else if (data.error){ alert(data.error) }
        else {
          $('.cashout-complete .cashout-amount').text('$' + data.amount)
          $('.cashout-complete .cashout-method').text(data.method)
          var increment = (parseFloat($('.paypal-top span').text()) / animateCt).toFixed(2)
          this.withdrawAnimation(animateCt, increment)
        }
      })
    }
    else { $('.paypal').hide() }
  }

  _cashout(){ return $.ajax({ method: 'POST', url: '/api/Cashout/cashout', data: { userid: this.props.user.userid, method: this.state.paypalEmail } }) }

  withdrawAnimation(i, x){
    var n = parseFloat($('.paypal-top span').text()) - x
    setTimeout(() => {
      if (i == 0 || n < 0){
        $('.paypal-top span').text('0.00')
        $('.account-balance span').text('$0.00')
        setTimeout(() => {
          $('.paypal-top').addClass('success')
          setTimeout(() => {
            $('.cashout-complete').addClass('success')
            $('.paypal-withdraw').text('RETURN TO SITE').addClass('success')
            this.setState({
              withdrawSuccessful: true,
              cashoutText: 'RETURN TO SITE'
            })
          }, 850)
        }, 300)
      }
      else {
        $('.paypal-top span').text(n.toFixed(2))
        $('.account-balance span').text('$' + n.toFixed(2))
        this.withdrawAnimation(i-1, x)
      }
    }, (animateCt+20 - i))
  }

  toggleWithdrawalHistory(){
    this.setState({
      withdrawalHistory: !this.state.withdrawalHistory
    })
  }

  onChange(type, e){
    this.setState({
      [type]: e.target.value
    })
  }

  render() {
    return (
      <div>
        <div className={`container paypal ${this.props.active != 'false' && this.props.active != false ? '' : 'hidden'}`}>
          <div className="paypal-popup">
            <div className={`row paypal-top ${this.state.withdrawalHistory ? 'hidden' : ''}`}>
              <a className="close-paypal" onClick={this.props.hideCashout.bind(this.props.this)}><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icon_delete_grey.svg" alt="Close Icon" /></a>
              <h2 style={{ fontFamily: 'Barlow', fontWeight: 900 }}>
                {
                  this.state.balanceLoaded &&
                  !this.state.cashingOut &&
                  <CountUp
                    start={parseFloat(0).toFixed(2)}
                    end={parseFloat(this.state.available).toFixed(2)}
                    duration={0.78}
                    delay={0.1}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="$"
                    onEnd={() => {
                      if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); }
                    }}
                    onStart={() => {
                      if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); }
                    }}
                  >
                    {({ countUpRef, start }) => {
                      setTimeout(start, 500);
                      return (
                        <p ref={countUpRef} className="earnings-amt" id="totalEarnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}></p>
                      );
                    }}
                  </CountUp>
                }
                {
                  this.state.balanceLoaded &&
                  this.state.cashingOut &&
                  <CountUp
                    start={parseFloat(this.state.available).toFixed(2)}
                    end={parseFloat(0).toFixed(2)}
                    duration={0.78}
                    delay={0.001}
                    separator=","
                    decimals={2}
                    decimal="."
                    prefix="$"
                    onEnd={() => {
                      if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); }
                    }}
                    onStart={() => {
                      if (document.getElementById("totalEarnings")){ document.getElementById("totalEarnings").classList.toggle("animating"); }
                    }}
                  >
                    {({ countUpRef, start }) => {
                      setTimeout(start, 750);
                      return (
                        <p ref={countUpRef} className="earnings-amt" id="totalEarnings" style={{fontFamily: 'Barlow', fontWeight: '900'}}></p>
                      );
                    }}
                  </CountUp>
                }
                {
                  !this.state.balanceLoaded &&
                  <span>
                    $<span>{Globals.numberWithCommas(Number(this.state.available).toFixed(2))}</span>
                  </span>
                }
              </h2>
              <h4 className="pending-balance" style={{ textAlign: 'center', fontFamily: 'Barlow', fontWeight: 900 }}>Available Balance</h4>
              <p className="sub-text"><b>${this.state.pending}</b> pending balance</p>
              <h3 className="cashout-complete"><b className="cashout-amount"></b> successfully sent to <b className="cashout-method"></b></h3>
            </div>
            <div className={`row row0 paypal-cashout-fields ${(!!this.state.paypalEmail?.length && !this.state.withdrawalHistory && this.state.available >= Globals.minimumCashout) ? '' : 'hidden'}`} style={{padding: '15px 0 15px 0'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '6px'}}>
                <div className={`success-check active`}><img src={Globals.icon('ok', 'white')} /></div>
                <p style={{margin: '0 0 0 10px'}} className="success"><b>EMAIL VERIFIED</b></p>
              </div>
              <input className="paypal-email dark-input" placeholder="PayPal Email" value={this.state.paypalEmail} disabled={true} onChange={this.onChange.bind(this, 'paypalEmail')} />
            </div>
            { !this.state.paypalEmail?.length && <div className={`row row0 paypal-cashout-fields ${(!this.state.paypalEmail?.length && !this.state.withdrawalHistory && this.state.available >= Globals.minimumCashout) ? '' : 'hidden'}`} style={{padding: '15px 0 15px 0'}}>
              <p className="sub-text" style={{margin: '6px 0 0 0', textAlign: 'center'}}>You need to add a <b>Paypal email</b> to cash out!</p>
              <button className={'paypal-withdraw'} onClick={() => window.location.replace('/account')}>Go to settings</button>
            </div>}
            <div className={`row row0 paypal-cashout-fields ${this.state.available >= Globals.minimumCashout ? '' : 'hidden'}`}>
              {
                !this.state.withdrawSuccessful && this.state.paypalEmail?.length &&
                <button className={`paypal-withdraw ${this.state.cashingOut ? 'disabled' : ''}`} disabled={!this.state.paypalEmail?.length} onClick={this.cashout.bind(this)}>{this.state.cashoutText}</button>
              }
              {
                this.state.withdrawSuccessful &&
                <button className={`paypal-withdraw ${this.state.withdrawSuccessful ? 'success' : ''}`} onClick={this.props.hideCashout.bind(this.props.this)}>{this.state.cashoutText}</button>
              }
            </div>
            <div className={`row row0 paypal-balance-minimum ${this.state.available < Globals.minimumCashout ? '' : 'hidden'}`}>
              <h3 style={{ border: 'none', fontSize: '18px' }}>Must have available balance of $50.00 or higher to cash out.</h3>
            </div>
            <div className={`row withdrawal-history ${!this.state.withdrawalHistory ? 'hidden' : ''}`}>
              <h3>Withdrawal History</h3>
              <div className="row row0">
                <div className="col-xs-4">Date</div>
                <div className="col-xs-4">Amount</div>
                <div className="col-xs-4">PayPal Account</div>
              </div>
              <div className="withdrawals">
                {
                  this.state.withdrawals.map((withdrawal) => {
                    return (
                      <div className="row">
                        <div className="col-xs-4">{(withdrawal.createdAt).toString().split('T')[0]}</div>
                        <div className="col-xs-4" style={{color: '#80c67d'}}>{`$${parseFloat(withdrawal.amount).toFixed(2)}`}</div>
                        <div className="col-xs-4">{withdrawal.receiverContact}</div>
                      </div>
                    )
                  })
                }
              </div>

            </div>


            {
              this.state.user &&
              <span className="see-withdrawal-history sub-text" onClick={this.toggleWithdrawalHistory.bind(this)}>{this.state.withdrawalHistory ? 'See Cashout Page' : 'See Withdrawal History'}</span>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default memo(Cashout)
