import React from 'react';
import Globals from '../global-functions.js';

export default class Guideline extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      copyId: '',
      url: ''
    }
  }

  componentDidMount(){
    console.log(this.props)
    var guideline = this.props.guideline
    var obj = { copyId: Math.floor(Math.random() * 9999999999999) }
    if (guideline.type == 'post'){
      obj.url = guideline.value
    }
    this.setState(obj)
  }

  onChangeInput(type, e){ this.setState({ [type]: e.target.value }) }

  copyLink(e){
    $(`#guideline-input-${this.state.copyId}`).select()
    document.execCommand('copy')
  }

  uploadPost(){}

  checkPostFormat(){
    if (this.state.url.indexOf('instagram.com/p/') > -1){
      return true
    }
    else return false
  }

  render() {
    var guideline = this.props.guideline
    return (
      <div>
        <div className="row guideline">
          <div className="col-xs-6" style={{paddingTop: '7px'}}>
            <div className="guideline-status">
              { guideline.active == 'inactive' &&
                <>
                  <img src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${Globals.themeMode == 'dark' ? 'white' : 'black'}/star-filled-icon.svg`} style={{width: '16px', marginTop: '2px'}} />
                  {/*<img src="/images/close-red.svg" style={{width: '14px', marginTop: '0'}} />*/}
                </>
              }
              { guideline.active == 'active' && <img src="/images/checked.svg" style={{marginTop: '-2px'}} /> }
              { guideline.active == 'passive' && <img src="/images/checked.svg" style={{marginTop: '-2px'}} /> }
            </div>
            <div className="guideline-text">
              <p className="guideline-main">{guideline.mainText}</p>
              <p className="guideline-sub sub-text">{guideline.subText}</p>
            </div>
          </div>
          <div className="col-xs-6">
            { guideline.type == 'banner' &&
              <>
                <div className="banner-download">
                  <a href={guideline.value} target="_blank" download>
                    <div className="banner-download-wrapper">
                      <img className="banner-download-preview" src={guideline.value} />
                      <div className="banner-download-btn">
                        <span><img className="banner-download-icon" src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${Globals.themeMode == 'dark' ? 'white' : 'black'}/download-icon.svg`} /></span>
                        <span>Download Image</span>
                      </div>
                    </div>
                  </a>
                </div>
              </>
            }
            { guideline.type != 'banner' && guideline.type != 'post' &&
              <>
                <div className="trackable-link" style={{float: 'right'}}>
                  <div className="trackable-link-wrapper" onClick={this.copyLink.bind(this)}>
                    <input className="dark-input" id={`guideline-input-${this.state.copyId}`} defaultValue={guideline.value} readOnly />
                    <div className="trackable-link-copy">
                      <div className="trackable-link-copy-container">
                        <img src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${Globals.themeMode == 'dark' ? 'white' : 'black'}/duplicate-icon.svg`} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
            { guideline.type == 'post' &&
              <>
                <div className="trackable-link" style={{float: 'right'}}>
                  <div className="trackable-link-wrapper">
                    <input className="dark-input" id={`guideline-input-${this.state.copyId}`} value={this.state.url} onChange={this.onChangeInput.bind(this, 'url')} />
                    {
                      this.checkPostFormat() &&
                      <div className="trackable-link-copy">
                        <div className="trackable-link-copy-container">
                          <img src={`//weave-img.s3-us-west-2.amazonaws.com/icons/${Globals.themeMode == 'dark' ? 'white' : 'black'}/share-1-icon.svg`} onClick={this.uploadPost.bind(this)} style={{cursor: 'pointer'}} />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}
