import React from 'react';

export default class SlideOutProgressTitle extends React.Component {

  render() {
    var title = this.props.title, description = this.props.description

    return (
      <div>
        <h4 className="shipping-meta-title" style={{margin: '0 0 8px 0'}}>{title}</h4>
        { description && description.length > 1 && <p className="sub-text shipping-meta-desc" style={{margin: 0}}>{description}</p> }
      </div>
    )
  }
}
