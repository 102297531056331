import { createReducer } from 'typesafe-actions';
import { GetContentObjectivesFailed, GetContentObjectivesResponse } from '../models/content.models';
import { Nullable } from '../../utils/utils';
import { contentActionCreators, ContentActions } from '../actions/content.actions';

type ContentObjectivesState = {
  data: GetContentObjectivesResponse;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetContentObjectivesFailed>;
};

const initialState: ContentObjectivesState = Object.freeze({
  data: [],
  hasError: false,
  loading: false,
  error: null
});

export const contentObjectivesReducer = createReducer<ContentObjectivesState, ContentActions>(
  initialState,
).handleAction(contentActionCreators.getContentObjectivesAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    contentActionCreators.getContentObjectivesSuccessAction,
    (state, action) => ({
      ...initialState,
      data: action.payload,
    }),
  )
  .handleAction(contentActionCreators.getContentObjectivesFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

