import React from 'react'
import Globals from '../global-functions.js'
// import '../../styles/scss/flatpickr.scss'
// import '../../styles/scss/calendar.scss'

export default class Calendar extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      calendar: false,
      startDate: moment().subtract('1825', 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      calendarRange: {startDate: moment().subtract('1825', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')},
      old_calendar: {startDate: moment().subtract('1825', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')},
      dateRanges: {
      	today: { startDate: moment().format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	yesterday: { startDate: moment().subtract('1', 'days').format('YYYY-MM-DD'), endDate: moment().subtract('1', 'days').format('YYYY-MM-DD') },
      	last_7d: { startDate: moment().subtract('7', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	last_30d: { startDate: moment().subtract('30', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	last_3m: { startDate: moment().subtract('90', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	last_6m: { startDate: moment().subtract('180', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	last_y: { startDate: moment().subtract('365', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') },
      	lifetime: { startDate: moment().subtract('1825', 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') }
      }
    }
  }

  componentWillUnmount(){
    this.setState({ mounted: false });
    $('.shortcuts').remove();
  }

  componentDidMount() {
    this.initCalendar()
  }

  initCalendar(){
    var { startDate, start_date, endDate, endDate } = this.props
    startDate = moment(startDate || start_date || moment().subtract('365', 'days').format('YYYY-MM-DD')).format('YYYY-MM-DD')
    endDate = moment(endDate || end_date || moment().format('YYYY-MM-DD')).format('YYYY-MM-DD')
  	let calendar = flatpickr('#calendar', {
      mode: "range",
      dateFormat: "Y-m-d",
  		minDate: this.state.userCreated,
  		maxDate: moment().format('YYYY-MM-DD'),
      defaultDate: [startDate, moment().format('YYYY-MM-DD')],
  		onChange: this.changeCalendar.bind(this),
  		onClose: this.updateCalendar.bind(this)
  	})

    $('.shortcuts').prependTo('.flatpickr-calendar').show().addClass('loaded')
  	$('#calendar').text($('#calendar').val())
    if (this.state.mounted) { this.setState({ calendar: calendar }) }
    let that = this

    $('.shortcuts li a').click(function(){
  		var dates = that.state.dateRanges[$(this).attr('id')]
  		if (that.state.mounted) { that.setState({ calendarRange: dates }) }
  		// that.state.calendar.setDate([dates.startDate, dates.endDate], false)
  		// that.state.calendar.toggle()
      calendar.setDate([dates.startDate, dates.endDate], false)
  		calendar.toggle()
  		$('.shortcuts li a').removeClass('active')
  		$(this).addClass('active')
  	})
  }

  changeCalendar(){
    $('.shortcuts li a').removeClass('active')
  	$('.shortcuts #custom').addClass('active')
  }

  updateCalendar(selectedDates, dateStr, instance){
    this.state.calendarRange.startDate = moment(selectedDates[0]).format('YYYY-MM-DD')
  	this.state.calendarRange.endDate = moment(selectedDates[1]).format('YYYY-MM-DD')
  	if (this.state.calendarRange.startDate != this.state.old_calendar.startDate || this.state.calendarRange.endDate != this.state.old_calendar.endDate){
  		this.state.old_calendar.startDate = this.state.calendarRange.startDate
  		this.state.old_calendar.endDate = this.state.calendarRange.endDate
      if (this.state.mounted) {
        this.setState({
          calendarRange: this.state.calendarRange,
          old_calendar: this.state.old_calendar
        })
      }
      this.props.updateCalendar(this.props.this, moment(selectedDates[0]).format('YYYY-MM-DD'), moment(selectedDates[1]).format('YYYY-MM-DD'), dateStr)
  		$('#calendar').text(dateStr)
  	}
  }

  render() {

    return (
      <div>
        <button id="calendar" className="search-filter-box module" name="calendar" style={{padding: '15px'}}></button>

        <ul className="shortcuts">
          <li><a id="today">Today</a></li>
          <li><a id="yesterday">Yesterday</a></li>
          <li><a id="last_7d">Last 7 Days</a></li>
          <li><a id="last_30d">Last 30 Days</a></li>
          <li><a id="last_3m">Last 3 Months</a></li>
          <li><a id="last_6m">Last 6 Months</a></li>
          <li><a id="last_y">Last Year</a></li>
          <li><a id="lifetime" className="active">All Time</a></li>
          <li><a id="custom">Custom</a></li>
        </ul>
      </div>
    )
  }
}
