import { Component, memo } from 'react';
import Globals from '../global-functions';

class AccountDetails extends Component {

  constructor(props){
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      paypalEmail: '',
      unverifiedPaypalEmail: '',
      gender: '',
      birthday: '',
      updateSettingsBtn: 'Save',
      setEmailBtn: 'Set'
    }
  }

  componentDidMount(){
    this.refreshUser()
  }

  refreshUser(){
    this.getUser().done((response) => {
      if (response){
        var obj = {
          userdata: response,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          verifiedPaypalEmail: response.paypalEmail,
          paypalEmail: response.paypalEmail,
          gender: response.gender,
          birthday: response.birthday,
          socials: response.socials
        }
        this.setState(obj)
      }
      else {  }
    })
  }

  getUser(){ return $.ajax({ method: 'GET', url: '/api/me/details', data: { } }) }

  onChangeInput(field, e){
    if (typeof e == 'string'){ this.setState({ [field]: e }) }
    else { this.setState({ [field]: e.target.value }) }
  }

  updateSettings(){
    this.setState({ requestError: null, updateSettingsBtn: 'Saving...' })
    this._updateSettings()
      .catch(error => {
        const requestError = error.responseJSON.message;
        this.setState({ requestError, updateSettingsBtn: 'Save' });
      }).done((response) => {
      if (response.success) {
        this.setState({ updateSettingsBtn: 'Saved!' });
        setTimeout(() => { this.setState({ updateSettingsBtn: 'Save' }) }, 1000);
      }
    })
  }

  setPaypalEmail() {
    this.setState({ setEmailBtn: 'Setting...', setPaypalEmailLoading: true, setPaypalEmailMessage: null, setPaypalEmailError: null })
    this._setPaypalEmail().catch(
      ({responseJSON}) => {
        this.setState({
          setEmailBtn: 'Set',
          setPaypalEmailLoading: false,
          setPaypalEmailError: !responseJSON.success && 'Failed to set Paypal email'
        })
      }
    ).done(({message}) => {
      this.setState({
        setEmailBtn: 'Set',
        setPaypalEmailLoading: false,
        setPaypalEmailMessage: message
      })
    });
  }

  _setPaypalEmail() { return $.ajax({ method: 'POST', url: '/api/me/setPaypalEmail', data: { email: this.state.paypalEmail } }) }

  _updateSettings(){
    return $.ajax({
      method: 'POST',
      url: '/api/me/updateSettings',
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        gender: this.state.gender,
        birthday: this.state.birthday,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword
      }
    })
  }

  hasValidationError(key) {
    const { requestError } = this.state;
    if (requestError && requestError.type === 'validation-error') {
      return !!requestError.errors.find(error => error.property === key);
    }
  }

  render() {

    var profileImg = '';

    return (
      <div>
        <h3 style={{ margin: '20px 0 32px 0', padding: 0, fontSize: '20px', textTransform: 'initial' }}>My Account</h3>

        <div className="row" style={{margin: 0}}>
          <div className="module" style={{maxWidth: '700px', overflow: 'auto'}}>
            <div className="col-md-2">
              <div className="account-img" style={{backgroundImage: `url(${profileImg})`}}></div>
            </div>
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6">
                  <h5 className="sub-text">First Name</h5>
                  <input value={this.state.firstName} onChange={this.onChangeInput.bind(this, 'firstName')} className={`dark-input ${this.hasValidationError('firstName') ? 'error' : ''}`} />
                  <h5 className="sub-text">Email</h5>
                  <input value={this.state.email} onChange={this.onChangeInput.bind(this, 'email')} className={`dark-input ${this.hasValidationError('email') ? 'error' : ''}`} />
                  <h5 className="sub-text">
                    Gender
                    { this.hasValidationError('gender') && <span className={'error-msg'}> *Required</span> }
                  </h5>
                  <div className="row row4">
                    <div className="col-xs-4 account-gender-container">
                      <div className={`dark-input account-gender account-gender-male ${this.state.gender == 'male' ? 'active' : ''}`} onClick={this.onChangeInput.bind(this, 'gender', 'male')}>Man</div>
                    </div>
                    <div className="col-xs-4 account-gender-container">
                      <div className={`dark-input account-gender account-gender-female ${this.state.gender == 'female' ? 'active' : ''}`} onClick={this.onChangeInput.bind(this, 'gender', 'female')}>Woman</div>
                    </div>
                    <div className="col-xs-4 account-gender-container">
                      <div className={`dark-input account-gender account-gender-other ${this.state.gender == 'other' ? 'active' : ''}`} onClick={this.onChangeInput.bind(this, 'gender', 'other')}>Other</div>
                    </div>
                  </div>
                  {/*<h5 className="sub-text">Old Password</h5>
                  <input value={this.state.oldPassword} onChange={this.onChangeInput.bind(this, 'oldPassword')} className="dark-input" />*/}
                </div>
                <div className="col-md-6">
                  <h5 className="sub-text">Last Name</h5>
                  <input value={this.state.lastName}
                         onChange={this.onChangeInput.bind(this, 'lastName')}
                         className={`dark-input ${this.hasValidationError('lastName') ? 'error' : ''}`} />
                  <h5 className="sub-text">Birth Date (MM/DD/YYYY)</h5>
                  <input value={this.state.birthday}
                         onChange={this.onChangeInput.bind(this, 'birthday')}
                         className={`dark-input ${this.hasValidationError('birthday') ? 'error' : ''}`} />
                  {/*<h5 className="sub-text">New Password</h5>
                  <input value={this.state.newPassword} onChange={this.onChangeInput.bind(this, 'newPassword')} className="dark-input" />*/}
                </div>
                <div className="row" style={{paddingLeft: '28px', paddingRight: '28px'}}>
                  <button className="btn-action btn-primary" onClick={this.updateSettings.bind(this)}>{this.state.updateSettingsBtn}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row row0" style={{marginTop: 20}}>
          <div className="row row0" style={{marginBottom: '15px'}}>
            <h3 style={{ margin: '20px 0px 15px', padding: 0, fontSize: '20px', textTransform: 'initial' }}>Payout Details</h3>
            <p className="sub-text">Add your PayPal email and verify it so Weave can pay you your earnings</p>
          </div>
          <div className="module" style={{maxWidth: '700px', overflow: 'auto'}}>
            <div>
              <div className="row row0">
                <div className="col-md-9">
                  <h5 className="sub-text" style={{marginTop: 0}}>PayPal Email</h5>
                  <div className={`success-check email-verified ${(!!this.state.verifiedPaypalEmail && this.state.verifiedPaypalEmail === this.state.paypalEmail) ? 'active' : ''}`} style={{bottom: '10px'}}>
                    <img src={Globals.icon('ok', 'white')} />
                  </div>
                  <input value={this.state.paypalEmail} style={{marginBottom: 0}} onChange={this.onChangeInput.bind(this, 'paypalEmail')} className={'dark-input'} />
                </div>
                <div className="col-md-3">
                  <div className="row" style={{paddingLeft: '28px', paddingRight: '28px'}}>
                    <button className="btn-action btn-primary" style={{marginTop: '25px'}} disabled={this.state.setPaypalEmailLoading} onClick={this.setPaypalEmail.bind(this)}>{this.state.setEmailBtn}</button>
                  </div>
                </div>
              </div>
              { this.state.setPaypalEmailMessage &&
              <div className="row row0">
                <div className="col-md-12">
                  <p className={'sub-text'}>{this.state.setPaypalEmailMessage}</p>
                </div>
              </div>
              }

              { this.state.setPaypalEmailError &&
                <div className="row row0">
                  <div className="col-md-12">
                    <p className={'error-msg'}>{this.state.setPaypalEmailError}</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(AccountDetails)
