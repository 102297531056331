import React, { useEffect, useState } from 'react';
import { isiOS } from '../../utils/utils';
import { InviteHeader } from './invite-header';
import { InviteBody } from './invite-body';
import { InviteBountyInfo } from './invite-bounty-info';
import SwipeableRoutes from 'react-swipeable-routes';
import { Route } from 'react-router-dom';
import { InviteProducts } from './invite-products/invite-products';
import { InviteModal } from './invite-modal/invite-modal';
import { InviteGuidelines } from './invite-guidelines/invite-guidelines';
import { InviteDeliverables } from './invite-deliverables/invite-deliverables';
import { InviteShipping } from './invite-shipping/invite-shipping';
import { InviteAccount } from './invite-account/invite-account';
import { useAuthLoadingSelector, useIsLoggedInSelector } from '../../redux/selectors/auth.selectors';
import { LoginType } from '../../redux/models/auth.models';
import { useDispatch } from 'react-redux';
import { authActionCreators } from '../../redux/actions/auth.actions';
import { EditDeliverable } from './edit-deliverable/edit-deliverable';
import { InviteCompensation } from './invite-compensation/invite-compensation';
import './invite-standalone.scss';
import { InviteSocialsInfo } from './invite-socials/invite-socials-info';
import { useInvitationSelector } from '../../redux/selectors/invitation.selectors';
import { invitationActionCreators } from '../../redux/actions/invitation.actions';
import { brandCampaignActionCreators } from '../../redux/actions/brand-campaign.actions';
import { useBrandCampaignSelector } from '../../redux/selectors/brand-campaign.selectors';
import { bountyActionCreators } from '../../redux/actions/bounty.actions';
import { useBountyProductsSelector, useSelectedProductSelector } from '../../redux/selectors/bounty.selectors';
import { Product } from '../../redux/models/bounty.models';
import { useInviteUserSelector } from '../../redux/selectors/invite-user.selectors';
import { useContentObjectivesSelector } from '../../redux/selectors/content.selectors';
import { usePrimaryAddressAnonymousSelectorSelector } from '../../redux/selectors/address.selectors';

export interface InviteStandaloneProps {
  match: {
    params: {
      invitationId: string;
    };
  };
}

export const InviteStandalone: React.FC<InviteStandaloneProps> = ({match}) => {

  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedInSelector();
  const isLoggingIn = useAuthLoadingSelector();
  const {data: invitation, loading} = useInvitationSelector();
  const {data: campaign} = useBrandCampaignSelector();
  const {data: products} = useBountyProductsSelector();
  const {data: product} = useSelectedProductSelector();
  const {data: objectives} = useContentObjectivesSelector();
  const {data: primaryAddress} = usePrimaryAddressAnonymousSelectorSelector();
  const {hasAccount} = useInviteUserSelector();
  const invitationId = match.params.invitationId;
  const bountyId = invitation?.bountyId || invitation?.offerId || '';

  const [selectedProduct, setSelectedProduct] = useState(product);


  //1. We have an invitation ID:
  useEffect( () => {
    if (!invitationId) { return; }
    dispatch(invitationActionCreators.getInvitationAction({id: invitationId}))
  }, [invitationId]);

  // 1a. We're not logged in:
  useEffect(() => {
    if (!isLoggedIn && !isLoggingIn) {
      dispatch(authActionCreators.createLoginAction({loginType: LoginType.Auto}));
    }
  }, [isLoggedIn]);

  // 2. invitation successfully loaded (but we may not be logged in)
  useEffect(() => {
    if (!invitation) { return; }
    dispatch(invitationActionCreators.loadInvitationDetails({brandCampaignType: 'active', invitationId, bountyId}))
  }, [invitation]);

  useEffect(() => {
    if (product) {
      setSelectedProduct(product);
    }
  }, [product])

  const refreshCampaign = async () => {
    dispatch(brandCampaignActionCreators.getBrandCampaignAction({type: 'active', id: invitationId}));
  }

  const onSelectProduct = (product: Product) => {
    setSelectedProduct(product);
    dispatch(bountyActionCreators.selectProductAction({shopifyproductId: product.id, contentId: invitationId}));
  }

  const makeModalRoute = (url: string, title: string, renderChildren: (routeParams: any) => any) => <Route path={`/collab/${invitationId}/overview/${url}`} render={(props) => {
    return <InviteModal title={title} backgroundColor={`#${invitation?.brandingColor?.replace('#', '')}5c`} background={invitation?.bannerImageUrl || ''}>
      { renderChildren(props?.match?.params) }
    </InviteModal>
  }


  } />;

  const subRoutes = !invitationId? [] : [
    makeModalRoute('products', 'Products', () => <InviteProducts mode="full" products={products} selectedProduct={selectedProduct} selectProduct={onSelectProduct} content={campaign?.content} campaign={campaign} giftingType={campaign?.giftingType} /> ),
    makeModalRoute('guidelines', 'Guidelines', () => <InviteGuidelines campaign={campaign} invitation={invitation} /> ),
    makeModalRoute('compensation', 'Compensation', () => <InviteCompensation mode="full" campaign={campaign}/> ),
    makeModalRoute('deliverables/:deliverableId', 'Upload Content', ({deliverableId}) => <EditDeliverable campaign={campaign} objectives={objectives} offerId={campaign?.id} contentId={invitationId} typeId={deliverableId} reviewType={campaign?.reviewType} /> ),
    makeModalRoute('shipping', 'Shipping Information', () => <InviteShipping primaryAddress={primaryAddress} contentId={invitationId} refreshCampaign={() => refreshCampaign() }/> ),
    makeModalRoute('signup', 'Sign up to Weave', () => <InviteAccount hasAccount={hasAccount} mode="signup" contentId={invitationId} /> ),
    makeModalRoute('login', 'Login to Weave', () => <InviteAccount hasAccount={hasAccount} mode="login" contentId={invitationId}/> ),
    // makeModalRoute( 'socials', 'Connect Socials', () => <InviteSocialsInfo socials={[]} mode="full" contentId={invitationId} userId={campaign?.content?.userid}/> ),
  ];

  // todo: Remove this and improve the animation. When you go between deliverable list and the deliverable itself,
  // it would render both routes and we can't use switch or it will kill the animation in either case
  if (!location.pathname.match(/deliverables\/[0-9]+/g)?.length) {
    subRoutes.push(makeModalRoute('deliverables', 'Deliverables', () => <InviteDeliverables objectives={objectives} reviewType={campaign?.reviewType} /> ) );
  }

  // const getSubmissions = async (offerId: string) => {
  //   const submissionResponse = await _getSubmissions(offerId);
  //   setSubmissions(submissionResponse.map((submission: any) => ({
  //     ...submission,
  //     value: submission.min || 0,
  //     image: Globals.icon(submission.icon)
  //   })));
  // };


  const [canSwipe, setCanSwipe] = useState(false);

  return (
    <div className="standalone-invite">
      <SwipeableRoutes
        replace
        disabled={canSwipe || isiOS()}
        onChangeIndex={() => setCanSwipe(window.location.href.endsWith('/overview'))}
      >
        <Route
          path={`/collab/${invitationId}/overview`}
          exact
          key="overview"
          render={() => (
            <div>
              <InviteHeader invitation={invitation} loading={loading}>
                <InviteBody invitation={invitation} loading={loading}>
                  <InviteBountyInfo
                    hasAccount={hasAccount}
                    campaign={campaign}
                    content={campaign?.content}
                    contentId={invitationId}
                    invitation={invitation}
                    products={products}
                    selectedProduct={selectedProduct}
                    primaryAddress={primaryAddress}
                    objectives={objectives}
                  />
                </InviteBody>
              </InviteHeader>
            </div>
          )}
        />

        <Route
          key="slideout"
          path={`/collab/${invitationId}/overview/*`}
          exact
          render={() => <> { subRoutes } </>}
        />


      </SwipeableRoutes>
    </div>
  );
}
