import React from 'react';
import Globals from '../global-functions.js';
import BrandTile from './brand-tile.jsx';
import SlideOut from './slide-out.jsx';

export default class Merchants extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      merchants: false,
      searching: false,
      user: Globals.getUser(),
      brand_campaigns: [],
      expandedCampaign: false,
      slideOutActive: 'false'
    }
  }

  componentDidMount() {
    Globals.pageView(window.location.pathname + window.location.search)
    if (!this.state.user.internal){ window.Intercom("update"); }

    this.getBrandCampaigns().done((response) => {
      this.setState({
        brand_campaigns: response.campaigns
      })
    })
  }

  getBrandCampaigns(){
    return $.ajax({
      method: 'GET',
      url: '/api/Campaign/getBrandCampaigns',
      data: { userid: this.state.user.userid }
    })
  }

  _requestCampaign(params){
    return $.ajax({
      method: 'POST',
      url: '/api/Campaign/requestCampaign',
      data: { userid: this.state.user.userid, user: this.state.user, name: params.name  }
    })
  }

  _deleteRequestCampaign(params){
  }

  pressEnter(e){
    if (e.charCode == 13){
      this.search()
    }
  }

  search(){
    Globals.logEvent('event', 'searchMerchant')
    this.setState({
      merchants: false,
      searching: true
    })
  }

  viewOffer(campaign){
    console.log(campaign)
    this.setState({
      expandedCampaign: campaign,
      slideOutActive: 'true'
    })
  }

  deleteRequestCampaign(params){
    this._deleteRequestCampaign(params).done((response) => {
      this.state.brand_campaigns[params.num].pending = false
      this.setState({
        brand_campaigns: this.state.brand_campaigns
      })
    })
  }

  viewOffer(campaign){
    console.log(campaign)
    this.setState({
      expandedCampaign: campaign,
      slideOutActive: 'true'
    })
  }

  closeSlideOut(_this){
    _this.setState({
      slideOutActive: false
    })
    setTimeout(() => {
      _this.setState({
        expandedCampaign: false,
        slideOutType: false
      })
    }, 500)
  }

  render() {

    let merchantStatus = !this.state.merchants ? false : true, merchants = []
    if (merchantStatus){
      merchants = this.state.merchants.map(function(merchant){
        return (
          <tr key={merchant.id}>
            <td>{ merchant.name }</td>
            <td>{ merchant.domain }</td>
            <td>{ merchant.cpa_rate ? parseFloat(merchant.cpa_rate).toFixed(2) + '%' : 'not offered' }</td>
            <td>{ merchant.cpc_rate ? '$' + merchant.cpc_rate : 'not offered' }</td>
          </tr>
        )
      })
    }

    return (
      <div>
        <div className="row" style={{margin: 0}}>
          <h1 style={{fontFamily: 'Barlow', fontWeight: '900', textTransform: 'uppercase'}}>Bounties</h1>
          <p style={{color: 'rgb(189, 187, 192)'}}>
            Complete bounties to earn money. Browse the bounties below and accept ones you are interested in.
            Once you activate a bounty, you complete the bounty by streaming yourself play the game.
          </p>
        </div>

        <div className="row">
          <div className="col-md-4">
            <h3>Choose Game</h3>
            <p style={{color: 'rgb(189, 187, 192)'}}>
              Choose a game offer and redeem the game code to download for free.
            </p>
          </div>
          <div className="col-md-4">
            <h3>Play</h3>
            <p style={{color: 'rgb(189, 187, 192)'}}>
              Stream and play the game - Weave will detect your channel's stream.
            </p>
          </div>
          <div className="col-md-4">
            <h3>Get Paid</h3>
            <p style={{color: 'rgb(189, 187, 192)'}}>
              Once you conclude your stream, Weave will credit your account based on your viewership.
            </p>
          </div>
        </div>

        {/*<div className="row" style={{margin: 0}}>
          <h3 style={{padding: '0 0px 37px 0'}}>
            <div>
              <div style={{display: 'inline-block', float: 'left'}}>
                Search For A Merchant
              </div>
              <div className="search-wrap" style={{display: 'inline-block', float: 'left', margin: '-7px 0 0 20px'}}>
                <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/searchicon.svg" style={{width: '16px', margin: '5px'}} />
                <input className="search-merchant" onKeyPress={this.pressEnter.bind(this)} placeholder="Search for merchants by their name or domain (ex. macys, macys.com)" style={{display: 'inline-block', background: 'none', border: 'none', borderBottom: '1px solid gray', padding: '5px', fontSize: '14px', fontWeight: '400', width: '525px'}}></input>
                <button className="search-box-btn" onClick={this.search.bind(this, null)} style={{margin: '-11px 0 0 20px'}}>Search</button>
              </div>
            </div>
          </h3>
        </div>*/}

        {/*<div className="merchants" style={{position: 'relative', padding: '25px 0'}}>
          <div>
            <Loader active={this.state.searching} />
            <p className={`header-description merchant-unavailable ${merchantStatus && merchants.length == 0 ? '' : 'hidden'}`}>Merchant not part of the Weave network yet.</p>
            <table className={!merchantStatus || merchants.length == 0 ? 'hidden' : ''}>
              <tbody>
                <tr>
                  <th>Store</th>
                  <th>Domain</th>
                  <th>CPA (Cost Per Action)</th>
                  <th>CPC (Cost Per Click)</th>
                </tr>
                {merchants}
              </tbody>
            </table>
          </div>
        </div>*/}

        <h3>Popular Games</h3>
        <div className="row">
          {
            this.state.brand_campaigns.map((campaign, i) => {
              return (
                <BrandTile size={'col-md-2'} this={this} num={i} campaign={campaign} viewOffer={this.viewOffer} />
              )
            })
          }
        </div>

        <h3>Upcoming Launches</h3>
        <div className="row">
          {
            this.state.brand_campaigns.map((campaign, i) => {
              return (
                <BrandTile size={'col-md-2'} this={this} num={i} campaign={campaign} viewOffer={this.viewOffer} />
              )
            })
          }
        </div>

        <h3>All Games</h3>
        <div className="row">
          {
            this.state.brand_campaigns.map((campaign, i) => {
              return (
                <BrandTile size={'col-md-2'} this={this} num={i} campaign={campaign} viewOffer={this.viewOffer} />
              )
            })
          }
        </div>

        <SlideOut active={this.state.slideOutActive} user={this.state.user} campaign={this.state.expandedCampaign} closeSlideOut={this.closeSlideOut} this={this} requestCampaign={this.requestCampaign} deleteRequestCampaign={this.deleteRequestCampaign} />

      </div>
    )
  }
}
