import React from 'react';

export default class BlogTips extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){
    console.log('updating')
  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    return (
      <div>
        <h4><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/star.svg" style={{ width: '30px', margin: '-7px 10px 0 0' }} />Useful Tips for Blogs</h4>
        <p>Using Weave on Blogs gives your readers the easiest way to find and purchase products or stores you feature in your posts. Here’s some helpful tips for posting on Blogs:</p>
        <ol>
          <li>include links to images and text if you’re featuring stores and products that you want your followers to be able to easily find</li>
          <li>link to products or stores that you’re talking about throughout your blog posts</li>
          <li>create a “resources,” “shop,” or “favorite stores/products” page for viewers to easily direct to when they’re looking for your most recommended brands or products!</li>
        </ol>
      </div>
    )
  }
}
