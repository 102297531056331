import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';

export default class NewBrandsGaming extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    return (
      <div className="landing">
        <div className="section hero">
          <div className="background-strip" style={{backgroundImage: `url(${Globals.landing('bg.svg')})`}}></div>
          <div className="container">
            <Link to="/">
              <img className="logo-hero" src={Globals.icon('logo-text', 'black')} alt="Weave Logo" />
            </Link>
            <div className="login-container">
              <div className="login-container-inner">
                <Link to="/signup"><span className="signup" style={{marginRight: '45px'}}>get started</span></Link>
                <Link to="/login"><span>login</span></Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="title">
                {/*<h1>Earn money & free products as a creator.</h1>
                <p style={{color: '#000'}}>The smartest way to collaborate with brands for money & rewards.</p>*/}
                <h1>The smarter way to collaborate with gamers</h1>
                <p style={{color: '#000'}}>Weave simplifies earning money & free products for creators.</p>
                <div className="actions">
                  <div className="row row0">
                    <div className="btn-container">
                      <Link to="/signup"><span className="get-started agency btn-apply" style={{marginRight: '15px'}}>Get Started</span></Link>
                    </div>
                    <div className="btn-container">
                      <Link to="/streamers"><span className="get-started learn-more agency btn-apply">I'm a streamer</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="hero-icons">
                <img className="shadow" src={Globals.landing('tiles.png')} />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container" style={{textAlign: 'left', padding: 0}}>
            <div className="" style={{maxWidth: '650px', margin: '0 auto'}}>
              <div className="row" style={{}}>
                <div className="ur-goddamn-right" style={{maxWidth: '680px'}}>
                  <div className="" style={{display: 'block', margin: '0 auto', textAlign: 'center', width: 'calc(100%)', maxWidth: '550px'}}>
                    <h2 style={{maxWidth: '100%', marginTop: '18px'}}>The future of collaborating with brands.</h2>
                  </div>
                </div>
              </div>
              <p className="sub-text" style={{fontSize: '20px', maxWidth: '100%', margin: '25px 0 0', textAlign: 'center'}}>We built Weave after years of working with brands and influencers. We truly believe Weave is unlocking the full potential of what influencers can accomplish working with brands.</p>
            </div>
          </div>
        </div>

        <div className="section sliver" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Make money, get free products & other rewards.</h2>
                <p>Browse & accept bounties from brands with a simple click. Earn money from brand sponsorships and affiliate revenue.</p>
              </div>
              {/*<div className="col-sm-6 retailer-icons">*/}
              <div className="col-sm-6 icon-display">
                <img src={Globals.landing('bountyrewards.png')} />
              </div>
            </div>
          </div>

          <div className="container earnings-container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 feature-text">
                <h2>We keep it simple.</h2>
                <p>From receiving products to getting paid, everything you need is handled within Weave. No tedious email back-and-forth or using multiple platforms.</p>
              </div>
              <div className="col-sm-6 col-sm-pull-6 icon-display icon-display-left">
                <img src={Globals.landing('tracking.png')} />
              </div>
            </div>
          </div>
        </div>


        <div className="section" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Cash out in a click.</h2>
                <p>Earn money from brand sponsorships and affiliate revenue. Cash out your brand earnings in a click.</p>
              </div>
              <div className="col-sm-6 icon-display">
                <img src={Globals.landing('cashout.png')} />
              </div>
            </div>
          </div>
        </div>


        <div className="section" style={{overflow: 'hidden', paddingBottom: 0}}>
          <div className="shard-1"></div>
          <div className="shard-2"></div>

          <div className="container split-features" style={{display: 'none', padding: 0}}>
            <div className="col-sm-6 split-left">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/codehomelogo-blue.svg" alt="Weave Logo" />
              </div>
              <h3>ALWAYS DEVELOPING</h3>
              <p>Weave is an ever-growing platform that constantly develops new features. Our goal is to build technology for the modern day creator that helps them truly improve performance and earn more from their content. We know that you work hard to create posts that add value to people’s lives, and we want Weave to add as much value as possible to yours.</p>
            </div>
            <div className="col-sm-6 split-right">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/highfivehomeicon-blue.svg" alt="Weave Logo" />
              </div>
              <h3>CREATOR FOCUSED</h3>
              <p>We initially started Weave to help creators monetize their YouTube videos. We saw that other companies were offensively exclusive, charged outrageous fees on earnings and never built tools to actually help the performance of their users. By offering the simplest, yet most advanced platform on the internet and connecting you to thousands of retailers, we know that we can change the way you earn from your content.</p>
            </div>
          </div>

          <div className="container final-get-started">
            <h2 style={{marginBottom: '25px', color: 'white'}}>{'Ready to join?'}</h2>
            <div className="btn-container" style={{float: 'initial'}}>
              <Link to="/signup"><button className="get-started" style={{float: 'initial', margin: '15px 0 0 0'}}>Get Started</button></Link>
            </div>
          </div>

          <footer className="footer">
            <div className="container">
              <div className="logo-container footer-left">
                <img className="logo" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </div>
              <div className="footer-right">
                <a href="https://medium.com/weavesocial" target="_blank">Blog</a>
                <a href="mailto:talent@weavesocial.com?Subject=Hello" target="_top">Contact Us</a>
                <a href="/terms-of-service" target="_blank">Terms of Use</a>
                <a href="/privacy" target="_blank">Privacy Policy</a>
                <a>Weave, Inc © 2022</a>
              </div>
            </div>
          </footer>
        </div>

      </div>
    )
  }
}
