import React from 'react'
import Globals from '../../global-functions.js'
import ContactsTable from './contacts-table'

function _get(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
function _set(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

export default class EditBrandPopup extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      inputValue: '',
      addText: '+ ADD',
      personalizedMessage: '',
      draftEmail: '',
      contacts: []
    }
  }

  UNSAFE_componentWillMount(){ document.addEventListener('mousedown', this.handleClosePopup.bind(this), false) }
  componentWillUnmount(){ document.removeEventListener('mousedown', this.handleClosePopup.bind(this), false) }

  componentDidMount(){
    // this.getBrandInfo()
  }

  async getBrandInfo(){
    var { brands } = this.props, { meta, instagram } = brands[0]
    var res = await _get('getBrand', { id: meta.id }); this.setState(res)
  }

  async disconnectBrandIG(){
    var { company } = this.props, { meta, instagram } = company
    var res = await _get('disconnectBrandIG', { brandId: meta.id, username: instagram.username }); this.props.togglePopup()
  }

  handleClosePopup(e) {
    if (this.props.active){
      if (this.node && this.node.contains(e.target) && !$(e.target).hasClass('popup-out-close-icon')){ return }
      else { this.props.togglePopup() }
    }
  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }
  selectEmployee(e){  }
  chooseSort(e){  }

  render() {
    var { brands, togglePopup, _this } = this.props; var company = brands[0]; var { meta, instagram } = company

    return (
      <div ref={ node => this.node = node }>
        <div className="add-influencer-popup">
          <div className="add-influencer-popup-container">
            <div className="add-influencer-popup-module" style={{width: '850px', height: '575px', maxWidth: '90%'}}>
              <div className="add-influencer-popup-outer">
                <div className="add-influencer-popup-inner">
                  <div className="popup-out-close-icon" onClick={togglePopup.bind(_this)} style={{position: 'absolute', top: '25px', right: '25px', width: '15px', height: '15px', cursor: 'pointer'}}>
                    <img src={Globals.icon('close')} style={{width: '100%', height: '100%'}} />
                  </div>
                  <div className="row row0">
                    <h3>Edit Brand</h3>
                  </div>
                  <div className="row row0">
                    <p>@{instagram.username} • {Globals.convertNumberFormat(instagram.followerCount)}</p>
                    <button className="btn-action btn-error" onClick={this.disconnectBrandIG.bind(this)}>Disconnect IG</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
