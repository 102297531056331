import { memo } from 'react';
import { Link } from 'react-router-dom';
import Icon from './icon'

const NavigationMenuItem = ((props) => {
  let { id, name, icon, iconPack, page, firstItem, updateMenu, _this } = props
  return (
    <li key={`nav-menu-${id}`} id={id} className={_this.state[id]} onClick={updateMenu.bind(_this)}>
      { firstItem && <div className={`select-pillar ${page}`}><div className="select-pillar-pill"></div></div> }
      <Link to={`/${id}`}>
        <div className="left-menu-circle"></div>
        <div>
          <Icon icon={icon} iconPack={iconPack} style={{marginTop: '3px', marginLeft: '6px'}} width="20px" defaultColor={_this.state[id] ? false : true} />
        </div>
        <div>
          <p>{name}</p>
        </div>
      </Link>
    </li>
  )
})

export default memo(NavigationMenuItem)
