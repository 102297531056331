import { all, call, put, takeLatest } from 'typed-redux-saga';
import { GetBrandCampaignAction, brandCampaignActionCreators } from '../actions/brand-campaign.actions';
import { fetchBrandCampaign } from '../../services/campaign-service';

function* getBrandCampaignSaga(action: GetBrandCampaignAction) {
  try {
    const brandCampaignResponse = yield* call(fetchBrandCampaign, action.payload);
    return yield* put(brandCampaignActionCreators.getBrandCampaignSuccessAction(brandCampaignResponse));
  } catch (e) {
    yield put(brandCampaignActionCreators.getBrandCampaignFailAction(e));
  }
}


export function* brandCampaignSagas() {
  yield all([
    takeLatest('brandCampaign/GET', getBrandCampaignSaga),
  ]);
}
