import { memo } from 'react'
import Globals from '../../global-functions.js'
import Icon from '../icon'

const CrmMenuEntity = ((props) => {
  var { title, icon, entity, selectedEntity, toggleEntity, slider, _this, isDev } = props
  return (
    <> { isDev && Globals.isProd ? (<></>) : (
    <>
      <div className={`crm-toggle-entity ${selectedEntity==entity ? 'active' : ''}`} onClick={toggleEntity.bind(_this, entity)}>
        <div className="crm-toggle-entity-inner">
          <Icon iconPack={icon} defaultColor={!(selectedEntity==entity)} activeColor={selectedEntity==entity} width="18px" />
          <span className="crm-toggle-entity-text">{title}</span>
        </div>
        { slider && <div className="crm-toggle-entity-slider"><div className="crm-toggle-entity-slider-bar"></div></div> }
      </div>
    </>
    ) } </>
  )
})

export default memo(CrmMenuEntity);
