import React from 'react';
import Globals from '../../global-functions.js';
import ShippingTrackingAppBar from './shipping-tracking-app-bar.jsx';
import SlideOutProgressTitle from '../slide-out/slide-out-progress-title.jsx';

var countdown

export default class ShippingTrackingApp extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00'
    }
  }

  componentDidMount(){
    this.handleEvents()
  }

  componentWillUnmount(){
    clearInterval(countdown)
    countdown = false
  }

  handleEvents(){
    countdown = setInterval(this.handleCountdown.bind(this), 500)
  }

  handleCountdown(){
    var time = 1598344290120,
    days = Globals.getTimeTo(time, true).days >= 0 ? Globals.getTimeTo(time, true).days : '00',
    hours = Globals.getTimeTo(time, true).hours >= 0 ? Globals.getTimeTo(time, true).hours : '00',
    minutes = Globals.getTimeTo(time, true).minutes >= 0 ? Globals.getTimeTo(time, true).minutes : '00',
    seconds = Globals.getTimeTo(time, true).seconds >= 0 ? Globals.getTimeTo(time, true).seconds : '00'

    this.setState({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    })
  }

  render() {
    var props = this.props, status = this.props.status, content = {}, animated = true
    return (
      <div>
        <div className={`shipping-tracking shipping-tracking-game ${animated ? 'animated' : ''}`}>
          <div className="row row0 shipping-stages stages-3" style={{margin: '25px 0'}}>
            <ShippingTrackingAppBar status={status} animated={true} size="large" />
          </div>

          {/*<h3 style={{marginTop: '25px'}}>Status</h3>*/}
          <div className="row row0 shipping-meta">
            { status == 'brand_awaiting' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_applied' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_responded' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
            { status == 'brand_invited' && <SlideOutProgressTitle title="You are invited" description="Review and accept the invitation here." /> }
            { status == 'brand_active' && <SlideOutProgressTitle title="Post Content" description="Follow the guidelines below to promote the app" /> }
            { status == 'brand_live' && <SlideOutProgressTitle title="Promote your link" description="Continue to promote your link until bounty expires" /> }
            { status == 'brand_completed' && <SlideOutProgressTitle title="Bounty Completed" description="The bounty has now been finished" /> }
          </div>

          <hr />

        </div>
      </div>
    )
  }
}
