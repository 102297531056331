import React from 'react';
import Globals from '../../global-functions.js';

export default class CreateInvoiceTitle extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  render() {
    var { title, subtext, style } = this.props
    style = { marginBottom: '25px', ...(style || {}) }

    return (
      <div className="create-invoice-title" style={style}>
        <div className="row row0">
          <h3 style={{padding: 0, margin: '0 0 8px 0'}}>{title}</h3>
          <p className="sub-text" style={{margin: 0}}>{subtext}</p>
        </div>
      </div>
    )
  }
}
