import React from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainCell from './crm-table-main-cell'
import EmptyMessage from '../empty-message'

export default class InfluencerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Globals.getUser(),
    };
  }

  componentDidMount() {}

  render() {
    var { _this, influencers, sortFilter, chooseSort, selectBrand, editBrand, editList, editContacts, loading } = this.props, user = this.state.user;
    var selectedActiveAll = this.props.selectedActive

    return (
      <div>
        <div className="crm-table" style={{height: 'calc(100vh - 117px)', width: 'calc(100% + 50px)', borderRadius: 0, boxShadow: 'none', border: 'none'}}>
          <div className="crm-table-header" style={{borderTop: '1px solid #eeeeee'}}>
            <div className={`row row0 crm-row ${selectedActiveAll ? "selected" : ""}`}>
              <div className="crm-td crm-td-xs crm-td-img" style={{paddingTop: '12px', paddingBottom: '12px', height: '44px'}}>
                <input type="checkbox" style={{ display: "block" }} onClick={selectBrand.bind(_this, "all")} checked={selectedActiveAll} />
              </div>
              <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Website" title="Website" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              <CRMTableHeaaderCell label="Actions" title="Actions" size="crm-td-md" _this={_this} />
              <CRMTableHeaaderCell label="Followers" title="Followers" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Bio" title="IG Bio" size="crm-td-xl" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Socials" title="Socials" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Username" title="Username" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Hubspot" title="Hubspot" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Pipelines" title="Pipelines" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Pitching" title="Pitching" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Outreach" title="Outreach" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Contact" title="Contact" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Lists" title="Lists" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Industry" title="Industry" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Country" title="Country" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Shared #" title="Shared #" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Ad Spend" title="Ad Spend" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="#ads" title="#ads" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="@ mentions" title="@ mentions" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Language" title="Language" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="IG Category" title="IG Category" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Keywords" title="Keywords" size="crm-td-xl" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="First Contact" title="First Contact" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Last Contact" title="Last Contact" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}

              {/*<CRMTableHeaaderCell label="Likes" title="Likes" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Comments" title="Comments" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Deals ($)" title="Deals ($)" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Deals (#)" title="Deals (#)" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Revenue" title="Revenue" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Employees" title="Employees" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Funding" title="Funding" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Alexa" title="Alexa" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              <CRMTableHeaaderCell label="Last Updated" title="Last Updated" size="crm-td-md" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Bounties" title="Bounties" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Clicks" title="Clicks" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Posts" title="Posts" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
            </div>
          </div>

          <div className="crm-table-main" style={{height: 'calc(100vh - 170px)'}}>
            { !loading && influencers && influencers.map((i) => {
              var { social, instagram } = i
              social=social||{}; instagram=instagram||social;
              var infName = social.displayName || social.username,
                  imageUrl = social.imageUrl, initial = infName ? infName[0] : '',
                  keyId = `${i.id||''}-${social.username}`


              return (
                <div key={`insightsTable-${keyId}`} className={`row row0 crm-row crm-row-influencer ${i.selectedActive ? "selected" : ""}`}>
                  <div className="row row0">
                    <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={imageUrl} initial={initial} influencer={instagram} hoverFunc={selectBrand.bind(_this, i)} _this={_this} />
                    <CRMTableMainCell title={infName} size="crm-td-ml" classes="crm-username" instagram={social} type="main-name" triggerSlideOut={this.props.triggerSlideOut} instagram={social} influencer={social} influencerLabel={'InstagramUser'} _this={_this} />
                    {/*<CRMTableMainCell title={brand.domain} size="crm-td-md" domain={brand.domain} _this={_this} />*/}
                    {/*<CRMTableMainCell title={instagram.username ? '@'+instagram.username : ''} size="crm-td-md" classes="crm-username crm-td-dbl" clickFunc={this.props.triggerSlideOut} _this={_this} />*/}
                    <CRMTableMainCell type="actions" size="crm-td-md" editBrand={editBrand} editList={editList} editContacts={editContacts} brand={i} _this={_this} />
                    <CRMTableMainCell title={Globals.convertNumberFormat(social.followerCount) || ''} size="crm-td-sm" />
                    <CRMTableMainCell title={social.bio} size="crm-td-xl" type="dbl-text" classes="crm-td-dbl" clickFunc={this.props.triggerSlideOut} _this={_this} />
                    {/*<CRMTableMainCell size="crm-td-sm" type="socials" socials={socials} _this={_this} />*/}
                    {/*<CRMTableMainCell title={instagram.username ? '@'+instagram.username : ''} linkUrl={instagram.username ? `https://instagram.com/${instagram.username}`: ''} size="crm-td-md" _this={_this} />*/}
                    {/*<CRMTableMainCell title={hubspot && hubspot.domain ? '✅' : ''} size="crm-td-sm" _this={_this} />*/}
                    {/*<CRMTableMainCell title={pipelines.map((c) => { return c.name }).join(', ')} size="crm-td-lg" type="tile" tileColor={pipelines.length > 0 ? 'color-complete' : 'color-error'}  _this={_this} />*/}
                    {/*<CRMTableMainCell title={pitchingStatus} size="crm-td-sm" type="tile" tileColor={pitchingStatus ? 'color-complete' : 'color-error'}  _this={_this} />*/}
                    {/*<CRMTableMainCell title={pipelines.length > 0 ? 'Contacted' : ''} size="crm-td-sm" type="tile" tileColor={hubspot && hubspot.domain ? 'color-complete' : 'color-error'}  _this={_this} />*/}
                    {/*<CRMTableMainCell title={contacts.map((c) => { return c.email }).join(', ')} size="crm-td-lg" _this={_this} />*/}
                    {/*<CRMTableMainCell title={i.lists ? i.lists.map((l) => { return l.name }).join(', ') : ''} size="crm-td-ml" _this={_this} />
                    <CRMTableMainCell title={meta.industry} size="crm-td-ml" _this={_this} />
                    <CRMTableMainCell title={meta.country} size="crm-td-md" _this={_this} />
                    <CRMTableMainCell title={Globals.convertNumberFormat(relatedCt || 0) || ''} size="crm-td-sm" />
                    <CRMTableMainCell title={estimatedSpend} size="crm-td-md" />
                    <CRMTableMainCell title={Globals.convertNumberFormat(instagram.AdsAllTime) || ''} size="crm-td-sm" />
                    <CRMTableMainCell title={Globals.convertNumberFormat(instagram.MentionsAllTime) || ''} size="crm-td-sm" />
                    <CRMTableMainCell title={meta.languages ? meta.languages.join(', ') : ''} size="crm-td-md" _this={_this} />
                    <CRMTableMainCell title={categories ? categories.map((c) => { return c.name }).join(', ') : ''} size="crm-td-lg" _this={_this} />
                    <CRMTableMainCell title={meta.keywords ? meta.keywords.join(', ') : ''} size="crm-td-xl" _this={_this} />*/}
                    {/*<CRMTableMainCell title={i.firstContacted ? moment(i.firstContacted).format('MMM D, YYYY') : ''} size="crm-td-md" _this={_this} />*/}
                    {/*<CRMTableMainCell title={i.lastContacted ? moment(i.lastContacted).format('MMM D, YYYY') : ''} size="crm-td-md" _this={_this} />*/}
                    {/*<CRMTableMainCell title={Globals.convertNumberFormat(instagram.avgLikes) || ''} size="crm-td-sm" />*/}
                    {/*<CRMTableMainCell title={Globals.convertNumberFormat(instagram.avgComments) || ''} size="crm-td-sm" />*/}
                    {/*<CRMTableMainCell title={`$${Globals.convertNumberFormat(hubspot.totalDealsAmount || 0)}`} size="crm-td-sm" />*/}
                    {/*<CRMTableMainCell title={`${Globals.convertNumberFormat(hubspot.totalDeals || 0)}`} size="crm-td-sm" />*/}
                    {/*<CRMTableMainCell title={Globals.convertNumberFormat(meta.annual_revenue) || ''} precursor="$" size="crm-td-sm" />
                    <CRMTableMainCell title={Globals.convertNumberFormat(meta.estimated_num_employees) || ''} size="crm-td-sm" />
                    <CRMTableMainCell title={Globals.convertNumberFormat(meta.total_funding || meta.market_cap) || ''} precursor="$" size="crm-td-sm" />
                    <CRMTableMainCell title={Globals.convertNumberFormat(meta.alexa_ranking) || ''} size="crm-td-sm" />*/}
                    <CRMTableMainCell title={moment(social.lastRefreshed).format('MM-DD-YYYY')} size="crm-td-md" _this={_this} />
                    {/*<CRMTableMainCell title={i.bountyCt ? i.bountyCt : "0"} size="crm-td-sm" />
                    <CRMTableMainCell title={i.totalClicks ? i.totalClicks : "0"} size="crm-td-sm" />
                    <CRMTableMainCell title={i.totalPosts ? i.totalPosts : "0"} size="crm-td-sm" />*/}
                  </div>
                </div>
              );
            })}

            {!influencers && !loading && <EmptyMessage title={'Start influencer search'} subtext={'Adjust your filters on left to target your ideal influencers'} align="top" />}
            {influencers.length == 0 && !loading && <EmptyMessage title={'No Influencers Found'} subtext={'Adjust your filters to widen your influencer search'} align="top" />}
            {loading && <Loader active={true} title="Compiling influencer data..." subtext="Queries with more filters on will take longer to process" />}
          </div>
        </div>
      </div>
    );
  }
}
