import { createReducer } from 'typesafe-actions';
import { UpdateContentForReviewFailed, UpdateContentForReviewResponse } from '../models/content.models';
import { contentActionCreators, ContentActions } from '../actions/content.actions';
import { Nullable } from '../../utils/utils';

type UpdateContentForReviewState = {
  data: Nullable<UpdateContentForReviewResponse>;
  loading: boolean;
  hasError: boolean;
  error: Nullable<UpdateContentForReviewFailed>;
};

const initialState: UpdateContentForReviewState = Object.freeze({
  data: null,
  hasError: false,
  loading: false,
  error: null
});

export const updateContentForReviewReducer = createReducer<UpdateContentForReviewState, ContentActions>(
  initialState,
).handleAction(contentActionCreators.updateContentForReviewAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    contentActionCreators.updateContentForReviewSuccessAction, (state, action) => ({
      ...initialState,
      data: action.payload,
    }),
  )
  .handleAction(contentActionCreators.updateContentForReviewFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

