import React from 'react';
import Globals from '../../global-functions.js';
import moment from 'moment'
import IconPack from '../icon-pack.jsx'
import Loader from '../loader.jsx'
import InvoiceOverview from './invoice-overview.jsx'
import InvoicePayments from './invoice-payments.jsx'
import InvoiceHeader from './invoice-header.jsx'

export default class Invoice extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      deals: [],
      pitches: [],
      graphData: {},
      startDate: moment().subtract('5', 'month').startOf('month').format('YYYY/M/D'),
      endDate: moment().format('YYYY/M/D'),
      incrementType: 'month',
      paymentType: null,
      invoiceLoaded: false,
      invoice: {}
    }
  }

  componentDidMount(){
    const { invoiceId } = this.props.match.params
    this.getInvoice(invoiceId)
  }

  getInvoice(invoiceId){ this._getInvoice(invoiceId).done((response) => { this.setState({ ...response, invoiceLoaded: true }) }) }
  _getInvoice(invoiceId){ return $.ajax({ method: 'GET', url: '/api/Invoice/get', data: { invoiceId } }) }

  goBack() { this.setState({ paymentType: null }) }

  render() {
    function formatAmount(amount){ return (<span><span className="sub-text invoice-dollar">$</span>{Globals.numberWithCommas(amount)}</span>) }
    var { paymentType, invoice, invoiceLoaded } = this.state
    var { id, name, email, amount, deliverables, notes, status } = invoice
    // invoice = {
    //   id: 343,
    //   // amount: 6500,
    //   amount: 1,
    //   invoiceOwner: 'Makenzie Wilburn',
    //   address: '7403 Alverstone Ave, Los Angeles, CA 90045',
    //   status: 'unpaid',
    //   name: 'Beachwaver',
    //   netTerms: 30,
    //   dueDate: 'July 7, 2022',
    //   email: 'alyssa@beachwaver.com',
    //   deliverables: [
    //     { description: '1 TikTok Integration', amount: 5000, platform: 'tiktok' },
    //     { description: '1 Instagram Story', amount: 750, platform: 'instagram' },
    //     { description: '1 YouTube Mention', amount: 750, platform: 'youtube' }
    //   ],
    //   notes: `Still waiting on deliverable to be posted`
    // }

    return (
      <div className="invoice-page">
        {/*<script src="https://www.paypal.com/sdk/js?client-id=AVFfpKvBnbK2ZbIMO0Pc0zJ-TLClITx51Jhr1hRfpnpqyK5jEzlq87gvYlBFWcUDVcJevVFUNqx72ASw&currency=USD"></script>*/}
        <div className="invoice-weave-logo-container"><div className="invoice-weave-logo"><img className="logo-hero" src={Globals.icon('logo-text', 'black')} alt="Weave Logo"/></div></div>
        {/*<div className="login-bg-wave" style={{backgroundImage: `url('/images/login-wave.svg')`}}></div>*/}
        <div className="invoice-box">
          { !invoiceLoaded && <Loader active={true} /> }
          { invoiceLoaded &&
            <div>
              <div className="invoice-box-inner">
                <InvoiceHeader invoice={invoice} show={true} />
                {/*<InvoiceHeaderSecondary invoice={invoice} goBack={this.goBack.bind(this)} show={paymentOption ? true : false} />*/}
                <div className="invoice-scrollable">
                  <InvoiceOverview this={this} deliverables={deliverables} amount={amount} notes={notes} />
                </div>
              </div>

              <InvoicePayments this={this} invoice={invoice} />

              <div className="row row0" style={{marginTop: '50px', textAlign: 'center'}}>
                <p className="sub-text">If you have any questions, please contact <b><u><a>Weave Support</a></u></b></p>
              </div>
            </div>
          }
        </div>
        {/*<img className="signup-bg-wave" src="/images/homepage/signup-bg-wave.svg" />*/}
      </div>
    )
  }
}
