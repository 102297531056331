import React from 'react';
import Globals from '../global-functions.js';
import ShippingTrackingProductBar from './shipping-tracking/shipping-tracking-product-bar.jsx';
import SlideOutProgressTitle from './slide-out/slide-out-progress-title.jsx';

export default class BountyProduct extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {

    var username = this.props.username,
    campaign = this.props.campaign,
    game = campaign.game ? campaign.game : campaign.offer,
    content = campaign ? campaign.content : {},
    campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    user = this.props.user ? this.props.user : {},
    offer = campaign.offer

    var name = offer.bountyTitle, status = content.status

    return (
      <div>
        <div className="brand-game">
          <div className="brand-image">
            <div className="brand-image-spread" style={{backgroundImage: `url(${offer.bannerImageUrl ? offer.bannerImageUrl : offer.imageUrl})`}}>
              <div className="brand-image-profile" style={{backgroundImage: `url(${offer.imageUrl})`}}></div>
            </div>
          </div>

          <div className="brand-meta">
            <h3>{name}</h3>
            <div style={{padding: '0 25px'}}>
              {
                status == 'brand_invited' &&
                <div className="row row0">
                  <div className="bounty-tile-icon" style={{width: '44px', heigth: '44px'}}>
                    <img src={Globals.icon('star-filled', 'white')} style={{width: '16px', marginTop: '-2px'}} />
                  </div>
                </div>
              }
              { status != 'brand_invited' && status != 'brand_completed' && status != 'brand_live' &&
                <ShippingTrackingProductBar status={status} size="small" />
              }
            </div>

            <div className="row row0" style={{paddingTop: '8px'}}>

              { status == 'brand_awaiting' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
              { status == 'brand_applied' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
              { status == 'brand_responded' && <SlideOutProgressTitle title="Awaiting Acceptance" description="You will be notified once you're accepted" /> }
              { status == 'brand_invited' && <SlideOutProgressTitle title="You are invited" description="Review and accept the invitation here." /> }
              {
                status == 'brand_active' &&
                offer.giftingType != 'discount' &&
                <SlideOutProgressTitle title="Awaiting Gift Shipment" description="You will be notified once your gift package ships." />
              }
              {
                status == 'brand_active' &&
                offer.giftingType == 'discount' &&
                <SlideOutProgressTitle title="Order Free Gift" description="Click here for instructions." />
              }

              { status == 'brand_shipped' && <SlideOutProgressTitle title={`Your package is on its way!`} description="You can keep up to date with shipping here" /> }
              { status == 'brand_received' && <SlideOutProgressTitle title="Post content" description="Create content and post it on your channel(s)." /> }
              { status == 'brand_review' && <SlideOutProgressTitle title="Awaiting Content Approval" description="Brand will approve content to post live or suggest edits" /> }
              { status == 'brand_live' && <SlideOutProgressTitle title="Awaiting Confirmation" description="Brand will confirm you post is complete" /> }
              {/*{ status == 'brand_completed' && <SlideOutProgressTitle title="Bounty Completed" description="The bounty has been completed" /> }*/}

              {
                (status == 'brand_live' || status == 'brand_completed') &&
                <>
                  <div className="row row0">
                    <div className="col-xs-4">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>${content.totalSales ? Globals.convertNumberFormat(content.totalSales) : '0.00'}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Sales</h4>
                    </div>
                    <div className="col-xs-4">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>${content.totalCommission ? Globals.convertNumberFormat(content.totalCommission) : '0.00'}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Earnings</h4>
                    </div>
                    <div className="col-xs-4">
                      <h2 style={{fontSize: '28px', fontWeight: '700'}}>{content.link && content.link.count ? Globals.convertNumberFormat(content.link.count) : 0}</h2>
                      <h4 className="sub-text shipping-meta-title" style={{fontSize: '14px'}}>Clicks</h4>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
