import { memo } from 'react';
import moment from 'moment';
import Globals from '../../global-functions.js';

const CRMTableHeaderCell = ((props) => {

    var { sortFilter, label, icon, iconPack, size, title, chooseSort, _this } = props
    var sortFilter = sortFilter ? sortFilter.toLowerCase() : '', label = label ? label.toLowerCase() : '', iconHtml
    if (icon) { iconHtml = (<img src={Globals.icon(icon)} style={{width: '14px', margin: '-1px 4px 0 -9px', opacity: '0.45'}} />) }
    else if (iconPack) { iconHtml = (<img src={Globals.iconPack(iconPack)} style={{width: '14px', margin: '-1px 4px 0 -9px', opacity: '0.45'}} />) }
    var sortDirectionIcon, sortActive = sortFilter.indexOf(label) > -1
    if (sortFilter && sortFilter.toLowerCase().indexOf('desc') > -1) { sortDirectionIcon = Globals.icon('arrow-down-1') }
    else if (sortFilter && sortFilter.toLowerCase().indexOf('asc') > -1) { sortDirectionIcon = Globals.icon('arrow-up-1') }
    var colStyle = { paddingTop: '12px', paddingBottom: '12px', height: '44px' }

    return (
      <div>
        { chooseSort &&
          <div className={`crm-td-menu crm-td ${size} ${sortActive ? 'active' : ''}`} onClick={chooseSort.bind(_this, title)} style={colStyle}>
            <p className="sub-text">{iconHtml}{title} { sortActive && <span><img className="sort-indicator" src={sortDirectionIcon} style={{ width: '12px', margin: '-2px 0 0 4px' }} /></span> }</p>
          </div>
        }
        { !chooseSort &&
          <div className={`crm-td-menu crm-td ${size} ${sortActive ? 'active' : ''}`} style={colStyle}>
            <p className="sub-text">{iconHtml}{title} { sortActive && <span><img className="sort-indicator" src={sortDirectionIcon} style={{ width: '12px', margin: '-2px ​0 0 4px' }} /></span> }</p>
          </div>
        }
      </div>
    );
})

export default memo(CRMTableHeaderCell);
