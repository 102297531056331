import React from 'react';
import Globals from '../../global-functions.js';
import LinkOptions from './link-options.jsx';

var labelUpdater = 0

export default class LinkModule extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      expanded: false,
      domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me'
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  focus(link){ $(`.${link.hash} hr.fill`).addClass('active') }
  blur(link){ $(`.${link.hash} hr.fill`).removeClass('active') }

  copyLink(link){
    $(`#aff-link-${link.hash}`).select()
    document.execCommand('copy')
    // setTimeout(() => {
    //   $(`#aff-link-${link.hash}`).removeClass('copied')
    // }, 1500)
  }

  render() {
    var link = this.props.link
    var getCoupon = ''
    if (link.affiliator == 'linkshare') { getCoupon = <span id={`link-coupon-${link.hash}`} className="copy-link-module" onClick={this.props.showCoupon.bind(this.props.this, link)}>GET COUPON</span> }
    return (
      <li id={'link-'+link.hash}>
        <div className="upper">
          {/* <div style={{display: 'inline-block', width: '65px', cursor: 'pointer'}} onClick={this.props.showProductImages.bind(this.props.this, link)}> */}
          <div style={{display: 'inline-block', width: '65px'}}>
            <img style={ {marginTop: 0, minHeight: '50px'} } src={link.imageUrl && !(link.imageUrl.substr(link.imageUrl.length-5, link.imageUrl.length).indexOf('.gif') > -1) ? link.imageUrl : '/images/product-placeholder-plain.svg'} />
          </div>
          <div style={{display: 'inline-block', width: 'calc(100% - 130px)', padding: '0 15px'}}>
            { /*
              <p className="name" style={ {marginTop: '4px'} }>{link.givenName}</p>

              <input className="name" style={ {display: 'block', marginTop: '4px'} } defaultValue={link.givenName} />
            */ }

            <div className="label-section">
              <div className="label-container">
                <input id={`link-label-${link.hash}`} name="label-input" className="label-input" placeholder="give this link a label..." defaultValue={link.givenName ? link.givenName : ''} onChange={this.props.editLinkLabel.bind(this.props.this, link)} onFocus={this.focus.bind(this, link)} onBlur={this.blur.bind(this, link)} />
                <div className={`input-fill ${link.hash}`}>
                  <hr className="back" style={link.givenName && link.givenName.length > 0 ? {} : {borderColor: 'rgb(255, 173, 173)'}} />
                  <hr className="fill" />
                  <hr className="saved" />
                </div>
              </div>
            </div>
            <input id={`aff-link-${link.hash}`} className="link" defaultValue={`http://${this.state.domain}/~${link.hash}`} readOnly />
            <span className="copy-link-module" onClick={this.copyLink.bind(this, link)}>COPY</span>
            {getCoupon}
          </div>
          <div style={{display: 'inline-block', width: '65px'}}>
            <LinkOptions link={link} searchAgain={this.props.searchAgain} archiveLink={this.props.archiveLink} this={this.props.this} />
            { /* <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/bluelink.svg" style={{maxWidth: '25px', maxHeight: '25px'}} onClick={this.copyLink.bind(this, link)} /> */ }
            {/* <img className="icon-img" src="/images/temp-icons/copy.svg" /> */}
          </div>
        </div>
        <div className="lower">
          <div style={{display: 'inline-block', float: 'left', width: '65px', height: '40px', fontSize: '12px', textAlign: 'center'}}>
            <span style={link.preferred_cp ? (link.preferred_cp == 'cpa' ? {color: '#008dff'} : {color: '#139a09'}) : {color: '#e32d2d'} }>
              {link.preferred_cp == 'cpa' ? (link.cpa_rate ? link.cpa_rate + '% cpa' : 'varies') : (link.preferred_cp == 'cpc' ? ('$' + link.cpc_rate + ' cpc') : 'unaffiliated')}
            </span>
          </div>
          <div style={{display: 'inline-block', float: 'left', width: 'calc(100% - 130px)', padding: '0 15px'}}>
            <span className="original-url" style={ {color: '#cccccc'} }>{link.original_url}</span>
          </div>
          <div style={{display: 'inline-block', float: 'left', width: '65px', height: '40px', textAlign: 'center'}}>
            <div>
              <span style={{display: 'inline-block', color: '#ccc', float: 'right', fontWeight: '400'}}>{link.count ? link.count : 0}</span>
              <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/click.svg" style={{display: 'inline-block', margin: '0 6px', float: 'right'}} />
            </div>
            <div>
              <span style={{width: '100%', float: 'right', textAlign: 'right', color: '#ccc'}}>{link.earnings && parseFloat(link.earnings) > 0 ? `$${link.earnings}` : '$0.00'}</span>
            </div>
          </div>
        </div>
      </li>
    )
  }
}
