import { Component, memo } from 'react';
import Globals from '../global-functions.js';
import Loader from './loader.jsx';
import InsightsChart from './outreach/insights-chart';
import '../../styles/scss/slideout.scss';
import '../../styles/scss/popup.scss';
import { isDev } from '../hooks';
import Chart from './chart.jsx'

var oldScroll = 0,
  oldHeight = 0,
  ALLOWED_SWIPE_TIME = 300;

async function _getInsight(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
async function _setInsight(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

class SlideOutBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Globals.getUser(),
      influencers: [],
      domains: [],
      videos: [],
      //user: JSON.parse(Cookies.get('user')),
      skip: 0,
      autocomplete: [],
      autocompleteNames: [],
      searchValue: '',
      dropdown: false,
      tags: [],
      searchArtists: [],
      chosenArtist: false,
      loading: false,
      loadingSeq: false,
      searchBtnTxt: 'Find Fans',
      links: [],
      optionPost: false,
      optionStory: false,
      optionDedicated: false,
      optionFeature: false,
      optionStream: false,
      optionMixer: false,
      tabActive: 'offer',
      message: '',
      photos: [],
      reviews: [],
      brandColors: { Vibrant: {}, DarkVibrant: {}, DarkMuted: {}, },
      gameMeta: {},
      activateBountyStatus: 'inactive',
      vodLinkVal: '',
      vodAutocomplete: [],
      scrollingDown: false,
      submitBountyBtnText: 'Submit Bounty Request',
      acceptInviteBountyBtnText: 'Click To Accept Invitation',
      bountyParticipants: [],
      postTimeData: [],
      campaign: {},
      openGraph: false
    };
  }

  // componentWillUpdate(nextState, nextProps){
  //
  // }

  UNSAFE_componentWillMount() { document.addEventListener('mousedown', this.handleClose.bind(this), false); }

  componentDidMount() {
    // if (this.props.brand_active) { }
    // if (!this.state.initiatedScroll) { this.handleScroll(); }
    // if (this.props.viewType == 'standalone'){ var offer = this.props.campaign ? this.props.campaign : {}; this.updateSlideOut(offer); }
    // this.enable();
  }

  componentWillUnmount() { document.removeEventListener('mousedown', this.handleClose.bind(this), false ); }

  // enable() { document.getElementById('slide-out').addEventListener('touchstart', this.recordTouchStartValues.bind(this)); document.getElementById('slide-out').addEventListener('touchend', this.detectSwipeDirection.bind(this)); }
  // disable() { document.getElementById('slide-out').removeEventListener('touchstart', this.recordTouchStartValues.bind(this)); document.getElementById('slide-out').removeEventListener('touchend', this.detectSwipeDirection.bind(this)); }

  // handleScroll() {
  //   this.setState({ initiatedScroll: true, });
  //
  //   oldHeight = $('.slide-out-offer-scrollable').height();
  //   $('.slide-out-offer-scrollable').scroll((e) => {
  //     var scrollTop = $(e.target).scrollTop(), newHeight = $('.slide-out-offer-scrollable').height();
  //     var direction = oldScroll > scrollTop ? 'up' : 'down';
  //     if (oldHeight != newHeight) { oldHeight = newHeight; oldScroll = scrollTop; }
  //     else {
  //       var scrollGap = Math.abs(oldScroll - scrollTop) > 50 ? true : false;
  //       if (direction == 'up' && this.state.scrollingDown && scrollGap) { oldScroll = scrollTop; this.setState({ scrollingDown: false, }); }
  //       else if ( direction == 'down' && !this.state.scrollingDown && scrollGap) { oldScroll = $(e.target).scrollTop(); this.setState({ scrollingDown: true, }); }
  //     }
  //   });
  // }

  UNSAFE_componentWillReceiveProps(props) {
    var currentBrand = this.props.brand && this.props.brand.id ? this.props.brand.id : false, newBrand = props.brand && props.brand.id ? props.brand.id : false
    if (currentBrand != newBrand && newBrand && props.active) {
      document.addEventListener('mousedown', this.handleClose.bind(this), false);
      this.getBrandMeta(props.brand)
      // var offer = props.campaign ? props.campaign : {};
      // this.updateSlideOut(offer);
    }
    else if (this.props.active && !props.active){
      document.removeEventListener('mousedown', this.handleClose.bind(this), false );
    }
  }

  async getBrandMeta(brand){
    this.setState({ loading: true })
    $('.slide-out-container').scrollTop(0);
    this.getAllInsights(brand)
    var openGraph = await $.ajax({ method: 'GET', url: `/api/BrandSearch/getSiteOpenGraph`, data: { domain: brand.meta.domain } })
    this.setState({ openGraph })

    // fetch(`https://${brand.meta.domain}` /*, options */)
    // .then((response) => response.text())
    // .then((html) => { this.setState({ previewHtml: html }) })
    // .catch((error) => { console.warn(error); });
  }

  async getAllInsights(brand){
    var params = { domain: brand.meta.domain, username: brand.instagram.username, dateType: 'month' }
    var queries = [
      // 'getBrandContacts',
      'getTopHashtags',
      'getTopMentionHashtags',
      'getTopMentions',
      'getTopMentioned',
      'getPostsOverTime'
      // 'getTopAds',
      // 'getTopMentionAds',
      // 'getTopSimilarBrands',
      // 'getTopRecommendedInfluencers' ,
      // 'getInfluencerShortlist' ,
      // 'getTopYouTube'
    ]
    queries.forEach(async (query) => { var res = await _getInsight(query, params); this.setState({ ...res, loading: false }) })
  }

  handleClose(e, close) {
    var { active, _this, closeSlideOut } = this.props
    if (active) {
      if (close) {
        console.log('1')
        closeSlideOut();
      }
      else if (this.node && this.node.contains(e.target)) { console.log('2'); return; }
      else if (e.target.className && typeof e.target.className == 'string' && e.target.className.indexOf('brand-tile') > -1) { console.log('3'); return; }
      else {
        console.log('4')
        // if (status == 'brand_offer') { this.setState({ tabActive: 'offer' }); }
        closeSlideOut();
      }
    }
  }


  render() {
    var pageHTML = '';
    var { openGraph, previewHtml, topHashtags, topMentionHashtags, topMentions, topMentioned, postTimeData } = this.state
    openGraph = openGraph || {}
    var { brand, active } = this.props
    if (!(brand && active)) return (<></>)
    var { meta, instagram } = brand
    var websiteUrl = `https://${meta.domain}`

    if (brand && active){
      pageHTML = (
        <>
          <div className="row">
            { postTimeData &&
              <Chart data={postTimeData.data} title={'Social Activity'} value={''} size={false} color={'#f00'} dataKey={'postCount'} percChange={0} includeYAxis={true} emptyData={false} id={3} />
            }
          </div>
          <div className="row">
            <InsightsChart title="Brand Hashtags" data={topHashtags} type="hashtag" size="col-md-4" _this={this} />
            <InsightsChart title="Creator Hashtags" data={topMentionHashtags} type="hashtag" size="col-md-4" _this={this} />
            <InsightsChart title="Brand Mentions" data={topMentions} type="mention" size="col-md-4" _this={this} />
            <InsightsChart title="Creator Mentions" data={topMentioned} type="mention" size="col-md-4" _this={this} />
          </div>
        </>
      )
    }

    return (
      <div id={`slide-out`} ref={(node) => (this.node = node)}>
        <div className={`slide-out outline ${active == true ? 'active' : ''} ${this.props.viewType == 'standalone' ? 'slide-out-standalone' : ''}`}>
          <div className="slide-out-container">
            { this.state.loading && <Loader active={true} /> }
            { !this.state.loading &&
              <>
                <div className="popup-module-outer">
                  <div className="popup-module-inner">

                    <div className={`game-header ${this.props.scrollingDown ? 'minimize' : ''}`} style={{background: `rgba(0,0,0,0.32)`}}>
                      <div className="game-header-banner" style={{backgroundImage: `url(${meta.imageUrl || openGraph.image_guess || openGraph.mainImg || meta.imageUrl})`}}>
                        <div className="game-header-banner-darken"></div>
                      </div>
                    </div>
                    <div className="slide-out-header">
                      <div className="restaurant-main-img" style={{backgroundImage: `url(${meta.imageUrl})`, top: '-68px'}}></div>
                      <div className="row row0" style={{paddingTop: '75px'}}>
                        <h3>{meta.name}</h3>
                        <p className="sub-text" style={{margin: 0}}>{openGraph.description || instagram.bio}</p>
                        <p className="sub-text"><a href={websiteUrl} target="_blank">{websiteUrl}</a></p>
                        {
                          meta.keywords && meta.keywords.length > 0 &&
                          <div className="row row0" style={{margin: '15px 0'}}>
                            { meta.keywords.map((c) => {
                                return <div key={c} className="bounty-tile-category" style={{float: 'initial', marginBottom: '6px'}}>{c}</div>
                              }) }
                          </div>
                        }
                      </div>
                    </div>

                    <div>
                      {pageHTML}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default memo(SlideOutBrand)
