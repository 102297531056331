import { ActionType, createAction } from 'typesafe-actions';
import { GetInvitationFailed, GetInvitationRequest, GetInvitationResponse, LoadInvitationDetails } from '../models/invitation.models';

export const invitationActionCreators = {
  getInvitationAction: createAction('invitation/GET')<GetInvitationRequest>(),
  getInvitationSuccessAction: createAction('invitation/GET/success')<GetInvitationResponse>(),
  getInvitationFailAction: createAction('invitation/GET/fail')<GetInvitationFailed>(),
  loadInvitationDetails: createAction('invitationDetails/LOAD')<LoadInvitationDetails>(),
};

export type GetInvitationAction = ActionType<
  typeof invitationActionCreators.getInvitationAction
  >;
export type GetInvitationSuccessAction = ActionType<
  typeof invitationActionCreators.getInvitationSuccessAction
  >;
export type GetInvitationFailAction = ActionType<
  typeof invitationActionCreators.getInvitationFailAction
  >;
export type LoadInvitationDetailsAction = ActionType<
  typeof invitationActionCreators.loadInvitationDetails
  >;

export type InvitationActions = ActionType<typeof invitationActionCreators>;
