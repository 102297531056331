import { createReducer } from 'typesafe-actions';
import { Nullable } from '../../utils/utils';
import { inviteUserActionCreators, InviteUserActions } from '../actions/invite-user.actions';

type InviteUserState = {
  data?: Nullable<any>;
  hasAccount: boolean;
  loading: boolean;
  hasError: boolean;
  error: Nullable<any>;
};

const initialState: InviteUserState = Object.freeze({
  hasAccount: false,
  hasError: false,
  loading: false,
  error: null
});

export const inviteUserReducer = createReducer<InviteUserState, InviteUserActions>(
  initialState,
).handleAction(inviteUserActionCreators.hasAccountAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(inviteUserActionCreators.hasAccountSuccessAction, (state, action) => ({
  ...initialState,
  data: !!action.payload?.user,
  hasAccount: !!action.payload?.user,
  loading: false,
}))
  .handleAction(inviteUserActionCreators.hasAccountFailAction, (state, action) => ({
    ...initialState,
    loading: false,
  }));

