import React from 'react';
import Globals from '../global-functions.js';

export default class IconPack extends React.Component {

  render() {
    var { icon, color, style, className } = this.props
    style = style || {}; className = className || ''
    style = { ...style, filter: `${Globals.hexToFilter(color || Globals.themeIconColor())}` }
    return (<img className={className} src={Globals.iconPack(icon)} style={style} />)
  }
}
