import React from 'react';
import Globals from '../global-functions.js';
import ConversationSummary from './conversation-summary.jsx';
import ConversationPart from './conversation-part.jsx';
import { Editor } from 'slate-react';
import Html from 'slate-html-serializer';
import Cookies from 'js-cookie';

export default class Concierge extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      messages: [],
      text: '',
      user: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {},
      user_id: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')).intercomId : {},
      convo_id: this.props.convo_id,
      value: html.deserialize('<p></p>'),
      serializedValue: '',
      addSavedBtn: 'Save',
      getSavedBtn: 'Auto',
      sendChatBtn: '',
      sentMessages: [],
      selectedConvo: 1,
      conversations: []
    }
  }

  // componentWillUpdate(nextState, nextProps){
  //
  // }

  // UNSAFE_componentWillMount(){
  //   document.addEventListener('mousedown', this.handleClose.bind(this), false)
  // }

  componentDidMount(){
    var existingValue = localStorage.getItem(`editortext-${this.props.id}`)
    this.setState({
      value: html.deserialize(existingValue || ''),
      serializedValue: existingValue
    })
    this._getChats().done((response) => {
      if (response.messages && response.messages.length > 0){
        this.setState({
          conversations: response.messages,
          selectedConvoId: response.messages[0].contentId,
          selectedConvo: response.messages[0]
        })
        this._getChat(response.messages[0].contentId).done((response) => {
          console.log(response)
    		  this.setState({
            messages: response.messages,
            convo_id: response.convo_id
          })
          setTimeout(() => {
            if (document.getElementsByClassName('chat-messages-container')[0]){ document.getElementsByClassName('chat-messages-container')[0].scrollTop = 10000000 }
          }, 5)
        })
      }
    })
  }

  UNSAFE_componentWillReceiveProps(props){
    if (props.id && this.props.id != props.id) {
      var existingValue = localStorage.getItem(`editortext-${props.id}`)
      this.setState({
        value: html.deserialize(existingValue || ''),
        serializedValue: existingValue,
        convo_id: props.convo_id
      })
    }
  }

  _getChats(id){
    return $.ajax({
    	method: 'GET',
    	url: '/api/Intercom/getChats',
    	data: { id: this.state.user.userid }
    })
  }

  onChange({ value }) {
    if (value.document != this.state.value.document) {
      var serialized = html.serialize(value)
      localStorage.setItem(`editortext-${this.props.id}`, serialized)
      this.setState({ value: value, serializedValue: serialized })
      setTimeout(() => { $('.chat-messages-container').css('padding-bottom', `${125 + ($('.chat-input').height() - 144)}px`) }, 8)
    }
    else { this.setState({ value }) }
  }

  renderMark(props, editor, next) {
    switch (props.mark.type) {
      case 'bold':
        return <strong>{props.children}</strong>
      // Add our new mark renderers...
      case 'code':
        return <code>{props.children}</code>
      case 'italic':
        return <em>{props.children}</em>
      case 'strikethrough':
        return <del>{props.children}</del>
      case 'underline':
        return <u>{props.children}</u>
      default:
        return next()
    }
  }

  sendChat(){
    var message = html.serialize(this.state.value)
    this.setState({ sendChatBtn: 'Sending...' })
    this._sendChat(message).done((response) => {
      var messages = this.state.sentMessages.concat([{
        created_at: new Date(),
        conversation_id: this.props.convo_id,
        author_type: 'admin',
        user_id: this.state.user_id,
        message: message
      }])
      this.setState({
        sendChatBtn: 'Send',
        value: html.deserialize('<p></p>'),
        serializedValue: '',
        sentMessages: messages
      })
    })
  }

  _sendChat(message){
    console.log('sending message')
    console.log({ chat_id: this.props.id, admin_id: this.state.user_id })
    return $.ajax({
    	method: 'POST',
    	url: '/api/Intercom/sendChat',
    	data: { id: this.state.selectedConvo.id, user_id: this.state.selectedConvo.intercomId, contentId: this.state.selectedConvo.contentId, message: message }
    })
  }

  // _getInfluencerPanel(id){
  //   return $.ajax({
  //   	method: 'GET',
  //   	url: '/api/Search/getInfluencerPanel',
  //   	data: { id: id }
  //   })
  // }

  addSavedResponse(){
    var message = html.serialize(this.state.value)
    this.setState({ addSavedBtn: 'Saving...' })
    this._addSavedResponse(message).done((response) => {
      this.setState({ addSavedBtn: 'Save' })
    })
  }

  _addSavedResponse(message){
    return $.ajax({
    	method: 'POST',
    	url: '/api/Intercom/addSavedResponse',
    	data: { campaign: this.props.influencer.campaign_name, message: message, status: this.props.influencer.status }
    })
  }

  getSavedResponse(){
    this.setState({ getSavedBtn: 'Analyzing...' })
    this._getSavedResponse().done((response) => {
      this.setState({
        value: html.deserialize(response.message || ''),
        serializedValue: response.message,
        getSavedBtn: 'Auto'
      })
    })
  }

  _getSavedResponse(){
    return $.ajax({
    	method: 'GET',
    	url: '/api/Intercom/getSavedResponse',
    	data: { campaign: this.props.influencer.campaign_name, status: this.props.influencer.status }
    })
  }

  focusEditor(){
    $('#intercom-chat-editor').focus()
  }

  selectConvo(params){
    this.setState({
      selectedConvo: params,
      selectedConvoId: params.contentId
    })

    this._getChat(params.contentId).done((response) => {
      console.log(response)
		  this.setState({
        messages: response.messages,
        convo_id: response.convo_id
      })
      setTimeout(() => {
        if (document.getElementsByClassName('chat-messages-container')[0]){ document.getElementsByClassName('chat-messages-container')[0].scrollTop = 10000000 }
      }, 5)
    })

    console.log(params)
  }

  _getChat(id){
    return $.ajax({
    	method: 'GET',
    	url: '/api/Intercom/getChat',
    	data: { id: id }
    })
  }

  render() {
    return (
      <div>
        {/*<h3>Messages</h3>*/}
        <div className="message-container">
          <div className="message-column">
            <div className="row" style={{margin: 0}}>
              <h3 style={{marginBottom: 0}}><b style={{marginLeft: '20px'}}>Messages</b> <span style={{fontSize: '14px'}}>{this.state.conversations.length} current</span></h3>
            </div>
            <ul>
              {
                this.state.conversations &&
                this.state.conversations.map((c) => {
                  return (
                    <ConversationSummary
                      this={this}
                      selectConvo={this.selectConvo}
                      selectedConvo={this.state.selectedConvo}
                      convo={c}
                    />
                  )
                })
              }
            </ul>
          </div>
          <div className="message-view">
            <div className="intercom-duplicate">
              <div className="intercom-background"></div>
              {
                this.state.conversations &&
                this.state.conversations.length > 0 &&
                <div>
                  <div className="chat-messages-container">
                  {
                    this.state.messages &&
                    this.state.messages.map((m) => {
                      return (
                        <ConversationPart
                          key={Math.floor(Math.random() * 9999999)}
                          influencer={{imageUrl: ''}}
                          message={m}
                        />
                      )
                    })
                  }
                  </div>

                  <div className="chat-input-container">
                    <div className="chat-input">
                      <div className="chat-input-text" style={{ minHeight: '90px', cursor: 'text' }} onClick={this.focusEditor.bind(this)}>
                        <Editor
                          id="intercom-chat-editor"
                          value={this.state.value}
                          onChange={this.onChange.bind(this)}
                          plugins={plugins}
                          renderMark={this.renderMark.bind(this)}
                          autoCorrect={true}
                          spellCheck={true}
                          placeholder={'Type response...'}
                        />
                      </div>
                      <div className="chat-input-actions">
                        {/*<button onClick={this.addSavedResponse.bind(this)} style={{background: 'rgb(26, 185, 71)', borderColor: 'rgb(26, 185, 71)'}}>{this.state.addSavedBtn}</button>
                        <button onClick={this.getSavedResponse.bind(this)} style={{background: 'rgb(255, 184, 72)', borderColor: 'rgb(255, 184, 72)'}}>{this.state.getSavedBtn}</button>*/}
                        <button onClick={this.sendChat.bind(this)}><img src={Globals.icon('send', 'white')} style={{width: '25px'}} /></button>
                      </div>
                      {/*<div className="view-in-intercom">
                        <a href={`https://app.intercom.io/a/apps/rm4ja52n/conversations/${this.props.convo_id}`} target="_blank">View in Intercom</a>
                      </div>*/}
                    </div>
                  </div>
                </div>
              }
              {
                (!this.state.conversations ||
                (this.state.conversations &&
                this.state.conversations.length == 0)) &&
                <p style={{textAlign: 'center', marginTop: '45vh', fontSize: '16px', fontFamily: 'Barlow', fontWeight: 500}}>No current conversations.</p>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}







function MarkHotkey(options) {
  const { type, key } = options
  return {
    onKeyDown(event, editor, next) {
      // console.log(event.key)
      if ( (!event.ctrlKey || event.key != key) && (event.key != 'Meta' || event.key != key) ) return next()
      event.preventDefault()
      editor.toggleMark(type)
    }
  }
}

const plugins = [
  MarkHotkey({ key: 'b', type: 'bold' }),
  MarkHotkey({ key: '`', type: 'code' }),
  MarkHotkey({ key: 'i', type: 'italic' }),
  MarkHotkey({ key: '~', type: 'strikethrough' }),
  MarkHotkey({ key: 'u', type: 'underline' }),
]

function BoldMark(props) {
  return <strong>{props.children}</strong>
}

function CodeNode(props) {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  )
}

const BLOCK_TAGS = {
  blockquote: 'quote',
  p: 'paragraph',
  pre: 'code',
}

const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  u: 'underline',
}

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'block',
          type: type,
          data: {
            className: el.getAttribute('class'),
          },
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object == 'block') {
        switch (obj.type) {
          case 'code':
            return (
              <pre>
                <code>{children}</code>
              </pre>
            )
          case 'paragraph':
            return <p className={obj.data.get('className')}>{children}</p>
          case 'quote':
            return <blockquote>{children}</blockquote>
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) {
        return {
          object: 'mark',
          type: type,
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object == 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>
          case 'italic':
            return <em>{children}</em>
          case 'underline':
            return <u>{children}</u>
        }
      }
    },
  },
]

const html = new Html({ rules })
