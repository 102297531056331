import React from 'react';
import Globals from '../../global-functions.js';

export default class VideoTile extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      videos: []
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    let video = this.props.video, date = Globals.calculateTime(video.snippet.publishedAt)
    date = date == 'just now' ? date : date + ' ago'

    return (
      <div>
        <div className="video-tile-container" onClick={this.props.chooseVideo.bind(this.props.this, video)}>
          <div className="video-tile-img-container">
            <img className="video-tile-img" src={video.snippet.thumbnails.default.url} />
          </div>
          <p className="video-title">{video.snippet.title}</p>
          <p className="video-date">{date}</p>
        </div>
      </div>
    )
  }
}
