import { memo } from 'react';
import FilterOptionMinMax from './filter-option-min-max'
import FilterOption from './filter-option'

const FilterMetrics = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <div className="row row0">
        <FilterOption title="Instagram Matched" label="include" filterType="toggle-selector" filter={filter} index={0} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOption title="Instagram Not Matched" label="exclude" filterType="toggle-selector" filter={filter} index={0} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOptionMinMax title="Followers Min - Max" index={1} filter={filter} type={type} changeFilter={changeFilter} _this={_this} />
        <FilterOptionMinMax title="Likes Min - Max" index={2} filter={filter} type={type} changeFilter={changeFilter} _this={_this} />
        <FilterOptionMinMax title="Comments Min - Max" index={3} filter={filter} type={type} changeFilter={changeFilter} _this={_this} />
        <FilterOptionMinMax title="Views Min - Max" index={4} filter={filter} type={type} changeFilter={changeFilter} _this={_this} />
      </div>
    </>
  )
})

export default memo(FilterMetrics);
