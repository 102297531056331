import React, { useState } from 'react';
import Globals from '../../global-functions';
import { InviteBlock } from './invite-block/invite-block';
import { InviteProducts } from './invite-products/invite-products';
import { InviteGuidelines } from './invite-guidelines/invite-guidelines';
import { InviteDeliverables } from './invite-deliverables/invite-deliverables';
import { InviteShipping } from './invite-shipping/invite-shipping';
import { InviteAccount } from './invite-account/invite-account';
import { useIsLoggedInSelector } from '../../redux/selectors/auth.selectors';
import { InviteSteps } from './invite-steps/invite-steps';
import { InviteCompensation } from './invite-compensation/invite-compensation';
import { InviteSocialsInfo } from './invite-socials/invite-socials-info';
import { Product } from '../../redux/models/bounty.models';
import { Objective } from '../../redux/models/content.models';

export interface InviteBountyInfoProps {
  contentId: string;
  content?: object;
  invitation: any;
  products: Product[];
  selectedProduct: Product | null;
  campaign: any;
  primaryAddress: any;
  objectives: Objective[];
  hasAccount: boolean | null;
}

enum StepNames {
  Overview = 'Overview',
  Products = 'Products',
  Socials = 'Socials',
  Rates = 'Rates',
  Tasks = 'Tasks',
  Shipping = 'Shipping',
}

export const InviteBountyInfo: React.FC<InviteBountyInfoProps> = ({
                                                                    contentId,
                                                                    content,
                                                                    objectives,
                                                                    invitation,
                                                                    primaryAddress,
                                                                    products,
                                                                    campaign,
                                                                    hasAccount,
                                                                    selectedProduct }) => {
  const isLoggedIn = useIsLoggedInSelector();
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [timeframe, setTimeframe] = useState<number>();
  // const [stepSaved, setStepSaved] = useState({
  //   [StepNames.Overview]: false,
  //   [StepNames.Products]: false,
  //   [StepNames.Rates]: false,
  //   [StepNames.Socials]: false,
  //   [StepNames.Tasks]: false,
  //   [StepNames.Shipping]: false,
  // })


  //
  // const saveStep = (step: StepNames) => {
  //   setStepSaved({
  //     ...stepSaved,
  //     [step]: true
  //   })
  // }
  //
  // const saveSelectedProduct = (product: Product) => {
  //   saveStep(StepNames.Products);
  // }

  const updateSubmissions = (submission: any) => {
    const updatedSubmissions = submissions.map(s => s.typeId === submission.typeId ? submission : s);
    setSubmissions(updatedSubmissions);
  }
  //
  // const saveSubmissions = () => {
  //   saveStep(StepNames.Tasks);
  // }
  //
  // const setRates = (form: any) => {
  //   saveStep(StepNames.Rates);
  // }
  //
  // const setAddressForm = (form: any) => {
  //   saveStep(StepNames.Shipping);
  // }
  // const signUpForWeave = () => {};

  return (
    <>
      <InviteSteps status={campaign?.content?.status} content={campaign?.content} offer={campaign?.offer} brandType={campaign?.offer?.brandType}></InviteSteps>

      <InviteBlock title="Guidelines & Assets" linkTo="guidelines" subTitleText="Please review brand guidelines & include required assets" linkText="View" icon={Globals.iconPack('document')} noMargin={true}>
        <InviteGuidelines mode="preview"></InviteGuidelines>
      </InviteBlock>

      { (campaign?.giftingType && campaign?.giftingType != 'none') &&
      <InviteBlock title="Products" linkTo="products" icon={Globals.iconPack('shopping-cart')}>
        <InviteProducts mode="preview" products={products} selectedProduct={selectedProduct} content={content} campaign={campaign} giftingType={campaign?.giftingType}></InviteProducts>
      </InviteBlock>
      }

      <InviteBlock title="Compensation" linkTo="compensation" icon={Globals.iconPack('dollar')}>
        <InviteCompensation campaign={campaign} mode="preview"></InviteCompensation>
      </InviteBlock>

      <InviteBlock title="Deliverables" linkTo="deliverables" linkText="view" icon={Globals.iconPack('hastag')}>
        <InviteDeliverables mode="preview" objectives={objectives} content={content} campaign={campaign} reviewType={campaign?.reviewType} giftingType={campaign?.giftingType}></InviteDeliverables>
      </InviteBlock>

      { campaign?.giftingType && (campaign?.giftingType == 'weave' || campaign?.giftingType == 'manual') &&
        <InviteBlock title="Shipping Information" linkTo="shipping" linkText="edit" icon={Globals.iconPack('ship-side')}>
          <InviteShipping primaryAddress={primaryAddress} mode="preview"></InviteShipping>
        </InviteBlock>
      }

      { false &&
        <InviteBlock title="Add Socials" linkTo="socials" linkText="view" icon={Globals.iconPack('cloud')}>
          <InviteSocialsInfo socials={[]} mode="preview" contentId={contentId}/>
        </InviteBlock>
      }

      { false &&
        <InviteBlock title="Account" linkTo={hasAccount? 'login' : 'signup'} linkText={hasAccount? 'Login' : 'Signup'} icon={Globals.iconPack('comment-user')}>
          <InviteAccount hasAccount={hasAccount} mode="preview"></InviteAccount>
        </InviteBlock>
      }

      {/*<div className="collapsable-options">*/}


      {/*  {!!products.length && <InviteCollapsableBlock title={'Products'} icon={'/images/gift.svg'} isSaved={stepSaved[StepNames.Products]}>*/}
      {/*    <>{products.map((gift) => <InviteProduct key={gift.id}*/}
      {/*                                             product={gift}*/}
      {/*                                             selectProduct={setSelectedProduct}*/}
      {/*                                             isSelected={isProductSelected(gift.id)}*/}
      {/*                                             brandingColor={invitation.brandingColor}/>)}*/}
      {/*      <button className="invitation-btn"*/}
      {/*              disabled={!selectedProduct}*/}
      {/*              onClick={saveSelectedProduct}*/}
      {/*              style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Save</button>*/}
      {/*    </>*/}
      {/*  </InviteCollapsableBlock>}*/}


      {/*  <InviteCollapsableBlock title={'Socials'} icon={Globals.icon('star')} isSaved={stepSaved[StepNames.Socials]}>*/}
      {/*    <>*/}
      {/*      <p className="sub-text">Connect at least one social so that we know when you post your content. We do not change or edit anything about your account.</p>*/}
      {/*      <InviteSocials/>*/}
      {/*    </>*/}
      {/*  </InviteCollapsableBlock>*/}

      {/*  <InviteCollapsableBlock title={'Rates'} icon={'/images/dollar-2.svg'} isSaved={stepSaved[StepNames.Rates]}>*/}
      {/*    <>*/}
      {/*      <p className="sub-text">*/}
      {/*        Choose between a Flat Rate and a CPM model. On average, influencers who chose CPM models make 30% more on our platform.*/}

      {/*      </p>*/}
      {/*      <InviteRatesForm setRates={setRates}*/}
      {/*                       btnBackgroundColor={invitation.oppositeBrandingColor}*/}
      {/*                       btnTextColor={invitation.brandingBtnTextColor}/>*/}

      {/*    </>*/}
      {/*  </InviteCollapsableBlock>*/}


      {/*  <InviteCollapsableBlock title={'Tasks'} icon={Globals.icon('ok')} isSaved={stepSaved[StepNames.Tasks]}>*/}
      {/*    <>*/}
      {/*      <p className="sub-text">Select what content you would like to create. This allows you and the brand to transparently understand what is expected.</p>*/}
      {/*      <div className="row row0 submission-posts">*/}
      {/*        {submissions.map((submission) => <InviteSubmission key={submission.typeId} submission={submission} updateSubmission={updateSubmissions}/>)}*/}

      {/*        <hr/>*/}

      {/*        <p className="sub-text">How many weeks will it take to post the content after you receive the product?</p>*/}

      {/*        <InviteSubmission key={'timeframe'}*/}
      {/*                          submission={{ typeId: 'timeframe', name: 'Timeframe', description: '# of weeks', value: 0, min: 0 }}*/}
      {/*                          updateSubmission={(submission) => setTimeframe(submission.value)}/>*/}
      {/*      </div>*/}
      {/*      <button className="invitation-btn"*/}
      {/*              disabled={!timeframe}*/}
      {/*              onClick={saveSubmissions}*/}
      {/*              style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Save</button>*/}
      {/*    </>*/}
      {/*  </InviteCollapsableBlock>*/}

      {/*  <InviteCollapsableBlock title={'Shipping'} icon={'/images/icons/delivery-black.svg'} isSaved={stepSaved[StepNames.Shipping]}>*/}
      {/*    <>*/}
      {/*      <p className="sub-text">Specify the address to which the selected goods should be delivered.</p>*/}
      {/*      <InviteAddressForm setAddress={setAddressForm}*/}
      {/*                         btnBackgroundColor={invitation.oppositeBrandingColor}*/}
      {/*                         btnTextColor={invitation.brandingBtnTextColor}/>*/}
      {/*    </>*/}
      {/*  </InviteCollapsableBlock>*/}
      {/*</div>*/}

      {/*<p>Want to do more? Sign up for Weave to connect with other brands and more! </p>*/}
      {/*<button className="invitation-btn"*/}
      {/*        onClick={() => signUpForWeave()}*/}
      {/*        style={{backgroundColor: `${invitation.oppositeBrandingColor}`, color: `${invitation.brandingBtnTextColor}`}}>Sign up for Weave</button>*/}
    </>
  );
};
