import React from 'react'
import Globals from '../../global-functions.js'
import Loader from '../loader'
var Promise = require('bluebird')
var request = Promise.promisifyAll(require('request'), { multiArgs: true })

function _getInsight(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data: data }) }
function _setInsight(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data: data }) }

export default class Company extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      username: false,
      account: false,
      noAccount: false,
      topHashtags: false,
      topMentionHashtags: false,
      topMentions: false,
      topMentioned: false,
      topAds: false,
      topMentionAds: false,
      topSimilarBrands: false,
      topYouTube: false,
      pendingEmailSearches: {},
      create_companyName: '',
      create_website: '',
      create_instagram: '',
      create_tiktok: '',
    }
  }

  componentDidMount(){
    const { id } = this.props.match.params
    this.setState({ id, username: id, domain: id }, () => { this.getData() })
  }

  setInsight(query, data) { return new Promise((resolve, reject) => { _setInsight(query, data).done((response) => { resolve(response); }) }) }
  getInsight(query, data){ var { id, username, domain, startDate, endDate } = this.state; return _getInsight(query, Object.assign({ id, username, domain, startDate, endDate }, data ? data : {}) ) }

  getData(){
    // this.getAllInsights()
    // this.getInsight('getAllInsights')

    this.getInsight('getBrand').done((response) => {
      if (!response || !response.account) { this.setState({ noAccount: true }) }
      var { account } = response
      if (account && account.instagram && account.instagram.username){
        var { instagram, tiktok, brand, meta, company } = account
        var domain = brand && brand.domain ? brand.domain : false
        this.setState({...response, username: instagram.username}, () => {
          // this.getInsight('getBrandContacts').done((response) => { this.setState(response); })
          this.getInsight('getTopHashtags').done((response) => { this.setState(response); })
          this.getInsight('getTopMentionHashtags').done((response) => { this.setState(response); })
          this.getInsight('getTopMentions').done((response) => { this.setState(response); })
          this.getInsight('getTopMentioned').done((response) => { this.setState(response); })
          // this.getInsight('getTopAds').done((response) => { this.setState(response); })
          this.getInsight('getTopMentionAds').done((response) => { this.setState(response); })
          // this.getInsight('getTopSimilarBrands').done((response) => { this.setState(response); })
          // this.getInsight('getTopRecommendedInfluencers', { domain }).done((response) => { this.setState(response); })
          // this.getInsight('getInfluencerShortlist', { domain }).done((response) => { this.setState(response); })
          // this.getInsight('getTopYouTube').done((response) => { this.setState(response); })
        });
      }
    })
  }

  findContactEmail(employee){
    var pendingEmailSearches = this.state.pendingEmailSearches
    if (!pendingEmailSearches[employee.id]){
      pendingEmailSearches[employee.id] = true
      this.setState({ pendingEmailSearches: pendingEmailSearches })
      // employee.domain = this.state.account.brand.domain
      this.getInsight('findContactEmail', employee).done((response) => {
        var employees = this.state.employees
        employees = employees.map((e) => { if (e.id == employee.id) { if (response){ e = response } else { e.huntedEmail = true } } return e })
        pendingEmailSearches[employee.id] = false
        this.setState({ employees: employees, pendingEmailSearches: pendingEmailSearches })
      })
    }
  }

  removeBrandContact(employee){
    this.getInsight('removeBrandContact', employee).done((response) => {
      var employees = this.state.employees.filter((e) => { if (e.id != employee.id) { return e } })
      this.setState({ employees: employees })
    })
  }

  setAccountType(username, type, module){
    this.setInsight('setAccountType', { username, type }).done((response) => {
      var users = this.state[module]
      users.forEach((u) => { if (u.username == username) { u.accountType = type } })
      this.setState({ [`${module}`]: users })
    })
  }

  setWeaveRank(weaveRanking){
    var obj = { weaveRanking }, account = this.state.account
    if (account.brand && account.brand.domain) { obj.domain = account.brand.domain }
    if (account.instagram && account.instagram.username) { obj.instagram = account.instagram.username }
    if (account.tiktok && account.tiktok.username) { obj.tiktok = account.tiktok.username }
    this.setInsight('setWeaveRank', obj).done((response) => {
      // var users = this.state.topRecommendedInfluencers, newUsers = []
      // users.forEach((u) => { if (u.username != username) { newUsers.push(u) } })
      // this.setState({ topRecommendedInfluencers: newUsers })
    })
  }

  toggleWeaveShortlist(username, type){
    var obj = { username }, account = this.state.account
    if (account.brand && account.brand.domain) { obj.domain = account.brand.domain }
    this.setInsight(type, obj).done((response) => {
      var users = this.state.topRecommendedInfluencers, newUsers = []
      users.forEach((u) => { if (u.account.username != username) { newUsers.push(u) } })
      var ns = { topRecommendedInfluencers: newUsers }
      if (type == 'addInfluencerShortlist') { ns.shortlistedInfluencers = response.shortlistedInfluencers }
      console.log(username, type)
      console.log('setting new', users.length, newUsers.length)
      this.setState(ns)
    })
  }

  toggleBrandPopup(){
    var obj = { brandPopupActive: !this.state.brandPopupActive }
    if (!this.state.brandPopupActive){
      var account = this.state.account, { brand, instagram, tiktok, company, deals, contacts } = account
      instagram = instagram ? instagram : {}
      tiktok = tiktok ? tiktok : {}
      obj.create_companyName = instagram.displayName || instagram.username
      obj.create_website = instagram.website
      obj.create_instagram = instagram.username
      obj.create_tiktok = tiktok.username
    }
    this.setState(obj)
  }

  makeBrand(){
    var { create_companyName, create_website, create_instagram, create_tiktok } = this.state
    this.setInsight('makeBrand', { name: create_companyName, website: create_website, instagram: create_instagram, tiktok: create_tiktok }).done((response) => {
      this.getData()
      this.setState({ brandPopupActive: false })
    })
  }

  onChange(type, e) {
    var val = e.target.value;
    this.setState({ [type]: val });
  }

  render() {
    var account = this.state.account, { brand, instagram, tiktok, company, deals, contacts } = account
    instagram = instagram ? instagram : {}; tiktok = tiktok ? tiktok : {}; brand = brand ? brand : {};
    var dealNum = 0, dealAmount = 0
    if (deals && deals.length > 0) { deals.forEach((deal) => { dealNum++; dealAmount += deal.amount ? Number(deal.amount) : 0; }) }
    var weaveRanking = (brand.weaveRanking || instagram.weaveRanking || tiktok.weaveRanking)

    return (
      <div style={{padding: '25px'}}>
        <div className="row row0">
          <div className="module" style={{minHeight: '350px'}}>
            <div className="row row0">
              { (!account) && <Loader active={true} /> }
              { account &&
                <div>
                  <div className="col-md-6">
                    <h1>{brand.name || instagram.displayName || instagram.username}</h1>
                    <p><a href={`//${brand.domain}`} target="_blank">{brand.domain}</a></p>
                    <p><a href={`https://instagram.com/${instagram.username}`} target="_blank">@{instagram.username} • {Globals.convertNumberFormat(instagram.followerCount)} followers</a></p>
                    <p><a href={instagram.website} target="_blank">{instagram.website}</a></p>
                    { !company && <p className="sub-text">Hubspot not connected</p> }
                    { company &&
                      <div>
                        <p><a href={`https://app.hubspot.com/contacts/21133569/company/${company.id}`} target="_blank">{company.name}</a></p>
                        <p className="sub-text">{company.description || company.bio}</p>
                        <p className="sub-text">{company.industry}</p>
                        <p className="sub-text">
                          { company.annualRevenue && <span>Annual Revenue: <b>${Globals.convertNumberFormat(company.annualRevenue)}+</b> </span> }
                          { company.numEmployees && <span># Employees: <b>{company.numEmployees}+</b> </span> }
                          { company.foundedYear && <span>Founded: <b>{company.foundedYear}</b> </span> }
                          { company.isPublic && company.isPublic == 'true' && <span>Public Company </span> }
                        </p>

                        <h5>Deals ({Globals.convertNumberFormat(dealNum)} deals for ${Globals.convertNumberFormat(dealAmount)})</h5>
                        {deals.length == 0 && <p className="sub-text">No deals yet</p>}
                        {deals.map((deal) => { return ( <p><a href={`https://app.hubspot.com/contacts/21133569/deal/${deal.id}`} target="_blank">{deal.pipeline} • ${deal.amount ? deal.amount : 0} • {deal.name} • {deal.closedAt ? moment(deal.closedAt).format('MM/DD/YYYY') : moment(deal.createdAt).format('MM/DD/YYYY')} • {deal.closedAt ? 'closed' : 'open'}</a></p> ) })}
                        <h5>Contacts</h5>
                        {contacts.length == 0 && <p className="sub-text">No contacts yet</p>}
                        {contacts.map((contact) => { return ( <p><a href={`https://app.hubspot.com/contacts/21133569/contact/${contact.id}`} target="_blank">{contact.email}</a></p> ) })}
                      </div>
                    }
                  </div>
                  <div className="col-md-6" style={{textAlign: 'right'}}>
                    <div>
                      { (brand && brand.domain) && <span style={{color: 'green'}}>Brand</span> }
                      { !(brand && brand.domain) && <button onClick={this.toggleBrandPopup.bind(this)}>Create a brand</button> }
                    </div>
                    <div>
                      <button className={`btn-action ${!weaveRanking ? `btn-primary` : `btn-secondary`}`} onClick={this.setWeaveRank.bind(this, !weaveRanking ? `brand` : `remove`)} style={{paddingLeft: '32px'}}>
                        <img src={!weaveRanking ? Globals.icon('star', 'white') : Globals.icon('star-filled')} style={{position: 'absolute', width: '16px', margin: '1px 0 0 -22px'}} />
                        {!weaveRanking ? `Mark Primary Brand` : `Unmark Primary Brand`}
                      </button>
                    </div>
                  </div>
                </div>
              }
              { this.state.noAccount && <div><h1>No account found</h1></div> }
            </div>
          </div>
        </div>

        <div className="row row0 trending-lists">

          <div className="row row0">
            <div className="trending-list-container col-md-6">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Recommended Creators <span style={{ float: 'right' }}># Brands</span></li>
                { !this.state.topRecommendedInfluencers && <Loader active={true} /> }
                {
                  this.state.topRecommendedInfluencers && this.state.topRecommendedInfluencers.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <div className="row">
                          <div className="col-md-8">
                            <p style={{margin: 0}}><a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a></p>
                            <p className="sub-text">{t.account.bio}</p>
                          </div>
                          <div className="col-md-4">
                            <button style={{ float: 'right', margin: '0 4px', background: '#ffaeae', borderRadius: '2px' }} onClick={this.toggleWeaveShortlist.bind(this, t.account.username, 'hideInfluencerShortlist')}>
                              <img src={Globals.iconPack('cross')} style={{width: '14px', marginTop: '-2px' }} />
                            </button>
                            <button style={{ float: 'right', margin: '0 4px', background: '#d3ffd3', borderRadius: '2px' }} onClick={this.toggleWeaveShortlist.bind(this, t.account.username, 'addInfluencerShortlist')}>
                              <img src={Globals.iconPack('star')} style={{width: '16px', marginTop: '-2px' }} />
                            </button>
                            <a href={`/insights/creator/${t.account.username}`} target="_blank">
                              <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.account.followerCount)} • {Globals.convertNumberFormat(t.brandCt)}</span>
                            </a>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-6">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Shortlisted Creators <span style={{ float: 'right' }}># Followers</span></li>
                { !this.state.shortlistedInfluencers && <Loader active={true} /> }
                {
                  this.state.shortlistedInfluencers && this.state.shortlistedInfluencers.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <div className="row">
                          <div className="col-md-8">
                            <p style={{margin: 0}}><a href={`https://instagram.com/${t.username}`} target="_blank">@{t.username}</a></p>
                            <p className="sub-text">{t.bio}</p>
                          </div>
                          <div className="col-md-4">
                            <a href={`/insights/creator/${t.username}`} target="_blank">
                              <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.followerCount)}</span>
                            </a>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

          <div className="row row0">
            <div className="trending-list-container col-md-6">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Employees  <span style={{ float: 'right' }}></span></li>
                { !this.state.employees && <Loader active={true} /> }
                { this.state.employees && this.state.employees.map((e) => {
                    var scoreColor = 'green'
                    if (e.emailScore < 70) scoreColor = 'red'
                    else if (e.emailScore < 80) scoreColor = 'orange'
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <div className="row">
                          <div className="col-md-8">
                            <a href={`https://linkedin.com/in/${e.id}`} target="_blank">{e.name || e.googleName}</a>
                            <p className="sub-text">{e.googleFullTitleNoName || e.googleSubtitle}</p>
                          </div>
                          <div className="col-md-4" style={{textAlign: 'right'}}>
                            { e.huntedEmail && e.email &&
                              <div>
                                <p style={{margin: '0'}}>{e.email}</p>
                                <p style={{margin: '0', color: `${scoreColor}`}}>{e.emailStatus} • {e.emailScore}</p>
                              </div>
                            }
                            { e.huntedEmail && !e.email &&
                              <div>
                                <p style={{margin: '0', color: 'red'}}>No email found</p>
                              </div>
                            }
                            { !e.huntedEmail &&
                              <div>
                                <span style={{margin: '0', cursor: 'pointer'}} onClick={this.findContactEmail.bind(this, e)}>{this.state.pendingEmailSearches[e.id] ? 'Finding...': 'Find email'}</span>
                              </div>
                            }
                            <span style={{ float: 'right' }}>{e.companyMatch ? 'match' : '?'}</span>
                            <div style={{ float: 'right', cursor: 'pointer' }} onClick={this.removeBrandContact.bind(this, e)}><img src={Globals.iconPack('trash')} style={{width: '16px'}} /></div>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>@{instagram.username} Hashtags  <span style={{ float: 'right' }}># Posts</span></li>
                { !this.state.topHashtags && <Loader active={true} /> }
                { this.state.topHashtags && this.state.topHashtags.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/explore/tags/${t.name}`} target="_blank">#{t.name}</a>
                        <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.count)}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Associated Hashtags  <span style={{ float: 'right' }}># Posts</span></li>
                { !this.state.topMentionHashtags && <Loader active={true} /> }
                { this.state.topMentionHashtags && this.state.topMentionHashtags.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/explore/tags/${t.name}`} target="_blank">#{t.name}</a>
                        <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.count)}</span>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

          </div>


          <div className="row row0">
            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Mentions @{instagram.username}  <span style={{ float: 'right' }}># Followers</span></li>
                { !this.state.topMentions && <Loader active={true} /> }
                { this.state.topMentions && this.state.topMentions.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                        <a href={`/insights/company/${t.account.username}`} target="_blank">
                          <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.account.followerCount)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>@{instagram.username} Mentioned  <span style={{ float: 'right' }}># Posts</span></li>
                { !this.state.topMentioned && <Loader active={true} /> }
                { this.state.topMentioned && this.state.topMentioned.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                        <a href={`/insights/company/${t.account.username}`} target="_blank">
                          <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.count)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Top @{instagram.username} #ad <span style={{ float: 'right' }}># Followers • # Ads</span></li>
                { !this.state.topAds && <Loader active={true} /> }
                { this.state.topAds && this.state.topAds.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                        <a href={`/insights/company/${t.account.username}`} target="_blank">
                          <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.account.followerCount)} • {Globals.convertNumberFormat(t.count)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Top Similar Brands <span style={{ float: 'right' }}># Posts</span></li>
                { !this.state.topSimilarBrands && <Loader active={true} /> }
                { this.state.topSimilarBrands && this.state.topSimilarBrands.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                        <a href={`/insights/company/${t.account.username}`} target="_blank">
                          <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.count)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

          <div className="row row0">
            <div className="trending-list-container col-md-6">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Top Creators #ad <span style={{ float: 'right' }}># Followers • # Ads</span></li>
                { !this.state.topMentionAds && <Loader active={true} /> }
                { this.state.topMentionAds && this.state.topMentionAds.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                        <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                        <a href={`https://instagram.com/p/${t.posts[0].shortcode}`} target="_blank">
                          <span style={{ float: 'right' }}> • View Post</span>
                        </a>
                        <a href={`/insights/company/${t.account.username}`} target="_blank">
                          <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.account.followerCount)} • {Globals.convertNumberFormat(t.count)}</span>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>

            <div className="trending-list-container col-md-3">
              <ul className="trending-list">
                <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Top YouTube <span style={{ float: 'right' }}># Videos</span></li>
                { !this.state.topYouTube && <Loader active={true} /> }
                { this.state.topYouTube && this.state.topYouTube.map((t) => {
                    return (
                      <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}><a href={`https://youtube.com/channel/${t.account.id}`} target="_blank">{t.account.displayName || t.account.username}</a>  <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.count)}</span></li>
                    )
                  })
                }
              </ul>
            </div>
          </div>


          {/*<div className="trending-list-container col-md-3">
            <ul className="trending-list">
              <li style={{ padding: '5px', minWidth: '150px', fontWeight: 'bold' }}>Top Creators #ad <span style={{ float: 'right' }}># Followers • # Ads</span></li>
              { !this.state.topMentionAds && <Loader active={true} /> }
              {
                this.state.topMentionAds && this.state.topMentionAds.map((t) => {
                  return (
                    <li style={{ padding: '5px', borderBottom: '1px solid #eee', minWidth: '150px' }}>
                      <a href={`https://instagram.com/${t.account.username}`} target="_blank">@{t.account.username}</a>
                      <a href={`/insights/company/${t.account.username}`} target="_blank">
                        <span style={{ float: 'right' }}>{Globals.convertNumberFormat(t.account.followerCount)} • {Globals.convertNumberFormat(t.count)}</span>
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>*/}


        </div>

        { this.state.brandPopupActive &&
          <div>
          {/*<div ref={ node => this.node = node }>*/}
            <div className="add-influencer-popup">
              <div className="add-influencer-popup-container">
                <div className="add-influencer-popup-module" style={{width: '70%', minWidth: '450px', maxWidth: '650px'}}>
                  <div className="add-influencer-popup-outer">
                    <div className="add-influencer-popup-inner">
                      <div className="popup-out-close-icon" onClick={this.toggleBrandPopup.bind(this)} style={{position: 'absolute', top: '25px', right: '25px', width: '15px', height: '15px', cursor: 'pointer'}}>
                        <img src={Globals.icon('close')} style={{width: '100%', height: '100%'}} />
                      </div>
                      <div className="row row0">
                        <h3 style={{paddingBottom: '5px'}}>Create a brand</h3>
                        <p className="sub-text">Fill out the info to create the brand</p>
                      </div>

                      <div className="row row0" style={{marginTop: '25px'}}>
                        {/*<input
                          value={this.state.nameVal}
                          onChange={this.onChange.bind(this, 'nameVal')}
                          className="dark-input"
                          placeholder="Display name..."
                          style={{width: '350px', maxWidth: '80%', padding: '12px 18px', fontSize: '16px', fontFamily: 'Barlow', fontWeight: '700', margin: '0px auto 12px auto'}}
                        />*/}


                        <input
                          value={this.state.create_companyName}
                          onChange={this.onChange.bind(this, 'create_companyName')}
                          className="dark-input"
                          placeholder="Company Name"
                          style={{width: '350px', maxWidth: '80%', padding: '12px 18px', fontSize: '16px', fontFamily: 'Barlow', fontWeight: '700', margin: '0px auto 12px auto'}}
                        />
                        <input
                          value={this.state.create_website}
                          onChange={this.onChange.bind(this, 'create_website')}
                          className="dark-input"
                          placeholder="Website"
                          style={{width: '350px', maxWidth: '80%', padding: '12px 18px', fontSize: '16px', fontFamily: 'Barlow', fontWeight: '700', margin: '0px auto 12px auto'}}
                        />
                        <input
                          value={this.state.create_instagram}
                          onChange={this.onChange.bind(this, 'create_instagram')}
                          className="dark-input"
                          placeholder="Instagram"
                          style={{width: '350px', maxWidth: '80%', padding: '12px 18px', fontSize: '16px', fontFamily: 'Barlow', fontWeight: '700', margin: '0px auto 12px auto'}}
                        />
                        <input
                          value={this.state.create_tiktok}
                          onChange={this.onChange.bind(this, 'create_tiktok')}
                          className="dark-input"
                          placeholder="TikTok"
                          style={{width: '350px', maxWidth: '80%', padding: '12px 18px', fontSize: '16px', fontFamily: 'Barlow', fontWeight: '700', margin: '0px auto 12px auto'}}
                        />

                        <button className="btn-action btn-primary" style={{marginTop: '25px'}} onClick={this.makeBrand.bind(this)}>Create Brand</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
