import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class InvoiceBillCom extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {

    return (
      <div className="invoice-payment invoice-ach">
        <div className="row">
          <div className="col-sm-4 col-xs-6">
            <label>Company Name</label>
            <input className="dark-input" value={'Weave, Inc'} readOnly />
          </div>
          <div className="col-sm-4 col-xs-6">
            <label>Email</label>
            <input className="dark-input" value={'will@weavesocial.com'} readOnly />
          </div>
          <div className="col-sm-4 col-xs-6">
            <label>Payment Network ID</label>
            <input className="dark-input" value={'Weave, Inc'} readOnly />
          </div>
        </div>
      </div>
    )
  }
}
