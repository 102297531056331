import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import InvoicePaymentBtn from './invoice-payment-btn.jsx'
import InvoiceACH from './invoice-ach.jsx'
import InvoiceWire from './invoice-wire.jsx'
import InvoicePayPal from './invoice-paypal.jsx'
import InvoiceBillCom from './invoice-billcom.jsx'
import InvoiceCard from './invoice-card.jsx'

export default class InvoicePayments extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      paymentType: 'ach'
    }
  }

  componentDidMount(){

  }

  selectPaymentOption(type){ this.setState({ paymentType: type }) }

  render() {

    var { paymentType } = this.state
    var { invoice } = this.props
    var { id, name, email, amount, deliverables, notes, status } = invoice
    var paymentOption
    switch (paymentType){
      case 'ach': paymentOption = (<InvoiceACH invoice={invoice} />); break;
      case 'wire': paymentOption = (<InvoiceWire invoice={invoice} />); break;
      case 'card': paymentOption = (<InvoiceCard invoice={invoice} />); break;
      case 'paypal': paymentOption = (<InvoicePayPal invoice={invoice} />); break;
      case 'billcom': paymentOption = (<InvoiceBillCom invoice={invoice} />); break;
      default: break;
    }

    return (
      <div className="invoice-box-inner">
        <div className="invoice-payments">
          <div className="invoice-payments-header">
            <div className="row row0">
              <h4 style={{textAlign: 'center', margin: '25px 0 25px 0'}}>Choose a way to pay</h4>
            </div>
            <div className="invoice-payments-menu">
              <div className="invoice-payment-options">
                <div className="row row0">
                  <InvoicePaymentBtn title="ACH Pay" iconGeneral="bank-blue" iconType="icon" type="ach" selectPaymentOption={this.selectPaymentOption.bind(this)} />
                  <InvoicePaymentBtn title="PayPal" iconGeneral="paypal-logo" iconType="icon" type="paypal" selectPaymentOption={this.selectPaymentOption.bind(this)} />
                  <InvoicePaymentBtn title="Wire" iconGeneral="wire-transfer" iconType="icon" type="wire" selectPaymentOption={this.selectPaymentOption.bind(this)} />
                  <InvoicePaymentBtn title="Card" iconPack="credit-card" iconType="icon" type="card" selectPaymentOption={this.selectPaymentOption.bind(this)} />
                  <InvoicePaymentBtn title="Bill.com" iconGeneral="billcom-logo.png" iconType="icon" type="billcom" selectPaymentOption={this.selectPaymentOption.bind(this)} />
                </div>
                <div className="row row0">
                  <div className="invoice-payments-border">
                    <div className={`invoice-payments-selector invoice-selected-${paymentType}`}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice-payments-section">
            {paymentOption}
          </div>
        </div>
      </div>

    )
  }
}
