import React from 'react';
import Globals from '../global-functions.js';

export default class InstagramRedirect extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    // let social = this.gp('social')
    // if (social == 'youtube'){
    //   Cookies.remove('onboarding')
    //   Cookies.remove('onboarding-status')
    //   location.href = '/dashboard'
    // }

    setTimeout(() => {
      // var code = location.search.split('code=')[1].split('&')[0]
      var code = location.hash && location.hash.split('access_token=')[1] ? location.hash.split('access_token=')[1].split('&')[0] : false
      this.storeToken(code).done((response) => {
        location.replace('/account')
      })
    }, 50)
  }

  storeToken(code){
    return $.ajax({
      method: 'POST',
      url: '/api/Instagram/storeToken',
      data: { access_token: code }
    })
  }

  storeCode(code){
    return $.ajax({
      method: 'POST',
      url: '/api/Instagram/storeCode',
      data: { code: code }
    })
  }

  render() {
    return (
      <div>
        <div style={{position: 'relative', width: '100vw', height: '100vh'}}>
          <div className="results-empty-message">
            <img src={Globals.icon('instagram')} style={{ height: '40px', marginRight: '24px'}} />
            <img src={Globals.icon('logo', 'black')} style={{ height: '40px'}} />
            <h3>Connecting Instagram...</h3>
            <p className="sub-text">You will be redirected to Weave in a few seconds...</p>
          </div>
        </div>
      </div>
    )
  }
}
