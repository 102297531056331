import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  .background-gradient {
    background: ${({ theme }) => theme.grad_bg} !important;
    color: ${({ theme }) => theme.color} !important;
  }
  a {
    color: ${({ theme }) => theme.color};
  }
  article {
    background: ${({ theme }) => theme.main_bg} !important;
    color: ${({ theme }) => theme.color} !important;
  }
  .module, #calendar {
    background: ${({ theme }) => theme.module} !important;
    color: ${({ theme }) => theme.color} !important;
    // border:
  }
  .nav-sidenav li a {
    color: ${({ theme }) => theme.softText} !important;

    div p {
      color: ${({ theme }) => theme.softText} !important;
    }
  }
  .nav-sidenav li.active a {
    div p {
      color: ${({ theme }) => theme.color} !important;
    }
  }
  .mobile-menu {
    background: ${({ theme }) => theme.main_bg} !important;
    border-bottom: 1px solid ${({ theme }) => theme.dark_input} !important;
  }
  .sk-chase-dot:before{
    background-color: ${({ theme }) => theme.color} !important;
  }
  .app-menu {
    .app-menu-option {
      background: ${({ theme }) => theme.app_menu} !important;
      border-color: ${({ theme }) => theme.dark_input} !important;
    }
  }
  h1, h3, h4 {
    color: ${({ theme }) => theme.color};
  }
  p {
    color: ${({ theme }) => theme.color};
  }
  .sub-text {
    color: ${({ theme }) => theme.subtext} !important;
    a {
      color: ${({ theme }) => theme.subtext} !important;
    }
  }
  .nav-sidenav li:hover, .nav-sidenav li.active {
    background: ${({ theme }) => theme.menu_highlight};
  }
  .profile-tab a {
    color: ${({ theme }) => theme.color} !important;
  }
  .pill-tag {
    color: ${({ theme }) => theme.color};
    background: ${({ theme }) => theme.pilltag_standard};
  }
  .btn-action.btn-secondary {
    color: ${({ theme }) => theme.plus_primary} !important;
    border-color: ${({ theme }) => theme.plus_primary} !important;
  }
  .btn-action.btn-inactive {
    color: ${({ theme }) => theme.plus_secondary} !important;
    border-color: ${({ theme }) => theme.plus_secondary} !important;
  }
  .dark-input, .offer-container input, .rates-form .dzu-inputLabel input {
    background: ${({ theme }) => theme.dark_input} !important;
    color: ${({ theme }) => theme.color} !important;
    border-color: ${({ theme }) => theme.dark_input} !important;
  }
  .dark-input:hover, .dark-input:focus, .dark-input.active,
  .offer-container input:hover, .offer-container input:focus {
    background: ${({ theme }) => theme.module} !important;
    border-color: ${({ theme }) => theme.primary} !important;
  }
  .crm-table .row.crm-row {
    background: ${({ theme }) => theme.module} !important;
    border-bottom: 1px solid ${({ theme }) => theme.dark_input};
    color: ${({ theme }) => theme.softText} !important;
  }
  .crm-table {
    border: 1px solid ${({ theme }) => theme.dark_input};
  }
  .crm-filter {
    background: ${({ theme }) => theme.borders};
  }
  .crm-filter.active {
    background: ${({ theme }) => theme.main_bg};
    border-color: ${({ theme }) => theme.primary};
  }
  .main-dropdown-container {
    .main-dropdown {
      background: ${({ theme }) => theme.module} !important;
      border-color: ${({ theme }) => theme.borders} !important;
      ul {
        li {
          color: ${({ theme }) => theme.color} !important;
        }
      }
    }
  }
  .add-influencer-popup-module {
    background: ${({ theme }) => theme.main_bg};
  }
  .invoice-bar-inner { background: ${({ theme }) => theme.dark_input}; }
  .search-filter-container .search-filter-box {
    // background: ${({ theme }) => theme.module} !important;
    border-color: ${({ theme }) => theme.borders} !important;
  }
  .search-filter-container .search-filter-box-mainmenu {
    background: ${({ theme }) => theme.module} !important;
    border-color: ${({ theme }) => theme.borders} !important;
  }
  .search-filter-container .search-filter-box:hover, .search-filter-container .search-filter-box.active {
    border-color: ${({ theme }) => theme.borders_hover};
    background: ${({ theme }) => theme.module} !important;
  }
  .search-filter-dropdown {
    background: ${({ theme }) => theme.module} !important;
    border-bottom: 1px solid ${({ theme }) => theme.borders_hover} !important;
  }
  // .main-dropdown .main-dropdown-outer .main-dropdown-inner .dropdown-section ul.dropdown-results li:hover {
  //   background: ${({ theme }) => theme.add_to_campaign} !important;
  // }
  .main-dropdown .main-dropdown-outer .main-dropdown-inner .dropdown-section ul.dropdown-results li {
    border-color: ${({ theme }) => theme.add_to_campaign} !important;
    .inner-autocomplete span {
      color: ${({ theme }) => theme.color} !important;
    }
  }
  .hoverable:hover {
    background: ${({ theme }) => theme.tabHighlight} !important;
  }
  .mgmt-list .crm-table .crm-row-influencer:hover, .mgmt-list .crm-table .crm-row-influencer.selected {
    background: ${({ theme }) => theme.tabHighlight} !important;
  }
  .intercom-duplicate {
    background: ${({ theme }) => theme.light_shade};
  }
  .intercom-duplicate .chat-input {
    background: ${({ theme }) => theme.module} !important;
  }
  .bounty-category:hover {
		color: ${({ theme }) => theme.color} !important;
	}
  .bounty-category-highlight.all {
    background: ${({ theme }) => theme.color} !important;
  }
  .bounty-category.all.active {
		color: ${({ theme }) => theme.color_opp} !important;
	}
  .bounty-category.active {
		a {
			color: ${({ theme }) => theme.color_opp} !important;
		}
	}
  .bounty-objective {
    background: ${({ theme }) => theme.module} !important;
  }
  .bounty-progress {
    background: ${({ theme }) => theme.dark_input} !important;
  }
  .bounty-credit-bottom {
    background: ${({ theme }) => theme.module} !important;
  }
  .twitch-stream-module:hover {
    background: ${({ theme }) => theme.module_hover} !important;
  }
  .avatar-online-border {
    background-color: ${({ theme }) => theme.module};
  }
  .slide-out {
    background: ${({ theme }) => theme.slide_out};
    // textarea {
    //   background: ${({ theme }) => theme.dark_input};
    // }
  }
  hr {
    border-color: ${({ theme }) => theme.dark_input};
    background: ${({ theme }) => theme.dark_input};
  }
  .shipping-stage.inactive .shipping-stage-dot, .shipping-line hr {
    background: ${({ theme }) => theme.shipping_stage};
  }
  .restaurant-main-img {
    background-color: ${({ theme }) => theme.main_bg};
    border: 6px solid ${({ theme }) => theme.main_bg};
  }
  .brand-image-profile {
    background-color: ${({ theme }) => theme.main_bg};
    border: 5px solid ${({ theme }) => theme.main_bg};
  }
  .game-header-banner-darken {
    // background-color: ${({ theme }) => theme.color_opp};
    background-color: #000;
    opacity: ${({ theme }) => theme.darken_opacity};
  }
  .analytics-module {
    background: ${({ theme }) => theme.module};
  }
  .search-container {
    background: ${({ theme }) => theme.module};
  }
  .search-dropdown li {
    background: ${({ theme }) => theme.module};
    border-bottom: 1px solid ${({ theme }) => theme.dark_input};
  }
  .search-container.live-links .row.list-links-header {
    border-bottom: ${({ theme }) => theme.dark_input};
  }
  .message-container .message-column {
    background: ${({ theme }) => theme.module};
  }
  .message-container .message-view {
    background: ${({ theme }) => theme.secondary};
  }
  .message-container .chat-input {
    background: ${({ theme }) => theme.module};
  }
  .paypal-popup {
    background: ${({ theme }) => theme.main_bg};
    .paypal-top {
      background: ${({ theme }) => theme.module};
    }
  }
  .tag-pill, .bounty-tile-category {
    background: ${({ theme }) => theme.secondary_pill};
    color: ${({ theme }) => theme.color};
  }
  .tabbed-options .tabbed-option {
    border-color: ${({ theme }) => theme.dark_input} !important;
  }
  .social-tile:hover {
    background: ${({ theme }) => theme.dark_input} !important;
  }
  .submission-posts .submission-post .submission-increment .submission-down, .submission-posts .submission-post .submission-increment .submission-up {
    background: ${({ theme }) => theme.plus_secondary} !important;
  }
 .suggestion-item:hover {
    background-color: ${({ theme }) => theme.list_hover} !important;
  }
  .invitation .collapsable {
   .sub-text {
     color: ${({ theme }) => theme.subtext} !important;
   }
 }
 .example-track-0, .example-track-2, .example-thumb, .horizontal-slider.horizontal-slider-single .example-track-1 {
   background: ${({ theme }) => theme.dark_input} !important;
 }
 .horizontal-slider.horizontal-slider-single .example-track-0, .example-track-1 {
   background: ${({ theme }) => theme.slider_fill} !important;
 }
 .search-filter-box {
  color: ${({ theme }) => theme.color} !important;
  background: ${({ theme }) => theme.module} !important;
 }
  .search-filter-container .search-filter-box {
    // background: ${({ theme }) => theme.module} !important;
    border-color: ${({ theme }) => theme.borders} !important;
  }
  .search-filter-container .search-filter-box-mainmenu {
    background: ${({ theme }) => theme.module} !important;
    border-color: ${({ theme }) => theme.borders} !important;
  }
  .search-filter-container .search-filter-box:hover, .search-filter-container .search-filter-box.active {
    border-color: ${({ theme }) => theme.borders_hover};
    background: ${({ theme }) => theme.module} !important;
  }
  .search-filter-dropdown {
    background: ${({ theme }) => theme.module} !important;
    border-bottom: 1px solid ${({ theme }) => theme.borders_hover} !important;
  }

`;
