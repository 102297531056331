import React from 'react';
import Globals from '../../global-functions.js';

export default class VideoTilePrimary extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      videos: []
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
    console.log(this.props)
  }

  componentWillUnmount(){

  }

  render() {

    let video = this.props.video, date = Globals.calculateTime(video.snippet.publishedAt), stats = video.statistics ? video.statistics : {}
    date = date == 'just now' ? date : date + ' ago'
    let viewCount = stats && video.publishedViews ? parseInt(stats.viewCount) - parseInt(video.publishedViews) : stats.viewCount
    // let videoViews = video.currentViews ? Math.floor(video.currentViews - (!isNaN(video.publishedViews) ? video.publishedViews : 0)) : 0

    let likeCount = parseFloat(stats.likeCount), dislikeCount = parseFloat(stats.dislikeCount), commentCount = stats.commentCount, favoriteCount = stats.favoriteCount
    let likePct = (( likeCount/(likeCount + dislikeCount) ) * 100) + '%'
    if (!likeCount && !dislikeCount) { likePct = '50%' }

    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <div className="video-tile-img-container">
              <img className="video-tile-img" src={video.snippet.thumbnails.default.url} />
              <div className="like-dislike-container">
                <div className="likes-bar" style={{width: likePct, border: 'none'}}></div>
              </div>
              <div className="row">
                <div className="col-md-6 yt-thumb yt-thumb-like">
                  <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/thumb-up-button.svg" />
                  <p>{Globals.convertNumberFormat(likeCount)}</p>
                </div>
                <div className="col-md-6 yt-thumb yt-thumb-dislike">
                  <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/thumb-up-button.svg" />
                  <p>{Globals.convertNumberFormat(dislikeCount)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row" style={{marginBottom: '12px'}}>
              <div className="col-md-12">
                <p className="video-title" style={{margin: 0}}>{video.snippet.title}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{video.clicks ? Globals.convertNumberFormat(video.clicks) : 0}</p>
                <p style={{color: '#777'}}>Clicks</p>
              </div>
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{video.earnings ? '$'+video.earnings : '$0.00'}</p>
                <p style={{color: '#777'}}>Earnings</p>
              </div>
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{ viewCount && viewCount > 0 ? ((video.clicks/viewCount)*100).toFixed(2)+'%' : '0.00%'}</p>
                <p style={{color: '#777'}}>CTR</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{Globals.convertNumberFormat(viewCount)}</p>
                <p style={{color: '#777'}}>Adj. Views</p>
              </div>
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{Globals.convertNumberFormat(commentCount)}</p>
                <p style={{color: '#777'}}>Comments</p>
              </div>
              <div className="col-md-4">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{Globals.convertNumberFormat(favoriteCount)}</p>
                <p style={{color: '#777'}}>Favorites</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
