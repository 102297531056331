import React, { useRef } from 'react';
import Globals from '../../../global-functions';
import './invite-asset.scss';

export interface InviteAssetProps {
  title?: string,
  showIf?: boolean,
  subtext: string;
  label: string;
  values: [],
  isBtn?: boolean;
  onclickFunc?: Function;
}

export const copyValue = (element: any) => {
  element?.focus();
  element?.setSelectionRange(0, element.value.length);
  try { document.execCommand("copy") } catch (e) {}
}

export const InviteAsset = ({
  title,
  showIf = true,
  subtext,
  label,
  values,
  isBtn,
  onclickFunc
}: InviteAssetProps) => {


  return showIf ? (
    <div className="invite-asset">
      {title && <h3 className='title'> {title} </h3>}
      {subtext && <p className='sub-text'> {subtext} </p>}

      {values.map((v, i) => {
        const ref = useRef<any>();

        return (
          <input
            key={i}
            ref={ref}
            className={`dark-input ${isBtn ? 'dark-input-btn' : ''}`}
            style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
            defaultValue={v}
            onClick={() => onclickFunc ? onclickFunc() : copyValue(ref?.current)}
            readOnly
          />
        );
      })}

    </div>
  ) : (
    <></>
  );
}
