import React from 'react';
import Globals from '../../global-functions.js';

export default class OnboardEmailSent extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
  }

  render() {
    return (
      <div style={{background: '#36393f !important'}}>
        {/*<div className="row" style={{marginTop: '120px'}}>
          <div className="col-md-4">
            <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Activate</h3>
            <p className="sub-text" style={{textAlign: 'center'}}>
              Browse Weave's marketplace of games willing to pay you to play.
            </p>
          </div>
          <div className="col-md-4">
            <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Stream</h3>
            <p className="sub-text" style={{textAlign: 'center'}}>
              Go live to your audience and play the game.
            </p>
          </div>
          <div className="col-md-4">
            <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Earn</h3>
            <p className="sub-text" style={{textAlign: 'center'}}>
              Once you conclude your stream, your account is credited with your earnings.
            </p>
          </div>
        </div>*/}

        <div className="onboard-page">

          <div className="onboard-container interact-section" style={{width: '500px', maxWidth: '90%'}}>

            <div className="row">
              <div className="col-md-12">
                <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Activate</h3>
                <p className="sub-text" style={{textAlign: 'center'}}>
                  Browse Weave's marketplace of games willing to pay you to play.
                </p>
              </div>
              <div className="col-md-12">
                <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Stream</h3>
                <p className="sub-text" style={{textAlign: 'center'}}>
                  Go live to your audience and play the game.
                </p>
              </div>
              <div className="col-md-12">
                <h3 style={{color: 'white', textAlign: 'center', textTransform: 'uppercase'}}>Earn</h3>
                <p className="sub-text" style={{textAlign: 'center'}}>
                  Once you conclude your stream, your account is credited with your earnings.
                </p>
              </div>
            </div>

            <h3>Connect a platform below to verify.</h3>
            <p className="sub-text" style={{textAlign: 'center'}}>
              Please connect a platform below to verify your streaming profile. Weave pays you for your views and audience, so we need to verify you
              are the owner of your channel. You will be asked to grant permissions.
            </p>
            <div className="row">




              <div className="col-md-12" style={{margin: '10px 0'}}>
                <div>
                  <a href={`https://id.twitch.tv/oauth2/authorize?client_id=vomoslvnl8stwtg9wzzmlmb99876pb&redirect_uri=http://localhost/redirect/twitch&response_type=code&scope=analytics:read:games+bits:read+channel:read:subscriptions+user:edit+user:edit:broadcast+user:read:broadcast+user:read:email+channel_check_subscription+channel_read+channel_stream+channel_subscriptions+user_subscriptions&claims={"id_token":{"email":null,"email_verified":null},"userinfo":{"picture":null}}`}>
                    <div className="integration-btn" role="button" style={{backgroundColor: '#6441a4'}}>
                      <div className="integration-btn-text-container">
                        <div className="integration-btn-text">
                          <div className="integration-btn-text-left">
                            <div className="integration-btn-img-container">
                              <div className="integration-btn-img" style={{backgroundImage: 'url(/images/twitch-logo-white.svg)'}}></div>
                            </div>
                            <span className="integration-btn-title">Twitch</span>
                            <div className="integration-btn-sub">Stream games and make money</div>
                          </div>
                          <div className="integration-btn-text-right">
                            <button>
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-md-12" style={{margin: '10px 0'}}>
                <div>
                  <a href="https://accounts.google.com/o/oauth2/auth?access_type=offline&scope=profile%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube&response_type=code&client_id=268172365606-s204a1j7g12rt4vjbfa7mehvses90q8q.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%2Fsignedin">
                    <div className="integration-btn" role="button" style={{backgroundColor: '#FF0000'}}>
                      <div className="integration-btn-text-container">
                        <div className="integration-btn-text">
                          <div className="integration-btn-text-left">
                            <div className="integration-btn-img-container">
                              <div className="integration-btn-img" style={{backgroundImage: 'url(/images/youtube-icon.svg)'}}></div>
                            </div>
                            <span className="integration-btn-title">YouTube</span>
                            <div className="integration-btn-sub">Stream games and make money</div>
                          </div>
                          <div className="integration-btn-text-right">
                            <button>
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>


              <div className="col-md-12" style={{margin: '10px 0'}}>
                <div>
                  <a href="https://mixer.com/oauth/authorize?response_type=token&redirect_uri=http://localhost/redirect/mixer&scope=channel:analytics:self&client_id=fee8dba0769e1421927c26613181f3d5ee66f905a4236ba4">
                    <div className="integration-btn" role="button" style={{backgroundColor: 'rgb(41, 55, 74)'}}>
                      <div className="integration-btn-text-container">
                        <div className="integration-btn-text">
                          <div className="integration-btn-text-left">
                            <div className="integration-btn-img-container">
                              <div className="integration-btn-img" style={{backgroundImage: 'url(/images/mixer-merge-white.svg)'}}></div>
                            </div>
                            <span className="integration-btn-title">Mixer</span>
                            <div className="integration-btn-sub">Stream games and make money</div>
                          </div>
                          <div className="integration-btn-text-right">
                            <button>
                              View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
