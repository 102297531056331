import React from 'react';
import SocialTile from './social-tile.jsx';
import YouTubeChooser from './youtube-chooser.jsx';
import Globals from '../../global-functions.js';
import Cookies from 'js-cookie';

export default class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      platform: this.props.platform,
      user: this.props.user
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
    console.log(this.props.video)
  }

  focus(){
    $('.header-hr hr.fill').addClass('active')
  }

  blur(){
    $('.header-hr hr.fill').removeClass('active')
  }

  connectYouTube(){
    var redirect = '/links'
    if (this.props.links.length > 0){
      redirect = '/links/' + this.props.contentid
    }
    $.ajax({
      method: 'POST',
      url: '/api/YouTube/googleUrl',
      data: {}
    }).done((data) => {
      Cookies.set('redirect_google', redirect)
      location.href = data
    })
  }

  componentWillUnmount(){

  }

  render() {

    let video = this.props.video

    let module = {active: true}
    let labelling = (
      <div className="label-section">
        <div className="row">
          <div className="col-md-6">
            <div className="label-container">
              <label className="label-for-input" htmlFor="label-input">Label Post</label>
              <input id="label-input" name="label-input" className="label-input" value={this.props.label ? this.props.label : ''} placeholder="give post a label..." onChange={this.props.editLabel.bind(this.props.this)} onFocus={this.focus} onBlur={this.blur} />
              <div className="input-fill header-hr">
                <hr className="back" />
                <hr className="fill" />
                <hr className="saved" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row" style={{paddingTop: '20px'}}>
              <div className="col-md-6">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{Globals.convertNumberFormat(video.clicks)}</p>
                <p style={{color: '#777'}}>Clicks</p>
              </div>
              <div className="col-md-6">
                <p style={{fontWeight: 'bold', fontSize: '18px', marginBottom: 0}}>{video.earnings ? '$'+parseFloat(video.earnings).toFixed(2)+'' : '$0.00'}</p>
                <p style={{color: '#777'}}>Earnings</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    switch (this.props.platform) {
      case 'youtube':
        module.title = 'YouTube'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/youtubelogo.svg'
        module.background = ''
        module.color = '#ff0000'
        labelling = ( <YouTubeChooser chooseVideo={this.props.chooseVideo} video={this.props.video} showMoreVideos={this.props.showMoreVideos} this={this.props.this} /> )
        break;
      case 'instagram':
        module.title = 'Instagram'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/instagramlogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#8222b1'
        break;
      case 'facebook':
        module.title = 'Facebook'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/facebooklogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#3d5b99'
        break;
      case 'snapchat':
        module.title = 'Snapchat'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/snapchatlogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#fffc04'
        break;
      case 'twitter':
        module.title = 'Twitter'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/twitterlogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#1ea0f2'
        break;
      case 'pinterest':
        module.title = 'Pinterest'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/pinterestlogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#ca2028'
        break;
      case 'blog':
        module.title = 'Blog'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/bloglogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#17be64'
        break;
      case 'other':
        module.title = 'Other'
        module.logo = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/otherlogo.svg'
        module.background = 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/homepage/instagram-dark.svg'
        module.color = '#14405f'
        break;
      default:
        module.active = false
        break;
    }

    let initial = (
      <div className="section section-2 module" style={{margin: 0, boxShadow: '0px 2px 9px 0px rgba(0, 0, 0, 0.02)', border: '1px solid rgba(0, 0, 0, 0.06)'}}>
        <h4 style={{marginTop: '18px', padding: '0 0 24px 25px', borderBottom: '1px solid #ededed'}}>{'Where are you going to post?'}</h4>
        <div className="video-display social-display">
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="YouTube" platform="youtube" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/youtubelogo.svg" tagline="video descriptions and annotations" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Instagram" platform="instagram" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/instagramlogo.svg" tagline="swipe ups" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Facebook" platform="facebook" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/facebooklogo.svg" tagline="images, text or video posts" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Snapchat" platform="snapchat" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/snapchatlogo.svg" tagline="swipe ups" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Twitter" platform="twitter" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/twitterlogo.svg" tagline="any tweet" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Pinterest" platform="pinterest" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/pinterestlogo.svg" tagline="links to buy on pins" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Blog" platform="blog" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/bloglogo.svg" tagline="links in your blog" />
          <SocialTile this={this.props.this} chooseSocial={this.props.chooseSocial} title="Other" platform="other" img="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/social-icons/otherlogo.svg" tagline="anywhere else to post a link" />
        </div>
      </div>
    )

    if (module.active){
      if (this.props.platform == 'youtube' && !this.props.user.access_token){
        module.html = (
          <div className="social-header login-box">
            <div className="color-bar" style={{background: module.color}}></div>
            <div className="social-label">
              <img src={module.logo} />
              <p className="platform-label">{module.title}</p>
            </div>
            <a className="social-change" onClick={this.props.chooseSocial.bind(this.props.this, null)}>Change</a>
            <p style={{margin: '20px 35px'}}>
              *please note that YouTube will ask for permissions for your account. Weave integrates directly with social platforms to allow you to easily create links and post directly to your content. This permission does not give us any control over your accounts or allow us to post on your behalf, but it is required in order for you to build links for these platforms.
            </p>
            <a className="social-button" onClick={this.connectYouTube.bind(this)} style={{maxWidth: '300px'}}>
              <span className="social-button-icon"></span>
              <span className="social-button-text">CONNECT WITH YOUTUBE</span>
            </a>
          </div>
        )
      }
      else {
        module.html = (
          <div className="social-header">
            <div className="color-bar" style={{background: module.color}}></div>
            <div className="social-label">
              <img className="social-img" src={module.logo} />
              <p className="platform-label">{module.title}</p>
            </div>
            <a className="social-change" onClick={this.props.chooseSocial.bind(this.props.this, null)}>Change</a>

            {labelling}
          </div>
        )
      }
    }

    return (
      <div>
        {module.active ? module.html : initial}
      </div>
    )
  }
}
