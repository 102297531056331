import { ActionType, createAction } from 'typesafe-actions';
import {
  GetBountyProductsFailed,
  GetBountyProductsRequest,
  GetBountyProductsResponse,
  GetSelectedProductFailed,
  GetSelectedProductRequest,
  GetSelectedProductResponse,
  SelectProductFailed,
  SelectProductRequest,
  SelectProductResponse
} from '../models/bounty.models';

export const bountyActionCreators = {
  getBountyProductsAction: createAction('bountyProducts/GET')<GetBountyProductsRequest>(),
  getBountyProductsSuccessAction: createAction('bountyProducts/GET/success')<GetBountyProductsResponse>(),
  getBountyProductsFailAction: createAction('bountyProducts/GET/fail')<GetBountyProductsFailed>(),
  getSelectedProductAction: createAction('selectedProduct/GET')<GetSelectedProductRequest>(),
  getSelectedProductSuccessAction: createAction('selectedProduct/GET/success')<GetSelectedProductResponse>(),
  getSelectedProductFailAction: createAction('selectedProduct/GET/fail')<GetSelectedProductFailed>(),
  selectProductAction: createAction('product/SELECT')<SelectProductRequest>(),
  selectProductSuccessAction: createAction('product/SELECT/success')<SelectProductResponse>(),
  selectProductFailAction: createAction('product/SELECT/fail')<SelectProductFailed>(),
};

export type GetBountyProductsAction = ActionType<
  typeof bountyActionCreators.getBountyProductsAction
  >;
export type GetBountyProductsSuccessAction = ActionType<
  typeof bountyActionCreators.getBountyProductsSuccessAction
  >;
export type GetBountyProductsFailAction = ActionType<
  typeof bountyActionCreators.getBountyProductsFailAction
  >;
export type GetSelectedProductAction = ActionType<
  typeof bountyActionCreators.getSelectedProductAction
  >;
export type GetSelectedProductSuccessAction = ActionType<
  typeof bountyActionCreators.getSelectedProductSuccessAction
  >;
export type GetSelectedProductFailAction = ActionType<
  typeof bountyActionCreators.getSelectedProductFailAction
  >;
export type SelectProductAction = ActionType<
  typeof bountyActionCreators.selectProductAction
  >;
export type SelectProductSuccessAction = ActionType<
  typeof bountyActionCreators.selectProductSuccessAction
  >;
export type SelectProductFailAction = ActionType<
  typeof bountyActionCreators.selectProductFailAction
  >;

export type BountyActions = ActionType<typeof bountyActionCreators>;
