import React from 'react';
// import BrandTileProduct from './brand-tile-product.jsx'
// import BrandTileRestaurant from './brand-tile-restaurant.jsx'
// import BrandTileGame from './brand-tile-game.jsx'
import BountyProduct from './bounty-product.jsx';
import BountyRestaurant from './bounty-restaurant.jsx';
import BountyGame from './bounty-game.jsx';
import BountyApp from './bounty-app.jsx';

export default class CampaignStream extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    var username = '',
    campaign = this.props.campaign,
    game = campaign.game ? campaign.game : campaign.offer,
    content = campaign ? campaign.content : {},
    campaign_window = campaign && campaign.campaign ? campaign.campaign.campaign_window : '',
    user = this.props.user,
    offer = campaign.offer,
    restaurant = campaign.restaurant

    var name = offer.bountyTitle

    var html = (
      <>
        {
          campaign.brandType == 'product' &&
          <BountyProduct this={this} user={user} campaign={campaign} offer={offer} restaurant={restaurant} />
        }
        {
          campaign.brandType == 'restaurant' &&
          <BountyRestaurant this={this} user={user} campaign={campaign} offer={offer} restaurant={restaurant} />
        }
        {
          campaign.brandType == 'game' &&
          <BountyGame this={this} user={user} campaign={campaign} offer={offer} />
        }
        {
          campaign.brandType == 'app' &&
          <BountyApp this={this} user={user} campaign={campaign} offer={offer} />
        }
        {/*{
          campaign.brandType == 'game' &&
          <BrandTileGame this={this} user={user} campaign={campaign} offer={offer} />
        }*/}
      </>
    )

    return (
      <div>
        <div className={this.props.colSize}>
          <div className="module brand-tile brand-tile-active" style={{overflow: 'initial', padding: 0}} onClick={this.props.viewOffer.bind(this.props.this, campaign)}>
            {html}
          </div>
          {/*{
            content.status != 'brand_invited' &&
            <div className="module brand-tile brand-tile-active" style={{overflow: 'initial', padding: 0}} onClick={this.props.viewOffer.bind(this.props.this, campaign)}>
              {html}
            </div>
          }*/}
          {/*{
            content.status == 'brand_invited' &&
            (user.connectedSocials && user.connectedAddress) || true &&
            <div className="module brand-tile brand-tile-active" style={{overflow: 'initial', padding: 0}} onClick={this.props.viewOffer.bind(this.props.this, campaign)}>
              {html}
            </div>
          }*/}
          {/*{
            content.status == 'brand_invited' &&
            (!user.connectedSocials || !user.connectedAddress) &&
            <Link to="/account">
              <div className="module brand-tile brand-tile-active" style={{overflow: 'initial', padding: 0}}>
                <div className="blinking-dot-tile">
                  <BlinkingDot />
                </div>
                {html}
              </div>
            </Link>
          }*/}
        </div>
      </div>
    )
  }
}
