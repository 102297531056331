import React, { useEffect, useState } from 'react';
import Globals from '../../../global-functions';
import './invite-socials.scss';
import { setUpGuestUser, verifyInstagram, verifyProfile } from '../../../services/user-service';
import Loader from '../../loader';

type PlatformType = 'youtube' | 'instagram' | 'twitch' | 'tiktok';

const platforms: { type: PlatformType, icon: string, name: string }[] = [
  { type: 'instagram', icon: Globals.icon('instagram'), name: 'Instagram' },
  { type: 'youtube', icon: Globals.socialIcon('youtube'), name: 'YouTube', },
  // { type: 'twitch', icon: Globals.icon('twitch'), name: 'Twitch', },
  // { type: 'tiktok', icon: Globals.icon('tiktok'), name: 'TikTok', }
]

export const InviteSocials: React.FC<{currUserId: string | null, contentId: string}> = ({currUserId, contentId}) => {
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformType>();
  const [activeSocials, setActiveSocials] = useState({ instagram: false, youtube: false, tiktok: false, twitch: false });
  const [userId, setUserId] = useState(currUserId);

  // Instagram
  const [igUsername, setIgUsername] = useState('');
  const [verifyInstaError, setVerifyInstaError] = useState<string |null>(null);
  const [instaVerified, setInstaVerified] = useState<boolean>(false);
  const [verifyInstaLoading, setVerifyInstaLoading] = useState(false);

  // Youtube
  const [ytUsername, setYtUsername] = useState('');
  const [verifyYtError, setVerifyYtError] = useState<string |null>(null);
  const [ytVerified, setYtVerified] = useState<boolean>(false);
  const [verifyYtLoading, setVerifyYtLoading] = useState(false);

  // todo: WIP

  const selectPlatformConnect = async (platform?: PlatformType) => {
    setSelectedPlatform(platform);
    if ((platform === 'instagram' || platform === 'youtube' ) && !userId) {
      const {user} = await setUpGuestUser({contentId});
      if (user) { setUserId(user.userid); }
    }
  }

  useEffect(() => { setActiveSocials({ ...activeSocials, instagram: instaVerified }); }, [instaVerified]);

  const verifyIgAccount = async () => {
    setVerifyInstaError(null);
    if (!igUsername){ setVerifyInstaError('Please enter your username'); }
    if (!userId){ setVerifyInstaError('Missing user id. Please contact our support'); }
    if (userId && igUsername && !verifyInstaLoading) {
      setVerifyInstaLoading(true);
      const response = await verifyInstagram({profile: igUsername, userId});
      if (!response?.isValid) { setVerifyInstaError('Verification failed. Make sure that your username is correct and that you followed the steps above'); }
      else { setInstaVerified(true); }
      setVerifyInstaLoading(false);
    }
  }

  const verifyYtAccount = async () => {
    setVerifyYtError(null);
    if (!ytUsername){ setVerifyYtError('Please enter your channel name'); }
    if (!userId){ setVerifyYtError('Missing user id. Please contact our support'); }
    if (userId && ytUsername && !verifyYtLoading) {
      setVerifyYtLoading(true);
      const response = await verifyProfile({profile: ytUsername, userId});
      if (!response?.isValid) { setVerifyYtError('Verification failed. Make sure that your username is correct and that you followed the steps above'); }
      else { setYtVerified(true); }
      setVerifyYtLoading(false);
    }
  }

  return (
    <div className="connect-socials-container" id="socials">
      { !selectedPlatform &&
        <div className="connect-platforms" style={{ textAlign: 'center' }}>
          { platforms.map(platform => <PlatformTile platform={platform} isSelected={selectedPlatform == platform.type} isActive={activeSocials[platform.type]} selectPlatformConnect={(type) => selectPlatformConnect(type)}/> ) }
        </div> }
      <div>
        { selectedPlatform == 'instagram' &&
          <div className="social-section">
            <div className="close">
              <img src={Globals.icon('close')} onClick={() => selectPlatformConnect()} />
            </div>

            <>
              <div className="row row0">
                <h3 style={{ padding: 0, margin: '0 0 15px 0' }}>Instagram</h3>
                <p className="sub-text">Follow these steps to verify your account. We will never post, edit, or delete any of your Instagram content.</p>
              </div>

              {verifyInstaLoading && <Loader active={verifyInstaLoading}/>}
              <div className={`row row0 invite-socials-form ${verifyInstaLoading ? 'disabled' : ''}`}>
                <h5>Step 1: Enter your username</h5>
                <input className="dark-input" placeholder="@username" readOnly={instaVerified} value={igUsername} onChange={(event) => setIgUsername(event.target.value)} />
                <h5>Step 2: Paste this ID in your bio</h5>
                <input className="dark-input " readOnly value={userId || ''} />
                {!!verifyInstaError && <p className="error-msg">{verifyInstaError}</p>}
                {instaVerified && <p className="success">Verification Complete!</p>}
                {!instaVerified && <button onClick={verifyIgAccount} disabled={verifyInstaLoading} className="plaid-btn">Verify Account</button>}
              </div>
            </>
          </div>
        }

        { selectedPlatform == 'youtube' &&
          <div className="social-section">
            <div className="close">
              <img src={Globals.icon('close')} onClick={() => selectPlatformConnect()} />
            </div>

            <>
              <div className="row row0">
                <h3 style={{ padding: 0, margin: '0 0 15px 0' }}>Youtube</h3>
                <p className="sub-text">
                  Follow these steps to verify your account. We will never post, edit, or delete any of your YouTube content.
                </p>
              </div>

              { verifyYtLoading && <Loader active={verifyYtLoading}/>}
              <div className={`row row0 invite-socials-form ${verifyYtLoading ? 'disabled' : ''}`}>
                <h5>Step 1: Enter your username</h5>
                <input className="dark-input" placeholder="channel name" readOnly={ytVerified} value={ytUsername} onChange={(event) => setYtUsername(event.target.value)} />
                <h5>Step 2: Paste this ID in your description</h5>
                <input className="dark-input " readOnly value={userId || ''} />
                {!!verifyYtError && <p className="error-msg">{verifyYtError}</p>}
                {ytVerified && <p className="success">Verification Complete!</p>}
                {!ytVerified && <button onClick={verifyYtAccount} disabled={verifyYtLoading} className="plaid-btn">Verify Account</button>}
              </div>
            </>
          </div>
        }
      </div>
    </div>
  )
}

const PlatformTile = (props: {platform: any, isSelected: boolean, isActive: boolean, selectPlatformConnect: (platformType: PlatformType) => void}) => {
  const {platform, isSelected, isActive, selectPlatformConnect} = props;
  return <div key={platform.type} className="col-xs-6">
    <div
      className={`module connect-platform instagram ${isSelected ? 'selected' : ''} ${isActive ? 'active' : ''}`}
      onClick={() => selectPlatformConnect(platform.type)}>
      <div className={`connect-platform-success ${isActive ? 'active' : ''}`}>
        <img src={Globals.icon('ok', 'white')} />
      </div>
      <img className="connect-platform-img" src={platform.icon} />
      <h5>{platform.name}</h5>
      <p className="sub-text">{isActive ? 'Connected' : 'Not Connected'}</p>
    </div>
  </div>
}
