import React, { PropsWithChildren, useEffect, useState } from 'react';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import Globals from '../../global-functions';


export const DropzoneInput = (props: {
  contentLink: string,
  disabled: boolean,
  viewMode: boolean,
  label: string,
  reviewContentType: string,
  reviewContentPlatform: string,
  uploadFileMode: boolean,
  hasPreviewMode: boolean,
  onDropzoneChangeStatus: (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => void,
  inputDisabled: boolean,
  onInputChange: (event: any) => void,
  setUploadFileMode: (event: any) => void,
  onLinkClick: (params: any) => void,
  getUploadParams: any
}) => {
  const {
    contentLink, viewMode, inputDisabled, disabled, uploadFileMode,
    setUploadFileMode, onDropzoneChangeStatus, onInputChange, onLinkClick,
    label, hasPreviewMode, getUploadParams, reviewContentType, reviewContentPlatform
  } = props;
  const [uploadPreviewMode, setUploadPreviewMode] = useState(hasPreviewMode && !!contentLink);
  const contentLinkPreview = contentLink && (Globals.isURL(contentLink) || contentLink.indexOf('data:image') > -1) ? contentLink : false

  return <>
    <h5 className="sub-text">{label}</h5>

    {
      uploadPreviewMode
        ? <>
          <div className="preview-img" style={{backgroundImage: `url(${contentLink})`}}/>
          {!viewMode && <a href="#" className="upload-file-toggle" onClick={() => { setUploadPreviewMode(false); setUploadFileMode(true); }}>Upload another one?</a>}
        </>
        : <><>
              <input disabled={inputDisabled} className="dark-input" value={contentLink} onChange={onInputChange} placeholder="Paste url to file here..." />
              {/*{!viewMode && <a href="#" className="upload-file-toggle" onClick={() => setUploadFileMode(true)}>Upload file instead?</a>}*/}
            </>

            {
              contentLinkPreview &&
              <div className="content-image-preview-link" style={{backgroundImage: `url(${contentLinkPreview})`}}></div>
            }
            {
              !contentLinkPreview &&
              <>
                <Dropzone
                  inputContent={null}
                  maxFiles={1}
                  disabled={disabled}
                  getUploadParams={getUploadParams}
                  canRemove={true}
                  onChangeStatus={onDropzoneChangeStatus}
                />
                {/*Please only upload images, any video content should be uploaded to a service like Google Drive, Dropbox, YouTube, etc and shared as a link above*/}
                {/*<a href="#" className="upload-file-toggle" onClick={onLinkClick}>Use a link instead?</a>*/}
              </>
            }
        </>
    }
  </>
}
