import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import globals from '../global-functions';

const propTypes = { component: Component };

export const PrivateRoute = (props: RouteComponentProps<any>) => {
  console.log(globals.getUser());

  return <Route {...props} render={(props) => (
    !!globals.getUser()
      ? <Component {...props} />
      : <Redirect to='/login'/>
  )}/>
}
