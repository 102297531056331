import { memo } from 'react';
import FilterOption from './filter-option'

const FilterRestaurant = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <FilterOption title="Has Yelp" label="restaurant" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
      <div className="filter-option-container">
        <div className="row row0">
          <SearchDropdown endpoint="getCountries" preload={true} index={0} type={type} field="name" dataKey="countries" changeFilter={changeFilter} placeholder="Select country..." _this={_this} />
        </div>
        <div className="row row0">
          { filter[0] && filter[0].length > 0 && filter[0].map((l) => {
            return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> )
          }) }
        </div>
      </div>
      <label>Yelp ($)</label>
      <>
        <FilterOption title="$" label="$" filterType="toggle-selector" filter={filter} index={2} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOption title="$$" label="$$" filterType="toggle-selector" filter={filter} index={2} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOption title="$$$" label="$$$" filterType="toggle-selector" filter={filter} index={2} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOption title="$$$$" label="$$$$" filterType="toggle-selector" filter={filter} index={2} changeFilter={changeFilter} type={type} _this={_this} />
      </>
    </>
  )
})

export default memo(FilterRestaurant);
