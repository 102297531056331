import { memo } from 'react';
import FilterOption from './filter-option'

const FilterEmail = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <FilterOption title="Has Social Media Contact" label="influencer" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="Has Marketing Contact" label="marketing" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="Has Email" label="email" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="No Email" label="none" filterType="toggle-selector" filter={filter} changeFilter={changeFilter} type={type} _this={_this} />
    </>
  )
})

export default memo(FilterEmail);
