import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../global-functions.js';
import Canvas from './canvas.jsx';
import Calendar from './calendar.jsx';
import Chart from './chart.jsx'
import moment from 'moment'

export default class VIP extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      deals: [],
      pitches: [],
      graphData: {},
      startDate: moment().subtract('1', 'month').startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      incrementType: 'month'
    }
  }

  componentDidMount(){
    // this.getAllPipelines()

  }

  onSubmit(e){
    e.preventDefault();
  }

  async getAllPipelines(){ var res = await this._getAllPipelines(); await this.setState({ ...res, pipelineId: res.pipelines[0].id}); this.getAllDeals() }
  async _getAllPipelines(){ return $.ajax({ method: 'GET', url: '/api/Account/getAllPipelines', data: {} }) }

  async getAllDeals(){
    var { startDate, endDate, incrementType, pipelineId } = this.state
    var params = { startDate, endDate, incrementType, pipelineId }
    this._getAllDeals(params).done((response) => { this.setState(response) })
  }
  async _getAllDeals(params){ return $.ajax({ method: 'GET', url: '/api/Account/getAllDeals', data: params }) }
  async selectPipeline(e){ await this.setState({ pipelineId: e.target.value }); this.getAllDeals() }
  async updateCalendar(_this, startDate, endDate, dateStr){ await _this.setState({ startDate, endDate, dateStr }); this.getAllDeals() }
  onChange(type, e){ this.setState({ [type]: e.target.value }) }

  render() {
    var { deals, pitches, graphData, pipelines, pipelineId } = this.state
    var totalEarnings = 0, totalDeals = deals.length, totalPitches = pitches.length
    deals.forEach((d) => { totalEarnings += d.amount && !isNaN(d.amount) ? Number(d.amount) : 0 })
    var imageStyle = { position: 'absolute', width: '50px', height: '50px', borderRadius: '15px', inset: '0 0 0', top: 0, left: '15px', bottom: 0, margin: 'auto 0' }
    let canvas1 = <Canvas element="myChart1" total={graphData.totalEarnings} labels={graphData.labels} data={graphData.earningsData} type="amount" size="col-md-3"></Canvas>
    let canvas2 = <Canvas element="myChart2" total={graphData.totalDeals} labels={graphData.labels} data={graphData.dealsData} type="amount" size="col-md-3"></Canvas>
    let canvas3 = <Canvas element="myChart3" total={graphData.totalPitches} labels={graphData.labels} data={graphData.pitchesData} type="amount" size="col-md-3"></Canvas>

    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700', marginTop: 0 }}>Deals Overview</h3>
            <p className="sub-text"></p>
          </div>
          <div className="col-md-9">
            <div style={{display: 'inline-block', float: 'right', marginLeft: '25px'}}>
              <Calendar startDate={this.state.startDate} endDate={this.state.endDate} updateCalendar={this.updateCalendar.bind(this)} this={this} />
            </div>
            <div style={{display: 'inline-block', float: 'right'}}>
              { pipelines && pipelineId &&
                <div className="row row0">
                  <select className="module" value={pipelineId} onChange={this.selectPipeline.bind(this)} style={{width: '280px', marginBottom: '25px', padding: '15px'}}>
                    { pipelines.map((p) => { return ( <option key={p.id} className={`dark-input`} value={p.id}>{p.name}</option> ) }) }
                  </select>
                </div>
              }
            </div>
            <div style={{display: 'inline-block', float: 'right'}}>
              <Link to="/invoices">
                <button className="btn-action btn-primary" style={{margin: '6px 25px 0 0'}}>Go to invoices</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row" style={{fontFamily: 'Barlow'}}>
          <div className="col-md-4">
            {/*<div className="module">
              <h5 className="sub-text" style={{textTransform: 'uppercase'}}>Earnings</h5>
              <h3 style={{ fontWeight: '700', fontSize: '32px' }}>${Globals.numberWithCommas(totalEarnings)}</h3>
            </div>*/}
            <Chart data={graphData.earningsData} title={'Earnings'} value={'$'+graphData.totalEarnings} size={false} color={'#f00'} dataKey={'amount'} percChange={0} emptyData={false} id={3} />
          </div>
          <div className="col-md-4">
            {/*<div className="module">
              <h5 className="sub-text" style={{textTransform: 'uppercase'}}>Deals</h5>
              <h3 style={{ fontWeight: '700', fontSize: '32px' }}>{Globals.numberWithCommas(totalDeals)}</h3>
            </div>*/}
            <Chart data={graphData.dealsData} title={'Deals #'} value={graphData.totalDeals} size={false} color={'#f00'} dataKey={'amount'} percChange={0} emptyData={false} id={4} />
          </div>
          <div className="col-md-4">
            {/*<div className="module">
              <h5 className="sub-text" style={{textTransform: 'uppercase'}}>Pitches</h5>
              <h3 style={{ fontWeight: '700', fontSize: '32px' }}>{Globals.numberWithCommas(totalPitches)}</h3>
            </div>*/}
            <Chart data={graphData.pitchesData} title={'Pitches #'} value={graphData.totalPitches} size={false} color={'#f00'} dataKey={'amount'} percChange={0} emptyData={false} id={4} />
          </div>
          {/*{canvas1}
          {canvas2}
          {canvas3}*/}
        </div>
        {/*<div className="row row0">
          <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Early Payments (beta)</h3>
          <div className="row">
            <div className="col-md-8">
              <div className="module">
                <p className="sub-text">
                  Weave is rolling out a program for you to invoice brands and receive your payments early the instant your social content is posted live. Most brands, as you may have experienced, pay creators on Net 30 - 90 day accounting cycles. This means you don't receive your money until normally 1 - 3 months after your collaboration.
                  <br /><br />
                  Weave has allocated capital into a creator fund that advances your earnings immediately to your bank while Weave collects the money from the brand at a later time.
                  <br /><br />
                  If you are interested, please apply to our waitlist to be chosen for the beta. Weave is rolling this out gradually to more and more creators, so we'll try our best to accomodate your creator business as soon as we can.
                </p>
                <div className="row row0">
                  <Link to="/invoices">
                    <button className="btn-action btn-primary">Go to invoices</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-8">

            </div>
          </div>
        </div>*/}
        <div className="row">
          <div className="col-md-6">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Recent Deals</h3>
            { deals.map((d) => {
                console.log(d)
                var brand = d.brand ? d.brand : {}
                var style = { ...imageStyle, left: '2px', backgroundImage: `url(${brand.imageUrl || `https://logo.clearbit.com/${brand.domain || brand.website}?size=150`})` }
                return (
                  <div className="row row0">
                    <div className="module" style={{marginBottom: '8px', padding: '15px'}}>
                      <div className="row row0">
                        <div style={{position: 'relative', display: 'inline-block', float: 'left', width: '50px', height: '40px' }}>
                          <div className="image-div image-div-contain" style={style} />
                        </div>
                        <div style={{position: 'relative', display: 'inline-block', float: 'left', width: 'calc(100% - 50px)', height: '100%' }}>
                          <div className="col-md-9">
                            <div><b>{d.name}</b> • {d.stage}</div>
                            <div className="sub-text">Closed: <b>{moment(d.closedAt).format('MM/DD/YYYY')}</b> • Created: <b>{moment(d.createdAt).format('MM/D/YYYY')}</b> </div>
                          </div>
                          <div className="col-md-3" style={{textAlign: 'right'}}>
                            <div><b>${Globals.numberWithCommas(d.amount)}</b></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="col-md-6">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Recent Pitches</h3>
            { pitches.map((d) => {
              var { email, contact, company } = d
                var style = { ...imageStyle, backgroundImage: `url(${company.imageUrl || `https://logo.clearbit.com/${company.domain || company.website}?size=150`})` }
                return (
                  <div className="row row0">
                    <div className="module" style={{marginBottom: '8px', padding: '15px'}}>
                      <div className="row row0">
                        <div className="col-md-1" style={{position: 'initial'}}>
                          <div className="image-div image-div-contain" style={style} />
                        </div>
                        <div className="col-md-8">
                          <div><b>{company.name}</b></div>
                          <p className="sub-text">{company.description}</p>
                          {/*<div className="sub-text">Closed: <b>{moment(d.closedAt).format('MM/D/YYYY')}</b> • Created: <b>{moment(d.createdAt).format('MM/D/YYYY')}</b> </div>*/}
                        </div>
                        <div className="col-md-3" style={{textAlign: 'right'}}>
                          <div><b>{email ? moment(email.createdAt).format('MM/DD/YYYY') : ''}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
