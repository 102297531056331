import React from 'react';
import OnboardStatus from './onboard-status.jsx';
import Cookies from 'js-cookie';

export default class OnboardEmail extends React.Component {

  constructor(){
    super()
    this.state = {
      status: 'none'
    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault()
    var email = $('#email').val()
    if (this.validateEmail(email)){
      $('#submit').val('SAVING...')

      $.ajax({
        method: 'POST',
        url: '/api/Users/setEmail',
        data: {email}
      })
      .done((data) => {
        if (data.success){
          Cookies.set('onboarding-status', '/onboard-email-sent')
          location.href = '/onboard-email-sent'
        }
        else {
          alert(data.message)
          $('#submit').val('SAVE EMAIL')
        }
      })
    }
    else {
      alert('Please enter valid email address')
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {

    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section">
            <h3>Set Email</h3>
            <div className="set-email-container">
              <OnboardStatus status="20%" />
              <form onSubmit={this.onSubmit.bind(this)}>
                <div className="input-text-wrapper email">
                  <input id="email" className="input-text" placeholder="Enter Email" />
                </div>
                <button id="submit" type="submit" className="btn-submit">SAVE EMAIL</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
