import React, { useState } from 'react';
import moment from 'moment';
import './invite-products.scss';
import { useClasses } from '../../../utils/use-classes';
import { Product } from '../../../redux/models/bounty.models';
import { InviteAsset } from '../invite-asset/invite-asset';
import Globals from '../../../global-functions';

export interface InviteProductsProps {
  products: Product[];
  content?: object;
  campaign?: object;
  selectedProduct: Product | null;
  mode: 'full' | 'preview';
  selectProduct?: (product: Product) => void;
  giftingType?: string;
}

interface InviteProductPreviewProps {
  products?: Product[];
  campaign?: object;
  content?: object;
  selectedProduct?: Product | null;
  previewText?: string;
}

interface InviteProductsVersion {
  products: Product[];
  content?: object;
  campaign?: object;
  selectedProduct: Product | null;
  mode: 'full' | 'preview';
  selectProduct?: (product: Product) => void;
  giftingType?: string;
}

const _generateGiftingDiscount = (params) => $.ajax({ method: 'POST', url: '/api/Campaign/generateGiftingDiscount', data: params });

// giftingType == 'weave'
export const InviteProductPreview: React.FC<InviteProductPreviewProps> = ({products, selectedProduct, previewText}) => {
  // const topProducts = products?.slice(0, 3);
  if (!selectedProduct) { return <div  className="sub-text">{previewText}</div> }
  return <div className="invite-product-preview">
    {
      selectedProduct
      ? <div className="invite-product-preview-row">
          <div className="product-image-col column">
            <div className="product-image" style={{backgroundImage: `url(${selectedProduct.imageUrl})`}}></div>
          </div>
          <div className="product-description column">
            <div className="title"> { selectedProduct.title } </div>
            <div className="price"> ${ Number(selectedProduct?.price)?.toFixed(2) } value </div>
          </div>
        </div>
      : <div> Please choose a product. </div>
    }
  </div>
};

export const InviteProductSelection: React.FC<InviteProductPreviewProps> = ({products, selectedProduct, selectProduct}) => {
  return <div className="invite-products">
    <div className="invite-product-preview-row">
      <div className="sub-text heading"> Select the product you would like to receive: </div>
    </div>
    {
      products.map((product: Product, i: number) =>
        <div
          key={i}
          className={useClasses({'invite-product-preview-row': true, 'selectable-product': true, 'selected': product.id === selectedProduct?.id})}
          onClick={() => selectProduct && selectProduct(product)}>
          <div className="product-image-col column">
            <div className="product-image" style={{backgroundImage: `url(${product.imageUrl})`}}></div>
          </div>

          <div className="product-description column">
            <div className="sub-text"> {product.title} </div>
            <div className="price"> ${Number(product?.price)?.toFixed(2)} </div>
          </div>
        </div>
      )
    }
  </div>
};

// giftingType == 'discount'
export const InviteProductGiftingDiscountPreview: React.FC<InviteProductPreviewProps> = ({content}) => {
  const order = content?.orders[0], shipping = content?.shippingTracking
  return <>
    { (!order && !shipping?.id) && <InviteProductGiftingDiscountPreviewOrdering content={content} /> }
    { (order || shipping?.id) && <InviteProductGiftingDiscountPreviewShipped content={content} /> }
  </>
};

export const InviteProductGiftingDiscountPreviewOrdering: React.FC<InviteProductPreviewProps> = ({content}) => {
  return <div>
    <p className="sub-text" style={{fontSize: '14px', margin: 0}}>Please order your free product gift(s) here</p>
  </div>
};

export const InviteProductGiftingDiscountPreviewShipped: React.FC<InviteProductPreviewProps> = ({content}) => {
  const order = content?.orders[0], shipping = content?.shippingTracking
  let deliveryDate = !shipping ? false : (shipping.deliveryDate ? `Delivered ${moment(shipping.deliveryDate).format('MMMM Do')}` : (shipping.expectedDelivery ? `Arriving ${moment(shipping.expectedDelivery).format('MMMM Do')}` : 'Expected Delivery Unknown'))
  return <div className="invite-product-preview">
    {
      <div className="invite-product-preview-row">
        <div className="product-image-col column">
          <div className="invite-block-icon" style={{background: '#96bf48'}}>
            <img className="invite-block-img" src={Globals.icon('shopify')} style={{filter: `${Globals.hexToFilter('#ffffff')}`}} />
          </div>
          {/*<div className="product-image" style={{backgroundImage: `url(${Globals.icon('shopify')})`}}></div>*/}
        </div>
        <div className="product-description column">
          <div className="title"> { 'Order #'+order.orderNumber } </div>
          {/*<div className="price"> ${ Number(order?.totalLineItemsPrice)?.toFixed(2) } </div>*/}
          { deliveryDate && <div className="price"> { deliveryDate } </div> }
          <div className="price"> <i>{ shipping ? shipping.shippingMessage : 'Waiting for products to ship' }</i> </div>
          {/*<div className="price"> <a href={shipping.courierRedirectLink} target="_blank">Track Shipment: { shipping.id }</a> </div>*/}
        </div>
      </div>
    }
  </div>
};

export const InviteProductGiftingDiscountFull: React.FC<InviteProductPreviewProps> = ({campaign, content}) => {
  const order = content?.orders[0]
  return <>
    { (!order) && <InviteProductGiftingDiscountFullOrdering campaign={campaign} content={content} /> }
    { (order) && <InviteProductGiftingDiscountFullShipped campaign={campaign} content={content} /> }
  </>
};

export const InviteProductGiftingDiscountFullOrdering: React.FC<InviteProductPreviewProps> = ({campaign, content}) => {
  const [generatedDiscount, setGeneratedDiscount] = useState(false);
  const [generatedDiscountBtnTxt, setGeneratedDiscountBtnTxt] = useState('Generate Gift Discount');
  const [generatingDiscountLoading, setGeneratingDiscountLoading] = useState(false);

  const generateGiftingDiscount = () => {
    if (generatedDiscount || generatingDiscountLoading) { return; }
    setGeneratedDiscountBtnTxt('Generating...')
    setGeneratingDiscountLoading(true);
    _generateGiftingDiscount({ contentId: content?.id, offerId: content?.offerId })
      .catch(() => setGeneratingDiscountLoading(false))
      .done((response: { discount: object }) => {
        setGeneratedDiscount(response.discount);
        setGeneratedDiscountBtnTxt(response.discount.code)
        setGeneratingDiscountLoading(false);
      });
  }

  const discounts = generatedDiscount ? [generatedDiscount] : (content?.discountCodes || content?.discounts || [])

  return <div className="invite-product-preview" style={{padding: '25px'}}>
    { ((!discounts || discounts.length == 0) && !generatedDiscount) &&
      <>
        <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[generatedDiscount?.code || generatedDiscountBtnTxt]} onclickFunc={ () => generatedDiscount ? Globals.copyValue('bounty-discount'): generateGiftingDiscount() } isBtn={generatedDiscount ? false : true} />
        <hr />
      </>
    }

    { (discounts && discounts.length > 0) &&
      discounts.map((discount) => {
        return (
          <>
            <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[discount.code]} />
            <hr />
          </>
        )
      })
    }

    <h3>How To Order</h3>
    <p className="sub-text">Use the discount code above to make a purchase of your choosing from the {campaign?.offerWebsiteUrl} website. You can <b>purchase up to ${Math.abs(Number(campaign?.priceRule?.value))} of products.</b></p>
    <div style={{margin: '25px 0', textAlign: 'center'}}>
      <a href={campaign?.offerWebsiteUrl} target="_blank">
        <p style={{fontSize: '16px', fontWeight: 'bold'}}>{campaign?.offerWebsiteUrl}</p>
        <p classNAme="sub-text">Go to website <img src={Globals.iconPack('angle-right')} style={{width: '14px', margin: '-3px 0 0 4px'}} /></p>
      </a>
    </div>
  </div>
};

export const InviteProductGiftingDiscountFullShipped: React.FC<InviteProductPreviewProps> = ({content}) => {
  const order = content?.orders[0], shipping = content?.shippingTracking
  let deliveryDate = !shipping ? false : shipping?.deliveryDate ? `Delivered ${moment(shipping.deliveryDate).format('MMMM Do')}` : (shipping?.expectedDelivery ? `Arriving ${moment(shipping?.expectedDelivery).format('MMMM Do')}` : 'Expected Delivery Unknown')
  return <div className="invite-product-preview">
    {
      <>
        <div className="row row0" style={{margin: '50px 0', textAlign: 'center'}}>
          {
            shipping && (shipping?.expectedDelivery || shipping?.deliveryDate) &&
            <>
              <div className="row row0">
                <div className="col-xs-6">
                  <p style={{fontWeight: 'bold', fontSize: '20px'}}>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.checkpointUpdatedAt).format('dddd')}</p>
                  <p>
                    <span style={{fontSize: '48px', marginRight: '12px', fontWeight: '800', lineHeight: '36px'}}>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('D')}</span>
                    <div style={{display: 'inline-block', textAlign: 'left'}}>
                      <div><b>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('MMMM')}</b></div>
                      <div><b>{moment(shipping.shippingStatus != 'delivered' ? shipping.expectedDelivery : shipping.deliveryDate).format('YYYY')}</b></div>
                    </div>
                  </p>
                </div>
                <div className="col-xs-6">
                  <p style={{fontWeight: 'bold', fontSize: '20px'}}>
                    {
                      shipping.shippingStatus == 'delivered' &&
                      <span>Delivered <img src={Globals.icon('ok')} style={{marginTop: '-4px', marginLeft: '4px', height: '22px'}} /></span>
                    }
                    {
                      shipping.shippingStatus != 'delivered' &&
                      <span>In-Transit <img src={Globals.icon('delivery')} style={{marginTop: '-4px', marginLeft: '4px', height: '22px'}} /></span>
                    }
                  </p>
                  <p style={{margin: 0}}><b>{shipping.shippingMessage || 'Pending Update...'}</b></p>
                  <p style={{margin: 0}}>{shipping.shippingLocation}</p>
                  <p className="sub-text" style={{margin: 0}}>{moment(shipping.checkpointUpdatedAt || shipping.shippingUpdatedAt || shipping.lastUpdatedAt).format('ddd, MMM Do h:mma')}</p>
                </div>
              </div>
              <div className="row row0" style={{marginTop: '25px'}}>
                <a href={shipping.courierRedirectLink} target="_blank" style={{cursor: 'pointer'}}>
                  <h4>{shipping.id}</h4>
                  <p>View Shipping Details <img src={Globals.iconPack('angle-right')} style={{width: '14px', margin: '-3px 0 0 4px'}} /></p>
                </a>
              </div>
            </>
          }
          {
            (!shipping || !(shipping.expectedDelivery || shipping.deliveryDate)) &&
            <>
              <p style={{fontWeight: 'bold', fontSize: '20px'}}>Shipment Pending...</p>
              <p>
                Delivery date estimation will update once received from shipping courier.
              </p>
            </>
          }
        </div>
        <div className="invite-product-preview-row" style={{padding: '0 25px'}}>
          <div className="product-image-col column">
            <div className="invite-block-icon" style={{background: '#96bf48'}}>
              <img className="invite-block-img" src={Globals.icon('shopify')} style={{filter: `${Globals.hexToFilter('#ffffff')}`}} />
            </div>
            {/*<div className="product-image" style={{backgroundImage: `url(${Globals.icon('shopify')})`}}></div>*/}
          </div>
          <div className="product-description column">
            <div className="title"> Order { '#'+order.orderNumber } </div>
            <div className="price"> <span style={{textDecoration: 'line-through'}}>${Number(order?.totalLineItemsPrice)?.toFixed(2)}</span> <span><b>${Number(order?.totalPrice)?.toFixed(2)}</b></span> </div>
            <div className="price"> Ordered {moment(order.createdAt).format('ddd, MMM Do h:mma')} using {order.discountCode} </div>
            {/*<div className="price"> { shipping.shippingMessage } </div>*/}
            {/*<div className="price"> <a href={shipping.courierRedirectLink} target="_blank">Track Shipment: { shipping.id }</a> </div>*/}
          </div>
        </div>
      </>
    }
  </div>
};

// giftingType == 'manualDiscount'

export const InviteProductGiftingManualDiscountPreview: React.FC<InviteProductPreviewProps> = ({content}) => {
  return <div>
    <p className="sub-text" style={{fontSize: '14px', margin: 0}}>Please order your free product gift(s) here</p>
  </div>
};

export const InviteProductGiftingManualDiscountFull: React.FC<InviteProductPreviewProps> = ({campaign, content}) => {
  const [generatedDiscount, setGeneratedDiscount] = useState(false);
  const [generatedDiscountBtnTxt, setGeneratedDiscountBtnTxt] = useState('Generate Gift Discount');
  const [generatingDiscountLoading, setGeneratingDiscountLoading] = useState(false);

  const generateGiftingDiscount = () => {
    if (generatedDiscount || generatingDiscountLoading) { return; }
    setGeneratedDiscountBtnTxt('Generating...')
    setGeneratingDiscountLoading(true);
    _generateGiftingDiscount({ contentId: content?.id, offerId: content?.offerId })
      .catch(() => setGeneratingDiscountLoading(false))
      .done((response: { discount: object }) => {
        setGeneratedDiscount(response.discount);
        setGeneratedDiscountBtnTxt(response.discount.code)
        setGeneratingDiscountLoading(false);
      });
  }

  const discounts = generatedDiscount ? [generatedDiscount] : (content?.discountCodes || content?.discounts || [])

  return <div className="invite-product-preview" style={{padding: '25px'}}>
    { ((!discounts || discounts.length == 0) && !generatedDiscount) &&
      <>
        <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[generatedDiscount?.code || generatedDiscountBtnTxt]} onclickFunc={ () => generatedDiscount ? Globals.copyValue('bounty-discount'): generateGiftingDiscount() } isBtn={generatedDiscount ? false : true} />
        <hr />
      </>
    }

    { (discounts && discounts.length > 0) &&
      discounts.map((discount) => {
        return (
          <>
            <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[discount.code]} />
            <hr />
          </>
        )
      })
    }

    <h3>How To Order</h3>
    <p className="sub-text">Use the discount code above to make a purchase of your choosing from the {campaign?.offerWebsiteUrl} website. You can <b>purchase up to ${Math.abs(Number(campaign?.priceRule?.value))} of products.</b></p>
    <div style={{margin: '25px 0', textAlign: 'center'}}>
      <a href={campaign?.offerWebsiteUrl} target="_blank">
        <p style={{fontSize: '16px', fontWeight: 'bold'}}>{campaign?.offerWebsiteUrl}</p>
        <p classNAme="sub-text">Go to website <img src={Globals.iconPack('angle-right')} style={{width: '14px', margin: '-3px 0 0 4px'}} /></p>
      </a>
    </div>
  </div>
};


// giftingType == 'manualDigital'

export const InviteProductGiftingManualDigitalPreview: React.FC<InviteProductPreviewProps> = ({content}) => {
  return <div>
    <p className="sub-text" style={{fontSize: '14px', margin: 0}}>Please order your free product gift(s) here</p>
  </div>
};

export const InviteProductGiftingManualDigitalFull: React.FC<InviteProductPreviewProps> = ({campaign, content}) => {
  // var websiteUrl = campaign?.offerWebsiteUrl
  var websiteUrl = 'https://mastersofhorror.com/boss-wallet'
  return <div className="invite-product-preview" style={{padding: '25px'}}>
    <h3>How To Redeem</h3>
    <p className="sub-text">Go to <b>{websiteUrl}</b> and follow the instructions to set up your Boss Wallet. Once you have set it up, please send the email used to create your wallet to your Talent Partnerships contact who invited you. This allows us to drop additional NFT gifts into your wallet for your collaboration.</p>
    <div style={{margin: '25px 0', textAlign: 'center'}}>
      <a href={websiteUrl} target="_blank">
        <p style={{fontSize: '16px', fontWeight: 'bold'}}>{websiteUrl}</p>
        <p classNAme="sub-text">Go to website <img src={Globals.iconPack('angle-right')} style={{width: '14px', margin: '-3px 0 0 4px'}} /></p>
      </a>
    </div>
  </div>
};

export const InviteProductGiftingWeave: React.FC<InviteProductsVersion> = ({products, selectedProduct, selectProduct, mode}) => {
  return <>
    { mode === 'preview' ?
        <InviteProductPreview selectedProduct={selectedProduct} products={products} previewText={`Please select your preferred product gift(s).`} />
      : <InviteProductSelection selectedProduct={selectedProduct} products={products} selectProduct={selectProduct} /> }
  </>
};

export const InviteProductGiftingManualDiscount: React.FC<InviteProductsVersion> = ({products, selectedProduct, selectProduct, mode}) => {
  return <>
    { mode === 'preview' ?
        <InviteProductPreview selectedProduct={selectedProduct} products={products} previewText={`Please select your preferred product gift(s).`} />
      : <InviteProductSelection selectedProduct={selectedProduct} products={products} /> }
  </>
};

export const InviteProductGiftingDiscount: React.FC<InviteProductsVersion> = ({campaign, content, mode}) => {
  return <>
    { mode === 'preview' ?
        <InviteProductGiftingDiscountPreview campaign={campaign} content={content} previewText={`Please select your preferred product gift(s).`} />
      : <InviteProductGiftingDiscountFull campaign={campaign} content={content} /> }
  </>
};

export const InviteProductGiftingManual: React.FC<InviteProductsVersion> = ({products, selectedProduct, selectProduct, mode}) => {
  return <>
    { mode === 'preview' ?
        <InviteProductPreview selectedProduct={selectedProduct} products={products} previewText={`Check how to receive your product gift(s) here.`} />
      : <InviteProductSelection selectedProduct={selectedProduct} products={products} /> }
  </>
};

export const InviteProductGiftingManualDigital: React.FC<InviteProductsVersion> = ({products, selectedProduct, selectProduct, mode}) => {
  return <>
    { mode === 'preview' ?
        <InviteProductPreview selectedProduct={selectedProduct} products={products} previewText={`Check how to receive your product gift(s) here.`} />
      : <InviteProductGiftingManualDigitalFull selectedProduct={selectedProduct} products={products} /> }
  </>
};

export const InviteProducts: React.FC<InviteProductsProps> = ({products, selectedProduct, content, campaign, selectProduct, mode, giftingType}) => {
  return <>
    { giftingType == 'weave' && <InviteProductGiftingWeave products={products} selectedProduct={selectedProduct} selectProduct={selectProduct} campaign={campaign} content={content} mode={mode} /> }
    { giftingType == 'manualDiscount' && <InviteProductGiftingManualDiscount products={products} selectedProduct={selectedProduct} campaign={campaign} content={content} mode={mode} /> }
    { giftingType == 'discount' && <InviteProductGiftingDiscount products={products} selectedProduct={selectedProduct} campaign={campaign} content={content} mode={mode} /> }
    { giftingType == 'manual' && <InviteProductGiftingManual products={products} selectedProduct={selectedProduct} campaign={campaign} content={content} mode={mode} /> }
    { giftingType == 'manualDigital' && <InviteProductGiftingManualDigital products={products} selectedProduct={selectedProduct} campaign={campaign} content={content} mode={mode} /> }
  </>
}
