import React from 'react';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Globals from '../global-functions.js';
import Homepage from './homepage/landing';
import BrandRefer from './homepage/brand-refer';

import NewMain from './homepage/new-main';
import GodLogin from './homepage/god-login';
import NewInfProducts from './homepage/new-inf-products';
import NewInfFoodie from './homepage/new-inf-foodie';
import NewInfGaming from './homepage/new-inf-gaming';
import NewBrandsEcomm from './homepage/new-brands-ecomm';
import NewBrandsRestaurants from './homepage/new-brands-restaurants';
import NewBrandsGaming from './homepage/new-brands-gaming';
import { LoginComponent } from './homepage/login';
import Signup from './homepage/signup';
import Forgot from './homepage/forgot';
import BrandLogin from './homepage/brand-login';
import BrandSignup from './homepage/brand-signup';
import BrandForgot from './homepage/brand-forgot';
import WaitingList from './waiting-list';
import Invoice from './invoices/invoice';
import Privacy from './homepage/privacy';
import TOC from './homepage/terms-of-service';

import Invitation from './invite/invitation';
import { InviteStandalone } from './invite/invite-standalone';

const Landing: React.FC<any> = () => {
  const { pathname } = useLocation();

  useEffect(() => { Globals.pageView(window.location.pathname + window.location.search); window.scrollTo(0, 0); }, [pathname]);

  return (
    <main>
      <Switch>
        {/*<Route exact path='/' component={NewLanding} />*/}
        <Route exact path='/' component={NewMain} />
        <Route exact path='/old-landing' component={Homepage} />
        <Route path='/brand-refer' component={BrandRefer} />
        <Route path='/new-main' component={NewMain} />
        <Route path='/influencers' component={NewInfProducts} />
        <Route path='/foodies' component={NewInfFoodie} />
        <Route path='/streamers' component={NewInfGaming} />
        <Route path='/brands' component={NewBrandsEcomm} />
        <Route path='/restaurants' component={NewBrandsRestaurants} />
        <Route path='/gaming' component={NewBrandsGaming} />
        {/*<Route path='/influencers' component={Influencers}/>*/}
        {/*<Route path='/agencies' component={Agencies}/>*/}
        {/*<Route path='/brands' component={Brands}/>*/}
        <Route path='/login' component={LoginComponent} />
        <Route path='/signup' component={Signup} />
        <Route path='/forgot' component={Forgot} />
        <Route path='/brand-login' component={BrandLogin} />
        <Route path='/brand-signup' component={BrandSignup} />
        <Route path='/brand-forgot' component={BrandForgot} />
        <Route path='/godlogin' component={GodLogin} />
        <Route path='/waiting-list' component={WaitingList} />
        <Route exact path='/invite/:id/rewards' component={Invitation} />
        <Route exact path='/invite/:id/signup' component={Invitation} />
        <Route exact path='/invite/:id' component={Invitation} />
        <Route exact path='/invoice/:invoiceId' component={Invoice} />
        <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
        <Route exact path='/collab/:invitationId/overview/edit-deliverable/1' component={InviteStandalone} />
        <Route exact path='/collab/:invitationId/overview' component={InviteStandalone} />
        <Route exact path='/collab/:invitationId/overview/*' component={InviteStandalone} />
        <Route exact path='/collab/:invitationId' render={(props) => ( <Redirect to={`/collab/${props.match.params.invitationId}/overview`} /> )} />
        <Route path='/privacy' component={Privacy} />
        <Route path='/terms-of-service' component={TOC} />
      </Switch>
    </main>
  );
};

export default Landing;
