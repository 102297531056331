import React from 'react';
import Globals from '../../global-functions.js';
import ShippingTrackingStage from './shipping-tracking-stage.jsx';
import ShippingTrackingLine from './shipping-tracking-line.jsx';

export default class ShippingTrackingRestaurantBar extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var props = this.props, status = this.props.status

    var config = {}
    if (status == 'brand_applied'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive'
      }
    }
    else if (status == 'brand_responded'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive'
      }
    }
    else if (status == 'brand_active'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive',
        l3: 'inactive',
        s4: 'inactive'
      }
    }
    else if (status == 'brand_dining'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'pending active',
        l2: 'active',
        s3: 'inactive',
        l3: 'inactive',
        s4: 'inactive'
      }
    }
    else if (status == 'brand_posting'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'visited',
        l2: 'pending',
        s3: 'pending active',
        l3: 'inactive',
        s4: 'inactive'
      }
    }
    else if (status == 'brand_live'){
      config = {
        s1: 'visited',
        l1: 'visited',
        s2: 'visited',
        l2: 'visited',
        s3: 'visited',
        l3: 'visited',
        s4: 'visited'
      }
    }
    else if (status == 'brand_completed'){
      config = {
        s1: 'finished',
        l1: 'finished',
        s2: 'finished',
        l2: 'finished',
        s3: 'finished',
        l3: 'finished',
        s4: 'finished'
      }
    }

    return (
      <div>
        <div className={`shipping-tracking shipping-tracking-restaurant ${this.props.animated ? 'animated' : ''} ${this.props.size ? this.props.size : 'small'}`}>
          <div className="row row0 shipping-stages stages-4">
            <div className="shipping-stages-inner">
              <ShippingTrackingStage icon={`loupe`} progress={config.s1} />
              <ShippingTrackingLine progress={config.l1} />
              <ShippingTrackingStage icon={`food`} progress={config.s2} />
              <ShippingTrackingLine progress={config.l2} />
              <ShippingTrackingStage icon={`camera`} progress={config.s3} />
              <ShippingTrackingLine progress={config.l3} />
              <ShippingTrackingStage icon={`ok`} progress={config.s4} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
