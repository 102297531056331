import React from 'react';

export default class TaxInfoInput extends React.Component {

  render() {
    var { label, type, inputDetails, size } = this.props

    return (
      <div className={size ? size : 'col-xs-6'}>
        <label className="sub-text">{label}</label>
        <input className="dark-input" value={inputDetails[type]} onChange={this.props.onChange.bind(this.props.this, type)} />
      </div>
    )
  }
}
