import { all, call, put, takeLatest } from 'typed-redux-saga';
import {
  GetInvitationAction,
  invitationActionCreators,
  LoadInvitationDetailsAction
} from '../actions/invitation.actions';
import { fetchInvitation } from '../../services/invitation-service';
import { bountyActionCreators } from '../actions/bounty.actions';
import { brandCampaignActionCreators } from '../actions/brand-campaign.actions';
import { inviteUserActionCreators } from '../actions/invite-user.actions';
import { contentActionCreators } from '../actions/content.actions';
import { addressActionCreators } from '../actions/address.actions';

function* getInvitationSaga(action: GetInvitationAction) {
  try {
    const invitationResponse = yield* call(fetchInvitation, action.payload.id);
    return yield* put(invitationActionCreators.getInvitationSuccessAction(invitationResponse));
  } catch (e) {
    yield put(invitationActionCreators.getInvitationFailAction(e));
  }
}

function* loadInvitationDetailsSaga(action: LoadInvitationDetailsAction) {
  const {bountyId, invitationId, brandCampaignType} = action.payload;

  yield all([
    put(bountyActionCreators.getBountyProductsAction({offerId: bountyId})),
    put(bountyActionCreators.getSelectedProductAction({contentId: invitationId})),
    put(brandCampaignActionCreators.getBrandCampaignAction({type: brandCampaignType, id: invitationId})),
    put(inviteUserActionCreators.hasAccountAction({contentId: invitationId})),
    put(contentActionCreators.getContentObjectivesAction({contentId: invitationId})),
    put(addressActionCreators.getPrimaryAddressAnonymousAction({contentId: invitationId}))
  ]);
}


export function* invitationSagas() {
  yield all([
    takeLatest('invitation/GET', getInvitationSaga),
    takeLatest('invitationDetails/LOAD', loadInvitationDetailsSaga),
  ]);
}
