import { Component, memo } from 'react'
import {Link} from 'react-router-dom'
import Globals from '../../global-functions.js'
import EmptyMessage from '../empty-message'
import SearchDropdown from './search-dropdown'
import Icon from '../icon'
// var randomColor = require('randomcolor');

var titleChange = +new Date()

const BrandLogo = memo(({ imageUrl, initial }) => {
  var style = imageUrl ? { backgroundImage: `url(${imageUrl})`} : {border: '1px solid #eee'}
  return (
    <div className="col-xs-2 drip-bounty-influencer">
      <div className="drip-bounty-influencer-img" style={style}>{initial}</div>
    </div>
  )
})

class Drip extends Component {

  constructor(props){
    super(props)
    this.state = {
      drip: false,
      dripId: false,
      dripStages: [],
      dripTitle: 'Untitled',
      previewBrands: [],
      stages: { entered: 0, active: 0, exited: 0 }
    }
  }

  componentDidMount(){
    const { dripId } = this.props.match.params
    this.getDrip(dripId);
    this.getDripCampaign(dripId);
    this.setState({ dripId });
  }


  async getDrip(dripId){ var { drip, outreachList, previewBrands } = await this._getDrip(dripId); this.setState({ drip, outreachList, previewBrands, dripTitle: drip.title }) }
  async _getDrip(dripId){ return $.ajax({ method: 'GET', url: '/api/Drip/get', data: { dripId: dripId || this.state.dripId } }) }

  onChange(type, e){
    var val = e.target.value
    this.setState({ [`${type}`]: val })
    if (type == 'dripTitle'){ setTimeout(() => { if (this.state.dripTitle == val){ this.updateDrip() } }, 500) }
  }

  async updateDrip(){ var res = await this._updateDrip(); }
  async _updateDrip(){ return $.ajax({ method: 'POST', url: '/api/Drip/update', data: { title: this.state.dripTitle, dripId: this.state.dripId } }) }

  async assignEmailBounty(offerId){
    var res = await this._assignEmailBounty(offerId)
  }

  async assignList(type, list){ var res = await this._assignList(list); this.getDrip(); }
  async _assignList(list){ return $.ajax({ method: 'POST', url: '/api/Drip/assignList', data: { dripId: this.state.dripId, outreachId: list.id } }) }
  async removeList(list){ var res = await this._removeList(list); }
  async _removeList(list){ return $.ajax({ method: 'POST', url: '/api/Drip/removeList', data: { dripId: this.state.dripId } }) }

  async _assignEmailBounty(offerId){ return $.ajax({ method: 'POST', url: '/api/Drip/assignBounty', data: { offerId: offerId, dripId: this.state.dripId } }) }

  async getDripPreviews(dripId){ var res = await this._getDripPreviews(dripId); this.setState(res) }
  async _getDripPreviews(dripId){ return $.ajax({ method: 'GET', url: '/api/Drip/getDripPreviews', data: { dripId: dripId || this.state.dripId } }) }
  async getInfluencers(dripId){ var res = await this._getInfluencers(dripId); this.setState({ influencers: res.influencers, stages: res.stages }) }
  async _getInfluencers(dripId){ return $.ajax({ method: 'GET', url: '/api/Drip/getInfluencers', data: { offerId: this.state.offerId, dripId: dripId || this.state.dripId } }) }
  async getDripCampaign(dripId){ var res = await this._getDripCampaign(dripId); this.setState({ dripStages: res.stages }) }
  async _getDripCampaign(dripId){ return $.ajax({ method: 'GET', url: '/api/DripStage/get', data: { dripId: dripId || this.state.dripId } }) }
  async createDripStage(){ var res = await this._createDripStage(); location.replace(`/outreach/drip/${this.state.dripId}/message/${res}`) }
  async _createDripStage(){ return $.ajax({ method: 'POST', url: '/api/DripStage/create', data: { dripId: this.state.dripId } }) }
  async setDripLive(){ var res = await this._setDripLive(); this.setState({ drip: res }) }
  async _setDripLive(){ return $.ajax({ method: 'POST', url: '/api/DripStage/setLive', data: { dripId: this.state.dripId } }) }

  async changeFilter(){

  }

  render() {
    var { dripStages, stages, drip, previewBrands, outreachList } = this.state, previews = []
    var { changeFilter } = this
    var totalBrandsQueued = outreachList && outreachList.totalBrandsQueued ? outreachList.totalBrandsQueued : 0

    for (var i = 0; i < 24; i++){
      var imageUrl, initial
      if (previewBrands[i]) {
        var initial = ''
        if (i.name) { initial = i.name[0] }
        else if (i.domain) { initial = i.domain[0] }
        // backgroundColor: `${randomColor()}`,
        var imageUrl = previewBrands[i].imageUrl
      }
      previews.push( <BrandLogo key={imageUrl} imageUrl={imageUrl} initial={initial} /> )
    }

    var convertedPerc = 0, totalResponded = 0, totalSent = 0
    // dripStages.forEach((stage, i) => { totalResponded += (Number(stage.stats.responded) || 0); totalSent += (Number(stage.stats.sent) || 0) })
    if (totalResponded > 0){ convertedPerc = Math.round( ( (+totalResponded || 0) / (+totalSent || 1) ) * 100) }

    // function iterateNums(i){ var s = ''; for (x=i;x--;x>0){ s += `${x}, ` } return s }

    return (
      <div>
        {/*<UpgradeBanner bannerText={'Upgrade to set drip emails live'} />*/}
        <div className="drip-bounty-assignment">

          {/*<h1> { this.state.brandId } </h1>
          <h1> { this.state.offerId } </h1>*/}
          <div className="row row0">
            <div style={{display: 'inline-block', float: 'left'}}>
              <h3 style={{paddingBottom: '8px', marginTop: 0}}><input className="drip-bounty-title" value={this.state.dripTitle} onChange={this.onChange.bind(this, 'dripTitle')} style={{paddingLeft: 0, paddingBottom: '6px'}} /></h3>
              <p className="sub-text" style={{marginBottom: '25px'}}>Edit your drip title here</p>
            </div>

            <div style={{display: 'inline-block', float: 'right', paddingTop: '15px'}}>
              <button className={`btn-action ${drip.active ? 'btn-success' : 'btn-primary'}`} onClick={this.setDripLive.bind(this)} style={{marginRight: '8px'}}>
                { drip.active ?
                  <span><img src={Globals.icon('pause', 'white')} style={{height: '14px', margin: '-2px 4px 0 0' }} /> Pause Drip</span> :
                  <span><img src={Globals.icon('play', 'white')} style={{height: '14px', margin: '-2px 4px 0 0' }} /> Set Drip Live</span>
                }
              </button>
              <div style={{display: 'inline-block', float: 'right', marginRight: '8px'}}>
                <Link to={`/outreach/drip/${this.state.dripId}/search`}>
                  <button className="btn-action btn-secondary" style={{float: 'initial'}}>Add Brands</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="module">
            <div className="row">
              <div className="col-md-3">
                <h3 style={{marginTop: 0, padding: 0}}>Outreach Assignment</h3>
              </div>
              <div className="col-md-3" style={{float: 'right'}}>
                <p style={{margin: 0}}><span style={{fontSize: '20px', fontWeight: 700}}>{convertedPerc}%</span> <span className="sub-text" style={{fontSize: '16px'}}> of brands converted</span></p>
              </div>
            </div>
            <hr />
            <div className="row drip-bounty-sections">
              <div className="col-md-4 drip-bounty-section" style={{height: '310px'}}>
                <div className="drip-bounty-section-inner">
                  <h4>Brands enter the drip if</h4>
                  <p className="sub-text">Brands are set to "Scheduled" status in your <Link to="/crm"><b><u>Brand CRM</u></b></Link></p>
                  <div className="drip-bounty-meta">
                    <div className="row row0">
                      { outreachList &&
                        <>
                          <div>
                            <label><Icon iconPack="list-check" style={{margin: '-2px 4px 0 0'}} /> Brand List</label>
                          </div>
                          <div className="crm-btn">
                            <span>{outreachList.name}</span>
                            <span className="sub-text" style={{marginLeft: '8px'}}>edit</span>
                          </div>
                        </>
                      }
                      { !outreachList &&
                        <SearchDropdown endpoint="getOutreachLists" preload={true} index={0} type={['outreachLists']} field="name" dataKey="outreachLists" changeFilter={this.assignList.bind(this)} placeholder="Select list for outreach..." _this={this} />
                      }
                    </div>
                    <hr />
                    <div className="row row0">
                      <>
                        <div>
                          <label><Icon iconPack="envelope" style={{margin: '-2px 4px 0 0'}} /> From Email</label>
                        </div>
                        <div className="crm-btn">
                          <span>{`ron@weave.social`}</span>
                          <span className="sub-text" style={{marginLeft: '8px'}}>edit</span>
                        </div>
                      </>
                    </div>

                  </div>
                  <div className="row row0">
                    <p><span style={{fontSize: '20px', fontWeight: 700}}>{stages.entered}</span> <span className="sub-text" style={{fontSize: '16px'}}> brands have entered</span></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 drip-bounty-section" style={{height: '310px'}}>
                <div className="drip-bounty-section-inner">
                  <h4>In Email Outreach</h4>
                  <p className="sub-text">Brands scheduled for email or currently in email sequence</p>
                  <div className="drip-bounty-meta">
                    <div className="row row0">
                      {previews}
                    </div>
                  </div>
                  <div className="row row0">
                    <p><span style={{fontSize: '20px', fontWeight: 700}}>{Globals.numberWithCommas(stages.active || totalBrandsQueued)}</span> <span className="sub-text" style={{fontSize: '16px'}}> brands</span></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 drip-bounty-section" style={{height: '310px'}}>
                <div className="drip-bounty-section-inner">
                  <h4>Brands Exited Outreach</h4>
                  <p className="sub-text">Brands responded to an email & enter "Responded" status in <Link to="/crm"><b><u>Inbox</u></b></Link></p>
                  <div className="drip-bounty-meta">

                  </div>
                  <div className="row row0">
                    <p><span style={{fontSize: '20px', fontWeight: 700}}>{stages.exited}</span> <span className="sub-text" style={{fontSize: '16px'}}> brands have exited</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="module">
            <div className="row row0">
              <div className="col-md-6">
                <h3 style={{paddingBottom: '8px', marginTop: 0}}>Reach Out Emails</h3>
                <p className="sub-text" style={{marginBottom: '25px'}}>Create multiple email messages in a drip campaign to invite brands to your bounty and follow up with brands that don't respond</p>
                <p style={{margin: 0, fontWeight: 700, fontSize: '16px'}}>We'll always send the most relevant message</p>
                <p className="sub-text" style={{cursor: 'pointer'}}>At least 3 days apart</p>
              </div>
              <div className="col-md-6">
                <div style={{float: 'right'}}>
                  <button className="btn-action btn-primary" style={{marginTop: 0}} onClick={this.createDripStage.bind(this)}><b>+</b> New Message</button>
                </div>
              </div>
            </div>
          </div>

          <div className="row row0">
            <div className="crm-table drip-crm-table">
              <div className="row crm-row module crm-row-header">
                <div className="crm-td crm-td-xs crm-td-img">
                  <input type="checkbox" style={{display: 'block'}} />
                </div>
                <div className="crm-td crm-td-xs crm-td-img">

                </div>
                <div className="crm-td crm-td-lg">
                  <p className="sub-text">Name</p>
                </div>
                <div className="crm-td crm-td-md">
                  <p className="sub-text">Created</p>
                </div>
                <div className="crm-td crm-td-md">
                  <p className="sub-text">Last Updated</p>
                </div>
                <div className="crm-td crm-td-sm">
                  <p className="sub-text"><img src={Globals.icon('mail')} style={{width: '14px', margin: '-1px 4px 0 -6px', opacity: '0.45'}} /> Sent</p>
                </div>
                <div className="crm-td crm-td-sm">
                  <p className="sub-text"><img src={Globals.icon('user')} style={{width: '14px', margin: '-2px 4px 0 -6px', opacity: '0.45'}} /> Opened</p>
                </div>
                <div className="crm-td crm-td-sm" style={{overflow: 'initial'}}>
                  <p className="sub-text"><img src={Globals.icon('bubble')} style={{width: '14px', margin: '-2px 4px 0 -6px', opacity: '0.45'}} /> Responded</p>
                </div>
                <div className="crm-td crm-td-sm">
                  <p className="sub-text"><img src={Globals.icon('link')} style={{width: '14px', margin: '-2px 4px 0 -6px', opacity: '0.45'}} /> Clicked</p>
                </div>
                <div className="crm-td crm-td-sm">
                  <p className="sub-text"><img src={Globals.icon('ok')} style={{width: '14px', margin: '-2px 4px 0 -6px', opacity: '0.45'}} /> Agreed</p>
                </div>
                <div className="crm-td crm-td-sm">
                  <p className="sub-text"><img src={Globals.icon('signal')} style={{width: '14px', margin: '-1px 4px 0 -6px', opacity: '0.45'}} /> Completed</p>
                </div>
              </div>
              { dripStages.length > 0 && dripStages.map((stage, i) => {
                  console.log('stage', i, stage)
                  var stats = stage.stats ? stage.stats : { sent: 0 }
                  var stageNums = '';
                  for (var x=1;x<=i;x++){ stageNums += `${x}`; if (x<i){ stageNums += ', ' } }

                  return (
                    <div key={i} className="module row crm-row" style={{ position: 'relative' }}>
                      <div className="">
                        <div className="crm-td crm-td-xs crm-td-img" style={{padding: '14px 14px'}}>
                          <div className="crm-checkbox">
                            <input type="checkbox" style={{display: 'block'}} />
                          </div>
                          <img className="crm-profile-img" src={''} />
                        </div>
                        <div className="crm-td crm-td-xs crm-td-img" style={{padding: '21px 14px 0'}}>
                          <div className="crm-drag">
                            <svg className="" width="16" height="16" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="11.5" width="2" height="2" rx="1" transform="rotate(-90 2 11.5)"></rect><rect x="7" y="11.5" width="2" height="2" rx="1" transform="rotate(-90 7 11.5)"></rect><rect x="12" y="11.5" width="2" height="2" rx="1" transform="rotate(-90 12 11.5)"></rect><rect x="2" y="6.5" width="2" height="2" rx="1" transform="rotate(-90 2 6.5)"></rect><rect x="7" y="6.5" width="2" height="2" rx="1" transform="rotate(-90 7 6.5)"></rect><rect x="12" y="6.5" width="2" height="2" rx="1" transform="rotate(-90 12 6.5)"></rect></svg>
                          </div>
                        </div>
                        <div className="crm-td crm-td-lg" style={{paddingTop: '11px'}}>
                          <Link to={`/outreach/drip/${this.state.dripId}/message/${stage.id}`}>
                            <div className="row row0">
                              <div>
                                <span><div style={{display: 'inline-block', float: 'left', fontWeight: 'bold'}}>{stage.title || `Stage ${stage.sequenceNum}`}</div></span>
                                <span><div className={`crm-tag ${stage.active ? 'active' : ''}`}>{stage.active ? 'Active' : 'Inactive'}</div></span>
                              </div>
                            </div>
                            <div className="row row0">
                              { i == 0 && <p className="sub-text" style={{fontSize: '14px'}}>Everyone in the bounty</p> }
                              { i > 0 &&
                                <p className="sub-text" style={{fontSize: '14px'}}>
                                  <span><img src={Globals.icon('bubble')} style={{width: '12px', opacity: '0.45', margin: '-3px 4px 0 0'}} /></span>
                                  Hasn't responded to Stage {stageNums}
                                </p>
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="crm-td crm-td-md">
                          <p>{moment(stage.createdAt ? stage.createdAt : stage.updatedAt).format('MMM D, YYYY')}</p>
                        </div>
                        <div className="crm-td crm-td-md">
                          <p>{moment(stage.updatedAt ? stage.updatedAt : stage.createdAt).format('MMM D, YYYY')}</p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p style={{ color: '' }}><b>{stats.sent ? stats.sent : 0}</b></p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p className="sub-text"><b>{Math.round(((+stats.opened || 0) / (+stats.sent || 1)) * 100)}%</b></p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p className="sub-text"><b>{Math.round(((+stats.responded || 0) / (+stats.sent || 1)) * 100)}%</b></p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p className="sub-text"><b>{Math.round(((+stats.clicked || 0) / (+stats.sent || 1)) * 100)}%</b></p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p className="sub-text"><b>{Math.round(((+stats.clicked || 0) / (+stats.sent || 1)) * 100)}%</b></p>
                        </div>
                        <div className="crm-td crm-td-sm">
                          <p className="sub-text"><b>{Math.round(((+stats.failed || 0) / (+stats.sent || 1)) * 100)}%</b></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              { dripStages.length == 0 && <EmptyMessage title="No Email Stages" subtext="Create an email stage to be sent to brands." /> }
              { this.props.loading && <Loader active={true} /> }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(Drip);
