import React, { useState } from 'react';
import './invite-shipping.scss';
import { LocationSearchInput } from '../../LocationSearch';
import {
  Address,
  upsertGuestPrimaryAddress,
} from '../../../services/address-service';
import history from '../../history';

export interface InviteShippingProps {
  primaryAddress: Partial<Address> | null;
  mode?: 'preview' | 'full';
  contentId?: string;
  refreshCampaign?: () => void;
}

export interface InviteShippingFullProps {
  initialPrimaryAddress: Partial<Address> | null;
  onSubmit: (address: Partial<Address>) => void;
  contentId?: string;
}

export interface InviteShippingPreviewProps {
  primaryAddress?: Partial<Address> | null;
}

export const InviteShippingFull: React.FC<InviteShippingFullProps> = ({
  initialPrimaryAddress,
  onSubmit,
  contentId,
}) => {
  const [primaryAddress, setPrimaryAddress] = useState(
    initialPrimaryAddress || {}
  );

  const updateAddressField = (key: string) => (event: any) =>
    setPrimaryAddress({
      ...primaryAddress,
      [key]: event.target.value,
    });

  const handleUpsertPrimaryAddress = async () => {
    if (contentId) {
      const resp = await upsertGuestPrimaryAddress({
        contentId,
        address: primaryAddress,
      });
      if (resp.success) {
        onSubmit(primaryAddress);
      }
    }
  };

  const Field = (props: { name: string; children: any }) => (
    <div className="col-md-6">
      <h5 className="sub-text">{props.name}</h5>
      {props.children}
    </div>
  );

  const shippingForm = (
    <form
      className="row"
      onSubmit={(event) => {
        handleUpsertPrimaryAddress();
        history.push('..');
        event.preventDefault();
      }}
    >
      <Field name="First Name">
        <input
          value={primaryAddress.firstName}
          name="first-name"
          onChange={updateAddressField('firstName')}
          className="dark-input"
        />
      </Field>
      <Field name="Last Name">
        <input
          value={primaryAddress.lastName}
          name="last-name"
          onChange={updateAddressField('lastName')}
          className="dark-input"
          required
        />
      </Field>

      <Field name="Street">
        <input
          value={primaryAddress.address1}
          name="address-line1"
          onChange={updateAddressField('address1')}
          className="dark-input"
          required
        />
      </Field>

      <Field name="Apt/Suite">
        <input
          value={primaryAddress.address2}
          name="address-line2"
          onChange={updateAddressField('address2')}
          className="dark-input"
        />
      </Field>

      <Field name="City">
        <input
          value={primaryAddress.city}
          name="city"
          onChange={updateAddressField('city')}
          className="dark-input"
          required
        />
      </Field>

      <Field name="State">
        <input
          value={primaryAddress.province}
          name="state"
          onChange={updateAddressField('province')}
          className="dark-input"
          required
        />
      </Field>

      <Field name="Zip">
        <input
          value={primaryAddress.zip}
          name="postal-code"
          onChange={updateAddressField('zip')}
          className="dark-input"
          required
        />
      </Field>

      <Field name="Country Code">
        <input
          value={primaryAddress.countryCode}
          name="country-code"
          onChange={updateAddressField('countryCode')}
          className="dark-input"
          required
          minLength={2}
          maxLength={2}
        />
      </Field>

      <div
        className="row"
        style={{
          paddingLeft: '28px',
          paddingRight: '28px',
          paddingBottom: '28px',
        }}
      >
        <div>
          <button
            className="plaid-btn"
            type="submit"
            style={{ marginLeft: '12px' }}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );

  return (
    <div className="invite-shipping-form">
      <LocationSearchInput
        onLocationSelect={(address) => {
          setPrimaryAddress({ ...primaryAddress, ...address });
        }}
      ></LocationSearchInput>

      {(initialPrimaryAddress || primaryAddress) && shippingForm}
    </div>
  );
};

export const InviteShippingPreview: React.FC<InviteShippingPreviewProps> = ({
  primaryAddress,
}) => {
  const renderList = (li: Array<string | undefined | null>) =>
    li.filter((item) => item).join(' ');

  return (
    <div className="invite-shipping-preview">
      {primaryAddress ? (
        <>
          <div className="sub-text">
            {renderList([primaryAddress.firstName, primaryAddress.lastName])}
          </div>

          <div className="sub-text">
            {renderList([primaryAddress.address1, primaryAddress.address2])}
          </div>

          <div className="sub-text">
            {renderList([primaryAddress.city, primaryAddress.countryCode])}
          </div>
        </>
      ) : (
        <div className="sub-text">
          Add shipping address to receive product gifts
        </div>
      )}
    </div>
  );
};

export const InviteShipping: React.FC<InviteShippingProps> = ({
  primaryAddress,
  mode = 'full',
  contentId,
  refreshCampaign,
}) => {
  return (
    <div className="invite-shipping">
      {mode === 'full' ? (
        <div className="invite-shipping-full">
          <div className="sub-text">
            {' '}
            Update your shipping information. We'll use this to determine where
            to ship your product.
          </div>

          <InviteShippingFull
            initialPrimaryAddress={primaryAddress}
            onSubmit={() => refreshCampaign && refreshCampaign()}
            contentId={contentId}
          />
        </div>
      ) : (
        <InviteShippingPreview primaryAddress={primaryAddress} />
      )}
    </div>
  );
};
