import { Component, memo } from 'react'
import Globals from '../../global-functions.js'
import ContactsTable from './contacts-table'

function _get(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
function _set(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

class AddContactPopup extends Component {

  constructor(props){
    super(props)
    this.state = {
      loading: true,
      inputValue: '',
      addText: '+ ADD',
      personalizedMessage: '',
      draftEmail: '',
      companies: []
    }
  }

  componentDidMount(){
    this.getContacts()
  }

  async getContacts(){
    var { brands } = this.props
    var res = await _get('getContacts', { ids: brands.map((b) => { return b.meta.id }) }); this.setState({ ...res, loading: false })
  }

  async chooseContact(e){
    var { meta } = this.props.brands[0]
    var res = await _set('chooseContact', { apolloId: meta.id, contactId: e.id }); this.getContacts()
  }

  handleClosePopup(close, e) {
    var { active, type, brands, _this } = this.props, { companies } = this.state, selectedContact
    companies.forEach((com) => { com.contacts.forEach((c) => { if (c.selected) selectedContact = { contact: c, metaId: com.company.id } }) })
    if (active){
      if (close != 'close' && this.node && this.node.contains(e.target) && !$(e.target).hasClass('popup-out-close-icon')){ console.log('return'); return }
      else { this.props.togglePopup('contact', selectedContact) }
    }
  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }
  selectEmployee(e){  }
  chooseSort(e){  }

  render() {
    var { companies, loading } = this.state, { _this } = this.props
    var { selectEmployee, chooseContact, chooseSort } = this

    return (
      <div ref={ node => this.node = node }>
        <div className="add-influencer-popup">
          <div className="add-influencer-popup-container">
            <div className="add-influencer-popup-module" style={{width: '850px', height: '575px', maxWidth: '90%'}}>
              <div className="add-influencer-popup-outer">
                <div className="add-influencer-popup-inner">
                  <div className="add-influencer-popup-header">
                    <div className="row row0">
                      <h3>Choose Primary Email Contact</h3>
                    </div>
                    <div className="popup-out-close-icon" onClick={this.handleClosePopup.bind(this, 'close')} style={{position: 'absolute', top: '15px', right: '15px', width: '15px', height: '15px', cursor: 'pointer'}}>
                      <img src={Globals.icon('close')} style={{width: '100%', height: '100%'}} />
                    </div>
                  </div>
                  <div className="add-influencer-popup-body">
                    <div className="row row0">
                      <ContactsTable this={this} brands={companies} loading={loading} selectEmployee={selectEmployee.bind(this)} chooseContact={chooseContact.bind(this)} chooseSort={chooseSort.bind(this)} _this={_this} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(AddContactPopup);
