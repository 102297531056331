import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import moment from 'moment'
import InvoicePill from './invoice-pill.jsx'

export default class CreateInvoicePreview extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var { invoice } = this.props

    return (
      <Link to={`/invoices/create/${invoice.id}`}>
        <div className="preview-invoice">
          <div className="module">
            <div className="row">
              <div className="col-xs-2" style={{position: 'relative', height: '75px'}}>
                <div className="preview-invoice-company-image-container">
                  <div className="preview-invoice-company-image-inner">
                    <div className="preview-invoice-company-image" style={{backgroundImage: `url(${`https://logo.clearbit.com/${invoice.domain}?size=150`})`}}></div>
                  </div>
                </div>
              </div>
              <div className="col-xs-5">
                <label className="sub-text">Invoice <b>#{invoice.id}</b></label>
                <h4 style={{margin: 0}}>{invoice.company}</h4>
                <div>{moment(invoice.dueDate).format('MMM Do, YYYY')}</div>
              </div>
              <div className="col-xs-5">
                <div className="row row0">
                  <InvoicePill invoice={invoice} type="status" className="invoice-status-preview" />
                </div>
                <div className="row row0" style={{marginTop: '8px'}}>
                  <h3>${Globals.numberWithCommas(invoice.amount)}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}
