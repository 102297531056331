import { memo, useEffect, useRef, useState, useCallback } from 'react';
import Dropzone from 'react-dropzone-uploader'
import Loader from './loader';
import Globals from '../global-functions';

declare const $: any;

const _getUploadParams = () => ({ url: 'api/me/rate-screenshot' })
const _getRatesScreenshots = () => $.ajax({ method: 'GET', url: '/api/me/rate-screenshots', data: {}, }) as Promise<string[]>;
const _deleteRatesScreenshot = (url: string) => $.ajax({ method: 'DELETE', url: '/api/me/rate-screenshots/' + encodeURIComponent(url), data: {}, });

const initialState = Object.freeze({
  screenshots: []
});

export const AccountScreenshots = memo(() => {
  const formRef = useRef<HTMLFormElement>();
  const [form, setForm] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);

  useEffect(() => { loadData(); }, []);

  const loadData = () => {
    setLoading(true);

    return Promise.all([ _getRatesScreenshots() ])
      .then(([ screenshots ]) => { setForm({ screenshots: screenshots as any }); })
      .finally(() => { setLoading(false); })
      .catch(() => { setInvalidUser(true); })
  };

  const deleteScreenshot =  async (url: string) => {
    await _deleteRatesScreenshot(url);
    await loadData();
  }

  // if (invalidUser) {
  //   return <div> Please connect your instagram to add your rates. </div>
  // }

  return (
    <div className={'rates-form'}>
      <div className="row row0" style={{marginBottom: '15px'}}>
        <h3
          style={{ margin: '20px 0px 15px', padding: 0, fontSize: '20px', textTransform: 'initial' }}>Screenshot Analytics</h3>
        <p className="sub-text">For social accounts you do not link above, please upload screenshots of your analytics. Instagram story insights, Instagram/YouTube/Tiktok audience demographics, etc</p>
      </div>

      <form
        className="module"
        ref={formRef as any}
        onSubmit={(event) => updateRates(event)}
      >
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className="row">
              {form.screenshots.map((screenshot) => (
                <div className="col-xs-4">
                  <div className="rate-img-preview" style={{backgroundImage: `url(${screenshot})`}}>
                    <div className="rate-img-preview-darken"></div>
                    <img className="rate-img-delete" src={Globals.icon('close', 'white')} onClick={() => deleteScreenshot(screenshot)} />
                  </div>
                </div>
              ))}
            </div>

            {
              loading ? ( <Loader></Loader> ) : (
              <Dropzone
                inputContent={null}
                getUploadParams={_getUploadParams}
                // onSubmit={() => loadData()}
                onChangeStatus={({ remove }, status) => { if (status === 'headers_received') { remove(); loadData(); } }}
                accept="image/*"
              />
            )}
          </div>
        </div>

      </form>
    </div>
  );
});
