import { Component, memo } from 'react';
import Globals from '../global-functions.js';
import AccountSocials from './account-socials.jsx';
import AccountAddresses from './account-addresses.jsx';
import AccountCards from './account-cards.jsx';
import AccountDetails from './account-details.jsx';
import AccountInvoice from './account-invoice.jsx';
import AccountReferrals from './account-referrals.jsx';
import store from 'store2';
import Cookies from 'js-cookie';
import { IsDev } from './isDev';
import AccountNotifications from './account-notifications';
import { AccountScreenshots } from './account-screenshots';
import { AccountRatesForm } from './account-rates-form';
import { AccountCategories } from './account-categories';
import Cashout from './cashout.jsx';

class Account extends Component {

  constructor(props){
    super(props)
    this.state = {
      user: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {},
      userid: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')).userid : '',
      userdata: false,
      firstName: '',
      lastName: '',
      email: '',
      paypalEmail: '',
      gender: '',
      birthday: '',
      updateSettingsBtn: 'Save',
      updateShippingBtn: 'Save',
      socials: []
    }
  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
    if (!this.state.user.internal && Globals.isProd){ window.Intercom("update"); }
    this.refreshUser()
    if (location.hash && location.hash.length > 3) { setTimeout(() => { location.href = location.hash }, 50) }
  }

  refreshUser(){
    this.getUser().done((response) => {
      if (response){
        var obj = {
          userdata: response,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          paypalEmail: response.paypalEmail,
          gender: response.gender,
          birthday: response.birthday,
          socials: response.socials,
          addresses: response.addresses
        }
        this.setState(obj)
      }
      else {  }
    })
  }

  getUser(){ return $.ajax({ method: 'GET', url: '/api/me/details', data: { } }) }

  logout(){
    Globals.logEvent('click', 'logout')
    Cookies.remove('access_token')
    Cookies.remove('userid')
    Cookies.remove('userdata')
    Cookies.remove('twitchdata')
    Cookies.remove('mixerdata')
    store('access_token', null)
    store('userid', null)
    location.href = '/'
    Globals.logEvent('event', 'logout')
  }

  showCashout(){ this.setState({ cashout: true }) }
  hideCashout(){ this.setState({ cashout: false }) }

  getBalance() {
    this._getBalance().done((response) => {
      Cookies.set('totalBalance', response.total)
      Cookies.set('availableBalance', response.available)
      Cookies.set('pendingBalance', response.pending)

      this.setState({
        totalBalance: response.total,
        availableBalance: response.available,
        pendingBalance: response.pending,
        balanceLoaded: parseFloat(this.state.totalBalance) - parseFloat(response.total) != 0 ? true : false
      })
    })
  }

  _getBalance(){ return $.ajax({ method: 'GET', url: '/api/Users/getBalance', data: { userid: this.state.userid } }) }
  getBountyCredits(){ this._getBountyCredits().done((response) => { this.setState({ bountyCredits: response.credits, bountyCreditLimit: response.creditLimit, concurrents: response.concurrents, twitchUser: response.twitchUser }) }) }
  _getBountyCredits(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getBountyCredits', data: { userid: this.state.userid } }) }

  render() {

    var user = this.state.userdata
    // var connectedSocials = false, connectedAddress = false, connectedCard = false
    var socialActive = {}
    this.state.socials.forEach((s) => {
      if (s.label == 'InstagramUser'){ socialActive.instagram = true }
      else if (s.label == 'Influencer'){ socialActive.youtube = true }
      else if (s.label == 'TwitchUser'){ socialActive.twitch = true }
      else if (s.label == 'MixerUser'){ socialActive.mixer = true }
    })

    var profileImg = ''

    return (
      <div>
        <div className="account-settings" style={{maxWidth: '700px', margin: '0', padding: '15px'}}>
          <div className="row row0" style={{marginTop: 20}}>
            <div className="module" style={{maxWidth: '700px', overflow: 'auto'}}>
              <div>
                <div className="row">
                  <div className="row row0 talent-manager-container">
                    <div className="col-md-2 col-xs-3">
                      <img style={{width: '100%', borderRadius: '50%'}} src="https://weave-img.s3.us-west-2.amazonaws.com/web-assets/logan-w-prof.jpeg" />
                    </div>
                    <div className="col-md-10 col-xs-9">
                      <h4 style={{marginTop: '15px', fontWeight: 'bold'}}>Logan</h4>
                      <p>Your Weave Talent Manager</p>
                    </div>
                  </div>
                  <div className="row row0">
                    <div className="col-md-2"></div>
                    <div className="col-md-10">
                      <div className="row row0">
                        <p className="sub-text">Your talent manager can help improve your visibility to brands, price your content accordingly, help you negotiate with brands, provide insight to grow your social channels, and in general help you navigate the marketing space. We want to help you, so please reach out!</p>
                        <input className="dark-input" defaultValue={'logan@weavesocial.com'} style={{fontWeight: 'bold'}} readOnly />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />


          <AccountDetails/> <hr />
          <AccountSocials this={this} refreshUser={this.refreshUser.bind(this)} user={user} socials={this.state.socials} /> <hr />
          <AccountScreenshots/> <hr />
          <AccountRatesForm/> <hr />
          <AccountCategories/> <hr/>
          <AccountAddresses this={this} refreshUser={this.refreshUser.bind(this)} user={user} /> <hr />
          <AccountNotifications/> <hr />

          <IsDev>
            <AccountCards this={this} refreshUser={this.refreshUser.bind(this)} user={user} /> <hr />
          </IsDev>

          { this.state.cashout && <Cashout active={this.state.cashout} hideCashout={this.hideCashout} user={this.state.user} this={this} /> }

          <div className="row row0">
            <a href="#" id="logout" className="btn-action btn-primary"
              onClick={this.logout}
            >Logout</a>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(Account)
