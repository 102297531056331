import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from '@redux-saga/core';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './js/redux/reducers/root.reducer';
import { rootSaga } from './js/redux/sagas/root.sagas';
import { composeEnhancers } from './js/utils/utils';


const persistConfig = {
  key: 'root',
  whitelist: [],
  storage: storageSession,
};



export const configureStore = () => {
  // configure middlewares
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];


  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  // rehydrate state on app start
  const initialState = {};

  // create store
  const store = createStore(persistReducer(persistConfig, rootReducer), initialState, enhancer);
  // const store = createStore(rootReducer, initialState, enhancer);


  sagaMiddleware.run(rootSaga);
  let persistor = persistStore(store);
  return { store, persistor };
};
