import { memo } from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import CRMTableMainCell from './crm-table-main-cell'

const CRMTableMainRow = ((props) => {

    var { brandObj, togglePopup, selectBrand, index, _this, __this } = props
    var { instagram, social, brand, hubspot, meta, relatedCt, deals, categories, categoryTypes, emails, socials, pitches, pitching, pipelines, contacts, selectedContact, affiliatePrograms } = brandObj
    meta=meta||{}; instagram=instagram||social||{}; brand=brand||{}; hubspot=hubspot||{}; selectedContact=selectedContact||{};
    instagram.label = 'InstagramUser'; instagram.selectedActive = brandObj.selectedActive;
    var initial = "", social = instagram, content = brandObj.content || {}, imageUrl = meta.imageUrl || hubspot.imageUrl || `https://logo.clearbit.com/${meta.domain || brand.domain}?size=150`;
    socials = socials ? socials : [instagram]
    if (instagram.displayName) { initial = instagram.displayName[0]; }
    else if (instagram.username) { initial = instagram.username[0]; }
    var checkSocials = []
    // socials = socials.filter((s) => { if ( !checkSocials[s.id ? s.id : s.username] ){ checkSocials[s.id ? s.id : s.username] = true; return s } })
    // socials.forEach((s) => {
    //   if (!imageUrl) { imageUrl = s.imageUrl; }
    //   if (s.displayName && initial == "") { initial = s.displayName[0]; }
    //   if (s.username && initial == "") { initial = s.username[0]; }
    // });

    var infName = `${(meta && meta.name) || (brand && brand.name ) || instagram.displayName || instagram.username}`
    var emailStatus = 'none'
    if (brandObj.content && brandObj.content.email && !brandObj.content.invalidEmail) { emailStatus = 'yes' }
    else if (brandObj.content && brandObj.content.email && brandObj.content.invalidEmail) { emailStatus = 'invalid' }
    else if (brandObj.email) { emailStatus = 'choose' }
    var emailColors = { 'none': 'color-error', 'choose': 'color-needs-attention', 'yes': 'color-complete', 'invalid': 'color-needs-attention' }

    var pitchingStatus
    if (pitching && pitching.length > 0){
      if (!brandObj.firstContacted){ pitchingStatus = 'Queued' }
      else if (brandObj.firstContacted && deals.length == 0){ pitchingStatus = 'Pitched' }
      else if (brandObj.firstContacted && deals.length > 0){ pitchingStatus = 'Deal' }
    }

    var estimatedSpend = ''
    if (instagram.stat_AdsSpend180){
      var eLow = Number(instagram.stat_AdsSpend180) * .7, eHigh = Number(instagram.stat_AdsSpend180) * 1.3
      estimatedSpend = `$${Globals.convertNumberFormat(eLow, true)} - $${Globals.convertNumberFormat(eHigh, true)}`
    }
    var contactSelectedText = 'Auto-Selected', contactSelectedColor, contactSelectedIcon = 'bulb'
    if (selectedContact.selected) { contactSelectedText = 'Primary Contact'; contactSelectedColor = 'verified'; contactSelectedIcon = 'check' }
    else if (selectedContact.marketingInfluencer == 1){ contactSelectedColor = 'active'; }
    else if (selectedContact.marketing == 1){ contactSelectedColor = 'warning'; }
    else { contactSelectedColor = 'error'; }

    return (
      <>
        <div key={index} className={`row row0 crm-row crm-row-influencer ${brandObj.selectedActive ? "selected" : ""}`}>
          <div className="row row0">
            <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} imageUrl={imageUrl} initial={initial} influencer={instagram} hoverFunc={selectBrand.bind(__this, brandObj)} _this={_this} />
            <CRMTableMainCell title={infName} size="crm-td-ml" classes="crm-username" brand={brandObj} meta={meta} instagram={instagram} type="main-name" togglePopup={togglePopup} influencer={instagram} influencerLabel={'InstagramUser'} _this={_this} />
            {/*<CRMTableMainCell title={brand.domain} size="crm-td-md" domain={brand.domain} _this={_this} />*/}
            {/*<CRMTableMainCell title={instagram.username ? '@'+instagram.username : ''} size="crm-td-md" classes="crm-username crm-td-dbl" clickFunc={triggerSlideOut} _this={_this} />*/}
            <CRMTableMainCell type="actions" size="crm-td-md" brand={brandObj} meta={meta} togglePopup={togglePopup} _this={_this} />
            <CRMTableMainCell title={Globals.convertNumberFormat(instagram.followerCount) || ''} size="crm-td-sm" />
            <CRMTableMainCell title={meta.seoDescription || meta.shortDescription || instagram.bio} size="crm-td-xl" type="dbl-text" classes="crm-td-dbl" togglePopup={togglePopup} _this={_this} />
            {/*<CRMTableMainCell size="crm-td-sm" type="socials" socials={socials} _this={_this} />*/}
            {/*<CRMTableMainCell title={instagram.username ? '@'+instagram.username : ''} linkUrl={instagram.username ? `https://instagram.com/${instagram.username}`: ''} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={hubspot && hubspot.domain ? '✅' : ''} size="crm-td-sm" _this={_this} />*/}
            {/*<CRMTableMainCell title={pipelines.map((c) => { return c.name }).join(', ')} size="crm-td-lg" type="tile" tileColor={pipelines.length > 0 ? 'color-complete' : 'color-error'}  _this={_this} />*/}
            {/*<CRMTableMainCell title={pitchingStatus} size="crm-td-sm" type="tile" tileColor={pitchingStatus ? 'color-complete' : 'color-error'}  _this={_this} />*/}
            {/*<CRMTableMainCell title={pipelines.length > 0 ? 'Contacted' : ''} size="crm-td-sm" type="tile" tileColor={hubspot && hubspot.domain ? 'color-complete' : 'color-error'}  _this={_this} />*/}
            {/*<CRMTableMainCell title={contacts.map((c) => { return c.email }).join(', ')} size="crm-td-lg" _this={_this} />*/}
            <CRMTableMainCell title={selectedContact.title} subtext={selectedContact.name} badgeText={contactSelectedText} badgeIcon={contactSelectedIcon} badgeColor={contactSelectedColor} dataObject={selectedContact} type="title-subtext" size="crm-td-lg" classes="crm-td-dbl" _this={_this} />
            <CRMTableMainCell title={brandObj.lists ? brandObj.lists.map((l) => { return l.name }) : []} type="tile" size="crm-td-ml" classes="crm-td-dbl" _this={_this} />
            <CRMTableMainCell title={brandObj.leads ? brandObj.leads.map((l) => { return l.status }) : []} type="tile" size="crm-td-sm" classes="crm-td-dbl" _this={_this} />
            <CRMTableMainCell title={meta.industry} size="crm-td-ml" _this={_this} />
            {/*<CRMTableMainCell title={meta.country} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(relatedCt || 0) || ''} size="crm-td-sm" />*/}
            <CRMTableMainCell title={estimatedSpend} size="crm-td-md" />
            <CRMTableMainCell title={Globals.convertNumberFormat(instagram.AdsAllTime) || ''} size="crm-td-sm" />
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(instagram.MentionsAllTime) || ''} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={meta.languages ? meta.languages.join(', ') : ''} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={categories ? categories.map((c) => { return c.name }).join(', ') : ''} size="crm-td-lg" _this={_this} />*/}
            {/*<CRMTableMainCell title={meta.keywords ? meta.keywords.join(', ') : ''} size="crm-td-xl" type="dbl-text" classes="crm-td-dbl" _this={_this} />*/}
            {/*<CRMTableMainCell title={affiliatePrograms ? affiliatePrograms.map((l) => { return l.type }) : []} type="tile" size="crm-td-ml" classes="crm-td-dbl" _this={_this} />*/}
            {/*<CRMTableMainCell title={brandObj.firstContacted ? moment(brandObj.firstContacted).format('MMM D, YYYY') : ''} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={brandObj.lastContacted ? moment(brandObj.lastContacted).format('MMM D, YYYY') : ''} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(instagram.avgLikes) || ''} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(instagram.avgComments) || ''} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={`$${Globals.convertNumberFormat(hubspot.totalDealsAmount || 0)}`} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={`${Globals.convertNumberFormat(hubspot.totalDeals || 0)}`} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(meta.annual_revenue) || ''} precursor="$" size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(meta.employeeCt) || ''} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(meta.total_funding || meta.market_cap) || ''} precursor="$" size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={Globals.convertNumberFormat(meta.alexa_ranking) || ''} size="crm-td-sm" />*/}
            {/*<CRMTableMainCell title={moment(instagram.lastRefreshed).format('MM-DD-YYYY')} size="crm-td-md" _this={_this} />*/}
            {/*<CRMTableMainCell title={brandObj.bountyCt ? brandObj.bountyCt : "0"} size="crm-td-sm" />
            <CRMTableMainCell title={brandObj.totalClicks ? brandObj.totalClicks : "0"} size="crm-td-sm" />
            <CRMTableMainCell title={brandObj.totalPosts ? brandObj.totalPosts : "0"} size="crm-td-sm" />*/}
          </div>
        </div>
      </>
    );
})

export default memo(CRMTableMainRow);
