import React from 'react';
import { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import '../../styles/scss/location-search.scss'

type AddressFields = {
  address1: string,
  // address2: string,
  city: string;
  province: string;
  country: string;
  countryCode: string;
  zip?: string;
  streetNumber: string;
};

type PlacesAutoCompleteProps = {
  getInputProps: any,
  suggestions: any,
  getSuggestionItemProps: any,
  loading: any
}

type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
type AddressComponentType = "postal_code" | "country" | "locality"| "city" | "street_number" | "administrative_area_level_1" | "route";

type LocationSearchInputProps = {
  onLocationSelect: (address: AddressFields) => void
}

const extractFromAddress = (components: GeocoderAddressComponent[], type: AddressComponentType, longName: boolean = true) => {
  return components.filter(
    (component) => component.types.indexOf(type) === 0
  ).map((item) => item[longName? 'long_name': 'short_name']).pop() || '';
}

export const LocationSearchInput: React.FC<LocationSearchInputProps> = ({onLocationSelect}): JSX.Element => {

  const [address, setAddress] = useState();

  const handleChange = (address: any) => {
    setAddress(address);
  };

  const handleSelect = (address: string, placeId: string, suggestion?: any) => {
    geocodeByAddress(address)
      .then(([firstAddressResult] )=> {
        const addressComponents = firstAddressResult.address_components;
        onLocationSelect({
          address1: `${extractFromAddress(addressComponents, "street_number")}, ${extractFromAddress(addressComponents, "route", true)}`,
          city: extractFromAddress(addressComponents, "locality"),
          province: extractFromAddress(addressComponents, "administrative_area_level_1"),
          country: extractFromAddress(addressComponents, "country"),
          countryCode: extractFromAddress(addressComponents, "country", false),
          zip: extractFromAddress(addressComponents, "postal_code"),
          streetNumber: extractFromAddress(addressComponents, "street_number"),
        });

        handleChange('');

      })
      .catch(error => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address || ''}
      searchOptions={{ types: ['address'] }}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }: PlacesAutoCompleteProps) => (
        <div className="autocomplete-wrapper">
          <input
            {...getInputProps({
              placeholder: 'Search a street and house number...',
              className: 'location-search-input dark-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion: any) => {

              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: 'suggestion-item',
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );

};
