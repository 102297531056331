import { createAction } from 'typesafe-actions';
import { ActionType } from 'typesafe-actions';
import {
  GetContentObjectivesRequest,
  GetContentObjectivesResponse,
  GetContentObjectivesFailed,
  GetContentApprovalsAnonymousRequest,
  GetContentApprovalsAnonymousResponse,
  GetContentApprovalsAnonymousFail,
  CreateContentApprovalAnonymousRequest,
  CreateContentApprovalAnonymousResponse,
  CreateContentApprovalAnonymousFail,
  UpdateContentForReviewRequest, UpdateContentForReviewResponse, UpdateContentForReviewFailed
} from '../models/content.models';

export const contentActionCreators = {
  getContentObjectivesAction: createAction('contentObjectives/GET')<GetContentObjectivesRequest>(),
  getContentObjectivesSuccessAction: createAction('contentObjectives/GET/success')<GetContentObjectivesResponse>(),
  getContentObjectivesFailAction: createAction('contentObjectives/GET/fail')<GetContentObjectivesFailed>(),
  getContentApprovalsAnonymousAction: createAction('contentApprovalsAnonymous/GET')<GetContentApprovalsAnonymousRequest>(),
  getContentApprovalsAnonymousSuccessAction: createAction('contentApprovalsAnonymous/GET/success')<GetContentApprovalsAnonymousResponse>(),
  getContentApprovalsAnonymousFailAction: createAction('contentApprovalsAnonymous/GET/fail')<GetContentApprovalsAnonymousFail>(),
  createContentApprovalAnonymousAction: createAction('contentApprovalAnonymous/CREATE')<CreateContentApprovalAnonymousRequest>(),
  createContentApprovalAnonymousSuccessAction: createAction('contentApprovalAnonymous/CREATE/success')<CreateContentApprovalAnonymousResponse>(),
  createContentApprovalAnonymousFailAction: createAction('contentApprovalAnonymous/CREATE/fail')<CreateContentApprovalAnonymousFail>(),
  updateContentForReviewAction: createAction('contentForReview/UPDATE')<UpdateContentForReviewRequest>(),
  updateContentForReviewSuccessAction: createAction('contentForReview/UPDATE/success')<UpdateContentForReviewResponse>(),
  updateContentForReviewFailAction: createAction('contentForReview/UPDATE/fail')<UpdateContentForReviewFailed>(),
};

export type GetContentObjectivesAction = ActionType<
  typeof contentActionCreators.getContentObjectivesAction
  >;
export type GetContentObjectivesSuccessAction = ActionType<
  typeof contentActionCreators.getContentObjectivesSuccessAction
  >;
export type GetContentObjectivesFailAction = ActionType<
  typeof contentActionCreators.getContentObjectivesFailAction
  >;
export type GetContentApprovalsAnonymousAction = ActionType<
  typeof contentActionCreators.getContentApprovalsAnonymousAction
  >;
export type GetContentApprovalsAnonymousSuccessAction = ActionType<
  typeof contentActionCreators.getContentApprovalsAnonymousSuccessAction
  >;
export type GetContentApprovalsAnonymousFailAction = ActionType<
  typeof contentActionCreators.getContentApprovalsAnonymousFailAction
  >;
export type CreateContentApprovalAnonymousAction = ActionType<
  typeof contentActionCreators.createContentApprovalAnonymousAction
  >;
export type CreateContentApprovalAnonymousSuccessAction = ActionType<
  typeof contentActionCreators.createContentApprovalAnonymousSuccessAction
  >;
export type CreateContentApprovalAnonymousFailAction = ActionType<
  typeof contentActionCreators.createContentApprovalAnonymousFailAction
  >;
export type UpdateContentForReviewAction = ActionType<
  typeof contentActionCreators.updateContentForReviewAction
  >;
export type UpdateContentForReviewSuccessAction = ActionType<
  typeof contentActionCreators.updateContentForReviewSuccessAction
  >;
export type UpdateContentForReviewFailAction = ActionType<
  typeof contentActionCreators.updateContentForReviewFailAction
  >;


export type ContentActions = ActionType<typeof contentActionCreators>;
