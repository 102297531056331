import { Nullable } from '../../utils/utils';
import React from 'react';
import { GetInvitationResponse } from '../../redux/models/invitation.models';

export interface InviteBodyProps {
  loading: boolean;
  invitation?: Nullable<GetInvitationResponse>;
  children?: any;
}


export const InviteBody: React.FC<InviteBodyProps> = ({loading, invitation, children}) => {
  if (!invitation) { return <></> }
  const { bountyTitle, social, displayName } = invitation;
  const name = displayName || (social && (social.displayName || social.username));

  return <div className="invitation-meta-inner">
    <div className="row row0 top-section">
      <div className="slide-out-header" style={{marginBottom: '25px'}}>
        <div className="row row0">
          <h3>{bountyTitle}</h3>
          <p className="sub-text" style={{margin: 0}}>{invitation.offerTagline || invitation.offerCta}</p>
          <p className="sub-text" style={{fontSize: '14px'}}><a href={invitation.offerWebsiteUrl} target="_blank">{invitation.offerWebsiteUrl}</a></p>
          {
            invitation.bountyCategoryTags && invitation.bountyCategoryTags.length > 0 &&
            <div className="row row0" style={{margin: '15px 0'}}>
              {
                invitation.bountyCategoryTags.map((c) => {
                  return <div className="bounty-tile-category" style={{float: 'initial'}}>{c}</div>
                })
              }
            </div>
          }
        </div>
      </div>
      {/*<div className="bounty-influencer-name">
        <h3>{bountyTitle} </h3>
        {
          !!name && <>
            <h4 className="and">&</h4>
            <h3>{name}</h3>
          </>
        }
      </div>*/}
      <div className="row row0">
        {/*<p className="brand-tagline">You have been invited to a partnership with {bountyTitle}</p>*/}
      </div>
    </div>

    {children}
  </div>
};
