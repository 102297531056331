import { useSelector } from 'react-redux';
import { RootState } from '../reducers/root.reducer';

export const authSelector = (state: RootState) => state.login;
export const useAuthSelector = () => useSelector(authSelector);


export const isLoggedInSelector = (state: RootState) => state.login.authenticated;
export const useIsLoggedInSelector = () => useSelector(isLoggedInSelector);

export const authLoadingSelector = (state: RootState) => state.login.loading;
export const useAuthLoadingSelector = () => useSelector(authLoadingSelector);
