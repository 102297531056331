import React from 'react'
import Globals from '../../global-functions.js'
import Loader from "../loader";
// import Loader from './loader'
// import Autocomplete from 'react-autocomplete'

export default class Filter extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      active: false
    }
  }

  UNSAFE_componentWillMount(){ document.addEventListener('mousedown', this.toggleOpen.bind(this), false) }
  componentWillUnmount(){ document.removeEventListener('mousedown', this.toggleOpen.bind(this), false) }

  toggleOpen(e) {
    if (this.node ? this.node.contains(e.target) : false){ if (!this.state.active){ this.setState({ active: true }) } }
    else { if (this.state.active){ this.setState({ active: false }) } }
  }

  chooseOption(optionNum, option){
    this.setState({ selected: optionNum, active: false })
    if (this.props.this){
      if (this.props.filterName == 'override' || this.props.filterName == 'overrideBrand' || this.props.filterName == 'addAgencyBrand'){
        this.props.selectFilter(this.props.this, this.props.filterName, this.props.options[optionNum], option)
      }
      else { this.props.selectFilter(this.props.this, this.props.filterName, this.props.options[optionNum], optionNum) }
    }
  }

  render(){

    if (this.props.loading) {
      return <div style={{height: '50px', transform: 'scale(0.3)'}}>
        <Loader active={true}></Loader>
      </div>
    }

    let html = '';
    let selectedOption = this.props.selectedOption ? this.props.selectedOption : {};
    if (this.props.filterName === 'override' || this.props.filterName === 'overrideBrand' || this.props.filterName === 'addAgencyBrand'){
      html = (
        <div ref={ node => this.node = node } key={Math.floor(Math.random() * 99999999999)}>
          <div className={`search-filter-box search-filter-box-submenu ${this.state.active ? 'active' : ''}`}>
            <div className="search-filter-chosen">
              <div className="search-filter-chosen-img-container">
                <div className="search-filter-chosen-img" style={ selectedOption.imageUrl ? {backgroundImage: `url(${selectedOption.imageUrl})`} : {}}>
                  { !selectedOption.imageUrl && <span>{selectedOption.title ? selectedOption.title[0] : ''}</span> }
                </div>
              </div>
              <div style={{display: 'inline-block', float: 'left', width: 'calc(100% - 64px)'}}>
                <p className="search-filter-menu-title" style={{margin: 0}}>{selectedOption.title}</p>
                {/*{
                  this.props.subtitle &&
                  <p className="search-filter-menu-subtitle" style={{margin: 0}}>{this.props.subtitle}</p>
                }*/}
              </div>
              {/*<div style={{display: 'inline-block', float: 'right'}}>
                <span>{selectedOption.ct}</span>
              </div>*/}
            </div>
            <div className="search-filter-dropdown">
              <ul>
                { this.props.options && this.props.options.map((option, i) => {
                    return (
                      <li onClick={this.chooseOption.bind(this, i, option)} key={i}>
                        <div className="row row0">
                          <div className="search-filter-chosen-img-container search-filter-chosen-img-small">
                            <div className="search-filter-chosen-img" style={option.imageUrl ? {backgroundImage: `url(${option.imageUrl})`} : {}}>
                              { !option.imageUrl && <span>{option.title ? option.title[0] : ''}</span> }
                            </div>
                          </div>
                          <div className="search-filter-chosen-name">
                            <span>{option.title ? option.title : 'No Title'}</span>
                          </div>
                          <div className="search-filter-chosen-ct">
                            <span>{option.ct}</span>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      )
    }
    else {
      var selectedText = this.props.options && this.props.options[this.props.selected] ? this.props.options[this.props.selected] : ''
      html = (
        <div ref={ node => this.node = node } key={Math.floor(Math.random() * 99999999999)}>
          <div className={`search-filter-box search-filter-box-filtermenu ${this.state.active ? 'active' : ''}`}>
            <span>{selectedText}</span>
            <div className="search-filter-dropdown">
              <ul>
                { this.props.options && this.props.options.map((option, i) => { return ( <li onClick={this.chooseOption.bind(this, i)}>{option}</li> ) }) }
              </ul>
            </div>
          </div>
        </div>
      )
    }

    return ( <>{html}</> )
  }

}
