import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import Cookies from 'js-cookie';

export default class Landing extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      category: 'fashion'
    }
  }

  componentWillUpdate(){

  }

  componentDidUpdate(){

  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
    var referral = location.search
    if (referral.indexOf('r=') > -1){
      referral = referral.split('r=')[1].split('&')[0]
      Cookies.set('referral', referral)
    }
    var cmp = location.search
    if (cmp.indexOf('campaign=') > -1){
      cmp = cmp.split('campaign=')[1].split('&')[0]
      Cookies.set('cmp', cmp)
    }
    else {
      cmp = null;
    }

    window.Intercom("boot", { app_id: "rm4ja52n" });

    // tidioChatApi.method('ready', function(){
    //   tidioChatApi.setVisitorData({ tags: ['homepage'] })
    //   if (Cookies.get('visitor_id')){ tidioChatApi.setVisitorData({ distinct_id: Cookies.get('visitor_id') }) }
    // })

  }

  componentWillUnmount(){

  }

  changeCategory(category){
    this.setState({category: category})
  }

  render() {

    return (

      <div className="landing">
        <div className="section hero">
          <div className="background-strip"></div>
          <div className="container">
            <img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
            <div className="login-container">
              <div className="login-container-inner">
                <Link to="/signup"><span className="signup" style={{marginRight: '45px'}}>get started</span></Link>
                <Link to="/login"><span>login</span></Link>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="title">
                <h1>The easiest way for creators to earn money from things they promote.</h1>
                <p>Link to products you feature. Earn when viewers click and buy.</p>
                <div className="actions">
                  <Link to="/signup"><span className="get-started agency btn-apply" style={{marginRight: '45px'}}>Get Started</span></Link>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="hero-icons">
                <img className="shadow" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/content-and-earnings-icon.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container" style={{paddingTop: '50px', textAlign: 'left'}}>
            <div className="" style={{maxWidth: '650px', margin: '0 auto'}}>
              <div className="row" style={{}}>
                <div className="ur-goddamn-right" style={{maxWidth: '680px'}}>
                  <div className="" style={{display: 'inline-block', float: 'left', width: '80px'}}>
                    <img style={{}} className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/smiley.svg" alt="Weave Logo" />
                  </div>
                  <div className="" style={{display: 'inline-block', float: 'left', width: 'calc(100% - 80px)', maxWidth: '550px'}}>
                    <h2 style={{maxWidth: '100%', marginTop: '18px'}}>The best affiliate link platform, period.</h2>
                  </div>
                </div>
              </div>
              <p style={{fontSize: '22px', color: '#6b7c93', maxWidth: '100%', margin: '25px 0 0', textAlign: 'center'}}>Weave gives you affiliate links for the products you feature. Share these links anywhere on the web and make money when your fans click and buy.
              Our tools are completely free and easy to use.</p>
            </div>
          </div>
        </div>


        <div className="section" style={{overflow: 'hidden'}}>
          <div className="container">
            <h2 style={{textAlign: 'center', marginBottom: '50px', color: '#2a95ff'}}>Solutions for every type of creator.</h2>
            <div className="row categories-choose">
              <div className="categories-choose-outer">
                <div className="categories-choose-inner">
                  <div className={this.state.category == 'fashion' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'fashion')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/fashionbeautyhomeicon-red.svg" />
                    <p>Fashion</p>
                  </div>
                  <div className={this.state.category == 'gaming' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'gaming')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/gaminghome-icon.svg" />
                    <p>Gaming</p>
                  </div>
                  <div className={this.state.category == 'fitness' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'fitness')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/fitnesshome-icon.svg" />
                    <p>Fitness</p>
                  </div>
                  <div className={this.state.category == 'product_reviews' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'product_reviews')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/productreviewhome-icon.svg" />
                    <p>Product Reviews</p>
                  </div>
                  <div className={this.state.category == 'education' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'education')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/fashionbeautyhomeicon-green.svg" />
                    <p>Software</p>
                  </div>
                  <div className={this.state.category == 'diy' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'diy')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/diyhome-icon.svg" />
                    <p>DIY</p>
                  </div>
                  <div className={this.state.category == 'other' ? `category-select ${this.state.category} active` : `category-select ${this.state.category}`} onClick={this.changeCategory.bind(this, 'other')}>
                    <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/somuchmorehomeicon.svg" />
                    <p>So Much More</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row category-sections" style={{paddingTop: '50px'}}>
              <div className="col-sm-6 feature-text">
                <div className={this.state.category == 'fashion' ? '' : 'hidden'}>
                  <h3>Hello beautiful!</h3>
                  <p>Whether you’re posting your next makeup tutorial, GRWM, shopping haul or fashion/beauty blog post, Weave has you covered. Our fashion and beauty partners range from iconic retailers to the hottest new brands.</p>
                </div>
                <div className={this.state.category == 'gaming' ? '' : 'hidden'}>
                  <h3><span>Link</span> <span><img style={{width: '70px'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/link_8bit.png" /></span> <span>away.</span></h3>
                  <p>For all you gamers out there, promoting games and apps has never been easier. Weave plugs in to top gaming retailers like GameStop and tracks downloads and purchases to the Apple App Store.</p>
                </div>
                <div className={this.state.category == 'fitness' ? '' : 'hidden'}>
                  <h3>{'Do you even link?'}</h3>
                  <p>All athletes welcome! From fitness classes to supplements to workout gear, we’ve got you covered. Now you can let your fans know exactly how to enhance their sporting and fitness performance.</p>
                </div>
                <div className={this.state.category == 'product_reviews' ? '' : 'hidden'}>
                  <h3>Review it. Buy it. Done. If it’s cool...</h3>
                  <p>Weave has products for virtually every category. Whether you’re reviewing new gadgets, today’s hot products, web services or more, you can get links for these miscellaneous items directly within your dashboard.</p>
                </div>
                <div className={this.state.category == 'education' ? '' : 'hidden'}>
                  <h3>Just getting edumacated.</h3>
                  <p>Weave partners with the top educational and software websites so that you can promote thousands of products and courses in your social content and blogs! Earn commissions from driving purchases to monthly subscriptions, one time class packages and more.</p>
                </div>
                <div className={this.state.category == 'diy' ? '' : 'hidden'}>
                  <h3>Ride or D.I.Y.</h3>
                  <p>People love to watch you build - make it easy for your fans to build too.</p>
                </div>
                <div className={this.state.category == 'other' ? '' : 'hidden'}>
                  <h3>{"S’more. S’more of what?"}</h3>
                  <p>From Cooking to Travel to Pets to Gifts, Weave covers a large network of categories not listed above. We have a goal to get our creators access to all the affiliate platforms in the world, and our relationship with tens of thousands of retailers allow us to provide links that span nearly every industry.</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className={this.state.category == 'fashion' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/fashionhomecards.svg" />
                </div>
                <div className={this.state.category == 'gaming' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/gamerhomecardicons.svg" />
                </div>
                <div className={this.state.category == 'fitness' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/sportsandfitnesshomecards.svg" />
                </div>
                <div className={this.state.category == 'product_reviews' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/productreviewhomecards.svg" />
                </div>
                <div className={this.state.category == 'education' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/softwareandeducationhomecards.svg" />
                </div>
                <div className={this.state.category == 'diy' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/craftsanddiyhomecards.svg" />
                </div>
                <div className={this.state.category == 'other' ? '' : 'hidden'}>
                  <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/categories/somuchmorehomecards.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="section sliver" style={{position: 'relative'}}>
          <div className="container" style={{padding: 0}}>
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 feature-text">
                <h2>Instant access to thousands of brands.</h2>
                <p>Managing multiple affiliate programs sucks. Weave sets up relationships with brands and works to negotiate higher rates so you don't have to.</p>
              </div>
              <div className="col-sm-6 col-sm-pull-6 retailer-icons">
                <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/retailer-cards.svg" />
              </div>
            </div>
          </div>

          <div className="container earnings-container">
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Sign up and get paid.</h2>
                <p>It's that simple.</p>
              </div>
              <div className="col-sm-6 icon-display">
                <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/cashouticonhome.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="section" style={{position: 'relative'}}>
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-sm-push-6 feature-text">
                <h2>Get links in seconds, not hours.</h2>
                <p>Quickly search from Weave's database of millions of products, or automatically convert product names or urls to money earning Weave links quicker than you can say banana split.</p>
              </div>
              <div className="col-sm-6 col-sm-pull-6 icon-display">
                <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/searchandautoconverticonhomecard.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 feature-text">
                <h2>Learn more about how you’re performing.</h2>
                <p>Weave’s comprehensive dashboard gives you the insights you need to know how specific posts, products or affiliate programs are performing for you.</p>
              </div>
              <div className="col-sm-6 icon-display">
                <img style={{width: '100%'}} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/analytics-icon.svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="section" style={{overflow: 'hidden', paddingBottom: 0}}>
          <div className="shard-1"></div>
          <div className="shard-2"></div>

          <div className="container split-features">
            <div className="col-sm-6 split-left">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/codehomelogo-blue.svg" alt="Weave Logo" />
              </div>
              <h3>ALWAYS DEVELOPING</h3>
              <p>Weave is an ever-growing platform that constantly develops new features. Our goal is to build technology for the modern day creator that helps them truly improve performance and earn more from their content. We know that you work hard to create posts that add value to people’s lives, and we want Weave to add as much value as possible to yours.</p>
            </div>
            <div className="col-sm-6 split-right">
              <div className="split-img">
                <img className="logo-hero" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/landing/home/highfivehomeicon-blue.svg" alt="Weave Logo" />
              </div>
              <h3>CREATOR FOCUSED</h3>
              <p>We initially started Weave to help creators monetize their YouTube videos. We saw that other companies were offensively exclusive, charged outrageous fees on earnings and never built tools to actually help the performance of their users. By offering the simplest, yet most advanced platform on the internet and connecting you to thousands of retailers, we know that we can change the way you earn from your content.</p>
            </div>
          </div>

          <div className="container final-get-started">
            <h2 style={{marginBottom: '25px', color: 'white'}}>{'Ready to join?'}</h2>
            <Link to="/signup"><button className="get-started">Get Started</button></Link>
          </div>

          <footer className="footer">
            <div className="container">
              <div className="logo-container footer-left">
                <img className="logo" src={Globals.icon('logo-text', 'white')} alt="Weave Logo" />
              </div>
              <div className="footer-right">
                <a href="https://medium.com/weavesocial" target="_blank">Blog</a>
                <a href="mailto:talent@weavesocial.com?Subject=Hello" target="_top">Contact Us</a>
                <a href="/terms-of-service" target="_blank">Terms of Use</a>
                <a href="/privacy" target="_blank">Privacy Policy</a>
                <a>Weave, Inc © 2022</a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
