import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../global-functions.js';

export default class ContentTile extends React.Component {
  constructor(props){
    super(props)
  }

  componentWillUpdate(){

  }

  componentDidMount(){

  }

  componentWillUnmount(){

  }

  render() {

    var images = {
      youtube: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/video.svg',
      instagram: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/photos.svg',
      facebook: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/facebooklike.svg',
      snapchat: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/snapchat.svg',
      twitter: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/twitter.svg',
      pinterest: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/pin.svg',
      blog: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/blogpost.svg',
      other: 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/questionmark.svg'
    }

    var content = this.props.content
    let platform = content.platform && content.platform != '' ? content.platform : 'other'
    var weaveViews = content.currentViews ? Math.floor(content.currentViews - (!isNaN(content.publishedViews) ? content.publishedViews : 0)) : 0
  	var status = <div className={`status status-live ${platform}`}>{`${platform} • ${Globals.calculateTime(content.publishedAt)}`}</div>
  	var stats = ''
  	var image = ( <img src={images[platform]} alt="Video Thumbnail" /> )

    if (content.platform == 'youtube'){
  		stats = (
        <div>
          <div className="col-xs-4 stat-views-container">
      			<img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/eye_icon.svg" alt="Views Icon" />
      			&nbsp;
      			<span className="video-stat video-stat-views">{ (content.currentViews ? Globals.convertNumberFormat(content.currentViews) : '0') }</span>
      		</div>
      		<div className="col-xs-4">
      			<img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/click.svg" alt="Clicks Icon" />
      			&nbsp;
      			<span className="video-stat video-stat-clicks">{ ( content.clicks ? Globals.convertNumberFormat(content.clicks) : '0' ) }</span>
      		</div>
      		<div className="col-xs-4">
      			<img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/ctr_icon.svg" alt="Conversion Icon" />
      			&nbsp;
      			<span className="video-stat video-stat-conversion">{ ( content.clicks && weaveViews && weaveViews > 0 ? ((content.clicks/weaveViews)*100).toFixed(2) +'%' : '0.00%' ) }</span>
      		</div>
        </div>
      )

  		image = <img src={"//img.youtube.com/vi/"+content.post_id+"/mqdefault.jpg"} alt="Video Thumbnail" />
  	}
  	else {
  		stats = (
        <div>
          <div className="col-xs-6">
      			<img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/link-icon-gray.svg" alt="Clicks Icon" />
      			&nbsp;
      			<span className="video-stat video-stat-clicks">{ ( content.links ? Globals.convertNumberFormat(content.links) : '0' ) }</span>
      		</div>
          <div className="col-xs-6">
      			<img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/click.svg" alt="Clicks Icon" />
      			&nbsp;
      			<span className="video-stat video-stat-clicks">{ ( content.clicks ? Globals.convertNumberFormat(content.clicks) : '0' ) }</span>
      		</div>
        </div>
      )
  	}
    return (
      <div>
        <div key={content.id} id={"video-"+content.content_id} data-id={content.content_id} className={`col-xs-6 ${this.props.size} video-container`}>
          <Link to={"/links/"+content.content_id}>
            <div className="video">
              {status}
              <div className="video-img">
                {image}
              </div>
              <div className="video-bottom">
                <div><span className="title">{content.title && content.title.length > 0 ? content.title : ''}</span></div>
                <div className="row stat-container">
                  {stats}
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
