import React from 'react'
import Globals from '../../global-functions.js'

export default class DataAdmin extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {


    var html = (<></>)
    return (
      <>
        <div className="row row0">
          <h3>Data Admin</h3>
        </div>
        <div className="row row0">
          {html}
        </div>
      </>
    )
  }
}
