import React, { useState } from 'react';
import './invite-block.scss';
import { Link } from 'react-router-dom';
import Globals from '../../../global-functions';

export interface InviteBlockProps {
  children?: any;
  title: string;
  subTitleText?: string;
  // icon?: string;
  isSaved?: boolean;
  actionContent?: JSX.Element;
  linkText?: string;
  linkTo?: string | JSX.Element;
  icon?: string;
  noMargin?: boolean;
}



export const InviteBlock: React.FC<InviteBlockProps> = ({
                                                          children,
                                                          title,
                                                          subTitleText,
                                                          isSaved,
                                                          linkTo,
                                                          actionContent,
                                                          icon,
                                                          noMargin,
                                                          linkText= 'View'}) => {
  const [collapsed, setCollapsed] = useState(true);
  const fullLinkTo = `./overview/${linkTo}`;

  return (
    <div className="invite-block">
      <Link to={fullLinkTo}>
      <div className="header-row" style={noMargin ? { marginBottom: 0 } : {}}>

        {/*<div className="saved column">*/}
        {/*  { isSaved?*/}
        {/*    <div className="check green"><img src={Globals.icon('ok', 'white')}/></div>:*/}
        {/*    icon && <img className='icon' src={icon} />*/}
        {/*  }*/}
        {/*</div>*/}


        <div className="header column" style={{alignItems: 'initial'}}>
          <div className="row row0">
            <h4>
              <div className="invite-block-icon">
                <img className="invite-block-img" src={icon} />
              </div>
              <span> {title}</span>
            </h4>
          </div>
          { subTitleText &&
            <div className="row row0" style={{marginTop: '20px'}}>
              <span className="sub-text">{subTitleText}</span>
            </div>
          }
        </div>

        <div className="action column">
          {/*<button className="invite-btn">
            { linkText }
          </button>*/}
        </div>
      </div>
      { children }
      <img className="action-arrow" src={Globals.icon('arrow-right-1')} />

      </Link>
    </div>


  );
};
