import React from 'react';
import LinkModule from './builder/link-module.jsx';
import SearchResult from './builder/search-result.jsx';
import Globals from '../global-functions.js';
import Platform from './builder/platform.jsx';
import Loader from './loader.jsx';
import SearchAgain from './builder/search-again.jsx';
import CouponPopup from './builder/coupon-popup.jsx';
import ProductImagesPopup from './builder/product-images-popup.jsx';
import ShowMoreVideos from './builder/show-more-videos.jsx';
import Cookies from 'js-cookie';

var labelUpdater = 0, timeout_count = 0

var messages = [
  `Rummaging through Kim Kardashian's closet...`,
  `Machine Learning...Learning Machine...Lachine Mearning`,
  `Hopefully this works...`,
  `🐒 300 monkeys are testing all the links for you.`,
  `🌜 Travelling to the moon and back, please wait...`,
  `Initiating warp speed...`,
  `Exploring the entire web, like, the whole thing.`,
  `Wow, I cannot believe how fast this is!`,
  `Plugging into the Matrix...`,
  `I am using the Force!`,
  `⭐️⭐️⭐️⭐️  STAR POWER  ⭐️⭐️⭐️⭐️`,
  `You want links? How many? Ok, let me get them.`,
  `This is going to take really long...just kidding 😝`,
  `You're welcome for making this easy 😉`,
  `Racing the Flash ⚡️...and winning`,
  `🚀 Ready for takeoff...`,
  `Getting you links before they go out of style.`,
  `I give you the links, you make the money 🤑`,
  `Respect 🙏`,
  `Nice choice!! 👏`,
  `👨‍🚀 Be back in a sec...`,
  `🕵️ I'll find what you need`,
  `👩‍🍳 Let me cook something up for you!`,
  `🎅 Santa Clause, at your service`,
  `Make it chocolate rain`,
  `Ready, set, link!`,
  `Doing some work...`,
  `We expect you to make some money...`,
  `Making this link useful...`
]

export default class Builder extends React.Component {

  constructor(props){
    super(props)
    // var data = JSON.parse(unescape($('#data-container').data('data')))
    // var data = {}
    // console.log(data.content_id)
    this.state = {
      mounted: false,
      links: [],
      searches: [],
      post_content: null,
      content_id: null,
      post_id: null,
      platform: null,
      userid: Cookies.get('userid'),
      user: Globals.getUser(),
      searching: false,
      label: '',
      title: '',
      loaded: false,
      description: '',
      video: false,
      searchActive: false,
      domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me',
      searchAgain: null,
      couponActive: null,
      productImagesActive: null,
      showMoreVideos: null
    }
  }

  content(){
    return {
      userid: this.state.userid,
      content_id: this.state.content_id,
      post_id: this.state.post_id,
      platform: this.state.platform,
      label: this.state.label,
      // links: this.state.links,
      products: this.state.products
    }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   return true
  // }
  //
  componentWillUpdate(){

  }

  componentDidMount() {
    Globals.pageView(window.location.pathname + window.location.search)
    if (!this.state.user.internal && Globals.isProd){ window.Intercom("update"); }
    this.setState({ mounted: true })

    this.loadContent().done((data) => {
      if (this.state.mounted){
        this.setState({
          content_id: data.content_id,
          links: data.links ? data.links : [],
          platform: data.platform,
          post_id: data.post_id,
          post_content: data.post_content ? data.post_content : false,
          video: data.post_content ? data.post_content : false,
          products: data.products,
          title: data.title,
          label: data.title,
          loaded: true
        })
      }
    })
  }

  componentWillUnmount(){
    this.setState({ mounted: false })
  }

  loadContent(){
    let id = location.href.indexOf('/links/') > -1 ? location.href.split('/links/')[1] : false
    return $.ajax({
      method: 'POST',
      url: '/api/Content/load5',
      data: { id: id }
    })
  }

  manualSearch(){
    Globals.logEvent('event', 'search')
    timeout_count++
    var value = $('.manual-search').val()
    var check = Number(timeout_count)
    setTimeout(() => {
      if (check == timeout_count && value.length > 2){
        this.setState({
          searches: []
        })
        this.search(value)
      }
    }, 1000)
  }

  validateTextLength(){
    var textarea = $('.description-content').val()
    $('.description-ct').text(textarea.length)
    if (textarea.length > 4999){
      $('.description-length').addClass('too-long')
      $('#post-youtube').text('description too long').attr('disabled', true)
    }
    else if ($('.description-length').hasClass('too-long')) {
      $('.description-length').removeClass('too-long')
      $('#post-youtube').text('update youtube description').attr('disabled', false)
    }
  }

  textAreaAdjust(element){
    $(element).css('height', '1px')
    $(element).css('height', (25+$(element).prop('scrollHeight'))+'px');
  }

  clickConvert(){
    Globals.logEvent('click', 'convertLinks')
    var queries = this.getQueries($('.search').val())
    $('.search').val('')
    if (queries){
      this.setState({
        loading: true
      })
      this.convertAPI(queries).then((response) => {
        var templinks = response.response
        var newlinks = []
        templinks.forEach((link, i) => {
          let exists = false
          this.state.links.forEach((l, j) => {
            if (link.hash == l.hash) { exists = true }
          })
          if (!exists) { newlinks.push(link) }
        })
        this.state.links = this.state.links.concat(newlinks)
        this.setState({
          links: this.state.links,
          loading: false
        })
        if (!this.state.initiated) { this.chooseSocial(this.state.platform) }
      })
    }
  }

  getQueries(text){
    var lines = text.split('\n'), bail = false
    var filtered_lines = [], queries = []
    lines.forEach((line, i) => {
      if (!line.replace(/\s/g, '').length) { }
      else if (line.length <= 8){ bail = true; alert('Please be more descriptive with product names to convert (more than 8 characters)') }
      else if (line.indexOf(this.state.domain) > -1){ bail = true; alert("You're doing this wrong...you don't need to convert a link that is already Weave") }
      else { filtered_lines.push(line) }
    })
    filtered_lines.forEach((line, i) => {
      var strs = line.split(' '), q = {}
      strs.forEach((str, j) => {
        var tmp = str.split('?')[0]
        if (Globals.isURL(tmp)){ q.hasUrl = true; q.url = str; }
        else if (str.length > 0){ q.hasWord = true; }
      })
      if (q.hasUrl && q.hasWord) { q.words = line.replace(q.url, ''); q.both = true; }
      else { q.words = line }
      queries.push(q)
    })

    return bail ? false : queries
  }

  addLink(){
    this.state.links.push('one')
    this.setState({
      links: this.state.links
    })
  }

  search(value){
    this.setState({
      loading: true
    })
    this.searchAPI(value).then((response) => {
      this.state.searches = this.state.searches.concat(response.response)
      this.setState({
        searches: this.state.searches,
        loading: false
      })
    })
  }

  convertAPI(queries){
    return $.ajax({
      method: 'POST',
      url: '/api/Search/convert',
      data: {userid: this.state.userid, immediate: true, values: queries, content_id: this.state.content_id, platform: this.state.platform }
    })
  }

  searchAPI(value, _this){
    let that = this
    if (_this) { that = _this }

    return $.ajax({
      method: 'POST',
      url: '/api/Search/search',
      data: {value: value, user: that.state.user, immediate: false, video_id: that.state.content_id, platform: that.state.platform, search: 'search'}
    })
  }

  addProductAPI(product){
    product.content_id = this.state.content_id
    product.post_id = this.state.post_id
    product.platform = this.state.platform
    return $.ajax({
      method: 'POST',
      url: '/api/Link/makeLink',
      data: { user: Globals.getUser(), product: product }
    })
  }

  getVideoMeta(video_id){
    return $.ajax({
      method: 'POST',
      url: '/api/Bookmarklet/getVideoMeta',
      // data: {value: value, userid: userid, aff: userdata.aff, hash: userdata.hash, customAffiliate: userdata.customAffiliate}
      data: { userid: userid, video_id: video_id }
    })
  }

  toggleManualSearch(){
    if ( !$('.search-container').hasClass('search-active') ){
      $('.search-container').addClass('search-active')
      $('.search-dropdown').addClass('active')
      this.setState({
        searchActive: true
      })
    }
    else {
      $('.search-container').removeClass('search-active')
      $('.search-dropdown').removeClass('active')
      this.setState({
        searchActive: false
      })
    }
  }

  searchClicked(product){
    this.setState({ searches: [] })
    this.addProductAPI(product).done((p) => {
      let exists = false
      this.state.links.forEach((link, i) => {
        if (link.hash == p){ exists = true }
      })
      if (!exists) {
        product.hash = p
        this.state.links.push(product)
        this.setState({
          links: this.state.links
        })
      }
      this.toggleManualSearch()
      if (!this.state.initiated) { this.chooseSocial(this.state.platform) }
    })
  }

  chooseSocial(platform){
    var content = this.content()
    content.platform = platform
    if (content.initiated || this.state.links.length > 0){
      content.initiated = true
      content.action = 'updateContent'
      content.products = null

      if (platform == 'youtube' && this.state.post_content){
        this.chooseVideo(this.state.post_content)
      }
    }

    this.setState({
      platform: platform,
      initiated: content.initiated
    })
  }

  editLabel(){
    let label = $('#label-input').val()
    labelUpdater++
    var check = Number(labelUpdater)
    this.setState({
      label: label
    })
    setTimeout(() => {
      if (check == labelUpdater){
        this.updateLabel(label).done((success) => {
          $('.header-hr hr.saved').addClass('active')
          setTimeout(() => {
            $('.header-hr hr.saved').removeClass('active')
          }, 1000)
        })
      }
    }, 1000)
  }

  updateLabel(label){
    var content = this.content()
    content.action = 'updateContent'
    content.title = label
    content.label = label
    content.products = null
  }

  editLinkLabel(link){
    let label = $(`#link-label-${link.hash}`).val()
    labelUpdater++
    var check = Number(labelUpdater)

    setTimeout(() => {
      if (check == labelUpdater){
        this.state.links.forEach((l, i) => {
          if (link.hash == l.hash){
            l.givenName = label
          }
        })
        this.setState({
          links: this.state.links
        })
        this.updateLinkLabel(link, label).done((success) => {
          $(`.${link.hash} hr.saved`).addClass('active')
          setTimeout(() => {
            $(`.${link.hash} hr.saved`).removeClass('active')
          }, 1000)
        })
      }
    }, 1000)
  }

  updateLinkLabel(link, label){
    return $.ajax({
      method: 'POST',
      url: '/api/Product/updateProductName',
      data: {hash: link.hash, givenName: label}
    })
  }

  getVideoMeta(video_id){
    return $.ajax({
      method: 'POST',
      url: '/api/YouTube/getVideoMeta',
      data: {userid: this.state.userid, video_id: video_id}
    })
  }

  chooseVideo(video){
    // console.log(video)
    this.getVideoMeta(video.id).done((video_meta) => {
      // console.log(video_meta)
      video = video_meta
      this.setState({
        post_id: video.id,
        post_content: video,
        description: video.snippet.description,
        video: video
      })

      let content = this.content()
      content.action = 'updateContent'
      content.post_id = video.id
      content.post_content = video,
      content.title = video.snippet.title,
      content.description = video.snippet.description,
      content.video = video
      content.platform = 'youtube'
      content.products = null
    })
  }

  // loadVideoMeta(video){
  //   return $.ajax({
  //     method: 'POST',
  //     url: '/api/Bookmarklet/getVideoMeta',
  //     data: { userid: this.state.userid, video_id: video_id }
  //   })
  // }

  copyAll(){
    if (this.state.links.length > 0){
      $('#copy-paste').val('')
      let text = ''
      this.state.links.forEach((link, i) => {
        text += ( (link.givenName && link.givenName.length > 0) ? link.givenName+' ' : (link.title && !Globals.isURL(link.title) ? link.title+' ' : (link.name && !Globals.isURL(link.name) ? link.name+' ' : '')) ) + `http://${this.state.domain}/~${link.hash}\n`
      })

      $('#copy-paste').val(text).select()
      document.execCommand('copy')
      $('#copy-all').text('Copied!')
      setTimeout(() => { $('#copy-all').text('Copy All') }, 1500)
    }
    else {
      $('#copy-all').text('No Links to Copy!')
      setTimeout(() => { $('#copy-all').text('Copy All') }, 1500)
    }
  }

  handleTextarea(active){
    this.setState({
      linkTextarea: active
    })
    if ( active && !$('.search').hasClass('active') ){ $('.search').addClass('active') }
    else if (!active && $('.search').val().length == 0){ $('.search').removeClass('active') }
  }

  updateDescription(){
    $('#update-yt-description').text('Updating...')
    var complete = true, error_links = []

    this.state.links.forEach((link, i) => { if (!link.givenName || link.givenName == '') { complete = false } })

    if (!complete){
      var text = $('#youtube-description').val().replace(/\n/g, ' ').split(' ')
      var filtered_text = []
      text.forEach((t, i) => { if (t.length > 0){ filtered_text.push(t) } })
      complete = true
      filtered_text.forEach((t, i) => {
        if ( t.indexOf(this.state.domain) > -1 && (filtered_text[i-1] && filtered_text[i-1].indexOf(`http://${this.state.domain}`) > -1) ) {
          complete = false
          if (!error_links.includes(t)) { error_links.push(t) }
        }
        else if ( t.indexOf(`http://${this.state.domain}`) > -1 && (filtered_text[i+1] && filtered_text[i+1].indexOf(this.state.domain) > -1) ){
          complete = false
          if (!error_links.includes(filtered_text[i+1])) { error_links.push(filtered_text[i+1]) }
        }
      })
    }

    if (!complete){
      var error_text = ''
      error_links.forEach((l, i) => { error_text += '\n' + l })
      complete = confirm('Proceed to update description? We detected that you might have link(s) in your description that are not labeled - please check these links: \n' + error_text)
    }

    if (complete){
      this.updateDescriptionAPI().done(function(success){
        if (success){
          $('#update-yt-description').text('Updated!')
          setTimeout(() => {
            $('#update-yt-description').text('Update')
          }, 2500)
        }
        else {
          $('#update-yt-description').text('Failed!')
          setTimeout(() => {
            $('#update-yt-description').text('Update')
          }, 2500)
        }
      })
    }
    else {
      $('#update-yt-description').text('Update')
    }

  }

  updateDescriptionAPI(){}

  searchAgain(link){
    this.setState({
      searchAgain: link
    })
  }

  searchAgainClicked(link, search){
    search.content_id = this.state.content_id
    search.post_id = this.state.post_id
    search.platform = this.state.platform
    search.hash = link.hash
    search.userid = this.state.userid
    search.user_hash = this.state.user.hash

    this.setState({
      searchAgain: null
    })

    this.updateSearchAgain(search).done((created) => {
      // console.log(created)
      if (created){
        var newlinks = []
        this.state.links.forEach((l, i) => {
          // console.log(l.hash, created.hash)
          if (l.hash == created.hash){
            newlinks.push(created)
          }
          else {
            newlinks.push(l)
          }
        })

        this.setState({
          links: newlinks
        })
      }
      else {
        alert('something went wrong')
      }
    })
  }

  updateSearchAgain(link){
    return $.ajax({
      method: 'POST',
      url: '/api/Product/updateSearchAgain',
      data: { userid: this.state.userid, link: link }
    })
  }

  closeSearchAgain(){
    this.setState({
      searchAgain: null
    })
  }

  archiveLinkAPI(link){
    return $.ajax({
      method: 'POST',
      url: '/api/Product/archiveLink',
      data: { userid: this.state.userid, hash: link.hash }
    })
  }

  archiveLink(link){
    this.archiveLinkAPI(link).done((success) => {
      if (success){
        let newlinks = []
        this.state.links.forEach((l, i) => {
          if (link.hash != l.hash){ newlinks.push(l) }
        })

        this.setState({
          links: newlinks
        })
      }
      else {
        alert('something went wrong')
      }
    })
  }

  getCoupon(link){
    return $.ajax({
      method: 'POST',
      url: '/api/Search/getCoupon',
      data: link
    })
  }

  showCoupon(link){
    $(`#link-coupon-${link.hash}`).text('GETTING...')
    this.getCoupon(link).done((coupons) => {
      $(`#link-coupon-${link.hash}`).text('GET COUPON')
      this.setState({
        coupons: coupons,
        couponActive: true
      })
    })
  }

  hideCoupon(){
    this.setState({
      coupons: false,
      couponActive: false
    })
  }

  updateProductImage(hash, url){
    return $.ajax({
      method: 'POST',
      url: '/api/Product/updateProductImage',
      data: {hash: hash, url: url}
    })
  }

  showProductImages(link){
    // $(`#link-coupon-${link.hash}`).text('GETTING...')
    this.setState({
      productImages: false,
      productImagesActive: true,
      productImagesLink: link
    })
  }

  hideProductImages(){
    this.setState({
      productImages: false,
      productImagesActive: false,
      productImagesLink: false
    })
  }

  chooseProductImage(image){
    this.updateProductImage(this.state.productImagesLink.hash, image.src).done((success) => {
      var links = this.state.links
      links.forEach((l, i) => {
        if (l.hash == this.state.productImagesLink.hash) { l.imageUrl = image.src }
      })
      this.setState({
        productImages: false,
        productImagesActive: false,
        productImagesLink: false,
        links: links
      })
    })
  }

  showMoreVideos(){
    this.setState({
      showMoreVideosActive: true
    })
  }

  hideMoreVideos(){
    this.setState({
      showMoreVideosActive: false
    })
  }

  // textLinks(){
  //   var that = this
  //   var number = $('.send-text-input').val()
  //   number = '+1' + number.replace('+', '').replace('(', '').replace(')', '').replace('-', '').replace(/\s/g, '')
  //   $('.text-link-btn').text('Texting...')
  //
  //   $.ajax({
  //     method: 'POST',
  //     url: '/api/Phone/textLinks',
  //     data: { userid: userid, number: number, platform: that.content.platform, links: $('.links-in-video').val().replace(/\n/g, '\n\n') }
  //   })
  //   .done(function(data){
  //     if (data){
  //       $('.text-link-btn').addClass('published').text('Text Sent!')
  //       setTimeout(function(){ $('.text-link-btn').removeClass('published').text('Text Links') }, 2000)
  //     }
  //     else {
  //       $('.send-text').text('Wrong Number?')
  //       setTimeout(function(){ $('.text-link-btn').text('Text Links') }, 2000)
  //     }
  //   })
  // }

  // validatePhoneNumber(){
  //   var text = $('.send-text-input').val().replace(/ /g, '').replace(/\n/g, '')
  //   var length = 0, number = '', new_num = ''
  //   for (var i = 0 ; i < text.length ; i++){ if ( !isNaN(text[i]) && length < 10 ){ length++; number += text[i]; } }
  //   if (length == 0){ $('.send-text-input').val(''); return }
  //   else if (length == 1 || length == 2){ new_num = number }
  //   else if (length == 3){ new_num = '(' + number + ') ' }
  //   else if (length == 4){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 1) }
  //   else if (length == 5){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 2) }
  //   else if (length == 6){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + ' - ' }
  //   else if (length == 7){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + ' - ' + number.substr(6, 1) }
  //   else if (length == 8){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + ' - ' + number.substr(6, 2) }
  //   else if (length == 9){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + ' - ' + number.substr(6, 3) }
  //   else if (length == 10){ new_num = '(' + number.substr(0, 3) + ') ' + number.substr(3, 3) + ' - ' + number.substr(6, 4) }
  //   $('.send-text-input').val(new_num)
  // }


// if (userdata.phone_number){ $('.send-text-input').val(userdata.phone_number.replace('+1', '')); that.validatePhoneNumber() }




  render() {

    if (!this.state.loaded){
      return (
        <Loader active="active" />
      )
    }
    else {

      let searchAgain = ''
      if (this.state.searchAgain){
        searchAgain = (
          <SearchAgain
            link={this.state.searchAgain}
            searchAPI={this.searchAPI}
            searchAgainClicked={this.searchAgainClicked}
            closeSearchAgain={this.closeSearchAgain}
            this={this}
          />
        )
      }

      let couponPopup = ''
      if (this.state.couponActive){
        couponPopup = (
          <CouponPopup
            coupons={this.state.coupons}
            hideCoupon={this.hideCoupon}
            this={this}
          />
        )
      }

      let productImagesPopup = ''
      if (this.state.productImagesActive){
        productImagesPopup = (
          <ProductImagesPopup
            images={this.state.productImages}
            hideProductImages={this.hideProductImages}
            chooseProductImage={this.chooseProductImage}
            this={this}
          />
        )
      }

      let showMoreVideos = ''
      if (this.state.showMoreVideosActive){
        showMoreVideos = (
          <ShowMoreVideos
            videos={this.state.videos}
            hideMoreVideos={this.hideProductImages}
            chooseProductImage={this.chooseProductImage}
            this={this}
          />
        )
      }

      return (
        <div>
          <div className="col-md-6">
            <div className="section section-1" style={ {padding: 0, margin: 0, overflow: 'initial'} }>
              <div className="row" style={ {maxWidth: '625px'}, {margin: 0} }>
                <div className="col-md-12 search-module" style={ {margin: 0, padding: 0} }>
                  <div className="search-container search-links">
                    <div className="row convert-links-header">
                      <div className="col-xs-6" style={{padding: 0}}>
                        <h4>Add Names or URLs</h4>
                      </div>
                      <div className="col-xs-6" style={{ textAlign: 'right', padding: 0, margin: 0 }}>
                        <img className="search-icon border" onClick={this.toggleManualSearch.bind(this)} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/search-white.svg" />
                        { /* <img className="icon-img" src="/images/temp-icons/search.svg" /> */ }
                        <button className="search-box-btn link-convert-btn" style={{color: 'white !important'}} onClick={this.clickConvert.bind(this)}>{this.state.loading && !this.state.searchActive ? 'Converting...' : 'Convert'}</button>
                      </div>
                      <div className="search-overlay">
                        <img className="search-icon" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/search-blue.svg" />
                        <input className="manual-search dark-input" placeholder="search for a product..." onChange={this.manualSearch.bind(this)} />
                        <img onClick={this.toggleManualSearch.bind(this)} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/xmarkplain_black.svg" style={{position: 'absolute', top: '32px', right: '32px', left: 'initial', width: '16px', cursor: 'pointer', opacity: '0.55'}} />
                      </div>
                    </div>
                    <div className={`row ${this.state.searchActive ? 'hidden' : ''}`} style={{position: 'relative', margin: 0, minHeight: '150px'}}>
                      <Loader active={!!(this.state.loading && !this.state.searchActive)} />
                      <p className={this.state.loading ? "placeholder" : "placeholder hidden"} style={{top: '40px'}}>{messages[Math.floor(Math.random() * messages.length)]}</p>

                      <textarea className={this.state.loading ? "search-box search hidden" : "search-box search"} onFocus={this.handleTextarea.bind(this, true)} onBlur={this.handleTextarea.bind(this, false)} />
                      <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }} className={this.state.loading || this.state.linkTextarea ? 'hidden' : ''}>
                        <p style={{textAlign: 'left', margin: '20px 0px -15px 25px'}}>name or link HERE (if typing a name be as descriptive as possible)</p>
                        <div className="col-xs-4 search-example-container">
                          <div className="search-example">
                            <div className="example-img">
                              <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/singleproductname.svg" />
                            </div>
                            <p>type a product name</p>
                          </div>
                        </div>
                        <div className="col-xs-4 search-example-container">
                          <div className="search-example">
                            <div className="example-img">
                              <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/wwwurl.svg" />
                            </div>
                            <p>use a retailer url</p>
                          </div>
                        </div>
                        <div className="col-xs-4 search-example-container">
                          <div className="search-example">
                            <div className="example-img">
                              <img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/productlist.svg" />
                            </div>
                            <p>paste in a list of both</p>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="search-dropdown">
                      <p className={(this.state.loading || this.state.searches.length > 0) ? "placeholder hidden" : "placeholder" }>Search for a product above to start the magic!</p>
                      <p className={(this.state.loading && this.state.searchActive) ? "placeholder" : "placeholder hidden" }>{ messages[Math.floor(Math.random() * messages.length) ] }</p>
                      <Loader active={this.state.loading && this.state.searchActive ? true : false} />
                      <div className="search-ul-container">
                        <ul>
                          {
                            this.state.searches.map((search) => {
                              return (
                                <SearchResult key={Math.floor(Math.random() * 9999999)} search={search} onClick={this.searchClicked} this={this}></SearchResult>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12" style={{ margin: 0, padding: 0, marginTop: '15px' }}>
                <div className="search-container live-links">
                  <div className="row list-links-header" style={{margin: 0, borderBottom: '1px solid #eaeaea'}}>
                    <div className="col-xs-4">
                      <h4 style={ {margin: 0} }>Links ({this.state.links.length})</h4>
                    </div>
                    <div className="col-xs-8" style={{ padding: 0 }}>
                      <button id='copy-all' onClick={this.copyAll.bind(this)}>Copy All</button>
                      {/* <button>Text To Phone</button> */}
                    </div>
                  </div>
                  <div className="list-links-container" style={{position: 'relative', width: '100%', height: '100%', minHeight: '180px', overflow: 'auto'}}>
                    <p className={(this.state.links.length > 0) ? "placeholder hidden" : "placeholder" }>No links currently - create links above!</p>
                    <ul className="list-links">
                      {
                        this.state.links.map((link) => {
                          return (
                            <LinkModule
                              key={Math.floor(Math.random() * 9999999)}
                              link={link}
                              editLinkLabel={this.editLinkLabel}
                              this={this}
                              searchAgain={this.searchAgain}
                              archiveLink={this.archiveLink}
                              showCoupon={this.showCoupon}
                              showProductImages={this.showProductImages}
                            />
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <Platform
              platform={this.state.platform}
              user={this.state.user}
              links={this.state.links}
              contentid={this.state.content_id}
              chooseSocial={this.chooseSocial}
              chooseVideo={this.chooseVideo}
              editLabel={this.editLabel}
              updateDescription={this.updateDescription}
              this={this}
              label={this.state.label}
              video={this.state.post_content}
              showMoreVideos={this.showMoreVideos}
            />
          </div>

          {showMoreVideos}
          {productImagesPopup}
          {couponPopup}
          {searchAgain}
          <textarea id="copy-paste" style={{ position: 'fixed', opacity: 0, left: '-10000px', top: '-10000px' }} className="copy-paste"></textarea>
        </div>
      )
    }
  }
}
