import { memo } from 'react';
import DynamicValueCopy from './dynamic-value-copy'
import Globals from '../../global-functions.js'
import Icon from '../icon'

const DynamicEmailValues = ((props) => {
  var { socialLabels, chosenPlatform, choosePlatform, copyValue, _this } = props

  return (
    <>
    <ul className="dynamic-fields">
      <li>
        <div className="row row0" style={{marginBottom: '12px'}}>
          <b>Lead Fields</b>
        </div>
      </li>
      <DynamicValueCopy title="Contact Name" type="name" label="contact" copyValue={copyValue} />
      <DynamicValueCopy title="Brand Name" type="name" label="brand" copyValue={copyValue} />
      <DynamicValueCopy title="Brand Website" type="domain" label="brand" copyValue={copyValue} />
      {/*<DynamicValueCopy title="Creator Name" type="name" label="contact" copyValue={copyValue.bind(this)} />*/}

      {/*<li>
        <div className="dark-input" onClick={copyValue.bind(this, 'contact-name')}>Contact Name</div>
        <input id="dynamic-value-contact-name" value={`{{ contact.name }}`} />
      </li>
      <li>
        <div className="dark-input" onClick={copyValue.bind(this, 'brand-name')}>Brand Name</div>
        <input id="dynamic-value-brand-name" value={`{{ brand.name }}`} />
      </li>
      <li>
        <div className="dark-input" onClick={copyValue.bind(this, 'brand-domain')}>Brand Website</div>
        <input id="dynamic-value-brand-domain" value={`{{ brand.domain }}`} />
      </li>
      <li>
        <div className="dark-input" onClick={copyValue.bind(this, 'creator-name')}>Creator Name</div>
        <input id="dynamic-value-creator-name" value={`{{ creator.name }}`} />
      </li>*/}
      <li>
        <div className="row row0" style={{marginBottom: '8px'}}>
          <hr />
          <div className="row row0" style={{marginBottom: '12px'}}>
            <b>Social Fields</b>
          </div>
          <div className="row" style={{margin: '0 -3px'}}>
            { socialLabels.map((s) => {
              var lc = s.toLowerCase()
              return (
                <div className="col-xs-2" style={{padding: '0 3px'}}>
                  <div className={`dark-input ${lc == chosenPlatform ? 'active' : ''}`} onClick={choosePlatform.bind(_this, lc)} style={{padding: '14px 10px'}}>
                    <Icon icon={lc} style={{position: 'absolute', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0, width: 'initial', height: '14px'}} />
                  </div>
                </div>
              )
            }) }
          </div>
        </div>
      </li>
      { socialLabels.map((s) => {
        var lc = s.toLowerCase()
        if (lc == chosenPlatform) {
          return (
            <>
              <DynamicValueCopy type="username" title={`${s} Username`} label={lc} icon={lc} copyValue={copyValue} />
              <DynamicValueCopy type="url" title={`${s} URL`} label={lc} icon={lc} copyValue={copyValue} />
              <DynamicValueCopy type="followers" title={`${s} Followers`} label={lc} icon={lc} copyValue={copyValue} />

              {/*<li>
                <div className="dark-input" onClick={copyValue.bind(this, `${lc}-username`)}>
                  <Icon icon={lc} style={{margin: '-2px 6px 0 0'}} />
                  <span>{s} Username</span>
                </div>
                <input id={`dynamic-value-${lc}-username`} value={`{{ ${lc}.username }}`} />
              </li>
              <li>
                <div className="dark-input" onClick={copyValue.bind(this, `${lc}-url`)}>
                  <Icon icon={lc} style={{margin: '-2px 6px 0 0'}} />
                  <span>{s} URL</span>
                </div>
                <input id={`dynamic-value-${lc}-url`} value={`{{ ${lc}.url }}`} />
              </li>
              <li>
                <div className="dark-input" onClick={copyValue.bind(this, `${lc}-followers`)}>
                  <Icon icon={lc} style={{margin: '-2px 6px 0 0'}} />
                  <span>{s} Followers</span>
                </div>
                <input id={`dynamic-value-${lc}-followers`} value={`{{ ${lc}.followers }}`} />
              </li>*/}
            </>
          )
        }
      }) }
    </ul>
    </>
  )
})

export default memo(DynamicEmailValues);
