import React, { useState } from 'react';
import './invite-guidelines.scss';
import SlideOutListItem from '../../slide-out/slide-out-list-item';
import { InvitePanel } from '../invite-panel/invite-panel';
import { InviteAsset } from '../invite-asset/invite-asset';
import Globals from '../../../global-functions.js';

export interface InviteOverviewProps {
  invitation?: any;
  campaign?: any;
  mode?: 'preview' | 'full';
}

const _generateCampaignLink = (params) => $.ajax({ method: 'POST', url: '/api/Link/generateCampaignLink', data: params });
const _generateGiftingDiscount = (params) => $.ajax({ method: 'POST', url: '/api/Campaign/generateGiftingDiscount', data: params });

export const InviteGuidelines: React.FC<InviteOverviewProps> = ({invitation, campaign, mode = 'full'}) => {

  const [showMore, setShowMore] = useState(false);
  const [generatedLink, setGeneratedLink] = useState(false);
  const [generatedLinkBtnTxt, setGeneratedLinkBtnTxt] = useState('Generate Promo Link');
  const [generatingLinkLoading, setGeneratingLinkLoading] = useState(false);
  const [generatedDiscount, setGeneratedDiscount] = useState(false);
  const [generatedDiscountBtnTxt, setGeneratedDiscountBtnTxt] = useState('Generate Gift Discount');
  const [generatingDiscountLoading, setGeneratingDiscountLoading] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);
  if (mode === 'preview') {
    // return <div className="sub-text">Please follow the brand's guidelines when creating your content.</div>;
    return <div></div>;
  }

  if (!campaign) {
    return <></>
  }

  const listDoExists = campaign.listDo[0]?.text?.length > 0;
  const listDontExists = campaign.listDont[0]?.text?.length > 0;
  const talkingDoExists = campaign.talkingDo[0]?.text?.length > 0;
  const mealDishesExists = campaign.mealDishes[0]?.text?.length > 0;
  const giftsExists = !!campaign.gifts[0];
  const content = campaign?.content ? campaign?.content : {}
  const offer = campaign?.offer ? campaign?.offer : {}

  // const copyValue = (type) => {
  //   var currentFocus = document.activeElement, target = document.getElementById(type), succeed
  //   target?.focus(); target?.setSelectionRange(0, target.value.length)
  //   try { succeed = document.execCommand("copy") } catch(e) { succeed = false }
  //   if (currentFocus && typeof currentFocus.focus === "function") { currentFocus?.focus(); }
  // }

  const generateCampaignLink = () => {
    if (generatedLink || generatingLinkLoading) { return; }
    setGeneratedLinkBtnTxt('Generating...')
    setGeneratingLinkLoading(true);
    _generateCampaignLink({ contentId: content.id })
      .catch(() => setGeneratingLinkLoading(false))
      .done((response: { url: string, hash: string}) => {
        console.log(response)
        setGeneratedLink(response);
        setGeneratedLinkBtnTxt(`http://weav.me/~${response?.hash}`)
        setGeneratingLinkLoading(false);
      });
  }

  const generateGiftingDiscount = () => {
    if (generatedDiscount || generatingDiscountLoading) { return; }
    setGeneratedDiscountBtnTxt('Generating...')
    setGeneratingDiscountLoading(true);
    _generateGiftingDiscount({ contentId: content.id, offerId: content.offerId })
      .catch(() => setGeneratingDiscountLoading(false))
      .done((response: { discount: object }) => {
        console.log(response)
        setGeneratedDiscount(response.discount);
        setGeneratedDiscountBtnTxt(response.discount.code)
        setGeneratingDiscountLoading(false);
      });
  }

  var links = generatedLink ? [generatedLink] : (content.links || []),
      discounts = generatedDiscount ? [generatedDiscount] : (content.discountCodes || [])

  var houseRules = [
    { text: 'Include #ad' },
    { text: 'You must own the rights to all content/music submitted' },
    { text: 'Do not use previously published content' },
    { text: 'Keep your post at the top of feed for 5 hrs and live for 30 days' },
    { text: 'Tag the brand and include #ad in each Stories frame' },
    { text: 'Always adhere to the Weave Codes of Conduct' }
  ]

  return <div className="invite-guidelines">
    <div style={{padding: '0 25px'}}>
      { !content?.orders[0] && offer.giftingType == 'discount' && ((!discounts || discounts.length == 0) && !generatedDiscount) &&
        <>
          <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[generatedDiscount?.code || generatedDiscountBtnTxt]} onclickFunc={ () => generatedDiscount ? Globals.copyValue('bounty-discount'): generateGiftingDiscount() } isBtn={generatedDiscount ? false : true} />
          <hr />
        </>
      }

      { !content?.orders[0] && offer.giftingType == 'discount' && (discounts && discounts.length > 0) &&
        discounts.map((discount) => {
          return (
            <>
              <InviteAsset title="Redeem Free Product" subtext="Checkout with this code from the brand's website" label="bounty-discount" values={[discount.code]} />
              <hr />
            </>
          )
        })
      }

      { links && links.length > 0 &&
        links.map((link) => {
          return (
            <>
              <InviteAsset title="Promo Link" subtext="Direct your audience to this link on your socials!" label="bounty-link" values={[`http://weav.me/~${link?.hash}`]} />
              <hr />
            </>
          );
        })}

        {!links || links.length == 0 &&
          <>
            <InviteAsset title="Promo Link" subtext="Direct your audience to this link on your socials!" label="bounty-link" values={[generatedLink ? `http://weav.me/~${generatedLink?.hash}` : generatedLinkBtnTxt]} onclickFunc={ () => generatedLink ? Globals.copyValue('bounty-link'): generateCampaignLink() } isBtn={generatedLink ? false : true} />
            <hr />
          </>
        }

      {content.discountCodes &&
        content.discountCodes.length > 0 &&
        content.discountCodes.map((discount) => {
          if (discount.discountReason == 'customer') {
            return (
              <>
                <InviteAsset title="Discount Code" subtext="Include your discount code in your description, caption, or story for fans to purchase." label="bounty-code" values={[discount.code ? discount.code.toUpperCase() : offer.bountyFallbackDiscount ? offer.bountyFallbackDiscount.toUpperCase() : '']} />
                {/*<div className="row row0">
                  <h3>Discount Code</h3>
                  <p className="sub-text">
                    Include your discount code in your description, caption,
                    or story for fans to purchase.
                  </p>
                  <input
                    id="bounty-code"
                    className="dark-input"
                    style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                    defaultValue={discount.code ? discount.code.toUpperCase() : offer.bountyFallbackDiscount ? offer.bountyFallbackDiscount.toUpperCase() : ''}
                    onClick={Globals.copyValue('bounty-code')}
                    readOnly
                  />
                </div>*/}
                <hr />
              </>
            );
          }
        })}

      {offer.bountyHashtags && offer.bountyHashtags.length > 0 && (
        <>
          <InviteAsset title="Hashtag" subtext="Include the hashtag in each caption or story." label="bounty-hashtag" values={offer.bountyHashtags.map((h) => { return `#${h}` })} />
          {/*<div className="row row0">
            <h3>Hashtag</h3>
            <p className="sub-text">
              Include the hashtag in each caption or story.
            </p>
            {offer.bountyHashtags.map((h, i) => {
              return (
                <input
                  id={`bounty-hashtag-${i}`}
                  className="dark-input"
                  style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                  defaultValue={`#${h}`}
                  onClick={Globals.copyValue(`bounty-hashtag-${i}`)}
                  readOnly
                />
              );
            })}
          </div>*/}
          <hr />
        </>
      )}

      {offer.bountyMentions && offer.bountyMentions.length > 0 && (
        <>
          <InviteAsset title="Brand Mentions" subtext="Mention the brand in each caption or story." label="bounty-mention" values={offer.bountyMentions.map((m) => { return `@${m}` })} />
          {/*<div className="row row0">
            <h3>Brand Mention</h3>
            <p className="sub-text">
              Mention the brand in each caption or story.
            </p>
            {offer.bountyMentions.map((m, i) => {
              return (
                <input
                  id={`bounty-mention-${i}`}
                  className="dark-input"
                  style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}
                  defaultValue={`@${m}`}
                  onClick={Globals.copyValue(`bounty-mention-${i}`)}
                  readOnly
                />
              );
            })}
          </div>*/}
          <hr />
        </>
      )}

      {offer.brandType == 'game' && (
        <>
          {/*<InviteAsset title="Promo Link" subtext="Direct your audience to this link on your socials!" label="bounty-link" values={[`https://weav.me/~${link?.hash}`]} />*/}
          <div className="row row0">
            <h3 className="title">Brand Assets</h3>
            <p className="sub-text">
              Use only this image asset for your post.
            </p>
            <div className="downloadable-img" style={{ backgroundImage: `url(${offer.imageUrl})` }}></div>
            <a href={offer.imageUrl} target="_blank" download>
              <div className="dark-input" style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', marginTop: '15px' }}>
                <span>
                  <img src={Globals.icon('download')} style={{ width: '16px', marginRight: '6px', marginTop: '-5px' }} />
                </span>{' '}
                <span>Download</span>
              </div>
            </a>
          </div>
          <hr />
        </>
      )}

      {offer.externalAssets && offer.externalAssets.length > 0 && (
        <>
          <div className="row row0">
            <h3 className="title">Brand Assets</h3>
            <p className="sub-text">
              You may use these brand assets to augment your content.
            </p>
            <a href={offer.externalAssets} target="_blank">
              <div className="dark-input" style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer', marginTop: '15px'}}>
                <span>
                  <img src={Globals.icon('link')} style={{ width: '16px', marginRight: '6px', marginTop: '-3px' }} />
                </span>{' '}
                <span>View Brand Assets</span>
              </div>
            </a>
          </div>
          <hr />
        </>
      )}
    </div>

    <InvitePanel title="About Us" showIf={campaign?.offerDescription?.length > 3}>
      { campaign?.offerDescription && <div className="sub-text" dangerouslySetInnerHTML={{ __html: campaign?.offerDescription.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> }
    </InvitePanel>

    <InvitePanel title="Visual Moodboard" showIf={campaign.inspirationImgs?.length}>
        <div className="row">
          <div className="carousel-container">
            <div className="carousel-container-inner">
              <div className={`row row0 restaurant-photos`} style={{width: `${campaign.inspirationImgs.length * (campaign.id == '402783047754' ? 410 : 230)}px`}}>
                {
                  campaign.inspirationImgs.map((i: any) =>
                      <div className={`res-photo-container`}>
                        <div className="res-photo" style={{backgroundImage: `url(${i})`}}></div>
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
    </InvitePanel>

    <InvitePanel title="Content we're looking for" showIf={campaign?.offerContentDescription?.length > 3}>
      { campaign?.offerContentDescription && <div className="sub-text" dangerouslySetInnerHTML={{ __html: campaign?.offerContentDescription.replace(/(?:\r\n|\r|\n)/g, '<br />') }}></div> }
    </InvitePanel>

    <InvitePanel showIf={campaign.inviteRewards[0]?.text?.length > 0} title="Rewards">
      <ul className="do-list">
        { campaign.inviteRewards.map( (item: any, i: number) => item?.text?.length > 3? <SlideOutListItem text={item.text} type="yes" /> : <></> )}
      </ul>
    </InvitePanel>

    <InvitePanel showIf={campaign.listDo[0]?.text?.length > 0} title="Do" bottomHr={false}>
      <ul className="do-list">
        { campaign.listDo.map( (item: any, i: number) => item?.text?.length > 3? <SlideOutListItem text={item.text} type="yes" /> : <></> )}
      </ul>
    </InvitePanel>

    <InvitePanel showIf={campaign.listDont[0]?.text?.length > 0} title="Don't">
      <ul className="dont-list">
        { campaign.listDont.map( (item: any, i: number) => item?.text?.length > 3? <SlideOutListItem text={item.text} type="no" /> : <></> )}
      </ul>
    </InvitePanel>

    <InvitePanel showIf={campaign.talkingDo[0]?.text?.length > 0} title="Talking Points">
      <ul className="talking-point-list">
        { campaign.talkingDo.map( (item: any, i: number) => item?.text?.length > 3? <SlideOutListItem text={item.text} type="yes" /> : <></> )}
      </ul>
    </InvitePanel>

    <InvitePanel showIf={houseRules[0]?.text?.length > 0} title="House Rules">
      <ul style={{padding: 0, listStyle: 'none'}}>
        { houseRules.map( (item: any, i: number) => item?.text?.length > 3? <SlideOutListItem text={item.text} type="yes" /> : <></> )}
      </ul>
    </InvitePanel>

    <InvitePanel showIf={campaign?.offerLegalDisclaimer?.length > 3} title="">
      <i className="sub-text">{ campaign?.offerLegalDisclaimer && <p className="sub-text">{campaign?.offerLegalDisclaimer}</p> }</i>
    </InvitePanel>

    {/*<InvitePanel showIf={!content?.accepted} title="Accept Collaboration">
      <div className="row row0" style={{margin: '50px 0 35px'}}>
        <button className="invitation-accept-btn">Accept Collaboration</button>
        <p className="sub-text" style={{marginTop: '15px'}}>*By accepting collaboration, you have read & agree to post according to the specified guidelines.</p>
      </div>
    </InvitePanel>*/}
  </div>
}
