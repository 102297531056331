import { memo } from 'react';
import Globals from '../../global-functions.js'

const CreateMessageSchedule = ((props) => {
  var { daysActive, selectDay, selectTime, timeStart, timeEnd, _this } = props

  return (
    <>
      <div className="module" style={{maxWidth: '650px'}}>
        <div className="row row0">
          <h4 style={{marginTop: 0}}>Schedule your email</h4>
          <p className="sub-text">Choose the optimal days/times for influencers to be emailed - emails will only be sent within these timeframes when an influencer enters this drip stage.</p>

          <div className="row row0" style={{marginTop: '25px'}}>
            <div className="create-message-days">
              { daysActive.map((day) => {
                  return (
                    <div key={`schedule-day-${day.name}`} className="create-message-day">
                      <input type="checkbox" onChange={selectDay.bind(this, day)} checked={day.active} />
                      <span>{day.name}</span>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <div className="row row0" style={{marginTop: '15px'}}>
            <div style={{display: 'inline-block', float: 'left'}}>
              <select className="dark-input" onChange={selectTime.bind(_this, 'timeStart')} style={{cursor: 'pointer'}}>{timeStart}</select>
              <span className="sub-text" style={{padding: '0 12px'}}>to</span>
              <select className="dark-input" onChange={selectTime.bind(_this, 'timeEnd')} style={{cursor: 'pointer'}}>{timeEnd}</select>
            </div>
            <div style={{display: 'inline-block', float: 'left', marginLeft: '12px', marginTop: '10px'}}>
              <span className="sub-text">Pacific Time (PST)</span>
            </div>
          </div>

        </div>
      </div>
    </>
  )
})

export default memo(CreateMessageSchedule);
