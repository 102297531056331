import { Component, memo } from 'react';
import moment from 'moment';
import store from 'store2';
import Globals from '../../global-functions.js';
import Loader from '../loader';
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainRow from './crm-table-main-row'
import EmptyMessage from '../empty-message'
import SearchPagination from './search-pagination'
import Icon from '../icon'

var checkSearch = '', searchQueryStart, searchQueryTime
async function _getInsight(query, data){ return $.ajax({ method: 'GET', url: `/api/BrandSearch/${query}`, data }) }
async function _setInsight(query, data){ return $.ajax({ method: 'POST', url: `/api/BrandSearch/${query}`, data }) }

class BrandsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      loading: false,
      searchMessage: '',
      loading: false,
      skip: 0,
      limit: 100,
      sortFilter: store('brandListSortFilter') ? store('brandListSortFilter') : false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentWillUnmount() {
    this.setState({ mounted: false })
  }

  shouldComponentUpdate(nextProps, nextState){
    var lastSearch = this.props.lastSearchTime, newSearch = nextProps.lastSearchTime
    if ((newSearch && lastSearch != newSearch) || !this.isEqual(this.state, nextState)) { return true }
    else { return false }
  }

  componentDidUpdate(prevProps) {
    var lastSearch = prevProps.lastSearchTime, newSearch = this.props.lastSearchTime
    if (newSearch && lastSearch != newSearch && this.state.mounted) { this.getBrands(true) }
  }

  isEqual(k1, k2) { return JSON.stringify(k1) === JSON.stringify(k2) }

  async getInsight(query, data){ var { id, username, domain, startDate, endDate, platformFilter } = this.state; return _getInsight(query, Object.assign({ id, username, domain, startDate, endDate, platformFilter }, data ? data : {}) ) }

  async getBrands(newSearch){
    console.log('getBrands')
    if (this.state.loading) return true
    var { skip, limit, sortFilter } = this.state; skip = newSearch ? 0 : skip
    var { filtersActive, selectedEntity, finishBrandSearch } = this.props
    console.log(filtersActive)
    var data = { requestType: selectedEntity, skip, limit, sortFilter }
    if (filtersActive){ var { filters, filtersApplied, filterLabels, activeFilters } = filtersActive; data = { ...data, ...activeFilters } }
    await this.setState({ loading: true, brands: [], influencers: [], searchQueryTime: null })
    var res = await this.getInsight('getBrands', data); await this.setState({ ...res, loading: false }); finishBrandSearch();
  }

  async getBrandsCt(){
    // var data = this.getChosenFilters(); data.requestType = 'count';
    // var res = await this.getInsight('getBrands', data); this.setState({ ...res });
  }

  async chooseSort(sort) {
    var { sortFilter } = this.state
    var direction = sortFilter && sortFilter.indexOf(sort) > -1 && sortFilter.indexOf("DESC") > -1 ? "ASC" : "DESC";
    var sortFilter = sort + " " + direction;
    await this.setState({ sortFilter });
    store('brandListSortFilter', sortFilter);
    this.getBrands(true);
  }

  selectedBrands(){ var { brands } = this.state; return brands ? brands.filter((b) => { if (b.selectedActive){ return b } }) : [] }
  selectBrand(inf, e) {
    var id = inf == "all" ? inf : inf.id;
    var brands = this.state.brands;
    brands.forEach((i) => {
      if (id == "all") { i.selectedActive = !this.state.selectedActive; }
      else { if (i.id == id) { i.selectedActive = !i.selectedActive; } }
    });
    this.setState({ brands, selectedActive: id == "all" ? !this.state.selectedActive : this.state.selectedActive });
  }

  async unmarkBrand(params, type){ await _setInsight('unmarkBrand', params) }

  async incrementPage(dir) {
    var { skip, limit } = this.state, move
    if (dir == 'previous' && skip > 0) { move = parseInt(skip) - parseInt(limit) }
    else if (dir == 'next') { move = parseInt(skip) + parseInt(limit) }
    await this.setState({ skip: move }); this.getBrands();
  }

  render() {
    var { _this, searchMessage, togglePopup } = this.props;
    var { brands, sortFilter, loading, skip, limit } = this.state
    var { chooseSort, selectBrand, incrementPage } = this
    var selectedActiveAll = this.props.selectedActive

    var brandsSelectedNum = false, brandsSelected = this.selectedBrands(), _stages = []
    var hubspotCt = 0, emailCt = 0
    if (brands && brands.length > 0){
      brands.forEach((b) => { if (b.hubspot) { hubspotCt++ } if (b.emails && b.emails[0]) { emailCt++ } })
      brands.forEach((i) => { if (i.selectedActive) { brandsSelectedNum = !brandsSelectedNum ? 1 : brandsSelectedNum + 1; } });
    }

    return (
      <div>
        <div className="crm-table" style={{height: 'calc(100vh - 151px)', width: 'calc(100% + 50px)', borderRadius: 0, boxShadow: 'none', border: 'none'}}>
          <div className="crm-table-header" style={{borderTop: '1px solid #eeeeee'}}>
            <div className={`row row0 crm-row ${selectedActiveAll ? "selected" : ""}`}>
              <div className="crm-td crm-td-xs crm-td-img" style={{paddingTop: '12px', paddingBottom: '12px', height: '44px'}}>
                <input type="checkbox" style={{ display: "block" }} onClick={selectBrand.bind(this, "all")} checked={selectedActiveAll} />
              </div>
              <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Website" title="Website" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              <CRMTableHeaaderCell label="Actions" title="Actions" size="crm-td-md" _this={_this} />
              <CRMTableHeaaderCell label="Followers" title="Followers" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort.bind(this)} />
              <CRMTableHeaaderCell label="Bio" title="IG Bio" size="crm-td-xl" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Socials" title="Socials" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Username" title="Username" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Hubspot" title="Hubspot" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Pipelines" title="Pipelines" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Pitching" title="Pitching" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Outreach" title="Outreach" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Contact" title="Contact" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />*/}
              <CRMTableHeaaderCell label="Primary Contact" title="Primary Contact"  size="crm-td-lg" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Lists" title="Lists" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Lead Status" title="Lead Status"  size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Industry" title="Industry" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />
              {/*<CRMTableHeaaderCell label="Country" title="Country" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Shared #" title="Shared #" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
              <CRMTableHeaaderCell label="Ad Spend" title="Ad Spend" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort.bind(this)} />
              <CRMTableHeaaderCell label="#ads" title="#ads" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort.bind(this)} />
              {/*<CRMTableHeaaderCell label="@ mentions" title="@ mentions" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Language" title="Language" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="IG Category" title="IG Category" size="crm-td-lg" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Keywords" title="Keywords" size="crm-td-xl" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Affiliate Programs" title="Affiliate Programs" size="crm-td-ml" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="First Contact" title="First Contact" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Last Contact" title="Last Contact" size="crm-td-md" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}

              {/*<CRMTableHeaaderCell label="Likes" title="Likes" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Comments" title="Comments" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Deals ($)" title="Deals ($)" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />
              <CRMTableHeaaderCell label="Deals (#)" title="Deals (#)" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Revenue" title="Revenue" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Employees" title="Employees" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Funding" title="Funding" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Alexa" title="Alexa" size="crm-td-sm" sortFilter={sortFilter} _this={_this} chooseSort={chooseSort} />*/}
              {/*<CRMTableHeaaderCell label="Last Updated" title="Last Updated" size="crm-td-md" sortFilter={sortFilter} _this={_this} />*/}
              {/*<CRMTableHeaaderCell label="Bounties" title="Bounties" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Clicks" title="Clicks" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />
              <CRMTableHeaaderCell label="Posts" title="Posts" size="crm-td-sm" sortFilter={sortFilter} _this={_this} />*/}
            </div>
          </div>

          <div className="crm-table-main" style={{height: 'calc(100vh - 170px)'}}>
            { !loading && brands && brands.map((brandObj, i) => {
              var id = brandObj.meta.id
              var row = ( <CRMTableMainRow key={i} brandObj={brandObj} index={i} togglePopup={togglePopup} selectBrand={selectBrand.bind(this)} this={_this} __this={this} /> )
              return ( <> {row} </> );
            })}

            {!brands && !loading && <EmptyMessage title={'Start brand search'} subtext={'Adjust your filters on left to target your ideal brands'} align="top" />}
            {brands.length == 0 && !loading && <EmptyMessage title={'No Brands Found'} subtext={searchMessage || 'Adjust your filters to widen your brand search'} align="top" />}
            {loading && <Loader active={true} title="Compiling brand data..." subtext="Queries with more filters on will take longer to process, please wait up to 20 seconds depending on your filters (our search is in beta)" />}
          </div>
        </div>
        { (brands.length > 0 || skip > 0) && <SearchPagination skip={skip} limit={limit} brandsLength={brands.length} incrementPage={incrementPage.bind(this)} /> }
      </div>
    );
  }
}

export default memo(BrandsTable);
