import React from 'react';
import Globals from '../../global-functions.js';
import SlideOutListItem from './slide-out-list-item.jsx';
import SlideOutIncentive from './slide-out-incentive.jsx';
import SlideOutMainBtn from './slide-out-main-btn.jsx';

export default class SlideOutOffer extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {

    var campaign = this.props.campaign
    var user = campaign.user
    // console.log(campaign)
    var game = campaign.game ? campaign.game : campaign.offer
    var offer = campaign.offer ? campaign.offer : {}
    var content = campaign.content ? campaign.content : {}
    if (campaign) {
      campaign.brandType = campaign.brandType ? campaign.brandType : offer.brandType
    }
    var restaurant = campaign.restaurant

    var pageHTML = ''

    var bountyObjs = [], hashtags = [], mentions = [], mealVal = 0, freeProductVal = offer.freeProductVal ? offer.freeProductVal : 0, offerCPV = 0, offerCPVExists = false, offerClickPayout = 0, offerClickPayoutExists = false

    if (offer.offerClickPayout && Number(offer.offerClickPayout) > 0) { offerClickPayout = offer.offerClickPayout; offerClickPayoutExists = true; }

    if (campaign && campaign.bountyOffers){
      campaign.bountyOffers.forEach((bounty) => {
        if (bounty){
          var bountyOffer = bounty.bountyOffer
          if (bountyOffer.offerMealVal) { mealVal = bountyOffer.offerMealVal }
          // if (bountyOffer.offerFreeProductVal) { freeProductVal = bountyOffer.offerFreeProductVal }
          if (bountyOffer.metricCPV && bountyOffer.offerCPV && Number(bountyOffer.offerCPV) > 0) { offerCPV = bountyOffer.offerCPV; offerCPVExists = true; }

          if (bountyOffer.bountyMentions) { offer.bountyMentions = bountyOffer.bountyMentions }
          if (bountyOffer.bountyHashtags) { offer.bountyHashtags = bountyOffer.bountyHashtags }
          if (bountyObj && bountyOffer){
            var bountyObj = bounty.bountyObj, bountyOffer = bountyOffer
            // console.log(bounty.bountyOffer.offerMealVal)
            // if (bounty.bountyOffer.offerMealVal) { mealVal = bounty.bountyOffer.offerMealVal }
            // if (bounty.bountyOffer.offerFreeProductVal) { freeProductVal = bounty.bountyOffer.offerFreeProductVal }

            if (bountyObj.bountyMentions) { offer.bountyMentions = bountyObj.bountyMentions }
            if (bountyObj.bountyHashtags) { offer.bountyHashtags = bountyObj.bountyHashtags }
          }
        }

        // if (includes(bountyObj.labels, 'BountyGameTwitchStream')) { bountyObjs.push(<SlideOutGameTwitchStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyGameMixerStream')) { bountyObjs.push(<SlideOutGameMixerStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyGameYTStream')) { bountyObjs.push(<SlideOutGameYTStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyGameYTVideo')) { bountyObjs.push(<SlideOutGameYTVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyGameIGVideo')) { bountyObjs.push(<SlideOutGameIGVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyGameIGPhoto')) { bountyObjs.push(<SlideOutGameIGPhoto campaign={campaign} bounty={bountyObj} />) }
        //
        // else if (includes(bountyObj.labels, 'BountyProductTwitchStream')) { bountyObjs.push(<SlideOutProductTwitchStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyProductMixerStream')) { bountyObjs.push(<SlideOutProductMixerStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyProductYTStream')) { bountyObjs.push(<SlideOutProductYTStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyProductYTVideo')) { bountyObjs.push(<SlideOutProductYTVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyProductIGVideo')) { bountyObjs.push(<SlideOutGameYTVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyProductIGPhoto')) { bountyObjs.push(<SlideOutProductIGPhoto campaign={campaign} bounty={bountyObj} />) }
        //
        // else if (includes(bountyObj.labels, 'BountyRestaurantTwitchStream')) { bountyObjs.push(<SlideOutRestaurantTwitchStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyRestaurantMixerStream')) { bountyObjs.push(<SlideOutRestaurantMixerStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyRestaurantYTStream')) { bountyObjs.push(<SlideOutRestaurantYTStream campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyRestaurantYTVideo')) { bountyObjs.push(<SlideOutRestaurantYTVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyRestaurantIGVideo')) { bountyObjs.push(<SlideOutRestaurantIGVideo campaign={campaign} bounty={bountyObj} />) }
        // else if (includes(bountyObj.labels, 'BountyRestaurantIGPhoto')) { bountyObjs.push(<SlideOutRestaurantIGPhoto campaign={campaign} bounty={bountyObj} />) }

      })
    }

    var offerDescription = offer.offerDescription && offer.offerDescription.length > 2 ? offer.offerDescription : false
    if (!offer.offerDescription && restaurant) { offerDescription = restaurant.bio }
    else if (!offer.offerDescription) { offerDescription = 'No bounty description provided.'  }

    if (offer.listDo && typeof offer.listDo == 'string') { offer.listDo = JSON.parse(offer.listDo) }
    if (offer.listDont && typeof offer.listDont == 'string') { offer.listDont = JSON.parse(offer.listDont) }
    if (offer.talkingDo && typeof offer.talkingDo == 'string') { offer.talkingDo = JSON.parse(offer.talkingDo) }
    if (offer.mealDishes && typeof offer.mealDishes == 'string') { offer.mealDishes = JSON.parse(offer.mealDishes) }
    if (offer.gifts && typeof offer.gifts == 'string') { offer.gifts = JSON.parse(offer.gifts) }

    var listDoExists = offer.listDo && offer.listDo[0] && offer.listDo[0].text.length > 0 ? true : false
    var listDontExists = offer.listDont && offer.listDont[0] && offer.listDont[0].text.length > 0 ? true : false
    var talkingDoExists = offer.talkingDo && offer.talkingDo[0] && offer.talkingDo[0].text.length > 0 ? true : false
    var mealDishesExists = offer.mealDishes && offer.mealDishes[0] && offer.mealDishes[0].text.length > 0 ? true : false
    var giftsExists = offer.gifts && offer.gifts[0] && offer.gifts[0] ? true : false

    var houseRules = [
      { text: 'Include #ad' },
      { text: 'You must own the rights to all content/music submitted' },
      { text: 'Do not use previously published content' },
      { text: 'Keep your post at the top of feed for 5 hrs and live for 30 days' },
      { text: 'Tag the brand and include #ad in each Stories frame' },
      { text: 'Always adhere to the Weave Codes of Conduct' }
    ]

    return (
      <div>
        <div className={`slide-out-offer-scrollable slide-out-offer-${campaign.brandType} ${this.props.scrollingDown ? 'maximize' : ''}`}>
          {/*<div className="row row0">
            <h3>Influencer Details</h3>
            <p className="sub-text" style={{margin: 0}}>male 18-45yrs</p>
            <p className="sub-text" style={{margin: 0}}>Instagram</p>
            <p className="sub-text" style={{margin: 0}}>25k - 250k followers</p>
          </div>*/}

          {/*<hr />*/}

          <div className="row row0 slide-out-incentives">
            {
              offer.brandType == 'restaurant' &&
              <div>
                <SlideOutIncentive value={`$${mealVal}`} title={`Meal Credit`} />
              </div>
            }
            {
              (!content ||
              (content && (content.status == 'brand_offer' || content.status == 'brand_invited'))) &&
              <div>
                { freeProductVal > 0 && <SlideOutIncentive value={`$${freeProductVal}`} title={`Free Products`} /> }
                { offer.offerAffiliateComm && <SlideOutIncentive value={`${offer.offerAffiliateType == '$' ? '$' : ''}${offer.offerAffiliateComm}${offer.offerAffiliateType == '%' ? '%' : ''}`} title={offer.brandType == 'app' ? 'Per Download' : 'Per Sale'} /> }
                { content && content.paymentFlatRate && Number(content.paymentFlatRate) > 0 && <SlideOutIncentive value={`$${content.paymentFlatRate}`} title={`Flat Payment`} /> }
                { offerCPVExists && <SlideOutIncentive value={`$${Math.round(Number(offerCPV)*1000)}`} title={`CPM Rate`} /> }
                {/*{ offerClickPayoutExists && <SlideOutIncentive value={`$${offerClickPayout}`} title={`Pay-Per-Click`} /> }*/}
                { offer && offer.postingDeadline && String(offer.postingDeadline).length > 4  && <SlideOutIncentive value={`${moment(offer.postingDeadline).format('MMM D')}`} title={`Posting Deadline`} /> }
              </div>
            }
          </div>

          {
            offer.brandType == 'restaurant' &&
            mealDishesExists &&
            typeof offer.mealDishes == 'object' &&
            <>
              <div className="row row0">
                <h3>Meal Dish Recommendations</h3>
                <ul style={{padding: 0, listStyle: 'none'}}>
                  {
                    offer.mealDishes &&
                    offer.mealDishes.map((item, i) => {
                      if (item && item.text && item.text.length > 3){
                        return (
                          <>
                            <li>
                              <div style={{display: 'inline-block', float: 'left'}}>
                                <svg style={{ marginRight: '8px', marginTop: '3px', width: '15px' }} className="SVGInline-svg SVGInline--cleaned-svg" fill="#01d36d" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="f964ce12-05e4-410b-bfe0-4a4b8eaa49c4" dataname="Design"><path d="M20.43,7.07,10.71,17.79a1.26,1.26,0,0,1-.89.41h0a1.24,1.24,0,0,1-.89-.37l-5.1-5.1A1.25,1.25,0,0,1,5.57,11l4.17,4.17,8.84-9.75a1.25,1.25,0,1,1,1.85,1.68Z"></path></g></svg>
                              </div>
                              <div style={{display: 'inline-block', position: 'relative', float: 'left', width: 'calc(100% - 25px)'}}>
                                <div style={{ position: 'relative', width: '100%' }}>
                                  <p className="sub-text">{item.text}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        )
                      }
                    })
                  }
                </ul>
              </div>

              <hr />
            </>
          }

          {/*{
            offer && offer.id == '988672654695' &&
            content && (content.status == 'brand_offer' || content.status == 'brand_invited') &&
            <>
              <div className="row row0">
                <h3 style={{marginTop: '15px'}}>Get Your Free Product</h3>
                <p className="sub-text">
                  Once you accept your invitation, you will be given your code to redeem any product from uglychristmassweater.com for free.
                </p>
              </div>

              <hr />
            </>
          }*/}

          {/*{
            offer && offer.id == '988672654695' &&
            content && (content.status == 'brand_offer' || content.status == 'brand_invited') &&
            <>
              <div className="row row0">
                <h3 style={{marginTop: '15px'}}>Bounty Availability</h3>
                <p className="sub-text">
                  There are <b>{(offer.spotsRemaining && typeof offer.spotsRemaining == 'object' && offer.spotsRemaining.low) ? offer.spotsRemaining.low : offer.spotsRemaining} partnership spots remaining</b> for creators. Once all spots fill up, this bounty will no longer be available.
                </p>
              </div>

              <hr />
            </>
          }*/}

          {/*{
            this.props.bountyParticipants && this.props.bountyParticipants.length > 0 &&
            <>
              <div className="row row0">
                <h3>Creators In Bounty</h3>
                <p className="sub-text">These creators are currently collaborating with {offer.bountyTitle} as well!</p>
                <div className="row">
                  {
                    this.props.bountyParticipants.map((p) => {
                      return (
                        <div className="col-xs-2" style={{padding: '15px'}}>
                          <img src={p} style={{width: '100%', borderRadius: '50%'}} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>

              <hr />
            </>
          }*/}


          {
            (offer.brandType == 'app' ||
            offer.brandType == 'game') &&
            offer.budget &&
            offer.tieredPayoutStages && typeof offer.tieredPayoutStages == 'object' && offer.tieredPayoutStages.length > 1 &&
            <>
              <div className="row row0">
                <h3>$200 Max Reward</h3>
                <p className="sub-text">Reach the click count milestones and collect the different rewards! The challenge will end once total rewards given out get to $10K</p>
              </div>
              <div className="row row0" style={{marginTop: '25px'}}>
                {/*<h4 style={{margin: '0 0 8px 0', fontWeight: 'bold', fontFamily: 'Barlow'}}><span style={{color: '#21b93e'}}>${Math.round(Number(offer.budget.spent))}</span> Rewarded</h4>*/}
                <div className="row row0 bounty-progress-labels" style={{position: 'relative'}}>
                  {
                    offer.tieredPayoutStages.map((p, i) => {
                      var length = offer.tieredPayoutStages.length
                      return (
                        <div className={`bounty-progress-reward ${length == i + 1 ? 'right-side' : ''} bsr-${length}-${i+1}`} style={{left: `${(p.threshold / 1000)*100}%`}}>
                          ${p.amount}
                          <div className="bounty-progress-arrow-down-container">
                            <div className="bounty-progress-arrow-down"></div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="row row0" style={{marginTop: '25px'}}>
                <div className="">
                  <div className="bounty-progress" style={{top: '1px'}}>
                    <div className="bounty-progress-outer">
                      <div className="bounty-progress-inner" style={{width: 0}}>

                      </div>
                      <div style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
                        <div className="row row0" style={{position: 'relative', width: '100%', height: '100%'}}>
                          {
                            offer.tieredPayoutStages.map((p, i) => {
                              if (i+1 != offer.tieredPayoutStages.length){
                                return (
                                  <div className={`bounty-progress-tick bsr-${offer.tieredPayoutStages.length}-${i+1}`} style={{left: `${(p.threshold / 1000)*100}%`}}><div className="bounty-progress-tick-inner"></div></div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row0 bounty-progress-labels" style={{position: 'relative', marginBottom: '50px'}}>
                {
                  offer.tieredPayoutStages.map((p, i) => {
                    return (
                      <div className={`bounty-progress-label bsr-${offer.tieredPayoutStages.length}-${i+1}`} style={{left: `${(p.threshold / 1000)*100}%`}}><img src={Globals.icon('click')} style={{width: '14px', marginTop: '-2px'}} /> {p.threshold}</div>
                    )
                  })
                }
              </div>

              <hr />
            </>
          }


          {
            (offer.brandType == 'app' ||
            offer.brandType == 'game') &&
            offer.budget &&
            <>
              <div className="row row0">
                <h3>Bounty Reward Pool</h3>
                <p className="sub-text" style={{margin: 0}}>You may continue to earn money until the bounty reward pool is depleted.</p>
              </div>
              <div className="row row0" style={{marginTop: '25px'}}>
                <div className="">
                  <h4 style={{margin: '0 0 8px 0', fontWeight: 'bold', fontFamily: 'Barlow'}}><span style={{color: '#21b93e'}}>${Math.round(Number(offer.budget.spent))}</span> Rewarded</h4>
                  <div className="bounty-progress" style={{top: '1px'}}>
                    <div className="bounty-progress-outer">
                      <div className="bounty-progress-inner" style={{width: `${Math.round((Number(offer.budget.spent)/Number(offer.budget.budget)) * 100)}%`}}>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row0 bounty-progress-labels">
                <div style={{float: 'left'}}>$0</div>
                <div style={{position: 'absolute', margin: '0 auto', left: 0, right: 0, textAlign: 'center'}}>${Globals.convertNumberFormat(Math.round(Number(offer.budget.budget)/2))}</div>
                <div style={{float: 'right'}}>${Globals.convertNumberFormat(offer.budget.budget)}</div>
              </div>
              {/*<div className="row row0" style={{marginTop: '15px', fontFamily: 'Barlow'}}>
                <span>
                  <span style={{fontSize: '24px', fontWeight: 'bold'}}>$10,000.00</span>
                  <span style={{fontSize: '18px', marginLeft: '8px'}}>/</span>
                  <span style={{fontSize: '18px'}}>$10,000</span>
                </span>
              </div>*/}
              <div className="row row0" style={{marginTop: '15px', fontFamily: 'Barlow'}}>
                <p className="sub-text">There is <b>{Math.round((1 - Number(offer.budget.spent)/Number(offer.budget.budget)) * 100)}%</b> of the bounty reward pool remaining to be earned.</p>
              </div>

              <hr />
            </>
          }

          {
            offer.brandType == 'product' &&
            <>
              <div className="row row0">
                <h3>Payout Breakdown</h3>
                <p className="sub-text">You earn the following rewards below for completing the bounty.</p>
                <div className="row row0" style={{marginTop: '15px', fontFamily: 'Barlow'}}>
                  <span>
                    {
                      freeProductVal > 0 &&
                      <span>
                        <span style={{fontSize: '24px', fontWeight: 'bold'}}>${freeProductVal}</span>
                        <span style={{fontSize: '18px', marginLeft: '6px'}}>/products</span>
                      </span>
                    }
                    {
                      content && content.paymentFlatRate && Number(content.paymentFlatRate) > 0 &&
                      <span>
                        { freeProductVal > 0 && <span style={{fontSize: '18px', fontWeight: 'bold', margin: '0 6px'}}>  +  </span> }
                        <span style={{fontSize: '24px', fontWeight: 'bold'}}>${content.paymentFlatRate}</span>
                        <span style={{fontSize: '18px', marginLeft: '6px'}}>/payment</span>
                      </span>
                    }
                    {
                      offer.offerAffiliateComm &&
                      <span>
                        { (freeProductVal > 0 || (content && content.paymentFlatRate && Number(content.paymentFlatRate) > 0)) && <span style={{fontSize: '18px', fontWeight: 'bold', margin: '0 6px'}}>  +  </span> }
                        <span style={{fontSize: '24px', fontWeight: 'bold'}}>
                          { offer.offerAffiliateType == '$' && <span>$</span> }
                          { offer.offerAffiliateComm }
                          { offer.offerAffiliateType == '%' && <span>%</span> }
                        </span>
                        <span style={{fontSize: '18px', marginLeft: '6px'}}>/sale</span>
                      </span>
                    }
                  </span>
                </div>
              </div>

              <hr />
            </>
          }

          {
            offer.brandType == 'app' &&
            offer.downloadPayouts &&
            <>
              <div className="row row0">
                <h3>Payout Breakdown</h3>
                <p className="sub-text">You earn for each app download and an additional payout if that user subscribes to the Season Pass.</p>
                <div className="row row0" style={{marginTop: '15px', fontFamily: 'Barlow'}}>
                  <span>
                    {
                      offer.downloadPayouts.map((p, i) => {
                        return (
                          <>
                            <span style={{fontSize: '24px', fontWeight: 'bold'}}>${p.rate}</span>
                            <span style={{fontSize: '18px', marginLeft: '6px'}}>/{p.name}</span>
                            { i + 1 != offer.downloadPayouts.length && <span style={{fontSize: '18px', fontWeight: 'bold', margin: '0 6px'}}>  +  </span> }
                          </>
                        )
                      })
                    }
                    {/*<span style={{fontSize: '24px', fontWeight: 'bold'}}>$1.00</span>
                    <span style={{fontSize: '18px', marginLeft: '6px'}}>/download</span>
                    <span style={{fontSize: '18px', fontWeight: 'bold', margin: '0 6px'}}>  +  </span>
                    <span style={{fontSize: '24px', fontWeight: 'bold'}}>$4.00</span>
                    <span style={{fontSize: '18px', marginLeft: '6px'}}>/signup</span>*/}
                  </span>
                </div>
              </div>

              <hr />
            </>
          }

          {
            offer.brandType == 'game' &&
            <>
              <div className="row row0">
                <h3>Payout Breakdown</h3>
                <p className="sub-text">You earn for each unique click you drive to the game's purchase page (USA only).</p>
                <div className="row row0" style={{marginTop: '15px', fontFamily: 'Barlow'}}>
                  <span>
                    <span style={{fontSize: '24px', fontWeight: 'bold'}}>$0.15</span>
                    <span style={{fontSize: '18px', marginLeft: '6px'}}>/click (USA only)</span>
                  </span>
                </div>
              </div>

              <hr />
            </>
          }

          {
            offer.offerDescription && offer.offerDescription.length > 0 &&
            <>
              <div className="row row0">
                <h3>About Us</h3>
                <p className="sub-text">{offer.offerDescription}</p>
              </div>

              <hr />
            </>
          }


          {
            offer.inspirationImgs && offer.inspirationImgs.length > 0 &&
            <>
              <div className="row row0">
                <h3>Visual Inspiration</h3>
                <div className="row">
                  <div className="carousel-container">
                    <div className="carousel-container-inner">
                      <div className={`row row0 restaurant-photos`} style={{width: `${offer.inspirationImgs.length * (offer.id == '402783047754' ? 410 : 230)}px`}}>
                        {
                          offer.inspirationImgs.map((i) => {
                            return (
                              <div className={`res-photo-container`} style={offer.id == '402783047754' ? { width: '410px' } : {}}>
                                <div className="res-photo" style={{backgroundImage: `url(${i})`}}></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </>
          }

          {
            offer.downloadableAssets && offer.downloadableAssets.length > 0 &&
            <>
              <div className="row row0">
                <h3>Visual Inspiration</h3>
                <div className="row">
                  <div className="carousel-container">
                    <div className="carousel-container-inner">
                      <div className={`row row0 restaurant-photos`} style={{width: `${offer.inspirationImgs.length * (offer.id == '402783047754' ? 410 : 230)}px`}}>
                        {
                          offer.inspirationImgs.map((i) => {
                            return (
                              <div className={`res-photo-container`} style={offer.id == '402783047754' ? { width: '410px' } : {}}>
                                <div className="res-photo" style={{backgroundImage: `url(${i})`}}></div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </>
          }

          {
            offer.offerContentDescription && offer.offerContentDescription.length > 1 &&
            <>
              <div className="row row0">
                <h3>Content we're looking for</h3>
                <p className="sub-text">{offer.offerContentDescription}</p>
              </div>

              <hr />
            </>
          }

          {
            (listDoExists || listDontExists) &&
            <>
              {
                listDoExists &&
                <div className="row row0" style={{marginBottom: '20px'}}>
                  <h3>Do</h3>
                  <ul style={{padding: 0, listStyle: 'none'}}>
                    {
                      offer.listDo &&
                      offer.listDo.map((item, i) => {
                        if (item && item.text && item.text.length > 3){
                          return ( <SlideOutListItem text={item.text} type="yes" /> )
                        }
                      })
                    }
                  </ul>
                </div>
              }

              {
                listDontExists &&
                <div className="row row0">
                  <h3>Don't</h3>
                  <ul style={{padding: 0, listStyle: 'none'}}>
                    {
                      offer.listDont &&
                      offer.listDont.map((item, i) => {
                        if (item && item.text && item.text.length > 3){
                          return ( <SlideOutListItem text={item.text} type="no" /> )
                        }
                      })
                    }
                  </ul>
                </div>
              }

              <hr />
            </>
          }

          {
            talkingDoExists &&
            <>
              <div className="row row0">
                <h3>Talking Points</h3>
                <ul style={{padding: 0, listStyle: 'none'}}>
                  {
                    offer.talkingDo &&
                    offer.talkingDo.map((item, i) => {
                      if (item && item.text && item.text.length > 3){
                        return ( <SlideOutListItem text={item.text} type="yes" /> )
                      }
                    })
                  }
                </ul>
              </div>

              <hr />
            </>
          }


          {
            (offer.bountyHashtags && offer.bountyHashtags.length > 0 ||
            offer.bountyMentions && offer.bountyMentions.length > 0) &&
            <>
              <h3>Hashtags & Mentions</h3>
              <div className="row row0 hashtags-container">
                {
                  offer.bountyHashtags &&
                  typeof offer.bountyHashtags == 'object' &&
                  offer.bountyHashtags.map((h) => { return ( <span className="tag-pill hashtag">#{h} </span> ) })
                }
                {
                  offer.bountyMentions &&
                  typeof offer.bountyMentions == 'object' &&
                  offer.bountyMentions.map((h) => { return ( <span className="tag-pill mention">@{h} </span> ) })
                }
              </div>

              <hr />
            </>
          }

          <div className="row row0">
            <h3>{offer.id == '402783047754' ? 'Dojo Rules' : 'House Rules'}</h3>
            <ul style={{padding: 0, listStyle: 'none'}}>
              {
                houseRules.map((r) => { return ( <SlideOutListItem text={r.text} type="yes" /> ) })
              }
            </ul>
          </div>
          <hr />

          {
            offer.offerLegalDisclaimer && offer.offerLegalDisclaimer.length > 2 &&
            <>
              <div className="row row0">
                {/*<h3>House Rules</h3>*/}
                <p className="sub-text"><i>{offer.offerLegalDisclaimer}</i></p>
              </div>
              <hr />
            </>
          }

          <div>

            <div className="row">
              {
                campaign.slideOutStage == 'bounty_chosen' &&
                offer.bounty_stage == 'completed' &&
                !content.vodUploaded &&
                <div>
                  {/*<input value={this.state.vodLinkVal} onChange={this.updateText.bind(this, 'vodLinkVal')} style={{background: '#202225', borderColor: '#2f2f2f'}} />*/}
                  {
                    campaign.vodLinks && campaign.vodLinks.length > 0 &&
                    campaign.vodLinks.map((vod) => {
                      return (
                        <div className="row vod-autocomplete" style={{margin: 0, padding: '15px'}} onClick={this.props.chooseVod.bind(this.props.this, vod)}>
                          <div className="col-md-4" style={{padding: 0}}>
                            <img src={vod.thumbnail_url.replace('%{width}', '320').replace('%{height}', '180')} style={{width: '100%', borderRadius: '3px'}} />
                            <div className="vod-duration">
                              {Globals.convertTwitchDuration(vod.duration)}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <p style={{margin: 0}}>{vod.title}</p>
                            <p style={{margin: 0}} className="sub-text">{moment(vod.created_at).format('MMM Do h:mma')}</p>
                            <span className="sub-text">{vod.type}</span>
                            {/*<a href={link.url} style={{fontWeight: 800, fontSize: '18px'}} target="_blank">{link.url}</a>*/}
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    this.state.vodAutocomplete && this.state.vodAutocomplete.length > 0 &&
                    this.state.vodAutocomplete.map((vod) => {
                      return (
                        <div className="row vod-autocomplete" style={{margin: 0, padding: '15px'}} onClick={this.props.chooseVod.bind(this.props.this, vod)}>
                          <div className="col-md-4" style={{padding: 0}}>
                            <img src={vod.thumbnail_url.replace('%{width}', '320').replace('%{height}', '180')} style={{width: '100%', borderRadius: '3px'}} />
                            <div className="vod-duration">
                              {Globals.convertTwitchDuration(vod.duration)}
                            </div>
                          </div>
                          <div className="col-md-8">
                            <p style={{margin: 0}}>{vod.title}</p>
                            <p style={{margin: 0}} className="sub-text">{moment(vod.created_at).format('MMM Do h:mma')}</p>
                            <span className="sub-text">{vod.type}</span>
                            {/*<a href={link.url} style={{fontWeight: 800, fontSize: '18px'}} target="_blank">{link.url}</a>*/}
                          </div>
                        </div>
                      )
                    })
                  }
                  {/*<button onClick={this.props.addVod.bind(this.props.this, campaign, this.state.vodLinkVal)} style={{background: 'rgb(195, 58, 58)', maxWidth: '200px', padding: '12px 15px', fontFamily: 'Barlow', fontWeight: 900, letterSpacing: 'initial'}}>
                    Save VOD
                  </button>*/}
                  <button onClick={this.props.getVodList.bind(this.props.this, '438597369')} style={{background: 'rgb(195, 58, 58)', maxWidth: '200px', padding: '12px 15px', fontFamily: 'Barlow', fontWeight: 900, letterSpacing: 'initial'}}>
                    + Select VOD
                  </button>
                  <p style={{color: 'rgb(189, 187, 192)', textAlign: 'center', fontSize: '12px'}}>*Access your VOD <a href={`https://www.twitch.tv/${user.username}/videos`} target="_blank">here</a>. Weave will review your stream for payment approval.</p>
                </div>
              }
            </div>


          </div>
        </div>
        {
          (
            content.status == 'brand_offer' ||
            content.status == 'brand_awaiting' ||
            content.status == 'brand_invited'
          ) &&
          <>
            {
              this.props.bountyCredits == 0 &&
              <SlideOutMainBtn this={this.props.this} title={'0 Bounty Credits Remaining'} description={'Complete current bounty to earn back credit'} onClickFunc={() => {}} />
            }
            {
              this.props.bountyCredits > 0 &&
              <SlideOutMainBtn this={this.props.this} title={'Continue'} onClickFunc={this.props.startAcceptBounty.bind(this.props.this)} />
            }
          </>
        }

        {
          content.status == 'brand_incomplete' &&
          <SlideOutMainBtn this={this.props.this} title={'No Socials Connected'} description={'Connect a social to be eligible'} onClickFunc={this.props.connectSocialSlideOut} />
        }

      </div>
    )
  }
}
