import React from 'react';
import SlideOutMenuItem from './slide-out-menu-item.jsx';

export default class SlideOutMenu extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      cart: [],
      cartActive: false,
      lat: 34.064420,
      lng: -118.427850,
      center: {
        lat: 34.064420,
        lng: -118.427850
      },
      zoom: 15,
      chosenItems: [],
      cartValue: 0
    }
  }

  componentDidMount(){

  }

  toggleCart(val) {
    this.setState({
      cartActive: val
    })
  }

  chooseItem(item){
    var chosenItems = this.state.chosenItems.concat([item])
    var total = 0
    chosenItems.forEach((i) => { total += (parseFloat(i.price) * parseInt(i.quantity)) })
    this.setState({
      chosenItems: chosenItems,
      cartValue: total
    })
  }

  render() {

    var menu = [
      {
        title: 'To-Go Trust Me',
        description: 'Organic Edamame, Tuna Sashimi, Albacore Sushi (2 pieces), Salmon Sushi (2 pieces), Yellowtail Sushi (2 pieces), NZ Sea Bream (2 pieces), Toro Cut Roll (4 pieces), Crab Cut Roll (4 pieces).',
        price: '33.00',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2F901195ad-b887-45dd-971b-498b13e161df%2Foriginal.jpg%3Fv%3D63745226693&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      },
      {
        title: 'To-Go Lite',
        description: 'Organic Edamame, Tuna Sashimi, Albacore Sushi (2 pieces), Salmon Sushi (2 pieces), Yellowtail Sushi (2 pieces), NZ Sea Bream (2 pieces), Toro Cut Roll (4 pieces), Crab Cut Roll (4 pieces).',
        price: '25.00',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2F8c882659-59fd-484c-bb67-f8a5a4655e69%2Foriginal.jpg%3Fv%3D63745226754&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      },
      {
        title: 'To-Go Nozawa Trust Me',
        description: 'Organic Edamame, Tuna Sashimi, Albacore Sushi (2 pieces), Salmon Sushi (2 pieces), Yellowtail Sushi (2 pieces), NZ Sea Bream (2 pieces), Toro Cut Roll (4 pieces), Crab Cut Roll (4 pieces).',
        price: '38.00',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2F95c5b1d2-bd3d-47ed-b32a-8a3112190cba%2Foriginal.jpg%3Fv%3D63745226867&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      },
      {
        title: 'No Roll Trust Me',
        description: 'Organic Edamame, Tuna Sashimi, Albacore Sushi (2 pieces), Salmon Sushi (2 pieces), Yellowtail Sushi (2 pieces), NZ Sea Bream (2 pieces), Toro Cut Roll (4 pieces), Crab Cut Roll (4 pieces).',
        price: '29.00',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2F77da9b1a-d24b-41ac-95b9-50492df90b60%2Foriginal.jpg%3Fv%3D63745226886&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      },
      {
        title: 'Tuna Sashimi',
        description: '',
        price: '8.25',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2F05bf5faf-f079-4ac5-be31-fab73a6055f2%2Foriginal.jpg%3Fv%3D63744953044&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      },
      {
        title: 'Salmon Sashimi',
        description: '',
        price: '7.50',
        quantity: '1',
        img: 'https://raster-static.postmates.com/?url=https%3A%2F%2Fitems-static.postmates.com%2Fuploads%2Fmedia%2Fff721b48-4974-4e68-a778-ad0f2cd35483%2Foriginal.jpg%3Fv%3D63744953087&quality=85&w=320&h=0&mode=auto&format=webp&v=4'
      }
    ]

    var chosenItems = this.state.chosenItems

    return (
      <div className="slide-out-menu">
        <h4 className="menu-header">Add Items To Delivery Order</h4>
        <div className="row row0">
          <ul>
            { menu.map((item) => { return ( <SlideOutMenuItem this={this} type="full" item={item} chooseItem={this.chooseItem} /> ) }) }
          </ul>
        </div>

        <div className="menu-checkout">
          <button className="menu-checkout-btn" onClick={this.toggleCart.bind(this)}>
            <div className="menu-checkout-btn-container">
              <div className="row">
                <div className="menu-checkout-btn-text">
                  <img src="/images/cart.svg" />
                  <span>View cart</span>
                </div>
                <div className="menu-checkout-btn-value">
                  <b>${this.state.cartValue}</b>
                  {/*<span className="sub-text"> /$80</span>*/}
                  <span style={{fontWeight: 500}}> /$80</span>
                </div>
              </div>
            </div>
          </button>
        </div>

        <div className={`slide-out-menu-cart ${this.state.cartActive ? 'active' : ''}`}>
          <div className="row">
            <div className="col-md-6">
              <h4 className="menu-header">Cart ({this.state.chosenItems.length})</h4>
            </div>
            <div className="col-md-6" style={{textAlign: 'right'}}>
              <p style={{fontWeight: 600, marginTop: '7px', cursor: 'pointer'}} onClick={this.toggleCart.bind(this, false)}>+ ADD MORE ITEMS</p>
            </div>
          </div>

          {/*<h5 className="menu-header">Items</h5>*/}
          <ul>
            {
              this.state.chosenItems.map((item) => {
                return (
                  <SlideOutMenuItem
                    this={this}
                    type="simple"
                    item={item}
                    chooseItem={this.chooseItem}
                  />
                )
              })
            }
            <li style={{background: 'none'}}>
              <div className="row row0">
                <p style={{float: 'right', margin: '0'}}><b style={{fontSize: '16px'}}>${this.state.cartValue}</b> <span>/$80</span><span className="sub-text"> credit spent</span></p>
              </div>
            </li>
          </ul>

          <div className="menu-checkout">
            <div className="row row0" style={{marginBottom: '25px'}}>
              <div style={{display: 'inline-block', width: '100px', height: '100px', float: 'left', marginRight: '25px', overflow: 'hidden', borderRadius: '15px'}}>
                <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.lat},${this.state.lng}&zoom=${this.state.zoom}&size=150x150&maptype=roadmap&markers=color:red%7Clabel:•%7C${this.state.lat},${this.state.lng}&key=AIzaSyBqNw58k2WB9KkQTPis6Y-oAs-_6pCOl-I`} style={{width: '100%', height: '100%'}} />
              </div>
              <div style={{display: 'inline-block', float: 'left'}}>
                <p style={{marginBottom: 0}}>1283 S Beverly Glen Blvd Apt 5</p>
                <p className="sub-text">Los Angeles, CA 90024</p>
                <p className="sub-text" style={{marginBottom: 0}}>Delivery ETA: 20 - 30 min</p>
                <p style={{fontSize: '12px', color: '#00CC99'}}>Change Delivery Address</p>
              </div>
            </div>

            <div className="row row0" style={{textAlign: 'center'}}>
              <p className="sub-text" style={{marginBottom: 0}}>*By placing this order, I accept the obligations of the bounty.</p>
            </div>

            <button className="menu-checkout-btn" onClick={this.toggleCart.bind(this, true)}>
              <div className="menu-checkout-btn-container">
                <div className="btn-fill btn-fill-1"></div>
                <div className="btn-fill btn-fill-2"></div>
                <div className="row">
                  <div className="menu-checkout-btn-text">
                    <img src="/images/cart.svg" />
                    <span className="">Place Order</span>
                  </div>
                  <div className="menu-checkout-btn-value">
                    {/*<span className="sub-text" style={{fontSize: '12px', letterSpacing: 'initial', margin: '4px 0 0 0'}}>Deliver Now</span>*/}
                    <p style={{fontSize: '12px', letterSpacing: 'initial', margin: '3px 0 0 0', fontWeight: 600}}>Deliver Now</p>
                    {/*<b>$33.00</b>
                    <span className="sub-text"> /$80</span>*/}
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }
}
