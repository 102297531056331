import React, { useState } from 'react';
import './invite-account.scss';
import { Link } from 'react-router-dom';
import { authActionCreators } from '../../../redux/actions/auth.actions';
import { LoginType } from '../../../redux/models/auth.models';
import { useDispatch } from 'react-redux';
import { useAuthLoadingSelector, useIsLoggedInSelector } from '../../../redux/selectors/auth.selectors';
import Loader from '../../loader';
import store from 'store2';
import Globals from '../../../global-functions';
import Cookies from 'js-cookie';
import { guestSignup } from '../../../services/user-service';

export interface InviteAccountProps {
  mode?: 'preview' | 'signup' | 'login';
  contentId?: string;
  hasAccount?: boolean | null;
}



export const InviteAccount: React.FC<InviteAccountProps> = ({ mode, contentId, hasAccount }) => {
  const dispatch = useDispatch();
  const authLoading = useAuthLoadingSelector();
  const isLoggedIn = useIsLoggedInSelector();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  if (mode === 'preview') {
    return (
      <div className="invite-account-preview">
        <div className="sub-text">

          { hasAccount === null && 'Loading...' }
          { hasAccount && 'You may have an account on weave. Login to your account for future collaborations & benefits.' }
          { hasAccount === false && 'Sign up to weave for future collaborations & benefits.' }
          { isLoggedIn && <>
            You're signed in. <div onClick={() => {
            Cookies.remove('access_token');
            Cookies.remove('userid');
            Cookies.remove('userdata');
            Cookies.remove('twitchdata');
            Cookies.remove('mixerdata');
            store('access_token', null);
            store('userid', null);
            Globals.logEvent('event', 'logout');
            window.location.reload();
          }}> Click here to Log out. </div>
          </>
          }

        </div>
      </div>
    );
  }

  if (authLoading) {
    return <Loader active="active" />
  }

  const passValueInto = (setter: any) => (event: any) =>  setter(event.target.value);

  const onSubmitAccountForm = async (event: any) => {
    event.preventDefault();


    if (mode === 'login') {
      dispatch(authActionCreators.createLoginAction({
        loginType: LoginType.Email,
        username: email,
        password: password,
        redirectUrl: '../overview'
      }));
    }

    if (mode === 'signup') {
      if (contentId) {
        const resp = await guestSignup({contentId, email, password});
        if(resp.success) {
          dispatch(authActionCreators.createLoginAction({
            loginType: LoginType.Email,
            username: email,
            password: password,
            redirectUrl: '../overview'
          }));
        }
      }
    }
  };

  const accountForm = (
    <form className="invite-account-form" onSubmit={onSubmitAccountForm}>
      <h5 className="sub-text">Email</h5>
      <input
        value={email}
        name="email"
        onChange={passValueInto(setEmail)}
        className="dark-input"
        placeholder={'you@email.com'}
        required
      />

      <h5 className="sub-text">Password</h5>
      <input
        value={password}
        name="password"
        onChange={passValueInto(setPassword)}
        className="dark-input"
        required
        type="password"
      />

      {mode === 'signup' && (
        <>
          <h5 className="sub-text">Confirm Password</h5>
          <input
            value={confirmPassword}
            name="confirmPassword"
            onChange={passValueInto(setConfirmPassword)}
            className="dark-input"
            required
            type="password"
          />
        </>
      )}


      {mode === 'signup' ? <>
          <h5 className="sub-text">
            Already have an account on weave? <Link replace={true} to="./login"> Login instead</Link>.
          </h5>
          <button className="plaid-btn" type="submit"> Sign up</button>
        </> :
        <>
          <h5 className="sub-text">
            Don't have an account? <Link replace={true} to="./signup"> Signup instead</Link>.
          </h5>
          <button className="plaid-btn" type="submit"> Login </button>
        </>
      }

    </form>
  );

  return <div className="invite-account">
    {mode === 'signup' && (
      <>
      <div className="signup-info">
        <h4>Why Weave?</h4>
        <div>
          <div className="sub-text">- Get paid instantly through Weave</div>
          <div className="sub-text">- Discover & connect with new brands</div>
          <div className="sub-text">- Get premium talent management support</div>
          <div className="sub-text">- Manage multiple collaborations in one place</div>
          <div className="sub-text">- Auto-fill your information for new opportunities</div>
        </div>
      </div>
      <div className="signup-info">
        <h4>Create Account</h4>
        <div className="sub-text">Signing up or logging into Weave allows your information to be saved for future collaborations.</div>
      </div>
      </>
    )}

    {accountForm}
  </div>;
};
