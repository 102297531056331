import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import InvoiceSummary from './invoice-summary.jsx'
const WEAVE_ADDRESS = '7403 Alverstone Ave, Los Angeles, CA 90045'

export default class InvoiceHeader extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    function formatAmount(amount){ return (<span><span className="sub-text invoice-dollar">$</span>{Globals.numberWithCommas(amount)}</span>) }
    var { invoice } = this.props
    var { id, name, email, amount, deliverables, notes, status, netTerms, invoiceOwner, address, dueDate } = invoice

    return (
      <div className="invoice-overview-module">
        <div className="invoice-overview-module-inner">
          <div className="module">
            <div className="row">
              <div className="col-sm-7 col-xs-6">
                <label className="sub-text">Invoice From</label>
                <h4>{invoiceOwner || 'Weave, Inc'}</h4>
                <p className="sub-text" style={{margin: 0}}>{WEAVE_ADDRESS}</p>
              </div>
              <div className="col-sm-5 col-xs-6">
                <div className="invoice-summary-amount">
                  <div className="invoice-summary-amount-bg"></div>
                  <div className="invoice-summary-amount-meta">
                    <label className="sub-text">Amount Due</label>
                    <h3 style={{fontSize: '32px', fontWeight: 800, marginTop: '10px'}}>{formatAmount(amount)}</h3>
                    <div className="invoice-due-date">
                      <div className="row row0">
                        <div className="invoice-date">
                          <div className="invoice-icon"><IconPack icon="calendar" color="#808080" /></div>
                          <div className="sub-text invoice-date-text">{moment(dueDate).format('MMM Do, YYYY')}</div>
                        </div>
                        <div className="invoice-remaining">
                          <div className="invoice-icon"><IconPack icon="document" color="#808080" /></div>
                          <div className="sub-text invoice-remaining-text">Net {netTerms}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
