import { createReducer } from 'typesafe-actions';
import { GetContentApprovalsAnonymousFail, GetContentApprovalsAnonymousResponse } from '../models/content.models';
import { Nullable } from '../../utils/utils';
import { contentActionCreators, ContentActions } from '../actions/content.actions';

type ContentApprovalsAnonymousState = {
  data: Nullable<GetContentApprovalsAnonymousResponse>;
  loading: boolean;
  hasError: boolean;
  error: Nullable<GetContentApprovalsAnonymousFail>;
};

const initialState: ContentApprovalsAnonymousState = Object.freeze({
  data: null,
  hasError: false,
  loading: false,
  error: null
});

export const getContentApprovalsAnonymousReducer = createReducer<ContentApprovalsAnonymousState, ContentActions>(
  initialState,
).handleAction(contentActionCreators.getContentApprovalsAnonymousAction, (state, action) => ({
  ...initialState,
  loading: true,
}))
  .handleAction(
    contentActionCreators.getContentApprovalsAnonymousSuccessAction, (state, action) => ({
      ...initialState,
      data: action.payload,
    }),
  )
  .handleAction(contentActionCreators.getContentApprovalsAnonymousFailAction, (state, action) => ({
    ...state,
    hasError: true,
    errorMessage: action.payload,
    loading: false
  }));

