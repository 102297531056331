import React from 'react';
import Globals from '../../global-functions.js';
import ShippingTrackingProductBar from './shipping-tracking-product-bar.jsx';
import SlideOutProgressTitle from '../slide-out/slide-out-progress-title.jsx';
import ShippingTrackingDisplay from './shipping-tracking-display.jsx';

export default class ShippingTrackingProduct extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  copyLink(type, e){
    console.log('copy link', type)
    var currentFocus = document.activeElement
    var target = document.getElementById(type)
    target.focus()
    target.setSelectionRange(0, target.value.length)

    var succeed
    try { succeed = document.execCommand("copy") }
    catch(e) { succeed = false }

    if (currentFocus && typeof currentFocus.focus === "function") { currentFocus.focus(); }
  }

  render() {
    var props = this.props, status = this.props.status, campaign = props.campaign ? props.campaign : {},
        content = campaign.content ? campaign.content : {}, shipping = content.shippingTracking ? content.shippingTracking : {}, offer = campaign.offer ? campaign.offer : {}

    var giftingDiscountCode
    if (content.discountCodes && Array.isArray(content.discountCodes)){
      content.discountCodes.forEach((d) => { if (!giftingDiscountCode && d.discountReason == 'gifting') { giftingDiscountCode = d } })
    }

    return (
      <div>
        <div className="shipping-tracking shipping-tracking-product">
          <div className="row row0 shipping-stages stages-4" style={{margin: '25px 0'}}>
            <ShippingTrackingProductBar status={status} animated={true} size="large" />
          </div>

          {/*<h3 style={{marginTop: '25px'}}>Status</h3>*/}
          <div className="row row0 shipping-meta" style={{marginBottom: '25px'}}>
            { status == 'brand_applied' && <SlideOutProgressTitle title="Awaiting Acceptance" description="The brand is reviewing your profile. You will be notified if you're accepted." /> }
            { status == 'brand_responded' && <SlideOutProgressTitle title="Awaiting Acceptance" description="The brand is reviewing your profile. You will be notified if you're accepted." /> }
            { status == 'brand_active' && offer.giftingType != 'discount' && <SlideOutProgressTitle title="Awaiting Gift Shipment" description="You will be notified once your gift package ships" /> }

            { status == 'brand_active' && offer.giftingType == 'discount' && giftingDiscountCode &&
              <div>
                <SlideOutProgressTitle title="Order Free Gift" />
                <p className="shipping-meta-desc" style={{margin: '0 0 25px 0'}}><span className="sub-text">Go to <a href={offer.offerWebsiteUrl} target="_blank"><b>{offer.offerWebsiteUrl}</b></a> and use your discount code to checkout out for free. Weave will update automatically once you purchase.</span></p>
                <input id="discount-free" className="dark-input" style={{fontWeight: 'bold', textAlign: 'center', fontSize: '21px', marginBottom: '25px'}} value={giftingDiscountCode.code} onClick={this.copyLink.bind(this, 'discount-free')} readOnly />
              </div>
            }

            { status == 'brand_shipped' &&
              <>
                { (!shipping || !shipping.trackingNumber) && <SlideOutProgressTitle title="Product approved for shipment" description="Your product has not been shipped yet, it will be updated here with shipping info once we have a tracking number." /> }
                {
                  shipping && shipping.trackingNumber &&
                  <>
                    <SlideOutProgressTitle title="Product on the way" description="Track your shipping progress here, it will update in real-time as your package travels to you." />
                    <h5 style={{fontWeight: '500', marginBottom: '25px'}}><span>Tracking Number:</span> <span style={{cursor: 'pointer'}}><b>{shipping.trackingNumber}</b></span></h5>
                    { shipping.shippingMessage && <ShippingTrackingDisplay shipping={shipping} /> }
                  </>
                }
              </>
            }

            { status == 'brand_received' && <SlideOutProgressTitle title="Post Content" description="Please create your content according to the guidelines set in the bounty. Include each of the required pieces below, and submit your post when it is up." /> }
            { status == 'brand_review' && <SlideOutProgressTitle title="Awaiting Brand Approval" description="Brand will either approve your content to complete bounty or suggest edits." /> }
            { status == 'brand_live' && <SlideOutProgressTitle title="Post is live" description="Awaiting brand approval of bounty completion." /> }
            { status == 'brand_completed' &&
              <div>
                <SlideOutProgressTitle title="Post Content" description="Please create your content according to the guidelines set in the bounty. Include each of the required pieces below, and submit your post when it is up." />
              </div>
            }

            { status == 'brand_completed' &&
              <>
                <div className="row row0">
                  <div className="col-xs-6">
                    <h2>$0.00</h2>
                    <h4 className="shipping-meta-title">Earnings</h4>
                  </div>
                  <div className="col-xs-6">
                    <h2>0</h2>
                    <h4 className="shipping-meta-title">Clicks</h4>
                  </div>

                  {/*<div className="col-md-4">

                  </div>*/}
                </div>
              </>
            }
          </div>

        </div>
      </div>
    )
  }
}
