import React, { useState, setTheme } from 'react';
import { Router } from 'react-router-dom';
import App from './components/app.jsx';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme } from './theme.js'
import { GlobalStyles } from './global-css.js'
import history from './components/history.js';
import store from 'store2'
import Cookies from 'js-cookie'

export default class Routes extends React.Component {

  constructor(props){
    super(props)
    // var theme = Cookies.get('themeMode')
    var theme = store('themeMode')
    if (!theme){ store('themeMode', 'light') }
    this.state = {
      theme: theme ? theme : 'light'
    }
  }

  componentDidMount(){

  }

  toggleTheme() {
    var theme = this.state.theme == 'light' ? 'dark' : 'light'
    this.setState({
      theme: theme
    })
    // Cookies.set('themeMode', theme)
    store('themeMode', theme)
  }

  render() {
    return (
      <>
        <Router history={history}>
          <ThemeProvider theme={this.state.theme === 'light' ? lightTheme : darkTheme}>
            <>
              <GlobalStyles />
              <App this={this} toggleTheme={this.toggleTheme} themeMode={this.state.theme} />
            </>
          </ThemeProvider>
        </Router>
      </>
    )
  }
}
