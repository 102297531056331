import React from 'react';
import './invite-compensation.scss';

export interface InviteCompensationProps {
  campaign?: any;
  mode: 'full' | 'preview';
}

export const InviteCompensation: React.FC<InviteCompensationProps> = ({campaign, mode}) => {
  const freeProductValue = campaign?.offer?.freeProductVal, content = campaign?.content ? campaign?.content : {}

  if (mode === 'preview') {
    return (
      <div>
        {content.paymentFlatRate > 0 && <div className="sub-text"><b>${content.paymentFlatRate}</b> payment</div>}
        {freeProductValue > 0 && <div className="sub-text">Up to <b>${freeProductValue}</b> of gifted product value</div>}
        {!!campaign?.offerAffiliateComm && <div className="sub-text"><b>{campaign?.offerAffiliateComm}</b> commission</div>}
        {
          content.paymentFlatRate <= 0 && freeProductValue <= 0 && !campaign?.offerAffiliateComm &&
          <div className="sub-text">No compensation specified yet</div>
        }
      </div>)
  }

  return <div className="invite-compensation-preview">

    {
      content.paymentFlatRate && <>
        <div className='payout-block'>
          <h2 className='payout'>${content.paymentFlatRate}</h2>
          <p className='sub-text'>PAYOUT</p>
        </div>
        <hr/>
      </>
    }

    {
      !!content?.paymentFlatRate && <div>
        <h4>Flat payment</h4>
        <p className='sub-text'>You'll receive <b>${content.paymentFlatRate}</b> in payment after all agreed content is live and approved</p>
        <hr/>
      </div>
    }

    {
      !!freeProductValue && <div>
        <h4>Product</h4>
        <p className='sub-text'>You may receive up to <b>${freeProductValue}</b> of product value as part of this collaboration</p>
        <hr/>
      </div>
    }

    {
      !!campaign?.offerAffiliateComm && <div>
        <h4>Affiliate Commission</h4>
        <p className='sub-text'>You will earn <b>{campaign?.offerAffiliateComm}</b> of sales you drive to the brand</p>
        <hr/>
      </div>
    }

    {
      !content.paymentFlatRate && <div>
        <h4>Payout</h4>
        <p className='sub-text'>No payout compensation has been specified</p>
      </div>
    }
  </div>
};
