import React from 'react';

export default class StripeCard extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var props = this.props
    var card = props.card
    var _scale = props.scale ? props.scale : 1,
        scale = `scale(${_scale})`,
        width = props.width ? props.width : `${_scale * 400}px`,
        height = props.height ? props.height : `${_scale * 250}px`,
        left = props.left ? props.left : `-${_scale * 400}px`,
        top = props.top ? props.top : `-${_scale * 250}px`

        // scale = .55
        // width: 220;
        // height: 137.5;
        //
        // left: -90;
        // top: -55.5;


    return (
      <div className="stripe-card-container" style={{width: `${width}`, height: `${height}`}}>
        <div className="stripe-card-wrapper" style={{transform: `${scale}`, top: `${top}`, left: `${left}`}}>
          <div className="stripe-card" onClick={this.props.getCard.bind(this.props.this, card.viewType == 'preview' ? 'reveal' : 'preview', card.type == 'virtual' ? true : false)}>
            <div className="card-top">
              <div className="card-security">
                <p>
                  <span className="card-label">CVC</span>
                  <span>{card.cvc}</span>
                </p>
                <p>
                  <span className="card-label">ZIP</span>
                  <span>{card.zip}</span>
                </p>
              </div>
            </div>

            <div className="chip">
              <div className="chip-line"></div>
              <div className="chip-line"></div>
              <div className="chip-line"></div>
              <div className="chip-line"></div>
              <div className="chip-main"></div>
            </div>

            <svg className="card-wave" viewBox="0 3.71 26.959 38.787" width="24" height="36" fill="white">
              <path d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"></path>
              <path d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"></path>
              <path d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"></path>
            </svg>

            <div className="card-bottom">
              <div className="card-number">
                <div className="card-number-section">{card.number.slice(0, 4)}</div>
                <div className="card-number-section">{card.number.slice(4, 8)}</div>
                <div className="card-number-section">{card.number.slice(8, 12)}</div>
                <div className="card-number-section">{card.last4}</div>
              </div>
              <div className="card-details">
                <p style={{marginBottom: '2px'}}>
                  <span className="card-label">EXP.</span>
                  <span>{card.exp_month}/{card.exp_year}</span>
                </p>
                <p>{card.name}</p>
              </div>

              <div className="visa-logo">
                <svg version="1.1" id="visa" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     width="47.834px" height="47.834px" viewBox="0 0 47.834 47.834" style={{enableBackground: 'new 0 0 47.834 47.834' }}>
                  <g>
                    <g>
                      <path d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                               c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                               c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                               M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                               c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                               c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                               l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                               C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                               C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                               c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                               h-3.888L19.153,16.8z"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
