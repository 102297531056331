import React, { useEffect, useState } from 'react';
import Globals from '../../../global-functions';
import { _getUploadParams, deleteContentApprovals, getPlatformByTypeId, ReviewContentTypes, } from '../../../services/content-review-service';
import { DropzoneInput } from '../../shared/dropzone-input';
import { ContentWithStatusRow } from './content-with-status-row';
import { useDispatch } from 'react-redux';
import { contentActionCreators } from '../../../redux/actions/content.actions';
import { useContentApprovalsAnonymousSelector, useCreateContentApprovalAnonymousSelector, useUpdateContentForReviewSelector } from '../../../redux/selectors/content.selectors';
import { StatusElement } from '../../invite/invite-deliverables/objective-element/objective-element'

// todo: Extract to separate file
interface ContentForReviewProps {
  campaign?: Object;
  objectives?: Array<object>;
  offerId: string;
  brandCampaignId: string;
  lockToTypeId?: string;
  reviewType?: string;
}

const _addPost = (params: Object) => $.ajax({ method: 'POST', url: '/api/Campaign/addPost', data: params });

export const ContentForReview = (props: ContentForReviewProps) => {
  const dispatch = useDispatch();
  const {data: anonymousContentApprovals, loading: getLoading} = useContentApprovalsAnonymousSelector();
  const {data: createData, loading: createLoading, error: createError} = useCreateContentApprovalAnonymousSelector();
  const {data: updateData, loading: updateLoading, error: updateError} = useUpdateContentForReviewSelector();

  const contentLoading = createLoading || getLoading || updateLoading;
  const [contentApprovals, setContentApprovals] = useState([]);
  const [childContentApprovals, setChildContentApprovals] = useState([]);
  const [reviewContentLink, setReviewContentLink] = useState('');
  const [reviewContentPlatform, setReviewContentPlatform] = useState('');
  const [reviewContentType, setReviewContentType] = useState('');
  const [reviewContentCaption, setReviewContentCaption] = useState('');
  const [reviewContentVideoTitle, setReviewContentVideoTitle] = useState('');
  const [reviewContentVideoCaption, setReviewContentVideoCaption] = useState('');
  const [reviewContentVideoThumbnail, setReviewContentVideoThumbnail] = useState('');
  const [postUrlTxt, setPostUrlTxt] = useState('');
  const [reviewContentError, setReviewContentError] = useState<string | null>();
  const [reviewContentLinkUploaded, setReviewContentLinkUploaded] = useState<boolean>(false);
  const [disableContentForReview, setDisableContentForReview] = useState<boolean>(false);
  const [uploadFileMode, setUploadFileMode] = useState<boolean>(false);
  const [addContentMode, setAddContentMode] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<any>(null);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [viewRejectedContent, setViewRejectedContent] = useState<any>(null);
  const [parentContentId, setParentContentId] = useState<string>('');
  const { lockToTypeId } = props;
  const [addCustomContentMode, setAddCustomContentMode] = useState(false);
  const { reviewType } = props


  const fetchData = () => {
    if (!props.brandCampaignId) { return; }
    dispatch(contentActionCreators.getContentApprovalsAnonymousAction({contentId: props.brandCampaignId}))
  }

  useEffect(() => {
    const isCustom = window.location.pathname.indexOf('custom') > -1;
    setAddCustomContentMode(isCustom);
    setAddContentMode(true);
  }, [window.location.pathname]);

  useEffect(() => {
    if (anonymousContentApprovals) {
      const parentContent = anonymousContentApprovals?.filter((c: any) => !c.parentInfluencerContentId);
      const childContent = anonymousContentApprovals?.filter((c: any) => !!c.parentInfluencerContentId);
      setContentApprovals(parentContent);
      setChildContentApprovals(childContent);
    }
  }, [anonymousContentApprovals]);

  useEffect(() => handleDataResponse(createData, createError, 'Failed to add content'), [createData, createError]);
  useEffect(() => handleDataResponse(updateData, updateError, 'Failed to edit content'), [updateData, updateError]);
  useEffect(() => fetchData(), [props.brandCampaignId]);
  useEffect(() => {
    if (lockToTypeId) {
      setAddContentMode(false);
      const platform = getPlatformByTypeId(lockToTypeId);

      if (platform) {
        setReviewContentPlatform(platform.name);
        setReviewContentType(platform.type);
      }
    }

  }, [lockToTypeId]);

  const handleDataResponse = (data: any, error: any, defaultErrMsg: string) => {
    if (data?.success) {
      resetContentReviewInfo();
      fetchData();
      window.location.reload(); //todo: remove
    } else if (error){
      const errMsg = error.errorMessage || error.message;
      errMsg ? setReviewContentError(errMsg) : setReviewContentError(defaultErrMsg);
    }
  }

  const addPost = (url: string) => {
    var type, id, url = url ? url : postUrlTxt;
    if (url.indexOf('instagram.com') > -1) {
      if (url.indexOf('instagram.com/p/') > -1) {
        id = url.split('instagram.com/p/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
        type = 'ig_post';
      } else if (url.indexOf('instagram.com/tv/') > -1) {
        id = url.split('instagram.com/tv/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
        type = 'ig_post';
      } else if (url.indexOf('instagram.com/stories/') > -1) {
        id = url.split('instagram.com/stories/')[1].split('/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
        type = 'ig_story';
      }
    } else if ( url.indexOf('youtube.com/watch?v=') > -1 || url.indexOf('youtu.be/') > -1 ) {
      type = 'youtube';
      if (url.indexOf('youtube.com/watch?v=') > -1) {
        id = url.split('youtube.com/watch?v=')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      } else if (url.indexOf('youtu.be/') > -1) {
        id = url.split('youtu.be/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      }
    } else if (url.indexOf('twitch.tv') > -1) {
      type = 'twitch';
      if (url.indexOf('twitch.tv/videos/') > -1) {
        id = url.split('twitch.tv/videos/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      }
    } else if (url.indexOf('tiktok.com') > -1) {
      type = 'tiktok';
      if (url.indexOf('m.tiktok.com/v/') > -1) {
        id = url.split('m.tiktok.com/v/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      } else if (url.indexOf('vm.tiktok.com/') > -1) {
        id = url.split('vm.tiktok.com/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      } else if (
        url.indexOf('tiktok.com/@') > -1 &&
        url.indexOf('/video/') > -1
      ) {
        id = url.split('/video/')[1].split('/')[0].split('?')[0].split('#')[0].split('&')[0];
      }
    }
    if (type && id) {
      let post = { type: type, id: id, url: url }
      _addPost({ post, contentId: props.brandCampaignId }).done((response: any) => { });
    }
  }

  const validateContentReviewInfo = () => {
    if (!reviewContentPlatform) { return 'Please select a platform' }

    if (reviewContentPlatform !== 'twitch') {
      if (!reviewContentType) { return 'Please select a content type' }
      if (!reviewContentLink) { return 'Please enter a content link' }

      switch (reviewContentType)  {
        case ReviewContentTypes.ig_reel:
        case ReviewContentTypes.ig_post: {
          if (reviewContentPlatform === 'instagram' && !reviewContentCaption) { return 'Please enter a caption' }
          return;
        }
        case ReviewContentTypes.ig_story:
          return;
        case ReviewContentTypes.tiktok_post: {
          if (!reviewContentCaption) { return 'Please enter a caption' }
          return;
        }
        case ReviewContentTypes.yt_dedicated: {
          if (!reviewContentVideoTitle) { return 'Please enter a video title' }
          else if (!reviewContentVideoCaption) { return 'Please enter a video caption' }
          else if (!reviewContentVideoThumbnail) { return 'Please enter a video thumbnail' }
          return;
        }
        case ReviewContentTypes.yt_mention: {
          if (!reviewContentVideoTitle) { return 'Please enter a video title' }
          else if (!reviewContentVideoCaption) { return 'Please enter a video caption' }
          else if (!reviewContentVideoThumbnail) { return 'Please enter a video thumbnail' }
          return;
        }
        default:
          return 'Invalid content type'
      }
    }
  }

  const makeRequestParams = (offerId: string, id?: string) => {
    let requestParams: any = {
      platformType: reviewContentPlatform,
      postType: reviewContentType,
      contentLink: reviewContentLink,
      offerId,
      contentId: props.brandCampaignId,
      ...(id ? {id} : {}),
      ...(parentContentId ? {parentInfluencerContentId: Number(parentContentId)} : {}),
      ...(props.brandCampaignId ? {brandCampaignId: props.brandCampaignId} : {}),
    }
    if (reviewContentType === 'ig_reel' || reviewContentType === 'ig_post' || reviewContentType === 'tiktok_post') {
      return { ...requestParams, caption: reviewContentCaption };
    }
    if (reviewContentType === 'ig_story') {
      return { ...requestParams, };
    }
    if (reviewContentType ===  'yt_dedicated' || reviewContentType ===  'yt_mention') {
      return {
        ...requestParams,
        videoTitle: reviewContentVideoTitle,
        videoCaption: reviewContentVideoCaption,
        videoThumbnail: reviewContentVideoThumbnail
      };
    }

    return requestParams;
  }

  const addContentForReview = () =>  {
    const errorMessage = validateContentReviewInfo();
    if (errorMessage) { setReviewContentError(errorMessage); }
    else {
      const requestParams: any = makeRequestParams(props.offerId);
      dispatch(contentActionCreators.createContentApprovalAnonymousAction(requestParams));
    }
  };

  const addChildReviewContent = (parentId: string) => {
    setAddContentMode(true);
    setParentContentId(parentId);
  }

  const viewContentDetails = (content: any) => {
    const { caption, contentLink, platformType, postType, videoCaption, videoThumbnail, videoTitle } = content;

    setViewMode(true);
    setAddContentMode(true);
    setReviewContentLink(contentLink);
    setReviewContentPlatform(platformType);
    setReviewContentType(postType);
    setReviewContentCaption(caption);
    setReviewContentVideoTitle(videoTitle);
    setReviewContentVideoCaption(videoCaption);
    setReviewContentVideoThumbnail(videoThumbnail);
  }

  const editContentForReview = () => {
    if (!editContent) { setReviewContentError('No content to edit'); return; }
    const errorMessage = validateContentReviewInfo();

    if (errorMessage) { setReviewContentError(errorMessage); }
    else {
      const { id, offerId } = editContent;
      const requestParams: any = makeRequestParams(offerId, id);
      dispatch(contentActionCreators.updateContentForReviewAction(requestParams));
    }
  }

  const selectPlatform = (reviewContentPlatform: string) => {
    setReviewContentPlatform(reviewContentPlatform);
    setReviewContentLinkUploaded(false);
    setReviewContentType('');
    setReviewContentCaption('');
    setReviewContentVideoTitle('');
    setReviewContentVideoCaption('');
    setReviewContentVideoThumbnail('');
    setReviewContentError(null);
    setUploadFileMode(false);
  }

  const selectContentToEdit = (content: any) => {
    const { caption, contentLink, platformType, postType, videoCaption, videoThumbnail, videoTitle } = content;

    setAddContentMode(true);
    setEditContent(content);
    setReviewContentLink(contentLink);
    setReviewContentPlatform(platformType);
    setReviewContentType(postType);
    setReviewContentCaption(caption);
    setReviewContentVideoTitle(videoTitle);
    setReviewContentVideoCaption(videoCaption);
    setReviewContentVideoThumbnail(videoThumbnail);
  }

  const resetContentReviewInfo = () => {
    setAddContentMode(false);
    setReviewContentLink('');
    setReviewContentLinkUploaded(false);
    setReviewContentCaption('');
    setReviewContentVideoTitle('');
    setReviewContentVideoCaption('');
    setReviewContentVideoThumbnail('');
    setParentContentId('');
    setReviewContentError(null);
    setUploadFileMode(false);
    setEditContent(null);
    // setViewMode(false);
  }

  const deleteContent = (id: string) => {
    setDisableContentForReview(true);
    deleteContentApprovals(id).done((response: any) => {
      fetchData();
      setDisableContentForReview(false);
    });
  }

  const PLATFORM_NAMES = {
    'instagram': 'Instagram',
    'youtube': 'YouTube',
    'tiktok': 'TikTok',
    'twitch': 'Twitch',
    'ig_post': 'Instagram Feed Post',
    'ig_story': 'Instagram Story Frame',
    'ig_reel': 'Instagram Reel',
    'yt_dedicated': 'YouTube Dedicated Video',
    'yt_mention': 'YouTube Mention Video',
    'yt_dedicated_video': 'YouTube Dedicated Video',
    'yt_mention_video': 'YouTube Mention Video',
    'tiktok_post': 'TikTok Video',
    'twitch_mention': 'Twitch Livestream Mention'
  }

  const _contentApprovals = (contentApprovals || [])
    .filter((contentApproval: any) => {
      if (lockToTypeId) { return contentApproval?.platformType === reviewContentPlatform && contentApproval?.postType === reviewContentType }
      return true;
    })
  var contentApproved, contentRejected, postedLive
  (_contentApprovals).forEach((c) => { if (c.status == 'APPROVED') { contentApproved = true } else if (c.status == 'REJECTED') { contentRejected = true } })
  const contentReviewActive = (reviewType == 'all' || reviewType == 'paid')
  if (!contentReviewActive) { contentApproved = true }
  console.log(props.objectives)
  var objective = props && props.objectives ? props.objectives.filter((o) => { if (o.typeId == props.lockToTypeId){ return o } })[0] : []
  objective = objective ? objective : {}

  return (
    <div className={`content-for-review ${disableContentForReview ? 'disabled' : ''}`}>
      <div className="row row0">
        <div className="invite-block-icon" style={{background: Globals.platformColor(reviewContentPlatform), marginTop: '0'}}>
          <img className="invite-block-img" src={Globals.icon(reviewContentPlatform, 'white')} />
        </div>
        <div>
          <h3>{PLATFORM_NAMES[reviewContentType]}</h3>
          <div className="deliverable-description sub-text" style={{paddingLeft: '42px', fontWeight: 400}}> {objective.description } </div>
          <div className="invite-deliverables">
            <div className="invite-deliverable">
              <div className="statuses" style={{paddingLeft: '42px'}}>
                { contentReviewActive && <StatusElement text="Content Review" status={contentApproved ? 'APPROVED' : (contentRejected ? 'REJECTED' : 'PENDING')}/> }
                <StatusElement text="Posted Live" status={postedLive? 'APPROVED': 'PENDING'}/>
              </div>
            </div>
          </div>
        </div>

        <hr />
      </div>

      {
        contentApproved &&
        <div>
          <div className="row row0">
            <h3>Upload Social Post</h3>
            <p className="sub-text">Please paste the url to your live social post for tracking.</p>
          </div>
          <div className="row row0">
            <input className="dark-input" placeholder="Paste link to social post..." value={postUrlTxt} onChange={(event) => { setPostUrlTxt(event.target.value); }} />
          </div>
          <div className="add-content-link">
            <button className="btn-action btn-primary" onClick={() => addPost(postUrlTxt)} style={{marginTop: 0}}>Save Social Post Url</button>
          </div>

          <hr />
        </div>
      }

      {
        contentReviewActive && !contentApproved &&
        <div>
          <h3>{addCustomContentMode ? 'Custom' : ''} Content Submission Review</h3>
          {!addCustomContentMode && <p className="sub-text">Submit content to be approved by the brand before posting live.</p>}
          {/*<pre> DEBUG: { reviewContentPlatform } {reviewContentType}. contentID = { props.brandCampaignId } offerId = {props.offerId}</pre>*/}
        </div>
      }

      {
        contentReviewActive && contentApproved &&
        <div>
          <h3>
            <span>Content Review</span>
            {contentApproved ?
              <span style={{fontSize: '14px', color: '#71b54d', fontWeight: '400', marginLeft: '12px'}}>Completed
                <div style={{ position: 'relative', display: 'inline-block', width: '15px', height: '15px' }}>
                  <div className="check green" style={{position: 'absolute', width: '15px', height: '15px', borderRadius: '50%', top: '3px', left: '6px' }}>
                    <img src={Globals.icon('ok', 'white')} style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto', width: '8px'}} />
                  </div>
                </div>
              </span> : ''}
          </h3>
          {!addCustomContentMode && <p className="sub-text">Your reviewed content is displayed below</p>}
          {/*<pre> DEBUG: { reviewContentPlatform } {reviewContentType}. contentID = { props.brandCampaignId } offerId = {props.offerId}</pre>*/}
        </div>
      }

      { contentReviewActive && _contentApprovals && !addContentMode && (
        <div className="slide-out-objectives">
          { _contentApprovals.sort((a: any, b: any) => { return +new Date(b.updatedAt) - +new Date(a.updatedAt) }).map((contentForReview: any) => {
            return <div key={contentForReview.contentLink}>
              <ContentWithStatusRow contentForReview={contentForReview}
                                    deleteContent={deleteContent}
                                    selectContentToEdit={selectContentToEdit}
                                    onSubmitNewContentClick={() => addChildReviewContent(contentForReview.parentInfluencerContentId || contentForReview.id)}
                                    onCloseClick={() => setViewRejectedContent(null)}
                                    onApprovedLinkClick={() => viewContentDetails(contentForReview)}
                                    onRejectedLinkClick={() => setViewRejectedContent(contentForReview)}
                                    onPendingLinkClick={() => selectContentToEdit(contentForReview)}
                                    viewRejectedContent={viewRejectedContent}
              />
              <div style={{ marginLeft: '25px'}}>
                {
                  childContentApprovals
                    .filter((childContent: any) => childContent.parentInfluencerContentId === contentForReview.id)
                    .map((childContent: any) =>
                      <ContentWithStatusRow key={childContent.contentLink}
                                            contentForReview={childContent}
                                            deleteContent={deleteContent}
                                            selectContentToEdit={selectContentToEdit}
                                            onSubmitNewContentClick={() => addChildReviewContent(childContent.parentInfluencerContentId)}
                                            onCloseClick={() => setViewRejectedContent(null)}
                                            onApprovedLinkClick={() => viewContentDetails(childContent)}
                                            onRejectedLinkClick={() => setViewRejectedContent(childContent)}
                                            onPendingLinkClick={() => selectContentToEdit(childContent)}
                                            viewRejectedContent={viewRejectedContent}
                      />)
                }
              </div>
            </div>
          })}
        </div>
      )}

      {
        contentReviewActive &&
        !contentApproved && !addContentMode && !viewRejectedContent && !addCustomContentMode &&
        <div className="add-content-link" onClick={() => setAddContentMode(true)}>
          <button className="btn-action btn-primary">Add Content</button>
        </div>
      }

      {
        contentReviewActive &&
        addContentMode &&
        <>
          { !lockToTypeId && <div>
            <h5 className="sub-text">Platform</h5>
            <div className={`platform-grid ${viewMode ? 'disabled' : ''}`}>
              <button className={`platform-tile ${reviewContentPlatform === 'instagram' ? 'active' : ''}`} onClick={() => selectPlatform('instagram')}>
                <img src={Globals.icon('instagram')} />
              </button>
              <button className={`platform-tile ${reviewContentPlatform === 'youtube' ? 'active' : ''}`} onClick={() => selectPlatform('youtube')}>
                <img src={Globals.icon('youtube')} />
              </button>
              <button className={`platform-tile ${reviewContentPlatform === 'tiktok' ? 'active' : ''}`} onClick={() => selectPlatform('tiktok')}>
                <img src={Globals.icon('tiktok')} />
              </button>
              <button className={`platform-tile ${reviewContentPlatform === 'twitch' ? 'active' : ''}`} onClick={() => selectPlatform('twitch')}>
                <img src={Globals.icon('twitch')} />
              </button>
            </div>
          </div> }
          {
            reviewContentPlatform === 'twitch'
              ? <p className="twitch-msg">Twitch content does not need to be uploaded for review since you will be
                streaming live, please follow all guidelines in the bounty</p>
              : <>
                {
                  !!reviewContentPlatform && !lockToTypeId &&
                  <div>
                    <h5 className="sub-text">Content Type</h5>
                    <div className={`platform-grid content-type-grid ${viewMode ? 'disabled' : ''}`}>
                      {
                        reviewContentPlatform === 'instagram' && (<>
                          <button
                            className={`content-type-tile ${reviewContentType === 'ig_post' ? 'active' : ''}`}
                            onClick={() => { setReviewContentType('ig_post'); setReviewContentError(null); }}>Feed Post</button>
                          <button
                            className={`content-type-tile ${reviewContentType === 'ig_story' ? 'active' : ''}`}
                            onClick={() => { setReviewContentType('ig_story'); setReviewContentError(null); }}>Story</button>
                        </>)
                      }
                      {reviewContentPlatform === 'youtube' && (
                        <button
                          className={`content-type-tile ${(reviewContentType === 'yt_dedicated' || reviewContentType === 'yt_mention') ? 'active' : ''}`}
                          onClick={() => { setReviewContentType(reviewContentType); setReviewContentError(null); }}>Video</button>
                      )}
                      {
                        reviewContentPlatform !== 'tiktok' &&
                        <button
                          className={`content-type-tile ${reviewContentType === 'ig_reel' ? 'active' : ''}`}
                          onClick={() => { setReviewContentType('ig_reel'); setReviewContentError(null); }}>Reel</button>
                      }
                      {reviewContentPlatform === 'tiktok' && (
                        <button
                          className={`content-type-tile ${reviewContentType === 'tiktok_post' ? 'active' : ''}`}
                          onClick={() => { setReviewContentType('tiktok_post'); setReviewContentError(null); }}>Video</button>
                      )}
                    </div>
                  </div>
                }

                {
                  (reviewContentType === 'ig_story'
                    || reviewContentType === 'ig_reel'
                    || reviewContentType === 'tiktok_post'
                    || reviewContentType === 'ig_post') &&
                  <div>
                    <DropzoneInput contentLink={reviewContentLink}
                                   label={`Paste url to content or upload file`}
                                   reviewContentType={reviewContentType}
                                   reviewContentPlatform={reviewContentPlatform}
                                   hasPreviewMode={false}
                                   disabled={disableContentForReview}
                                   viewMode={viewMode}
                                   getUploadParams={_getUploadParams}
                                   uploadFileMode={uploadFileMode}
                                   setUploadFileMode={setUploadFileMode}
                                   onDropzoneChangeStatus={({ remove, xhr }, status) => {
                                     if (status === 'preparing') {
                                       setDisableContentForReview(true);
                                     }
                                     if (status === 'done') {
                                       if (xhr) {
                                         setReviewContentLink(JSON.parse(xhr.response).uploadedUrl);
                                         setReviewContentLinkUploaded(true);
                                         setDisableContentForReview(false);
                                       }
                                     }
                                   }}
                                   inputDisabled={reviewContentLinkUploaded || viewMode}
                                   onInputChange={(event) => {
                                     setReviewContentLink(event.target.value);
                                     setReviewContentError(null);
                                   }}
                                   onLinkClick={() => {
                                     setUploadFileMode(false);
                                     setReviewContentLinkUploaded(false);
                                   }}/>
                  </div>
                }

                {
                  (reviewContentPlatform === 'instagram' && reviewContentType && reviewContentType !== 'ig_story')
                  || reviewContentType === 'tiktok_post'
                    ? (<div>
                      <h5 className="sub-text">Caption</h5>
                      <textarea
                        className="dark-input"
                        style={{padding: '10px', marginBottom: '20px'}}
                        value={reviewContentCaption}
                        disabled={viewMode}
                        onChange={(event) => { setReviewContentCaption(event.target.value); setReviewContentError(null) }}
                      />
                    </div>)
                    : null
                }

                {
                  (reviewContentType === 'yt_dedicated' || reviewContentType === 'yt_mention') && <div>
                    <h5 className="sub-text">YouTube Unlisted Link</h5>
                    <input
                      className="dark-input"
                      value={reviewContentLink}
                      disabled={viewMode}
                      onChange={(event) => { setReviewContentLink(event.target.value); setReviewContentError(null); }}
                    />

                    <h5 className="sub-text">YouTube Video Title</h5>
                    <input
                      className="dark-input"
                      value={reviewContentVideoTitle}
                      disabled={viewMode}
                      onChange={(event) => { setReviewContentVideoTitle(event.target.value); setReviewContentError(null); }}
                    />

                    <h5 className="sub-text">YouTube Video Description</h5>
                    <textarea
                      className="dark-input"
                      style={{padding: '10px', marginBottom: '20px'}}
                      value={reviewContentVideoCaption}
                      disabled={viewMode}
                      onChange={(event) => { setReviewContentVideoCaption(event.target.value); setReviewContentError(null); }}
                    />

                    <DropzoneInput contentLink={reviewContentVideoThumbnail}
                                   hasPreviewMode={false}
                                   label={`Paste url to video thumbnail or upload file`}
                                   disabled={disableContentForReview}
                                   viewMode={viewMode}
                                   uploadFileMode={uploadFileMode}
                                   setUploadFileMode={setUploadFileMode}
                                   onDropzoneChangeStatus={({remove, xhr}, status) => {
                                     if (status === 'preparing') {
                                       setDisableContentForReview(true);
                                     }
                                     if (status === 'done') {
                                       if (xhr) {
                                         setReviewContentVideoThumbnail(JSON.parse(xhr.response).uploadedUrl);
                                         setDisableContentForReview(false);
                                       }
                                     }
                                   }}
                                   inputDisabled={viewMode}
                                   onInputChange={(event) => { setReviewContentVideoThumbnail(event.target.value); setReviewContentError(null); }}
                                   onLinkClick={() => { setUploadFileMode(false); setReviewContentVideoThumbnail(''); }}
                                />
                  </div>
                }

                {!!reviewContentError && (<p className="error-msg">{reviewContentError}</p>)}
              </>
          }
          {
            viewMode
              ? <button className="add-content" onClick={() => resetContentReviewInfo()}>Close</button>
              : <div className="actions">
                {
                  editContent
                    ? <button className="add-content green solid full" disabled={contentLoading || reviewContentPlatform === 'twitch'} onClick={() => editContentForReview()}>Save</button>
                    : <button className="add-content green solid full" disabled={contentLoading || reviewContentPlatform === 'twitch'} onClick={() => addContentForReview()}>Submit Content</button>
                }
                {<button className="add-content" disabled={contentLoading} onClick={() => resetContentReviewInfo()}>Cancel</button>}
            </div>
          }
        </>
      }
    </div>
  );
}
