import React from 'react';
import FacebookLogin from '../../dependencies/facebook-login.js';

export default class InvitationSocials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  youtubeLogin() {
    // Globals.logEvent('click', 'youtubeLogin')
    $.ajax({
      method: 'GET',
      url: '/api/YouTube/authUrl',
      data: {},
    }).done((data) => {
      location.replace(data);
    });
  }

  twitchLogin() {
    // Globals.logEvent('click', 'youtubeLogin')
    $.ajax({
      method: 'GET',
      url: '/api/Twitch/login',
      data: {},
    }).done((data) => {
      location.replace(data);
    });
  }

  storeInstagramToken(response) {
    this._storeInstagramToken(response.accessToken).done((response) => {
      this.props.refreshUser();
    });
  }

  _storeInstagramToken(access_token) {
    return $.ajax({
      method: 'POST',
      url: '/api/Instagram/storeToken',
      data: {
        access_token: access_token,
      },
    });
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div className="row row0">
          <div className="col-md-12" style={{ margin: '10px 0', padding: '0' }}>
            <div>
              <FacebookLogin
                appId="520203271980535"
                autoLoad={false}
                scope="public_profile,instagram_basic,pages_read_engagement,pages_show_list,instagram_manage_insights,instagram_manage_comments"
                callback={this.storeInstagramToken.bind(this)}
                render={(renderProps) => (
                  <a onClick={renderProps.onClick}>
                    <div
                      className="integration-btn"
                      role="button"
                      style={{ backgroundColor: '#dc4465' }}
                    >
                      <div className="integration-btn-text-container">
                        <div className="integration-btn-text">
                          <div className="integration-btn-text-left">
                            <div className="integration-btn-img-container">
                              <div
                                className="integration-btn-img"
                                style={{
                                  backgroundImage:
                                    'url(/images/instagram-icon.svg)',
                                }}
                              ></div>
                            </div>
                            <span className="integration-btn-title">
                              Instagram
                            </span>
                            <div
                              className="integration-btn-sub"
                              style={{ display: 'none' }}
                            >
                              Stream games and make money
                            </div>
                          </div>
                          <div className="integration-btn-text-right">
                            <button>Login</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
              />
            </div>
          </div>

          <div className="col-md-12" style={{ margin: '10px 0', padding: '0' }}>
            <div>
              <a onClick={this.youtubeLogin.bind(this)}>
                <div
                  className="integration-btn"
                  role="button"
                >
                  <div className="integration-btn-text-container">
                    <div className="integration-btn-text">
                      <div className="integration-btn-text-left">
                        <div className="integration-btn-img-container">
                          <div
                            className="integration-btn-img"
                            style={{
                              backgroundImage: 'url(/images/youtube-icon.svg)',
                            }}
                          ></div>
                        </div>
                        <span className="integration-btn-title">YouTube</span>
                        <div
                          className="integration-btn-sub"
                          style={{ display: 'none' }}
                        >
                          Stream games and make money
                        </div>
                      </div>
                      <div className="integration-btn-text-right">
                        <button>Login</button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div className="col-md-12" style={{ margin: '10px 0', padding: '0' }}>
            <div>
              <a onClick={this.twitchLogin.bind(this)}>
                <div
                  className="integration-btn"
                  role="button"
                  style={{ backgroundColor: '#6441a4' }}
                >
                  <div className="integration-btn-text-container">
                    <div className="integration-btn-text">
                      <div className="integration-btn-text-left">
                        <div className="integration-btn-img-container">
                          <div
                            className="integration-btn-img"
                            style={{
                              backgroundImage:
                                'url(/images/twitch-logo-white.svg)',
                            }}
                          ></div>
                        </div>
                        <span className="integration-btn-title">Twitch</span>
                        <div
                          className="integration-btn-sub"
                          style={{ display: 'none' }}
                        >
                          Stream games and make money
                        </div>
                      </div>
                      <div className="integration-btn-text-right">
                        <button>Login</button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
