import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class InvoiceAddDeliverable extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      type: '',
      description: '',
      quantity: 1
    }
  }

  componentDidMount(){

  }

  onChange(type, e){
    if (type == 'type') { this.setState({ [type]: e }) }
    else { this.setState({ [type]: e.target.value }) }
  }

  resetFields(){ this.setState({ type: '', description: '', quantity: 1 }) }

  addDeliverable(){
    var { type, description, quantity } = this.state
    var obj = { id: Globals.uuid, type, description, quantity }
    this.props.addDeliverable(obj)
    this.resetFields();
  }

  toggleAddDeliverable(){ this.resetFields(); this.props.toggleAddDeliverable() }

  render() {
    var options = [{type:'tiktok',icon:true},{type:'instagram',icon:true},{type:'youtube',icon:true},{type:'twitch',icon:true},{type:'twitter',icon:true},{type:'Whitelisting',text:true},{type:'Content',text:true},{type:'Other',text:true}]
    var { type, description, quantity } = this.state

    return (
      <div className="invoice-add-deliverable">
        <div className="row row0">
          <div className="row row0">
            <label>Deliverable Type</label>
          </div>
          <div className="deliverable-options">
            { options.map((o) => {
              var deliverableType = o.type.toLowerCase(), selected = type == deliverableType ? true : false
              return (
                <div key={`deliverable-${deliverableType}`} className={`deliverable-option deliverable-${deliverableType} ${selected ? 'selected' : ''}`} onClick={this.onChange.bind(this, 'type', deliverableType)}>
                  { o.icon && <img src={Globals.icon(o.type, selected ? 'white' : 'black')} /> }
                  { o.text && <span style={{color: selected ? 'white' : 'black'}}>{o.type}</span> }
                </div>
              )
            })}
          </div>
        </div>
        <div className="row" style={{marginTop: '25px'}}>
          <div className="col-xs-10">
            <label>Description</label>
            <input className="dark-input" value={description} placeholder="Enter description of deliverable..." onChange={this.onChange.bind(this, 'description')} />
          </div>
          <div className="col-xs-2">
            <label>Quantity</label>
            <input className="dark-input" value={quantity} onChange={this.onChange.bind(this, 'quantity')} />
          </div>
        </div>
        <div className="row row0">
          <button className="btn-action btn-primary" style={{margin: '0 8px 0 0', float: 'left'}} onClick={this.addDeliverable.bind(this)}>Save</button>
          <button className="btn-action btn-secondary" style={{margin: '0', float: 'left'}} onClick={this.toggleAddDeliverable.bind(this)}>Cancel</button>
        </div>
      </div>
    )
  }
}
