import React, { useEffect, useRef, useState, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import Globals from '../global-functions';
import { IsDev } from './isDev';
import ReactSlider from 'react-slider'

declare const $: any;

export interface Rates {
  igPost?: string;
  igStory?: string;
  igReel?: string;
  igTv?: string;
  ytDedicated?: string;
  ytMention?: string;
  ytShort?: string;
  tiktokPost?: string;
  twitchMention?: string;
  twitchSponsor?: string;
}

interface Rate {
  id: number;
  influencerId?: string;
  userid?: string;
  type?: string;
  cpm?: number;
  minViews?: number;
  maxViews?: number;
  baseCost?: number;
  platform?: string;
  name?: string;
  createdAt?: Date;
  updatedAt?: Date;
  description?: string;
}

const _getMyRates = () => $.ajax({ method: 'GET', url: '/api/me/rates', });
const _getSubmissions = () => $.ajax({ method: 'GET', url: '/api/Submission/getAll', });
const _getRateDynamic = () => $.ajax({ method: 'GET', url: '/api/RateDynamic/getAll', });
// const _saveRateDynamic = (data as any) => $.ajax({ method: 'POST', url: '/api/RateDynamic/save', data: data });
const _getSocials = () => $.ajax({ method: 'GET', url: '/api/me/socials', data: {}, });

const _setMyRates = (rates: Rates) => {
  const {
    igPost,
    igStory,
    igReel,
    igTv,
    ytDedicated,
    ytMention,
    ytShort,
    tiktokPost,
    twitchMention,
    twitchSponsor,
  } = rates;

  return $.ajax({
    method: 'POST',
    url: '/api/me/rates',
    data: {
      ...(igPost && { igPost }),
      ...(igStory && { igStory }),
      ...(igReel && { igReel }),
      ...(igTv && { igTv }),
      ...(ytDedicated && { ytDedicated }),
      ...(ytMention && { ytMention }),
      ...(ytShort && { ytShort }),
      ...(tiktokPost && { tiktokPost }),
      ...(twitchMention && { twitchMention }),
      ...(twitchSponsor && { twitchSponsor }),
    },
  });
};

const initialState = Object.freeze({
  rates: {
    igPost: '',
    igStory: '',
    igReel: '',
    igTv: '',
    ytDedicated: '',
    ytMention: '',
    ytShort: '',
    tiktokPost: '',
    twitchMention: '',
    twitchSponsor: '',
  },
  rateDynamic: [],
  socialMetrics: {
    YoutubeUser: '',
    InstagramUser: '',
    TwitchUser: '',
    TiktokUser: '',
  }
});

export const AccountRatesForm = () => {
  const formRef = useRef<HTMLFormElement>();
  const [form, setForm] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingRate, setEditRate] = useState({});
  const [rateDynamic, setRateDynamic] = useState([] as any[]);
  const [submissions, setSubmissions] = useState([] as any[]);

  /***
   * As instructed on slack 06/18/2021 - we are to assume that a user always
   * has an instagramUser. If that's not the case, This form will not show up.
   */
  const [invalidUser, setInvalidUser] = useState(false);

  useEffect(() => { loadData(); }, []);

  const loadData = () => {
    setLoading(true);

    return Promise.all([
      _getMyRates(),
      _getRateDynamic(),
      _getSubmissions()
      // _getSocials(),
    ])
      .then(([
        ratesResponse,
        rateDynamicResponse,
        submissionsResponse
        // socialsResponse,
       ]) => {
        const rates: Rates = ratesResponse.rates || {};
        const socialMetrics: any =  {};
        setForm({
          rates: {
            igPost: rates?.igPost || '',
            igStory: rates?.igStory || '',
            igReel: rates?.igReel || '',
            igTv: rates?.igTv || '',
            ytDedicated: rates?.ytDedicated || '',
            ytMention: rates?.ytMention || '',
            ytShort: rates?.ytShort || '',
            tiktokPost: rates?.tiktokPost || '',
            twitchMention: rates?.twitchMention || '',
            twitchSponsor: rates?.twitchSponsor || '',
          },
          socialMetrics
        });
        setRateDynamic(rateDynamicResponse.rates)
        setSubmissions(submissionsResponse)
      }).finally(() => { setLoading(false); }).catch(() => { setInvalidUser(true); })
  };

  const saveForm = () => { return Promise.all([ _setMyRates(form.rates), ]); };
  const onRateChangeInput = (field: string, value: string) => { setForm({ ...form, rates: { ...form.rates, [field]: value, }, }); };
  const onSocialChangeInput = (field: string, value: string) => { setForm({ ...form, socialMetrics: { ...form.socialMetrics, [field]: value, }, }); };

  const updateRates = async (event: Event) => {
    setSaving(true);
    event.preventDefault();
    try { await saveForm(); } catch (e) {}
    setSaving(false);
    await loadData();
  };

  const editRate = async (rate: Rate) => {
    // console.log('rate')
    // console.log(rate)
    if (editingRate.id == rate.id) { rate = {} }
    setEditRate(rate)
  }

  const onChangeInput = async (event: Event, type: string) => {
    var obj = { ...editingRate }
    obj[type] = Number(event.target.value)
    console.log(obj)
    setEditRate(obj)
  }

  const saveRateDynamic = async () => {
    $.ajax({ method: 'POST', url: '/api/RateDynamic/save', data: editingRate }).done((response) => {
      setEditRate({})
      _getRateDynamic().done((response) => { setRateDynamic(response.rates) })
    })
  }

  // if (invalidUser) {
  //   return <div> Please connect your instagram to add your rates. </div>
  // }

  return (

    <div className={'rates-form'}>
      <div className="row row0" style={{marginBottom: '15px'}}>
        <h3
          style={{ margin: '20px 0px 15px', padding: 0, fontSize: '20px', textTransform: 'initial' }}>Collaboration Rates</h3>
        <p className="sub-text">Specify your general rates for collaborations on each type of social post. This helps Weave and brands better understand your rates before reaching out for collaborations. If you need help pricing your content, feel free to reach out to us in the chat or email your Weave talent manager <b>logan@weavesocial.com</b></p>
      </div>

      <form
        className="module"
        ref={formRef as any}
        onSubmit={(event) => updateRates(event)}
      >
        <div className={'row rates'}>
          <div className="col-md-6">
            <h4><img src={Globals.icon('youtube')} style={{height: '18px', margin: '-3px 3px 0 0'}} /> Youtube</h4>

            <h5 className="sub-text">1 Dedicated Youtube Video</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.ytDedicated}
              name="ytDedicated"
              onValueChange={(ev) => onRateChangeInput('ytDedicated', ev.value)}
              className="dark-input"
            />

            <h5 className="sub-text">1 Youtube Video Mention (90+ secs)</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.ytMention}
              name="ytMention"
              onValueChange={(ev) => onRateChangeInput('ytMention', ev.value)}
              className="dark-input"
            />
          </div>

          <div className="col-md-6">
            <h4><img src={Globals.icon('instagram')} style={{height: '18px', margin: '-3px 3px 0 0'}} /> Instagram</h4>

            <h5 className="sub-text">1 Instagram Feed Post</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.igPost}
              name="igPost"
              onValueChange={(ev) => onRateChangeInput('igPost', ev.value)}
              className="dark-input"
            />

            <h5 className="sub-text">1 Instagram Story</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.igStory}
              name="igStory"
              onValueChange={(ev) => onRateChangeInput('igStory', ev.value)}
              className="dark-input"
            />
          </div>

          <div className="col-md-6">
            <h4><img src={Globals.icon('twitch')} style={{height: '18px', margin: '-3px 3px 0 0'}} /> Twitch</h4>

            <h5 className="sub-text">5+ min Twitch stream mention</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.twitchMention}
              name="twitchMention"
              onValueChange={(ev) =>
                onRateChangeInput('twitchMention', ev.value)
              }
              className="dark-input"
            />
          </div>

          <div className="col-md-6">
            <h4><img src={Globals.icon('tiktok')} style={{height: '18px', margin: '-3px 3px 0 0'}} /> TikTok</h4>

            <h5 className="sub-text">1 Tiktok Video</h5>
            <NumberFormat
              prefix={'$'}
              value={form.rates.tiktokPost}
              name="tiktokPost"
              onValueChange={(ev) => onRateChangeInput('tiktokPost', ev.value)}
              className="dark-input"
            />
          </div>
        </div>

        <div className="row" style={{ paddingLeft: '28px', paddingRight: '28px' }}>
          <button className={`btn-action btn-primary ${loading || saving ? 'disabled' : ''}`} type={'submit'} style={{ marginLeft: '12px' }}>
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>

      {/*<IsDev>
        <div className="row row0" style={{marginTop: '15px'}}>
          {
            rateDynamic.map((rate) => {
              return (
                <div className="module" style={{overflow: 'hidden'}}>
                  <div onClick={(ev) => editRate(rate)}>
                    <div style={{position: 'absolute',width: '12px',height: '100%',top: 0,left: 0, backgroundImage: `${Globals.platformColor(rate.platform)}`, backgroundColor: `${Globals.platformColor(rate.platform)}`}}></div>
                    <div className="row row0" style={{marginLeft: '4px', cursor: 'pointer'}}>
                      <div className="col-xs-9">
                        <div className="row row0">
                          <div style={{position: 'relative', display: 'inline-block', float: 'left', marginRight: '12px', height: '34px', width: '24px'}}>
                            <div><img src={Globals.icon(rate.platform)} style={{position: 'absolute', top: 0, bottom: 0, margin: 'auto', width: '24px'}} /></div>
                          </div>
                          <div style={{display: 'inline-block', float: 'left', marginRight: '15px'}}>
                            <h4 style={{fontWeight: 700, margin: 0}}>{rate.name}</h4>
                            <p className="sub-text" style={{margin: 0}}>{rate.description}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-3" style={{textAlign: 'right'}}>
                        <h4 style={{fontWeight: 700, margin: '10px 0 0 0'}}>{`$${rate.baseCost} + $${rate.cpm} CPM`}</h4>
                      </div>
                    </div>
                  </div>

                  {
                    editingRate &&
                    editingRate.id == rate.id &&
                    <div style={{paddingLeft: '20px', marginTop: '15px'}}>
                      <div className="row" style={{marginBottom: '8px'}}>
                        <div className="col-md-8">
                          <h3 style={{paddingBottom: '8px'}}><b>Post Creation Fee</b></h3>
                          <p className="sub-text">The price of your time + expenses to create and edit your content for the brand</p>
                          <input className="dark-input" style={{width: '200px', fontWeight: 'bold', fontSize: '18px'}} value={editingRate.baseCost} onChange={(ev) => onChangeInput(ev, 'baseCost')} />
                        </div>
                        <div className="col-md-6">

                        </div>
                      </div>
                      <div className="row" style={{marginBottom: '8px'}}>
                        <div className="col-md-8">
                          <h3 style={{paddingBottom: '8px'}}><b>View CPM (Cost-Per-Thousand Views)</b></h3>
                          <p className="sub-text">Earn money on how many views your post receives. This aligns the brand’s payment to your post’s audience reach.</p>
                          <input className="dark-input" style={{width: '200px', fontWeight: 'bold', fontSize: '18px'}} value={editingRate.cpm} onChange={(ev) => onChangeInput(ev, 'cpm')} />
                        </div>
                        <div className="col-md-6">

                        </div>
                      </div>
                      <div className="row row0" style={{marginBottom: '8px'}}>
                        <h3 style={{paddingBottom: '8px'}}><b>Expected Earnings</b></h3>
                        <p className="sub-text" style={{margin: 0}}>Enter the minimum & maximum views your content normally receives to estimate how much you will earn. Your earnings are the combined <b>Post Creation Fee </b></p>
                      </div>

                      <div className="row row0" style={{marginBottom: '15px'}}>
                        <div className="row row0" style={{textAlign: 'center'}}>
                          <div className="" style={{display: 'inline-block', float: 'left', marginRight: '25px'}}>
                            <input className="dark-input" style={{width: '100px', fontWeight: 'bold', fontSize: '18px'}} value={editingRate.minViews} onChange={(ev) => onChangeInput(ev, 'minViews')} />
                            <label className="sub-text">Min Views</label>
                          </div>
                          <div className="" style={{display: 'inline-block', float: 'left', marginRight: '25px', paddingTop: '15px'}}>
                            <h3 style={{padding: 0, marginTop: 0}}>to</h3>
                          </div>
                          <div className="" style={{display: 'inline-block', float: 'left', marginRight: '25px'}}>
                            <input className="dark-input" style={{width: '100px', fontWeight: 'bold', fontSize: '18px'}} value={editingRate.maxViews} onChange={(ev) => onChangeInput(ev, 'maxViews')} />
                            <label className="sub-text">Max Views</label>
                          </div>
                          <div className="" style={{display: 'inline-block', float: 'left', marginRight: '25px', paddingTop: '15px'}}>
                            <h3 style={{padding: 0, marginTop: 0}}>=</h3>
                          </div>
                          <div className="" style={{display: 'inline-block', float: 'left', marginRight: '25px', paddingTop: '16px'}}>
                            <h3 style={{padding: 0, marginTop: 0}}>${Math.round( (Number(editingRate.cpm) * Number(editingRate.minViews/1000)) + Number(editingRate.baseCost) )} - ${Math.round( (Number(editingRate.cpm) * Number(editingRate.maxViews/1000)) + Number(editingRate.baseCost) )}</h3>
                            <label className="sub-text" style={{paddingTop: '22px'}}>Projected Earnings</label>
                          </div>
                        </div>

                        <div className="row row0" style={{marginTop: '35px', display: 'none'}}>
                          <ReactSlider
                            className={`horizontal-slider ${false == 'single' ? `horizontal-slider-single` : `horizontal-slider-double`}`}
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            type="multi"
                            defaultValue={[5000, 25000]}
                            range={[0, 1000000]}
                            min={Number(0)}
                            max={Number(1000000)}
                            step={Number(5000)}
                            minDistance={Number(10000)}
                            onChangeInput={(ev) => onChangeInput(ev)}
                          />
                        </div>

                      </div>

                      <div className="row row0" style={{marginBottom: '15px'}}>
                      <button className="btn-action btn-action-secondary" onClick={(ev) => editRate({})}>Cancel</button>
                        <button className="btn-action btn-primary" onClick={() => saveRateDynamic()}>Save Rate</button>
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }

          <div className="row row0">
            <div className="module" style={{position: 'relative', overflow: 'hidden'}}>
              <div className="row row0" style={{cursor: 'pointer'}}>
                <div style={{ position: 'absolute', width: '12px', height: '100%', top: 0, left: 0, backgroundColor: `${Globals.platformColor('other')}`}}></div>
                <div className="row row0" style={{marginLeft: '4px'}}>
                  <div className="col-xs-9">
                    <h4 style={{fontWeight: 700}}>
                      <span>Add Platform Rate</span>
                    </h4>
                  </div>
                  <div className="col-xs-3">
                    <div className="add-filter-restriction-img">
                      <svg className="SVGInline-svg SVGInline--cleaned-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g id="b5f73d9c-be37-45b9-924c-84a9c2d374ac" dataname="Design"><path d="M23.11,12a.5.5,0,0,1-.5.5H12.5V22.62a.5.5,0,1,1-1,0V12.51H1.39a.5.5,0,1,1,0-1H11.5V1.4a.5.5,0,0,1,1,0V11.51H22.61A.5.5,0,0,1,23.11,12Z"></path></g></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row0">
                {
                  submissions &&
                  typeof submissions == 'object' &&
                  submissions.map((s) => {
                    return (
                      <div className="row row0">
                        <div className="module" style={{overflow: 'hidden'}}>
                          <div>
                            <div style={{position: 'absolute',width: '12px',height: '100%',top: 0,left: 0, backgroundImage: `${Globals.platformColor(s.iconName)}`, backgroundColor: `${Globals.platformColor(s.iconName)}`}}></div>
                            <div className="row row0" style={{marginLeft: '4px', cursor: 'pointer'}}>
                              <div className="col-xs-9">
                                <div className="row row0">
                                  <div style={{position: 'relative', display: 'inline-block', float: 'left', marginRight: '12px', height: '34px', width: '24px'}}>
                                    <div><img src={Globals.icon(s.iconName)} style={{position: 'absolute', top: 0, bottom: 0, margin: 'auto', width: '24px'}} /></div>
                                  </div>
                                  <div style={{display: 'inline-block', float: 'left', marginRight: '15px'}}>
                                    <h4 style={{fontWeight: 700, margin: 0}}>{s.name}</h4>
                                    <p className="sub-text" style={{margin: 0}}>{s.defaultDescription}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-3" style={{textAlign: 'right'}}>
                                <p style={{fontWeight: 700, margin: '10px 0 0 0'}}>Add</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </IsDev>*/}

    </div>
  );
};


{/*<div className={'row row0'}>*/}
{/*  <div className={'col-md-12'}>*/}
{/*    <h4>Social Metrics</h4>*/}
{/*  </div>*/}
{/*  <div className={'col-md-6'}>*/}
{/*    <h5 className="sub-text">Youtube social username / url</h5>*/}
{/*    <input*/}
{/*      defaultValue={form.socialMetrics.YoutubeUser}*/}
{/*      name="youtubeSocial"*/}
{/*      onChange={(ev) =>*/}
{/*        onSocialChangeInput('youtubeSocial', ev.target.value)*/}
{/*      }*/}
{/*      className="dark-input"*/}
{/*    />*/}

{/*    <h5 className="sub-text">Tiktok social username / url</h5>*/}
{/*    <input*/}
{/*      defaultValue={form.socialMetrics.TiktokUser}*/}
{/*      name="tiktokSocial"*/}
{/*      onChange={(ev) =>*/}
{/*        onSocialChangeInput('tiktokSocial', ev.target.value)*/}
{/*      }*/}
{/*      className="dark-input"*/}
{/*    />*/}
{/*  </div>*/}
{/*  <div className={'col-md-6'}>*/}
{/*    <h5 className="sub-text">Instagram social username / url</h5>*/}
{/*    <input*/}
{/*      defaultValue={form.socialMetrics.InstagramUser}*/}
{/*      name="instagramSocial"*/}
{/*      onChange={(ev) =>*/}
{/*        onSocialChangeInput('instagramSocial', ev.target.value)*/}
{/*      }*/}
{/*      className="dark-input"*/}
{/*    />*/}

{/*    <h5 className="sub-text">Twitch social username / url</h5>*/}
{/*    <input*/}
{/*      defaultValue={form.socialMetrics.TwitchUser}*/}
{/*      name="twitchSocial"*/}
{/*      onChange={(ev) =>*/}
{/*        onSocialChangeInput('twitchSocial', ev.target.value)*/}
{/*      }*/}
{/*      className="dark-input"*/}
{/*    />*/}
{/*  </div>*/}
{/*</div>*/}
