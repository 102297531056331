import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'

const FilterCompany = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <div className="filter-option-container">
        <div className="row row0">
          <SearchDropdown endpoint="getAutocomplete" searchIndex="apollo_bio" index={0} type={type} field="domain" target="name" showImage={true} changeFilter={changeFilter} placeholder="Select brand..." _this={_this} />
        </div>
        <div className="row row0">
          { filter[0] && filter[0].length > 0 && filter[0].map((l) => {
            return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> )
          }) }
        </div>
      </div>
    </>
  )
})

export default memo(FilterCompany);
