import React from 'react'
import Globals from '../global-functions.js'
import Parser from 'html-react-parser';

export default class ConversationSummary extends React.Component {

  constructor(props){
    super(props)

    this.state = {

    }
  }

  render(){

    // var message = this.props.message, detect = this.props.detect
    // var detected = ''
    // if (message.author_type == 'user'){
    //   if (detect.type == 'declined') { detected = <b style={{color: '#f58a82'}}>• detected negative response</b> }
    //   if (detect.type == 'paid') { detected = <b style={{color: '#ffa500'}}>• detected paid response</b> }
    //   if (detect.type == 'accepted') { detected = <b style={{color: '#2ac30e'}}>• detected positive response</b> }
    // }

    var convo = this.props.convo ? this.props.convo : { name: '' }

    return (
      <li key={Math.floor(Math.random() * 9999999)} onClick={this.props.selectConvo.bind(this.props.this, convo)}>
        <div className={`message-tab ${this.props.selectedConvo.id == convo.id ? 'active' : ''}`}>
          <div className="message-tab-container">
            <div className="message-tab-header">
              <div className="message-tab-wrapper">
                <div className="message-tab-checkbox">
                  <input type="checkbox" />
                </div>
                <div className="message-tab-avatar-container">
                  <span className="message-tab-avatar">
                    <span className="message-tab-avatar-media">{convo.name ? convo.name[0] : ''}</span>
                    <span className="message-tab-avatar-badge"></span>
                  </span>
                </div>
                <div className="message-tab-layout-media-container">
                  <div className="message-tab-layout-media">
                    <div className="message-tab-layout-media-ext">
                      <h3 className="message-tab-layout-name">
                        {convo.name}
                      </h3>
                    </div>
                    <div className="message-tab-layout-timestamp">
                      <time dateTime="1564365775000" className="message-tab-layout-timestamp-view sub-text">{Globals.calculateTimeAbbr(convo.lastRepliedTime)}</time>
                    </div>
                  </div>
                </div>
              </div>
              <div className="message-tab-relative">
                <div className="message-tab-convo-summary">
                  <div className="layout__media o__center">
                    <div className="message-tab-convo">
                      <div className="layout__media o__center">
                        <div className="layout__media__ext">
                          <div className="message-tab-convo-truncated-line">
                            {convo.recentMessage ? Parser(convo.recentMessage) : '...'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="js__external-step-pointer__conversation-header"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    )

  }

}
