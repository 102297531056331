import React from 'react';
import store from 'store2';

export default class BrandRefer extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    var queries = location.search ? location.search.replace('?', '').split('&') : []
    var referral = false
    queries.forEach((q) => {
      var key = q.split('=')[0], value = q.split('=')[1]
      if (key == 'refer') { referral = value }
    })
    if (referral){
      store('referral', referral)
    }
  }


  render() {
    return (
      <div>

      </div>
    )
  }
}
