import { Component, memo } from 'react'
import {Link} from 'react-router-dom'
import Globals from '../../global-functions.js'
import moment from 'moment'
import OutboundTable from './outbound-table'
// import WatchlistsTable from './watchlists-table'

class Outreach extends Component {

  constructor(props){
    super(props)
    this.state = {
      collapsed: false,
      messages: [],
      text: '',
      dripId: '99',
      dripCampaigns: [],
      watchlists: [],
      brands: []
    }
  }

  componentDidMount(){
    this.getDripCampaigns()
    // this.getWatchlists()
  }

  // gmailLogin(){ $.ajax({ method: 'GET', url: '/api/YouTube/gmailAuthUrl', data: {} }).done((data) => { location.replace(data) }) }
  // listLabels(){ $.ajax({ method: 'GET', url: '/api/Gmail/listLabels', data: {} }).done((data) => { console.log(data) }) }
  // sendEmail(){ $.ajax({ method: 'POST', url: '/api/Gmail/sendEmail', data: {} }).done((data) => { console.log(data) }) }
  async getWatchlists(){ var res = await this._getWatchlists(); this.setState(res) }
  async _getWatchlists(){ return $.ajax({ method: 'GET', url: '/api/BrandSearch/getWatchlists', data: {  } }) }
  async getDripCampaigns(){ var res = await this._getDripCampaigns(); this.setState({ dripCampaigns: res }) }
  async _getDripCampaigns(){ return $.ajax({ method: 'GET', url: '/api/Drip/getAll', data: {  } }) }
  async createDripCampaign(){ var res = await this._createDripCampaign(); location.replace(`/outreach/drip/${res}`) }
  async _createDripCampaign(){ return $.ajax({ method: 'POST', url: '/api/Drip/create', data: {  } }) }

  chooseSort(sort) {
    // var direction = this.state.sortFilter.indexOf(sort) > -1 && this.state.sortFilter.indexOf("DESC") > -1 ? "ASC" : "DESC";
    // var sortFilter = sort + " " + direction;
    // this.setState({ sortFilter: sortFilter, });
    // store('brandListSortFilter', sortFilter);
    // setTimeout(() => { this.getBrands(); }, 50);
  }

  selectInfluencer(inf, e) {
    // var id = inf == "all" ? inf : inf.id;
    // var brands = this.state.brands;
    // brands.forEach((i) => {
    //   if (id == "all") { i.selectedActive = !this.state.selectedActive; }
    //   else { if (i.id == id) { i.selectedActive = !i.selectedActive; } }
    // });
    // this.setState({ brands: brands, selectedActive: id == "all" ? !this.state.selectedActive : this.state.selectedActive, });
  }

  render() {
    var _stages = [], { dripCampaigns, watchlists, loading } = this.state
    var { createDripCampaign, chooseSort, selectInfluencer } = this
    var sortFilter

    return (
      <div>
        <div className="mgmt-section">
          {/*<div className="mgmt-menu">
            <h3 style={{marginTop: 0}}>Outbound</h3>
            <p className="sub-text" style={{margin: 0, textDecoration: 'underline'}}><a href="http://support.weavesocial.com/en/articles/5435087-create-influencer-reach-out-emails" target="_blank">Read tips how to maximize your outreach here.</a></p>
          </div>*/}
          <div className={`mgmt-list ${this.state.collapsed ? 'collapsed' : ''} mgmt-menu-dark`}>
            <div className="crm-actions">
              <div className="row row0" style={{marginBottom: '15px'}}>
                <div style={{display: 'inline-block', float: 'left'}}>
                  <div className="row row0">
                    <h4 className="crm-title">All Email Drips</h4>
                  </div>
                  <div className="row row0" style={{marginTop: '8px'}}>
                    <p className="sub-text">Create email reach outs to invite influencers to your bounty</p>
                  </div>
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                  <button className="btn-action btn-primary btn-right" onClick={createDripCampaign.bind(this)}>+ Create Email Drip</button>
                </div>
                <Link to="/outreach/settings">
                  <button className="btn-action btn-secondary btn-right">Settings</button>
                </Link>
              </div>
              {/*<div className="row row0 crm-filters">
                <div className="crm-filter">
                  <div className="crm-filter-block">
                    In Network
                  </div>
                </div>
                <div className="crm-add-filter">
                  + Add filter
                </div>
              </div>*/}
              <hr />
              <div className="row row0 crm-filters" style={{margin: 0}}>
                <div className="crm-inline" style={{marginRight: '25px'}}>
                  <h5>{dripCampaigns.length} Email Drip{dripCampaigns.length == 1 ? '' : 's'}</h5>
                </div>
                {/*<div className="crm-inline">
                  <button className="crm-btn">
                    New Content
                  </button>
                </div>
                <div className="crm-inline">
                  <button className="crm-btn">
                    Tag
                  </button>
                </div>
                <div className="crm-inline">
                  <button className="crm-btn">
                    More
                  </button>
                </div>*/}
              </div>
            </div>

            <div className="content-library">
              <div className="content-library-inner">
                <div className="row">
                  <div className="col-md-12" style={{height: 'calc(100vh - 163px)'}}>
                    <div className="row row0">
                      { dripCampaigns.length > 0 && <OutboundTable this={this} dripCampaigns={dripCampaigns} chooseSort={chooseSort.bind(this)} selectInfluencer={selectInfluencer.bind(this)} style={{height: 'calc(100vh - 163px)'}} _this={this} /> }
                      { dripCampaigns.length == 0 &&
                        <div className="results-empty-message">
                          <h3>No Email Drip Campaigns</h3>
                          <p className="sub-text">Create your first email drip campaign to pitch brands.</p>
                        </div>
                      }
                      { loading && <Loader active={true} /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(Outreach);
