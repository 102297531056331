import React, { useEffect, useRef, useState, useCallback } from 'react';
declare const $: any;

const _getMyCategories = () => $.ajax({ method: 'GET', url: '/api/me/categories', });
const _setMyCategories = (categories: any) => $.ajax({ method: 'POST', url: '/api/me/categories', data: { categories, }, });
const _getAvailableCategories = () => $.ajax({ method: 'GET', url: '/api/me/available-categories', data: {}, });

const initialState = Object.freeze({
  categories: []
});

export const AccountCategories = () => {
  const formRef = useRef<HTMLFormElement>();
  const [form, setForm] = useState(initialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([] as any[]);

  /***
   * As instructed on slack 06/18/2021 - we are to assume that a user always
   * has an instagramUser. If that's not the case, This form will not show up.
   */
  const [invalidUser, setInvalidUser] = useState(false);
  useEffect(() => { loadData(); }, []);

  const loadData = () => {
    setLoading(true);
    return Promise.all([ _getMyCategories(), _getAvailableCategories() ])
      .then(([ categoriesResponse, availableCategoriesResponse ]) => {
        const categories: any = (categoriesResponse || []);
        setAllCategories(availableCategoriesResponse);
        setForm({ categories });
      })
      .finally(() => { setLoading(false); })
      .catch(() => { setInvalidUser(true); })
  };

  const saveForm = () => { return Promise.all([ _setMyCategories(form.categories) ]); };

  const updateCategories = async (event: Event) => {
    setSaving(true);
    event.preventDefault();
    try { await saveForm(); } catch (e) {}
    setSaving(false);
    await loadData();
  };

  const isCategorySelected = (categoryId: string) =>  !!form.categories.find((category) => category === categoryId);

//  const isCategorySelected = (categoryId: string) => {
//    return !!form.categories.find((category) => category === categoryId);
//  };

  const toggleCategory = (categoryId: string) => {
    const isSelected: boolean = isCategorySelected(categoryId);
    const selectedCategoryIds: string[] = isSelected ? form.categories.filter((cat) => cat !== categoryId) : [...form.categories, categoryId];
    setForm({ ...form, categories: selectedCategoryIds });
  };

  // if (invalidUser) {
  //   return <div> Please connect your instagram to add your rates. </div>
  // }

  return (
    <div className={'rates-form'}>
      <div className="row row0" style={{marginBottom: '15px'}}>
        <h3 style={{ margin: '20px 0px 15px', padding: 0, fontSize: '20px', textTransform: 'initial' }}>Interests</h3>
        <p className="sub-text">Select which categories of brands you are interested in - this helps Weave recommend you relevant opportunities and increase your chances of being discovered by brands you are interested in.</p>
      </div>

      <form className="module" ref={formRef as any} onSubmit={(event) => updateCategories(event)}>
        <div className={'row'} style={{marginTop: 0, marginBottom: 0}}>
          <div className={'col-md-12'}>
            <h4 style={{marginTop: 0}}>Categories</h4>
            <p className="sub-text" style={{marginBottom: '25px'}}>
              Selecting categories you are interested in helps recommend & auto-match you with brands
            </p>

            <div className={'categories'}>
              {allCategories.map((category) => (
                <span key={category.id} className={`tag-pill ${isCategorySelected(category.id) ? 'selected' : ''}`} onClick={() => toggleCategory(category.id)}>
                  <span>{category.icon}</span>
                  <span style={{ paddingLeft: '5px' }}> {category.name}</span>
                </span>
              ))}
            </div>
          </div>
        </div>

        <div className="row" style={{ paddingLeft: '28px', paddingRight: '28px' }}>
          <button className={`btn-action btn-primary ${loading || saving ? 'disabled' : ''}`} type={'submit'} style={{ marginLeft: '12px' }}>
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
