import React from 'react'
import BlinkingDot from "./blinking-dot";
import {LocationSearchInput} from "./LocationSearch";

export default class AccountNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phoneNumber: '',
      phone: '',
      updateSettingsBtn: 'Save',
      requestError: null,
      openForm: false
    };
  }

  componentDidMount() {
    this.refreshUser();
  }

  onChangeInput(field, e) { this.setState({ [field]: e.target.value }) }

  refreshUser(){
    this.getUser().done((response) => {
      if (response){
        this.setState({ phoneNumber: response.phoneNumber, phone: response.phoneNumber, })
      }
    })
  }

  getUser(){ return $.ajax({ method: 'GET', url: '/api/me/details', data: {} }) }

  updateSettings() { this.setState({ requestError: null }); this._updateSettings(this.state.phone); }

  _updateSettings(phoneNumber) {
    return $.ajax({ method: 'POST', url: '/api/me/updatePhone', data: { phoneNumber } }).catch((error) => {
      this.setState({ requestError: error.responseJSON.message });
    }).done((response) => {
        if (!response) { return; }
        this.refreshUser();
        if (response.success) {
          this.setState({ updateSettingsBtn: 'Saved!' });
          setTimeout(() => { this.setState({ updateSettingsBtn: 'Save', openForm: false }); }, 1000);
        }
      });
  }

  toggleAddPhoneNumber(open) {
    if (open) { this.setState({ phone: this.state.phoneNumber || '', requestError: null }) }
    this.setState({ openForm: open })
  }

  removePhoneNumber() { this._updateSettings(null); }

  render() {
    return (
      <div>
        <div className="row">
          <div className={`col-md-8 ${this.state.phoneNumber ? 'col-xs-12' : 'col-xs-6'}`}>
            <h3 style={{ margin: '20px 0 15px 0', padding: 0, fontSize: '20px', textTransform: 'initial' }}>
              Notifications
            </h3>
            <p className="sub-text" style={{ marginBottom: '25px' }}>
              Enter your phone number to receive real-time bounty updates
            </p>
          </div>
          <div className="col-md-4 col-xs-6">
            { !this.state.phoneNumber &&
              <button className="btn-action btn-primary"
                      disabled={this.state.phoneNumber}
                      onClick={this.toggleAddPhoneNumber.bind(this, true)}
                      style={{float: 'right', marginTop: '13px'}}>+ Add Phone</button>
            }
          </div>
        </div>

        <div style={{position: 'relative', minHeight: '192px'}}>
          { this.state.phoneNumber?.length == 0 &&
            <div>
              <div className="results-empty-message">
                <h3>No phone number</h3>
                <p className="sub-text">You will not be able to receive bounty notifications until you add a phone number</p>
              </div>
            </div>
          }
          { this.state.openForm &&
            <div className="row row0">
              <div className="module">
                <h3>Add Phone Number</h3>

                <div className="row row0">
                  <div className="row row-flex-end">
                    <div className="col-md-6 col-xs-12">
                      {/*<h5 className="sub-text">Email Notifications</h5>*/}
                      {/*<input value={this.state.email} onChange={this.onChangeInput.bind(this, 'email')} className="dark-input" />*/}

                      <h5 className="sub-text">SMS Notifications</h5>
                      <input
                        placeholder={'+12345678999'}
                        value={this.state.phone}
                        onChange={this.onChangeInput.bind(this, 'phone')}
                        className={`dark-input ${!!this.state.requestError ? ' error' : ''}`}
                        style={{ marginBottom: '0' }}
                      />
                    </div>
                  </div>
                  {!!this.state.requestError && ( <p className={'row row0 error-msg'}>{this.state.requestError}</p> )}
                </div>

                <div className="row row0" >
                  <button className="btn-action btn-primary"
                          onClick={this.updateSettings.bind(this)}
                          style={{marginLeft: '12px'}}>{this.state.updateSettingsBtn}</button>
                  <button className="btn-action" onClick={this.toggleAddPhoneNumber.bind(this, false)}>Cancel</button>
                </div>
              </div>
            </div>
          }
          <div className="row">
            {/*<div className="module">
              <div className={"row row0"}>
                <div className="col-xs-6">
                  <p>Email Notification</p>
                  <p className="sub-text" style={{margin: 0}}></p>
                </div>
                <div className="col-xs-6" style={{textAlign: 'right'}}>
                  <span>toggle</span>
                </div>
              </div>
            </div>*/}

            { this.state.phoneNumber && !this.state.openForm &&
              <div className="col-md-6">
                <div className="module">
                  <div className={"row row0"}>
                    <p><b><i>Notifications will be sent to</i></b></p>
                  </div>
                  <div >
                    <div className="row row0">
                      <p style={{margin: 0}}><b>{this.state.phoneNumber}</b></p>
                    </div>
                    <div className="row row0 settings-address-actions" style={{textAlign: 'right', marginTop: '25px'}}>
                      <a style={{borderRight: '1px solid #2f80ed'}} onClick={this.toggleAddPhoneNumber.bind(this, true)}>Edit</a>
                      <a onClick={this.removePhoneNumber.bind(this)}>Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
