import React from 'react';

export default class OnboardStatus extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    return (
      <div>
        <div className="onboard-status-container">
          <div className="onboard-status-outerbar">
            <div className="onboard-status-innerbar" style={{width: this.props.status}}>

            </div>
          </div>
        </div>
      </div>
    )
  }
}
