import { MakeFetchGet, MakeFetchPost } from '../utils/utils';
import { Address, CreateAddressRequest, CreateAddressResponse } from '../redux/models/addresses.models';


export const fetchAddresses = () =>
  MakeFetchGet('/api/Address/get') as Promise<Address[]>;

export const fetchAddressesAnonymous = (contentId: string) =>
  MakeFetchGet('/api/Address/getAnonymous',{ contentId }) as Promise<Address[]>;

export const fetchPrimaryAddress = () =>
  fetchAddresses().then(addresses => addresses.find(address => address.primary) || null)

export const fetchPrimaryAddressAnonymous = (contentId: string) =>
  fetchAddressesAnonymous(contentId).then(addresses => addresses.find(address => address.primary) || null)

export const createAddress = (params: CreateAddressRequest) =>
  MakeFetchPost('/api/Address/create', params) as Promise<CreateAddressResponse>;

export const updateAddress = (params: CreateAddressRequest) =>
  MakeFetchPost('/api/Address/update', params) as Promise<CreateAddressResponse>;

export const upsertPrimaryAddress = (params: CreateAddressRequest) =>
  MakeFetchPost('/api/Address/upsertPrimary', params) as Promise<CreateAddressResponse>;

export const upsertGuestPrimaryAddress = (params: {contentId: string, address: CreateAddressRequest}) =>
  MakeFetchPost('/api/Address/upsertGuestPrimaryAddress', params) as Promise<CreateAddressResponse>;

export const setPrimaryAddress = (params: {id: string}) =>
  MakeFetchPost('/api/Address/setPrimary', params) as Promise<CreateAddressResponse>;

export const removeAddress = (params: {id: string}) =>
  MakeFetchPost('/api/Address/remove', params) as Promise<CreateAddressResponse>;



