import React from 'react'
import Globals from '../../global-functions.js'

export default class DropdownBrandsFilter extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      dropdownActive: false
    }
    this.toggleFilterDropdown = this.toggleFilterDropdown.bind(this)
  }

  componentDidUpdate(){

  }

  componentDidMount(){ document.addEventListener('click', this.toggleFilterDropdown, true) }
  componentWillUnmount(){ document.removeEventListener('click', this.toggleFilterDropdown, true) }

  toggleFilterDropdown(open, e) {
    var forced = typeof open == 'string'
    if (forced && open == 'true' && !this.state.dropdownActive) { this.setState({ dropdownActive: true }) }
    else if (forced && open == 'false' && this.state.dropdownActive) { this.setState({ dropdownActive: false }) }
    else if (!forced) {
      e = open
      var target = document.querySelector(`.dropdown-${this.props.dropdownType}-ref`)
      if (target && target.contains(e.target)) { }
      else if (e.target.id == `switch-${this.props.dropdownType}-span`){ }
      else { console.log(3); this.setState({ dropdownActive: false }) }
    }
  }

  chooseFilter(type, option){
    this.toggleFilterDropdown('false', false)
    this.props.chooseFilter(type, option)
  }

  render() {
    var { dropdownActive } = this.state
    var _this = this.props.this, inactive
    var { chosenFilter, options, dropdownType, defaultLabel, align, width, height, multiselect } = this.props
    options = options ? options : []
    if (!chosenFilter || chosenFilter.length == 0) { chosenFilter = defaultLabel; inactive = true }
    var style = {}
    if (align) { style.float = align }

    var dropdownStyle = { minHeight: 'initial', top: '5px' }
    if (width){ dropdownStyle.width = width + 'px' }
    // if (!multiselect){
    //   chosenFilter
    // }
    // else {
    //
    // }

    return (
      <div className={`crm-filter ${inactive ? '' : 'active'}`} style={style}>
        <div className={`crm-filter-block switch-${dropdownType}-span`} onClick={this.toggleFilterDropdown.bind(this, 'true')}>
          {/*{ chosenFilter.icon && <span><img src={Globals.icon(chosenFilter.icon)} style={{ width: '16px', marginTop: '-3px', borderRadius: 0, marginRight: '10px'}}/></span> }*/}
          <span>{chosenFilter}</span>
          { inactive && <span><img src={Globals.icon('arrow-down-1')} style={{ width: '12px', marginTop: '-3px', borderRadius: 0, marginLeft: '10px'}}/></span> }
          { !inactive && <span style={{marginLeft: '10px'}}>✅</span> }
        </div>

        {dropdownActive &&
          <div className={`dropdown-${dropdownType}-ref`} ref={ d => this[`${dropdownType}-ref`] = d } key={Math.floor(Math.random() * 99999999999)} tabIndex="-1">
            <div className="main-dropdown-container crm-filter-dropdown">
              <div className={`main-dropdown ${dropdownActive ? 'active' : ''} ${align ? `align-${align}` : ``}`} style={dropdownStyle}>
                <div className="main-dropdown-outer">
                  <div className="main-dropdown-inner">
                    <div className="dropdown-section">
                      <div className={`dropdown-insert active`}>
                        <ul className="dropdown-results">
                          {
                            options.map((o) => {
                              return (
                                <li className="hoverable" onClick={this.chooseFilter.bind(this, dropdownType, o)} style={{padding: '8px'}} key={Math.floor(Math.random() * 99999999999)}>
                                  <div className="inner-autocomplete a-name">
                                    { o.icon && <span><img src={Globals.icon(o.icon)} style={{width: '16px', marginTop: '-3px', borderRadius: 0}} /></span> }
                                    { o.emoji && <span>{o.emoji}</span> }
                                    <span>{o.name}</span>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}
