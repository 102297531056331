import React from 'react';
import Globals from '../global-functions.js';
import Canvas from './canvas.jsx';
import Loader from './loader.jsx';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Moment } from 'moment';
var map = null

type DateRange = { startDate: string, endDate: string };
type ChartData = { earningct: null, earnings: { labels: null, data: null }, clickct: null, clicks: { labels: null, data: null } };
type Link = { name: string; hash: string; original_url: string; clicks: string; revenue: string; }
type Sale = { merchant: string; referrer_url: string; transactionAmount: string; amount: string; dateEarned: string; }
type Referrer = { referrer: string; clicks: string; }
type Retailer = { retailer: string; clicks: string; }
type Stream = { title: string, views: string, followers: string, stream_length: string, avg_concurrent: string, created_at: string }

type Props = {}
type State = {
  mounted: boolean;
  testData: null[];
  offset: number;
  links: Link[];
  sales: Sale[];
  content: null[];
  products: null[];
  retailers: Retailer[];
  referrers: Referrer[];
  referrer_urls: Referrer[];
  devices: null[];
  domain: string;
  calendar: boolean;
  campaigns_pending: null[];
  campaigns_active: null[];
  campaigns_past: null[];
  pastStreams: Stream[];
  slideOutActive: boolean;
  expandedCampaign: boolean;
  userCreated: string;
  startDate?: string;
  endDate?: string;
  calendarRange: Partial<DateRange>
  old_calendar: Partial<DateRange>,
  dateRanges: { today: DateRange, yesterday: DateRange, last_7d: DateRange, last_30d: DateRange, last_3m: DateRange, last_6m: DateRange, last_y: DateRange, lifetime: DateRange, },
  user: User,
  chartData?: ChartData,
  slideOutType: any,
  linksLoaded: boolean,
  retailersLoaded: boolean,
  referrersLoaded: boolean,
  referrerUrlsLoaded: boolean,
  salesLoaded: boolean,
  chartsLoaded: boolean
}

type User = { internal: boolean; userid: string; hash: string; }
const dateFormat = 'YYYY-MM-DD';
declare const $: any;
declare const flatpickr: any;

export default class Analytics extends React.Component<Props, State> {

  constructor(props: {}){
    super(props);
    let userCreated = moment(Globals.getUser().createdAt).format(dateFormat)
    var dateRanges = {
      today: { startDate: moment().format(dateFormat), endDate: moment().format(dateFormat) },
      yesterday: { startDate: userCreated < moment().subtract('1', 'days').format(dateFormat) ? moment().subtract('1', 'days').format(dateFormat) : userCreated, endDate: moment().subtract('1', 'days').format(dateFormat) },
      last_7d: { startDate: userCreated < moment().subtract('7', 'days').format(dateFormat) ? moment().subtract('7', 'days').format(dateFormat) : userCreated, endDate: moment().format(dateFormat) },
      last_30d: { startDate: userCreated < moment().subtract('30', 'days').format(dateFormat) ? moment().subtract('30', 'days').format(dateFormat) : userCreated, endDate: moment().format(dateFormat) },
      last_3m: { startDate: userCreated < moment().subtract('90', 'days').format(dateFormat) ? moment().subtract('90', 'days').format(dateFormat) : userCreated, endDate: moment().format(dateFormat) },
      last_6m: { startDate: userCreated < moment().subtract('180', 'days').format(dateFormat) ? moment().subtract('180', 'days').format(dateFormat) : userCreated, endDate: moment().format(dateFormat) },
      last_y: { startDate: userCreated < moment().subtract('365', 'days').format(dateFormat) ? moment().subtract('365', 'days').format(dateFormat) : userCreated, endDate: moment().format(dateFormat) },
      lifetime: { startDate: userCreated, endDate: moment().format(dateFormat) }
    };

    const prefStart = Cookies.get('preferredDateStart') ? Cookies.get('preferredDateStart') : moment().subtract('30', 'days').format(dateFormat)
    const prefEnd = Cookies.get('preferredDateEnd') ? Cookies.get('preferredDateEnd') : moment().add(1, 'day').format(dateFormat)

    const user = Globals.getUser() ? Globals.getUser() : {};
    this.state = {
      mounted: false,
      user: user,
      testData: [],
      offset: 0,
      links: [],
      sales: [],
      content: [],
      products: [],
      retailers: [],
      referrers: [],
      referrer_urls: [],
      devices: [],
      userCreated: userCreated,
      domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me',
      calendar: false,
      startDate: prefStart,
      endDate: prefEnd,
      calendarRange: { startDate: prefStart, endDate: prefEnd },
      old_calendar: { startDate: prefStart, endDate: prefEnd },
      dateRanges: dateRanges,
      campaigns_pending: [],
      campaigns_active: [],
      campaigns_past: [],
      pastStreams: [],
      slideOutActive: false,

      chartsLoaded: false,
      linksLoaded: false,
      retailersLoaded: false,
      referrersLoaded: false,
      referrerUrlsLoaded: false,
      salesLoaded: false,

      expandedCampaign: false,
      slideOutType: null
    }
  }

  componentDidMount() {
    Globals.pageView(window.location.pathname + window.location.search)
    if (!this.state.user.internal)  {
      (window as any)['Intercom']("update");
    }
    this.setState({ mounted: true })

    this.initCalendar();
    this.getAnalytics();
    this.getActiveBounties();
  }

  componentWillUnmount(){
    this.setState({ mounted: false })
    $('.shortcuts').remove()
  }

  getActiveBounties(){
    this._getActiveBounties().done(({campaigns_pending, campaigns_active, campaigns_past}: any) => {
      this.setState({ campaigns_pending, campaigns_active, campaigns_past })
    })
  }

  _getActiveBounties(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getActiveBounties', data: { userid: this.state.user.userid } }) }

  getAnalytics(){
    var { mounted } = this.state
    this.setState({
      linksLoaded: false,
      retailersLoaded: false,
      referrersLoaded: false,
      referrerUrlsLoaded: false,
      salesLoaded: false,
      chartsLoaded: false,
    })

    this.getCharts().then((data: ChartData) => { if (this.state.mounted) { this.setState({ chartData: data, chartsLoaded: true }) } })

    if (mounted){
      this.getData('/api/Analytics/getLinks').done((data: Link[]) => { this.setState({ links: data, linksLoaded: true }) })
      this.getData('/api/Analytics/getSales').done((data: { sales: Sale[]}) => { this.setState({ sales: data.sales, salesLoaded: true }) })
      this.getData('/api/Analytics/getReferrers').done((referrers: Referrer[]) => { this.setState({ referrers, referrersLoaded: true }) })
      this.getData('/api/Analytics/getReferrerUrls').done((referrer_urls: Referrer[]) => { this.setState({ referrer_urls, referrerUrlsLoaded: true }) })
    }

    // this.getData('/Analytics/getRetailers').done((data) => {
    //   // console.log(data)
    //   if (this.state.mounted) { this.setState({ retailers: data }) }
    // })
    //
    // this.getData('/Analytics/getStreamingStats', 'GET').done((response) => {
    //   console.log(response)
    //   if (this.state.mounted) { this.setState({ mixerStats: response }) }
    // })
    //
    // this.getData('/Analytics/getPastStreams', 'GET').done((response) => {
    //   console.log(response)
    //   if (this.state.mounted) { this.setState({ pastStreams: response }) }
    // })
    //
    // this.getData('/Analytics/getGameConcurrents', 'GET').done((response) => {
    //   console.log(response)
    //   if (this.state.mounted) { this.setState({ donutData: response }) }
    // })
  }

  getData(url: string, method: string = 'GET'){
    return $.ajax({
  	  method: method ? method : 'POST',
  	  url: url,
  		data: {
  	    startDate: moment(this.state.calendarRange.startDate).format(),
        endDate: moment(this.state.calendarRange.endDate).add('1', 'days').format(),
        offset: this.state.offset
  	  }
  		// data: { user: this.state.user, user_hash: 'p-7r', userid: '103347538086721258202', startDate: this.state.startDate, endDate: this.state.endDate, offset: this.state.offset }
  	})
  }

  getCharts(){
    return $.ajax({
  		method: 'POST',
  		url: '/api/Analytics/getDashboardAnalytics',
  		// data: { user: this.state.user, user_hash: this.state.user.hash, userid: this.state.user.userid, startDate: this.state.calendarRange.startDate, endDate: this.state.calendarRange.endDate, test: moment(this.state.calendarRange.startDate).format() }
      data: { user: this.state.user, user_hash: this.state.user.hash, userid: this.state.user.userid, startDate: moment(this.state.calendarRange.startDate).format(), endDate: moment(this.state.calendarRange.endDate).add('1', 'days').format() }
      // data: { user: userdata, user_hash: 'p-k_', userid: '103013318975038542812', startDate: this.state.startDate, endDate: this.state.endDate }
  	})
  }

  initCalendar(){
  	let calendar = flatpickr('#calendar', {
      mode: "range",
      dateFormat: "Y-m-d",
  		minDate: this.state.userCreated,
  		maxDate: moment().format('YYYY/MM/DD'),
      defaultDate: [this.state.userCreated < moment().subtract('30', 'days').format(dateFormat) ? moment().subtract('30', 'days').format(dateFormat) : this.state.userCreated, moment().format(dateFormat)],
  		onChange: this.changeCalendar.bind(this),
  		onClose: this.updateCalendar.bind(this)
  	})

    $('.shortcuts').prependTo('.flatpickr-calendar').show().addClass('loaded')
  	$('#calendar').text($('#calendar').val())
    if (this.state.mounted) { this.setState({ calendar: calendar }) }

    const that = this as any;

    $('.shortcuts li a').click((e: any) => {
  		var dates = that.state.dateRanges[e.target.id]
  		if (that.state.mounted) { that.setState({ calendarRange: dates }) }
  		// that.state.calendar.setDate([dates.startDate, dates.endDate], false)
  		// that.state.calendar.toggle()
      // Cookies.set('preferredDateStart', dates.startDate)
      // Cookies.set('preferredDateEnd', dates.endDate)
      calendar.setDate([dates.startDate, dates.endDate], false)
  		calendar.toggle()
  		$('.shortcuts li a').removeClass('active')
  		$(e.target.id).addClass('active')
  	})
  }

  changeCalendar(){
    $('.shortcuts li a').removeClass('active')
  	$('.shortcuts #custom').addClass('active')
  }

  updateCalendar(selectedDates: string[], dateStr: string){
    var { calendarRange, old_calendar, mounted } = this.state
    calendarRange.startDate = moment(selectedDates[0]).format(dateFormat)
  	calendarRange.endDate = moment(selectedDates[1]).format(dateFormat)
  	if (calendarRange.startDate != old_calendar.startDate || calendarRange.endDate != old_calendar.endDate){
  		old_calendar.startDate = calendarRange.startDate
  		old_calendar.endDate = calendarRange.endDate
      if (mounted) { this.setState({ calendarRange: calendarRange, old_calendar: old_calendar }) }
  		$('#calendar').text(dateStr)
  		this.getAnalytics()
  	}
  }

  closeSlideOut(_this: Analytics){
    _this.setState({ slideOutActive: false })
    setTimeout(() => { _this.setState({ expandedCampaign: false, slideOutType: false })
    }, 500)
  }


  render() {

    const { linksLoaded, retailersLoaded, referrersLoaded, referrerUrlsLoaded, salesLoaded, } = this.state;
    let canvas1: JSX.Element | null = null;
    let canvas2: JSX.Element | null = null;

    if (this.state.chartData && this.state.chartData?.earningct){
      canvas1 = <Canvas element="myChart1" total={this.state.chartData?.earningct} labels={this.state.chartData?.earnings?.labels} data={this.state.chartData?.earnings?.data} type="earnings" size="col-md-6"></Canvas>
      canvas2 = <Canvas element="myChart2" total={this.state.chartData?.clickct} labels={this.state.chartData?.clicks?.labels} data={this.state.chartData?.clicks?.data} type="clicks" size="col-md-6"></Canvas>
    }
    else { canvas1 = <Loader active="true" /> }

    const links = linksLoaded && this.state.links.map((link, i) =>  (
        <div key={i} className={'table-row'} style={{ padding: '20px 0px' }}>
          <div className="col-xs-8">
            { link.name && <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{link.name}</p>}
            <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 0 }}>{`http://${this.state.domain}/~${link.hash}`}</p>
            <p style={{ color: '#cccccc', margin: 0 }}>{link.original_url}</p>
          </div>
          <div className="col-xs-2">{link.clicks}</div>
          <div className="col-xs-2">${parseFloat(link.revenue).toFixed(2)}</div>
        </div>
    ));


    const retailers = this?.state?.retailers.map((retailer, i) =>
      <tr key={i}>
        <td>{retailer.retailer ? retailer.retailer : 'unknown'}</td>
        <td>{retailer.clicks}</td>
      </tr>
    );

    const referrers = this?.state?.referrers.map((referrer, i) =>
      <tr key={i}>
        <td>{referrer.referrer ? referrer.referrer : 'unknown'}</td>
        <td>{referrer.clicks}</td>
      </tr>
    );

    const referrer_urls = this?.state?.referrer_urls.map((referrer, i) =>
        <tr key={i}>
            <td>{referrer.referrer ? referrer.referrer : 'unknown'}</td>
            <td>{referrer.clicks}</td>
        </tr>
    );

    const sales = this.state?.sales?.map((sale, i) =>
        <tr key={i} className={'table-row'}>
          <td>{sale.merchant}</td>
          <td>{sale.referrer_url}</td>
          <td>${sale.transactionAmount}</td>
          <td>${sale.amount}</td>
          <td>{sale.dateEarned}</td>
        </tr>
    );

    return (
      <div>
        <div className="row analytics-header" style={{paddingLeft: '15px', paddingRight: '15px', margin: 0}}>
          <div className="col-xs-6" style={{padding: 0}}>
            <h3 style={{textAlign: 'left', marginTop: '20px'}}>Key Metrics</h3>
          </div>
          <div className="col-xs-6" style={{textAlign: 'right'}}>
            <button id="calendar" name="calendar"></button>
          </div>
          {/*<hr style={{width: '100%'}} />*/}
        </div>

        <div className="row" style={{position: 'relative', minHeight: '200px', margin: 0}}>
          {canvas1}
          {canvas2}
        </div>

        <div className="row" style={{position: 'relative', minHeight: '200px', margin: 0}}>
          {/*{canvas3}*/}
          {/*{canvas4}*/}
        </div>

        <div className="row" style={{margin: 0}}>
          { this.state.campaigns_past.length > 0 &&
            <div>
              <h3 style={{ marginTop: '20px' }}>Past Bounties</h3>
              {/*{
                this.state.campaigns_past.map((campaign) => {
                  if (campaign.game){
                    return (
                      <CampaignStream
                        this={this}
                        twitchLive={campaign.game}
                        campaign={campaign}
                        expandBountyDetails={this.expandBountyDetails}
                        bountyDetailsExpanded={this.bountyDetailsExpanded}
                        viewOffer={this.viewOffer}
                      />
                    )
                  }
                })
              }*/}
            </div>
          }
        </div>

        {/*<div className="row" style={{position: 'relative', minHeight: '200px', margin: 0}}>
          <div className="col-md-4">
            {
              this.state.donutData &&
              <Donut data={this.state.donutData} />
            }
          </div>
          <div className="col-md-4">
            {
              this.state.donutData &&
              <Donut data={this.state.donutData} />
            }
          </div>
          <div className="col-md-4">
            {
              this.state.donutData &&
              <Donut data={this.state.donutData} />
            }
          </div>
        </div>*/}

        {/*

          <div className="analytics-module-container">
            <h3>{'How Many Clicks Are Desktop/Mobile?'}</h3>
            <div className="analytics-module">
              <canvas id="device-doughnut" />
            </div>
          </div>

          <div className="analytics-module-container">
            <h3>{'What Platforms Drive The Most Clicks?'}</h3>
            <div className="analytics-module">
              <canvas id="platform-doughnut" />
            </div>
          </div>

        */}

        <div className="col-xs-12 analytics-module-container">
          <div className={"analytics-module module"}>
            <span className={"sub-text"}>Top performing links</span>
            <div className="table-row" style={{ padding: '20px 0px' }}>
              <div className="col-xs-8"><b>Link</b></div>
              <div className="col-xs-2"><b>Clicks</b></div>
              <div className="col-xs-2"><b>Revenue</b></div>
            </div>
            <div className={"module-body"}>
              <Loader active={!linksLoaded} />
              {links}
            </div>
          </div>
        </div>

        <div className="col-md-4 analytics-module-container">
          <div className={"analytics-module module"}>
            <span className={"sub-text"}>Retailers clicks</span>
            <div className={"module-body"}>
            <Loader active={!retailersLoaded} />
            <table className={!retailersLoaded ? 'hidden' : ''}>
              <tbody>
                <tr>
                  <th>Retailer</th>
                  <th>Clicks</th>
                </tr>
                {retailers}
              </tbody>
            </table>
          </div>
          </div>
        </div>

        <div className="row" style={{margin: 0}}>
          <div className="col-md-4 analytics-module-container">
            <div className="analytics-module module">
              <span className={"sub-text"}>Referrers clicks</span>
              <div className={"module-body"}>
                <Loader active={!referrersLoaded} />
                <table className={!referrersLoaded ? 'hidden' : ''}>
                  <tbody>
                  <tr>
                    <th>Referrer</th>
                    <th>Clicks</th>
                  </tr>
                  {referrers}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-md-4 analytics-module-container">
            <div className="analytics-module module">
              <span className={"sub-text"}>Referrer urls clicks</span>
              <div className={"module-body"}>
                <Loader active={!referrerUrlsLoaded} />
                <table className={!referrerUrlsLoaded ? 'hidden' : ''}>
                  <tbody>
                  <tr className={'table-row'}>
                    <th>Referrer Url</th>
                    <th>Clicks</th>
                  </tr>
                  {referrer_urls}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-md-4 analytics-module-container">
            {/*<h3>{`Which referrer urls get the most clicks?`}</h3>
            <div className="analytics-module">
              <Loader active={!referrerUrlStatus} />
              <table className={!referrerUrlStatus ? 'hidden' : ''}>
                <tbody>
                  <tr>
                    <th>Referrer Url</th>
                    <th>Clicks</th>
                  </tr>
                  {referrer_urls}
                </tbody>
              </table>
            </div>*/}
          </div>
        </div>

        <div className="col-md-12 analytics-module-container">
          <div className="analytics-module module">
            <div className={"module-body"}>
              <span className={"sub-text"}>Past Streams</span>
              <Loader active={!linksLoaded} />
              <div className="row-table" style={{ padding: '20px 0px' }}>
                <div className="col-xs-2"><b>Title</b></div>
                <div className="col-xs-2"><b>Views</b></div>
                <div className="col-xs-2"><b>Followers</b></div>
                <div className="col-xs-2"><b>Length</b></div>
                <div className="col-xs-2"><b>Concurrents</b></div>
                <div className="col-xs-2"><b>Date</b></div>
              </div>
              { this.state.pastStreams && this.state.pastStreams.length > 0 && this.state.pastStreams.map((stream, i) => {
                  return (
                    <div className="row-table" key={Math.floor(Math.random() * 9999999)} style={{ padding: '20px 0px'}}>
                      <div className="col-xs-2">{stream.title}</div>
                      <div className="col-xs-2">{stream.views}</div>
                      <div className="col-xs-2">{stream.followers}</div>
                      <div className="col-xs-2">{Globals.getElapsedSecs(stream.stream_length)}</div>
                      <div className="col-xs-2">{stream.avg_concurrent}</div>
                      <div className="col-xs-2">{moment(stream.created_at).format('')}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="col-md-12 analytics-module-container">
          <div className="analytics-module module">
            <span className={"sub-text"}>What are your most recent sales?</span>
            <div className={"module-body"}>
              <Loader active={!salesLoaded} />
              <table className={!salesLoaded ? 'hidden' : ''}>
                <tbody>
                <tr className={"table-row"}>
                  <th>Merchant</th>
                  <th>Link</th>
                  <th>Revenue</th>
                  <th>Earnings</th>
                  <th>Date Earned</th>
                </tr>
                {sales}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/*{
          this.state.campaigns_past.length > 0 &&
          <div>
            <h3 style={{ marginTop: '20px' }}>Past Bounties</h3>
            {
              this.state.campaigns_past.map((campaign) => {
                if (campaign.game){
                  return (
                    <CampaignStream
                      this={this}
                      twitchLive={campaign.game}
                      campaign={campaign}
                    />
                  )
                }
              })
            }
          </div>
        }*/}

        {/*
          <div className="analytics-module-container">
            <h3>{'What are your top clicked products?'}</h3>
            <div className="analytics-module">
              <table>
                <tbody>
                  <tr>
                    <th>Link</th>
                    <th>Clicks</th>
                  </tr>
                  {
                    this.state.content.map((content) => {
                      return (
                        <tr key={Math.floor(Math.random() * 9999999)}>
                          <td>{content.title}</td>
                          <td>{content.clicks}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className="analytics-module-container">
            <h3>{'What are your top clicked products?'}</h3>
            <div className="analytics-module">
              <table>
                <tbody>
                  <tr>
                    <th>Link</th>
                    <th>Clicks</th>
                  </tr>
                  {
                    this.state.products.map((product) => {
                      return (
                        <tr key={Math.floor(Math.random() * 9999999)}>
                          <td>{product.name}</td>
                          <td>{product.clicks}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        */}

        {/*<h3>{'How have your specific posts performed?'}</h3>
        <ContentContainer showLoadMore={true} />*/}

        {/* <div id="chartdiv" style={{height: '500px'}}></div> */}

        {/*<SlideOut
          active={this.state.slideOutActive}
          user={this.state.user}
          campaign={this.state.expandedCampaign}
          closeSlideOut={this.closeSlideOut}
          this={this}
          expandBountyDetails={this.expandBountyDetails}
          bountyDetailsExpanded={this.state.bountyDetailsExpanded}
          addVod={this.addVod.bind(this)}
        />*/}

        <ul className="shortcuts">
        	<li><a id="today">Today</a></li>
        	<li><a id="yesterday">Yesterday</a></li>
        	<li><a id="last_7d">Last 7 Days</a></li>
        	<li><a id="last_30d" className="active">Last 30 Days</a></li>
        	<li><a id="last_3m">Last 3 Months</a></li>
        	<li><a id="last_6m">Last 6 Months</a></li>
        	<li><a id="last_y">Last Year</a></li>
        	<li><a id="lifetime">Lifetime</a></li>
        	<li><a id="custom">Custom</a></li>
        </ul>

      </div>
    )
  }
}
