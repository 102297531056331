import React from 'react';

export default class SlideOutHeaderApp extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var campaign = this.props.campaign
    var offer = this.props.offer
    var brandColors = {}
    var bountyCategoryTags = offer && offer.bountyCategoryTags ? offer.bountyCategoryTags : []

    return (
      <div>
        <>
          <div className={`game-header ${this.props.scrollingDown ? 'minimize' : ''}`} style={{background: `rgba(0,0,0,0.32)`}}>
            <div className="game-header-banner" style={{backgroundImage: `url(${offer.bannerImageUrl ? offer.bannerImageUrl : offer.imageUrl})`}}>
              <div className="game-header-banner-darken"></div>
            </div>

            {/*<div className="game-header-outer">
              <div className="game-header-inner">
                <div className="game-header-img">
                  <div className="game-header-img-container">
                    <div className="game-header-img-outer">
                      <div className="game-header-img-inner">
                        <div className="game-header-img-padding"></div>
                        <div className="game-header-img-div" alt={game.name} style={{backgroundImage: `url(${imageUrl})`}}></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="game-header-meta">
                  <div className="game-header-name">
                    <h1 className="" style={{fontFamily: 'Barlow', fontWeight: '900', marginBottom: 0}}>{game.name}</h1>
                  </div>

                  {
                    game &&
                    game.totalViewers &&
                    <div>
                      <div className="game-header-meta-items">
                        <div className="game-header-meta-item">
                          <p className="">
                            <strong>{Globals.convertNumberFormat(game.totalViewers)}</strong>
                            Viewers
                          </p>
                        </div>
                        <div className="game-header-meta-item">
                          <p className="game-header-meta-dot">·</p>
                        </div>
                        <div className="game-header-meta-item">
                          <p className="">
                            <strong>{Globals.convertNumberFormat(game.channels)}</strong>
                            Channels
                          </p>
                        </div>
                        <div className="game-header-meta-item">
                          <p className="game-header-meta-dot">·</p>
                        </div>
                        <div className="game-header-meta-item">
                          <p className="">
                            <strong>#{Globals.convertNumberFormat(game.ranking)}</strong>
                          </p>
                        </div>
                        {
                          campaign.categories &&
                          campaign.categories.length > 0 &&
                          <div className="game-header-meta-item">
                            <p className="game-header-meta-dot">·</p>
                          </div>
                        }

                        <div className="game-header-meta-item">
                          {
                            campaign.categories &&
                            campaign.categories.split(',').map((c) => {
                              return (
                                <span className="brand-tag">{c}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }

                  {
                    (!game ||
                    (game &&
                    !game.totalViewers)) &&
                    <div className="game-header-meta-item">
                      <p className="">
                        <strong>Game not launched yet.</strong>
                      </p>
                    </div>
                  }

                  <div className="row">
                    <div className="col-md-3">
                      <h3 style={{fontFamily: 'Barlow', fontWeight: 900, margin: '0', fontSize: '32px', padding: 0}}>${campaign.proposedOffer ? campaign.proposedOffer : (content.proposedOffer ? content.proposedOffer : '--')}</h3>
                    </div>
                  </div>

                </div>
              </div>
            </div>*/}
          </div>
          <div className="slide-out-header">
            <div className="restaurant-main-img" style={{backgroundImage: `url(${offer.imageUrl})`, top: '-68px'}}></div>
            <div className="row row0" style={{paddingTop: '75px'}}>
              <h3>{offer.bountyTitle}</h3>
              <p className="sub-text" style={{margin: 0}}>{offer.offerCTA}</p>
              <p className="sub-text"><a href={offer.offerWebsiteUrl} target="_blank">{offer.offerWebsiteUrl}</a></p>
              {
                bountyCategoryTags && bountyCategoryTags.length > 0 &&
                <div className="row row0" style={{margin: '15px 0'}}>
                  {
                    bountyCategoryTags.map((c) => {
                      return <div className="bounty-tile-category" style={{float: 'initial'}}>{c}</div>
                    })
                  }
                </div>
              }
            </div>

            {/*<div className="restaurant-ratings">
              <div className="restaurant-stars-container">
                <div className="restaurant-stars">
                  <img src={stars} />
                </div>
              </div>
              <span className="sub-text ratings-text">{parseInt(restaurant.review_count)} reviews</span>
            </div>*/}
            {/*<div className="sub-text restaurant-sub">
              <span className="restaurant-price">{restaurant.price ? restaurant.price : '$'}</span>
              <span className="restaurant-dot">•</span>
              <span className="restaurant-categories">{restaurant.categories ? restaurant.categories : ''}</span>
              <span className="restaurant-dot">•</span>
              <span className="restaurant-city">{restaurant.city}</span>
            </div>*/}
          </div>
        </>
      </div>
    )
  }
}
