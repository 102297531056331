/**
 * Usage:
 *
 * Pass in an object with classes, in Object<string | boolean> format, for example:
 *
 * useClasses({
 *   loading: true
 * });
 *
 * returns "loading", while:
 *
 * useClasses({
 *   background: true? 'white-background': 'black-background'
 * });
 *
 * returns "white-background"
 *
 * @param classes
 */
export const useClasses = (classes: { [key: string]: string | boolean } ) => {

  if (!classes) {
    return '';
  }

  const classNames = Object.keys(classes);
  const activeClasses = classNames.filter((className) => classes[className]);

  const computedClassNames = activeClasses.map(className => {
    const value = classes[className];

    if (typeof value === 'boolean') {
      return className;
    } else if (value) {
      return value + '';
    } else {
      return '';
    }
  });

  return computedClassNames.join(' ').trim();

}
