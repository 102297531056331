export const lightTheme = {
  // main_bg: '#FAFAFA',
  // main_bg: '#f6faff',
  main_bg: '#fafafa',
  // main_bg: '#f5f6f8',
  grad_bg: '#fff',
  // grad_bg: '#f5f5f5',
  menu_highlight: '#45b9fe26',
  app_menu: '#ffffff',
  module: '#ffffff',
  module_hover: '#ffffff',
  slide_out: '#fafafa',
  color: '#000000',
  color_opp: '#ffffff',
  subtext: '#808080',
  primary: '#1b85f8',
  error: '#CF6679',
  dark_input: '#eeeeee',
  dark_input_focus: '#a9aaad',
  dark_input_darker: '#a9aaad',
  add_to_campaign: '#fff',
  secondary: '#fff',
  pilltag_blue: '#bee9ff',
  pilltag_standard: '#e8eef4',
  secondary_pill: '#dae0e6',
  weave_primary: '#fd8d31',
  plus_primary: '#676767',
  plus_secondary: '#cbcbcb',
  dark_accent: '#ffffff',
  darken_opacity: '.22',
  shipping_stage: '#eeeeee',
  list_hover: '#fafafa',
  slider_fill: '#1fb5ef',
  borders: '#f1f3f8',
  borders_hover: '#ddd',
  tabHighlight: '#f7f9fb',
  tabHighlightHover: '#f7f9fb',
  softText: '#242D3E',
}

export const darkTheme = {
  main_bg: '#202225',
  // grad_bg: '#202225',
  grad_bg: '#141517',
  menu_highlight: '#45b9fe26',
  app_menu: '#141517',
  module: '#25282d',
  module_hover: '#25282d',
  slide_out: '#202225',
  color: '#ffffff',
  color_opp: '#000000',
  subtext: '#BDBBC0',
  primary: '#1b85f8',
  error: '#CF6679',
  // dark_input: '#2e3036',
  dark_input: '#383b40',
  dark_input_focus: '#212226',
  dark_input_darker: '#212226',
  add_to_campaign: '#202225',
  secondary: '#202225',
  pilltag_blue: '#296dfb',
  pilltag_standard: '#383b40',
  // secondary_pill: '#202225',
  secondary_pill: '#383b40',
  weave_primary: '#fd8d31',
  plus_primary: '#cbcbcb',
  plus_secondary: '#676767',
  dark_accent: '#1d1e1f',
  darken_opacity: '.52',
  shipping_stage: '#42454a',
  list_hover: '#202225',
  slider_fill: '#1fb5ef',
  borders: '#4b4f52',
  borders_hover: '#3b3d42',
  tabHighlight: '#3a6bc7',
  tabHighlightHover: '#3d5177',
  softText: '#fafafa'
}
