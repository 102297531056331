import React from 'react';
import PlaidLink from 'react-plaid-link';
import Cookies from 'js-cookie';

export default class PlaidSlim extends React.Component {

  constructor(props){
    super(props)
    // var user = Globals.getUser()
    this.state = {
      userid: Cookies.get('userid')
    }
  }

  UNSAFE_componentWillMount(){

  }

  componentDidMount(){

  }

  handleOnSuccess(token, metadata) {
    console.log(token, metadata)
    this._storeAccessToken(token, metadata).done((response) => {
      console.log('connected!')
      console.log(response)
      this.props.getPersonalCards()
    })
    // send token to client server
  }

  handleOnExit() {
    // handle the case when your user exits Link
  }

  _storeAccessToken(token, metadata){
    return $.ajax({
      method: 'POST',
      url: '/api/Plaid/storeAccessToken',
      data: { public_token: token, metadata: metadata }
    })
  }

  render() {

    return (
      <div>
        <PlaidLink
          className="plaid-btn"
          clientName="Weave"
          env="production"
          product={["transactions"]}
          publicKey="6153b9305959339fba88b310b32d0c"
          webhook={`https://ea5u4dhbfa.execute-api.us-west-2.amazonaws.com/beta/plaid/${this.state.userid}`}
          onExit={this.handleOnExit.bind(this)}
          onSuccess={this.handleOnSuccess.bind(this)}>
          + Link Card
        </PlaidLink>
      </div>
    )

  }
}
