import React from 'react';
import TaxInfoHeader from './taxinfo-header.jsx'
import TaxInfoDisplay from './taxinfo-display.jsx'

export default class TaxInfoSubmit extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { firstName, lastName, businessType, businessName, ein, ssn, address, address2, city, state, zip } = this.props.inputDetails
    ein = ein.replace(/[^0-9]+/g, ''); ssn = ssn.replace(/[^0-9]+/g, '');

    return (
      <div>
        <TaxInfoHeader
          title={`Everything look right?`}
          description={`Review and save your business info.`}
        />

        <div>
          { businessType == 'Sole-Proprietorship/Individual' &&
          <div className="row" style={{marginTop: '25px'}}>
            <TaxInfoDisplay label="First Name" value={firstName} />
            <TaxInfoDisplay label="Last Name" value={lastName} />
          </div>
          }
          <div className="row" style={{marginTop: '25px'}}>
            <TaxInfoDisplay label="Business Name" value={businessName} />
            <TaxInfoDisplay label={businessType == 'Sole-Proprietorship/Individual' ? 'Social Security Number (SSN)' : 'Employer Identification Number (EIN)'} value={businessType == 'Sole-Proprietorship/Individual' ? `${ssn.slice(0,3)}-${ssn.slice(3,5)}-${ssn.slice(5,9)}` : `${ein.slice(0,2)}-${ein.slice(2,9)}`} />
          </div>
          <div className="row" style={{marginTop: '25px'}}>
            <TaxInfoDisplay label="Business Entity Type" value={businessType} />
            <TaxInfoDisplay label="Address" value={`${address}${address2.length > 0 ? ' '+address2 : ''}, ${city}, ${state} ${zip}`} />
          </div>
          <div className="row" style={{marginTop: '25px'}}>
            <div className="col-xs-1">
              <input type="checkbox" />
            </div>
            <div className="col-xs-11" style={{paddingLeft: 0}}>
              <p className="sub-text">I confirm that the information is accurate and I authorize Weave to generate Form W-9 documents on my behalf.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
