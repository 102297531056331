import { memo } from 'react';
// import OutreachListSearch from '../outreach-list-search'
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'
import FilterOption from './filter-option'
import Globals from '../../../global-functions.js'

const FilterList = ((props) => {
  var { filter, type, changeFilter, _this } = props

  return (
    <>
      <div className="row row0">
        <FilterOption title="In Any List" label="include" filterType="toggle-selector" filter={filter} index={4} changeFilter={changeFilter} type={type} _this={_this} />
        <FilterOption title="Exclude All Lists" label="exclude" filterType="toggle-selector" filter={filter} index={4} changeFilter={changeFilter} type={type} _this={_this} />

        <div className="filter-option-container">
          <div className="row row0">
            <label>Brand Lists</label>
          </div>
          <div className="row row0">
            <SearchDropdown endpoint="getOutreachLists" preload={true} index={0} type={type} field="name" dataKey="outreachLists" changeFilter={changeFilter} placeholder="Select brand list..." _this={_this} />
            {/*<OutreachListSearch changeFilter={changeFilter} type={type} index={0} _this={_this} />*/}
          </div>
          <div className="row row0">
            { Globals.checkFilterVals(filter[0]) && filter[0].map((l) => {
              return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={0} deleteTag={changeFilter} /> )
            }) }
          </div>
        </div>

        <div className="filter-option-container">
          <div className="row row0">
            <label>Exclude Brand Lists</label>
          </div>
          <div className="row row0">
            <SearchDropdown endpoint="getOutreachLists" preload={true} index={1} type={type} field="name" dataKey="outreachLists" changeFilter={changeFilter} placeholder="Exclude brand list..." _this={_this} />
            {/*<OutreachListSearch changeFilter={changeFilter} type={type} index={1} _this={_this} />*/}
          </div>
          <div className="row row0">
            { Globals.checkFilterVals(filter[1]) && filter[1].map((l) => {
              return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={1} deleteTag={changeFilter} /> )
            }) }
          </div>
        </div>

        {/*<div className="filter-option-container">
          <div className="row row0">
            <label>Creator Lists</label>
          </div>
          <div className="row row0">
            <SearchDropdown endpoint="getOutreachLists" preload={true} index={2} type={type} field="name" dataKey="outreachLists" changeFilter={changeFilter} placeholder="Select creator list..." _this={_this} />
          </div>
          <div className="row row0">
            { filter[2] && filter[2].length > 0 && filter[2].map((l) => {
              return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={2} deleteTag={changeFilter} /> )
            }) }
          </div>
        </div>
        <div className="filter-option-container">
          <div className="row row0">
            <label>Exclude Creator Lists</label>
          </div>
          <div className="row row0">
            <SearchDropdown endpoint="getOutreachLists" preload={true} index={3} type={type} field="name" dataKey="outreachLists" changeFilter={changeFilter} placeholder="Select creator list..." _this={_this} />
          </div>
          <div className="row row0">
            { filter[3] && filter[3].length > 0 && filter[3].map((l) => {
              return ( <PillTag key={l.id} _this={_this} icon={""} name={l.name} item={l} type={type} index={3} deleteTag={changeFilter} /> )
            }) }
          </div>
        </div>*/}
      </div>
    </>
  )
})

export default memo(FilterList);
