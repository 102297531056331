import React from 'react'
import Globals from '../../global-functions.js'
import moment from 'moment'

export default class OutreachSettings extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      collapsed: false,
      messages: [],
      text: '',
      dripId: '99',
      dripCampaigns: [],
      gmails: []
    }
  }

  componentDidMount(){
    // this.getOutreachSettings()
    this.getConnectedGmail()
  }

  updateFilter(type){ }
  async gmailLogin(){ var res = await $.ajax({ method: 'GET', url: '/api/YouTube/gmailAuthUrl', data: {} }); location.replace(res) }
  async listLabels(){ return $.ajax({ method: 'GET', url: '/api/Gmail/listLabels', data: {} }) }
  async sendEmail(){ return $.ajax({ method: 'POST', url: '/api/Gmail/sendEmail', data: {} }) }
  async getConnectedGmail(){ var res = await this._getConnectedGmail(); this.setState(res) }
  async _getConnectedGmail(){ return $.ajax({ method: 'GET', url: '/api/Gmail/getConnectedGmail', data: {  } }) }
  async createDripCampaign(){ var res = await this._createDripCampaign(); location.replace(`/outreach/drip/${res}`) }
  async _createDripCampaign(){ return $.ajax({ method: 'POST', url: '/api/Drip/create', data: {  } }) }

  render() {
    var _stages = [], dripCampaigns = this.state.dripCampaigns
    var sortFilter
    var { gmails } = this.state

    return (
      <div>
        <div className="mgmt-section">
          <div className="mgmt-menu">
            <h3>Outbound Settings</h3>
            <p className="sub-text" style={{margin: 0, textDecoration: 'underline'}}><a href="http://support.weavesocial.com/en/articles/5435087-create-influencer-reach-out-emails" target="_blank">Read tips how to maximize your outreach here.</a></p>

            <div>
              <button className="btn-action btn-secondary" style={{float: 'initial'}} onClick={this.listLabels.bind(this)}>List Labels</button>
            </div>
            <div>
              <button className="btn-action btn-secondary" style={{float: 'initial'}} onClick={this.sendEmail.bind(this)}>Send Email</button>
            </div>
          </div>
          <div className={`mgmt-list ${this.state.collapsed ? 'collapsed' : ''} mgmt-menu-dark`}>
            <div className="row row0" style={{width: '700px'}}>
              <div className="module">
                <h3>Manage Email Address</h3>
                <p className="sub-text">Weave allows your Gmail email address to be connected so we can automate sending emails on your behalf to brands. Weave only sends emails from your account when you schedule brands for outreach.</p>
                <div className="gmail-signin-container">
                  <a onClick={this.gmailLogin.bind(this)}><button className="gmail-signin"><img src={Globals.iconGeneral('google_dark_normal.svg')} /><span>Sign in with Google</span></button></a>
                </div>
              </div>
            </div>

            <div className="row row0" style={{width: '700px'}}>
              <div className="module">
                <h3>Connected Email Addresses</h3>
                <p className="sub-text"></p>
                { gmails.map((gmail) => {
                  return (
                    <p>{gmail.email}</p>
                  )
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
