import { memo } from 'react';
import BrandTileProduct from './brand-tile-product';
import { BrandTileApp } from './brand-tile-app';
import BrandTileGame from './brand-tile-game.jsx';
import BrandTileRestaurant from './brand-tile-restaurant.jsx';

const BrandTile = ((props) => {
  var { campaign, size, viewOffer, _this } = props
  var offer = campaign && campaign.offer ? campaign.offer : campaign
  var restaurant = campaign.restaurant
  // campaign.categories = campaign.categories && campaign.categories.indexOf(',') > -1 ? campaign.categories.split(',') : campaign.categories

  return (
    <div>
      <div className={size} style={{padding: '15px'}}>
        <div className="module brand-tile brand-tile-offer" style={{overflow: 'initial', padding: 0}} onClick={viewOffer.bind(_this, campaign)}>
          { campaign.brandType == 'product' &&
            <BrandTileProduct
              imageUrl={campaign.imageUrl}
              bountyImg={campaign.bannerImageUrl}
              tagLine={campaign.offerTagline}
              tags={campaign.bountyCategoryTags}
              name={campaign.bountyTitle}
              bountyCredit={offer.bountyCredit}
            />
          }

          { campaign.brandType == 'app' &&
            <BrandTileApp
              imageUrl={campaign.imageUrl}
              bountyImg={campaign.bannerImageUrl}
              tagLine={campaign.offerTagline}
              tags={campaign.bountyCategoryTags}
              name={campaign.bountyTitle}
              bountyCredit={offer.bountyCredit}
            />
          }

          { campaign.brandType == 'restaurant' &&
            <BrandTileRestaurant
              this={this}
              campaign={campaign}
              offer={offer}
              restaurant={restaurant}
              bountyCredit={offer.bountyCredit}
            />
          }

          { campaign.brandType == 'game' &&
            <BrandTileGame
              this={this}
              campaign={campaign}
              offer={offer}
              bountyCredit={offer.bountyCredit}
            />
          }
        </div>
      </div>
    </div>
  )
})

export default memo(BrandTile)
