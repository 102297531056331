import { memo } from 'react';
import Globals from '../../global-functions.js'
import Prism from 'prismjs'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import Plain from 'slate-plain-serializer'
import Html from 'slate-html-serializer'

const CreateMessageInput = ((props) => {
  var { value, onChange, renderMark, focusEditor, _this } = props

  return (
    <>
      <div className="chat-input-container">
        <div className="chat-input" style={{position: 'relative', margin: 0, width: '100%', bottom: 0}}>
          <div className="chat-input-text" style={{ minHeight: '175px', cursor: 'text' }} onClick={focusEditor.bind(this)}>
            <Editor
              id="intercom-chat-editor"
              value={value}
              onChange={onChange}
              plugins={plugins}
              renderMark={renderMark}
              autoCorrect={true}
              spellCheck={true}
              placeholder={'Add Email Message Template...'}
            />
          </div>
        </div>
      </div>
    </>
  )
})

function MarkHotkey(options) {
  const { type, key } = options
  return {
    onKeyDown(event, editor, next) {
      if ( (!event.ctrlKey || event.key != key) && (event.key != 'Meta' || event.key != key) ) return next()
      event.preventDefault()
      editor.toggleMark(type)
    }
  }
}

const plugins = [ MarkHotkey({ key: 'b', type: 'bold' }), MarkHotkey({ key: '`', type: 'code' }), MarkHotkey({ key: 'i', type: 'italic' }), MarkHotkey({ key: '~', type: 'strikethrough' }), MarkHotkey({ key: 'u', type: 'underline' }) ]
function BoldMark(props) { return <strong>{props.children}</strong> }
function CodeNode(props) { return ( <pre {...props.attributes}><code>{props.children}</code></pre> ) }
const BLOCK_TAGS = { blockquote: 'quote', p: 'paragraph', pre: 'code' }
const MARK_TAGS = { em: 'italic', strong: 'bold', u: 'underline' }

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK_TAGS[el.tagName.toLowerCase()]
      if (type) { return { object: 'block', type: type, data: { className: el.getAttribute('class') }, nodes: next(el.childNodes) } }
    },
    serialize(obj, children) {
      if (obj.object == 'block') {
        switch (obj.type) {
          case 'code': return ( <pre><code>{children}</code></pre> )
          case 'paragraph': return <p className={obj.data.get('className')}>{children}</p>
          case 'quote': return <blockquote>{children}</blockquote>
        }
      }
    },
  },
  // Add a new rule that handles marks...
  {
    deserialize(el, next) {
      const type = MARK_TAGS[el.tagName.toLowerCase()]
      if (type) { return { object: 'mark', type: type, nodes: next(el.childNodes) } }
    },
    serialize(obj, children) {
      if (obj.object == 'mark') {
        switch (obj.type) {
          case 'bold': return <strong>{children}</strong>
          case 'italic': return <em>{children}</em>
          case 'underline': return <u>{children}</u>
        }
      }
    },
  },
]

const html = new Html({ rules })
export default memo(CreateMessageInput);
