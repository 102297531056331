import { Component, memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'
import Icon from '../../icon'

class FilterSearch extends Component {
  constructor(props){
    super(props)

    this.state = {
      active: false,
      val: ''
    }
  }

  chooseFilter(i, item){
    var { filter, changeFilter, type } = this.props
    filter[i].push(item)
    changeFilter(type[i], filter[i])
  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }
  onEnter(type, e){ if (e.key === 'Enter'){ var { filter, type, changeFilter } = this.props; changeFilter(type, filter[0].concat([e.target.value])); this.setState({ [type]: '' }) } }
  deleteTag(tag){  }

  render(){
    var { filter, changeFilter, type, _this } = this.props
    var { val, dropdown } = this.state

    return (
      <>
        <div className={`crm-filter crm-search ${dropdown ? 'active' : ''}`} style={{background: 'none'}}>
          <div className="crm-search-container">
            <div className="crm-search-inner">
              <Icon className="crm-search-glass" iconPack="search" defaultColor={true} />
              <input className="search-filter-main-search" value={val} style={{margin: 0, width: '100%'}} placeholder="Search brands or keywords..." onChange={this.onChange.bind(this, 'val')} onKeyDown={this.onEnter.bind(this, type)}/>
            </div>
          </div>

          {filter[0] && filter[0].length > 0 &&
            <div className="row row0" style={{marginTop: '8px'}}>
              <div className="row row0 crm-filters" style={{margin: 0, padding: '0 8px 8px 8px'}}>
                {filter[0].map((tag) => {
                  return (
                    <div key={tag} className="crm-filter" style={{ background: "none" }}>
                      <PillTag icon={""} type={type} index={0} name={tag} item={tag} type={type} index={0} deleteTag={changeFilter} _this={_this} />
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}

export default memo(FilterSearch);
