import { Component, memo } from 'react';
import PlaidSlim from './plaid-slim.jsx';
import StripeCard from './stripe-card.jsx';
import BlinkingDot from './blinking-dot.jsx';
import Cookies from 'js-cookie';

class AccountCards extends Component {

  constructor(props){
    super(props)
    this.state = {
      user: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : {},
      userid: Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')).userid : '',
      stripeCard: false,
      cards: {},
      personalCards: [],
      verifyLast4: ''
    }
  }

  componentDidMount(){
    this.getCards()
    this.getPersonalCards()
  }

  onChangeInput(field, e){
    if (typeof e == 'string'){ this.setState({ [field]: e }) }
    else { this.setState({ [field]: e.target.value }) }
  }

  getCards(){ this._getCards().done((response) => { this.setState({ cards: response.cards }) }) }
  _getCards(){ return $.ajax({ method: 'GET', url: '/api/Card/getCards', data: { userid: this.state.user.userid } }) }
  replaceCard(card){ this._replaceCard(card).done((response) => { this.getCards() }) }
  _replaceCard(card){ return $.ajax({ method: 'POST', url: '/api/Card/replace', data: { userid: this.state.user.userid, card: card } }) }
  disableCard(card){ this._disableCard(card).done((response) => { this.getCards() }) }
  _disableCard(card){ return $.ajax({ method: 'DELETE', url: '/api/Card/disable', data: { userid: this.state.user.userid, card: card } }) }
  activateCard(card){ this._activateCard(card).done((response) => { this.getCards() }) }
  _activateCard(card){ return $.ajax({ method: 'PUT', url: '/api/Card/activate', data: { userid: this.state.user.userid, card: card, last4: this.state.verifyLast4 } }) }
  createCard(type){ this._createCard(type).done((response) => { this.getCards() }) }
  _createCard(type){ return $.ajax({ method: 'POST', url: '/api/Card/create', data: { userid: this.state.user.userid, type: type } }) }

  getCard(viewType, proceed){
    if (proceed){
      this._getCard(viewType).done((response) => {
        var c = this.state.cards
        var cards = { physical: c.physical, virtual: response.card }
        this.setState({ cards: cards })
      })
    }
  }

  _getCard(viewType){ return $.ajax({ method: 'GET', url: '/api/Card/get', data: { userid: this.state.userid, viewType: viewType } }) }

  getPersonalCards(){ this._getPersonalCards().done((response) => { this.setState({ personalCards: response }) }) }
  _getPersonalCards(){ return $.ajax({ method: 'GET', url: '/api/Plaid/get', data: { } }) }
  revokeCard(id){ this._revokeCard(id).done((response) => { this.getPersonalCards() }) }
  _revokeCard(id){ return $.ajax({ method: 'POST', url: '/api/Plaid/revoke', data: { id: id } }) }

  render() {

    var user = this.props.user

    return (
      <div id="cards">
        <div className="row">
          <div className="col-xs-6">
            <h3 style={{ margin: '20px 0 32px 0', padding: 0, fontSize: '20px', textTransform: 'initial' }}>
              <span>My Linked Cards</span>
              { !user.connectedCard && <BlinkingDot /> }
            </h3>
          </div>
          <div className="col-xs-6">
            <div className="row row0">
              <PlaidSlim this={this} getPersonalCards={this.getPersonalCards.bind(this)} />
            </div>
          </div>
        </div>

        <p className="sub-text">
          Weave identifies your purchases with partner brands & restaurants to reimburse you the exact amount you spend so you get their products for free! Weave does not track or store any purchases that are not related to our brand partner network.
        </p>
        <div className="row row0" style={{position: 'relative', marginTop: '25px', minHeight: '250px'}}>
          { this.state.personalCards && this.state.personalCards.map((card) => {
              return (
                <div className="module" style={{marginBottom: '10px', padding: '12px 25px'}}>
                  <p style={{margin: 0}}>
                    <span>{card.institution_name + ' ' + card.name}</span>
                    <span style={{marginLeft: '12px'}}><b>{'••' + card.last4}</b></span>
                    <span style={{float: 'right', cursor: 'pointer'}}><a onClick={this.revokeCard.bind(this, card.id)}>Revoke</a></span>
                  </p>
                </div>
              )
            })
          }

          { (!this.state.personalCards || this.state.personalCards.length == 0) &&
            (
              <div>
                <div className="results-empty-message">
                  <h3>No cards connected.</h3>
                  <p className="sub-text">Connect at least 1 card to redeem free meals.</p>
                </div>
              </div>
            )
          }
        </div>



        <h3 style={{ margin: '20px 0 32px 0', padding: 0, fontSize: '20px', textTransform: 'initial' }}>Weave Smart Payment Cards</h3>

        <div className="row row0">
          <h5 style={{ marginTop: '0px', marginBottom: '15px' }}>Physical Card</h5>
          { this.state.cards.physical &&
            <div className="module">
              <div className="row row0">
                <div className="col-md-3" style={{position: 'relative'}}>
                  { <StripeCard this={this} card={this.state.cards.physical} getCard={this.getCard} scale={0.35} /> }
                </div>
                <div className="col-md-9">
                  { this.state.cards.physical.status == 'active' &&
                    <div>
                      <p style={{margin: 0}}>Use your card for qualifying bounties.</p>
                      <p>Activated: <b>{moment(this.state.cards.physical.estDeliveryTime).format('dddd, MMMM Do')}</b></p>
                      <button className="btn-action btn-primary" style={{marginTop: '7px'}} onClick={this.replaceCard.bind(this, this.state.cards.physical)}>Replace Card</button>
                    </div>
                  }

                  { this.state.cards.physical.status == 'inactive' &&
                    <div>
                      <p style={{margin: 0}}>Your card is on the way!</p>
                      <p>Estimated Delivery: <b>{moment(this.state.cards.physical.estDeliveryTime).format('dddd, MMMM Do')}</b></p>
                      <div className="row">
                        <div style={{display: 'inline-block', float: 'left'}}>
                          <input placeholder="Verify Last 4 Digits" value={this.state.verifyLast4} onChange={this.onChangeInput.bind(this, 'verifyLast4')} style={{ background: '#131313', padding: '4px 12px', border: 'none', margin: '6px 0 0 13px', width: '155px' }} />
                        </div>
                        <div style={{display: 'inline-block', float: 'left'}}>
                          <button className="btn-action btn-primary" style={{marginTop: '7px', marginLeft: '15px'}} onClick={this.activateCard.bind(this, this.state.cards.physical)}>Activate Card</button>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          }

          { !this.state.cards.physical &&
            <div className="row row0">
              <button className="btn-action btn-primary" onClick={this.createCard.bind(this, 'physical')}>+ Create Physical Card</button>
            </div>
          }
        </div>

        <div className="row row0">
          <h5 style={{ marginTop: '0px', marginBottom: '15px' }}>Virtual Card</h5>
          { this.state.cards.virtual &&
            <div className="module">
              <div className="row row0">
                <div className="col-md-3" style={{position: 'relative'}}>
                  { <StripeCard this={this} card={this.state.cards.virtual} getCard={this.getCard} scale={0.35} /> }
                </div>
                <div className="col-md-9">
                  <p>Add this virtual card to your Apple/Google Wallet or your checkout screen with a Weave partner brand. This card will sync with any of your current bounties so you can purchase the brand's product(s) without using your own money (so you get it free!).</p>
                  <button className="btn-action btn-primary" onClick={this.replaceCard.bind(this, this.state.cards.virtual)}>Replace Card</button>
                </div>
              </div>
            </div>
          }

          { !this.state.cards.virtual &&
            <div className="row row0">
              <button className="btn-action btn-primary" onClick={this.createCard.bind(this, 'virtual')}>+ Create Virtual Card</button>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default memo(AccountCards)
