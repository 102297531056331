import React from 'react'
import {Link} from 'react-router-dom'
import Globals from '../../global-functions.js'
import moment from 'moment'
import WatchlistTable from './watchlist-table'

export default class OutreachWatchlist extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      collapsed: false,
      messages: [],
      text: '',
      dripId: '99',
      dripCampaigns: [],
      usernames: [],
      watchlist: { usernames: [] }
    }
  }

  componentDidMount(){
    const { watchlistId } = this.props.match.params
    this.setState({ watchlistId }, () => { this.getWatchlist() })
  }

  updateFilter(type){ }

  // gmailLogin(){ $.ajax({ method: 'GET', url: '/api/YouTube/gmailAuthUrl', data: {} }).done((data) => { location.replace(data) }) }
  // listLabels(){ $.ajax({ method: 'GET', url: '/api/Gmail/listLabels', data: {} }).done((data) => { console.log(data) }) }
  // sendEmail(){ $.ajax({ method: 'POST', url: '/api/Gmail/sendEmail', data: {} }).done((data) => { console.log(data) }) }
  getWatchlist(){ this._getWatchlist().done((response) => { this.setState(response) }) }
  _getWatchlist(){ return $.ajax({ method: 'GET', url: '/api/BrandSearch/getWatchlist', data: { watchlistId: this.state.watchlistId } }) }
  createDripCampaign(){ this._createDripCampaign().done((response) => { location.replace(`/outreach/drip/${response}`) }) }
  _createDripCampaign(){ return $.ajax({ method: 'POST', url: '/api/Drip/create', data: {  } }) }

  chooseSort(sort) {
    var direction = this.state.sortFilter.indexOf(sort) > -1 && this.state.sortFilter.indexOf("DESC") > -1 ? "ASC" : "DESC";
    var sortFilter = sort + " " + direction;
    this.setState({ sortFilter: sortFilter, });
    store('brandListSortFilter', sortFilter);
    setTimeout(() => { this.getBrands(); }, 50);
  }

  selectInfluencer(inf, e) {
    var id = inf == "all" ? inf : inf.id;
    var brands = this.state.brands;
    brands.forEach((i) => {
      if (id == "all") { i.selectedActive = !this.state.selectedActive; }
      else { if (i.id == id) { i.selectedActive = !i.selectedActive; } }
    });
    this.setState({ brands: brands, selectedActive: id == "all" ? !this.state.selectedActive : this.state.selectedActive, });
  }

  render() {
    var _stages = [], { watchlist } = this.state
    var sortFilter, { usernames } = watchlist

    return (
      <div>
        <div className="mgmt-section">
          <div className="mgmt-menu">
            <h3 style={{marginTop: 0}}>Outbound Watchlist</h3>
            <p className="sub-text" style={{margin: 0, textDecoration: 'underline'}}><a href="http://support.weavesocial.com/en/articles/5435087-create-influencer-reach-out-emails" target="_blank">Read tips how to maximize your outreach here.</a></p>
          </div>
          <div className={`mgmt-list ${this.state.collapsed ? 'collapsed' : ''} mgmt-menu-dark`}>
            <div className="crm-actions">

              {/*<div className="row row0 crm-filters">
                <div className="crm-filter">
                  <div className="crm-filter-block">
                    In Network
                  </div>
                </div>
                <div className="crm-add-filter">
                  + Add filter
                </div>
              </div>*/}
              <hr />
              <div className="row row0 crm-filters" style={{margin: 0}}>
                <div className="crm-inline" style={{marginRight: '25px'}}>
                  <h5>{watchlist.usernames.length} Email Drip{watchlist.usernames.length == 1 ? '' : 's'}</h5>
                </div>
                {/*<div className="crm-inline">
                  <button className="crm-btn">
                    New Content
                  </button>
                </div>
                <div className="crm-inline">
                  <button className="crm-btn">
                    Tag
                  </button>
                </div>
                <div className="crm-inline">
                  <button className="crm-btn">
                    More
                  </button>
                </div>*/}
              </div>
            </div>

            <div className="content-library">
              <div className="content-library-inner">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row row0">
                      { usernames && usernames.length > 0 && <WatchlistTable this={this} usernames={usernames} chooseSort={this.chooseSort} selectInfluencer={this.selectInfluencer} /> }
                      { (!usernames || usernames.length == 0) &&
                        <div className="results-empty-message">
                          <h3>No Usernames</h3>
                          <p className="sub-text">Create your first email drip campaign to outreach and onboard influencers.</p>
                        </div>
                      }
                      { this.props.loading && <Loader active={true} /> }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
