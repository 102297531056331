import { memo } from 'react'
import Globals from '../../global-functions.js'
import Icon from '../icon'

const PillTag = ((props) => {
  var { _this, type, index, action, name, item, className, icon, toggleSubFilterDropdown, chooseTag, deleteTag, ct, _this, perc, background, marginBottom, cursor, style, iconStyle } = props
  var html = '';
  style=style||{}; iconStyle=iconStyle||{}; className=className||''; icon=icon||''
  if (background) { style.background = background }
  if (marginBottom) { style.marginBottom = marginBottom }
  if (cursor) { style.cursor = cursor }

  // if (type == 'object'){
  //   style = background ? { background: background } : {background: '#8affcf'}
  //   html = (
  //     <div id={`sub-filter-span-${name}`} className={`pill-tag ${className}`} onClick={toggleSubFilterDropdown.bind(_this, name)} style={style}>
  //       <span><img src={icon} style={{ width: '14px', margin: '-3px 8px 0 0' }} /></span>
  //       <span>{name}</span>
  //       <img className="pill-tag-delete" src={Globals.icon('close')} style={iconStyle} onClick={deleteTag.bind(_this, type, name)} />
  //     </div>
  //   )
  // }
  // else {
    html = (
      <>
      { chooseTag &&
        <div className={`pill-tag ${className}`} style={style} onClick={chooseTag.bind(_this, type[index], name)}>
          <p className="pill-tag-text">{icon}{name}</p>
        </div>
      }
      { deleteTag &&
        <div className={`pill-tag pill-tag-with-delete ${className}`} style={style} onClick={deleteTag.bind(_this, index != null ? type[index] : type, item, 'delete')}>
          <p className="pill-tag-text">{icon}{name}</p>
          <Icon className="pill-tag-delete" style={iconStyle} iconPack="cross" defaultColor={true} width="8px" />
        </div>
      }
      { (!chooseTag && !deleteTag) &&
        <div className={`pill-tag ${className}`} style={style}>
          <p className="pill-tag-text">{icon}{name}</p>
        </div>
      }
      </>
    )
  // }

  return (
    <div>{html}</div>
  )
})

export default memo(PillTag);
