import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'
import InvoiceSummary from './invoice-summary.jsx'
import InvoicePill from './invoice-pill.jsx'

export default class InvoiceHeader extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      downloading: false
    }
  }

  componentDidMount(){

  }

  downloadInvoice(invoiceId){
    if (!this.state.downloading){
      this.setState({ downloading: true })
      this._downloadInvoice(invoiceId).done((response) => {
        if (response){ window.open(response,'_blank') }
        setTimeout(() => { this.setState({ downloading: false }) }, 150)
      })
    }
  }
  _downloadInvoice(invoiceId){ return $.ajax({ method: 'GET', url: '/api/Invoice/downloadInvoice', data: { invoiceId: invoiceId } }) }

  onSubmit(e){
    e.preventDefault();
  }

  render() {
    function formatAmount(amount){ return (<span><span className="sub-text invoice-dollar">$</span>{Globals.numberWithCommas(amount)}</span>) }
    var { downloading } = this.state
    var { show, invoice } = this.props
    var { id, invoiceId, name, company, email, amount, deliverables, notes, status, invoiceOwner, address, dueDate } = invoice

    return (
      <div className={`invoice-header-container ${show ? 'show' : ''}`}>
        <header className={`invoice-header`}>
          <div className="row row0">
            <InvoicePill invoice={invoice} type="status" />
            <InvoicePill invoice={invoice} type="time" />
            <div className="invoice-header-actions">
              <div className="invoice-header-action" onClick={this.downloadInvoice.bind(this, invoiceId)}>
                <IconPack icon={'download'} className={downloading ? 'downloading' : ''} color={'#fff'} style={{width: '18px', cursor: 'pointer'}} />
              </div>
            </div>
          </div>
          <div className="row row0">
            <div className="invoice-title">
              <p className="invoice-title-text">{company || name}</p>
            </div>
          </div>
          <div className="row row0">
            <p className="invoice-num">Invoice <b>#{id}</b></p>
          </div>
          {/*<img className="logo-hero" src={Globals.icon('logo-text', 'white')} alt="Weave Logo"/>*/}
        </header>
        <InvoiceSummary invoice={invoice} />
      </div>
    )
  }
}
