import React from 'react'
import Globals from '../global-functions.js'
import HoverTipItem from './hover-tip-item'

export default class HoverTip extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      open: false
    }
  }

  onHoverTip(open){ this.setState({ open }) }

  render(){
    var html, { open } = this.state
    html = (
      <div className={`search-tip-dropdown ${open ? 'active' : ''}`}>
        <div className="search-tip-point">
          <h4>Filter Descriptions</h4>
          <p style={{ marginBottom: '0' }}>Understand how to use each brand filter</p>
          <hr />
          <HoverTipItem title="Lists" subtext="" />
          <HoverTipItem title="Lead Status" subtext="" />
          <HoverTipItem title="Sponsorships" subtext="" />
          <HoverTipItem title="Hashtag" subtext="" />
          <HoverTipItem title="Mention" subtext="Return brands based off of which influencers have mentioned a brand & what other brands they have mentioned or list which brands an influencers have mentioned them" />
          <HoverTipItem title="Industry" subtext="Select which industries the brand is in - each brand is associated with only 1 industry" />
          <HoverTipItem title="Keyword" subtext="Select which specific keywords are associated with the brand" />
          <HoverTipItem title="Brand" subtext="Select which specific brands you want or don't want" />
          <HoverTipItem title="Country HQ" subtext="Restrict which countries the brand is headquartered" />
          <HoverTipItem title="Influencer" subtext="" />
          <HoverTipItem title="Language" subtext="" />
          <HoverTipItem title="Employees" subtext="" />
          <HoverTipItem title="Email" subtext="" />
        </div>
      </div>
    )

    return (
      <>
        <img className="search-tip-icon" onMouseEnter={this.onHoverTip.bind(this, true)} onMouseLeave={this.onHoverTip.bind(this, false)} src={Globals.icon('question')} />
        {html}
      </>
    )
  }

}
