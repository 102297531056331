import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'
import FilterOption from './filter-option'
import FilterListDropdown from './filter-list-dropdown'
import Globals from '../../../global-functions.js'

function formatList(list){ return list.map((s) => { return { id: s, name: s } }) }

const FilterLead = ((props) => {
  var { filter, type, changeFilter, _this } = props
  var leadStatusList = ['queued','pitched','responded','pending','closed','declined','archived']
  var dealSourceList = ['Inbound', 'Inbound Existing', 'Automated Pitch', 'Logan Pitch', 'Cross Pitch', 'Inbound Existing;Inbound']
  leadStatusList = formatList(leadStatusList); dealSourceList = formatList(dealSourceList)

  return (
    <>
      <FilterListDropdown title="Lead Status" index={0} filter={filter} type={type} staticList={leadStatusList} changeFilter={changeFilter} _this={_this} />

      <div className="row row0">
        <hr />
      </div>

      <FilterOption title="Has Deal" label="deal" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterOption title="No Deal" label="no-deal" filterType="toggle-selector" filter={filter} index={1} changeFilter={changeFilter} type={type} _this={_this} />
      <FilterListDropdown title="Deal Status" index={2} filter={filter} type={type} staticList={dealSourceList} changeFilter={changeFilter} _this={_this} />
      <FilterListDropdown title="Deal Source" index={3} filter={filter} type={type} staticList={dealSourceList} changeFilter={changeFilter} _this={_this} />

    </>
  )
})

export default memo(FilterLead);
