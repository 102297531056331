import { PropsWithChildren, useRef, useState } from 'react';
import { contentReviewStatuses } from '../../../services/content-review-service';
import Globals from '../../../global-functions';
import React from 'react';
import moment from 'moment';
import "./content-for-review.scss";

export const ContentWithStatusRow = (props: PropsWithChildren<{
  contentForReview: any,
  deleteContent: (id: string) => void,
  selectContentToEdit: (content: any) => void,
  onSubmitNewContentClick: () => void,
  onCloseClick: () => void,
  viewRejectedContent: any,
  onApprovedLinkClick: () => void,
  onRejectedLinkClick: () => void,
  onPendingLinkClick: () => void,
}>) => {
  const {
    contentForReview, deleteContent, onApprovedLinkClick, onRejectedLinkClick,
    onPendingLinkClick, selectContentToEdit, onSubmitNewContentClick,
    viewRejectedContent, onCloseClick
  } = props;

  const ref = useRef<any>();
  const [copied, setCopied] = useState(false);
  const urlToRender = contentForReview.videoThumbnail || contentForReview.contentLink;

  let status = 'failed';
  let canEdit = false;
  let onLinkClick: any = () => {};
  const copyLink = () => {
    let succeed; const target: HTMLInputElement | null = ref.current; target?.focus(); target?.setSelectionRange(0, target.value.length);
    try {
      succeed = document.execCommand("copy");
      if (succeed) { setCopied(true); setTimeout(() => setCopied(false), 2000); }
    }
    catch(e) { console.error(e); }
  }

  switch (contentForReview.status) {
    case contentReviewStatuses.approved:
      status = 'complete';
      onLinkClick = onApprovedLinkClick;
      break;
    case contentReviewStatuses.rejected:
      status = 'failed';
      onLinkClick = onRejectedLinkClick;
      break;
    case contentReviewStatuses.pending:
      status = 'pending';
      canEdit = true;
      onLinkClick = onPendingLinkClick;
      break;
  }

  return <div className="slide-out-objective" style={{ padding: '25px 0', borderBottom: '1px solid #eee' }}>
    <div className="slide-out-objective-title">
      <div style={{display: 'flex', width: '100%'}}>
        <div className="review-link-preview" style={{backgroundImage: `url(${urlToRender})`}}/>

        <div className={"review-link-item"}>
          <div className="row row0 review-link-meta">
            <p>{contentForReview.videoTitle}</p>
            <p className="sub-text">{contentForReview.videoCaption}</p>
            <p>{contentForReview.contentLink}</p>

            {
              viewRejectedContent && contentForReview.id === viewRejectedContent.id
                ? <div style={{ marginLeft: '25px'}}>
                    <p className="sub-text">Your content has been rejected. Reason:</p>
                    <p>{contentForReview.rejectionReason}</p>

                    <div className="actions">
                      <button className="add-content" onClick={onCloseClick}>Close</button>
                      <button className="add-content green" onClick={onSubmitNewContentClick}>Submit new content</button>
                    </div>
                  </div>
                : <div className={`status sub-text ${status}`}>
                    {status === 'failed' && <><img className={'red cross'} src={'/images/icons/red-cross.svg'} />Rejected: {contentForReview.rejectionReason}</>}
                    {status === 'pending' && <><img className={'gray minus'} src={Globals.icon('minus')} />Pending review: Typically 24 hours or less</>}
                    {status === 'complete' && <><img src={'/images/icons/green-check.svg'} />Approved! Ready for posting</>}
                  </div>
            }
            <div className={`row row0`}>
              <p className="sub-text">{moment(contentForReview.createdAt).format('ddd, MMM Do h:mma')}</p>
            </div>
          </div>
          <div className={"review-link-row"}>
            <div className="copy-link" onClick={() => copyLink()}>
              <input ref={ref} readOnly={true} className="clipboard" value={contentForReview.contentLink} style={{display: 'none'}} />
              <img src="/images/icons/copy-black.svg"/>
            </div>
            {
              canEdit && <>
                <button className={'edit-btn'} onClick={() => selectContentToEdit(contentForReview)}><img src={Globals.icon('pencil-filled')}/></button>
                <button className={'edit-btn'} onClick={() => deleteContent(contentForReview.id)}><img src={Globals.icon('trash')}/></button>
              </>
            }
            <span className={`copied ${copied ? 'active' : ''}`}>Copied!</span>
          </div>


      </div>
      </div>
    </div>
  </div>

}
