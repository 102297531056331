import React from 'react';
import Globals from '../global-functions';

export default class AccountInvoice extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      paypalEmail: '',
      unverifiedPaypalEmail: '',
      gender: '',
      birthday: '',
      updateSettingsBtn: 'Save',
      setEmailBtn: 'Set'
    }
  }

  componentDidMount(){
    this.refreshUser()
  }

  // getUser(){ return $.ajax({ method: 'GET', url: '/api/me/details', data: { } }) }

  onChangeInput(field, e){
    if (typeof e == 'string'){ this.setState({ [field]: e }) }
    else { this.setState({ [field]: e.target.value }) }
  }

  render() {

    var profileImg = '';

    return (
      <div>

      </div>
    )
  }
}
