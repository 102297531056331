import React from 'react';
import Globals from '../../../global-functions';
import './invite-modal.scss';
import history from '../../../components/history';

export interface InviteModalProps {
  title: string;
  children: any;
  background: string;
  backgroundColor?: string;
}

const goBack = () => {
  if (history.length > 2) {
    history.goBack();
  } else {
    history.push('..');
  }
}



export const InviteModal: React.FC<InviteModalProps> = ({title, children, background, backgroundColor}) =>
<>
  <div className="invite-modal-darken" style={{backgroundColor}} />
  <div className="invite-modal-bg" style={{backgroundImage: `url(${background})`}}> </div>
  <div className="invite-modal-wrapper">
  <div className="invite-modal">
    <div className="invite-modal-header">
      <div className="left-panel" onClick={() => goBack() }>
        <img className={'spacer nav-left'} src={Globals.icon('arrow-left-1', 'black')} />
      </div>
      <h4 className={'title'}> {title} </h4>
      <div className={'spacer'}></div>
    </div>
    <div className="invite-modal-body">
      {children}
    </div>
  </div>
  </div>
</>
