import React from 'react';
import { Link } from 'react-router-dom';
import includes from 'array-includes';
import Globals from '../../global-functions.js';
import SlideOutListItem from './slide-out-list-item.jsx';
import SlideOutMainBtn from './slide-out-main-btn.jsx';
import SlideOutTitle from './slide-out-title.jsx';
import history from '../history.js';

const getSubmissions = (bountyId) => $.ajax({ method: 'GET', url: '/api/Submissions', data: { bountyId } })
export default class SlideOutAccept extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      chosenObjectives: [],
      giftOption: {},
      giftOptions: [],
      product: false,
      submissions: [],
      timeframe: { type: 'timeframe', name: 'Timeframe', description: '# of weeks', value: 1 },
      sizeShirt: false,
      acceptingBounty: false,
      paymentFlatRate: '',
      paymentFlatRateCreatorProposal: ''
    }
  }

  componentDidMount(){

    getSubmissions(this.props.offerId).done((submissions) => {
      var eligible
      submissions.forEach((submission) => { if (submission.min > 0){ eligible = true } })
      this.setState({
        submissions: submissions.map(submission => ({ ...submission, value: submission.min || 0, image: Globals.icon(submission.icon) })),
        selectedPost: eligible
      });
    });

    if (this.props.campaign && this.props.campaign.brandType == 'product') {
      this.getBountyProducts()
      this.getAddress()
      if (this.props.campaign && this.props.campaign.offer && this.props.campaign.id == '402783047754') {
        var timeframe = this.state.timeframe
        timeframe.value = 1
        this.setState({ timeframe: timeframe })
      }
    }
  }

  onChange(type, e){ this.setState({ [type]: e.target.value }) }

  chooseOption(objective){
    var newObjectives = []
    var found = false
    this.state.chosenObjectives.forEach((o) => { if (o == objective) { found = true } else { newObjectives.push(o) } })
    if (!found) { newObjectives.push(objective) }
    this.setState({ chosenObjectives: newObjectives })
  }

  selectGiftOption(product){ this.setState({ giftOption: product, product: product }) }

  incrementVal(type, direction){
    if (type == 'timeframe'){
      var timeframe = this.state.timeframe, eligible = false
      if (direction == 'up') { timeframe.value = timeframe.value + 1 }
      else if (direction == 'down') { timeframe.value = timeframe.value == 0 ? 0 : timeframe.value - 1 }
      if (timeframe.value > 0) { eligible = true }
      this.setState({ timeframe: timeframe })
    }
    else {
      var submissions = this.state.submissions, eligible = false
      submissions.forEach((s) => {
        if (s.typeId == type){ if (direction == 'up') { s.value = +s.value + 1 } else if (direction == 'down') { s.value = +s.value == 0 ? 0 : +s.value - 1 } }
        if (+s.value > 0) { eligible = true }
      })
      this.setState({ submissions, selectedPost: eligible })
    }
  }

  getBountyProducts(){
    this._getBountyProducts().done((response) => {
      const { products } = response;
      const product = (products.length === 1) && products[0];
      this.setState({ giftOptions: products, giftOption: product, product: product })
    })
  }

  _getBountyProducts(){ return $.ajax({ method: 'GET', url: '/api/Campaign/getBountyProducts', data: { offerId: this.props.offerId } }) }
  getAddress(){ this._getAddress().done((response) => { this.setState({ address: response && response[0] ? response[0] : false }) }) }
  _getAddress(){ return $.ajax({ method: 'GET', url: '/api/Address/get', data: { } }) }
  chooseShirtSize(type, size){ this.setState({ [type]: size }) }

  goToPage(url){
    location.replace(url)
    // this.props.handleClose.bind(this.props.thisClose, 'close')
    // history.push(url)
  }

  requestCampaign(){
    if (!this.state.acceptingBounty){
      var campaign = this.props.campaign
      var offer = campaign.offer ? campaign.offer : {}
      var content = campaign.content ? campaign.content : {}
      this.setState({ acceptingBounty: true })
      this.props.requestCampaign({
        objectives: this.state.submissions,
        timeframe: this.state.timeframe,
        message: this.props.message,
        status: content.status,
        product: this.state.product,
        giftOption: this.state.giftOption,
        sizeShirt: this.state.sizeShirt,
        paymentFlatRateCreatorProposal: this.state.paymentFlatRateCreatorProposal,
        offer: offer,
        content: content
      })
    }
  }

  render() {

    var campaign = this.props.campaign
    var user = campaign.user
    var game = campaign.game ? campaign.game : campaign.offer
    var offer = campaign.offer ? campaign.offer : {}
    var products = this.state.giftOptions ? this.state.giftOptions : this.state.gifts
    var content = campaign.content ? campaign.content : {}
    if (campaign) { campaign.brandType = campaign.brandType ? campaign.brandType : offer.brandType }
    var objectives = campaign.bountyOffers ? campaign.bountyOffers.map((b) => { return b.bountyObj }) : []
    var restaurant = campaign.restaurant
    if (offer.gifts && typeof offer.gifts == 'string') { offer.gifts = JSON.parse(offer.gifts) }
    var timeframe = this.state.timeframe
    var pageHTML = ''

    var bountyObjs = objectives.map((o) => {
      var active = false
      o.labels = o.labels ? o.labels : []
      if (this.state.chosenObjectives){ this.state.chosenObjectives.forEach((c) => { if (o.id == c) { active = true } }) }
      if (includes(o.labels, 'BountyIGPhoto')){
        return (
          <div className="min-require-options instagram">
            <div className="col-md-4 col-xs-6" style={{paddingLeft: 0}}>
              <div className={`module min-option ${active ? 'active' : ''}`} onClick={this.chooseOption.bind(this, o.id)}>
                <div className="min-option-img-container">
                  <img className="min-option-img" src={active ? Globals.icon('ig-post', 'white') : Globals.icon('ig-post')} />
                </div>
                <p><img src={active ? Globals.icon('instagram', 'white') : Globals.icon('instagram')} style={{width: '15px', margin: '-2px 6px 0 0'}} /> IG Post</p>
              </div>
            </div>
          </div>
        )
      }
      else if (includes(o.labels, 'BountyIGStory')){
        return (
          <div className="min-require-options instagram">
            <div className="col-md-4 col-xs-6" style={{paddingLeft: 0}}>
              <div className={`module min-option ${active ? 'active' : ''}`} onClick={this.chooseOption.bind(this, o.id)}>
                <div className="min-option-img-container">
                  <img className="min-option-img" src={active ? Globals.icon('ig-story', 'white') : Globals.icon('ig-story')} />
                </div>
                <p><img src={active ? Globals.icon('instagram', 'white') : Globals.icon('instagram')} style={{width: '15px', margin: '-2px 6px 0 0'}} /> IG Story</p>
              </div>
            </div>
          </div>
        )
      }
      else if (includes(o.labels, 'BountyYTVideo')){
        return (
          <div className="min-require-options youtube">
            <div className="col-md-4 col-xs-6" style={{paddingLeft: 0}}>
              <div className={`module min-option ${active ? 'active' : ''}`} onClick={this.chooseOption.bind(this, o.id)}>
                <div className="min-option-img-container">
                  <img className="min-option-img" src={active ? '/images/youtube-icon.svg' : '/images/youtube-1.svg'} />
                </div>
                <p>Video (60+ secs)</p>
              </div>
            </div>
          </div>
        )
      }
    })

    var submissions = this.state.submissions

    var houseRules = [
      { text: 'Include #ad' },
      { text: 'You must own the rights to all content/music submitted' },
      { text: 'Do not use previously published content' },
      { text: 'Keep your post at the top of feed for 5 hrs and live for 30 days' },
      { text: 'Tag the brand and include #ad in each Stories frame' },
      { text: 'Always adhere to the Weave Codes of Conduct' }
    ]

    var btnAcceptOption, btnAcceptTxt

    if ( !this.state.giftOption.id && offer.giftingType == 'weave' ){ btnAcceptTxt = 'Select your product gift' }
    else if ( !this.state.address && (offer.giftingType == 'weave' || offer.giftingType == 'manual') ){ btnAcceptTxt = 'Add a shipping address' }
    else if ( !this.state.selectedPost ){ btnAcceptTxt = 'Select at least 1 submission type' }
    else if ( !(timeframe.value > 0) && offer.brandType == 'product' ){ btnAcceptTxt = 'Select your posting timeframe' }

    if (btnAcceptTxt){
      btnAcceptOption = ( <SlideOutMainBtn this={this.props.this} title={btnAcceptTxt} onClickFunc={() => {}} /> )
    }
    else {
      var btnTextVal = ''
      if (content.status == 'brand_invited') { btnTextVal = this.props.acceptInviteBountyBtnText }
      else if (content.status == 'brand_offer') { btnTextVal = this.props.submitBountyBtnText }
      else if (content.status != 'brand_invited' && content.status != 'brand_offer') { btnTextVal = this.props.submitBountyBtnText }
      btnAcceptOption = ( <SlideOutMainBtn this={this} title={btnTextVal} onClickFunc={this.requestCampaign.bind(this)} /> )
    }

    var { paymentFlatRate, paymentFlatRateCreatorProposal } = this.state
    if (paymentFlatRate && !isNaN(paymentFlatRate) && Number(paymentFlatRate) > 0) { paymentFlatRate = Number(paymentFlatRate) } else { paymentFlatRate = false }
    if (paymentFlatRateCreatorProposal && !isNaN(paymentFlatRateCreatorProposal) && Number(paymentFlatRateCreatorProposal) > 0) { paymentFlatRateCreatorProposal = Number(paymentFlatRateCreatorProposal) } else { paymentFlatRateCreatorProposal = 0 }

    return (
      <div>
        <div className={`slide-out-offer-scrollable slide-out-offer-${campaign.brandType} ${this.props.scrollingDown ? 'maximize' : ''}`}>
          <div>
            <SlideOutTitle title={'Before You Apply'} hr={true} description={`Please read the entire bounty and Weave rules to make sure you understand the brand's goals. Failure to complete bounties may result in removal from our network.`} />

            {
              offer.giftingType == 'weave' &&
              products &&
              typeof products == 'object' &&
              products.length > 0 &&
              <div>
                <div className="slide-out-select-gifts">
                  <div className="row row0">
                    <h3>Choose Gift</h3>
                    {
                      products.map((g) => {
                        return (
                          <div className={`row row0 equal select-gift-option ${this.state.giftOption.id == g.id ? 'active-gift' : ''}`} style={{padding: '8px 0'}} onClick={this.selectGiftOption.bind(this, g)}>
                            <div className="col-xs-1">
                              <div className="select-gift-indicator"></div>
                            </div>
                            <div className="col-xs-2" style={{padding: 0}}>
                              <img className="select-gift-img" src={g.imageUrl} />
                            </div>
                            <div className="col-xs-6">
                              <p className="two-line-ellipsis" style={{fontWeight: '500', fontFamily: 'Barlow', height: 'initial'}}>{g.title}</p>
                              <p style={{fontSize: '16px', fontWeight: '500', fontFamily: 'Barlow'}}>
                                <span className="sub-text" style={{textDecoration: 'line-through', marginRight: '10px'}}>${Globals.formatCurrency(g.price)}</span>
                                <span style={{color: 'green'}}>$0.00</span>
                              </p>
                            </div>
                            <div className="col-xs-3" style={{textAlign: 'right'}}>
                              {
                                offer.offerWebsiteUrl &&
                                <a className="view-product-url" href={`${offer.offerWebsiteUrl.replace(/\//g, '')}/products/${g.handle}`} target="_blank">
                                  <span className="sub-text" style={{float:'right'}}>View Product <img src={Globals.icon('share-2')} /></span>
                                </a>
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <hr />
              </div>
            }

            { offer.giftingType == 'discount' && <SlideOutTitle title={`Get Your Free Product`} hr={true} noMargin={true} descriptionHtml={`<span>Once you accept your invitation, you will be given your code to redeem your product gift(s) from <a href=""${offer.offerWebsiteUrl}"" target="_blank">${offer.offerWebsiteUrl}</a> for free.</span>`} /> }
            { offer.giftingType == 'manual' && <SlideOutTitle title={'Receiving Free Product'} hr={true} noMargin={true} description={`Once you accept the bounty invitation, you will be notified once your free product has been shipped out with shipping tracking.`} /> }
            { offer.id == '402783047754' && <SlideOutTitle title={'Receiving Free Sensei Pass'} hr={true} noMargin={true} description={`Once you accept the bounty invitation, Weave will gift you a free annual Sensei Season Pass ($60 value).`} /> }

            <div className="row row0">
              <SlideOutTitle title={'Choose Submission Types'} noMargin={true} description={`Select what content you agree to post for this bounty. This allows you and the brand to transparently understand what is expected.`} />
              <div className="row row0 submission-posts">
                {
                  submissions.map((s) => {
                    return (
                      <div className={`module submission-post ${s.value > 0 ? 'active' : ''}`} style={{cursor: 'pointer', marginBottom: '8px'}} onClick={this.incrementVal.bind(this, s.typeId, s.value == 0 ? 'up' : '')}>
                        <div className="row row0">
                          <div className="col-xs-1 col-sm-1">
                            <img src={s.image} style={{maxWidth: '22px', maxHeight: '22px', marginTop: '9px'}} />
                          </div>
                          <div className="col-xs-7 col-sm-8">
                            <p className="submission-title">{s.name}</p>
                            <p className="sub-text submission-description" style={{margin: 0}}>{s.description}</p>
                          </div>
                          <div className="col-xs-4 col-sm-3">
                            { s.value == 0 && <div><p className="" style={{margin: '10px 0 0 0', textAlign: 'right'}}>+ Add</p></div> }
                            {
                              s.value > 0 &&
                              <div className="submission-increment" style={{float: 'right', marginTop: '6px'}}>
                                <button className={`submission-down ${+s.value == 0 ? 'disabled' : ''}`} disabled={+s.value === +s.min || +s.value == 0} onClick={this.incrementVal.bind(this, s.typeId, 'down')}>-</button>
                                <div className="submission-value">{+s.value || +s.min}</div>
                                <button className="submission-up" onClick={this.incrementVal.bind(this, s.typeId, 'up')}>+</button>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <hr />

            {
              offer.brandType == 'product' &&
              <>
                <div className="row row0">
                  <SlideOutTitle
                    title={`Submission Timeframe ${offer && offer.postingDeadline && String(offer.postingDeadline).length > 4 ? `• ${moment(offer.postingDeadline).format('MMM Do')} or earlier` : ''}`}
                    noMargin={true}
                    descriptionHtml={`How many weeks will it take to post the content after you receive the product? ${offer && offer.postingDeadline && String(offer.postingDeadline).length > 4 ? `<span> All content must be <b>posted by ${moment(offer.postingDeadline).format('MMM Do')} or earlier</b></span>` : ``}`}
                  />

                  <div className="row row0 submission-posts">
                    <div className="submission-post" style={{padding: 0, marginTop: '12px'}}>
                      <div className="row">
                        <div className="col-xs-8">
                          <p className="submission-title">Timeframe</p>
                          <p className="sub-text submission-description" style={{margin: 0}}># of weeks</p>
                        </div>
                        <div className="col-xs-4">
                          <div className="submission-increment" style={{float: 'right'}}>
                            <button className={`submission-down`} disabled={+timeframe.value == 0} onClick={this.incrementVal.bind(this, 'timeframe', 'down')}>-</button>
                            <div className="submission-value">
                              <a>{timeframe.value}</a>
                            </div>
                            <div className="submission-up" onClick={this.incrementVal.bind(this, 'timeframe', 'up')}><a>+</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row0" style={{textAlign: 'right'}}>
                    <p style={{margin: 0}}>Post by: <b>{moment().add(this.state.timeframe.value, 'weeks').format('dddd, MMM Do YYYY')}</b></p>
                  </div>
                </div>
                <hr />
              </>
            }

            { content && content.paymentFlatRate && Number(content.paymentFlatRate) > 0 && <SlideOutTitle title={`Receiving ${content.paymentFlatRate} Payment`} hr={true} description={`Once you complete the bounty with the required content, you will be credited your <b>${content.paymentFlatRate} payment</b> to your Weave account for cashout via Paypal.`} /> }

            {
              (offer.giftingType == 'weave' || offer.giftingType == 'manual') &&
              <div>
                <SlideOutTitle title={`Shipping Address`} noMargin={true} descriptionHtml={`<span>Your product gift(s) will be shipped to this address, <a href="/account#addresses" style="cursor: pointer;"><b>click here to change.</b></a></span>`} />
                {
                  this.state.address &&
                  <div className="row row0" style={{marginTop: '10px'}}>
                    <p style={{margin: 0}}><b>{this.state.address.firstName} {this.state.address.lastName}</b></p>
                    <p style={{margin: 0}}>{this.state.address.address1 + ' ' + this.state.address.address2}</p>
                    <p style={{margin: 0}}>{this.state.address.city + ' ' + this.state.address.province + ' ' + this.state.address.zip + ', ' + this.state.address.countryCode}</p>
                  </div>
                }

                {
                  !this.state.address &&
                  <div className="row row0" style={{textAlign: 'center'}}>
                    <a onClick={this.goToPage.bind(this, '/account#addresses')}>
                      <button className="btn-action btn-primary" style={{margin: '25px auto', float: 'initial'}}>+ Add Address</button>
                    </a>
                  </div>
                }
                <hr />
              </div>
            }

            <SlideOutTitle title={'Sponsorship Fee'} noMargin={true} description={`Specify your sponsorship fee for your deliverables - the brand may not accept your rate fee (optional).`} />
            <div className="row">
              <div className="col-xs-6">
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', left: '16px', top: '9px', zIndex: '2', fontSize: '20px'}}><b>$</b></div>
                  <input className="dark-input" style={{margin: 0, paddingLeft: '36px', fontWeight: 'bold', fontSize: '18px'}} value={paymentFlatRateCreatorProposal ? paymentFlatRateCreatorProposal : ''} onChange={this.onChange.bind(this, 'paymentFlatRateCreatorProposal')} />
                </div>
              </div>
              <div className="col-xs-6">
                { paymentFlatRateCreatorProposal && paymentFlatRateCreatorProposal > 0 &&
                  <>
                    <div className="sub-text">${Globals.numberWithCommas(paymentFlatRateCreatorProposal)} - ${Globals.numberWithCommas(Number(paymentFlatRateCreatorProposal)*.15)} (15% platform fee)</div>
                    <b>${Globals.numberWithCommas(Number(paymentFlatRateCreatorProposal)*.85)} earnings for you</b>
                  </>
                }
                { (!paymentFlatRateCreatorProposal || paymentFlatRateCreatorProposal <= 0 )&&
                  <p className="sub-text" style={{marginTop: '12px'}}><i>No payment specified</i></p>
                }
              </div>
            </div>
            <hr />

            <SlideOutTitle title={'Message'} noMargin={true} description={`Let the brand know content ideas you have in mind so they know what content to expect (optional).`} />
            <div className="textarea-container" style={{borderRadius: '3px'}}>
              <img className="" src={Globals.icon('mail')} />
              <textarea className="dark-input" placeholder="Type message here..." value={this.props.message} onChange={this.props.updateText.bind(this.props.this, 'message')}></textarea>
            </div>
            <hr />

            <SlideOutTitle title={'Collaboration Proposal'} noMargin={true} description={`Overview summary of your proposal to be submitted to the brand.`} />
            <div className="row">
              <div className="col-xs-9">
                <p className="sub-text"><b>{moment().add(this.state.timeframe.value, 'weeks').format('dddd, MMM Do YYYY')}</b> ({this.state.timeframe.value} weeks)</p>
                <p>
                  {paymentFlatRate > 0 && <b>${Globals.numberWithCommas(paymentFlatRate)} additional sponsorship payment</b>}
                  {!paymentFlatRate || paymentFlatRate <= 0 && <b>No additional sponsorship payment</b>}
                </p>
                <p className="sub-text"><i>{this.props.message}</i></p>
              </div>
            </div>
            <hr />

            <div className="row row0">
              <h3>{offer.id == '402783047754' ? 'Dojo Rules' : 'House Rules'}</h3>
              <ul style={{padding: 0, listStyle: 'none'}}>
                { houseRules.map((r) => { return ( <SlideOutListItem text={r.text} type="yes" /> ) }) }
              </ul>
            </div>
            <hr />

            {
              offer.offerLegalDisclaimer && offer.offerLegalDisclaimer.length > 2 &&
              <>
                <div className="row row0">
                  {/*<h3>House Rules</h3>*/}
                  <p className="sub-text"><i>{offer.offerLegalDisclaimer}</i></p>
                </div>
                <hr />
              </>
            }

          </div>
        </div>

        {btnAcceptOption}
      </div>
    )
  }
}
