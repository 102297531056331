import React from 'react';
import Globals from '../../global-functions.js';
import IconPack from '../icon-pack.jsx'

export default class InvoiceHeader extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var { invoice, type, className } = this.props
    var { status, dueDate } = invoice
    var STATUSES = { unpaid: { color: '#dfcc26', text: 'unpaid'}, paid: {color: '#26df26', text: 'paid'}, late: {color: '#ff4747', text: 'late'}, void: {color: '#b8b8b8', text: 'void'} }
    var text, style = {}
    if (type == 'status'){
      text = STATUSES[status] ? STATUSES[status].text : (status || 'draft')
      style = STATUSES[status] ? {backgroundColor: STATUSES[status].color} : { backgroundColor: STATUSES['void'].color }
    }
    else if (type == 'time'){
      text = `${moment(dueDate).diff(moment(), 'days')} days left`
    }
    var iconStyle = { position: 'absolute', width: '12px', left: '11px', top: '9px' }

    return (
      <div className={`invoice-status-pill ${className}`}>
        { type == 'status' && <div className="invoice-status-ind" style={style}></div> }
        { type == 'time' && <IconPack icon="clock" color="#fff" style={iconStyle} /> }
        <div className="invoice-status-text">
          <span>{text}</span>
        </div>
      </div>
    )
  }
}
