import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Dashboard from './dashboard.jsx';
import Bounties from './bounties.jsx';
// import ReferTab from './refer-tab.jsx';
import Refer from './refer.jsx';
import Builder from './builder.jsx';
import Analytics from './analytics.tsx';
import Merchants from './merchants.jsx';
import Concierge from './concierge.jsx';
import Account from './account.jsx';
import Analyze from './analyze.jsx';
import OnboardCampaignInvited from './onboard/onboard-campaign-invited.jsx';
import Applications from './applications.jsx';
import VIP from './vip.jsx';
import Rosters from './rosters.jsx';
import Roster from './roster.jsx';
import Invoices from './invoices/invoices.jsx';
import InvoiceSettings from './invoices/invoice-settings.jsx';
import CreateInvoice from './invoices/create-invoice.jsx';
import Search from './outreach/search-brands.jsx';
import Outreach from './outreach/outreach.jsx';
import OutreachSettings from './outreach/outreach-settings.jsx';
import SettingsEmail from './outreach/settings-email.jsx';
import OutreachWatchlists from './outreach/outreach-watchlists.jsx';
import OutreachWatchlist from './outreach/outreach-watchlist.jsx';
import Drip from './outreach/drip.jsx';
import DataAdmin from './outreach/data-admin.jsx';
import CreateMessage from './outreach/create-message.jsx';
import CreateOutreach from './outreach/create-outreach.jsx';
import SearchBrands from './outreach/search-brands.jsx';
import Company from './outreach/company.jsx';
import InstagramRedirect from './instagram-redirect.jsx';
import GmailRedirect from './gmail-redirect.jsx';
import Globals from '../global-functions.js';
import Cookies from 'js-cookie';
import { PrivateRoute } from './private-route';

export default class Main extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  componentDidMount(){
    var user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : null
    var token = Cookies.get('access_token') ? Cookies.get('access_token') : null

    if (location.pathname == ('/offer')){
      console.log('main.jsx')
    }
    else if (user){
      console.log(user)
      var userid = user.userid
      this.getUser(userid, user.internal).done((user) => {
        if (user && !user.internal && !Globals.isMobile() && Globals.isProd){
          window.Intercom("boot", {
            app_id: "rm4ja52n",
            first_name: user.firstName,
            last_name: user.lastName,
            name: user.username,
            username: user.username,
            email: user.email,
            user_hash: user.hmac
          });
        }
        else if (!user) {
          // Cookies.remove('userid')
          // Cookies.remove('userdata')
          // location.href = '/'
        }
      })
    }
    else {
      console.log('no user')
      Cookies.remove('userid')
      Cookies.remove('userdata')
      // location.href = '/'
    }
  }

  getUser(userid, internal){ return $.ajax({ method: 'POST', url: '/api/Users/get', data: { userid: userid, internal: internal } }) }

  openMenu(){
    var open = !this.state.menuOpen
    if (open) { $('body').addClass('pointer-event-menu-open') }
    else { $('body').removeClass('pointer-event-menu-open') }
    this.setState({ menuOpen: open })
  }

  render() {
    return (
      <main>
        <div className={`page-with-side-navigation ${this.state.menuOpen ? 'menu-push-right' : ''}`}>
        	<article>
            {/*<div className="mobile-menu">
              <div className="mobile-menu-inner">
                <div className="mobile-menu-logo">
                  <img src="/images/logo-transparent.png" />
                </div>
                <div className="mobile-menu-hamburger">
                  <img onClick={this.openMenu.bind(this)} src={Globals.icon('menu-left-1')} />
                </div>
              </div>
            </div>*/}
            <div className="main-container" style={{ paddingBottom: '150px' }}>
              <Switch>
                <Route path='/onboard-campaign-invited' component={OnboardCampaignInvited} />
                <PrivateRoute path='/dashboard' component={Dashboard} />
                <PrivateRoute path='/dashboard/bounty/:id' component={Dashboard} />
                {/*<PrivateRoute path='/refer-tab' component={ReferTab} />*/}
                <PrivateRoute path='/refer' component={Refer} />
                <PrivateRoute path='/bounties' component={Bounties} />
                <PrivateRoute path='/links' component={Builder} />
                <PrivateRoute path='/analytics' component={Analytics} />
                <PrivateRoute path='/merchants' component={Merchants} />
                <PrivateRoute path='/concierge' component={Concierge} />
                <PrivateRoute path='/account' component={Account} />
                <PrivateRoute path='/analyze' component={Analyze} />
                <PrivateRoute path='/vip' component={VIP} />
                <PrivateRoute path='/rosters/:rosterId' component={Roster} />
                <PrivateRoute exact path='/rosters' component={Rosters} />
                <PrivateRoute path='/invoices/create/:id' component={CreateInvoice} />
                <PrivateRoute path='/invoices/settings' component={InvoiceSettings} />
                <PrivateRoute path='/invoices' component={Invoices} />
                <PrivateRoute path='/search' component={SearchBrands} />
                <PrivateRoute path='/company/:id' component={Company} />
                <PrivateRoute path='/outreach/drip/:dripId/message/:messageId' component={CreateMessage} />
                <PrivateRoute path='/outreach/drip/:dripId' component={Drip} />
                <PrivateRoute path='/outreach/watchlists/:watchlistId' component={OutreachWatchlist} />
                <PrivateRoute path='/outreach/watchlists' component={OutreachWatchlists} />
                <PrivateRoute path='/outreach/settings/email' component={SettingsEmail} />
                <PrivateRoute path='/outreach/settings' component={OutreachSettings} />
                <PrivateRoute path='/outreach' component={Outreach} />
                <PrivateRoute path='/applications' component={Applications} />
                <PrivateRoute path='/redirect/instagram' component={InstagramRedirect} />
                <PrivateRoute path='/redirect/gmail' component={GmailRedirect} />
                <PrivateRoute path='/data-admin' component={DataAdmin} />
              </Switch>
            </div>

            <div className="app-menu">
              <Link to="/dashboard">
                <div className="app-menu-option">
                  <img src={Globals.icon('home')} /><p>Home</p>
                </div>
              </Link>
              <Link to="/bounties">
                <div className="app-menu-option">
                  <img src={Globals.icon('duplicate')} /><p>Bounties</p>
                </div>
              </Link>
              <Link to="/refer">
                <div className="app-menu-option">
                  <img src={Globals.icon('heart')} /><p>Refer</p>
                </div>
              </Link>
              <Link to="/account">
                <div className="app-menu-option">
                  <img src={Globals.icon('user')} /><p>Account</p>
                </div>
              </Link>
            </div>
          </article>
        </div>
      </main>
    )
  }
}
