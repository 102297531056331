import { useSelector } from 'react-redux';
import { RootState } from '../reducers/root.reducer';

export const contentObjectivesSelector = (state: RootState) => state.contentObjectives;
export const useContentObjectivesSelector = () => useSelector(contentObjectivesSelector);

export const contentApprovalsAnonymousSelector = (state: RootState) => state.contentApprovalsAnonymous;
export const useContentApprovalsAnonymousSelector = () => useSelector(contentApprovalsAnonymousSelector);

export const createContentApprovalAnonymousSelector = (state: RootState) => state.createContentApprovalAnonymous;
export const useCreateContentApprovalAnonymousSelector = () => useSelector(createContentApprovalAnonymousSelector);

export const updateContentForReviewSelector = (state: RootState) => state.updateContentForReview;
export const useUpdateContentForReviewSelector = () => useSelector(updateContentForReviewSelector);
