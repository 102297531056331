import React from 'react';
import Globals from '../../global-functions.js';
import ShippingTrackingStage from './shipping-tracking-stage.jsx';
import ShippingTrackingLine from './shipping-tracking-line.jsx';

export default class ShippingTrackingAppBar extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  render() {
    var props = this.props, status = this.props.status

    var config = {}
    if (status == 'brand_applied'|| status == 'brand_awaiting' || status == 'brand_scheduled'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive',
      }
    }
    else if (status == 'brand_responded'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive',
      }
    }
    else if (status == 'brand_invited'){
      config = {
        s1: 'pending active',
        l1: 'inactive',
        s2: 'inactive',
        l2: 'inactive',
        s3: 'inactive',
      }
    }
    else if (status == 'brand_active'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'pending active',
        l2: 'active',
        s3: 'inactive',
      }
    }
    else if (status == 'brand_shipped'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'pending active',
        l2: 'active',
        s3: 'inactive',
      }
    }
    else if (status == 'brand_review'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'visited',
        l2: 'pending',
        s3: 'visited',
      }
    }
    else if (status == 'brand_live'){
      config = {
        s1: 'visited',
        l1: 'pending',
        s2: 'visited',
        l2: 'pending',
        s3: 'visited',
      }
    }
    else if (status == 'brand_completed'){
      config = {
        s1: 'finished',
        l1: 'finished',
        s2: 'finished',
        l2: 'finished',
        s3: 'finished',
      }
    }

    return (
      <div>
        <div className={`shipping-tracking shipping-tracking-product ${this.props.animated ? 'animated' : ''} ${this.props.size ? this.props.size : 'small'}`}>
          <div className="row row0 shipping-stages stages-3">
            <div className="shipping-stages-inner">
              <ShippingTrackingStage icon={`loupe`} progress={config.s1} />
              <ShippingTrackingLine progress={config.l1} />
              <ShippingTrackingStage icon={`camera`} progress={config.s2} />
              <ShippingTrackingLine progress={config.l2} />
              <ShippingTrackingStage icon={`ok`} progress={config.s3} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
