import React from "react";
import moment from "moment";
import Globals from "../../global-functions.js";
import Loader from "../loader";
import CRMTableHeaaderCell from './crm-table-header-cell'
import CRMTableMainCell from './crm-table-main-cell'
import EmptyMessage from '../empty-message'

export default class KeywordsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Globals.getUser(),
    };
  }

  componentDidMount() {}

  render() {
    var { keywords, sortFilter, loading, keywordsFilter } = this.props, user = this.state.user;
    var selectedActive; if (keywordsFilter.length > 0) { selectedActive = true }

    return (
      <div>
        <div className="crm-table" style={{height: 'calc(100vh - 200px)'}}>
          <div className="crm-table-header">
            <div className={`row row0 crm-row ${selectedActive ? "selected" : ""}`}>
              <div className="crm-td crm-td-xs crm-td-img">
                <input type="checkbox" style={{ display: "block" }} onClick={this.props.selectKeyword.bind(this.props.this, "all")} checked={selectedActive} />
              </div>
              <CRMTableHeaaderCell label="Name" title="Name" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
              <CRMTableHeaaderCell label="Total Brands" title="Total Brands" size="crm-td-md" sortFilter={sortFilter} this={this.props.this} />
            </div>
          </div>

          <div className="crm-table-main">
            { !loading && keywords.map((k) => {
              var keywordSelected; if (keywordsFilter) { keywordsFilter.forEach((f) => { if (f == k.name){ keywordSelected = true } }) }
              return (
                <div key={'insightsTable-'+k.name} className={`row row0 crm-row crm-row-influencer ${keywordSelected ? "selected" : ""}`}>
                  <div className="">
                    <CRMTableMainCell size="crm-td-xs" type="profileImg" classes={'crm-td-img'} style={{ padding: '10px 14px' }} influencer={k} hoverFunc={this.props.selectKeyword.bind(this.props.this, k)} this={this.props.this} />
                    <CRMTableMainCell title={k.name} size="crm-td-md" classes="crm-username" this={this.props.this} />
                    <CRMTableMainCell title={k.totalCompanies} size="crm-td-md" this={this.props.this} />
                  </div>
                </div>
              );
            })}
            {keywords.length == 0 && !loading && <EmptyMessage title={'No Keywords Found'} description={'Adjust your filters to widen your keyword search'} />}
            {loading && <Loader active={true} />}
          </div>
        </div>
      </div>
    );
  }
}
