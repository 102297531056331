import { memo } from 'react';
import SearchDropdown from '../search-dropdown'
import PillTag from '../pill-tag'

const FilterAd = ((props) => {
  var { filter, changeFilter, type, _this } = props

  return (
    <>
      <div className="row row0">
        <div className="filter-option-container">
          <div className="filter-option-inner">
            <div className="row row0">
              <div className={`filter-option-selector-container ${filter[0].active ? 'active' : ''}`}>
                <div className="filter-option-selector" style={{display: 'inline-block', float: 'left', marginRight: '8px'}}>
                  <div className="filter-checkbox" onClick={changeFilter.bind(_this, type[0], filter[0], 'selector')}>
                    <div className="filter-checkbox-inner"></div>
                  </div>
                </div>
              </div>
              <div className="filter-option-title">
                <label>Min #ad creators</label>
              </div>
            </div>
          </div>

          <div className="row row0">
            <div className="row row0">
              <SearchDropdown disableDropdown={true} index={0} filter={filter} type={type} simple="true" field="name" changeFilter={changeFilter} placeholder="0" inputStyle={{ marginBottom: '-2px', borderRadius: '3px 3px 0 0' }} _this={_this} />
            </div>
            <div className="row row0">
              <div className="crm-action-btn-container crm-action-btn-container-attached" style={{width: '100%'}}>
                <div className="row row0">
                  <div className={`crm-action-btn ${filter[0].timeRange == '7' ? 'active' : ''}`} style={{width: '16.66%'}} onClick={changeFilter.bind(_this, type[0], '7', 'timeRange')}><span>7d</span></div>
                  <div className={`crm-action-btn ${filter[0].timeRange == '30' ? 'active' : ''}`} style={{width: '16.66%'}} onClick={changeFilter.bind(_this, type[0], '30', 'timeRange')}><span>30d</span></div>
                  <div className={`crm-action-btn ${filter[0].timeRange == '60' ? 'active' : ''}`} style={{width: '16%'}} onClick={changeFilter.bind(_this, type[0], '60', 'timeRange')}><span>60d</span></div>
                  <div className={`crm-action-btn ${filter[0].timeRange == '90' ? 'active' : ''}`} style={{width: '17%'}} onClick={changeFilter.bind(_this, type[0], '90', 'timeRange')}><span>90d</span></div>
                  <div className={`crm-action-btn ${filter[0].timeRange == '180' ? 'active' : ''}`} style={{width: '18%'}} onClick={changeFilter.bind(_this, type[0], '180', 'timeRange')}><span>180d</span></div>
                  <div className={`crm-action-btn ${filter[0].timeRange == 'all' ? 'active' : ''}`} style={{width: '18%'}} onClick={changeFilter.bind(_this, type[0], 'all', 'timeRange')}><span>All</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filter-option-container">
          <div className="filter-option-inner">
            <div className="row row0" style={{marginTop: '25px'}}>
              <div className={`filter-option-selector-container ${filter[1].active ? 'active' : ''}`}>
                <div className="filter-option-selector">
                  <div className="filter-checkbox" onClick={changeFilter.bind(_this, type[1], filter[1], 'selector')}>
                    <div className="filter-checkbox-inner"></div>
                  </div>
                </div>
              </div>
              <div className="filter-option-title">
                <label>Estimated Ad Spend</label>
              </div>
            </div>
          </div>

          <div className="row row0">
            <div className="row row0">
              <SearchDropdown disableDropdown={true} index={1} filter={filter} type={type} simple="true" field="name" changeFilter={changeFilter} placeholder="0" inputStyle={{ marginBottom: '-2px', borderRadius: '3px 3px 0 0' }} _this={_this} />
            </div>
            <div className="row row0">
              <div className="crm-action-btn-container crm-action-btn-container-attached" style={{width: '100%'}}>
                <div className="row row0">
                  <div className={`crm-action-btn ${filter[1].timeRange == '7' ? 'active' : ''}`} style={{width: '16.66%'}} onClick={changeFilter.bind(_this, type[1], '', 'timeRange')}><span>7d</span></div>
                  <div className={`crm-action-btn ${filter[1].timeRange == '30' ? 'active' : ''}`} style={{width: '16.66%'}} onClick={changeFilter.bind(_this, type[1], '30', 'timeRange')}><span>30d</span></div>
                  <div className={`crm-action-btn ${filter[1].timeRange == '60' ? 'active' : ''}`} style={{width: '16%'}} onClick={changeFilter.bind(_this, type[1], '60', 'timeRange')}><span>60d</span></div>
                  <div className={`crm-action-btn ${filter[1].timeRange == '90' ? 'active' : ''}`} style={{width: '17%'}} onClick={changeFilter.bind(_this, type[1], '90', 'timeRange')}><span>90d</span></div>
                  <div className={`crm-action-btn ${filter[1].timeRange == '180' ? 'active' : ''}`} style={{width: '18%'}} onClick={changeFilter.bind(_this, type[1], '180', 'timeRange')}><span>180d</span></div>
                  <div className={`crm-action-btn ${filter[1].timeRange == 'all' ? 'active' : ''}`} style={{width: '18%'}} onClick={changeFilter.bind(_this, type[1], 'all', 'timeRange')}><span>All</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default memo(FilterAd);
