import React from 'react';

export default class SearchResult extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      expanded: false
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  render() {
    var search = this.props.search
    return (
      <li id={'search-'+search.num} onClick={this.props.onClick.bind(this.props.this, search)}>
        <div id={'results-'+search.num} className="row result-row">
          <div className="col-md-3">
            <img src={search.imageUrl ? search.imageUrl : 'https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/product-placeholder.svg'} />
          </div>
          <div className="col-md-6">
            <p className="item-name">{ (search.name && search.name != 'false' && search.name != 'undefined' ? search.name : <br />) }</p>
            <p className="item-domain" style={{color: '#ccc'}}>{search.domain}</p>
            {
              /*
              <p className="item-brand">{ (search.brand && search.brand != 'false' && search.brand != 'undefined' ? search.brand : <br />) }</p>
              <p className="item-price">{ (search.price && search.price != 'false' && search.price != 'undefined' && !isNaN(search.price) ? '$'+search.price : <br />) }</p>
              */
            }
          </div>
          {
            /*
            <div className="col-md-3">
              <div className="add-btn-center">
                 <button className="btn-thin-outline add-to-page">Choose</button>
              </div>
            </div>
            */
          }
          <div className="col-md-3">
            <div className="add-btn-center" style={{lineHeight: '62px'}}>
              <span style={search.preferred_cp ? (search.preferred_cp == 'cpa' ? {color: 'blue'} : {color: 'green'}) : {color: 'red'} }>
                {search.preferred_cp == 'cpa' ? (search.cpa_rate ? search.cpa_rate + '% cpa' : 'varies') : (search.preferred_cp == 'cpc' ? ('$' + search.cpc_rate + ' cpc') : 'unaffiliated')}
              </span>
            </div>
          </div>
        </div>
      </li>
    )
  }
}
