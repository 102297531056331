import React from 'react';

export default class Template extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {

    var restaurant = this.props.restaurant, offer = this.props.offer, content = this.props.content
    var stars = ''
    if (restaurant){
      var rating = Math.floor(restaurant.rating), partial = restaurant.rating - rating > 0 ? '_half' : ''
      stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`
    }
    if (this.state.reviews){
      this.state.reviews.forEach((r) => {
        var rating = Math.floor(r.rating), partial = r.rating - rating > 0 ? '_half' : ''
        r.stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`
      })
    }

    return (
      <div className={`slide-out-restaurant ${this.props.tabActive == 'menu' ? 'menu-active' : ''} ${this.props.scrollingDown ? 'slideout-header-minimize' : ''}`}>
        <div className="row restaurant-photos">
          {
            restaurant && restaurant.photos &&
            restaurant.photos.map((p, i) => {
              var s = {}
              if (i == 0) { s = { paddingLeft: 0 } }
              if (i == 2) { s = { paddingRight: 0 } }
              return (
                <div className="res-photo-container" style={s}>
                  <div className="res-photo" style={{backgroundImage: `url(${p})`}}>

                  </div>
                </div>
              )
            })
          }
          <div className="game-header-banner-darken"></div>
        </div>
        <div className="restaurant-header" style={{marginTop: '30px'}}>
          <div className="restaurant-main-img" style={{backgroundImage: `url(${restaurant.image_url})`}}></div>
          <h3>{restaurant.name}</h3>
          <p className="sub-text" style={{margin: '0 0 15px 0', fontSize: '16px'}}>{offer.offerTagline}</p>
          <a href={restaurant.url} target="_blank">
            <img className="yelp-logo" src="https://weave-img.s3-us-west-2.amazonaws.com/yelp/yelp-logo.png" />
          </a>
          {/*<div className="restaurant-ratings">
            <div className="restaurant-stars-container">
              <div className="restaurant-stars">
                <img src={stars} />
              </div>
            </div>
            <span className="sub-text ratings-text">{parseInt(restaurant.review_count)} reviews</span>
          </div>*/}
          <div className="sub-text restaurant-sub">

            {/*<div className="slide-out-tag">{restaurant.price ? restaurant.price : '$'}</div>*/}
            <div className="bounty-tile-category" style={{float: 'initial'}}>{restaurant.price ? restaurant.price : '$'}</div>
            {restaurant.categories ? restaurant.categories.split(',').map((c) => { return <div className="bounty-tile-category" style={{float: 'initial'}}>{c}</div> }) : ''}
            {/*<div className="slide-out-tag">{restaurant.city}</div>*/}
            <div className="bounty-tile-category" style={{float: 'initial'}}>{restaurant.city}</div>
            {/*<span className="restaurant-price">{restaurant.price ? restaurant.price : '$'}</span>
            <span className="restaurant-dot">•</span>
            <span className="restaurant-categories">{restaurant.categories ? restaurant.categories.split(',').join(', ') : ''}</span>
            <span className="restaurant-dot">•</span>
            <span className="restaurant-city">{restaurant.city}</span>*/}
          </div>
          {/*<div className="sub-text restaurant-sub">
            <span><a href={restaurant.url} target="_blank">{restaurant.display_address}</a></span>
            <span className="restaurant-dot">•</span>
            <span><a href={`tel:${restaurant.phone}`}>{restaurant.display_phone}</a></span>
          </div>*/}
        </div>
      </div>
    )
  }
}
