import React from 'react';
import Globals from '../../../../global-functions';
import './objective-element.scss';
import { useClasses } from '../../../../utils/use-classes';
import { Link } from 'react-router-dom';
import { Objective } from '../../../../redux/models/content.models';

interface ObjectiveElementProps {
  objective: Objective,
  reviewType: string;
  contentReview: 'PENDING' | 'REJECTED' | 'APPROVED',
  postedLive: boolean,
  linkTo?: string,
  mode?: 'full' | 'preview'
}

type StatusElementsProps = Pick<ObjectiveElementProps, 'postedLive' | 'contentReview'> & {
  direction: 'horizontal' | 'vertical'
}

const GreenCheck = <div className="check green"><img src={Globals.icon('ok', 'white')}/></div>;

export const StatusElement: React.FC<any> = ({text, status}: {text: string, status: 'APPROVED' | 'PENDING' | 'REJECTED'}) =>
  <div className="status">
    <span className={useClasses({ 'green-fg': status === 'APPROVED', 'red-fg': status === 'REJECTED' })}>{text}</span> {
    {
      'APPROVED': <img className={'green check'} src={Globals.icon('ok', 'white')} />,
      'REJECTED':<img className={'red cross'} src={Globals.icon('close', 'white')} />,
      'PENDING': <img className={'gray minus'} src={Globals.icon('minus', 'white')} />
    }[status]
  }
  </div>


export const StatusElements: React.FC<StatusElementsProps> = ({direction, reviewType, postedLive, contentReview}) =>
  <div className={useClasses({ 'horizontal-statuses': direction === 'horizontal', 'vertical-statuses': direction === 'vertical', })}>
    { (reviewType == 'all' || reviewType == 'paid') && <div className="status"> Content Review </div> }
    <div className="status"> Posted Live </div>
  </div>

export const ObjectiveElement: React.FC<ObjectiveElementProps> = ({objective, reviewType, contentReview, postedLive, linkTo, mode = 'full'}) =>
  <div className={useClasses({ 'invite-deliverable': true, 'preview': mode === 'preview', 'full': mode === 'full', })}>
    <div className="left-panel">
      <div className={'deliverable-row'}>
        <div className="invite-block-icon" style={{background: Globals.platformColor(objective.platform)}}>
          <img className="invite-block-img" src={Globals.icon(objective.icon ? objective.icon : objective.platform, 'white')} />
        </div>

        {/*<div className="icon">
          <img src={objective.image ? objective.image : (objective.icon ? Globals.icon( objective.icon, 'black') : Globals.icon( objective.platform, 'black'))}/>
        </div>*/}

        {/*<div className="title"> {objective.value }x {objective.name }  </div>
        {mode === 'full' && <div className="deliverable-description sub-text" style={{paddingLeft: '42px'}}> {objective.description } </div> }*/}


        <div className="title">
          {objective.value }x {objective.name }
          {mode === 'full' && false && <span className="deliverable-description sub-text" style={{paddingLeft: '4px', fontWeight: 400}}> {objective.description } </span> }
          {/*<div><span className={objective.contentReview === 'APPROVED' ? 'green-fg' : 'red-fg'}>{objective.contentReview}</span></div>*/}
        </div>
      </div>

      <div className="deliverable-row">
        {(mode === 'full' || true) && <div className="statuses" style={{marginTop: 0, paddingLeft: '42px'}}>
          { (reviewType == 'all' || reviewType == 'paid') && <StatusElement text="Content Review" status={contentReview || objective.contentReview} reviewType={reviewType} /> }
          <StatusElement text="Posted Live" status={postedLive || objective.postedLive ? 'APPROVED': 'PENDING'} reviewType={reviewType} />
        </div> }

        <div className="deliverable-description sub-text" style={{marginTop: '15px', paddingLeft: '42px', fontWeight: 400}}>
          { (reviewType == 'all' || reviewType == 'paid') && contentReview == 'PENDING' && <span>Submit your content for review before posting</span> }
          { (reviewType == 'all' || reviewType == 'paid') && contentReview == 'REJECTED' && <span>Re-submit your content based on brand's feedback for edits</span> }
          { ( (!reviewType || reviewType == 'none') || contentReview == 'APPROVED') && !postedLive && <span>Post your content live and submit link here</span> }
          { ( (!reviewType || reviewType == 'none') || contentReview == 'APPROVED') && postedLive && <span>Completed</span> }
        </div>
      </div>

    </div>

    {
      mode === 'full' && <div className="right-panel">
        <div> Upload <img src={Globals.icon('arrow-right-1', 'black')}/>  </div>
      </div>
    }
  </div>
