import React from 'react';
import { Link } from 'react-router-dom';
import Globals from '../../global-functions.js';
import moment from 'moment'
import history from '../history.js';
import TaxInfo from '../taxinfo/taxinfo.jsx'
import CreateInvoicePreview from './create-invoice-preview.jsx'
import InvoicesTable from './invoices-table.jsx'

export default class Invoices extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      startDate: moment().subtract('5', 'month').startOf('month').format('YYYY/M/D'),
      endDate: moment().format('YYYY/M/D'),
      incrementType: 'month',
      invoices: [],
      taxInfoOpen: false
    }
  }

  componentDidMount(){
    this.getAllInvoices()
  }

  getAllInvoices(){ this._getAllInvoices().done((response) => { this.setState(response) }) }
  _getAllInvoices(){ return $.ajax({ method: 'GET', url: '/api/Invoice/getAll', data: {} }) }
  createInvoice(){
    if (!this.state.taxInfo){ this.toggleTaxInfo() }
    else { this._createInvoice().done((response) => { if (response) { history.push(`/invoices/create/${response.invoice.id}`) } }) }
  }
  _createInvoice(){ return $.ajax({ method: 'POST', url: '/api/Invoice/create', data: {} }) }
  toggleTaxInfo() { this.setState({ taxInfoOpen: !this.state.taxInfoOpen }) }

  chooseSort(sort) {
    // var direction = this.state.sortFilter.indexOf(sort) > -1 && this.state.sortFilter.indexOf("DESC") > -1 ? "ASC" : "DESC";
    // var sortFilter = sort + " " + direction;
    // this.setState({ sortFilter: sortFilter, });
    // store('brandListSortFilter', sortFilter);
    // setTimeout(() => { this.getBrands(); }, 50);
  }

  selectInfluencer(inf, e) {
    // var id = inf == "all" ? inf : inf.id;
    // var brands = this.state.brands;
    // brands.forEach((i) => {
    //   if (id == "all") { i.selectedActive = !this.state.selectedActive; }
    //   else { if (i.id == id) { i.selectedActive = !i.selectedActive; } }
    // });
    // this.setState({ brands: brands, selectedActive: id == "all" ? !this.state.selectedActive : this.state.selectedActive, });
  }

  render() {
    var { invoices, taxInfoOpen } = this.state
    var unpaid = 53618, notdue = 40700, notdeposited = 28000, deposited = 58600

    return (
      <div>
        <div className="row">
          <div className="col-xs-6">
            <h3 style={{ fontSize: '32px', fontFamily: 'Barlow', fontWeight: '700' }}>Invoices</h3>
            <p className="sub-text">Manage your invoices here.</p>
          </div>
          <div className="col-xs-6" style={{paddingTop: '25px'}}>
            <button className="btn-action btn-primary" onClick={this.createInvoice.bind(this)} style={{float: 'right'}}>+ Create Invoice</button>
            <Link to="/invoices/settings">
              <button className="btn-action btn-secondary" style={{float: 'right', marginRight: '8px'}}>Settings</button>
            </Link>
          </div>
        </div>
        <div className="row row0">
          <div className="invoice-overview-report" style={{width: '100%'}}>
            <div className="row">
              <div className="col-md-4">
                <div className="module">
                  <div className="row row0">
                    <span className="overview-unpaid">$94,529.00 Unpaid</span>
                    <span className="sub-text overview-daterange">Last 365 days</span>
                  </div>
                  <div className="row overview-breakdown">
                    <div className="col-md-6">
                      <p className="overview-amount">${Globals.numberWithCommas(unpaid)}</p>
                      <p className="sub-text">Unpaid</p>
                    </div>
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                      <p className="overview-amount">${Globals.numberWithCommas(notdue)}</p>
                      <p className="sub-text">Not due yet</p>
                    </div>
                  </div>
                  <div className="row row0">
                    <div className="invoice-bar-container">
                      <div className="invoice-bar invoice-bar-left" style={{width: `${(unpaid/(unpaid+notdue))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-unpaid"></div></div>
                      </div>
                      <div className="invoice-bar invoice-bar-right" style={{width: `${(notdue/(unpaid+notdue))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-notdue"></div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="module">
                  <div className="row row0">
                    <span className="overview-unpaid">$94,529.00 paid</span>
                    <span className="sub-text overview-daterange">Last 365 days</span>
                  </div>
                  <div className="row overview-breakdown">
                    <div className="col-md-6">
                      <p className="overview-amount">${Globals.numberWithCommas(notdeposited)}</p>
                      <p className="sub-text">Not deposited</p>
                    </div>
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                      <p className="overview-amount">${Globals.numberWithCommas(deposited)}</p>
                      <p className="sub-text">Deposited</p>
                    </div>
                  </div>
                  <div className="row row0">
                    <div className="invoice-bar-container">
                      <div className="invoice-bar invoice-bar-left" style={{width: `${(notdeposited/(notdeposited+deposited))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-notdeposited"></div></div>
                      </div>
                      <div className="invoice-bar invoice-bar-right" style={{width: `${(deposited/(notdeposited+deposited))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-deposited"></div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="module">
                  <div className="row row0">
                    <span className="overview-unpaid">$34,550.00 paid early</span>
                    <span className="sub-text overview-daterange">Last 365 days</span>
                  </div>
                  <div className="row overview-breakdown">
                    <div className="col-md-6">
                      <p className="overview-amount">${Globals.numberWithCommas(notdeposited)}</p>
                      <p className="sub-text">Cash Out Now</p>
                    </div>
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                      <p className="overview-amount">${Globals.numberWithCommas(deposited)}</p>
                      <p className="sub-text">Paid</p>
                    </div>
                  </div>
                  <div className="row row0">
                    <div className="invoice-bar-container">
                      <div className="invoice-bar invoice-bar-left" style={{width: `${(notdeposited/(notdeposited+deposited))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-earlypay"></div></div>
                      </div>
                      <div className="invoice-bar invoice-bar-right" style={{width: `${(deposited/(notdeposited+deposited))*100}%`}}>
                        <div className="invoice-bar-inner"><div className="invoice-bar-fill invoice-deposited"></div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row0" style={{fontFamily: 'Barlow'}}>
            <div className="row row0">
              {/*{ invoices.map((invoice) => { return ( <CreateInvoicePreview invoice={invoice} /> ) }) }*/}
              <InvoicesTable this={this} invoices={invoices} chooseSort={this.chooseSort} selectInfluencer={this.selectInfluencer} />
            </div>
            <div className="row row0">
              { invoices.length == 0 &&
                <>
                  <div className="results-empty-message">
                    <h3>You have no invoices.</h3>
                    <p className="sub-text"><Link to="/account">Create an invoice.</Link></p>
                  </div>
                </>
              }
            </div>
          </div>
        </div>

        { taxInfoOpen && <TaxInfo this={this} toggleTaxInfo={this.toggleTaxInfo.bind(this)} /> }
      </div>
    )
  }
}
