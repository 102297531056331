import React from 'react';
import OnboardStatus from './onboard-status.jsx';
import Globals from '../../global-functions.js';
import Cookies from 'js-cookie';

export default class OnboardUsername extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){
    Globals.pageView(window.location.pathname + window.location.search)
    var user = Cookies.get('userdata') ? JSON.parse(Cookies.get('userdata').replace('j:', '')) : null
    if (user && user.username){
      Cookies.remove('onboarding')
      Cookies.remove('onboarding-status')
      location.href = '/dashboard'
    }
  }

  onSubmit(e){
    Globals.logEvent('click', 'submitUsername')
    e.preventDefault()
    var username = $('#username').val()
    var first_name = $('#first_name').val()
    var last_name = $('#last_name').val()
    if (username && username.length > 3 && first_name.length > 0 && last_name.length > 0 && !(username.indexOf(' ') > -1) && !(username.indexOf('.') > -1)){
      $.ajax({
        method: 'POST',
        url: '/api/Users/setUsername',
        data: {currentUserId: Cookies.get('userid'), username: username, firstName: first_name, lastName: last_name}
      })
      .done((data) => {
        if (data.success){
          Globals.logEvent('event', 'usernameSuccess')
          var user = data.user
          $('#submit').addClass('published').text('Saved! Redirecting...')
          Cookies.set('userid', user.userid)
          Cookies.set('userdata', user)
          setTimeout(() => {
            // if (!user.access_token){
            //   Cookies.set('onboarding-status', '/onboard-connect')
            //   location.href = '/onboard-connect'
            // }
            // else {
              Cookies.remove('onboarding')
              Cookies.remove('onboarding-status')
              location.href = '/dashboard'
            // }
          }, 250)
        }
        else {
          Globals.logEvent('click', 'usernameFailure')
          alert(data.message)
        }
      })
    }
    else {
      alert('Username must be more than 3 characters, and not contain any spaces or periods (".")')
    }
  }

  render() {
    return (
      <div>
        <div className="onboard-page">
          <div className="onboard-container interact-section">
            <h3>Choose Username</h3>
            <div className="set-email-container">
              <OnboardStatus status="80%" />
              <form onSubmit={this.onSubmit.bind(this)}>
                <div className="input-text-wrapper email">
                  <input id="first_name" className="input-text" placeholder="Enter First Name" />
                </div>
                <div className="input-text-wrapper email">
                  <input id="last_name" className="input-text" placeholder="Enter Last Name" />
                </div>
                <div className="input-text-wrapper email">
                  <input id="username" className="input-text" placeholder="Enter Username" />
                </div>
                <button id="submit" type="submit" className="btn-submit">SAVE</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
