import React from 'react';
import Loader from '../loader.jsx';
import SearchResult from './search-result.jsx';

let timeout_count = 0

export default class SearchAgain extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      searches: [],
      loading: false
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){
    console.log(this.props.link)
    if (this.props.link && this.props.link != 'false' && this.props.link != 'null'){
      this.search(this.props.link.wordmatch.query, )
    }
  }

  search(value){
    this.setState({
      loading: true,
      link: this.props.link,
      searches: []
    })

    this.props.searchAPI(value, this.props.this).done((response) => {
      console.log(response)
      this.state.searches = this.state.searches.concat(response.response)
      this.setState({
        searches: this.state.searches,
        loading: false
      })
    })
  }

  manualSearch(){
    timeout_count++
    var value = $('.search-again-input').val()
    var check = Number(timeout_count)
    setTimeout(() => {
      if (check == timeout_count && value.length > 2){
        this.setState({
          searches: []
        })
        this.search(value)
      }
    }, 1000)
  }

  render() {

    let html = ''

    if (this.props.link){
      html = (
        <div>
          <div style={{position: 'fixed', background: 'rgba(0, 0, 0, 0.3)', width: '100vw', height: '100vh', top: 0, left: 0, zIndex: 9999999999}}>
            <div style={{position: 'absolute', width: '650px', maxWidth: '100vw', height: '550px', maxHeight: '100vh', margin: 'auto', top: 0, left: 0, right: 0, bottom: 0, background: 'white'}}>
              <div style={{position: 'relative', width: '100%', height: '100%', overflow: 'hidden'}}>
                <div style={{position: 'relative', width: '100%', height: '50px'}}>
                  <h3 style={{padding: 0, margin: 0, height: '50px', lineHeight: '50px', paddingLeft: '20px'}}>Choose another result</h3>
                </div>
                <div className="search-overlay" style={{display: 'block', position: 'relative', height: '80px'}}>
                  <img className="search-icon" src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/search-blue.svg" />
                  <input className="manual-search search-again-input" defaultValue={this.props.link.wordmatch.query} placeholder="search for a product..." onChange={this.manualSearch.bind(this)} />
                   <img onClick={this.props.closeSearchAgain.bind(this.props.this)} src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icons/xmarkplain_black.svg" style={{position: 'absolute', top: '32px', right: '32px', left: 'initial', width: '16px', cursor: 'pointer', opacity: '0.55'}} />
                </div>
                <div className="search-dropdown" style={{display: 'block', top: 0, height: '420px'}}>
                  <p className={(this.state.loading || this.state.searches.length > 0) ? "placeholder hidden" : "placeholder" }>Search for a product above and results will appear here!</p>
                  <p className={(this.state.loading) ? "placeholder" : "placeholder hidden" }>{'Retrieving relevant products...'}</p>
                  <Loader active={this.state.loading ? true : false} />
                  <div className="search-ul-container">
                    <ul>
                      {
                        this.state.searches.map((search) => {
                          return (
                            <SearchResult key={Math.floor(Math.random() * 9999999)} search={search} onClick={this.props.searchAgainClicked.bind(this.props.this, this.props.link, search)} this={this}></SearchResult>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        {html}
      </div>
    )
  }
}
