import { Component, memo } from 'react'
import Globals from '../../global-functions.js'
import Filter from './filter'
import PillTag from './pill-tag';
import Icon from '../icon';
import includes from 'array-includes'
import FilterSearch from './filters/filter-search'
import SearchFilterContainer from './search-filter-container'

const FILTER_KEYS = ['filterSearch','filterList','filterExcludeList','filterCreatorList','filterExcludeCreatorList','filterAllLists','filterLead','filterAds','filterAdsSpend','filterCompany','filterCountry','filterCountryKnown','filterEmployees','filterJobTitle','filterHashtag','filterHashtagMention','filterMentionBrand','filterMentionInfluencer','filterIndustry','filterInfluencer','filterLanguage','filterKeyword','filterEmail','filterMentionCt','filterHasSocial','filterAffiliate','filterRestaurant','filterSocialFollowers','filterSocialLikes','filterSocialComments','filterSocialViews','filterSocialEng','filterDealOverview','filterDealStatus','filterDealSource']

class SearchFilters extends Component {
  constructor(props){
    super(props)
    this.state = {
      startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dateStr: `${moment().subtract(7, 'days').format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')}`,
      filterOpen: false,
      filterSearch: [],
      filterList: [],
      filterExcludeList: [],
      filterCreatorList: [],
      filterExcludeCreatorList: [],
      filterAllLists: { value: 'exclude', active: true },
      filterLead: [],
      filterCompany: [],
      filterCountry: [], // [{id:'US',name:'United States'},{id:'CA',name:'Canada'},{id:'UK',name:'United Kingdom'}]
      filterCountryKnown: { value: 'primary', active: true },
      filterEmployees: [],
      filterJobTitle: [],
      filterJobType: { value: false },
      filterHashtag: [],
      filterMentionBrand: [],
      filterMentionInfluencer: [],
      filterHashtagMention: [],
      filterIndustry: [],
      filterInfluencer: [],
      filterLanguage: [],
      filterKeyword: [],
      filterEmail: { value: 'marketing', active: true },
      filterAffiliate: { value: false, active: false },
      filterRestaurant: { value: false, active: false },
      filterAds: { value: '0', timeRange: '60', active: false },
      filterAdsSpend: { value: '0', timeRange: '60', active: false },
      filterMentionCt: false,
      filterHasSocial: { value: false, active: false },
      filterSocialFollowers: { value: '0', min: '0', max: '0', active: false },
      filterSocialLikes: { value: '0', min: '0', max: '0', active: false },
      filterSocialComments: { value: '0', min: '0', max: '0', active: false },
      filterSocialViews: { value: '0', min: '0', max: '0', active: false },
      filterSocialEng: { value: '0', min: '0', max: '0', active: false },
      filterDealOverview:  { value: false, active: false },
      filterDealStatus: [],
      filterDealSource: [],
    }
  }

  async startBrandSearch(){
    var { startBrandSearch, _this } = this.props
    var filtersActive = this.getActiveFilters()
    startBrandSearch(filtersActive)
  }

  getAllFilters(){ var filters = {}; FILTER_KEYS.forEach((f) => { filters[f] = this.state[f] }); return filters }
  getActiveFilters(){
    var filters = {}, activeFilters = {}, filterLabels = [], filtersApplied = 0; FILTER_KEYS.forEach((f) => { filters[f] = this.state[f] })
    Object.keys(filters).forEach((k) => { var f = filters[k], l = f[0] ? f.length : 0, active = f.active; if (l > 0 || active){ filtersApplied += l || 1; filterLabels.push(k); activeFilters[k] = f } })
    return { filters, filtersApplied, filterLabels, activeFilters }
  }

  async getWatchlists(){ var res = await this.getInsight('getWatchlists'); this.setState(res) }
  async getIndustries(){ var res = await this.getInsight('getIndustries'); this.setState(res) }
  async getLanguages(){ var res = await this.getInsight('getLanguages'); this.setState(res) }
  async getKeywords(){ var res = await this.getInsight('getKeywords'); this.setState(res) }
  async getOutreachLists() { var res = await this.getInsight('getOutreachLists'); this.setState(res) }
  async getBrandActionOptions(){ var res = await this.getInsight('getBrandActionOptions'); this.setState(res) }
  async getSavedFilters(){ var res = await this.getInsight('getSavedFilters'); this.setState(res) }

  toggleOpen(type, e) { var { filterOpen } = this.state; this.setState({ filterOpen: type == filterOpen ? false : type }) }
  removeFilters(){ var { activeFilters, filterLabels } = this.getActiveFilters(); this.changeFilter(filterLabels, activeFilters, 'all') }

  async chooseFilter(type, option) {
    store(`brandInsights-${type}Filter`, option.name);
    store(`brandInsights-${type}Chosen`, option);
    var obj = { [`${type}Filter`]: option.inactive ? false : option.name, [`${type}Chosen`]: option, skip: 0 }
    if (option.subtype == 'hubspot') { obj.hubspotChosen = { name: 'In Hubspot' }; obj.hubspotFilter = obj.hubspotChosen.name; }
    console.log(obj)
    await this.setState(obj); this.startBrandSearch();
  }

  async changeFilter(type, val, action){
    console.log('changeFilter', 'type', type, 'val', val, 'action', action)
    if (typeof val == 'string' && (val[0] == '@' || val[0] == '#')) val = val.replace('@','').replace('#','')
    var state = {}, prevent
    if (action == 'all'){
      val.forEach((v, i) => {
        if (v && v.active){ state[type[i]] = { ...v, value: false, active: false } }
        else state[type[i]] = []
      })
    } // clear filter
    else if (action == 'selector'){
      var obj = this.state[type]; obj.active = !obj.active
      state[type] = obj
      if (obj.value == '0'){ prevent = true }
    }
    else if (action == 'toggle-selector'){
      state[type] = this.state[type]
      if (state[type].value == val){ state[type] = { value: false, active: false } }
      else { state[type] = { value: val, active: true } }
    }
    else if (action == 'timeRange'){ state[type] = this.state[type]; state[type].timeRange = val; if (state[type].value == '0' || !state[type].active){ prevent = true } }
    else if (action == 'simple'){ state[type] = this.state[type]; state[type].value = val; if (val == '0' || !state[type].active){ prevent = true } }
    else if (action && action.length > 0){
      var v = []; this.state[type].forEach((f) => { if (typeof f == 'string' && f != val){ v.push(f) } else if (f.name != val.name){ v.push } })
      state[type] = v
    }
    else { state[type] = this.state[type].concat(val) }
    console.log('prevent', prevent)
    await this.setState(state); if (!prevent) this.startBrandSearch();
  }

  render() {
    var { _this, loading, dropdown, togglePopup } = this.props
    var { filterOpen, filtersApplied,
        filterSearch,filterList,filterExcludeList,filterCreatorList,filterExcludeCreatorList,filterAllLists,filterLead,filterAds,filterAdsSpend,filterMentionCt,filterCompany,filterCountry,filterCountryKnown,filterEmployees,filterJobTitle,filterHashtag,filterHashtagMention,filterMentionBrand,filterMentionInfluencer,filterIndustry,filterInfluencer,filterLanguage,filterKeyword,filterEmail,filterHasSocial,filterAffiliate,filterRestaurant,filterSocialFollowers,filterSocialLikes,filterSocialComments,filterSocialViews, filterDealOverview,filterDealStatus,filterDealSource } = this.state

    var { changeFilter, toggleOpen, startBrandSearch, removeFilters } = this
    var activeFilters = this.getActiveFilters(); var { filtersApplied } = activeFilters

    return (
      <div className="search-filters-module-container">
        <div className="search-filters-title-container" style={{paddingLeft: '10px'}}>
          <div className="row row0">
            <div className="search-filters-title-text">
              <h3>
                <span>Filters</span>
                {/*<HoverTip />*/}
              </h3>
            </div>
            <div className="search-filters-title-filters" style={{float: 'left'}}>
              { filtersApplied > 0 && <PillTag _this={this} className="pill-tag-clear-all" icon={""} name={filtersApplied} deleteTag={removeFilters.bind(this)} type={false} /> }
            </div>
            <div className="search-filters-title-filters" style={{width: '110px', float: 'right', textAlign: 'right'}}>
              { filtersApplied > 0 &&
                <div className="row row0">
                  <a onClick={togglePopup.bind(_this, 'addSavedFilterPopup', activeFilters)} style={{cursor: 'pointer'}}>
                    <Icon iconPack="bell-ring" width="11px" style={{marginTop: '-1px', marginRight: '4px'}} activeColor={true} />
                    <span style={{fontSize: '11px', color: '#1991EB'}}>Save Filters</span>
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
        <div ref={ node => this.node = node }>
          <div className={`row row0 search-menu ${this.props.active ? 'active' : ''}`} style={{ textAlign: 'center' }}>
            <div className={`search-filter-container page-search ${this.props.skinny ? '' : 'wide'}`} style={{padding: 0, border: 'none'}}>
              <FilterSearch filter={[filterSearch]} type={['filterSearch']} changeFilter={changeFilter.bind(this)} _this={this} />
              <SearchFilterContainer title="Lists" type={['filterList','filterExcludeList','filterCreatorList','filterExcludeCreatorList','filterAllLists']} icon="list-check" filter={[filterList,filterExcludeList,filterCreatorList,filterExcludeCreatorList,filterAllLists]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              {/*<SearchFilterContainer title="Brand Grouping" type={['filterGroup']} icon="list-check" filter={[filterGroup]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />*/}
              <SearchFilterContainer title="Lead Status" type={['filterLead','filterDealOverview','filterDealStatus','filterDealSource']} icon="paper-plane" filter={[filterLead,filterDealOverview,filterDealStatus,filterDealSource]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Email" type={['filterEmail']} icon="envelope-download" filter={[filterEmail]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Industry" type={['filterIndustry']} icon="briefcase" filter={[filterIndustry]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Country HQ" type={['filterCountry','filterCountryKnown']} icon="flag" filter={[filterCountry,filterCountryKnown]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Sponsorships" type={['filterAds','filterAdsSpend']} icon="dollar" filter={[filterAds,filterAdsSpend]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Social Metrics" label="filterMetrics" type={['filterHasSocial','filterSocialFollowers','filterSocialLikes','filterSocialComments','filterSocialViews']} icon="share" filter={[filterHasSocial,filterSocialFollowers,filterSocialLikes,filterSocialComments,filterSocialViews]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Hashtag" type={['filterHashtag','filterHashtagMention']} icon="hastag" filter={[filterHashtag,filterHashtagMention]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Mention" type={['filterMentionBrand','filterMentionInfluencer']} icon="at" filter={[filterMentionBrand,filterMentionInfluencer]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} iconWidth="17px" iconHeight="17px" filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer title="Keyword" type={['filterKeyword']} icon="search-alt" filter={[filterKeyword]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer isDev={true} title="Brand" type={['filterCompany']} icon="building" filter={[filterCompany]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              {/*<SearchFilterContainer title="Influencer" type={['filterInfluencer']} icon="share" filter={[filterInfluencer]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />*/}
              <SearchFilterContainer isDev={true} title="Language" type={['filterLanguage']} icon="microphone" filter={[filterLanguage]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer isDev={true} title="Employees" type={['filterEmployees','filterJobTitle']} icon="portrait" filter={[filterEmployees,filterJobTitle]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer isDev={true} title="Affiliate" type={['filterAffiliate']} icon="earnings" filter={[filterAffiliate]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />
              <SearchFilterContainer isDev={true} title="Restaurants" type={['filterRestaurant']} icon="hamburger" filter={[filterRestaurant]} changeFilter={changeFilter.bind(this)} toggleOpen={toggleOpen.bind(this)} filterOpen={filterOpen} _this={this} />

              { (filtersApplied > 0 && !loading) && <button className="btn-action btn-primary" onClick={startBrandSearch.bind(this)} style={{width: 'calc(100% - 30px)', margin: '15px'}}>Refresh Brands</button> }
              { (filtersApplied == 0 || loading) && <button className="btn-action btn-primary" style={{width: 'calc(100% - 30px)', margin: '15px', opacity: '0.6', cursor: 'not-allowed'}}>{loading ? `Compiling brands...` : `Apply Filter To Search<`}</button> }

              {/*<SearchFilterContainer title="Mention" type="filterKeyword" icon="user" filter={filterKeyword} changeFilter={changeFilter} _this={this} />
              <SearchFilterContainer title="Socials" type="filterKeyword" icon="user" filter={filterKeyword} changeFilter={changeFilter} _this={this} />
              <SearchFilterContainer title="Keyword" type="filterKeyword" icon="user" filter={filterKeyword} changeFilter={changeFilter} _this={this} />*/}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default memo(SearchFilters);
