import React from 'react';
import { PropsWithChildren } from 'react';
import { useClasses } from '../../utils/use-classes';
import { makeBackgroundImage, Nullable } from '../../utils/utils';
import Loader from '../loader';
import { GetInvitationResponse } from '../../redux/models/invitation.models';

export interface InviteHeaderProps {
  loading: boolean;
  invitation: Nullable<GetInvitationResponse>;
}

export const InviteHeader: React.FC<PropsWithChildren<InviteHeaderProps>> = ({ loading, invitation, children }) => {
  const { bannerImageUrl, brandingTextColor, brandingColor, imageUrl } = invitation || {};

  const whiteBrandColor = brandingTextColor === '#fff';
  const bgImg = bannerImageUrl ? bannerImageUrl : imageUrl;

  const invitationClasses = useClasses({
    invitation: true,
    loaded: !loading,
    loaded2: true,
    // 'white-text': whiteBrandColor,
    // 'black-text': !whiteBrandColor,
    loading
  });

  const invitationStyle = {
      backgroundColor: `${brandingColor}`,
      ...makeBackgroundImage(bgImg),
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      padding: '15px'
  };

  return <div className={invitationClasses} style={invitationStyle}>
    <div className="invitation-bg-darken" style={{backgroundColor: `#${brandingColor?.replace('#', '')}5c`}} />

    <div className="invitation-module invite-standalone">
      { loading && <Loader active={true} color={brandingTextColor} /> }
      <div className={`${!loading ? 'active' : ''}`}>

        <div>
          <div>
            <div className="game-header">
              <div className="game-header-banner" style={makeBackgroundImage(bannerImageUrl)}>
              </div>
            </div>
          </div>


          <div className="invitation-meta">
            <div>
              <div className="row row0">
                <div className="invitation-brand-img" style={makeBackgroundImage(imageUrl)} />
              </div>

              <div className="invitation-meta-inner">
                {children}
              </div>

              <p style={{ marginTop: '25px', fontSize: '14px' }}>
                <a href="https://weavesocial.com" target="_blank">Powered by <b>Weave</b></a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>


  </div>;
};
