import React from 'react';

export default class TaxInfoFooter extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }

  moveStep(num){ this.setState({ step: step + num }) }

  render() {
    var { step, valid } = this.props
    var style = {}
    if (!valid) { style = { cursor: 'not-allowed', opacity: 0.6 } }

    return (
      <div className="taxinfo-popup-footer">
        <div className="row row0">
          { step < 5 && valid && <button className="btn-action btn-primary" onClick={this.props.moveStep.bind(this.props.this, 1)}>Continue</button> }
          { step < 5 && !valid && <button className="btn-action btn-primary" style={style} disabled="disabled">Continue</button>  }
          { step >= 5 && valid && <button className="btn-action btn-primary" onClick={this.props.saveTaxInfo.bind(this.props.this, 1)}>Complete Tax Info</button> }
          { step > 1 && <button className="btn-action btn-goback" onClick={this.props.moveStep.bind(this.props.this, -1)}>Go Back</button> }
        </div>
      </div>
    )
  }
}
