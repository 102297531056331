import React from 'react';
import Loader from '../loader.jsx';

var labelUpdater = 0

export default class ProductImagesPopup extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      // expanded: false,
      // domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me'
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  // copyLink(link){
  //   $(`#aff-link-${link.hash}`).select()
  //   document.execCommand('copy')
  //   // setTimeout(() => {
  //   //   $(`#aff-link-${link.hash}`).removeClass('copied')
  //   // }, 1500)
  // }

  render() {
    var images = ''
    if (this.props.images && this.props.images.length > 0){
      images = this.props.images.map((image) => {
        return (
          <div key={Math.floor(Math.random() * 999999)} className="col-xs-4" style={{position: 'relative', height: '180px'}} onClick={this.props.chooseProductImage.bind(this.props.this, image)}>
            <img src={image.src} className="product-image-choose" />
          </div>
        )
      })
    }
    else if (this.props.images && this.props.images.length == 0) {
      images = <p className="placeholder">No images able to be found.</p>
    }
    else {
      images = <Loader active="active" />
    }

    return (
      <div>
        <div className="coupon-popup-container">
          <div className="coupon-popup">
            <div className="coupon-popup-padding">
              <div className="coupon-popup-header">
                <p>Choose A Product Image</p>
                <span onClick={this.props.hideProductImages.bind(this.props.this)}><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icon_delete_grey.svg" alt="Close Icon" /></span>
              </div>
              <div style={{overflowY: 'auto', height: '590px'}}>
                {images}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
