import React from 'react'
import Globals from '../global-functions.js'

export default class BrandTileRestaurant extends React.Component {

  constructor(props){
    super(props)
    this.state = {

    }
  }

  componentDidMount(){

  }


  render() {
    var restaurant = this.props.restaurant
    var campaign = this.props.campaign
    var content = campaign.content ? campaign.content : {}
    var offer = this.props.offer
    var stars = ''
    if (restaurant){
      var rating = Math.floor(restaurant.rating), partial = restaurant.rating - rating > 0 ? '_half' : ''
      stars = `https://weave-img.s3-us-west-2.amazonaws.com/yelp/regular_${rating}${partial}%402x.png`
    }
    else { restaurant = {} }

    var bountyCategoryTags = typeof restaurant.categories == 'string' ? restaurant.categories.split(',') : restaurant.categories

    return (
      <div>
        {/*<div className={`ribbon small-ribbon ribbon-top-right ${campaign.brandType}-ribbon`}><span>{`$${parseInt(campaign.offerInput)}`}</span></div>*/}
        {/*<div className="brand-category">
          {campaign.brandType}
        </div>*/}
        <div className="brand-restaurant">
          <div className="">
            <div className="restaurant-image" style={{backgroundImage: `url(${restaurant.image_url})`}}></div>
            <div className="restaurant-meta">
              <h3>{restaurant.name}</h3>
              {/*<div className="restaurant-ratings">
                <div className="restaurant-stars-container">
                  <div className="restaurant-stars">
                    <img src={stars} />
                  </div>
                </div>
                <span className="sub-text ratings-text">{parseInt(restaurant.review_count)} reviews</span>
              </div>*/}
              {/*<div className="sub-text restaurant-sub">
                <span className="restaurant-price">{restaurant.price ? restaurant.price : '$'}</span>
                <span className="restaurant-dot">•</span>
                <span className="restaurant-categories">{typeof restaurant.categories == 'string' ? restaurant.categories.replace(/,/g, ', ') : restaurant.categories.join(', ')}</span>
                <span className="restaurant-dot">•</span>
                <span className="restaurant-city">{restaurant.city}</span>
              </div>*/}
              <div className="sub-text restaurant-sub">
                <p className="sub-text">{offer.offerTagline}</p>
                {/*<span className="restaurant-price">{restaurant.price ? restaurant.price : '$'}</span>
                <span className="restaurant-dot">•</span>
                <span className="restaurant-city">{restaurant.city}</span>*/}
              </div>
              <div className="row row0 bounty-tile-categories">
                <div className="bounty-tile-categories-container">
                  <>
                    <div className="bounty-tile-category">{restaurant.price ? restaurant.price : '$'}</div>
                  </>
                  {
                    bountyCategoryTags &&
                    bountyCategoryTags.map((c) => {
                      return <div className="bounty-tile-category">{c}</div>
                    })
                  }
                  <>
                    <div className="bounty-tile-category">{restaurant.city}</div>
                  </>
                </div>
              </div>
              {/*<div className="sub-text restaurant-sub">
                <span className="restaurant-categories">{typeof restaurant.categories == 'string' ? restaurant.categories.replace(/,/g, ', ') : restaurant.categories.join(', ')}</span>
              </div>*/}

            </div>

            {/*   */}

            {/*<button onClick={this.props.viewOffer.bind(this.props.this, campaign)} style={{marginTop: 0}}>VIEW OFFER</button>*/}
          </div>
        </div>
      </div>
    )
  }
}
