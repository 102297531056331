import React from 'react';
import VideoTile from './video-tile.jsx';

export default class ShowMoreVideos extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      // expanded: false,
      // domain: Globals.getUser().customDomain ? Globals.getUser().customDomain : 'weav.me'
    }
  }

  componentDidUpdate(){

  }

  componentDidMount(){

  }

  // copyLink(link){
  //   $(`#aff-link-${link.hash}`).select()
  //   document.execCommand('copy')
  //   // setTimeout(() => {
  //   //   $(`#aff-link-${link.hash}`).removeClass('copied')
  //   // }, 1500)
  // }

  render() {
    var videos = ''
    if (this.props.videos && this.props.videos.length > 0){
      videos = (
        this.props.videos.map((video) => {
          return (
            <div onClick={this.props.hideMoreVideos.bind(this.props._this)}>
              <VideoTile key={video.id}  video={video} chooseVideo={this.props.chooseVideo} this={this.props.this} />
            </div>
          )
        })
      )
    }
    else {
      videos = <p className="placeholder">No videos able to be found.</p>
    }

    return (
      <div>
        <div className="coupon-popup-container">
          <div className="coupon-popup" style={{width: '650px'}}>
            <div className="coupon-popup-padding" style={{width: '650px'}}>
              <div className="coupon-popup-header">
                <p>Choose A Video</p>
                <span onClick={this.props.hideMoreVideos.bind(this.props._this)}><img src="https://s3-us-west-2.amazonaws.com/weave-img/web-assets/icon_delete_grey.svg" alt="Close Icon" /></span>
              </div>
              <div style={{overflowY: 'auto', height: '590px', padding: '15px'}}>
                {videos}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
